import {ThemeProvider} from "@mui/material/styles";
import 'moment/locale/it';
import React from 'react';
import Logged from "./pages/logged/Logged";
import NotAuthorized from "./pages/NotAuthorized";
import NotFound from "./pages/NotFound";
import NotLogged from "./pages/notLogged/NotLogged";
import {PrimaryTheme} from './theme/Themes';
import {CssBaseline} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ContextTree} from "./context/ContextTree";

const App = () => {

    const primaryTheme = PrimaryTheme();

    return (
        <>
            <ThemeProvider theme={primaryTheme}>
                <CssBaseline/>
                <ContextTree>
                    <BrowserRouter>
                        <Routes>
                            <Route exact path="/" element={<Logged/>}/>
                            <Route exact path="/Login" element={<NotLogged/>}/>
                            <Route exact path="/Register" element={<NotLogged/>}/>
                            <Route exact path="/RememberPassword" element={<NotLogged/>}/>
                            <Route exact path="/Home" element={<Logged/>}/>
                            <Route path="/Accounting/*" element={<Logged/>}/>
                            <Route path="/Booking/*" element={<Logged/>}/>
                            <Route path="/Management/*" element={<Logged/>}/>
                            <Route path="/MedicalReporting/*" element={<Logged/>}/>
                            <Route path="/Registries/*" element={<Logged/>}/>
                            <Route path="/User/*" element={<Logged/>}/>
                            <Route path="/Recup/*" element={<Logged/>}/>
                            <Route exact path="/NotFound" element={<NotFound/>}/>
                            <Route exact path="/NotAuthorized" element={<NotAuthorized/>}/>
                            <Route path="/*" element={<NotFound/>}/>
                        </Routes>
                    </BrowserRouter>
                </ContextTree>
            </ThemeProvider>
        </>
    );

}

export default App;