import React, {useContext, useEffect, useState} from 'react';
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {RecupContext} from "../../../../context/RecupContext";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {Autocomplete, Grid, Stack, TextField, useTheme} from "@mui/material";
import {columnTypes, defaultColDef, isDataNull, setDataLoading} from "../../../../common";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {AgGridReact} from "ag-grid-react";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {useForm} from "react-hook-form";
import ReactJson from "react-json-view";
import {RecupOperationUnit} from "./RecupOperationUnit";
import {RecupExecutionUnit} from "./RecupExecutionUnit";
import {RecupEuMedicalServices} from "./RecupEuMedicalServices";
import {RecupEuDiaries} from "./RecupEuDiaries";
import {RecupEuDiary} from "./RecupEuDiary";
import {RecupDiaryMedicalServices} from "./RecupDiaryMedicalServices";
import moment from "moment/moment";

export const RecupHierarchy = (props) => {

    const recupContext = useContext(RecupContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [hierarchy, setHierarchy] = useState(null);

    const [authority, setAuthority] = useState(null);

    const [hospitals, setHospitals] = useState([]);
    const [hospital, setHospital] = useState(null);

    const [sites, setSites] = useState([]);
    const [site, setSite] = useState(null);

    const [executionUnits, setExecutionUnits] = useState([]);
    const [executionUnitID, setExecutionUnitID] = useState("");
    const [executionUnitModalOpen, setExecutionUnitModalOpen] = useState(false);
    const [diariesModalOpen, setDiariesModalOpen] = useState(false);

    const [operationUnits, setOperationUnits] = useState([]);
    const [operationUnitID, setOperationUnitID] = useState("");
    const [operationUnitModalOpen, setOperationUnitModalOpen] = useState(false);

    const [execUnitMedicalServices, setExecUnitMedicalServices] = useState([]);
    const [execUnitMedicalServicesModalOpen, setExecUnitMedicalServicesModalOpen] = useState(false);

    useEffect(() => {
        if (!isDataNull(hierarchy)) return;
        recupContext.fetchHierarchy().then(res => {
            setHierarchy(res);
            setAuthority(res.authority);
            setHospitals(res.authority.hospital);
            if (res.authority.hospital.length > 0) {
                setHospital(res.authority.hospital[0])
                setSites(res.authority.hospital[0].sites);
                if (res.authority.hospital[0].sites.length > 0) {
                    setSite(res.authority.hospital[0].sites[0]);
                    setOperationUnits(normalizeOperarionUnits(res.authority.hospital[0].sites[0].operationUnits));
                    fetchExecutionUnits(res.authority.hospital[0].sites[0].id);
                }
            }
        });
    }, [hierarchy]);

    /* EXECUTION UNITS */

    const fetchExecutionUnits = (siteID) => {
        recupContext.fetchExecutionUnits(siteID).then(res => {
            setExecutionUnits(res);
        })
    }

    const ToolBarEu = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            setExecutionUnitID(selectedRow.registry.id);
            setExecutionUnitModalOpen(true);
        };

        const deleteAction = () => {
            alert(selectedRow.id);
        }

        const toogleAction = () => {
            recupContext.toogleExecutionUnit(selectedRow.registry).then(res => {
                setHierarchy(null);
            });
        }

        const diariesAction = () => {
            setExecutionUnitID(selectedRow.registry.id);
            setDiariesModalOpen(true);
        }

        const medicalServicesAction = () => {
            setExecUnitMedicalServices(selectedRow.services);
            setExecUnitMedicalServicesModalOpen(true);
        }

        const customActions = [
            {
                action: toogleAction ,
                disabled: false,
                icon: "power",
                tooltip: "Attiva/Disattiva",
            },
            {
                action: diariesAction,
                disabled: false,
                icon: "clock",
                tooltip: "Agende",
            },
            {
                action: medicalServicesAction,
                disabled: false,
                icon: "medical-service",
                tooltip: "Prestazioni",
            }
        ];

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
                customActions={customActions}
            />
        )
    }

    const activeRenderer = (props) => {
        const isActive =  moment().isBefore(props.node.data.registry.endDate);
        return isActive ? "ATTIVA" : "NON ATTIVA";
    }

    const [columnsEu, setColumnsEu] = useState([
        {headerName: "ID", field: "registry.id"},
        {headerName: "Codice", field: "registry.code"},
        {headerName: "Nome", field: "registry.name"},
        {headerName: "Descrizione", field: "registry.description"},
        {headerName: "Operation Unit", field: "registry.idOperationUnit"},
        {headerName: "Stato", cellRenderer: activeRenderer},
        {headerName: "Azioni", cellRenderer: ToolBarEu, type: "toolBar"}
    ]);

    const addActionEu = () => {
        setExecutionUnitID("0");
        setExecutionUnitModalOpen(true);
    }

    const onCloseEu = (event, reason) => {
        fetchExecutionUnits(site.id);
        setExecutionUnitID("0");
        setExecutionUnitModalOpen(false);
    }

    /* OPERATION UNIT */

    const normalizeOperarionUnits = (rows) =>{
        return rows.map((item)=> { return { ...item, label: item.id + '-' + item.name }});
    }

    const onCloseOu = (event, reason) => {
        setOperationUnitID("");
        setOperationUnitModalOpen(false);
        setHierarchy(null);
    }

    const addActionOu = () => {
        setOperationUnitID("0");
        setOperationUnitModalOpen(true);
    }

    /* EU MEDICAL SERVICES */

    const onCloseEuMedicalServices = (event, reason) => {
        setExecUnitMedicalServices([]);
        setExecUnitMedicalServicesModalOpen(false);
    }

    /* DIARIES */

    const onCloseDiaries = ()=> {
        setExecutionUnitID("");
        setDiariesModalOpen(false);
    }

    const hierarchyJSON = hierarchy ?? {};
    const executionUnitsJSON = executionUnits ?? {};

    return (
        <>
            <Grid
                item
                container
                spacing={0} direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                {
                    !isDataNull(hierarchy) ?
                        <>
                            <Grid item xs={12}>
                                <Grid container sx={{pb: theme.spacing(2)}}>
                                    <Grid item xs={12}>
                                        <Stack
                                            direction={"row"}
                                            justifyContent={"flex-end"}
                                            spacing={theme.spacing(2)}
                                            sx={{pt: theme.spacing(1)}}>
                                            <Autocomplete
                                                name={`auto-complete-hopsitals`}
                                                key={`auto-complete-hopsitals`}
                                                disablePortal
                                                options={hospitals}
                                                getOptionLabel={(option) => option.name ?? ""}
                                                defaultValue={hospital}
                                                onChange={(event, item) => {
                                                    if (!item) {
                                                        setSites([]);
                                                        setOperationUnits([]);
                                                        setExecutionUnits([]);
                                                        return;
                                                    }
                                                    setHospital(item)
                                                    setSites(item.sites);
                                                    if (item.sites.length > 0) {
                                                        setSite(item.sites[0]);
                                                        setOperationUnits(normalizeOperarionUnits(item.sites[0].operationUnits));
                                                    }
                                                }}
                                                renderInput={(params) =>
                                                    <TextField variant="filled" label={"Hospitals"} {...params} />
                                                }
                                                sx={{width: 300}}
                                            />
                                            <Autocomplete
                                                name={`auto-complete-sites`}
                                                key={`auto-complete-sites`}
                                                disablePortal
                                                options={sites}
                                                getOptionLabel={(option) => option.name ?? ""}
                                                defaultValue={site}
                                                onChange={(event, item) => {
                                                    if (!item) {
                                                        setOperationUnits([]);
                                                        setExecutionUnits([]);
                                                        return;
                                                    }
                                                    setSite(item);
                                                    setOperationUnits(normalizeOperarionUnits(item.operationUnits));
                                                    fetchExecutionUnits(item.id);
                                                }}
                                                renderInput={(params) =>
                                                    <TextField variant="filled" label={"Sites"} {...params} />
                                                }
                                                sx={{width: 300}}
                                            />
                                            <AddButton
                                                addAction={addActionOu}
                                                disabled={!site}
                                                label={"Nuova OU"}
                                            />
                                            <AddButton
                                                addAction={addActionEu}
                                                disabled={!site}
                                                label={"Nuova EU"}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <div id="agReactGridEu" className="ag-theme-alpine" style={gridSizeStyle}>
                                    <AgGridReact
                                        rowData={executionUnits}
                                        defaultColDef={defaultColDef}
                                        columnDefs={columnsEu}
                                        columnTypes={columnTypes}
                                        suppressMovableColumns={true}
                                        suppressCellFocus={true}
                                        pagination={true}
                                        rowSelection={'single'}
                                        getRowHeight={(params) => gridRowHeight}
                                    >
                                    </AgGridReact>
                                </div>
                            </Grid>
                            <Grid
                                item
                                container
                                direction={"col"}
                                display={"flex"}
                                alignContent={"flex-start"}
                                justifyContent={"flex-start"}
                                alignItems={"flex-start"}
                                justifyItems={"flex-start"}
                                sx={{p: theme.spacing(4)}}
                                spacing={theme.spacing(2)}
                            >
                                <Grid item xs={12}>
                                    <h3>JSON DI RITORNO GERARCHIA</h3>
                                </Grid>
                                <Grid item xs={12}>
                                    <ReactJson src={hierarchyJSON} theme="monokai" collapsed={true}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <h3>JSON DI RITORNO EXECUTION UNITS</h3>
                                </Grid>
                                <Grid item xs={12}>
                                    <ReactJson src={executionUnitsJSON} theme="monokai" collapsed={true}/>
                                </Grid>
                            </Grid>
                        </>
                        :
                        <CircularWaiting/>
                }
            </Grid>

            {
                executionUnitID!=="" && executionUnitModalOpen &&
                <RecupExecutionUnit
                    open={executionUnitModalOpen}
                    onClose={onCloseEu}
                    executionUnitId={executionUnitID}
                    operationUnits={operationUnits}
                />
            }

            {
                operationUnitID!=="" && operationUnitModalOpen &&
                <RecupOperationUnit
                    open={operationUnitModalOpen}
                    onClose={onCloseOu}
                    id={operationUnitID}
                    site={site}
                    sites={sites}
                />
            }

            {
                execUnitMedicalServices &&
                <RecupEuMedicalServices
                    open={execUnitMedicalServicesModalOpen}
                    close={onCloseEuMedicalServices}
                    rows={execUnitMedicalServices}
                />
            }

            {
                executionUnitID !== "" && diariesModalOpen &&
                <RecupEuDiaries
                    open={diariesModalOpen}
                    onClose={onCloseDiaries}
                    authorityId={authority.id}
                    hospitalId={hospital.id}
                    siteId={site.id}
                    executionUnitId={executionUnitID}
                />
            }
        </>
    )


}