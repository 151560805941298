import { ReactSession } from 'react-client-session';
export const API_KEY_SESSION_KEY = 'InCareApiKey';
export const LOGGED_USER_SESSION_KEY = 'InCareSession';
export const CART_SESSION_KEY = 'InCareCart';
export const AXIOS_CONFIG_SESSION_KEY = 'InCareAxiosConfig';
export const AXIOS_CALL_RESULT_SESSION_KEY = 'GesiAxiosCallResult';

export const getSessionValue = (key, defaultValue) => {
    ReactSession.setStoreType('sessionStorage');
    let apiKey = ReactSession.get(key);
    if (!apiKey) ReactSession.set(key, defaultValue);
    return ReactSession.get(key);
};

export const setSessionValue = (key, value) => {
    ReactSession.setStoreType('sessionStorage');
    ReactSession.set(key, value);
};
