import {FormControl, IconButton, InputAdornment, TextField} from "@mui/material";
import React from 'react';
import {Controller} from "react-hook-form";
import {IconLibraryItem} from "../Icons/IconLibraryItem";

export const FormTextBox = ({
                                pattern,
                                multiline,
                                lines,
                                required,
                                maxLength,
                                minLength,
                                disabled,
                                field,
                                control,
                                label,
                                register,
                                ...props
                            }) => {

    const bMultiline = (multiline ?? false);
    const iLines = (lines ?? 1);

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const getOptions = () => {
        let options = {}
        if (required !== null) {
            options.required = {};
            options.required.value = required;
            if (required === true) {
                options.required.message = "Campo richiesto";
            }
        }
        if (maxLength !== null) {
            if (maxLength > 0) {
                options.maxLength = {};
                options.maxLength.value = maxLength;
                options.maxLength.message = "Lunghezza massima " + maxLength + " caratteri.";
            }
        }
        if (minLength !== null) {
            if (minLength > 0) {
                options.minLength = {};
                options.minLength.value = minLength;
                options.minLength.message = "Lunghezza minima " + minLength + " caratteri.";
            }
        }
        if (pattern !== null) {
            switch (pattern) {
                case "email" :
                    options.pattern = {};
                    options.pattern.value = /\S+@\S+\.\S+/;
                    options.pattern.message = "Il valore inserito non è un indirizzo email valido";
                    break;
                case "IP" :
                    options.pattern = {}
                    options.pattern.value = /(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
                    options.pattern.message = "Il valore inserito non è un IP valido";
                    break;
                case "money" :
                    options.pattern = {};
                    options.pattern.value = /^([\u20AC]?[0-9]\d*\.\d{3}(?:,\d{2})?|[\u20AC]?[0-9]\d*(?:,\d{2})?|[\u20AC]?[0-9]\d*)$/;
                    options.pattern.message = "Il valore inserito non è un importo valido ";
                    break;
                case "number" :
                    options.pattern = {};
                    options.pattern.value = /^[0-9]+$/;
                    options.pattern.message = "Il valore inserito non è un numero intero";
                    break;
                case "month" :
                    options.pattern = {};
                    options.pattern.value = /^(0?[1-9]|1[012])$/;
                    options.pattern.message = "Il valore inserito non è un mese valido";
                    break;
                case "year" :
                    options.pattern = {};
                    options.pattern.value = /^(19|20)\d{2}$/;
                    options.pattern.message = "Il valore inserito non è un anno valido";
                    break;
            }
        }
        return options;
    }

    const isPassword = ((pattern ?? "") === "password");

    const isDisabled = () => {
        return (disabled) ? disabled : false;
    }

    const getValue = (value) => {
        if (pattern === "money") {
            if (value!==null) {
                const stringValue = value.toString();
                if (stringValue.indexOf(".") >= 0) {
                    return stringValue.replace(".", ",");
                } else {
                    return stringValue;
                }
            }
            else return ""

        } else {
            return (value) ? value : ""
        }
    }

    const getInputType = () => {
        let retValue = "text";
        if (pattern) {
            switch (pattern) {
                case "email" :
                    retValue = "email"
                    break;
                case "password" :
                    retValue = showPassword ? "text " : "password"
                    break;
                case "IP" :
                    retValue = "text"
                    break;
                case "money" :
                    retValue = "text"
                    break;
                case "number" :
                    retValue = "number"
                    break;
                case "year" :
                    retValue = "number"
                    break;
                case "month" :
                    retValue = "number"
                    break;
                case "tel" :
                    retValue = "tel"
                    break;
            }
        }
        return retValue;
    }

    const getAdornment = () => {
        let res = {};
        if (isPassword === true) {
            res = {
                endAdornment:
                    <InputAdornment
                        position="end"
                    >
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="small"
                        >
                            {showPassword ?
                                <IconLibraryItem name={"view"} iconSize={"small"}/>
                                :
                                <IconLibraryItem name={"hide"} iconSize={"small"}/>
                            }
                        </IconButton>
                    </InputAdornment>
            }
        return res;
        }
    }

    return (
        <FormControl fullWidth>
            <Controller
                name={field}
                control={control}
                defaultValue=""
                render={
                    (
                        {
                            field: {onChange, onBlur, value, name, ref},
                            fieldState: {error},
                        }
                    ) =>
                        (
                            <TextField
                                name={`text-field-${name}`}
                                key={`text-field-${name}`}
                                variant="filled"
                                inputRef={ref}
                                label={label}
                                type={getInputType()}
                                value={getValue(value)}
                                onChange={(event) => {
                                    onChange(event.target.value);
                                }}
                                multiline={bMultiline}
                                rows={iLines}
                                onBlur={onBlur}
                                disabled={isDisabled()}
                                error={!!error}
                                helperText={error ? error.message : null}
                                InputProps={getAdornment()}
                                // sx={{...props.sx}}
                                // InputProps={{...props.InputProps}}
                                {...props}
                            />
                        )
                }
                {...register(field, getOptions())}
            />

        </FormControl>
    )
}