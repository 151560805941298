import {Grid, Typography, useTheme} from "@mui/material";
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {isDataLoading, isDataNull} from "../../../../common";
import {FormActionBar} from "../../../../components/Form/FormActionBar";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormCheckBox} from "../../../../components/Form/FormCheckBox";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AccountingContext} from "../../../../context/AccountingContext";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {BookingContext} from "../../../../context/BookingContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {loggedClassNames} from "../../../../theme/Styles";

export const InvoiceEdit = (props) => {

        const registriesContext = useContext(RegistriesContext);
        const bookingContext = useContext(BookingContext);
        const alertContext = useContext(CustomAlertContext);
        const accountingContext = useContext(AccountingContext);

        const theme = useTheme();
        const loggedClasses = loggedClassNames(theme);

        const [firstLoaded, setFirstLoaded] = useState(false);
        const [record, setRecord] = useState(null);

        const {
            watch, register, control, formState: {errors}, handleSubmit, reset, getValues
        } = useForm({defaultValues: record});

        useEffect(() => {
            if (firstLoaded) return;
            if (!isDataLoading(registriesContext.payingMethods)) registriesContext.fetchPayingMethodsItemizedListValues();
            if (!isDataLoading(registriesContext.banks)) registriesContext.fetchBanks();
            if (!isDataLoading(accountingContext.invoiceQuoteTypes)) accountingContext.fetchInvoiceQuoteTypeItemizedListValues();
            setFirstLoaded(true);
        }, [firstLoaded]);

        useEffect(() => {
            if (record) return;
            accountingContext.fetchInvoice(accountingContext.accountingState.invoice.mInvoiceID).then((res) => {
                setRecord(res);
                reset(res);
            });
        }, [record]);

        const onError = (errors, e) => {
            console.log(errors, e);
        }

        const invoiceSavingCancel = (data) => {
            //niente
        }

        const onSubmit = (data) => {
            alertContext.showConfirmDialog(
                "Conferma salvataggio fattura",
                "Stai salvataggio la fattura. Sei sicuro? ",
                invoiceSavingCancel,
                () => {
                    accountingContext.updateInvoice(data);
                    accountingContext.openAccounting({}, 0);
                }
            );
        }

        if (isDataNull(registriesContext.payingMethods) || isDataLoading(registriesContext.payingMethods)) return <CircularWaiting/>
        if (isDataNull(registriesContext.payingMethods) || isDataLoading(registriesContext.payingMethods)) return <CircularWaiting/>
        if (isDataNull(registriesContext.invoiceQuoteTypes) || isDataLoading(registriesContext.invoiceQuoteTypes)) return <CircularWaiting/>
        if (!record) return <CircularWaiting/>

        return (
            <>
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Grid
                        container
                        direction={"row"}
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                        sx={loggedClasses.bookingDetailModal}
                        spacing={theme.spacing(2)}
                    >

                        <Grid item xs={12}>
                            <Typography variant="h3">Modifica fattura</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextBox
                                label="CF"
                                control={control}
                                record={record}
                                register={register}
                                field="mPatient.mCF"
                                required={true}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextBox
                                label="Nome completo"
                                control={control}
                                record={record}
                                register={register}
                                field="mPatient.mCompleteName"
                                required={true}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextBox
                                label="Indirizzo"
                                control={control}
                                record={record}
                                register={register}
                                field="mPatient.mCompleteAddress"
                                required={true}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormAutoComplete
                                label="Metodo di pagamento"
                                control={control}
                                record={record}
                                getValues={getValues}
                                setRecord={accountingContext.setFromVisitLinesInvoice}
                                reset={reset}
                                register={register}
                                field="mPayementMode"
                                joinField=""
                                values={registriesContext.payingMethods}
                                idField="mItemizedListValueID"
                                labelField="mValue"
                                selectedValue="label"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormAutoComplete
                                label="Banca"
                                control={control}
                                record={record}
                                getValues={getValues}
                                setRecord={accountingContext.setFromVisitLinesInvoice}
                                reset={reset}
                                register={register}
                                field="mBank"
                                joinField="mBankID"
                                values={registriesContext.banks}
                                idField="mBankID"
                                labelField="mBankName"
                                selectedValue="id"
                                required={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormAutoComplete
                                label="Quote"
                                control={control}
                                record={accountingContext.fromVisitLinesInvoice}
                                register={register}
                                field="mQuote"
                                joinField=""
                                values={accountingContext.invoiceQuoteTypes}
                                idField="mItemizedListValueID"
                                labelField="mValue"
                                selectedValue="label"
                                required={false}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextBox
                                label="Notes"
                                control={control}
                                record={record}
                                register={register}
                                field="mNotes"
                                required={false}
                                maxLength={250}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormCheckBox
                                label="Pagata"
                                control={control}
                                record={record}
                                getValues={getValues}
                                setRecord={accountingContext.setFromVisitLinesInvoice}
                                reset={reset}
                                register={register}
                                field="mPayed"
                                required={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormActionBar cancelAction={() => accountingContext.openAccounting({}, 0)}/>
                        </Grid>
                    </Grid>

                </form>
            </>
        );
    }
;

export default InvoiceEdit;