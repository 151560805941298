import React, {createContext, useEffect, useState} from 'react';
import {JSONClone} from "../common";
import {CustomAlert, WebApiHandler} from '../components/Alerts/CustomAlerts';

const InitDataAlertState = {
    requestId: "",
    apiURL: "",
    bShowOnlyError: false,
    isFinished: false,
    isSucceeded: false,
    responseCode: 0,
    responseData: null,
    responseMessage: "",
    responseApiKey: null
}

const InitCustomAlertState = {
    requestId: "",
    open: false,
    message: "",
    severity: ""
}

const InitCustomConfirmDialogState = {
    requestId: "",
    open: false,
    message: ""
}

const CustomAlertContext = createContext();

const CustomAlertProvider = (props) => {

    let dataAlertInternalQueue = [];
    const [dataAlertQueue, setDataAlertQueue] = useState([]);
    const [customAlertQueue, setCustomAlertQueue] = useState([]);
    const [jsonAlertQueue, setJsonAlertQueue] = useState([]);
    const [customConfirmDialog, setCustomConfirmDialog] = useState(InitCustomConfirmDialogState);

    const generateRequestId = (length) => {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const beginServiceCall = (bShowOnlyError, pApiURL) => {
        let reqId = generateRequestId(25);
        let startFetchingState = JSONClone(InitDataAlertState);
        startFetchingState.requestId = reqId;
        startFetchingState.apiURL = pApiURL;
        startFetchingState.bShowOnlyError = bShowOnlyError;
        dataAlertInternalQueue.push(startFetchingState);
        return reqId;
    }

    const endServiceCall = (pRequestId, res) => {

        let updatedItem = null;

        let newDataAlertInternalQueue = dataAlertInternalQueue.map(item => {
            if (item.requestId === pRequestId) {
                let newItem = {};
                newItem = JSONClone(item);
                newItem.isFinished = true;
                newItem.isSucceeded = (res.responseCode===0);
                newItem.responseCode = res.responseCode;
                newItem.responseData =  (res.responseData) ?? [];
                newItem.responseMessage = res.responseMessage;
                newItem.responseShowInPopup = res.responseShowInPopup;
                newItem.responseAnyError = res.responseAnyError;
                updatedItem = newItem;
                return newItem;
            }
            else return item;
        });
        dataAlertInternalQueue = newDataAlertInternalQueue;

        if (updatedItem && (!updatedItem.bShowOnlyError || !updatedItem.isSucceeded)) {
            let newDataAlertQueue = dataAlertQueue;
            newDataAlertQueue.push(updatedItem);
            setDataAlertQueue([...newDataAlertQueue]);
        }

        return updatedItem;
    }

    const showCustomAlert = (pType, pMessage, handleHide = null) => {
        /* INFO: 'info', SUCCESS: 'success', ERROR: 'error' */
        let reqId = generateRequestId(25);
        let showCustomState = {
            requestId: reqId,
            open: true,
            message: pMessage,
            handleHide: handleHide,
            severity: pType,
            isReady: false,
        }
        let newValue = [...customAlertQueue, showCustomState];
        setCustomAlertQueue(newValue);
    }

    const removeCustomRequest = (pRequestId) => {
        setCustomAlertQueue(
            customAlertQueue.filter(item => item.requestId !== pRequestId)
        );
    }

    const showConfirmDialog = (pTitle,pMessage,handleCancel,handleConfirm) => {
        /* INFO: 'info', SUCCESS: 'success', ERROR: 'error' */
        let reqId = generateRequestId(25);
        let showCustomState = {
            requestId: reqId,
            open: true,
            title: pTitle,
            message: pMessage,
            handleHide: hideConfirmDialog,
            handleCancel: handleCancel,
            handleConfirm : handleConfirm,
        }
        setCustomConfirmDialog(showCustomState);
    }

    const hideConfirmDialog = (pRequestId) => {
        setCustomConfirmDialog(InitCustomConfirmDialogState);
    }

    const removeDataAlertRequest = (pRequestId) => {
        setDataAlertQueue(
            dataAlertQueue.filter(item => item.requestId !== pRequestId)
        );
    }

    const showJsonAlert = (pMessage, pData, handleHide = null) => {
        /* INFO: 'info', SUCCESS: 'success', ERROR: 'error' */
        let reqId = generateRequestId(25);
        let showCustomState = {
            requestId: reqId,
            open: true,
            message: pMessage,
            handleHide: handleHide,
            data: pData,
            isReady: false,
        }
        let newValue = [...customAlertQueue, showCustomState];
        setJsonAlertQueue(newValue);
    }

    const removeJsonRequest = (pRequestId) => {
        setJsonAlertQueue(
            jsonAlertQueue.filter(item => item.requestId !== pRequestId)
        );
    }

    return (
        <CustomAlertContext.Provider
            value={{
                beginServiceCall,
                endServiceCall,
                showCustomAlert,
                removeCustomRequest,
                showConfirmDialog,
                hideConfirmDialog,
                customConfirmDialog,
                customAlertQueue,
                dataAlertQueue,
                removeDataAlertRequest,
                jsonAlertQueue,
                showJsonAlert,
                removeJsonRequest
            }}
        >
            {props.children}
        </CustomAlertContext.Provider>
    )

}

export {CustomAlertProvider, CustomAlertContext};