import {CardContent, Grid, Stack, Typography, useTheme} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {columnTypes, defaultColDef, isDataLoading, isDataNull} from "../../../../common";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";

const Districts = () => {

    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [record, setRecord] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [dataLoaded, setDataLoaded] =useState(false);

    const {
        register,
        control,
        handleSubmit,
        reset
    } = useForm({defaultValues: record});

    useEffect(() => {
        if (isDataNull(registriesContext.regions) && !isDataLoading(registriesContext.regions)) registriesContext.fetchRegions();
    }, []);

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(registriesContext.districts)) registriesContext.fetchDistricts().then((res)=>{
            setDataLoaded(true);
        })
    }, [registriesContext.districts]);

    //dettaglio

    const onSubmit = (data) => {
        registriesContext.updateDistrict(data);
        setModalOpen(false);
    }

    const onError = (errors, e) => {
        console.log(record);
        console.log(errors, e);
    }

    const onClose = (event, reason) => {
        setModalOpen(false);

    }

    //griglia

    const addAction = () => {
        registriesContext.fetchDistrict(0).then((res) => {
            setRecord(res);
            reset(res);
            setModalOpen(true);
        });
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            registriesContext.fetchDistrict(selectedRow.mProvID).then((res) => {
                setRecord(res);
                reset(res);
                setModalOpen(true);
            });
        };

        const deleteAction = () => {
            alert(selectedRow.mProvID);
        }

        return (<ActionsToolBar
            hasEdit editAction={editAction}
            hasDelete deleteAction={deleteAction}
        />)
    }

    const onGridReady = (params) => {
        // setAgGridApi(params.api);
        // params.api.sizeColumnsToFit();
    }

    const [columns, setColumns] = useState([
        {headerName: "Nome", field: "mProvName"},
        {
            headerName: "Targa",
            field: "mProvTarga"
        }, {headerName: "Codice ISTAT", field: "mISTATCode"},
        {
            headerName: "Regione",
            field: "mRegion.mRegionName"
        },
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    if (isDataNull(registriesContext.regions) || isDataLoading(registriesContext.regions)) return <CircularWaiting/>
    if (isDataNull(registriesContext.districts) || isDataLoading(registriesContext.districts)) return <CircularWaiting/>


    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={11}>
                    <Typography variant="h4">Province</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{pb: theme.spacing(2)}}>
                        <Grid item xs={12}>
                            <Stack
                                direction={"row"}
                                justifyContent={"flex-end"}
                                spacing={theme.spacing(2)}
                                sx={{pt: theme.spacing(1)}}>
                                <AddButton addAction={addAction}/>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={registriesContext.districts}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            onGridReady={onGridReady}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
            <CardModal
                modalOpen={modalOpen}
                onClose={onClose}
                title={"Dettaglio provincia"}
                size={"small"}
                formFunctions={{submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError}}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Nome"
                                control={control}
                                record={record}
                                register={register}
                                field="mProvName"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Targa"
                                control={control}
                                record={record}
                                register={register}
                                field="mProvTarga"
                                required={true}
                                maxLength={2}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Codice ISTAT"
                                control={control}
                                record={record}
                                register={register}
                                field="mISTATCode"
                                required={true}
                                maxLength={4}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormAutoComplete
                                label="Regione"
                                control={control}
                                record={record}
                                register={register}
                                field="mRegion"
                                joinField="mRegionID"
                                values={registriesContext.regions}
                                idField="mRegionID"
                                labelField="mRegionName"
                                selectedValue="id"
                                required={true}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardModal>
        </>
    )
}

export default Districts;