export default function Switch(theme) {
    return {
        MuiSwitch: {
            styleOverrides: {
                root: {
                },
                switchBase: {
                    '&.Mui-checked': {
                        '& + .MuiSwitch-track': {
                            opacity: 0.38,
                            backgroundColor: theme.palette.primary.main
                        },
                    },
                },
                thumb: {
                    backgroundColor: theme.palette.primary.main
                },
                track: {
                    opacity: 0.38,
                    backgroundColor: theme.palette.primary.main
                },
            }
        }
    };
}