export default function Typography(theme) {
    return {
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    // marginBottom: 12
                }
            }
        }
    };
}
