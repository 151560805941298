import {useTheme} from "@mui/material";
import React, {useContext, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import {RegistriesContext} from "../../context/RegistriesContext";
import {loggedClassNames} from "../../theme/Styles";
import {ActionButton} from "../Buttons/ActionButton";

export const AceButton = ({patientID}) => {

    const authContext = useContext(AuthContext);
    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();

    let aceBaseUrl = authContext.getSystemParameterValue("ACE_URL") ?? '';

    const buttonClicked = () => {
        let aceUrl = aceBaseUrl;
        aceUrl+='/' + patientID;
        window.open(aceUrl, "_blank");
    };

    if (aceBaseUrl==='') return <></>

    return (
        <ActionButton
            color="secondary"
            variant="contained"
            action={buttonClicked}
            size="small"
            icon="world"
            iconSize="medium"
            tooltip="Files"
            label="Files"
        />
    )
};
