import {Backdrop, Card, Fade, Grid, Modal, Typography, useTheme} from "@mui/material";
import React from "react";
import {StyledCardHeader} from "./SyledCardHeader";
import {loggedClassNames} from "../../theme/Styles";
import {CircleButton} from "../Buttons/CircleButton";


const CardBodyContent = ({title, titleSize, actionSize, onClose, formFunctions, ...props}) => {
    const theme = useTheme();

    return (
        <>
            {title &&
                <StyledCardHeader>
                    <Grid container>
                        <Grid item xs={titleSize}>
                            <Typography variant={"h5"} component={"span"}
                                        justifyContent={"center"}>{title}</Typography>
                        </Grid>
                        <Grid item xs={actionSize} display={"flex"} justifyContent={"right"}>
                            <>
                                {
                                    (formFunctions) &&
                                    <CircleButton
                                        color="secondary"
                                        variant="contained"
                                        size="extra-small"
                                        icon="save"
                                        iconSize="small"
                                        tooltip="Salva"
                                        label=""
                                        type="submit"
                                        sx={{mr: theme.spacing(1)}}
                                    />
                                }
                                <CircleButton
                                    color="secondary"
                                    variant="contained"
                                    action={onClose}
                                    size="extra-small"
                                    icon="close"
                                    iconSize="small"
                                    tooltip="Chiudi"
                                    label=""
                                    sx={{mr: theme.spacing(1)}}
                                />
                            </>
                        </Grid>
                    </Grid>
                </StyledCardHeader>
            }
            {props.children}
        </>
    )
}

export const CardModal = ({
                              modalOpen,
                              onClose,
                              title,
                              size,
                              formFunctions,
                              ...props
                          }) => {

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    const sizeClass = ((size ?? "") === "xs") ?
        loggedClasses.extraSmallDetailsContainer :
        ((size ?? "") === "small") ?
            loggedClasses.smallDetailsContainer :
            loggedClasses.detailsContainer;
    const titleSize = (formFunctions) ? 10 : 11;
    const actionSize = 12 - titleSize;


    return (
        <Modal
            open={modalOpen}
            onClose={onClose}
            aria-labelledby={"modal-" + title ?? ""}
            aria-describedby={"modal-description-" + title ?? ""}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            disableRestoreFocus
        >
            <Fade in={modalOpen}>
                <Card sx={sizeClass}>
                    {
                        (formFunctions) ?
                            <form
                                onSubmit={formFunctions.submitHandler(formFunctions.onFormSubmit, formFunctions.onFormError)}>
                                <CardBodyContent
                                    actionSize={actionSize}
                                    onClose={onClose}
                                    title={title}
                                    titleSize={titleSize}
                                    formFunctions={formFunctions}
                                    children={props.children}
                                />
                            </form> :
                            <CardBodyContent
                                actionSize={actionSize}
                                onClose={onClose}
                                title={title}
                                titleSize={titleSize}
                                formFunctions={formFunctions}
                                children={props.children}
                            />
                    }
                </Card>
            </Fade>
        </Modal>
    )

}