import {Card, CardContent, CardHeader, Divider, Typography, useTheme} from "@mui/material";
import React from 'react';
import * as PropTypes from "prop-types";

const headerSX = {
    p: 2.5,
    '& .MuiCardHeader-action': {m: '0px auto', alignSelf: 'center'}
};

export const MainCard = ({
                             border = true,
                             boxShadow,
                             children,
                             content = true,
                             contentSX = {},
                             divider = true,
                             elevation,
                             secondary,
                             shadow,
                             sx = {},
                             title,
                             codeHighlight,
                             ...others
                         }) => {

    const theme = useTheme();

    boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;

    return (
        <Card
            elevation={elevation || 0}
            {...others}
            sx={{
                ...sx,
                border: border ? '1px solid' : 'none',
                borderRadius: 2,
                borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A800,
                boxShadow: boxShadow && (!border || theme.palette.mode === 'dark') ? shadow || theme.customShadows.z1 : 'inherit',
                ':hover': {
                    boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit'
                },
                '& pre': {
                    m: 0,
                    p: '16px !important',
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '0.75rem'
                }
            }}
        >
            {/* card header and action */}
            <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary}/>

            {/* content & header divider */}
            {title && divider && <Divider/>}

            {/* card content */}
            {content && <CardContent sx={contentSX}>{children}</CardContent>}
            {!content && children}

        </Card>
    )
}