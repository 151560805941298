
import {createTheme} from '@mui/material/styles';
import {Palette} from "./Palette";
import {useMemo} from "react";
import {CustomShadows} from "./CustomShadows";
import {Typography} from "./Typography";
import Components from "./Components";
export const PrimaryTheme = () => {

    const theme = Palette('light', 'default');

    const themeTypography = Typography(theme,'Montserrat');

    const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

    const themeBreakPoints = {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 1920,
        },
    };

    const themeOptions = useMemo(
        () => ({
            palette: theme.palette,
            typography: themeTypography,
            customShadows: themeCustomShadows,
            breakpoints: themeBreakPoints,
            shape: {borderRadius: 2}
        }),
        [theme, themeTypography]
    );

    let primaryTheme =  createTheme(themeOptions);
    primaryTheme.components = Components(primaryTheme);

    return primaryTheme;

}

