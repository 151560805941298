import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Grid,
    Popover,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import React, {useContext, useState} from "react";
import {getCompleteName} from "../../common";
import {BookingContext} from "../../context/BookingContext";
import {ManagementContext} from "../../context/ManagementContext";
import SmsComposer from "../Sms/SmsComposer";
import {loggedClassNames} from "../../theme/Styles";
import {StatusBadge} from "../Badges/StatusBadge";
import {ActionButton} from "../Buttons/ActionButton";
import VisitLineSlotDetail from "./VisitLineSlotDetail";
import {CardModal} from "../Layout/CardModal";
import {useNavigate} from "react-router-dom";
import {CommonContext} from "../../context/CommonContext";
import {ExpandMoreTwoTone} from "@mui/icons-material";
import {VisitLineSlotPaper} from "./VisitLineSlotPaper";
import {IconLibraryItem} from "../Icons/IconLibraryItem";
import {RegistriesContext} from "../../context/RegistriesContext";

export const VisitLineSlotHeader = ({room, slot}) => {

    const bookingContext = useContext(BookingContext);
    const registriesContext = useContext(RegistriesContext);
    const commonContext = useContext(CommonContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    let navigate = useNavigate();

    let label = "";
    label += commonContext.getSlotHourLabel(slot.timeBegin);
    let textDecoration = (slot.isCurrent) ? "underline" : "normal";
    let visitLinesCount = slot.visitLines.length;
    let isEmpty = visitLinesCount === 0;
    let status = (isEmpty) ? "PRENOTA" : "OCCUPATO";
    let slotColor = (isEmpty) ? "success" : "error";
    let slotPaddingFactor = 5;
    let slotFactor = 50;
    let slotPadding = (slot.size === 1) ? slotPaddingFactor : slotPaddingFactor * (slot.size + 2);
    let slotHeight = slotPadding + (slotFactor * slot.size);

    const newSlotClick = (slot) => {
        registriesContext.resetMedicalServices();
        let booking = {
            room: room.mRoomID,
            slot: slot.timeBegin
        }
        bookingContext.openBooking(booking, 1);
        navigate('/Booking/TakeBooking');
    }

    const IsWebIcon = ({vls}) => {
        let isWeb = false;

        vls.map((vl, index) => {
            isWeb = isWeb || (vl.mIsWebReservation ?? false);
            return null;
        });
        return (isWeb) ?
            <IconLibraryItem name={"world"} iconSize={"medium"} sx={{color: theme.palette.secondary.dark}}/> : <></>;
    }

    const vl = slot.visitLines[0];
    let completeName = '';
    let vlLabel = '';
    if (!isEmpty) {
        completeName = (((vl.mVisita.mPatient ?? {}).mPatientID ?? 0) !== 0) ?
            getCompleteName(vl.mVisita.mPatient.mNomePaziente, vl.mVisita.mPatient.mCognomePaziente) :
            getCompleteName(vl.mVisita.mTempPatient.mNomePaziente, vl.mVisita.mTempPatient.mCognomePaziente);
        vlLabel = vl.mPrestazione.mDescrizione;
    }

    return (
        <>
            <Grid container direction={"row"}>
                <Grid item xs={12} md={2}>
                    <Typography variant={"h6"}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                    <IsWebIcon vls={slot.visitLines}/>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={5}
                    display="flex"
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid item xs={12}>
                        <Typography variant={"h6"}>{completeName}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>{vlLabel}</Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-end"
                    sx={{pr: 2}}
                >
                    <StatusBadge
                        label={status}
                        color={slotColor}
                        onClick={() => {
                            if (isEmpty) newSlotClick(slot);
                        }}
                    />
                    {
                        (!isEmpty) && (slot.visitLines).map((vl, index) => {
                            return (
                                <StatusBadge
                                    sx={{mt: theme.spacing(1) }}
                                    label={vl.mStatoEsame ?? ""}
                                    color={bookingContext.getVisitLineStatusColor(vl)}
                                />

                            )
                        })
                    }
                </Grid>
            </Grid>
        </>
    )
}