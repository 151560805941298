import {
    Avatar,
    Box, Chip,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useTheme
} from "@mui/material";
import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {firstLetter, getCompleteName} from "../../common";
import {BookingContext} from "../../context/BookingContext";
import {RegistriesContext} from "../../context/RegistriesContext";
import {loggedClassNames} from "../../theme/Styles";
import {StatusBadge} from "../Badges/StatusBadge";
import {CommonContext} from "../../context/CommonContext";
import {CircleButton} from "../Buttons/CircleButton";
import {StyledCardHeader} from "../Layout/SyledCardHeader";

const VisitLineSlotDetail = ({
                                 room,
                                 slot,
                                 vl,
                                 onClose,
                                 ...otherProps
                            }) => {

    const commonContext = useContext(CommonContext);
    const bookingContext = useContext(BookingContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    let navigate = useNavigate();

    const getTimeSlotHourLabel = (currentDate) => {
        return currentDate.format(commonContext.getPlanningParameter("TIME_FORMAT_SHORT"));
    }

    const getDateSlotHourLabel = (currentDate) => {
        return currentDate.format(commonContext.getPlanningParameter("DATE_FORMAT_SHORT"));
    }

    const visitLineClick = () => {
        let booking = {
            visit: vl.mVisita
        }
        bookingContext.openBooking(booking, 4);
        navigate('/Booking/VisitDetail');
    }

    let completeName = "";

    if (vl.mVisita && vl.mVisita.mPatient) {
        completeName = getCompleteName(vl.mVisita.mPatient.mNomePaziente, vl.mVisita.mPatient.mCognomePaziente);
    }

    let cf = "";
    let tel = "";

    if (
        vl.mVisita &&
        vl.mVisita.mPatient
    ) {
        cf += vl.mVisita.mPatient.mCF ?? "-"
        tel += (vl.mVisita.mPatient.mMobilePhone ?? "-") + " / " + (vl.mVisita.mPatient.mTelefono ?? "-")
    }

    let statusColor = bookingContext.getVisitLineStatusColor(vl);
    let status = vl.mStatoEsame;
    let invoicingColor = bookingContext.getVisitLineInvoicingColor(vl);
    let invoicingStatus = vl.mInvoicingStatus;

    const createdBy =  ((vl ?? {}).mCreatedBy ?? {});
    const createdName = (createdBy.mFirstName) ? firstLetter(createdBy.mFirstName) + "." : "";
    const createdByString = (createdBy.mLastName ?? " - ") + " " + createdName;

    const acceptedBy =  ((vl ?? {}).mAccettedBy ?? {});
    const acceptedName = (acceptedBy.mFirstName) ? firstLetter(acceptedBy.mFirstName) + "." : "";
    const acceptedByString = (acceptedBy.mLastName ?? " - ") + " " + acceptedName;

    const reportedBy =  ((vl ?? {}).mReportedBy ?? {});
    const reportedName = (reportedBy.mFirstName) ? firstLetter(reportedBy.mFirstName) + "." : "";
    const reportedByString = (reportedBy.mLastName ?? " - ") + " " + reportedName;

    return (
        <Paper
            sx={loggedClasses.roomSlotDetail}
            sx={{padding: theme.spacing(3)}}
        >
            <StyledCardHeader>
                <Grid container>
                    <Grid item xs={11}>
                        <Typography variant={"h5"} component={"span"} justifyContent={"center"}>
                            {commonContext.getSlotDateLabel(slot.timeBegin)} {commonContext.getSlotHourLabel(slot.timeBegin) + ' - ' + commonContext.getSlotHourLabel(slot.timeEnd)}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} display={"flex"} justifyContent={"right"}>
                        <>
                            <CircleButton
                                color="secondary"
                                variant="contained"
                                action={onClose}
                                size="extra-small"
                                icon="close"
                                iconSize="small"
                                tooltip="Chiudi"
                                label=""
                                sx={{mr: theme.spacing(1)}}
                            />
                        </>
                    </Grid>
                </Grid>
            </StyledCardHeader>
            <TableContainer component={Box} sx={{mb: theme.spacing(4)}}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell><Typography>SALA</Typography></TableCell>
                            <TableCell>{room.mRoomName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><Typography>PAZIENTE</Typography></TableCell>
                            <TableCell flexWrap="wrap">{completeName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><Typography>CF</Typography></TableCell>
                            <TableCell flexWrap="wrap">{cf}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><Typography>CONVENZIONE</Typography></TableCell>
                            <TableCell flexWrap="wrap">{vl.mVisita.mConvention.mConventionDescription}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><Typography>PRESTAZIONE</Typography></TableCell>
                            <TableCell flexWrap="wrap"> {vl.mPrestazione.mDescrizione}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><Typography>TELEFONO</Typography></TableCell>
                            <TableCell flexWrap="wrap"> {tel}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid
                container
                direction={"row"}
                spacing={theme.spacing(2)}
                sx={{mb: theme.spacing(2)}}
            >
                <Grid item xs={12} md={12}>
                    <StatusBadge label={status} color={statusColor} fullWidth/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <StatusBadge label={invoicingStatus} color={invoicingColor} fullWidth/>
                </Grid>
            </Grid>
            <Grid
                container
                direction={"row"}
                spacing={theme.spacing(1)}
                sx={{p: theme.spacing(1)}}
            >
                <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                    <Typography>Prenotato da</Typography>
                </Grid>
                <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                    <Typography>
                        Accettato da
                    </Typography>
                </Grid>
                <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                    <Typography>Refertato da</Typography>
                </Grid>
                <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                    <Chip color={"warning"} label={createdByString}/>
                </Grid>
                <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                    <Chip color={"primary"} label={acceptedByString}/>
                </Grid>
                <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                    <Chip color={"secondary"} label={reportedByString}/>
                </Grid>

            </Grid>

        </Paper>
    )
}

export default VisitLineSlotDetail