import {useTheme} from "@mui/material";
import React, {createContext, useContext} from "react";
import * as webServices from "../services/WebServices";
import {CustomAlertContext} from "./AlertContext";
import {AuthContext} from "./AuthContext";
import moment from "moment";
import {CommonContext} from "./CommonContext";

const RecupContext = createContext();

const newExecutionUnit =  {
    mOperationUnitID: "",
    mExecutionUnitCode: "",
    mExecutionUnitName:  "",
    mExecutionUnitDescription:  "",
    mExecutionUnitID: "0"
}

const newDiary = {
    mAuthorityID: "",
    mHospitalID: "",
    mOperationUnitID: "",
    mSiteID: "",
    mExecutionUnitID: "",
    mAgendaName: "",
    mAgendaCode: "",
    mAgendaExternalCode: "",
    mAgendaID: "0",
}

const RecupProvider = (props) => {

    const alertContext = useContext(CustomAlertContext);
    const authContext = useContext(AuthContext);
    const commonContext = useContext(CommonContext);
    const theme = useTheme();

    const shortTimeFormat = commonContext.getPlanningParameter("TIME_FORMAT_SHORT");
    const longDateTimeFormat = commonContext.getPlanningParameter("DATETIME_FORMAT_LONG");

    const fetchHierarchy = async () => {
        let res = await webServices.wsGetHierarchicalStructure({}, alertContext, true);
        if (res.responseAnyError) return {};
        return res.responseData;
    }

    const fetchExecutionUnits = async (siteID) => {
        const body  = { mSiteID: siteID }
        let res = await webServices.wsGetExecutionUnits(body, alertContext, true);
        if (res.responseAnyError) return [];
        return res.responseData;
    }

    const fetchExecutionUnit = async (execUnitID) => {
        if (execUnitID !== "0") {
            const body  = { mExecutionUnitID: execUnitID }
            let res = await webServices.wsGetExecutionUnits(body, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            console.log('recs ', recs[0]);
            return (recs.length !== 0) ? recs : newExecutionUnit;
        } else {
            return newExecutionUnit;
        }
    }

    const updateExecutionUnit = async (record) => {
        let res = await webServices.wsUpdateExecutionUnit(record, alertContext, true);
        if (res.responseAnyError) return null;
        alertContext.showJsonAlert("/Recup/ExecutionUnitsCreateOrUpdate", res.responseData);
        return res;
    }

    const toogleExecutionUnit = async (record) => {
        const execUnitID = record.id;
        const endDate = record.endDate;
        const isActive =  moment().isBefore(endDate);
        const body  = {
            mExecutionUnitID: execUnitID,
            mExectutionUnitActive: !isActive
        }
        let res = await webServices.wsToggleExecutionUnit(body, alertContext, true);
        if (res.responseAnyError) return null;
        alertContext.showJsonAlert("/Recup/ExecutionUnitToggle", res.responseData);
        return res;
    }

    const fetchDiaries = async (execUnitID) => {
        const body  = { mExecutionUnitID: execUnitID }
        let res = await webServices.wsGetDiariesByExecutionUnit(body, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        return (recs.length !== 0) ? recs : [];
    }

    const fetchDiary = async (diaryID) => {
        if (diaryID !== "0") {
            const body  = { mAgendaID: diaryID }
            let res = await webServices.wsGetDiariesByID(body, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs : newDiary;
        } else {
            return newDiary;
        }
    }

    const updateDiary = async (record) => {
        let res = await webServices.wsUpdateDiary(record, alertContext, true);
        if (res.responseAnyError) return null;
        alertContext.showJsonAlert("/Recup/DiariesCreateOrUpdate", res.responseData);
        return res;
    }

    const updateOperationUnit = async (record) => {
        let res = await webServices.wsUpdateOperationUnit(record, alertContext, true);
        if (res.responseAnyError) return null;
        alertContext.showJsonAlert("/Recup/OperationUnitCreateOrUpdate", res.responseData);
        return res;
    }

    const createTimeBands = async (record) => {
        const timeBands = record.mSchemaTimebands.map((item)=>{
            return {
                ...item,
                mOraInizio: item.mOraInizio.clone().format(longDateTimeFormat),
                mOraFine: item.mOraFine.clone().format(longDateTimeFormat)
            }
        });
        const body = {
            ...record,
            mSchemaTimebands: [...timeBands]
        }
        let res = await webServices.wsCreateTimeBands(body, alertContext, true);
        if (res.responseAnyError) return null;
        alertContext.showJsonAlert("/Recup/CreateSchemaAndTimebandsFromDiary", res.responseData);
        return res;
    }

    const fetchDiarySchemas = async (diaryID) => {
        const body  = { mAgendaID: diaryID }
        let res = await webServices.wsGetDiarySchemas(body, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        return recs;
    }

    const deleteSchema = async (record) => {
        let res = await webServices.wsDeleteSchema(record, alertContext, true);
        if (res.responseAnyError) return null;
        alertContext.showJsonAlert("/Recup/DeleteDiarySchema", res.responseData);
        return res;
    }

    const fetchSchemaTimeBands = async (body) => {
        let res = await webServices.wsGetSchemaTimeBands(body, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        return recs;
    }

    const toggleDiary = async (record) => {
        const boolActive = record.status.id === 1;
        const body  = {
            mAgendaID: record.id,
            mAgendaActive: !boolActive
        }
        let res = await webServices.wsToogleDiary(body, alertContext, true);
        if (res.responseAnyError) return null;
        alertContext.showJsonAlert("/Recup/DiaryToggle", res.responseData);
        return res;
    }

    return (
        <RecupContext.Provider
            value={{
                fetchHierarchy,
                fetchExecutionUnits,
                fetchExecutionUnit,
                updateExecutionUnit,
                toogleExecutionUnit,
                fetchDiaries,
                fetchDiary,
                updateDiary,
                updateOperationUnit,
                createTimeBands,
                fetchDiarySchemas,
                deleteSchema,
                fetchSchemaTimeBands,
                toggleDiary
            }}
        >
            {props.children}
        </RecupContext.Provider>
    );
};

export {RecupProvider, RecupContext};