import React, {useContext, useEffect, useState} from "react";
import {BookingContext} from "../../../../context/BookingContext";
import {useTheme} from "@mui/material/styles";
import {loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {Grid, Stack, Typography} from "@mui/material";
import {PDFViewer} from "../../../../components/PDF/PDFViewer";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {ManagementContext} from "../../../../context/ManagementContext";

export const SmsModulePreview = ({
                                     orderID,
                                     setOrderToPrint
                                   }) => {

    const managementContext = useContext(ManagementContext);
    const [documentContent, setDocumentContent] = useState("");

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    useEffect(() => {
        if (orderID===-1) return;
        if (documentContent === "") {
            managementContext.printSmsOrder(orderID).then((res) => {
                setDocumentContent(res.mListOfBytes);
            });
        }
    }, [orderID]);

    const modalOpen = () => {
        return orderID !== -1;
    }

    const onClose = (event, reason) => {
        setOrderToPrint(-1);
        setDocumentContent("");
    }

    if (!modalOpen()) return <></>

    return (
        <CardModal
            modalOpen={modalOpen}
            title={"Anteprima ordine"}
            onClose={onClose}
        >
            {
                (documentContent === "") ?
                    <CircularWaiting/> :
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h4" component="h5">
                                Ordine numero {orderID}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <PDFViewer dataType={"application/pdf"} dataContent={'data:application/pdf;base64,' + documentContent}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack
                                direction={"row"}
                                justifyContent="flex-start"
                                spacing={theme.spacing(2)}
                                sx={{pt: theme.spacing(1)}}>
                                <ActionButton
                                    color="primary"
                                    variant="contained"
                                    action={onClose}
                                    size="medium"
                                    icon="close"
                                    iconSize="medium"
                                    tooltip="Chiudi"
                                    label="Chiudi"
                                />
                            </Stack>
                        </Grid>
                    </Grid>
            }
        </CardModal>
    )

}