import {Button, Card, CardContent, Fade, Grid, Modal, Stack, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import React, {useContext, useEffect, useState} from "react";
import {isDataLoading, isDataNull, JSONIsEmpty} from "../../../../common";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {AddButton} from "../../../../components/Grid/ActionsToolbar";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AccountingContext} from "../../../../context/AccountingContext";
import {loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";
import {BookingContext} from "../../../../context/BookingContext";
import {PDFViewer} from "../../../../components/PDF/PDFViewer";

export const InvoicePreview = ({
                                   documentID,
                                   setDocumentID
                               }) => {

    const accountingContext = useContext(AccountingContext);
    const bookingContext = useContext(BookingContext);

    const [documentContent, setDocumentContent] = useState("");

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    useEffect(() => {
        if (accountingContext.accountingMode !== 2 && bookingContext.bookingMode!==4) return;
        if (documentID === -1) return;
        if (documentContent === "") {
            accountingContext.fetchInvoicePDF(documentID).then((res) => {
                if (res === null) {
                    onClose();
                    return;
                }
                setDocumentContent(res.mListOfBytes);
            });
        }
    }, [documentID]);

    const modalOpen = () => {
        return documentID !== -1 && (documentContent !== "") && (accountingContext.accountingMode === 2 || bookingContext.bookingMode===4);
    }

    const onClose = (event, reason) => {
        setDocumentID(-1);
        setDocumentContent("");
    }

    if (!modalOpen()) return <></>
    if (isDataNull(accountingContext.invoices) || isDataLoading(accountingContext.invoices)) return <></>

    return (
        <CardModal
            modalOpen={modalOpen}
            onClose={onClose}
            title={"Anteprima fattura"}
        >
            <CardContent sx={loggedClasses.detailsContent}>
                {
                    (documentContent==="") ?
                        <CircularWaiting/> :
                        <Grid container>
                            <Grid item xs={12}>
                                <PDFViewer dataType={"application/pdf"} dataContent={'data:application/pdf;base64,' + documentContent}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack
                                    direction={"row"}
                                    justifyContent="flex-start"
                                    spacing={theme.spacing(2)}
                                    sx={{pt: theme.spacing(1)}}>
                                    <ActionButton
                                        color="primary"
                                        variant="contained"
                                        action={onClose}
                                        size="medium"
                                        icon="close"
                                        iconSize="medium"
                                        tooltip="Chiudi"
                                        label="Chiudi"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                }
            </CardContent>
        </CardModal>
    )
}