// import NewPassword from "./Components/NewPassword";
import {Box, Grid, Link, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useLocation} from 'react-router-dom';
import {CustomAlerts, DataAlerts} from "../../components/Alerts/CustomAlerts";
import {notLoggedClassNames} from "../../theme/Styles";
import {MainCard} from "../../components/Cards/MainCard";
import Login from './login/Login';
import RememberPassword from "./rememberPassword/RememberPassword";
import AppLogo from "../../components/Logos/AppLogo";
import Register from "./register/Register";

const notLoggedRoutes = [
    {path: '/Login', component: <Login/>, exact: true},
    {path: '/Register', component: <Register/>, exact: true},
    {path: '/RememberPassword', component: <RememberPassword/>, exact: true},
]

const LocationRenderer = () => {

    const location = useLocation();

    const exactElement = notLoggedRoutes.find(l => l.exact === true && location.pathname === l.path);

    if (exactElement) return exactElement.component;

    const partialElement = notLoggedRoutes.find(l => l.exact === false && location.pathname.startsWith(l.path));

    return (partialElement) ? partialElement.component : <></>

}

const NotLogged = () => {

    const theme = useTheme();
    const notLoggedClasses = notLoggedClassNames(theme);

    const [disclaimerOpen, setDisclaimerOpen] = React.useState(true);

    const handleDisclaimerClick = () => {
        setDisclaimerOpen(true);
    };

    const handleDisclaimerClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setDisclaimerOpen(false);
    };

    const LinkWithTargetBlank = ({...props}) => {
        return <Link target={"_blank"} href={"https://www.incarecloud.it"}>{props.children}</Link>;
    }

    return (
        <>
            <Box
                display="flex"
                alignContent={"flex-start"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                    backgroundImage: `url(${"../../assets/images/login.jpg"})`,
                    ...notLoggedClasses.notLoggedContainer
                }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                >
                    <Grid item xs={12}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            sx={notLoggedClasses.notLoggedContent}
                        >
                            <Grid item>
                                <MainCard
                                    sx={notLoggedClasses.notLoggedMainCard}
                                    content={false}
                                    border={false}
                                    boxShadow
                                    shadow={(theme) => theme.customShadows.z1}
                                >
                                    <Box sx={notLoggedClasses.notLoggedMainBox}>
                                        <Grid container spacing={theme.spacing(4)}>
                                            <Grid item xs={12} textAlign={"center"} sx={{p: theme.spacing(1)}}>
                                                {/*<AppLogo size={75} partnerSize={50}/>*/}
                                                <AppLogo size={75}/>
                                            </Grid>
                                            {/*<Grid item xs={12} textAlign={"center"}>*/}
                                            {/*    <Typography component={LinkWithTargetBlank} variant="h1">*/}
                                            {/*        Consulta il nostro catalogo prodotti*/}
                                            {/*    </Typography>*/}
                                            {/*</Grid>*/}
                                            <Grid item xs={12}>
                                                <LocationRenderer/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </MainCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <CustomAlerts/>
                <DataAlerts/>
            </Box>

        </>
    )
}

export default NotLogged;