import React, {useContext, useEffect, useState} from 'react';
import {CardContent, Grid, useTheme} from "@mui/material";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import ReactJson from "react-json-view";
import {CardModal} from "../../../../components/Layout/CardModal";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {RecupContext} from "../../../../context/RecupContext";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {loggedClassNames} from "../../../../theme/Styles";
import {useForm} from "react-hook-form";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {isDataNull} from "../../../../common";

export const RecupOperationUnit = ({
                                       open,
                                       onClose,
                                       id,
                                       site,
                                       sites
                                   }) => {

    const recupContext = useContext(RecupContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [operationUnit, setOperationUnit] = useState(null);
    const [operationUnitRecord, setOperationUnitRecord] = useState(null);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        if (!isDataNull(operationUnit)) return;
        if (id === "0") {
            const ouRec = {
                mOperationUnitName: '',
                mSiteID: site.id
            }
            setOperationUnit(ouRec);
            setOperationUnitRecord(ouRec);
            resetOu(ouRec);
        } else {
        }
    }, [operationUnit])

    const {
        register: registerOu,
        control: controlOu,
        handleSubmit: handleSubmitOu,
        reset: resetOu,
        getValues: getValuesOu
    } = useForm({defaultValues: operationUnitRecord});

    const onSubmitOu = (data) => {
        recupContext.updateOperationUnit(data).then(res => {
            setOperationUnitRecord(null);
            onClose()
        });
    }

    const onErrorOu = (errors, e) => {
        console.log(errors, e);
    }

    const operationUnitJSON = operationUnit ?? {};

    const title = (id==="0") ? "Nuova operation unit" : ("Dettaglio operation unit " + id);

    return (
        <>
            <CardModal
                modalOpen={open}
                onClose={onClose}
                title={title}
                size={"small"}
                formFunctions={{submitHandler: handleSubmitOu, onFormSubmit: onSubmitOu, onFormError: onErrorOu}}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        {
                            operationUnit && !updating ?
                                <>
                                    <Grid item xs={12} md={6}>
                                        <FormTextBox
                                            label="Nome"
                                            control={controlOu}
                                            record={operationUnitRecord}
                                            register={registerOu}
                                            field="mOperationUnitName"
                                            required={true}
                                            maxLength={100}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormAutoComplete
                                            label="Site"
                                            control={controlOu}
                                            record={operationUnitRecord}
                                            register={registerOu}
                                            field="mSiteID"
                                            joinField=""
                                            values={sites}
                                            idField="id"
                                            labelField="name"
                                            selectedValue="id"
                                            required={true}
                                            disabled={false}
                                        />
                                    </Grid>
                                </>
                                :
                                <CircularWaiting/>
                        }

                    </Grid>
                    <Grid item xs={12} container sx={{pt: theme.spacing(2)}}>
                        <Grid item xs={12}>
                            <h3>JSON DI RITORNO OPERATION UNIT</h3>
                            <ReactJson src={operationUnitJSON} theme="monokai" collapsed={true}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardModal>
        </>
    )
}