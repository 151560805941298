import {createTheme} from '@mui/material/styles';
import {presetPalettes} from '@ant-design/colors';
import {indigo, teal} from "@mui/material/colors";
import {greyAscent, greyConstant, greyPrimary} from "./Colors";

export const Palette = (mode) => {

    const colors = presetPalettes;

    colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

    const { grey } = colors;

    const greyColors = {
        0: grey[0],
        50: grey[1],
        100: grey[2],
        200: grey[3],
        300: grey[4],
        400: grey[5],
        500: grey[6],
        600: grey[7],
        700: grey[8],
        800: grey[9],
        900: grey[10],
        A50: grey[15],
        A100: grey[11],
        A200: grey[12],
        A400: grey[13],
        A700: grey[14],
        A800: grey[16]
    };

    const contrastText = '#fff';

    return createTheme({
        palette: {
            mode,
            common: {
                black: '#000',
                white: '#fff'
            },
            primary: {
                main: "#0C2840",
                contrastText: "#fff"
                // main: indigo[500]
                // main: "#355fd5",
                // light: "#acb4dc",
                // dark: "#1d45b5",

            },
            secondary: {
                // main: teal[500]
                main: "#27B2F8",
                contrastText: "#fff"
            },
            text: {
                primary: greyColors[700],
                secondary: greyColors[500],
                disabled: greyColors[400],
                contrastText: "#fff"
            },
            action: {
                disabled: greyColors[300]
            },
            divider: greyColors[200],
            background: {
                paper: greyColors[0],
                default: greyColors.A50
            }
        }
    });

};