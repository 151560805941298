import {Box, Grid, Typography, useTheme} from "@mui/material";
import {SquareButton} from "../Buttons/SquareButton";
import {StatusBadge} from "../Badges/StatusBadge";
import React, {useContext} from "react";
import {RegistriesContext} from "../../context/RegistriesContext";
import {BookingContext} from "../../context/BookingContext";
import {CustomAlertContext} from "../../context/AlertContext";
import {loggedClassNames} from "../../theme/Styles";

const InvervalSlot = ({
                          interval,
                          closeInterval,
                          openInterval,
                          openIntervalDetail
                      }) => {

    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);
    const alertContext = useContext(CustomAlertContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const slotsLabel = "DISP. " + interval.mFreeSlots + " di " + interval.mTotalSlots
    const statusLabel = (!interval.mClosed) ? slotsLabel : "CHIUSO";
    const statusColor = (!interval.mClosed) ? "secondary" : "error";
    const classStyle = {
        ...loggedClasses.schedulerInterval,
        borderColor: interval.rgbColor
    }

    return (
        <Box
            sx={classStyle}
        >
            <Grid container>
                <Grid item xs={12} display={"flex"} justifyContent={"right"}>
                    {
                        (!interval.mClosed) ?
                            <SquareButton
                                color="secondary"
                                variant="contained"
                                action={
                                    () => {
                                        alertContext.showConfirmDialog(
                                            "Conferma chiusura turno",
                                            "Stai per chiudere il turno. Sei sicuro? ",
                                            () => {
                                            },
                                            () => {
                                                closeInterval(interval);
                                            }
                                        );
                                    }
                                }
                                disabled={false}
                                icon={"lock-clock"}
                                iconSize={"extra-small"}
                                size={"extra-small"}
                                tooltip={"Chiudi intervallo"}
                            />
                            :
                            <SquareButton
                                color="secondary"
                                variant="contained"
                                action={
                                    () => {
                                        alertContext.showConfirmDialog(
                                            "Conferma apertura turno",
                                            "Stai per aprire il turno. Sei sicuro? ",
                                            () => {
                                            },
                                            () => {
                                                openInterval(interval);
                                            }
                                        );
                                    }
                                }
                                disabled={false}
                                icon={"lock-clock"}
                                iconSize={"extra-small"}
                                size={"extra-small"}
                                tooltip={"Chiudi intervallo"}
                            />
                    }
                </Grid>
                <Grid
                    item
                    container
                    onClick={
                        (!interval.mClosed) ?
                            () => {
                                openIntervalDetail(interval)
                            }
                            :
                            () => {
                            }
                    }
                >
                    <Grid item xs={12}>
                        <Typography variant={"body2"} sx={loggedClasses.schedulerIntervalHeader}>{interval.mBranca.mBrancaName}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>{interval.mDoctor.mCompleteShortName}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>{interval.mHourFromLabel} {interval.mHourToLabel}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <StatusBadge
                            label={statusLabel}
                            color={statusColor}
                            fullWidth

                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default InvervalSlot;