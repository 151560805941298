import {ExitToApp} from "@mui/icons-material";
import {Badge, Box, Chip, Grid, IconButton, Toolbar, Typography} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import {useTheme} from '@mui/material/styles';
import React, {useContext, useState} from 'react';
import {useNavigate, Link} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";
import {BookingContext} from "../../context/BookingContext";
import CustomIcon from "../Icons/CustomIcon";
import MainNav from "../MainNav/MainNav";
import SessionProgress from "../Session/SessionProgress";
import ProfileMenu from "../User/ProfileMenu";
import UserImage from "../User/UserImage";
import {IconLibraryItem} from "../../components/Icons/IconLibraryItem";
import {RecupContext} from "../../context/RecupContext";
import AppLogo from "../Logos/AppLogo";

const TopBar = (props) => {

    const authContext = useContext(AuthContext);
    const bookingContext = useContext(BookingContext);
    const recupContext = useContext(RecupContext);

    const theme = useTheme();
    let navigate = useNavigate();

    const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);

    const handleProfileMenuOpen = (event) => {
        setProfileMenuAnchor(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuAnchor(null);
    };

    const handleProfileMenuClick = () => {

        setProfileMenuAnchor(null);
    };

    const goToHome = (event) => {
        navigate('/Home');
    };

    const handleCartVisitOpen = (event) => {
        let visitID = bookingContext.getStorageCartID();
        if (visitID === 0) return;
        let booking = {
            visit: {mVisitaID: visitID}
        }
        bookingContext.openBooking(booking, 4);
        navigate('/Booking/VisitDetail');
    };

    const doLogout = () => {
        authContext.logout().then((res) => {
            let session = authContext.getSession();
            if (!session.isLogged) {
                navigate('/Login');
            }
        })
    };

    return (
        <>
            <MuiAppBar position="fixed">
                <Toolbar variant="dense" disableGutters>
                    <MainNav/>
                    <Box sx={{flexGrow: 1}}/>
                    <Grid
                        container
                        display={"flex"}
                        spacing={theme.spacing(1)}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        sx={{ ml: theme.spacing(1), flexGrow: 1}}
                    >
                        <Grid item xs={12} md={8}>
                            {/*<AppLogo size={150} partnerSize={150} direction={"row"}/>*/}
                            <AppLogo size={150} direction={"row"}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Chip
                                clickable
                                variant="outlined"
                                icon={
                                    <Badge badgeContent={bookingContext.getCartVisitLinesCount()} max={10}>
                                        <IconLibraryItem name={"cart"} iconSize={"medium"}/>
                                    </Badge>
                                }
                                size={"medium"}
                                onClick={handleCartVisitOpen}
                                label={"Carrello"}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{flexGrow: 0}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls={"primary-search-account-menu"}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <UserImage width={45} height={45}/>
                        </IconButton>
                        <ProfileMenu
                            anchor={profileMenuAnchor}
                            closeHandler={handleProfileMenuClose}
                        />
                    </Box>
                    <Box sx={{flexGrow: 0}}>
                        <SessionProgress/>
                    </Box>
                    <Box sx={{flexGrow: 0}}>
                        <IconButton
                            size="large"
                            aria-label="logout"
                            aria-controls={"logout"}
                            aria-haspopup="true"
                            onClick={doLogout}
                            color="inherit"
                        >
                            <ExitToApp/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </MuiAppBar>
        </>
    );
}

export default TopBar;