import {Stack} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import moment from "moment";
import 'moment/locale/it';
import React, {useContext, useState} from 'react';
import {ManagementContext} from "../../context/ManagementContext";
import {ActionButton} from "../Buttons/ActionButton";
import {InputDatePicker} from "../Input/InputDatePicker";
import {CommonContext} from "../../context/CommonContext";

export const DashboardDataFilter = ({
                                        showFrom,
                                        showTo,
                                        submitFunction,
                                        resetFunction,
                                        isDisabled
                                    }) => {

    const commonContext = useContext(CommonContext);
    const managementContext = useContext(ManagementContext);

    const theme = useTheme();

    const it = moment().locale('it');

    const [resetted, setResetted] = useState(false);

    const fromValue = managementContext.dashboardFilter.mYearStart;
    const toValue = managementContext.dashboardFilter.mYearEnd;

    const setStartFilter = (newValue)=> {
        resetFunction().then(()=> {
            const newFilter = {...managementContext.dashboardFilter, mYearStart: newValue};
            managementContext.setDashboardFilter(newFilter);
        });
    }

    const setEndFilter = (newValue)=> {
        resetFunction().then(()=> {
            const newFilter = {...managementContext.dashboardFilter, mYearEnd: newValue};
            managementContext.setDashboardFilter(newFilter);
        });
    }

    return (
        <Stack
            direction={"row"}
            spacing={theme.spacing(2)}
        >
            {
                (showFrom ?? false) &&
                    <InputDatePicker
                        label="Da"
                        openTo="year"
                        views={['year']}
                        value={fromValue}
                        format={"yyyy"}
                        setValue={setStartFilter}
                        sx={{width: 300}}
                        disableFuture
                        disabled={isDisabled}
                    />
            }
            {
                (showTo ?? false) &&
                    <InputDatePicker
                        label="A"
                        openTo="year"
                        views={['year']}
                        value={toValue}
                        format={"yyyy"}
                        setValue={setEndFilter}
                        sx={{width: 300}}
                        disableFuture
                        disabled={isDisabled}
                    />

            }
            <ActionButton
                color="primary"
                variant="contained"
                action={submitFunction}
                size="small"
                icon="load"
                iconSize="small"
                tooltip="Genera grafici"
                label="Genera grafici"
                disabled={false}
                fullWidth
            />
            <ActionButton
                color="primary"
                variant="contained"
                action={resetFunction}
                size="small"
                icon="reset"
                iconSize="small"
                tooltip="Reset grafici"
                label="Reset grafici"
                disabled={false}
                fullWidth
            />
        </Stack>
    )

}