import moment from "moment/moment";
import React from 'react';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

export const InputDatePicker = ({
                                    label,
                                    format,
                                    disabled,
                                    fullWidth,
                                    value,
                                    setValue,
                                    openTo,
                                    views,
                                    disableFuture,
                                    ...props
                                }) => {

    let it = moment().locale('it');

    const isDisabled = disabled ?? false;
    const isFullWidth = fullWidth ?? false;
    const openToOption = openTo ?? "day";
    const viewsOptions = views ?? ['year', 'month','day'];
    const isDisableFuture = disableFuture ?? false;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={it}>
            <DatePicker
                label={label}
                inputFormat={format}
                value={value}
                disabled={isDisabled}
                openTo={openToOption}
                views={viewsOptions}
                onChange={(newValue) => {
                    setValue(newValue);
                }}
                slotProps={{
                    textField: {
                        variant:"filled",
                        format: props.format
                    }
                }}
                fullWidth={isFullWidth}
                sx={props.sx}
                disableFuture={isDisableFuture}
            />
        </LocalizationProvider>
    )

}