import {Grid, TextField, Typography, useTheme} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    columnTypes,
    dateTimeShortColumnRenderer,
    defaultColDef,
    isDataLoading,
    isDataNull,
    JSONClone
} from "../../../../common";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {ActionsToolBar} from "../../../../components/Grid/ActionsToolbar";
import {PaginationToolBar} from "../../../../components/Grid/PaginationToolBar";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {MedicalReportingContext} from "../../../../context/MedicalReportingContext";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";

export const MedicalReports = () => {

    const medicalReportingContext = useContext(MedicalReportingContext);

    const theme = useTheme();
   const loggedClasses = loggedClassNames(theme);
    let navigate = useNavigate();

    const [agGridApi, setAgGridApi] = useState(null);
    const [dataLoaded, setDataLoaded] =useState(false);
    const [currServerSideFilter, setCurrServerSideFilter] = useState(medicalReportingContext.medicalReportsServerSideFilter);

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(medicalReportingContext.medicalReports)) medicalReportingContext.fetchMedicalReports(true).then(res=>{
            medicalReportingContext.setMedicalReportingLoaded(true);
            setDataLoaded(true);
        });
    }, [medicalReportingContext.medicalReports]);

    const onGridReady = (params) => {
        setAgGridApi(params.api);
        // params.api.sizeColumnsToFit();
    }

    const ToolBar = (props) => {

        let record = props.node.data;

        const pdfDisabled = () => {
            return false
        }

        const pdfAction = () => {
            let reportingItem = {medicalReport: record};
            medicalReportingContext.openMedicalReporting(reportingItem, 3);
            navigate('/MedicalReporting/MedicalReportEditor');
        };

        const editDisabled = () => {
            return !record.mCanBeUpdated;
        }

        const editAction = () => {
            let reportingItem = {medicalReport: record}
            medicalReportingContext.openMedicalReporting(reportingItem, 4);
            navigate('/MedicalReporting/MedicalReportEditor');
        };

        const customDisabled = () => {
            return record.mStato !== 'FINALE';
        }

        const customAction = () => {
            let reportingItem = {medicalReport: record};
            medicalReportingContext.openMedicalReporting(reportingItem, 5);
            navigate('/MedicalReporting/Sign');
        };

        const customActions = [{
            action: customAction,
            disabled: customDisabled(),
            icon: "fingerprint",
            tooltip: "Firma",
        }]

        return (
            <ActionsToolBar
                hasPDF pdfAction={pdfAction} pdfDisabled={pdfDisabled()}
                hasEdit editAction={editAction} editDisabled={editDisabled()}
                customActions={customActions}
            />
        )
    }

    const [columns, setColumns] = useState([
        {headerName: "Visita", field: "mVisita.mAccessionNumber"},
        {headerName: "Referto", field: "mNUMERO_REFERTO"},
        {headerName: "Paziente", field: "mPatientCompleteName"},
        {headerName: "Data referto", field: "mDataReferto", cellRenderer: dateTimeShortColumnRenderer},
        {headerName: "Stato referto", field: "mStato"},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    const onSelectionChanged = () => {
        // let rows = agGridApi.getSelectedNodes().map(node => node.data);
        // let visits = rows.map(q => q.mVisita.mVisitaID);
        // let uniqueVisits = visits.filter((v, idx) => visits.indexOf(v) === idx);
        // setSelectedRows(rows);
        // setSelectedVisits(uniqueVisits);
    }

    const serverSideFilterChange = (field, newValue) => {
        let newObject = JSONClone(currServerSideFilter);
        newObject[field] = newValue;
        setCurrServerSideFilter(newObject)
    }

    const serverSideFilterResetClick = () => {
        medicalReportingContext.fetchMedicalReports(true);
    }

    const serverSideConfirmClick = () => {
        medicalReportingContext.fetchMedicalReports(true, currServerSideFilter);
    }

    if (isDataNull(medicalReportingContext.medicalReports) || isDataLoading(medicalReportingContext.medicalReports)) return <CircularWaiting/>

    if (!medicalReportingContext.medicalReportState.loaded) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Elenco referti</Typography>
                </Grid>
                <Grid item xs={12}>
                    <PaginationToolBar
                        currentPage={medicalReportingContext.medicalReportsCurrentPage}
                        pagesCount={medicalReportingContext.medicalReportsTotalPages}
                        pageSize={medicalReportingContext.medicalReportsPageSize}
                        setCurrentPage={medicalReportingContext.setMedicalReportsCurrentPage}
                        rowsCount={medicalReportingContext.medicalReportsTotalRows}
                    >
                        <TextField
                            variant="filled"
                            sx={{width: 250}}
                            label="Cognome"
                            type="text"
                            value={currServerSideFilter.mCognomePaziente}
                            onChange={(e) => {
                                serverSideFilterChange("mCognomePaziente", e.target.value);
                            }}
                        />
                        <TextField
                            variant="filled"
                            sx={{width: 250}}
                            label="Nome"
                            type="text"
                            value={currServerSideFilter.mNomePaziente}
                            onChange={(e) => {
                                serverSideFilterChange("mNomePaziente", e.target.value);
                            }}
                        />
                        <ActionButton
                            color="secondary"
                            variant="contained"
                            action={serverSideConfirmClick}
                            size="small"
                            icon="search"
                            iconSize="small"
                            tooltip="Cerca"
                            label="Cerca"
                            sx={loggedClasses.paginationToolbarHeightSx}
                        />
                        <ActionButton
                            color="secondary"
                            variant="contained"
                            action={serverSideFilterResetClick}
                            size="small"
                            icon="refresh"
                            iconSize="small"
                            tooltip="Annulla"
                            label="Annulla"
                            sx={loggedClasses.paginationToolbarHeightSx}
                        />
                    </PaginationToolBar>
                </Grid>
                <Grid item xs={12}>
                    <div id="invoicesGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={medicalReportingContext.medicalReports}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={false}
                            rowSelection={'single'}
                            onGridReady={onGridReady}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>

        </>
    );

}

export default MedicalReports;