import axios from "axios";
import {ReactSession} from 'react-client-session';
import {
    API_KEY_SESSION_KEY,
    AXIOS_CALL_RESULT_SESSION_KEY,
    AXIOS_CONFIG_SESSION_KEY,
    getSessionValue,
    setSessionValue
} from "../storage";
import {getConfigKey} from "../common";

const configAPIURL = getConfigKey('apiURL');

//const apiURL = configAPIURL ? configAPIURL : "http://INCARE-SERVER:5100/api";
//const apiURL = 'https://localhost:64644/api'
const apiURL = configAPIURL ? configAPIURL : "http://INCARE-SERVER:5100/api";

console.log('endpoint ',apiURL);

const axiosInstance = axios.create({
    baseURL: apiURL
});

axiosInstance.interceptors.request.use((config) => {
    let api = "";
    api = getSessionValue(API_KEY_SESSION_KEY,'');
    if (api==="") api = getSessionValue(API_KEY_SESSION_KEY,'');
    config.headers = {
        'Content-Type': 'application/json',
        Sessionkey: api
    }
    setSessionValue(AXIOS_CONFIG_SESSION_KEY, config);
    return config;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    setSessionValue(AXIOS_CALL_RESULT_SESSION_KEY, response);
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

const DoWebApiCall = async (pUrl, pBody, alertContext, showOnlyError) => {

    let callResponse = new WebAPICallResponse();

    let reqId = alertContext.beginServiceCall(showOnlyError, pUrl);
    try {
        let res = await axiosInstance.post(pUrl, pBody);
        callResponse.responseData = res.data.mResult;
        callResponse.responseCode = parseInt(res.data.mErrorCode);
        callResponse.responseMessage = res.data.mErrorDescription;
        callResponse.responseAnyError = res.data.mAnyError;
        callResponse.responseShowInPopup = res.data.mShowInPopup;
        callResponse.responseApiKey = res.data.mSessionKey;
        if (res.data.mTotRecords) { callResponse.responseDataRowsCount =  res.data.mTotRecords}
        if (res.data.mTotPages) { callResponse.responseDataPagesCount =  res.data.mTotPages}
        if (res.data.mCurrententPage) { callResponse.responseDataCurrentPage =  res.data.mCurrententPage}
        if (res.data.mPageSize) { callResponse.responseDataPageSize =  res.data.mPageSize}
        if (res.data.mAuthCode) { callResponse.responseAuthCode = res.data.mAuthCode}
    } catch (error) {
        callResponse.responseData = [];
        callResponse.responseAnyError = true;
        callResponse.responseApiKey = "";
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            callResponse.responseCode = parseInt(error.response.status);
            callResponse.responseMessage = error.response.statusText;
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            callResponse.responseCode = -100;
            callResponse.responseMessage = "Nessuna risposta dal server.";
        } else {
            // Something happened in setting up the request that triggered an Error
            callResponse.responseCode = -200;
            callResponse.responseMessage = "Errore di configurazione della chiamata.";
        }
    }
    finally {
        let ret = alertContext.endServiceCall(reqId, callResponse);
        callResponse.responseData = ret.responseData;
    }

    return callResponse;

}

class WebAPICallResponse {

    constructor() {
        this.responseData = [];
        this.responseCode = 0;
        this.responseMessage = "";
        this.responseAnyError = false;
        this.responseApiKey = false;
        this.responseDataPagesCount = 0;
        this.responseDataCurrentPage = 0;
        this.responseDataPageSize = 0;
        this.responseDataRowsCount = 0;
        this.responseAuthCode = "";
    }

}

export { DoWebApiCall };