import {Grid, useTheme} from "@mui/material";
import React, {useContext, useState} from "react";
import {useForm} from "react-hook-form";
import {CustomAlertContext} from "../../context/AlertContext";
import {ManagementContext} from "../../context/ManagementContext";
import {FormActionBar} from "../Form/FormActionBar";
import {FormTextBox} from "../Form/FormTextBox";
import {CircularWaiting} from "../Waitings/CircularWaiting";

const SmsComposer = ({
                         smsMessage,
                         closeSmsComposer
                     }) => {

    const managementContext = useContext(ManagementContext);
    const alertContext = useContext(CustomAlertContext);

    const theme = useTheme();

    const [record, setRecord] = useState(smsMessage);

    const {
        watch, register, control, trigger, formState: {errors}, handleSubmit, reset, getValues, setValue
    } = useForm({defaultValues: record}); //mode: 'onSubmit'

    const sendSmsCancel = (data) => {
        //niente
    }

    const sendSmsConfirm = (data) => {
        managementContext.sendSmsItem(data).then((res)=>{
            closeSmsComposer();
        })
    }

    const onSubmit = (data) => {
        //data
        alertContext.showConfirmDialog(
            "Conferma invio SMS",
            "Stai per inviare l'SMS sei sicuro?",
            sendSmsCancel,
            ()=>{ sendSmsConfirm(data);}
        );

    }

    const onError = (errors, e) => {
        console.log(errors, e);
    }

    if (smsMessage === null) return <CircularWaiting/>

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid
                    container
                    spacing={theme.spacing(1)}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                >
                    <Grid item xs={12} md={4}>
                        <FormTextBox
                            label="Numero Destinatario"
                            control={control}
                            record={record}
                            register={register}
                            field="mTargetPhone"
                            required={true}
                            maxLength={50}
                            disabled={false}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormTextBox
                            label="Nome Destinatario"
                            control={control}
                            record={record}
                            register={register}
                            field="mNome"
                            required={true}
                            maxLength={50}
                            disabled={false}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormTextBox
                            label="Cognome Destinatario"
                            control={control}
                            record={record}
                            register={register}
                            field="mCognome"
                            required={true}
                            maxLength={50}
                            disabled={false}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormTextBox
                            label="Messaggio"
                            control={control}
                            record={record}
                            register={register}
                            field="mMessageText"
                            required={true}
                            disabled={false}
                            multiline={true}
                            lines={3}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormActionBar submitLabel={"Invia sms"} cancelAction={closeSmsComposer}/>
                    </Grid>
                </Grid>

            </form>
        </>
    )
}

export default (SmsComposer);