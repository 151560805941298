import {Card, CardContent, CircularProgress, Grid, useTheme} from "@mui/material";
import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import {ContainerTitle} from "../../../components/Layout/ContainerTitle";
import {AccountingContext} from "../../../context/AccountingContext";
import {loggedClassNames} from "../../../theme/Styles";
import CreateInvoiceFromVisitLines from "./components/CreateInvoiceFromVisitLines";
import {CreditDocumentDetails} from "./components/CreditDocumentDetails";
import {CreditDocumentPreview} from "./components/CreditDocumentPreview";
import {Documents} from "./components/Documents";
import {InvoiceDetails} from "./components/InvoiceDetails";
import {InvoiceEdit} from "./components/InvoiceEdit";
import {InvoicePreview} from "./components/InvoicePreview";
import AccountingReports from "./components/AccountingReports";
import {isDataLoading, isDataNull} from "../../../common";
import Budget from "./components/Budget";
import CompanyServices from "./components/CompanyServices";
import {CreateInvoiceFromServices} from "./components/CreateInvoiceFromServices";


const accountingRoutes = [
    {path: '/Accounting/Reports', component: <AccountingReports/>, exact: true},
    {path: '/Accounting/Budget', component: <Budget/>, exact: true},
    {path: '/Accounting/Services', component: <CompanyServices/>, exact: true},
    {path: '/Accounting/CreateInvoiceFromServices', component: <CreateInvoiceFromServices/>, exact: true}
]
const LocationRenderer = () => {

    const location = useLocation();

    const accountingContext = useContext(AccountingContext);

    const exactElement = accountingRoutes.find(l => l.exact === true && location.pathname === l.path);

    if (exactElement) return exactElement.component;

    const partialElement = accountingRoutes.find(l => l.exact === false && location.pathname.startsWith(l.path));

    if (partialElement) return partialElement.component;

    switch (accountingContext.accountingMode) {
        case 0:
            return <Documents/>;
            break;
        case 1:
            return <CreateInvoiceFromVisitLines/>;
            break;
        case 2:
            return <Documents/>;
            break;
        case 3:
            return <InvoiceEdit/>;
            break;
        case 4:
            return <InvoiceDetails/>;
            break;
        case 5:
            return <Documents/>;
            break;
        case 6:
            return <CreditDocumentDetails/>;
            break;
        default:
            return <></>
    }

}

const AccountingContainer = (props) => {

    const accountingContext = useContext(AccountingContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [documentID, setDocumentID] = useState(-1);

    const mode = accountingContext.accountingMode;

    useEffect(() => {
        let docID = -1;
        if (mode === 2) {
            docID = (accountingContext.accountingState.invoice) ? (accountingContext.accountingState.invoice.mInvoiceID ?? -1) : -1;
        } else if (mode === 5) {
            docID = (accountingContext.accountingState.creditDocument) ? (accountingContext.accountingState.creditDocument.mInvoiceID ?? -1) : -1;
        }
        setDocumentID(docID);
    }, [accountingContext.accountingMode]);

    const setInvoiceToOpen = (pInvoiceID) => {
        setDocumentID(pInvoiceID);
        accountingContext.openAccounting({}, 0);
    }

    const setCreditDocumentToOpen = (pInvoiceID) => {
        setDocumentID(pInvoiceID);
        accountingContext.openAccounting({}, 0);
    }

    return (
        <Card sx={loggedClasses.loggedContent}>
            <CardContent sx={loggedClasses.loggedCardContent}>
                <ContainerTitle
                    title={"Contabilità"}
                    onHomeClick={() => { accountingContext.openAccounting({}, 0)}}
                    key={"container-title-accounting"}
                />
                <LocationRenderer/>
                {
                    (mode ?? 0 === 2) ?  <InvoicePreview documentID={documentID} setDocumentID={setInvoiceToOpen}/> : <></>
                }
                {
                    (mode ?? 0 === 5) ? <CreditDocumentPreview documentID={documentID} setDocumentID={setCreditDocumentToOpen}/> : <></>
                }

            </CardContent>
        </Card>
    )
}
export default AccountingContainer;
