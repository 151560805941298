import {Grid, Typography, useTheme} from '@mui/material';

import '../../../../theme/agGridStyle.css';
import {AgGridReact} from 'ag-grid-react';
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {columnTypes, defaultColDef, isDataLoading, isDataNull} from "../../../../common";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridRowHeight, gridSizeStyle} from "../../../../theme/Styles";

const ExemptionTypes = () => {

    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();

    const [record, setRecord] = useState({});
    const [dataLoaded, setDataLoaded] =useState(false);

    const {
        register,
        control,
        handleSubmit,
        reset
    } = useForm({defaultValues: record});

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(registriesContext.exemptionTypes)) registriesContext.fetchExemptionTypes().then((res) => {
            setDataLoaded(true);
        });
    }, [registriesContext.exemptionTypes]);


    const onGridReady = (params) => {
        // setAgGridApi(params.api);
        // params.api.sizeColumnsToFit();
    }

    const [columns, setColumns] = useState([
        {headerName: "Descrizione", field: "mDESC_TIPO"},
        {headerName: "Codice", field: "mCODICE_TIPO"}
    ]);

    if (isDataNull(registriesContext.exemptionTypes) || isDataLoading(registriesContext.exemptionTypes)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Tipi esenzione</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{pb: theme.spacing(2)}}>
                        <Grid item xs={12}>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={registriesContext.exemptionTypes}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            onGridReady={onGridReady}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
        </>
    )

}

export default ExemptionTypes;