import React from "react";
import {Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {formClassNames, loggedClassNames} from "../../theme/Styles";

export const CustomErrorPopup = (props) => {

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const handleCancel = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.handleCancel();
        props.handleHide(props.requestId);
    };

    const handleConfirm = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.handleHide(props.requestId);
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleCancel}
            aria-labelledby="alert-popup-title"
            aria-describedby="alert-popup-description"
            key={props.requestId}
        >
            <DialogTitle id="alert-popup-title" sx={loggedClasses.customConfirmDialogTitle}>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-popup-description" sx={loggedClasses.customConfirmDialogMessage}>
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} sx={{ height: '55px !important'}} autoFocus> OK </Button>
            </DialogActions>
        </Dialog>)
}