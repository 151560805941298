import {Divider, Grid, Typography, useTheme} from "@mui/material";
import React, {useContext, useState} from "react";
import Chart from "react-apexcharts";
import GaugeChart from 'react-gauge-chart';
import {ManagementContext} from "../../context/ManagementContext";
import {gridChartSizeStyle, gridRowHeight, loggedClassNames} from "../../theme/Styles";
import {AgGridReact} from "ag-grid-react";
import {columnTypes, defaultColDef, JSONClone} from "../../common";
import AnalyticCard from "../Dashboard/AnalyticCard";
import {CircularWaiting} from "../Waitings/CircularWaiting";
import {IconLibraryItem} from "../Icons/IconLibraryItem";

const DashboardChart = ({
                            chartObject
                        }) => {

    const managementContext = useContext(ManagementContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const customColors = [
        theme.palette.primary.dark,
        theme.palette.secondary.dark,
        theme.palette.success.dark,
        theme.palette.warning.dark,
        theme.palette.text.primary
    ]

    let dataSeries = [];
    let dataCategories = [];
    let isMultiseries = false;
    let chartData = chartObject.mChartData;
    let chartType = (chartObject.mChartOutputType ?? "");
    let chartColumns = chartObject.mChartColumns ?? [];
    let plotOptions = {};
    let extraOptions = {};
    let emptyGaugeSerie = {
        name: "",
        category: "",
        prevCategory: "-",
        data: {}
    }
    let emptyGaugeValues = {
        percValue: 0,
        gaugeValue: 0,
        realValue: 0,
        referValue: 0
    }
    const titleStyle = {
        fontSize: theme.typography.fontSize,
        fontWeight: 'bold',
        fontFamily: theme.typography.fontFamily,
    }
    let legendLabel = (chartObject.mLegendLabel ?? "");

    if (chartData && (chartData !== "loading") && (chartType === "bar")) {

        if (chartData.mZValues.length !== 0) isMultiseries = true;
        if (isMultiseries) {
            let xValue = null;
            let yFound = false;
            for (let iDataRow = 0; iDataRow < chartData.mXValues.length; iDataRow += 1) {
                xValue = chartData.mXValues[iDataRow];
                if (dataCategories.indexOf(xValue) === -1) dataCategories.push(xValue);
                yFound = dataSeries.find(cat => cat.name === chartData.mZValues[iDataRow]);
                if (!yFound) {
                    dataSeries.push({name: chartData.mZValues[iDataRow], data: [chartData.mYValues[iDataRow]]})
                } else {
                    yFound.data.push(chartData.mYValues[iDataRow]);
                }
            }
        } else {
            dataSeries.push({name: chartObject.mChartDescription, data: chartData.mYValues});
            dataCategories = chartData.mXValues;
        }

        plotOptions = {
            bar: {
                dataLabels: {
                    position: 'top',
                    style: {
                        ...titleStyle
                    }
                }
            }
        }

    } else if (chartData && (chartData !== "loading") && (chartType === "line")) {

        let xField = chartObject.mXField;

        chartData = chartData.sort((a, b) => (a[xField] > b[xField]) ? 1 : -1);

        for (let iSerie = 0; iSerie < chartObject.mSeries.length; iSerie += 1) {
            dataSeries.push({name: chartObject.mSeriesLabels[iSerie], data: []})
        }

        for (let iDataRow = 0; iDataRow < chartData.length; iDataRow += 1) {

            let xValue = chartData[iDataRow][xField];

            for (let iSerie = 0; iSerie < chartObject.mSeries.length; iSerie += 1) {
                let yField = chartObject.mSeries[iSerie];
                let yFound = dataSeries.find(cat => cat.name === chartObject.mSeriesLabels[iSerie]);
                if (yFound) {
                    yFound.data.push(chartData[iDataRow][yField]);
                }
            }

            dataCategories.push(xValue);

        }

        extraOptions = {
            stroke: {
                curve: 'straight', // smooth
            },
            markers: {
                size: 3,
            }
        }

    } else if (chartData && (chartData !== "loading") && (chartType === "gauge")) {

        let xField = chartObject.mXField;

        let rowsToSkip = chartObject.mRowsToSkip ?? 1;

        let maxRowsIndex = Math.min(1, chartData.length);

        chartData = chartData.sort((a, b) => (a[xField] < b[xField]) ? 1 : -1);

        for (let iDataRow = 0; iDataRow < maxRowsIndex; iDataRow += 1) {

            let xValue = chartData[iDataRow][xField] ?? iDataRow;

            for (let iSerie = 0; iSerie < chartObject.mSeries.length; iSerie += 1) {
                const jsonSerie = JSONClone(emptyGaugeSerie);
                jsonSerie.name = chartObject.mSeriesLabels[iSerie];
                jsonSerie.category = xValue;
                if (iDataRow < (chartData.length - 1)) {
                    jsonSerie.prevCategory = chartData[iDataRow + 1][xField] ?? iDataRow + 1;
                }
                dataSeries.push(jsonSerie)
            }

            dataCategories.push(xValue);
        }

        for (let iDataRow = 0; iDataRow < maxRowsIndex; iDataRow += 1) {

            let xValue = chartData[iDataRow][xField] ?? iDataRow;

            for (let iSerie = 0; iSerie < chartObject.mSeries.length; iSerie += 1) {
                let yField = chartObject.mSeries[iSerie];
                let yValue = chartData[iDataRow][yField];
                let percValue = null;
                let gauValue = null;
                let referValue = null;
                if (iDataRow + rowsToSkip < chartData.length) {
                    referValue = chartData[iDataRow + rowsToSkip][yField];
                    percValue = (referValue > 0) ? (yValue / (referValue / 100)) / 100 : 0;
                    gauValue = (referValue > 0) ? (yValue / (referValue * 2 / 100)) / 100 : 0;
                }
                let yFound = dataSeries.find(ds => ds.name === chartObject.mSeriesLabels[iSerie] && ds.category === xValue);
                if (yFound) {
                    let gaugeValue = JSONClone(emptyGaugeValues);
                    gaugeValue.percValue = percValue;
                    gaugeValue.gaugeValue = gauValue;
                    gaugeValue.realValue = yValue;
                    gaugeValue.referValue = referValue;
                    gaugeValue.diffValue = yValue - referValue;
                    yFound.data = JSONClone(gaugeValue);
                }
            }
        }

    }

    const chartOptions = {
        ...extraOptions,
        plotOptions,
        dataLabels: {
            background: {
                enabled: true,
                foreColor: customColors[4],
                borderRadius: 2,
                padding: 4,
                opacity: 0.9,
                borderWidth: 1,
                borderColor: customColors[0]
            },
        },
        colors: customColors,
        xaxis: {
            categories: dataCategories
        },
        title: {
            text: chartObject.mChartDescription,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                ...titleStyle
            },
        },
        chart: {
            fontSize: theme.typography.fontSize,
            fontWeight: 'bold',
            fontFamily: theme.typography.fontFamily,
        }
    }

    const tableGroups = [{
        headerName: chartObject.mChartDescription,
        children: chartColumns,
    }];

    const [columns, setColumns] = useState(tableGroups);

    if ((chartData ?? "") === "loading") return <CircularWaiting/>

    return (
        <>
            {
                ((chartType === "bar") || (chartType === "line")) ?
                    <div style={loggedClasses.chartContainerStyle}>
                        <Chart
                            type={chartObject.mChartOutputType}
                            series={dataSeries}
                            options={chartOptions}
                            height={'95%'}
                            width={'100%'}
                            style={loggedClasses.chartBodyStyle}
                        />
                    </div> :
                    (chartType === "table") ?
                        <div id="chartGrid" className="ag-theme-alpine"
                             style={{...gridChartSizeStyle, textAlign: "left", ...loggedClasses.chartBodyStyle}}>
                            {/*{chartObject.mChartDescription}*/}
                            <AgGridReact
                                rowData={chartData}
                                defaultColDef={defaultColDef}
                                columnDefs={columns}
                                columnTypes={columnTypes}
                                suppressMovableColumns={true}
                                suppressCellFocus={true}
                                rowSelection={'single'}
                                pagination={false}
                                getRowHeight={(params) => gridRowHeight}
                            >
                            </AgGridReact>
                        </div>
                        :
                        (chartType === "gauge") ?
                            <>
                                {
                                    dataCategories.map((dc) => {
                                        return dataSeries.filter(series => series.category === dc).map((ds, index) => {
                                            return (
                                                <Grid id={`gaugeDiv${index}`} container direction={"row"} spacing={theme.spacing(0)} sx={{...loggedClasses.chartBodyStyle}}>
                                                    <Grid item xs={12}>
                                                        <Typography sx={titleStyle}>
                                                            &nbsp; {ds.name + " " + ds.category + " su anno precedente"}
                                                        </Typography>
                                                    </Grid>
                                                    {
                                                        (ds.data.gaugeValue) ?
                                                            <>
                                                                <Grid item xs={3}>

                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <GaugeChart
                                                                        id={`gaugeChart-${ds.name}-${index}`}
                                                                        nrOfLevels={20}
                                                                        colors={[
                                                                            theme.palette.error.light,
                                                                            theme.palette.warning.light,
                                                                            theme.palette.success.light
                                                                        ]}
                                                                        arcsLength={[0.25, 0.25, 0.5]}
                                                                        percent={ds.data.gaugeValue}
                                                                        cornerRadius={2}
                                                                        style={{
                                                                            height: "150px"
                                                                        }}
                                                                        textColor={theme.palette.common.black}
                                                                        hideText
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3}>

                                                                </Grid>
                                                                <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                                                                    <Typography sx={titleStyle}>
                                                                        {legendLabel}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                                                                    <Typography sx={{color: theme.palette.error.light}}>
                                                                        <IconLibraryItem name={"square"} iconSize={"small"}/>
                                                                    </Typography>
                                                                    <Typography>0-50%</Typography>
                                                                </Grid>
                                                                <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                                                                    <Typography sx={{color: theme.palette.warning.light}}>
                                                                        <IconLibraryItem name={"square"} iconSize={"small"}/>
                                                                    </Typography>
                                                                    <Typography>56-100%</Typography>
                                                                </Grid>
                                                                <Grid item xs={4} display={"flex"} justifyContent={"center"}>
                                                                    <Typography sx={{color: theme.palette.success.light}}>
                                                                        <IconLibraryItem name={"square"} iconSize={"small"}/>
                                                                    </Typography>
                                                                    <Typography>
                                                                        101-200%
                                                                    </Typography>
                                                                </Grid>
                                                            </>
                                                            :
                                                            <Grid item xs={12}>
                                                                <Typography>
                                                                    Dati non disponibili.
                                                                </Typography>
                                                            </Grid>
                                                    }
                                                    <Grid item xs={12}>
                                                        <Divider variant={"fullWidth"}/>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <AnalyticCard
                                                            title={ds.category}
                                                            count={ds.data.realValue}
                                                            percentage={ds.data.percValue}
                                                            extra={ds.data.diffValue}
                                                            isLoss={ds.data.diffValue < 0}
                                                            symbol={"€"}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    })
                                }
                            </>
                            :
                            <></>
            }
        </>
    )
        ;
}

export default DashboardChart;
