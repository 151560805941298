import {Card, CardContent, useTheme} from "@mui/material";
import React, {useState} from 'react';
import {ContainerTitle} from "../../../components/Layout/ContainerTitle";
import {loggedClassNames} from "../../../theme/Styles";
import Profile from "./components/Profile";
import {useLocation} from "react-router-dom";

const registriesRoutes = [
    {path: '/User/Profile', component: <Profile/>, exact: false}
]

const LocationRenderer = () => {

    const location = useLocation();

    const exactElement = registriesRoutes.find(l => l.exact === true && location.pathname === l.path);

    if (exactElement) return exactElement.component;

    const partialElement = registriesRoutes.find(l => l.exact === false && location.pathname.startsWith(l.path));

    return (partialElement) ? partialElement.component : <></>;

}

const UserContainer = (props) => {

    const [authContext, setAuthContext] = useState(props.AuthContext);
    const [alertContext, setAlertContext] = useState(props.AlertContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    return (
        <>
            <Card sx={loggedClasses.loggedContent}>
                <CardContent sx={loggedClasses.loggedCardContent}>
                    <ContainerTitle
                        title={"Utente"}
                        onHomeClick={() => {}}
                        key={"container-title-medical-user"}
                    />
                    <LocationRenderer/>
                </CardContent>
            </Card>
        </>
    )
}
export default UserContainer;
