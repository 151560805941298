import {Stack, useTheme} from "@mui/material";
import React from "react";
import {ActionButton} from "../Buttons/ActionButton";
import {ToolButton} from "../Buttons/ToolButton";

export const AddButton = ({
                              addAction,
                              label,
                              ...props
                          }) => {

    const buttonClicked = () => {
        addAction();
    };

    return (
        <ActionButton
            color="primary"
            variant="contained"
            action={buttonClicked}
            size="medium"
            icon="new"
            iconSize="small"
            tooltip={label ?? "Nuovo"}
            label={label ?? "Nuovo"}
            {...props}
        />
    );
};

export const EditButton = ({editAction, editLabel, editTooltip, ...props}) => {

    const label = editLabel ?? "Modifica";
    const tooltip = editTooltip ?? "Modifica";

    const buttonClicked = () => {
        editAction();
    };

    return (
        <ToolButton
            color="primary"
            variant="contained"
            action={buttonClicked}
            size="medium"
            icon="edit"
            iconSize="small"
            tooltip={tooltip}
            label={label}
            {...props}
        />
    );
};

export const DeleteButton = ({deleteAction, ...props}) => {

    const buttonClicked = () => {
        deleteAction();
    };

    return (
        <ToolButton
            color="error"
            variant="contained"
            action={buttonClicked}
            size="medium"
            icon="delete"
            iconSize="small"
            tooltip="Elimina"
            label="Elimina"
            {...props}
        />
    );
};

export const DetailsButton = ({detailsAction, detailsTooltip, ...props}) => {

    const tooltip = detailsTooltip ?? "Dettaglio";

    const buttonClicked = () => {
        detailsAction();
    };

    return (
        <ToolButton
            color="secondary"
            variant="contained"
            action={buttonClicked}
            size="medium"
            icon="detail"
            iconSize="small"
            tooltip={tooltip}
            {...props}
        />
    );
};

export const ViewButton = ({viewAction, ...props}) => {

    const buttonClicked = () => {
        viewAction();
    };

    return (
        <ToolButton
            color="info"
            variant="contained"
            action={buttonClicked}
            size="medium"
            icon="view"
            iconSize="small"
            tooltip="Visualizza"
            {...props}
        />
    );
};

export const ExtraButton = ({
                                extraAction,
                                extraLabel,
                                ...props
                            }) => {

    extraLabel = (extraLabel ?? "Record collegati");

    const buttonClicked = () => {
        extraAction();
    };

    return (
        <ToolButton
            color="info"
            variant="contained"
            action={buttonClicked}
            size="medium"
            icon="link"
            iconSize="small"
            tooltip={extraLabel}
            {...props}
        />
    );
};

export const CustomButton = ({
                                 ref,
                                 customAction,
                                 customDisabled,
                                 customIcon,
                                 customTooltip,
                                 ...props
                             }) => {

    ref = React.createRef();

    const buttonClicked = () => {
        customAction();
    };

    return (
        <ToolButton
            color="info"
            variant="contained"
            action={buttonClicked}
            size="small"
            icon={customIcon}
            iconSize="small"
            tooltip={customTooltip}
            disabled={customDisabled}
            {...props}
        />
    );
};

export const CustomButtonWithRef = ({
                                        ref,
                                        customAction,
                                        customDisabled,
                                        customIcon,
                                        customTooltip,
                                        ...props
                                    }) => {

    ref = React.createRef();

    const buttonClicked = () => {
        customAction();
    };

    return (
        <ToolButton
            color="info"
            variant="contained"
            action={buttonClicked}
            size="small"
            icon={customIcon}
            iconSize="small"
            tooltip={customTooltip}
            disabled={customDisabled}
            {...props}
        />
    );
};

export const ActionsToolBar = ({
                                   hasEdit, editAction, editDisabled, editLabel, editTooltip,
                                   hasDelete, deleteAction, deleteDisabled,
                                   hasDetails, detailsAction, detailsDisabled, detailsTooltip,
                                   hasView, viewAction, viewDisabled,
                                   hasExtra, extraAction, extraDisabled, extraLabel,
                                   hasPDF, pdfAction, pdfDisabled,
                                   customActions,
                                   ...props
                               }) => {

    const theme = useTheme();
    customActions = customActions ?? [];

    return (
        <Stack direction={"row"} spacing={theme.spacing(1)} sx={{pt: theme.spacing(1)}}>
            {(hasEdit) && <EditButton editAction={editAction} editLabel={editLabel} editTooltip={editTooltip}
                                      disabled={editDisabled ?? false} {...props} />}
            {(hasDelete) && <DeleteButton deleteAction={deleteAction} disabled={deleteDisabled ?? false} {...props} />}
            {(hasDetails) && <DetailsButton detailsAction={detailsAction} detailsTooltip={detailsTooltip}
                                            disabled={detailsDisabled ?? false} {...props} />}
            {(hasExtra) && <ExtraButton extraAction={extraAction} extraLabel={extraLabel}
                                        disabled={extraDisabled ?? false} {...props} />}
            {(hasView) && <ViewButton viewAction={viewAction} disabled={viewDisabled ?? false} {...props} />}
            {(hasPDF) && <CustomButton customAction={pdfAction} disabled={pdfDisabled ?? false} customIcon={"pdf"}
                                       customTooltip={"Visualizza PDF"} {...props} />}
            {
                customActions.map((item) => {
                    return <CustomButton customAction={item.action} disabled={item.disabled ?? false}
                                         customIcon={item.icon} customTooltip={item.tooltip} {...props} />
                })
            }
        </Stack>
    )

}