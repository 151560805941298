import {Stack} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import React, {useContext, useEffect, useState} from 'react';
import {isDataLoading, isDataNull} from "../../common";
import {AuthContext} from "../../context/AuthContext";
import {RegistriesContext} from "../../context/RegistriesContext";
import {LinearWaiting} from "../Waitings/LinearWaiting";
import MainNavGroup from "./MainNavGroup";

const MainNav = () => {

    const authContext = useContext(AuthContext);
    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();

    const [itemToSearch, setItemToSearch] = useState('');

    let functions_list = authContext.getAllMenuItems().sort((a, b) => (a.mFunctionDescription < b.mFunctionDescription) ? 1 : -1);

    useEffect(() => {
        if (isDataNull(registriesContext.domains)) registriesContext.fetchDomains();
    }, [registriesContext.domains])

    const getFunctionList = (domain) => {

        let filteredFunctions = [];

        if (itemToSearch !== '' && itemToSearch.length >= 3) {
            filteredFunctions = functions_list.filter(
                f => (
                    (
                        f.mFunctionDescription.toLowerCase() === itemToSearch.toLowerCase() &&
                        f.mDomain.mDomainName.toLowerCase() === domain.toLowerCase() &&
                        f.mIsEnabled
                    )
                    ||
                    (
                        f.mDomain.mDomainName.toLowerCase() === itemToSearch.toLowerCase() &&
                        f.mIsEnabled
                    )

                )
            ).sort((a, b) => (a.mFunctionDescription > b.mFunctionDescription) ? 1 : -1);
        } else {
            filteredFunctions = functions_list.filter(
                f => (
                    (
                        f.mDomain.mDomainName.toLowerCase() === domain.toLowerCase() && f.mIsEnabled
                    )
                )
            ).sort((a, b) => (a.mFunctionName > b.mFunctionName) ? 1 : -1);
        }

        return filteredFunctions;

    }

    if (isDataNull(registriesContext.domains) || isDataLoading(registriesContext.domains)) return <LinearWaiting/>

    return (
        <>
            <Stack
                direction={{ md: 'column', lg: 'row' }}
                spacing={theme.spacing(1)}
                sx={{ml: theme.spacing(2)}}
            >
                {
                    registriesContext.domains.map((item) => (
                        <MainNavGroup menus={getFunctionList(item.mDomainName)} domain={item} key={'mainNavGroup-' + item.mDomainID}/>
                    ))
                }
            </Stack>
        </>
    );
}

export default MainNav;