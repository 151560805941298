import React from 'react';
import {Button, styled, Tooltip, useTheme} from "@mui/material";
import {ButtonIcon} from "../Icons/ButtonIcon";
import {StyledCardHeader} from "./SyledCardHeader";

export const CustomTitleButton = ({
                                      customAction,
                                      customDisabled,
                                      customIcon,
                                      customTooltip,
                                      ...props
                                  }) => {

    let isDisabled = customDisabled ?? false;
    let sTooltip = customTooltip ?? "";
    let hasTooltip = !isDisabled && sTooltip !== "";

    const theme = useTheme();

    const StyledTitleButton = styled(Button)({
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
        size: 'medium',
        boxShadow: 'none !important'
    });


    const buttonClicked = () => {
        if (customAction) customAction();
    };

    const getButton = ()=> {
        return (
            <StyledTitleButton
                onClick={buttonClicked}
                disabled={customDisabled}
                {...props}
            >
                <ButtonIcon icon={customIcon} iconSize={"large"}/>
            </StyledTitleButton>
        );
    }

    return (
        !hasTooltip ?
            <>{getButton()}</>
            :
            <Tooltip title={sTooltip}>
                <span>{getButton()}</span>
            </Tooltip>
    )
};

const ContainerTitle = ({
                            title,
                            hasHome,
                            onHomeClick,
                            customActions,
                            ...props
                        }) => {

    const theme = useTheme();
    const bHome = hasHome ?? true;
    const arrCustomActions = customActions ?? [];


    return (
        <StyledCardHeader>
            {
                bHome ?
                    <CustomTitleButton customAction={onHomeClick} disabled={false} customIcon={"home"} customTooltip={"Home"} {...props} />
                    :
                    <></>
            }
            {
                arrCustomActions.map((item) => {
                    return <CustomTitleButton customAction={item.action} disabled={item.disabled ?? false} customIcon={item.icon} customTooltip={item.tooltip} {...props} />
                })
            }
            {title}
        </StyledCardHeader>
    )
}

export {ContainerTitle};