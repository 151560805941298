import {CardActions, CardContent, Grid, Typography, useTheme} from "@mui/material";
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {MedicalReportingContext} from "../../context/MedicalReportingContext";
import {loggedClassNames, userPdfDropZoneStyles} from "../../theme/Styles";
import {ActionButton} from "../Buttons/ActionButton";
import {CardModal} from "../Layout/CardModal";
import {PDFViewer} from "../PDF/PDFViewer";

const UploadMedicalReportPDFModal = ({
                                         toggle,
                                         modalOpen,
                                         mRefertoID,
                                         setIsUploaded,
                                         uploadMessage,
                                         setUploadMessage
                                     }) => {

    const medicalReportingContext = useContext(MedicalReportingContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [isUploading, setIsUploading] = useState(false);
    const [selectedFileContent, setSelectedFileContent] = useState(undefined);

    const onDrop = useCallback(acceptedFiles => {

        acceptedFiles.forEach((file) => {

            const reader = new FileReader()

            reader.onabort = () => setUploadMessage('La lettura del file è stata interrotta.')
            reader.onerror = () => setUploadMessage('La lettura del file è fallita.')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                setSelectedFileContent(binaryStr);
            }
            reader.readAsDataURL(file);
        })
    }, []);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isFocused,
        isDragAccept,
        isDragReject,
        inputRef
    } = useDropzone({
        accept: ["application/pdf"],
        maxSize: 100000000, // 100 mb
        multiple: false,
        onDrop
    })

    const dropZoneStyles = userPdfDropZoneStyles(theme);

    const style = useMemo(() => ({
        ...dropZoneStyles.userPdfDropzoneBase,
        ...(isDragActive ? dropZoneStyles.userPdfDropzoneActive : {}),
        ...(isFocused ? dropZoneStyles.userPdfDropzoneFocused : {}),
        ...(isDragAccept ? dropZoneStyles.userPdfDropzoneAccepted : {}),
        ...(isDragReject ? dropZoneStyles.userPdfDropzoneRejected : {})
    }), [isDragActive, isFocused, isDragAccept, isDragReject]);

    const getPDFPreview = () => {
        if (selectedFileContent) {
            return (
                <PDFViewer dataType={"application/pdf"} dataContent={selectedFileContent}/>
            )
        } else {
            return <></>;
        }
    }

    const resetUpload = () => {
        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length)
        inputRef.current.value = '';
        setSelectedFileContent(undefined);
        toggle();
    }

    const savePDF = () => {
        //data:mimeType;base64,
        const base64Position = selectedFileContent.indexOf('base64,');
        const base64Length = 'base64,'.length;
        const dataType = selectedFileContent.substring(0, base64Position + base64Length);
        const selFile = selectedFileContent.replace(dataType, '');
        setIsUploading(true);
        medicalReportingContext.updateMedicalReportPDF(mRefertoID, selFile).then((res) => {
            setIsUploading(false);
            setIsUploaded(true);
            setUploadMessage("Upload avvenuto con successo");
            acceptedFiles.length = 0
            acceptedFiles.splice(0, acceptedFiles.length)
            if (inputRef.current) inputRef.current.value = '';
            setSelectedFileContent(undefined);
            toggle();
        })
    }

    const onClose = (event, reason) => {
        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length)
        inputRef.current.value = '';
        setSelectedFileContent(undefined);
        toggle();
    }

    return (
        <CardModal
            modalOpen={modalOpen}
            onClose={onClose}
            title={"Upload referto firmato"}
        >
            <CardContent>
                <Grid
                    container
                    direction={"column"}
                    alignItems={"center"}
                    spacing={theme.spacing(8)}
                >
                    <Grid item xs={12}>
                        <div  {...getRootProps({style})}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <Typography component={"p"}>Trascina il file qui ...</Typography> :
                                    <Typography component={"p"}>Trascina il file qui, o fai click per selezionare un file</Typography>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {getPDFPreview()}
                    </Grid>
                    {
                        uploadMessage !== '' && (
                            <Grid item xs={12}>
                                <Typography color={"error"}>{uploadMessage}</Typography>
                            </Grid>
                        )
                    }
                </Grid>
            </CardContent>
            <CardActions>
                <ActionButton
                    color="secondary"
                    variant="contained"
                    action={resetUpload}
                    icon={isUploading ? "pending" : "cancel"}
                    iconSize={"small"}
                    label={"Annulla"}
                    size={"small"}
                    tooltip={"Annulla"}
                    disabled={!selectedFileContent || isUploading}
                />
                <ActionButton
                    color="primary"
                    variant="contained"
                    action={savePDF}
                    icon={isUploading ? "pending" : "upload"}
                    iconSize={"small"}
                    label={"Upload"}
                    size={"small"}
                    tooltip={"Upload"}
                    disabled={!selectedFileContent || isUploading}
                />
            </CardActions>
        </CardModal>
    )
        ;

};


export default UploadMedicalReportPDFModal;