import {Avatar, Grid, Typography, useTheme} from "@mui/material";
import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {ActionButton} from "../Buttons/ActionButton";
import {CustomAlertContext} from "../../context/AlertContext";
import {BookingContext} from "../../context/BookingContext";
import {RegistriesContext} from "../../context/RegistriesContext";
import {loggedClassNames} from "../../theme/Styles";
import {CommonContext} from "../../context/CommonContext";

export const IntervalAvailabilities = ({
                                           interval,
                                           close
                                       }) => {

    const bookingContext = useContext(BookingContext);
    const commonContext = useContext(CommonContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    let navigate = useNavigate();

    const availableSlotClick = (slot, interval) => {
        let newBookingItem = {};
        newBookingItem.mDataAppuntamento = slot.mSlotBeginDateTime;
        newBookingItem.mBranch = interval.mBranca.mBrancaID;
        newBookingItem.mRoom = interval.mPlanning.mRoom.mRoomID;
        newBookingItem.mAssignedDoctor = interval.mDoctor;
        bookingContext.openBooking(newBookingItem, 6);
        navigate('/Booking/TakeBooking');
    }

    if (!interval) return <></>

    if (interval.mFreeSlots === 0) return (
        <Grid container spacing={theme.spacing(4)}>
            <Grid item xs={12}>
                <Typography>
                    Nessuna disponibilità per l'intervallo selezionato!
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ActionButton
                    color="primary"
                    variant="contained"
                    action={close}
                    size="large"
                    iconSize="medium"
                    tooltip="Chiudi"
                    label="Chiudi"
                />
            </Grid>
        </Grid>
    )

    let avails = interval.mIntervalSlots;
    let hours = [];
    avails.map(availability => {
        const hour = availability.mSlotBeginDateTime.format('HH');
        if (hours.indexOf(hour) === -1) hours.push(hour);
    });

    return (
        <Grid
            container
            spacing={theme.spacing(1)}
        >
            <Grid
                item
                container
                direction={"row"}
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                sx={{pt: theme.spacing(2), pb: theme.spacing(2)}}
                spacing={theme.spacing(2)}
            >
                <Grid item xs={4}>
                    <Typography variant={"h5"}>Stanza: {interval.mPlanning.mRoom.mRoomName}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"h5"}>Medico: {interval.mDoctor.mCompleteName}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant={"h5"}>Giorno: {interval.mIntervalDateTimeFrom}</Typography>
                </Grid>
            </Grid>
            <Grid
                item
                container
                direction={"row"}
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                sx={{pt: theme.spacing(2), pb: theme.spacing(2)}}
                spacing={theme.spacing(2)}
            >
                {
                    hours.map((hour) => {
                        return (
                            <Grid
                                item
                                container
                                display="flex"
                                direction="row"
                                justifyContent="left"
                                alignItems="center"
                                spacing={theme.spacing(0.5)}
                                sx={{
                                    ...loggedClasses.availabilityHour
                                }}
                            >
                                <Grid xs={12} md={1} pt={theme.spacing(2)} pb={theme.spacing(2)}>
                                    <Avatar variant={"square"} color={"primary"} sx={{width: 75, height: 75}}>
                                        <Typography variant={"h5"}>{hour}</Typography>
                                    </Avatar>
                                </Grid>
                                <Grid container
                                      item
                                      xs={12}
                                      md={11}
                                      display="flex"
                                      flexWrap="wrap"
                                      direction="row"
                                      justifyContent="left"
                                      alignItems="center"
                                      spacing={theme.spacing(0.5)}
                                >
                                    {
                                        avails
                                            .filter(availability => availability.mSlotBeginDateTime.format('HH') == hour)
                                            .map((availability, index) => {
                                                return (
                                                    <>
                                                        <Grid item xs={6} md={2}>
                                                            {
                                                                (availability.mSlotVisitlines.length > 0) ?
                                                                    <ActionButton
                                                                        color="secondary"
                                                                        variant={"contained"}
                                                                        size="medium"
                                                                        icon="calendar"
                                                                        iconSize="medium"
                                                                        tooltip="Prenota"
                                                                        label={commonContext.getSlotHourLabel(availability.mSlotBeginDateTime)}
                                                                        disabled={true}
                                                                        fullWidth={true}
                                                                    />
                                                                    :
                                                                    <ActionButton
                                                                        color="secondary"
                                                                        variant={"contained"}
                                                                        action={() => {
                                                                            availableSlotClick(availability, interval)
                                                                        }}
                                                                        size="medium"
                                                                        icon="calendar"
                                                                        iconSize="medium"
                                                                        tooltip="Prenota"
                                                                        label={commonContext.getSlotHourLabel(availability.mSlotBeginDateTime)}
                                                                        fullWidth={true}
                                                                    />
                                                            }
                                                        </Grid>
                                                    </>
                                                );
                                            })
                                    }
                                </Grid>
                            </Grid>
                        )

                    })
                }
            </Grid>
            <Grid item xs={12}>
                <ActionButton
                    color="primary"
                    variant="contained"
                    action={close}
                    size="large"
                    icon="close"
                    iconSize="medium"
                    tooltip="Chiudi"
                    label="Chiudi"
                />
            </Grid>
        </Grid>
    )
}