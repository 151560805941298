import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CardContent,
    Grid,
    Stack,
    Typography,
    useTheme
} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    booleanMatcher,
    booleanRendererWithBadge,
    columnTypes,
    defaultColDef,
    getCompleteName,
    isDataLoading,
    isDataNull,
    JSONClone
} from "../../../../common";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import '../../../../theme/agGridStyle.css';
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";
import {FormSwitch} from "../../../../components/Form/FormSwitch";
import {FormSubmitButton} from "../../../../components/Form/FormSubmitButton";
import {FormCheckBox} from "../../../../components/Form/FormCheckBox";
import {AuthContext} from "../../../../context/AuthContext";

const default_fees_record = {
    mUserID: 0,
    mType: "PERC",
    mValue: "0"
}

const default_doctor_fees_record = {
    mUserID: 0,
    mDefaultFeeType: "PERC",
    mDefaultFeeValue: "0"
}

const ExpandMoreIcon = () => {
    return null;
}

const Users = () => {

    const registriesContext = useContext(RegistriesContext);
    const authContext = useContext(AuthContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [record, setRecord] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [agGridApi, setAgGridApi] = useState(null);
    const [extraRecordId, setExtraRecordId] = useState(-1);
    const [extraRecord, setExtraRecord] = useState({});
    const [extraModalOpen, setExtraModalOpen] = useState(false);
    const [feeRecord, setFeeRecord] = useState(JSONClone(default_fees_record));
    const [isLoadingFees, setIsLoadingFees] = useState(false);
    const [doctorDefaultFeeRecord, setDoctorDefaultFeeRecord] = useState(JSONClone(default_doctor_fees_record));
    const [isSavingDefaultFeeRecord, setIsSavingDefaultFeeRecord] = useState(false);
    const [doctorFees, setDoctorFees] = useState(null);
    const [expanded, setExpanded] = useState("panel1");
    const [saveFeesDisabled, setSaveFeesDisabled] = useState(true);

    let activeRooms = (registriesContext.getActiveRooms() ?? []);

    const gridRef = useRef();

    const {
        register,
        control,
        handleSubmit,
        reset
    } = useForm({defaultValues: record});

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(registriesContext.users)) registriesContext.fetchUsers().then((res) => {
            setDataLoaded(true);
        })
    }, [registriesContext.users]);

    useEffect(() => {
        if (isDataNull(registriesContext.companies) && !isDataLoading(registriesContext.companies)) registriesContext.fetchCompanies();
        if (isDataNull(registriesContext.profiles) && !isDataLoading(registriesContext.profiles)) registriesContext.fetchProfiles().then((res) => {
        });
    }, []);

    useEffect(() => {
        if (isDataNull(authContext.loginStatusItemizedListValues) && !isDataLoading(authContext.loginStatusItemizedListValues)) authContext.fetchLoginStatusItemizedListValues();
    }, [authContext.loginStatusItemizedListValues]);

    //dettaglio

    const onSubmit = (data) => {
        setIsLoading(true);
        const body = {
            ...data,
            mDefaultRoom: (data.mDefaultRoom)  ?  { mRoomID: data.mDefaultRoom.mRoomID}: { mRoomID: 0 }
        };
        registriesContext.updateUser(body).then(res => {
            setIsLoading(false);
            setModalOpen(false);

        })
    }

    const onError = (errors, e) => {
        console.log(record);
        console.log(errors, e);
    }

    const onClose = (event, reason) => {
        setModalOpen(false);
    }

    //griglia

    const addAction = () => {
        registriesContext.fetchUser(0).then((res) => {
            setRecord(res);
            reset(res);
            setModalOpen(true);
        });
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            registriesContext.fetchUser(selectedRow.mUserID).then((res) => {
                setRecord(res);
                reset(res);
                setModalOpen(true);
            });
        };

        const deleteAction = () => {
            alert(selectedRow.mUserID);
        }

        const feesAction = () => {
            setExtraRecordId(selectedRow.mUserID);
            setIsLoadingFees(true);
            registriesContext.fetchUser(selectedRow.mUserID).then((res) => {
                setExtraRecord(res);
                const feeType = (res.mDefaultFeeType ?? "PERC");
                const newDoctorDefaultFeeRecord = {
                    mUserID: selectedRow.mUserID,
                    mDefaultFeeType: (feeType === "") ? "PERC" : feeType,
                    mDefaultFeeValue: (res.mDefaultFeeValue ?? 0).toString(),

                }
                setDoctorDefaultFeeRecord(newDoctorDefaultFeeRecord);
                resetDoctorFeeRecord(newDoctorDefaultFeeRecord)
            });
            registriesContext.fetchDoctorFees(selectedRow.mUserID).then((res) => {
                setIsLoadingFees(false);
                setDoctorFees(res);
                const newFeeRecord = {
                    ...default_fees_record,
                    mUserID: selectedRow.mUserID
                }
                setFeeRecord(newFeeRecord);
                resetFees(newFeeRecord);
                reset(JSONClone(default_fees_record));
                setExtraModalOpen(true);
            });

        }

        const customActions = [
            {
                action: feesAction,
                disabled: false,
                icon: "euro",
                tooltip: "Onorari",
            }
        ];


        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
                customActions={customActions}
            />
        )
    }

    const [columns, setColumns] = useState([
        {headerName: "Username", field: "mUserName"},
        {headerName: "Nome", field: "mFirstName"},
        {headerName: "Cognome", field: "mLastName"},
        {headerName: "Dipendente di", field: "mCompany.mCompanyName"},
        {headerName: "Lavora presso", field: "mWorkSite.mCompanyName"},
        {headerName: "Profilo", field: "mProfile.mProfileName"},
        {headerName: "Attivo", field: "mActive", cellRenderer: booleanRendererWithBadge, filterParams: { textMatcher: booleanMatcher  }},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    //onorari

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const onExtraClose = () => {
        setExtraModalOpen(false);
        setExtraRecordId(-1);
        setExtraRecord(null);
        setDoctorFees(null);
        setFeeRecord(JSONClone(default_fees_record));
        resetFees(JSONClone(default_fees_record));
        setDoctorDefaultFeeRecord(JSONClone(default_fees_record));
        resetDoctorFeeRecord(JSONClone(default_fees_record))
    }

    const userCompleteName = (extraRecord) ? getCompleteName(extraRecord.mFirstName, extraRecord.mLastName) : "";

    //onorari default

    const {
        register: registerDoctorFeeRecord,
        control: controlDoctorFeeRecord,
        handleSubmit: handleSubmitDoctorFeeRecord,
        getValues: getValuesDoctorFeeRecord,
        reset: resetDoctorFeeRecord
    } = useForm({defaultValues: doctorDefaultFeeRecord});

    const onSubmitDoctorFeeRecord = (data) => {
        data.mDefaultFeeValue = parseFloat(data.mDefaultFeeValue);
        setIsSavingDefaultFeeRecord(true);
        registriesContext.updateDefaultDoctorFee(data).then((res) => {
            setIsSavingDefaultFeeRecord(false);
            registriesContext.fetchUser(data.mUserID).then((res) => {
                setExtraRecord(res);
                const feeType = (res.mDefaultFeeType ?? "PERC");
                const newDoctorDefaultFeeRecord = {
                    mUserID: data.mUserID,
                    mDefaultFeeType: (feeType === "") ? "PERC" : feeType,
                    mDefaultFeeValue: (res.mDefaultFeeValue ?? 0).toString()
                }
                // setDoctorDefaultFeeRecord(newDoctorDefaultFeeRecord);
                // resetDoctorFeeRecord(newDoctorDefaultFeeRecord)
            });
        });

    }

    const onErrorDoctorFeeRecord = (errors, e) => {
        console.log(record);
        console.log(errors, e);
    }

    //onorari eccezioni

    const {
        register: registerFees,
        control: controlFees,
        handleSubmit: handleSubmitFees,
        reset: resetFees
    } = useForm({defaultValues: feeRecord});

    const getSelectedFees = () => {
        let selectedIds = gridRef.current.api.getSelectedNodes().map((node, index) => {
            return node.data;
        });
        return selectedIds ?? [];
    }

    const onSubmitFees = (data) => {
        const userID = feeRecord.mUserID
        let fees = {mUserID: userID};
        const selectedFees = getSelectedFees();
        const listOfFees = selectedFees.map(f => {
            return {
                mFeeID: (f.mFee) ? (f.mFee.mFeeID ?? 0) : 0,
                mPerformanceID: f.mPrestazioneID,
                mValue: parseFloat(data.mValue),
                mType: data.mType
            }
        });
        fees.mListOfFees = listOfFees;
        setIsLoadingFees(true);
        registriesContext.updateDoctorFees(fees).then((res) => {
            registriesContext.fetchDoctorFees(userID).then((res) => {
                setIsLoadingFees(false);
                setDoctorFees(res);
                agGridApi.deselectAll();
                const newFeeRecord = {
                    ...default_fees_record,
                    mUserID: userID
                }
                setFeeRecord(newFeeRecord);
                setExtraModalOpen(true);
            });
        });
    }

    const onErrorFees = (errors, e) => {
        console.log(record);
        console.log(errors, e);
    }

    const [feesColumns, setFeesColumns] = useState([
        {
            headerName: "Descrizione",
            field: "mDescrizione",
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            type: "flex4"
        },
        {headerName: "Modality", field: "mModality.mModalityDescription"},
        {headerName: "Branca", field: "mBranca.mBrancaName", type: "flex2"},
        {headerName: "Tipo", field: "mFee.mType", type: "flex1"},
        {headerName: "Valore", field: "mFee.mValue", type: "flex1"},
    ]);

    const onFeesSelectionChanged = () => {
        const selectedFees = getSelectedFees();
        setSaveFeesDisabled(selectedFees.length === 0);
    }

    if (isDataNull(registriesContext.companies) || isDataLoading(registriesContext.companies)) return <CircularWaiting/>
    if (isDataNull(registriesContext.profiles) || isDataLoading(registriesContext.profiles)) return <CircularWaiting/>
    if (isDataNull(registriesContext.users) || isDataLoading(registriesContext.users)) return <CircularWaiting/>
    if (isDataNull(authContext.loginStatusItemizedListValues) || isDataLoading(authContext.loginStatusItemizedListValues)) return <CircularWaiting/>

    let loginStatusItems = [];
    if (authContext.loginStatusItemizedListValues) {
        authContext.loginStatusItemizedListValues.map((item) => {
            const idValue = item.mItemizedListValueID;
            const labelValue = item.mValue;
            loginStatusItems.push({id: idValue, label: labelValue});
            return true;
        });
    }

    const isAdmin = authContext.isLoggedUserAdmin();

    if (isLoadingFees) return <CircularWaiting/>

    console.log('record ', record);

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Utenti</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{pb: theme.spacing(2)}}>
                        <Grid item xs={12}>
                            <Stack
                                direction={"row"}
                                justifyContent={"flex-end"}
                                spacing={theme.spacing(2)}
                                sx={{pt: theme.spacing(1)}}>
                                <AddButton addAction={addAction}/>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={registriesContext.users}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
            <CardModal
                modalOpen={modalOpen}
                onClose={onClose}
                title={"Dettaglio utente"}
                size={"small"}
                formFunctions={{submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError}}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        spacing={theme.spacing(2)}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12} lg={4}>
                            <FormTextBox
                                label="Username"
                                control={control}
                                record={record}
                                register={register}
                                field="mUserName"
                                required={true}
                                maxLength={100}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormTextBox
                                label="Nome"
                                control={control}
                                record={record}
                                register={register}
                                field="mFirstName"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormTextBox
                                label="Cognome"
                                control={control}
                                record={record}
                                register={register}
                                field="mLastName"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormTextBox
                                label="Cellulare"
                                control={control}
                                record={record}
                                register={register}
                                field="mMobilePhone"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormAutoComplete
                                label="Dipendente della società"
                                control={control}
                                record={record}
                                register={register}
                                field="mCompany"
                                joinField="mCompanyID"
                                values={registriesContext.companies}
                                idField="mCompanyID"
                                labelField="mCompanyName"
                                selectedValue="id"
                                required={false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormAutoComplete
                                label="Lavora presso"
                                control={control}
                                record={record}
                                register={register}
                                field="mWorkSite"
                                joinField="mCompanyID"
                                values={registriesContext.companies}
                                idField="mCompanyID"
                                labelField="mCompanyName"
                                selectedValue="id"
                                required={false}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormAutoComplete
                                label="Profilo"
                                control={control}
                                record={record}
                                register={register}
                                field="mProfile"
                                joinField="mProfileID"
                                values={registriesContext.profiles}
                                idField="mProfileID"
                                labelField="mProfileName"
                                selectedValue="id"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormTextBox
                                label="Sonic Username"
                                control={control}
                                record={record}
                                register={register}
                                field="mSonicUserName"
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormTextBox
                                label="Sonic Password"
                                control={control}
                                record={record}
                                register={register}
                                field="mSonicPassword"
                                maxLength={50}
                            />
                        </Grid>
                        {
                            (isAdmin) &&
                            <Grid item xs={12} lg={4}>
                                <FormAutoComplete
                                    label="Stato"
                                    control={control}
                                    record={record}
                                    register={register}
                                    values={loginStatusItems}
                                    field="mLoginStatus"
                                    joinField=""
                                    idField="id"
                                    labelField="label"
                                    selectedValue="label"
                                    required={true}
                                    disabled={false}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} lg={4}>
                            <FormAutoComplete
                                label="Stanza di default"
                                control={control}
                                record={record}
                                register={register}
                                field="mDefaultRoom"
                                joinField="mRoomID"
                                values={activeRooms}
                                idField="mRoomID"
                                labelField="mRoomName"
                                selectedValue="id"
                                required={false}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardModal>
            <CardModal
                modalOpen={extraModalOpen}
                onClose={onExtraClose}
                title={"Onorari " + userCompleteName}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <div>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={loggedClasses.schedulerAccordionHeader}
                            >
                                <Typography variant={"h6"} align={"center"}>
                                    Provvigione di default
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <form
                                    onSubmit={handleSubmitDoctorFeeRecord(onSubmitDoctorFeeRecord, onErrorDoctorFeeRecord)}>
                                    <Grid
                                        container
                                        spacing={theme.spacing(2)}
                                        direction={"row"}
                                    >
                                        <Grid item md={12} xl={2}>
                                            <FormSwitch
                                                label="UM"
                                                control={controlDoctorFeeRecord}
                                                record={doctorDefaultFeeRecord}
                                                register={registerDoctorFeeRecord}
                                                field="mDefaultFeeType"
                                                labels={["€", "%"]}
                                                values={["EURO", "PERC"]}
                                                required={true}
                                                disabled={false}
                                            />
                                        </Grid>
                                        <Grid item md={12} xl={4}>
                                            <FormTextBox
                                                label={"Valore"}
                                                control={controlDoctorFeeRecord}
                                                record={doctorDefaultFeeRecord}
                                                register={registerDoctorFeeRecord}
                                                pattern={"number"}
                                                field="mDefaultFeeValue"
                                                required={true}
                                                maxLength={5}
                                            />
                                        </Grid>
                                        <Grid item md={12} xl={2}>
                                            <FormSubmitButton label={"Applica"} loading={isSavingDefaultFeeRecord}/>
                                        </Grid>
                                    </Grid>
                                </form>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2bh-content"
                                sx={loggedClasses.schedulerAccordionHeader}
                            >
                                <Typography variant={"h6"} align={"center"}>
                                    Provvigione prestazioni
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <form onSubmit={handleSubmitFees(onSubmitFees, onErrorFees)}>
                                    <Grid
                                        container
                                        spacing={theme.spacing(2)}
                                        direction={"row"}
                                    >

                                        <Grid item md={12} xl={2}>
                                            <FormSwitch
                                                label="UM"
                                                control={controlFees}
                                                record={feeRecord}
                                                register={registerFees}
                                                field="mType"
                                                labels={["€", "%"]}
                                                values={["EURO", "PERC"]}
                                                required={true}
                                                disabled={false}
                                            />
                                        </Grid>
                                        <Grid item md={12} xl={4}>
                                            <FormTextBox
                                                label={"Valore"}
                                                control={controlFees}
                                                record={feeRecord}
                                                register={registerFees}
                                                pattern={"number"}
                                                field="mValue"
                                                required={true}
                                                maxLength={5}
                                            />
                                        </Grid>
                                        <Grid item md={12} xl={2}>
                                            <FormSubmitButton label={"Applica a selezione"} disabled={saveFeesDisabled}
                                                              loading={isLoadingFees}/>
                                        </Grid>
                                    </Grid>
                                </form>
                                <Grid
                                    container
                                    spacing={theme.spacing(2)}
                                    direction={"row"}
                                >
                                    <Grid item xs={12}>
                                        <div id="agReactGridFees" className="ag-theme-alpine" style={gridSizeStyle}>
                                            <AgGridReact
                                                ref={gridRef}
                                                rowData={doctorFees}
                                                defaultColDef={defaultColDef}
                                                columnDefs={feesColumns}
                                                columnTypes={columnTypes}
                                                suppressMovableColumns={true}
                                                suppressCellFocus={true}
                                                pagination={true}
                                                rowSelection={'multiple'}
                                                onSelectionChanged={onFeesSelectionChanged}
                                                getRowHeight={(params) => gridRowHeight}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </CardContent>
            </CardModal>
        </>
    )

}

export default Users;
