import {Box, Paper, Stack, Typography, useTheme} from "@mui/material";
import InvervalSlot from "./IntervalSlot";
import React, {useContext, useEffect, useState} from "react";
import {BookingContext} from "../../context/BookingContext";
import {CommonContext} from "../../context/CommonContext";
import {loggedClassNames} from "../../theme/Styles";
import {isDataLoading, isDataNull, setDataLoading} from "../../common";
import {CircularWaiting} from "../Waitings/CircularWaiting";
import {LinearWaiting} from "../Waitings/LinearWaiting";

const RoomDayCell = ({
                         slots,
                         closeInterval,
                         openInterval,
                         openIntervalDetail
                     }) => {

    const bookingContext = useContext(BookingContext);
    const commonContext = useContext(CommonContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    return (
        <Paper
            sx={loggedClasses.schedulerCell} elevation={3}
        >
            <Stack direction={"column"} spacing={theme.spacing(1)}>
                {
                    !(isDataNull(slots) || isDataLoading(slots)) && slots.length > 0 &&
                    slots.sort((a, b) => a.mHourFrom > b.mHourFrom ? 1 : -1).map((interval, intervalIndex) => {
                        return (
                            <InvervalSlot
                                interval={interval}
                                closeInterval={closeInterval}
                                openInterval={openInterval}
                                openIntervalDetail={openIntervalDetail}
                            />
                        )
                    })
                }
            </Stack>
        </Paper>
    )
}

export default RoomDayCell;