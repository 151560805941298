import {merge} from "lodash";
import Alert from "./overrides/Alert";
import Button from "./overrides/Button";
import Badge from "./overrides/Badge";
import CardContent from "./overrides/CardContent";
import Checkbox from "./overrides/Checkbox";
import Chip from "./overrides/Chip";
import IconButton from "./overrides/IconButton";
import InputBase from "./overrides/InputBase";
import InputLabel from "./overrides/InputLabel";
import LinearProgress from "./overrides/LinearProgress";
import Link from "./overrides/Link";
import ListItemIcon from "./overrides/ListItemIcon";
import Pagination from "./overrides/Pagination";
import Switch from "./overrides/Switch";
import Tab from "./overrides/Tab";
import Table from "./overrides/Table";
import Tabs from "./overrides/Tabs";
import Typography from "./overrides/Typography";

export default function Components(theme) {
    return merge(
        Alert(theme),
        Button(theme),
        Badge(theme),
        CardContent(),
        Checkbox(theme),
        Chip(theme),
        IconButton(theme),
        InputBase(theme),
        InputLabel(theme),
        LinearProgress(),
        Link(),
        ListItemIcon(),
        Pagination(theme),
        Switch(theme),
        Tab(theme),
        Table(theme),
        Tabs(theme),
        Typography(theme)
    );
}