import {Card, CardContent, Grid, Popover, Tooltip, Typography, useTheme} from "@mui/material";
import React, {useContext, useState} from "react";
import {getCompleteName} from "../../common";
import {BookingContext} from "../../context/BookingContext";
import {ManagementContext} from "../../context/ManagementContext";
import SmsComposer from "../Sms/SmsComposer";
import {loggedClassNames} from "../../theme/Styles";
import {StatusBadge} from "../Badges/StatusBadge";
import {ActionButton} from "../Buttons/ActionButton";
import VisitLineSlotDetail from "./VisitLineSlotDetail";
import {CardModal} from "../Layout/CardModal";
import {useNavigate} from "react-router-dom";
import {CommonContext} from "../../context/CommonContext";

export const VisitLineSlotPaper = ({room, slot, vl}) => {

    const bookingContext = useContext(BookingContext);
    const managementContext = useContext(ManagementContext);
    const commonContext = useContext(CommonContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    let navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [smsMessage, setSmsMessage] = useState(null);

    const visitLineClick = () => {
        let booking = {visit: vl.mVisita}
        bookingContext.openBooking(booking, 4);
        navigate('/Booking/VisitDetail');
    }

    let completeName = (((vl.mVisita.mPatient ?? {}).mPatientID ?? 0) !== 0) ?
        getCompleteName(vl.mVisita.mPatient.mNomePaziente, vl.mVisita.mPatient.mCognomePaziente) :
        getCompleteName(vl.mVisita.mTempPatient.mNomePaziente, vl.mVisita.mTempPatient.mCognomePaziente);

    let statusColor = bookingContext.getVisitLineStatusColor(vl);
    let invoicingColor = bookingContext.getVisitLineInvoicingColor(vl);
    let vlKey = slot.key + "-" + vl.mEsameID;

    const detailsHandleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const detailsHandleClose = () => {
        setAnchorEl(null);
    };

    const openWhatsApp = () => {
        let patient = (((vl.mVisita.mPatient ?? {}).mPatientID ?? 0) !== 0) ? vl.mVisita.mPatient : vl.mVisita.mTempPatient;
        let phone = patient.mMobilePhone ?? "";
        let message = 'Buongiorno dalla clinica XYZ';
        let url = 'https://wa.me/';
        url += '' + phone;
        url += '?text=' + message;
        url = encodeURI(url);
        const win = window.open(url, "_blank");
        win.focus();
    }

    let visitLineStatus = vl.mStatoEsame;
    let visitLineInvoicingStatus = vl.mInvoicingStatus;
    let paymentType = ((vl.mVisita.mTipoPagamento ?? {}).mDescription ?? "").toUpperCase();

    const sendSms = () => {
        managementContext.fetchSmsItem().then((res) => {
            let patient = (((vl.mVisita.mPatient ?? {}).mPatientID ?? 0) !== 0) ? vl.mVisita.mPatient : vl.mVisita.mTempPatient;
            let newSMS = res;
            newSMS.mTargetPhone = patient.mMobilePhone ?? "";
            newSMS.mNome = patient.mNomePaziente ?? "";
            newSMS.mCognome = patient.mCognomePaziente ?? "";
            newSMS.mMessageText = 'Buongiorno dalla clinica XYZ';
            setSmsMessage(newSMS);
        })
    }

    const onCloseSmsComposer = (event, reason) => {
        setSmsMessage(null);
    }

    const smsComposerVisible = (smsMessage !== null);

    return (
        <>
            <Card sx={loggedClasses.roomSlotContent}>
                <CardContent>
                    <Grid container direction={"column"} spacing={theme.spacing(2)}>
                        <Grid item xs={12} display="flex" justifyContent="center">
                            <Grid container direction={"row"} justifyContent="end" spacing={theme.spacing(1)}>
                                <Grid item xs={12} md={6} display="flex" justifyContent="center">
                                    <Tooltip title={visitLineInvoicingStatus}>
                                        <StatusBadge label={visitLineInvoicingStatus} color={invoicingColor} fullWidth/>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} md={6} display="flex" justifyContent="center">
                                    <Tooltip title={paymentType}>
                                        <StatusBadge label={paymentType} color={"info"} fullWidth/>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="center">
                            <Grid container direction={"row"} justifyContent="end" spacing={theme.spacing(1)}>
                                <Grid item xs={12} md={3}>
                                    <ActionButton
                                        color="secondary"
                                        variant="outlined"
                                        action={detailsHandleClick}
                                        size="small"
                                        icon="info"
                                        iconSize="small"
                                        tooltip="Dettagli"
                                        label="Dettagli"
                                        fullWidth
                                        vertical={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <ActionButton
                                        color="secondary"
                                        variant="outlined"
                                        action={visitLineClick}
                                        size="small"
                                        icon="open"
                                        iconSize="small"
                                        tooltip="Apri"
                                        label="Apri"
                                        fullWidth
                                        vertical={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <ActionButton
                                        color="secondary"
                                        variant="outlined"
                                        action={openWhatsApp}
                                        size="small"
                                        icon="whatsapp"
                                        iconSize="small"
                                        tooltip="WhatsApp"
                                        label="WhatsApp"
                                        fullWidth
                                        vertical={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <ActionButton
                                        color="secondary"
                                        variant="outlined"
                                        action={sendSms}
                                        size="small"
                                        icon="sms"
                                        iconSize="small"
                                        tooltip="Scrivi"
                                        label="SMS"
                                        fullWidth
                                        vertical={true}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                <Popover
                    id={"visitLineSlotDetails" + vlKey}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={detailsHandleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                >
                    <VisitLineSlotDetail
                        onClose={detailsHandleClose}
                        room={room}
                        slot={slot}
                        vl={vl}
                        key={vlKey}
                    />
                </Popover>
            </Card>
            <CardModal
                modalOpen={smsComposerVisible}
                onClose={onCloseSmsComposer}
                title={"Componi SMS"}
                size={"small"}
            >
                <CardContent>
                    <SmsComposer smsMessage={smsMessage} closeSmsComposer={onCloseSmsComposer}/>
                </CardContent>
            </CardModal>
        </>
    )
}