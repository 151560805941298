import {Alert, Grid, Typography, useTheme} from "@mui/material";
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {isDataLoading, isDataNull} from "../../../../common";
import {FormActionBar} from "../../../../components/Form/FormActionBar";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormCheckBox} from "../../../../components/Form/FormCheckBox";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AccountingContext} from "../../../../context/AccountingContext";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {BookingContext} from "../../../../context/BookingContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {useNavigate} from "react-router-dom";
import {loggedClassNames} from "../../../../theme/Styles";

export const CreateInvoiceFromVisitLines = ({...props}) => {

    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);
    const alertContext = useContext(CustomAlertContext);
    const accountingContext = useContext(AccountingContext);

    const theme = useTheme();
    let navigate = useNavigate();
    const loggedClasses = loggedClassNames(theme);

    const [firstLoaded, setFirstLoaded] = useState(false);
    const [isBankDisabled, setIsBankDisabled] = useState(true);
    const [isPrivilegeQuotes, setIsPrivilegeQuotes] = useState(false);

    const {
        watch,
        register,
        control,
        formState: {errors},
        handleSubmit,
        reset,
        getValues,
        setValue
    } = useForm({defaultValues: accountingContext.fromVisitLinesInvoice});

    useEffect(() => {
        if (firstLoaded) return;
        if (!isDataLoading(registriesContext.payingMethods)) registriesContext.fetchPayingMethodsItemizedListValues();
        if (!isDataLoading(registriesContext.banks)) registriesContext.fetchBanks();
        if (!isDataLoading(accountingContext.invoiceQuoteTypes)) accountingContext.fetchInvoiceQuoteTypeItemizedListValues();
    }, [firstLoaded]);

    useEffect(() => {
        const subscription = watch(
            (value, {name, type}) => {
                if (type === 'change') {
                    const currentValues = getValues();

                    //QP
                    const quoteValue = (currentValues.mQuote && currentValues.mQuote!=="") ? currentValues.mQuote.substring(0,2) : "";
                    const isPrivilege = (quoteValue==="QP");
                    if (isPrivilege) {
                        setValue('payingMethod', null, {});
                        setValue('bank', null, {});
                    } //{shouldValidate: true}
                    setIsPrivilegeQuotes(isPrivilege);

                    //bank
                    let isCard = (currentValues.payingMethod ?? "").toString().toUpperCase() === "CARTA";
                    let isTransfer = (currentValues.payingMethod ?? "").toString().toUpperCase() === "BONIFICO";
                    let isWithBank = (isCard || isTransfer);
                    let isDisabled = (currentValues.payingMethod) ? !isWithBank : true;
                    if (isDisabled) setValue('bank', null, {}); //{shouldValidate: true}

                    setIsBankDisabled(isDisabled);

                }
            }
        );
        return () => subscription.unsubscribe();
    }, [watch]);

    const onError = (errors, e) => {
        console.log(errors, e);
    }

    const createDisabled = () => {
        return (
            !accountingContext.fromVisitLinesInvoice
        )
    }

    const invoiceCreateCancel = (data) => {
        //niente
    }

    const getSumbitLabel = () => {
        return (isPrivilegeQuotes) ? "Finalizza" : "Crea";
    }

    const onSubmit = (data) => {
        const title = (isPrivilegeQuotes) ? "Conferma finalizzazione visita" : "Conferma generazione fattura";
        const message = (isPrivilegeQuotes) ? "Stai finalizzando con Quota Privilege. Sei sicuro?" : "Stai generando la fattura. Sei sicuro?"
        alertContext.showConfirmDialog(
            title,
            message,
            invoiceCreateCancel,
            () => {
                accountingContext.createInvoiceFromVisitLines(data).then((res) => {
                    let accountingItem = {invoice: res};
                    accountingContext.openAccounting(accountingItem, 2);
                })
            }
        )

    }

    const onClose = () => {
        accountingContext.openAccounting({}, 0);
        navigate('/Booking/VisitDetail');
    }

    if (isDataNull(registriesContext.payingMethods) || isDataLoading(registriesContext.payingMethods)) return <CircularWaiting/>
    if (isDataNull(registriesContext.banks) || isDataLoading(registriesContext.banks)) return <CircularWaiting/>
    if (!accountingContext.accountingState.visit) return <CircularWaiting/>
    if (!accountingContext.fromVisitLinesInvoice) return <CircularWaiting/>

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid
                    container
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                >
                    <Grid
                        item
                        container
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                        spacing={theme.spacing(2)}
                        sx={loggedClasses.bookingDetailModal}
                    >
                        <Grid item xs={12}>
                            <Typography variant="h3">Crea fattura da esami</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextBox
                                label="Nome completo"
                                control={control}
                                record={accountingContext.fromVisitLinesInvoice}
                                register={register}
                                field="completeName"
                                required={true}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextBox
                                label="Indirizzo"
                                control={control}
                                record={accountingContext.fromVisitLinesInvoice}
                                register={register}
                                field="address"
                                required={true}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormAutoComplete
                                label="Metodo di pagamento"
                                control={control}
                                record={accountingContext.fromVisitLinesInvoice}
                                register={register}
                                field="payingMethod"
                                joinField=""
                                values={registriesContext.payingMethods}
                                idField="mItemizedListValueID"
                                labelField="mValue"
                                selectedValue="label"
                                required={!isPrivilegeQuotes}
                                disabled={isPrivilegeQuotes}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormAutoComplete
                                label="Banca"
                                control={control}
                                record={accountingContext.fromVisitLinesInvoice}
                                register={register}
                                field="bank"
                                joinField="mBankID"
                                values={registriesContext.banks}
                                idField="mBankID"
                                labelField="mBankName"
                                selectedValue="id"
                                required={!isBankDisabled && !isPrivilegeQuotes}
                                selectFirst={true}
                                disabled={isBankDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormAutoComplete
                                label="Quote"
                                control={control}
                                record={accountingContext.fromVisitLinesInvoice}
                                register={register}
                                field="mQuote"
                                joinField=""
                                values={accountingContext.invoiceQuoteTypes}
                                idField="mItemizedListValueID"
                                labelField="mValue"
                                selectedValue="label"
                                required={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextBox
                                label="Notes"
                                control={control}
                                record={accountingContext.fromVisitLinesInvoice}
                                register={register}
                                field="notes"
                                required={false}
                                maxLength={250}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormCheckBox
                                label="Pagata"
                                control={control}
                                record={accountingContext.fromVisitLinesInvoice}
                                getValues={getValues}
                                setRecord={accountingContext.setFromVisitLinesInvoice}
                                reset={reset}
                                register={register}
                                field="payed"
                                required={false}
                            />
                        </Grid>
                        {
                            (isPrivilegeQuotes) ?
                                <Grid item xs={12} md={12}>
                                    <Alert severity="error">
                                        Attenzione finalizzando con Quota privilege non verrà creata fattura!
                                    </Alert>
                                </Grid>
                                : <></>
                        }
                        <Grid item xs={12} md={12}>
                            <FormActionBar
                                submitLabel={getSumbitLabel()}
                                submitDisabled={createDisabled()}
                                cancelAction={onClose}
                            />
                        </Grid>
                    </Grid>
                </Grid>

            </form>
        </>
    )
        ;
};

export default CreateInvoiceFromVisitLines;