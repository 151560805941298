import {ArrowDropDownTwoTone, ArrowDropUpTwoTone} from '@mui/icons-material';
import {Button, Menu, MenuItem, styled, Typography, useMediaQuery, useTheme} from "@mui/material";
import * as React from 'react';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {commonClassNames, loggedClassNames} from "../../theme/Styles";
import {IconLibraryItem} from "../Icons/IconLibraryItem";

const MenuGroupButton = styled(Button)(({theme}) => ({
    minHeight: '35px !important',
    maxHeight: '35px !important',
    pl: theme.spacing(2),
    pr: 0,
    color: theme.palette.primary.contrastText,
    boxShadow: "none",
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.spacing(1.5),
    '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.light,
    }
}));

const MainNavGroup = (props) => {

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    const commonClasses = commonClassNames(theme);

    let navigate = useNavigate();

    const showIcon = useMediaQuery(theme.breakpoints.up('xxl'));

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const historyPush = (url) => {
        navigate("/" + url);
        handleClose();
    }

    if (props.menus.length !== 0) {
        return (
            <>
                <MenuGroupButton
                    variant={"outlined"}
                    id={'menu-button-' + props.domain.mDomainName}
                    key={'menu-button-' + props.domain.mDomainName}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    {
                        (showIcon) ? <IconLibraryItem name={props.domain.mImageKey} iconSize={"small"} sx={{mr: theme.spacing(1)}}/> : <></>
                    }
                    <Typography
                        color={theme.palette.text.primary.contrastText}
                        sx={loggedClasses.mainNavGroupLabel}
                    >
                        {props.domain.mDomainName}
                    </Typography>
                    {(open) ? <ArrowDropUpTwoTone size={"sm"}/> : <ArrowDropDownTwoTone size={"sm"}/>}
                </MenuGroupButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{'aria-labelledby': 'basic-button',}}
                >

                    {props.menus.map((item) => (<MenuItem key={'menuItem' + item.mFunctionName} onClick={() => {
                        historyPush(item.mComponentName)
                    }}>
                                <span style={commonClasses.menuIcon}>
                                     {
                                         (showIcon) ? <IconLibraryItem name={item.mImageKey} iconSize={"medium"} sx={{mr: theme.spacing(1)}}/> : <></>
                                     }
                                </span>
                        <Typography component={"span"}
                                    sx={loggedClasses.mainNavItemLabel}> {item.mFunctionName} </Typography>
                    </MenuItem>))}
                </Menu>
            </>);
    } else {
        return <></>
    }

}

export default MainNavGroup;