import {Box, Typography, useTheme} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import React, {useContext, useEffect, useState} from 'react';
import {useIdleTimer} from "react-idle-timer";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";

const SessionProgress = () => {

    const authContext = useContext(AuthContext);

    const theme = useTheme();

    let navigate = useNavigate();

    const [remainingTime, setRemainingTime] = useState(authContext.getSessionRemainTime());

    // 1 * 60 *1000 authContext.maxSessionTime

    let idleTimer = useIdleTimer({
        timeout: authContext.maxSessionTime
    });

    useEffect(() => {

        const timer = setInterval(() => {
            let idleRemainingTime = idleTimer.getRemainingTime();
            if (idleRemainingTime< authContext.sessionCheckInterval) {
                authContext.setSessionExpired().then((res)=> {
                    navigate("/Login");
                });
            }
            else {
                authContext.setSessionRemainingTime(idleRemainingTime);
            }
            authContext.timedCheck();
            setRemainingTime(idleRemainingTime);
        }, authContext.sessionCheckInterval);
        return () => {
            clearInterval(timer);
        };
    }, []);

    const remainTime = authContext.getSessionRemainTime();
    const progress = authContext.getSessionProgressNormalizedValue();

    const getRemainLabel = () => {
        let mss = remainTime;
        let mm = Math.floor(mss / 60000);
        mss = mss - 60000 * mm;
        let ss = Math.floor(mss / 1000);
        mm = (mm + 100).toString().substr(1, 2);
        ss = (ss + 100).toString().substr(1, 2);
        return mm + ":" + ss;
    }

    return (
        <Box sx={{p: theme.spacing(1)}}>
            <LinearProgress variant="determinate" value={progress}/>
            <Typography variant={"body"}>{getRemainLabel()}</Typography>
            {/*<span sx={{}}> </span>*/}
        </Box>
    );
}
export default SessionProgress