import {Grid, Typography, useTheme} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import React, {useContext, useEffect, useState} from 'react';
import {columnTypes, dateTimeColumnRenderer, defaultColDef, isDataLoading, isDataNull} from "../../../../common";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {ManagementContext} from "../../../../context/ManagementContext";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {StatusBadge} from "../../../../components/Badges/StatusBadge";

export const Sessions = (props) => {

    const managementContext = useContext(ManagementContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    const [loaded, setLoaded] =useState(false);

    useEffect(() => {
        if ((!loaded) && !isDataLoading(managementContext.connectedUsers)) managementContext.fetchConnectedUsers().then((res) => {
            setLoaded(true);
        });
    }, [managementContext.connectedUsers]);

    const logingStatusRenderer = (props) => {
        const status = (props.node.data.mSessionStatus ?? "");
        const color = (status.toUpperCase() === "CONNESSO") ? "success" : "error";
        return (
            <StatusBadge label={status} color={color} fullWidth={false} minWidth={130} width={130} size={"small"}/>
        )
    }

    const [columns, setColumns] = useState([
        {headerName: "Nome", field: "mFirstName"},
        {headerName: "Cognome", field: "mLastName"},
        {headerName: "Login", field: "mLoginDate", cellRenderer: dateTimeColumnRenderer},
        {headerName: "Ultimo rilevamento", field: "mLastActvity", cellRenderer: dateTimeColumnRenderer},
        {headerName: "Stato", field: "mSessionStatus", type:"flex1", cellRenderer: logingStatusRenderer},
        {headerName: "Fine sessione", field: "mExpirationDate", cellRenderer: dateTimeColumnRenderer},
    ]);

    if (isDataNull(managementContext.connectedUsers) || isDataLoading(managementContext.connectedUsers)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={managementContext.connectedUsers}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
        </>
    )

}