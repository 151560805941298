import {Grid, Stack, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import React, {useContext, useEffect, useState} from "react";
import {BookingContext} from "../../context/BookingContext";
import {loggedClassNames} from "../../theme/Styles";
import {ActionButton} from "../Buttons/ActionButton";
import {CircularWaiting} from "../Waitings/CircularWaiting";
import {CardModal} from "../Layout/CardModal";
import {PDFViewer} from "../PDF/PDFViewer";

export const VisitModulePreview = ({
                                  visitID,
                                  setModulType,
                                  moduleType
                              }) => {

    const bookingContext = useContext(BookingContext);
    const [document, setDocument] = useState({});
    const [documentContent, setDocumentContent] = useState("");

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const typeID = moduleType.id;
    const typeName = moduleType.label;

    useEffect(() => {
        if (typeID === -1) return;
        if (documentContent === "") {
            bookingContext.getVisitModule(visitID, typeID).then((res) => {
                setDocumentContent(res.mListOfBytes);
            });
        }
    }, [moduleType]);

    const modalOpen = () => {
        return typeID !== -1;
    }

    const onClose = (event, reason) => {
        setModulType({id: -1, name: ""});
        setDocumentContent("");
    }

    if (!modalOpen()) return <></>

    return (
        <CardModal
            modalOpen={modalOpen}
            title={"Anteprima modulo"}
            onClose={onClose}
        >
            {
                (documentContent === "") ?
                    <CircularWaiting/> :
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h4" component="h5">
                                {typeName} della visita {visitID}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <PDFViewer dataType={"application/pdf"}
                                       dataContent={'data:application/pdf;base64,' + documentContent}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack
                                direction={"row"}
                                justifyContent="flex-start"
                                spacing={theme.spacing(2)}
                                sx={{pt: theme.spacing(1)}}>
                                <ActionButton
                                    color="primary"
                                    variant="contained"
                                    action={onClose}
                                    size="medium"
                                    icon="close"
                                    iconSize="medium"
                                    tooltip="Chiudi"
                                    label="Chiudi"
                                />
                            </Stack>
                        </Grid>
                    </Grid>
            }
        </CardModal>
    )

}