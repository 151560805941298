import {Box, styled, useTheme} from '@mui/material';
import React, {useContext, useEffect} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {isDataLoading, isDataNull} from "../../common";
import {CustomAlerts, DataAlerts, JsonAlerts} from "../../components/Alerts/CustomAlerts";
import {CustomConfirmDialog} from "../../components/Alerts/CustomConfirmDialog";
import TopBar from "../../components/Layout/TopBar";
import {CircularWaiting} from "../../components/Waitings/CircularWaiting";
import {CustomAlertContext} from "../../context/AlertContext";
import {AuthContext} from "../../context/AuthContext";
import {BookingContext} from "../../context/BookingContext";
import {ManagementContext} from "../../context/ManagementContext";
import {RegistriesContext} from "../../context/RegistriesContext";
import {loggedClassNames} from "../../theme/Styles";
import AccountingContainer from './accounting/AccountingContainer';
import Home from './home/Home';
import ManagementContainer from "./management/ManagementContainer";
import MedicalReportsContainer from './medicalReporting/MedicalReportingContainer';
import RegistriesContainer from './registries/RegistriesContainer';
import UserContainer from "./user/UserContainer";
import BookingContainer from "./booking/BookingContainer";
import moment from "moment/moment";
import {MedicalReportingContext} from "../../context/MedicalReportingContext";
import {AccountingContext} from "../../context/AccountingContext";
import RecupContainer from "./recup/RecupContainer";

const loggedRoutes = [
    {path: '/Home', component: <Home/>, exact: true},
    {path: '/Accounting', component: <AccountingContainer/>, exact: false},
    {path: '/Booking', component: <BookingContainer/>, exact: false},
    {path: '/Management', component: <ManagementContainer/>, exact: false},
    {path: '/MedicalReporting', component: <MedicalReportsContainer/>, exact: false},
    {path: '/Registries', component: <RegistriesContainer/>, exact: false},
    {path: '/User', component: <UserContainer/>, exact: false},
    {path: '/Recup', component: <RecupContainer/>, exact: false},
]

const LocationRenderer = () => {

    const location = useLocation();

    const exactElement = loggedRoutes.find(l => l.exact===true && location.pathname === l.path);

    if (exactElement) return exactElement.component;

    const partialElement = loggedRoutes.find(l => l.exact===false && location.pathname.startsWith(l.path));

    return (partialElement) ? partialElement.component : <></>

}

const ProtectedRoute = ({children}) => {

    const authContext = useContext(AuthContext);

    moment().locale('it');

    if (!authContext.getSession().isLogged) {
        return <Navigate to="/Login" replace/>;
    }

    return children;
};

const Logged = () => {

    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const alertContext = useContext(CustomAlertContext);
    const authContext = useContext(AuthContext);
    const accountingContext = useContext(AccountingContext);
    const bookingContext = useContext(BookingContext);
    const managementContext = useContext(ManagementContext);
    const medicalReportingContext = useContext(MedicalReportingContext);
    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const location = useLocation();

    useEffect(() => {
        if (!authContext.getSession().isLogged) { return; }
        if (isDataNull(authContext.systemParameters)) authContext.fetchSystemParameters().then(() => {});
    }, [authContext.systemParameters]);

    useEffect(() => {
        const exactElement = loggedRoutes.find(l => l.exact===true && location.pathname === l.path);
        const partialElement = loggedRoutes.find(l => l.exact===false && location.pathname.startsWith(l.path));

        if (partialElement && ((partialElement.path ?? "") !== '/Accounting')) accountingContext.setAccountingLoaded(false);
        // if (partialElement && ((partialElement.path ?? "") !== '/Booking')) bookingContext.setBookingLoaded(false);
        if (partialElement && ((partialElement.path ?? "") !== '/Management')) managementContext.setManagementLoaded(false);
        if (partialElement && ((partialElement.path ?? "") !== '/MedicalReporting')) medicalReportingContext.setMedicalReportingLoaded(false);

    }, [location]);

    const Spacer = styled('div')({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    });

    if (authContext.getSession().isLogged) {
        if (!registriesContext.baseRegistriesLoaded) return <CircularWaiting/>
        if (!authContext.systemParameters || (isDataLoading(authContext.systemParameters))) return <CircularWaiting/>
    }

    return (
        <ProtectedRoute>
            <TopBar/>
            <Spacer/>
            <Box
                display="flex"
                alignContent={"flex-start"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={loggedClasses.loggedContainer}
            >
                <LocationRenderer/>
            </Box>
            <CustomAlerts/>
            <JsonAlerts/>
            {
                (alertContext.customConfirmDialog.requestId !== "") ?
                    <CustomConfirmDialog
                        requestId={alertContext.customConfirmDialog.requestId}
                        open={alertContext.customConfirmDialog.open}
                        title={alertContext.customConfirmDialog.title}
                        message={alertContext.customConfirmDialog.message}
                        handleCancel={alertContext.customConfirmDialog.handleCancel}
                        handleConfirm={alertContext.customConfirmDialog.handleConfirm}
                        handleHide={alertContext.customConfirmDialog.handleHide}
                    />
                    : <></>
            }
            <DataAlerts/>
        </ProtectedRoute>
    )
}

export default Logged;