import {FormControl, Stack, Switch, Typography, useTheme} from "@mui/material";
import React from 'react';
import {Controller} from "react-hook-form";

export const FormSwitch = ({ notFullWidth, noFormControl, label, labels, values,  ...props}) => {

    const theme = useTheme();

    const getOptions = () => {
        let options = {}
        if (props.required !== null) {
            options.required = {};
            options.required.value = props.required;
            if (props.required === true) {
                options.required.message = "Campo richiesto";
            }
        }
        return options;
    }

    const isDisabled = () => {
        if (props.disabled) {
            return props.disabled;
        } else {
            return false;
        }
    }

    const labelItems = labels ?? ["A", "B"];
    const valueItems = values ?? ["A", "B"];

    return (
        <FormControl fullWidth>
            <Controller
                name={props.field}
                control={props.control}
                render={({
                             field: {onChange, onBlur, value, name, ref},
                             fieldState: {invalid, isTouched, isDirty, error},
                             formState,
                         }) => (
                    <>
                        <Typography>{label}</Typography>
                        <Stack direction="row" spacing={theme.spacing(1)} alignItems="center">
                            <Typography>{labelItems[0]}</Typography>
                            <Switch
                                name={`switch-${name}`}
                                key={`switch-${name}`}
                                inputRef={ref}
                                checked={value===valueItems[1]}
                                defaultChecked
                                disabled={isDisabled()}
                                onChange={(event, newValue) => {
                                    if (!newValue) onChange(valueItems[0]);
                                    if (newValue) onChange(valueItems[1]);
                                }}
                                onBlur={onBlur}
                            />
                            <Typography>{labelItems[1]}</Typography>
                        </Stack>
                    </>

                )}
                {...props.register(props.field, getOptions())}
            />
        </FormControl>
    )

}