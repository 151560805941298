import {Autocomplete, Avatar, Grid, Paper, Stack, TextField, Typography, useTheme} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {SquareButton} from "../../../../components/Buttons/SquareButton";
import {BookingContext} from "../../../../context/BookingContext";
import {loggedClassNames} from "../../../../theme/Styles";
import {CommonContext} from "../../../../context/CommonContext";
import {isDataLoading, isDataNull} from "../../../../common";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {RegistriesContext} from "../../../../context/RegistriesContext";

export const Availabilities = ({
                                   availsState,
                                   goPrevAvailabilities,
                                   goNextAvailabilities,
                                   bookingFromAvailability,
                                   close
                               }) => {

    const registriesContext = useContext(RegistriesContext);
    const commonContext = useContext(CommonContext);
    const bookingContext = useContext(BookingContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const activeRooms = registriesContext.getActiveRooms();

    const defaultRoom = registriesContext.fetchDefaultRoom();

    const defaultFound = defaultRoom ? activeRooms.find(r => r.mRoomID === defaultRoom.mRoomID)!==null : false;

    const initRoom = (defaultRoom && defaultFound === true) ? defaultRoom : (activeRooms.length !== 0) ? activeRooms[0] : null;

    let roomsList = activeRooms.map((item, index) => {
        return {id: item.mRoomID, label: item.mRoomName, ...item}
    });

    const initItem = initRoom ? roomsList.find(i=> i.id === initRoom.mRoomID) : null;

    const [roomItems, setRoomItems] = useState(roomsList);
    const [selectedRoom, setSelectedRoom] = useState(initItem);
    const [headerDays, setHeaderDays] = useState(null);
    const [daysWithAvailabilities, setDaysWithAvailabilities] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedAvailabilities, setSelectedAvailabilities] = useState(null);
    const [hours, setHours] = useState([]);

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (loaded) return;
        if (!isDataNull(daysWithAvailabilities)) return;

        const fromDate = availsState.fromDate.clone();
        const toDate = availsState.fromDate.clone().add(availsState.offset, 'day');
        commonContext.calculateAvailabilitiesDays(fromDate, availsState.offset).then((days) => {
            setHeaderDays(days);
            setSelectedDay(days[0].dayComparator);
            bookingContext.fetchVisitLinesShort(fromDate, toDate).then((vls) => {
                if ((activeRooms ?? []).length !== 0) {
                    commonContext.calculateAvailabilities(fromDate, toDate, availsState.medicalServiceID, activeRooms, vls).then((avails) => {
                        console.log('avails ', avails);
                        setDaysWithAvailabilities(avails);
                        setHours(null);
                        setLoaded(true);
                    });
                }
            });
        });
        //registriesContext.fetchRoomsByMedicalService(availsState.medicalServiceID).then((ms) => {
    }, [loaded]);

    useEffect(() => {
        if (!selectedDay) return;
        if (isDataNull(daysWithAvailabilities) || isDataLoading(daysWithAvailabilities)) return;

        let filteredDay = daysWithAvailabilities.find(a => a.dayComparator === selectedDay)

        let filteredDayAvails =  (filteredDay ?? {availabilities: []}).availabilities;

        if (selectedRoom && selectedRoom.mRoomID !== -1) {
            filteredDayAvails  =
                filteredDayAvails
                    .filter(a=> a.room.mRoomID === selectedRoom.mRoomID)
                        .sort((a, b) =>a.slot.comparator.localeCompare(b.slot.comparator));
        }
        else {
            filteredDayAvails =
                filteredDayAvails
                    .sort((a, b) =>
                        (a.slot.comparator !== b.slot.comparator) ?
                            a.slot.comparator.localeCompare(b.slot.comparator)
                            :
                            a.room.mRoomName.localeCompare(b.room.mRoomName)
                    );
        }

        let dayHours = filteredDayAvails
            .map((item) => item.slot.timeBeginLabel)
            .filter((value, index, self) => self.indexOf(value) === index);

        setSelectedAvailabilities(filteredDayAvails);
        setHours(dayHours);

    }, [daysWithAvailabilities, selectedDay, selectedRoom]);

    const availableSlotClick = (availability) => {
        bookingFromAvailability(availability.slot, availability.room);
    }

    const goToPrev = () => {
        goPrevAvailabilities();
        setHeaderDays(null);
        setDaysWithAvailabilities(null);
        setSelectedAvailabilities(null);
        setHours(null);
        setLoaded(false);
    }

    const goToNext = () => {
        goNextAvailabilities();
        setHeaderDays(null);
        setDaysWithAvailabilities(null);
        setSelectedAvailabilities(null);
        setHours(null);
        setLoaded(false);
    }

    if (isDataNull(headerDays) || isDataLoading(headerDays)) return <CircularWaiting/>

    const selectedDayDate = selectedDay ? commonContext.getSlotDateLabel(selectedDay).toUpperCase() : "-";
    const selectedDayName = selectedDay ? commonContext.getSlotNameOfDayLabel(selectedDay).toUpperCase() : "-";

    return (
        <Grid
            container
            sx={loggedClasses.availabilitiesContainer}
            spacing={theme.spacing(2)}
        >
            <Grid
                item
                container
                display={"flex"}
                direction="row"
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                spacing={theme.spacing(2)}
            >
                <Grid item xs={1}>
                    <SquareButton
                        color="primary"
                        variant="contained"
                        action={goToPrev}
                        size="large"
                        icon="left"
                        iconSize="large"
                        tooltip="Vai indietro di 7 giorni"
                        label=""
                    />
                </Grid>
                <Grid item xs={10}>
                    <Stack direction="row" sx={{justifyContent: 'space-between'}}>
                        {
                            headerDays.map((availableDay) => {

                                const headerStyle = (selectedDay === availableDay.dayComparator) ?
                                    loggedClasses.selectedAvailabilityDay :
                                    loggedClasses.availabilityDay

                                const dayAvailsCount = daysWithAvailabilities ?
                                    daysWithAvailabilities
                                        .filter(a => a.dayComparator === availableDay.dayComparator)
                                        .map(a => a.availabilities.length)
                                        .reduce((accumulator, current) => accumulator + current, 0)
                                    : "?";

                                return (
                                    <Paper
                                        sx={headerStyle}
                                        elevation={2}
                                        onClick={() => {
                                            setSelectedDay(availableDay.dayComparator)
                                        }}
                                    >
                                        <Typography variant={"h6"}>
                                            {availableDay.dayLabel}
                                        </Typography>
                                        <Typography>
                                            {availableDay.weekDayName}
                                        </Typography>
                                        <Typography>
                                            {"(" + dayAvailsCount + ")"}
                                        </Typography>
                                    </Paper>
                                )
                            })
                        }
                    </Stack>
                </Grid>
                <Grid item xs={1}>
                    <SquareButton
                        color="primary"
                        variant="contained"
                        action={goToNext}
                        size="large"
                        icon="right"
                        iconSize="large"
                        tooltip="Vai avanti di 7 giorni"
                        label=""
                    />
                </Grid>
            </Grid>
            <Grid
                item
                container
                display={"flex"}
                direction="row"
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={theme.spacing(2)}
                sx={{pb: theme.spacing(2)}}
            >
                <Grid item xs={12} lg={3}>
                    <Autocomplete
                        name={`auto-complete-room-page`}
                        key={`auto-complete-room-page`}
                        disablePortal
                        options={roomItems}
                        defaultValue={selectedRoom}
                        onChange={(event, item) => {
                            setSelectedRoom(item);
                        }}
                        renderInput={(params) =>
                            <TextField variant="filled" label={"Elenco sale"} {...params} />
                        }
                    />
                </Grid>
                <Grid item xs={12} lg={9}>
                    {
                        selectedDay &&
                        <Typography variant={"h5"} align={"right"}>
                            Stai prenotando per {selectedDayName} {selectedDayDate}
                        </Typography>
                    }
                </Grid>
            </Grid>
            {
                roomItems && roomItems.length === 0 &&
                <Typography variant={"h2"}>
                    La prestazione non è associata a nessuna sala!
                </Typography>
            }
            <Grid
                item
                container
                display={"flex"}
                direction="column"
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={theme.spacing(3)}
                sx={{pb: theme.spacing(2)}}
            >

                {
                    hours &&
                    hours.map((hour) => {
                        return (
                            <Grid
                                item
                                container
                                display="flex"
                                direction="row"
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                                sx={{...loggedClasses.availabilityHour}}
                            >
                                <Grid xs={12} md={1} pt={theme.spacing(2)} pb={theme.spacing(2)}>
                                    <Avatar variant={"square"} color={"primary"} sx={{width: 75, height: 75}}>
                                        <Typography variant={"h5"}>{hour}</Typography>
                                    </Avatar>
                                </Grid>
                                <Grid xs={12} md={11}>
                                    <Grid
                                        container
                                        display="flex"
                                        flexWrap="wrap"
                                        direction="row"
                                        justifyContent={"flex-start"}
                                        alignItems={"center"}
                                        spacing={theme.spacing(1)}
                                    >
                                        {
                                            selectedAvailabilities &&
                                            selectedAvailabilities
                                                .filter(availability => availability.slot.timeBegin.format('HH') == hour)
                                                .map((availability, index) => {
                                                    const timeBegin = commonContext.getSlotHourLabel(availability.slot.timeBegin);
                                                    const timeEnd = commonContext.getSlotHourLabel(availability.slot.timeEnd);
                                                    const roomName = availability.room.mRoomName;
                                                    return (
                                                        <Grid item md={6} lg={2}>
                                                            <Paper elevation={6}>
                                                                <Grid
                                                                    item
                                                                    container
                                                                    display={"flex"}
                                                                    justifyContent={"flex-start"}
                                                                    alignItems={"center"}
                                                                    alignContent={"flex-start"}
                                                                >
                                                                    <Grid item xs={8} sx={{px: theme.spacing(1)}}>
                                                                        <Typography variant={"body1"}> {timeBegin} </Typography>
                                                                        <Typography variant={"caption"}> {roomName} </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <ActionButton
                                                                            color="secondary"
                                                                            variant="contained"
                                                                            action={() => {
                                                                                availableSlotClick(availability)
                                                                            }}
                                                                            size="small"
                                                                            icon="calendar"
                                                                            iconSize="small"
                                                                            tooltip="Prenota"
                                                                            label={""}
                                                                            fullWidth={true}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                    );
                                                })
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        )

                    })
                }
            </Grid>
        </Grid>

    )
}