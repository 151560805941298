import {Grid, Typography, useTheme} from "@mui/material";
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {FormActionBar} from "../../../../components/Form/FormActionBar";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import UploadUserImage from "../../../../components/User/UploadUserImage";
import UserImage from "../../../../components/User/UserImage";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AuthContext} from "../../../../context/AuthContext";
import {loggedClassNames} from "../../../../theme/Styles";
import {ActionButton} from "../../../../components/Buttons/ActionButton";

const EditProfile = (props) => {

    const authContext = useContext(AuthContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [record, setRecord] = useState(null);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const {
        watch,
        register,
        control,
        formState: {errors},
        handleSubmit,
        reset,
        getValues
    } = useForm({defaultValues: record});

    useEffect(() => {
        if (!record) {
            let loggedUser = authContext.getLoggedUser();
            setRecord(loggedUser)
            reset(loggedUser);
        }
    }, []);

    const onSubmit = data => {
        authContext.updateLoggedUserProfile(data);
    }

    const onError = (errors, e) => {
        console.log(errors, e);
    }

    const openUploadModal = () => {
        setUploadModalOpen(true);
    }

    const onUploadClose = (event, reason) => {
        setUploadModalOpen(false);
    }

    const resetImage = () => {
        setIsUploading(true);
        authContext.saveLoggedUserImage("").then((res)=>{
            setIsUploading(false);
        })

    }


    if (!record) return <CircularWaiting/>

    let workFor = '';
    workFor += (record.mCompany.mCompanyName ?? "") + " - ";
    workFor += (record.mCompany.mAddress ?? "") + " - ";
    workFor += (record.mCompany.mCity ?? {mCityName: '-'}).mCityName + " (";
    workFor += (record.mCompany.mCity ?? {mCityName: '-'}).mTargaProvincia  + ")";

    let workAt = '';
    workAt += (record.mWorkSite.mCompanyName ?? "") + " - ";
    workAt += (record.mWorkSite.mAddress ?? "") + " - ";
    workAt += (record.mWorkSite.mCity ?? {mCityName: '-'}).mCityName + " (";
    workAt += (record.mWorkSite.mCity ?? {mCityName: '-'}).mTargaProvincia  + ")";

    return (
        <>
            {
                (isUploading ?? false) ?
                    <CircularWaiting size={20}/>
                    :
                    <Grid
                        container
                        display={"flex"}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        spacing={theme.spacing(8)}
                        sx={{
                            mb: theme.spacing(8)
                        }}
                    >
                        <Grid item xs={12} lg={4}>
                            <Grid container spacing={theme.spacing(1)}>
                                <Grid item xs={12} container display={"flex"} justifyContent={"center"}>
                                    <UserImage width={200} height={200}/>
                                </Grid>
                                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                                    <ActionButton
                                        color="secondary"
                                        variant="contained"
                                        action={openUploadModal}
                                        disabled={false}
                                        icon={"upload"}
                                        iconSize={"small"}
                                        label={"Carica immagine"}
                                        size={"small"}
                                        tooltip={"Upload immagine profilo"}
                                    />
                                </Grid>
                                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                                    <ActionButton
                                        color="secondary"
                                        variant="contained"
                                        action={resetImage}
                                        disabled={false}
                                        icon={"cancel"}
                                        iconSize={"small"}
                                        label={"Reset immagine"}
                                        size={"small"}
                                        tooltip={"Reset immagine profilo"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={8}
                              container
                              direction={"column"}
                              spacing={theme.spacing(8)}
                        >
                            <Grid item xs={12}>
                                <Typography variant={"h4"} color={theme.palette.secondary.main}>Dipendente della società</Typography>
                                <Typography variant={"h6"}>{workFor}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={"h4"} color={theme.palette.secondary.main}>Lavora presso</Typography>
                                <Typography variant={"h6"}>{workAt}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={"h4"} color={theme.palette.secondary.main}>Profilo</Typography>
                                <Typography variant={"h6"}>{record.mProfile.mProfileName}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
            }
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid
                    container
                    direction={"row"}
                    spacing={theme.spacing(2)}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                >
                    <Grid item xl={3} lg={12} md={12}>
                        <FormTextBox
                            label="Username"
                            control={control}
                            record={record}
                            register={register}
                            field="mUserName"
                            required={true}
                            maxLength={50}
                            pattern={"email"}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xl={3} md={12}>
                        <FormTextBox
                            label="Nome"
                            control={control}
                            record={record}
                            register={register}
                            field="mFirstName"
                            required={true}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xl={3} md={12}>
                        <FormTextBox
                            label="Cognome"
                            control={control}
                            record={record}
                            register={register}
                            field="mLastName"
                            required={true}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xl={3} md={12}>
                        <FormTextBox
                            label="Cellulare"
                            control={control}
                            record={record}
                            register={register}
                            field="mMobilePhone"
                            required={true}
                            maxLength={50}
                        />
                    </Grid>
                    {
                        ((record.mProfile.mProfileID ?? 0) === 2) ?
                            <>
                                <Grid item xl={6} md={12}>
                                    <FormTextBox
                                        label="Header Referto"
                                        control={control}
                                        record={record}
                                        register={register}
                                        field="mHeaderReferto"
                                        required={true}
                                        maxLength={50}
                                    />
                                </Grid>
                                <Grid item xl={6} md={12}>
                                    <FormTextBox
                                        label="Footer Referto"
                                        control={control}
                                        record={record}
                                        register={register}
                                        field="mFooterReferto"
                                        required={true}
                                        maxLength={50}
                                    />
                                </Grid>
                            </>
                            :
                            <></>
                    }
                    <Grid item xl={6} md={12}>
                        <FormActionBar submitLabel={"Salva"} hasCancel={false}/>
                    </Grid>
                </Grid>
            </form>
            <UploadUserImage
                isOpen={uploadModalOpen}
                onClose={onUploadClose}
                setIsUploading={setIsUploading}
            />
        </>
    )
}

export default EditProfile;