import {CardContent, Grid, Stack, TextField, Typography, useTheme} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {columnTypes, defaultColDef, isDataLoading, isDataNull, moneyColumnRenderer} from "../../../../common";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridExtraSizeStyle, gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {FormCheckBox} from "../../../../components/Form/FormCheckBox";
import {FormActionBar} from "../../../../components/Form/FormActionBar";

const EditPriceModal = ({
                            record,
                            priceOpen,
                            priceCancel,
                            priceSave
                        }) => {

    const theme = useTheme();

    const savePriceAndClose = (data) => {
        priceSave(data.mPrice);
    }

    const {
        register,
        control,
        handleSubmit,
        reset
    } = useForm({defaultValues: record}); //mode: 'onSubmit'

    return (
        <CardModal
            modalOpen={priceOpen}
            onClose={priceCancel}
            title={"Modifica prezzo prestazione"}
            size={"xs"}
        >
            <CardContent>
                <form onSubmit={handleSubmit(savePriceAndClose)}>
                <Grid
                    container
                    spacing={theme.spacing(2)}
                    direction={"row"}
                >
                    <Grid item xs={6}>
                        <FormTextBox
                            label="Nuovo prezzo"
                            control={control}
                            record={record}
                            reset={reset}
                            register={register}
                            field="mPrice"
                            required={true}
                            maxLength={50}
                            pattern={"money"}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormActionBar cancelAction={priceCancel}/>
                    </Grid>
                </Grid>
                </form>
            </CardContent>
        </CardModal>
    )

}

const MedicalServices = () => {

    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [record, setRecord] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [dataLoaded, setDataLoaded] =useState(false);
    const [extraRecordId, setExtraRecordId] = useState(-1);
    const [extraRecords, setExtraRecords] = useState([]);
    const [extraModalOpen, setExtraModalOpen] = useState(false);

    const [priceModalOpen, setPriceModalOpen] = useState(false);
    const [priceRow,setPriceRow] = useState(null);

    const {
        register,
        control,
        handleSubmit,
        reset,
        getValues: getValues
    } = useForm({defaultValues: record})

    useEffect(() => {
        if (isDataNull(registriesContext.medicalServiceTypes) && !isDataLoading(registriesContext.medicalServiceTypes)) registriesContext.fetchMedicalServiceTypes().then(() => {});
    }, [registriesContext.medicalServiceTypes]);

    useEffect(() => {
        if (isDataNull(registriesContext.modalities) && !isDataLoading(registriesContext.modalities)) registriesContext.fetchModalities();
    }, [registriesContext.modalities]);

    useEffect(() => {
        if (isDataNull(registriesContext.branches) && !isDataLoading(registriesContext.branches)) registriesContext.fetchBranches();
    }, []);

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(registriesContext.medicalServices)) registriesContext.fetchMedicalServices().then((res)=>{
            setDataLoaded(true);
        });
    }, [dataLoaded, registriesContext.medicalServices]);

    useEffect(()=>{
        if (isDataNull(registriesContext.classesListValues)) registriesContext.fetchMedicalServiceClassListValues();
    },[registriesContext.classesListValues])

    //dettaglio

    const onSubmit = (data) => {
        registriesContext.updateMedicalService(data).then(() => {
            setModalOpen(false);
            setDataLoaded(false);
        })
    }

    const onError = (errors, e) => {
        console.log(errors, e);
    }

    const onClose = (event, reason) => {
        setModalOpen(false);
    }

    //griglia

    const addAction = () => {
        registriesContext.fetchMedicalService(0).then((res) => {
            setRecord(res);
            reset(res);
            setModalOpen(true);
        });
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            registriesContext.fetchMedicalService(selectedRow.mPrestazioneID).then((res) => {
                setRecord(res);
                reset(res);
                setModalOpen(true);
            });
        };

        const deleteAction = () => {
            alert(selectedRow.mPrestazioneID);
        }

        const pricesAction = () => {
            setExtraRecordId(selectedRow.mPrestazioneID);
            setExtraModalOpen(true);
            registriesContext.fetchAllMedicalServicePrices(selectedRow.mPrestazioneID).then((res) => {
                setExtraRecords(res);
                setExtraModalOpen(true);
            });
        }

        const customActions = [
            {
                action: pricesAction,
                disabled: false,
                icon: "euro",
                tooltip: "Prezzi",
            }
        ];

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
                customActions={customActions}
            />
        )
    }

    const onGridReady = (params) => {
        // setAgGridApi(params.api);
        // params.api.sizeColumnsToFit();
    }

    const [columns, setColumns] = useState([
        {headerName: "Descrizione", field: "mDescrizione", type: "flex4"},
        {headerName: "Classe", field: "mClasse"},
        {headerName: "Cod. padre", field: "mCodicePadre"},
        {headerName: "Cod. figlio", field: "mCodiceFiglio"},
        // {headerName: "Tipo", field: "mTipo.mTipoDescription"},
        {headerName: "Modality", field: "mModality.mModalityDescription"},
        {headerName: "Branca", field: "mBranca.mBrancaName"},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    //  extra - prezzi

    const onPriceCancel = () => {
        setPriceRow(null);
        setPriceModalOpen(false);
    }

    const onPriceSave = (priceValue) => {
        const newPriceRow = {
            ...priceRow,
            mPrice: priceValue
        }
        registriesContext.updateMedicalServicePrice(newPriceRow).then((res) => {
            setPriceRow(null);
            setPriceModalOpen(false);
            registriesContext.fetchAllMedicalServicePrices(extraRecordId).then((res) => {
                setExtraRecords(res);
                setExtraModalOpen(true);
            });
        })
    }

    const ExtraToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            setPriceRow(selectedRow);
            setPriceModalOpen(true);
        };

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete={false}
            />
        )
    }

    const [columnsExtra, setColumnsExtra] = useState([
        {headerName: "Codice", field: "mConvenzione.mConventionCode"},
        {headerName: "Descrizione", field: "mConvenzione.mConventionDescription"},
        {headerName: "Prezzo", field: "mPrice", cellRenderer: moneyColumnRenderer},
        {headerName: "Azioni", cellRenderer: ExtraToolBar, type: "toolBar"}
    ]);

    const onExtraClose = () => {
        setExtraModalOpen(false);
        setExtraRecordId(-1);
    }

    if (isDataNull(registriesContext.medicalServices) || isDataLoading(registriesContext.medicalServices)) return <CircularWaiting/>
    if (isDataNull(registriesContext.medicalServiceTypes) || isDataLoading(registriesContext.medicalServiceTypes)) return <CircularWaiting/>
    if (isDataNull(registriesContext.modalities) || isDataLoading(registriesContext.modalities)) return <CircularWaiting/>
    if (isDataNull(registriesContext.branches) || isDataLoading(registriesContext.branches)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Prestazioni</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{pb: theme.spacing(2)}}>
                        <Grid item xs={12}>
                            <Stack
                                direction={"row"}
                                justifyContent={"flex-end"}
                                spacing={theme.spacing(2)}
                                sx={{pt: theme.spacing(1)}}>
                                <AddButton addAction={addAction}/>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={registriesContext.medicalServices}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            onGridReady={onGridReady}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
            <CardModal
                modalOpen={modalOpen}
                onClose={onClose}
                title={"Dettaglio prestazione"}
                formFunctions={{submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError}}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Descrizione"
                                control={control}
                                record={record}
                                register={register}
                                field="mDescrizione"
                                required={true}
                                maxLength={500}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormAutoComplete
                                label="Classe"
                                control={control}
                                record={record}
                                register={register}
                                field="mClasse"
                                joinField=""
                                values={registriesContext.classesListValues}
                                idField="mItemizedListValueID"
                                labelField="mValue"
                                selectedValue="label"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormTextBox
                                label="Durata"
                                control={control}
                                record={record}
                                register={register}
                                field="mDurata"
                                required={true}
                                pattern={"number"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormTextBox
                                label="Numerosità"
                                control={control}
                                record={record}
                                register={register}
                                field="mNumerosita"
                                required={true}
                                pattern={"number"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormAutoComplete
                                label="Tipo"
                                control={control}
                                record={record}
                                register={register}
                                field="mTipo"
                                joinField="mTipoPrestazioneID"
                                values={registriesContext.medicalServiceTypes}
                                idField="mTipoPrestazioneID"
                                labelField="mTipoDescription"
                                selectedValue="id"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormTextBox
                                label="Codice padre"
                                control={control}
                                record={record}
                                register={register}
                                field="mCodicePadre"
                                required={false}
                                maxLength={10}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormTextBox
                                label="Codice figlio"
                                control={control}
                                record={record}
                                register={register}
                                field="mCodiceFiglio"
                                required={false}
                                maxLength={10}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormCheckBox
                                label="Critica (ASL)"
                                control={control}
                                record={record}
                                register={register}
                                getValues={getValues}
                                field="mCritica"
                                reset={reset}
                                required={false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormAutoComplete
                                label="Modality"
                                control={control}
                                record={record}
                                register={register}
                                field="mModality"
                                joinField="mModalityID"
                                values={registriesContext.modalities}
                                idField="mModalityID"
                                labelField="mModalityDescription"
                                selectedValue="id"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormAutoComplete
                                label="Branca"
                                control={control}
                                record={record}
                                register={register}
                                field="mBranca"
                                joinField="mBrancaID"
                                values={registriesContext.branches}
                                idField="mBrancaID"
                                labelField="mBrancaName"
                                selectedValue="id"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Note preparazione"
                                control={control}
                                record={record}
                                register={register}
                                field="mWebPreparationNote"
                                required={false}
                                maxLength={250}
                                multiline={true}
                                lines={3}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardModal>
            <CardModal
                modalOpen={extraModalOpen}
                onClose={onExtraClose}
                title={"Prezzi della prestazione"}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        spacing={theme.spacing(2)}
                        direction={"row"}
                    >
                        <Grid item xs={12}>
                            <div id="agReactGrid" className="ag-theme-alpine" style={gridExtraSizeStyle}>
                                <AgGridReact
                                    rowData={extraRecords}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnsExtra}
                                    columnTypes={columnTypes}
                                    suppressMovableColumns={true}
                                    suppressCellFocus={true}
                                    pagination={true}
                                    rowSelection={'single'}
                                    getRowHeight={(params) => gridRowHeight}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    {
                        (priceRow) ? <EditPriceModal record={priceRow} priceOpen={priceModalOpen} priceCancel={onPriceCancel} priceSave={onPriceSave}/>:<></>
                    }
                </CardContent>
            </CardModal>


        </>
    )

}

export default MedicalServices;