import {CardContent, FormControl, Grid, Typography, useTheme} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    booleanMatcher,
    booleanRenderer,
    columnTypes,
    defaultColDef,
    isDataLoading,
    isDataNull
} from "../../../../common";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormCheckBox} from "../../../../components/Form/FormCheckBox";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {RoomIntervalConfig} from "../../../../components/RoomsIntervalsScheduling/RoomIntervalConfig";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import '../../../../theme/agGridStyle.css';
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";

const Rooms = () => {

    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [record, setRecord] = useState(null);
    const [extraRecordId, setExtraRecordId] = useState(-1);
    const [extraRecords, setExtraRecords] = useState(null);
    const [schedulingRoom, setSchedulingRoom] = useState(null);
    const [dataLoaded, setDataLoaded] =useState(false);
    const [filterRecord, setFilterRecord] = useState(null);

    const {
        register,
        control,
        handleSubmit,
        reset
    } = useForm({defaultValues: record});

    useEffect(() => {
        if (isDataNull(registriesContext.companies) && !isDataLoading(registriesContext.companies)) registriesContext.fetchCompanies();
    }, [registriesContext.companies]);

    useEffect(() => {
        if (isDataNull(registriesContext.doctors) && !isDataLoading(registriesContext.doctors)) registriesContext.fetchDoctors();
    }, [registriesContext.doctors]);

    useEffect(() => {
        if (isDataNull(registriesContext.branches) && !isDataLoading(registriesContext.branches)) registriesContext.fetchBranches();
    }, [registriesContext.branches]);

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(registriesContext.rooms)) registriesContext.fetchRoomsAsync().then((res)=>{
            setDataLoaded(true);
        })
    }, [registriesContext.rooms]);

    //dettaglio

    const onSubmit = (data) => {
        registriesContext.updateRoom(data);
        setRecord(null);
    }

    const onError = (errors, e) => {
        console.log(record);
        console.log(errors, e);
    }

    const onClose = (event, reason) => {
        setRecord(null);
    }

    //griglia

    const addAction = () => {
        registriesContext.fetchRoom(0).then((res) => {
            setRecord(res);
            reset(res);
        });
    }

    const onCustomActionClose = (event, reason) => {
        setSchedulingRoom(null);
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            registriesContext.fetchRoom(selectedRow.mRoomID).then((res) => {
                setRecord(res);
                reset(res);
            });
        };

        const deleteAction = () => {
            alert(selectedRow.mRoomID);
        }

        const extraAction = () => {
            setExtraRecordId(selectedRow.mRoomID);
            registriesContext.fetchRoomDevices(selectedRow.mRoomID).then((res) => {
                setExtraRecords(res);
            });
        }

        const customAction = () => {
            setSchedulingRoom(selectedRow);
        }

        const customActions = [,
            {
                action: extraAction,
                disabled: false,
                icon: "medical-device",
                tooltip: "Apparecchiature collegate",
            },
            {
                action: customAction,
                disabled: false,
                icon: "clock",
                tooltip: "Scheduling",
            }
        ];

        const customDisabled = () => {
            return false;
        }

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
                customActions={customActions}
            />
        )
    }

    const onGridReady = (params) => {
        // setAgGridApi(params.api);
        // params.api.sizeColumnsToFit();
    }

    const [columns, setColumns] = useState([
        {headerName: "ID", field: "mRoomID"},
        {headerName: "Nome", field: "mRoomName"},
        {headerName: "Abilitata", field: "mActive", cellRenderer: booleanRenderer, filterParams: { textMatcher: booleanMatcher  }},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    const onExtraClose = (event, reason) => {
        setExtraRecordId(-1);
    }

    const columnsExtra = [
        {headerName: "AETitle", field: "mAETitleName"},
        {headerName: "IP", field: "mAETitleIP"},
        {headerName: "Porta", field: "mAETitlePort"},
        {headerName: "", "cellRenderer": "extraActionRenderer", type: "toolButton"}
    ];

    const detailsModalOpen = (record !== null);
    const extraModalOpen = extraRecordId !== -1;
    const schedulingModalOpen = schedulingRoom !== null;

    const {
        register: registerFilter,
        control: controlFilter,
        handleSubmit: handleSubmitFilter
    } = useForm({defaultValues: filterRecord});

    const onSubmitFilter = (data) => {

    }

    const onErrorFilter = (errors, e) => {
        console.log(errors, e);
    }

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Stanze</Typography>
                </Grid>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmitFilter(onSubmitFilter, onErrorFilter)}>
                        <Grid
                            container
                            direction={"row"}
                            spacing={theme.spacing(2)}
                            sx={{mt: theme.spacing(2), mb: theme.spacing(2)}}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                        >
                            <Grid item xs={12} md={3}>
                                <FormAutoComplete
                                    label="Azienda"
                                    control={controlFilter}
                                    record={filterRecord}
                                    register={registerFilter}
                                    field="mCompany"
                                    joinField="mCompanyID"
                                    values={registriesContext.companies}
                                    idField="mCompanyID"
                                    labelField="mCompanyName"
                                    selectedValue="id"
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <FormControl>
                                    <AddButton addAction={addAction}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={registriesContext.rooms ?? []}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            onGridReady={onGridReady}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
            <CardModal
                modalOpen={detailsModalOpen}
                onClose={onClose}
                title={"Dettaglio stanza"}
                size={"small"}
                formFunctions={{submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError}}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Nome"
                                control={control}
                                record={record}
                                register={register}
                                field="mRoomName"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCheckBox
                                label="Abilitata"
                                control={control}
                                record={record}
                                register={register}
                                field="mActive"
                                required={false}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardModal>
            <CardModal
                modalOpen={extraModalOpen}
                onClose={onExtraClose}
                title={"Apparecchiature collegate"}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={extraRecords ?? []}
                            defaultColDef={defaultColDef}
                            columnDefs={columnsExtra}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            onGridReady={onGridReady}
                            getRowHeight={(params) => gridRowHeight}
                        />
                    </div>
                </CardContent>
            </CardModal>
            <RoomIntervalConfig open={schedulingModalOpen} onClose={onCustomActionClose} room={schedulingRoom}/>
        </>
    )

}

export default Rooms;