import moment from 'moment';
import React, {createContext, useContext, useEffect, useState} from 'react';
import {
    dateIsNull,
    daysWithName,
    empty_slot,
    emptyCompleteName,
    getCompleteName,
    getCompleteShortName,
    intDateTimeFormat,
    intervalScheduling,
    JSONClone,
    JSONIsEmpty,
    nullDate, parseMoney
} from "../common";
import * as webServices from "../services/WebServices";
import {CustomAlertContext} from "./AlertContext";
import {RegistriesContext} from "./RegistriesContext";
import {CommonContext} from "./CommonContext";
import {CART_SESSION_KEY, getSessionValue, setSessionValue} from "../storage";
import {wsGetVisitLineShort, wsOpenPlanningInterval} from "../services/WebServices";

const BookingContext = createContext();

const empty_temp_patient = {
    mNomePaziente: "",
    mCognomePaziente: "",
    mMobilePhone: ""
};

const empty_patient = {
    mPatientID: 0,
    mNomePaziente: "",
    mCognomePaziente: "",
    mSex: "",
    mAge: 0,
    mBirthDate: "0001-01-01T00:00:00",
    mBirthCity: null,
    mBirthCountry: null,
    mLiveCity: null,
    mLiveCountry: null,
    mLiveAddress: "",
    mCitizenOf: null,
    mCF: "",
    mASLName: "",
    mMobilePhone: "",
    mHomePhone: "",
    mEmail: "",
    mFamilyDoctor: null,
    mSubTown: null,
    mAdditionalInformation: "",
    mQueryLevel: 1,
    mGivenPrivacyDate: "0001-01-01T00:00:00"
}

const empty_visit = {
    mVisitaID: 0,
    mVisitNumber: "",
    mAccessionNumber: "",
    mImponibileNonScontato: 0,
    mImponibileScontato: 0,
    mTotaleFinale: 0,
    mTotaleSconti: 0,
    mTotaleTasse: 0,
    mStatoVisita: "",
    mPatient: JSONClone(empty_patient),
    mConvention: {
        mConventionID: 0,
        mConventionCode: "",
        mConventionDescription: ""
    },
    mSponsor: null,
    mAuthor: null,
    mNote: "",
    mQuesitoDiagnostico: "",
    mListOfEsami: [
        //empty_visit_line
    ]
}

const empty_visit_line = {
    mEsameID: 0,
    mDurata: 0,
    mRoom: {
        mRoomID: 0,
        mRoomName: "",
        mActive: true
    },
    mPrestazione: {
        mPrestazioneID: 0,
        mDescrizione: "",
        mDurata: 0,
        mClasse: "",
        mMnemonicCode: "",
        mNumerosita: 0
    },
    mDataAppuntamento: "",
    mDataPrenotatazione: "",
    mStatoEsame: "",
    mQta: 0,
    mClientPrice: 0,
    mPrezzoListino: 0,
    mPrezzoRimborso: 0,
    mSconto: 0,
    mStudyIUID: "",
    mReferto: null,
    mInvoiceLineID: 0,
    mCreatedBy: {
        mUserID: 0,
        mUserName: "",
        mMobilePhone: "",
        mPassword: "",
        mFirstName: "",
        mLastName: "",
        mLoginStatus: "",
        mHeaderReferto: "",
        mFooterReferto: "",
        mCompany: null,
        mProfile: null
    },
    mAccettedBy: null,
    mReportedBy: null,
    mAssignedDoctor: {
        mUserID: 0,
        mUserName: "",
        mMobilePhone: "",
        mPassword: "",
        mFirstName: "",
        mLastName: "",
        mLoginStatus: "",
        mHeaderReferto: "",
        mFooterReferto: "",
        mCompany: null,
        mProfile: null
    },
    mFilterFrom: "",
    mFilterTo: "",
    mFilterPatientID: 0,
    mShowDraft: false,
    mWithReport: false,
    mIsInvoiced: false
}

const initial_planning_state = {
    selectedDate: moment(),
    selectedRoom: 0,
    selectedBranch: 0,
    selectedSlot: null
}

const initial_prev_state = {
    bookingState: {},
    bookingItem: {}
}

const VISIT_STATUS = {
    composingStatus: "IN_COMPOSIZIONE",
    bookedStatus: "PRENOTATA",
    partialAcceptedStatus: "PARZIALMENTE ACCETTATA",
    acceptedStatus: "ACCETTATA",
    partialExecutedStatus: "PARZIALMENTE ESEGUITA",
    executedStatus: "ESEGUITA",
    reportedStatus: "REFERTATA"
}

const VISIT_INVOICING_STATUS = {
    exempt: "ESENTE",
    notInvoiceableStatus: "NON FATTURABILE",
    notInvoicedStatus: "NON FATTURATA",
    partialInvoicedStatus: "PARZIALMENTE FATTURATA",
    invoicedStatus: "FATTURATA"
}

const VISIT_LINE_STATUS = {
    bookedStatus: "PRENOTATO",
    acceptedStatus: "ACCETTATO",
    executedStatus: "ESEGUITO",
    reportedStatus: "REFERTATO"
}

const VISIT_LINE_INVOICING_STATUS = {
    exempt: "ESENTE",
    notInvoiceableStatus: "NON FATTURABILE",
    notInvoicedStatus: "NON FATTURATO",
    invoicedStatus: "FATTURATO",
}

const newPlanningDay = {
    mPlanID: 0,
    mRoom: {mRoomID: 0},
    mColor: "255,255,255",
    mGiorno: 1
}

const newPlanningInterval = {
    mSchedule: 0,
    mBranca: {mBrancaID: 0, mBrancaName: ""},
    mPlanning: {mPlanID: 0},
    mDoctor: {mUserID: 0},
    mDateFrom: moment(),
    mDateTo: moment(),
    mHourFrom: moment(),
    mHourTo: moment(),

}

const initial_scheduler_state = {
    fromDate: moment().startOf('week'),
    toDate: moment().endOf('week')
}

const BookingProvider = (props) => {

    // 0 - PLANNER SALE
    // 1 - TAKE BY SLOT
    // 20 - TAKE BY BLANK FROM PLANNER
    // 21 - TAKE BY BLANK FROM VISIT
    // 3 - DETTAGLIO LINEA VISITA
    // 4 - DETTAGLIO VISITA
    // 5 - INTERVAL PLANNING
    // 6 - TAKE BY INTERVAL

    const alertContext = useContext(CustomAlertContext);
    const commonContext = useContext(CommonContext);
    const registriesContext = useContext(RegistriesContext);

    const [bookingMode, setBookingMode] = useState(0); //caricamento
    const [bookingItem, setBookingItem] = useState({});
    const [bookingState, setBookingState] = useState(JSONClone(initial_planning_state));
    const [planningVisitLines, setPlanningVisitLines] = useState([]);
    const [cartID, setCartID] = useState(0);
    const [cart, setCart] = useState({});

    const [visit, setVisit] = useState(JSONClone(empty_visit));
    const [isVisitSaving, setIsVisitSaving] = useState(false);
    const [visitLine, setVisitLine] = useState(JSONClone(empty_visit));
    const [prevState, setPrevState] = useState(JSONClone(initial_prev_state));
    const [schedulerDayOptions, setSchedulerDayOptions] = useState([...daysWithName]);
    const [schedulerFreqOptions, setSchedulerFreqOptions] = useState([...intervalScheduling]);
    const [schedulerState, setSchedulerState] = useState(JSONClone(initial_scheduler_state));

    const getStorageCartID = () => {
        let value = parseInt(getSessionValue(CART_SESSION_KEY, '0'));
        if (value !== cartID) setCartID(value);
        return value;
    };

    const setStorageCartID = (storageVisitID) => {
        setCartID(storageVisitID);
        setSessionValue(CART_SESSION_KEY, storageVisitID.toString());
    }

    useEffect(() => {
        getStorageCartID();
        if ((cartID === 0 && !JSONIsEmpty(cart)) || (cartID !== 0 && JSONIsEmpty(cart)) || (cart.mVisitaID != cartID)) fetchAndSetCartVisit(cartID).then();
    }, []);

    useEffect(() => {
        if (bookingMode === -1) return;
        if (
            (cartID === 0 && !JSONIsEmpty(cart)) ||
            (cartID !== 0 && JSONIsEmpty(cart)) ||
            (cart.mVisitaID != cartID)
        ) fetchAndSetCartVisit(cartID).then();
    }, [cartID]);

    const goToRoomsPlanner = () => {
        setBookingItem({});
        setBookingMode(0);
        let newState = {
            ...JSONClone(initial_planning_state),
            selectedDate: bookingState.selectedDate
        };
        setBookingState(newState);
    }

    const getPlanningRoomVisitLines = (room, visitLines) => {
        return (visitLines.length === 0) ? [] : visitLines.filter(vl => vl.mRoom.mRoomID === room.mRoomID);
    }

    const calculatePlanningRoomSlots = async (vls, roomID) => {

        let slotDurationConf = commonContext.getPlanningParameter("SLOT_SIZE");
        let openingTime = commonContext.getOpeningDateTime(bookingState.selectedDate);
        let closingTime = commonContext.getClosingDateTime(bookingState.selectedDate);
        let roomSlots = [];
        let currentSlot = openingTime.clone();
        let end_date = closingTime.clone();

        while (currentSlot < end_date) {
            let nextSlot = currentSlot.clone().add(slotDurationConf, 'minutes');
            let newSlot = JSONClone(empty_slot);
            newSlot.timeBegin = currentSlot.clone();
            newSlot.timeEnd = nextSlot.clone();
            newSlot.duration = slotDurationConf;
            newSlot.isCurrent = bookingState.selectedDate.clone().isBetween(currentSlot, nextSlot, null, "[)")
            newSlot.isLast = (nextSlot >= end_date);
            let vlsContained = vls.filter(vl =>
                moment(vl.mDataAppuntamento).isBetween(currentSlot, nextSlot, null, "[)")
            );
            newSlot.size = Math.max(1, ...vlsContained.map(o => o.mDurata));
            newSlot.hidden = (vls.filter(vl =>
                (
                    moment(vl.mDataAppuntamento) < currentSlot &&
                    moment(vl.mDataAppuntamento).add(vl.mDurata * slotDurationConf, 'minutes').isBetween(currentSlot, nextSlot, null, "(]")
                )
                ||
                (
                    moment(vl.mDataAppuntamento) < currentSlot &&
                    moment(vl.mDataAppuntamento).add(vl.mDurata * slotDurationConf, 'minutes') > nextSlot
                )
            ).length) > 0;
            newSlot.visitLines = getMappedVisitLines(vlsContained);
            newSlot.key = "slot-" + roomID + "-" + currentSlot.clone().format(intDateTimeFormat);
            roomSlots.push(newSlot);
            currentSlot = nextSlot;
        }

        let result = {};
        result.roomID = roomID;
        result.slots = roomSlots;
        result.visitLinesCount = vls.length;
        return result;

    }

    const checkVisitTempPatientIsNull = () => {
        return (visit.mTempPatient.mNomePaziente === "" && visit.mTempPatient.mCognomePaziente === "" && visit.mTempPatient.mMobilePhone === "")
    }

    const checkVisitRealPatientIsNull = () => {
        return (visit.mPatient.mPatientID === 0)
    }

    const resetVisitRealPatient = () => {
        const newPat = JSONClone(empty_patient);
        newPat.mBirthCountry = registriesContext.italy.country;
        newPat.mLiveCountry = registriesContext.italy.country;
        newPat.mCitizenOf = registriesContext.italy.country;
        const newVisit = {
            ...visit,
            mPatient: newPat
        }
        setVisit(newVisit);
    }

    const getMappedVisitLines = (vls) => {
        const shortDateTimeFormat = commonContext.getPlanningParameter("DATETIME_FORMAT_SHORT");
        if (!vls) return []
        if (vls.length === 0) return []
        return vls.map((vl) => {
            vl.mPrestazione.mDurataMinuti = vl.mDurata * commonContext.getPlanningParameter("SLOT_SIZE");
            vl.mDataAppuntamentoString = moment(vl.mDataAppuntamento).format(shortDateTimeFormat);
            if (vl.mAssignedDoctor) {
                vl.mAssignedDoctor.mCompleteName = getCompleteName(vl.mAssignedDoctor.mFirstName, vl.mAssignedDoctor.mLastName)
                vl.mAssignedDoctor.mShortName = getCompleteShortName(vl.mAssignedDoctor.mFirstName, vl.mAssignedDoctor.mLastName)
            }
            let isPatientNull = vl.mVisita.mPatient.mPatientID === 0;
            let visitStatus = vl.mVisita.mStatoVisita.toUpperCase();
            let vlStatus = vl.mStatoEsame.toUpperCase();
            vl.isBooked = vl.mStatoEsame ? (vlStatus === VISIT_LINE_STATUS.bookedStatus) : false;
            vl.isAccepted = vl.mStatoEsame ? (vlStatus === VISIT_LINE_STATUS.acceptedStatus) : false;
            vl.isExecuted = vl.mStatoEsame ? (vlStatus === VISIT_LINE_STATUS.executedStatus) : false;
            vl.isReported = vl.mStatoEsame ? (vlStatus === VISIT_LINE_STATUS.reportedStatus) : false;
            vl.isInvoiced = vl.mStatoEsame ? (vl.mIsInvoiced) : false;
            vl.isExempt = vl.mVisita.mEsenzione ? ((vl.mVisita.mEsenzione.mEsenzioneID ?? 0) !== 0) : false;

            /* calcolo invoicing status */

            let invoicingStatus = "";
            if (vl.isExempt) invoicingStatus = VISIT_LINE_INVOICING_STATUS.exempt;
            else if (isPatientNull || visitStatus === VISIT_STATUS.composingStatus) invoicingStatus = VISIT_LINE_INVOICING_STATUS.notInvoiceableStatus;
            else if (!isPatientNull && !vl.isInvoiced) invoicingStatus = VISIT_LINE_INVOICING_STATUS.notInvoicedStatus;
            else if (!isPatientNull && vl.isInvoiced) invoicingStatus = VISIT_LINE_INVOICING_STATUS.invoicedStatus;
            else invoicingStatus = VISIT_LINE_INVOICING_STATUS.notInvoiceableStatus;

            vl.mInvoicingStatus = invoicingStatus;

            return vl;
        })
    }

    const getVisitStatus = (v) => {
        let vls = v.mListOfEsami;
        if (!vls) return ""
        if (vls.length === 0) return ""
        let total = vls.length;

        let booked = vls.filter((vl) => vl.isBooked === true).length;
        let accepted = vls.filter((vl) => vl.isAccepted === true).length;
        let executed = vls.filter((vl) => vl.isExecuted === true).length;
        let reported = vls.filter((vl) => vl.isReported === true).length;

        let status = "-";
        if (v.mStatoVisita.toUpperCase() === VISIT_STATUS.composingStatus) status = VISIT_STATUS.composingStatus;
        else if (total === reported) status = VISIT_STATUS.reportedStatus;
        else if (executed === total) status = VISIT_STATUS.executedStatus;
        else if ((executed < total) && (executed > 0)) status = VISIT_STATUS.partialExecutedStatus;
        else if (accepted === total) status = VISIT_STATUS.acceptedStatus;
        else if ((accepted < total) && (accepted > 0)) status = VISIT_STATUS.partialAcceptedStatus;
        else if (booked === total) status = VISIT_STATUS.bookedStatus;
        return status;
    }

    const getVisitInvoicingStatus = (v) => {
        let vls = v.mListOfEsami;
        let total = vls.length;
        let invoiced = vls.filter((vl) => vl.isInvoiced).length;
        let isPatientNull = (v.mPatient.mPatientID === 0)
        let isExempt = v.mEsenzione ? ((v.mEsenzione.mEsenzioneID ?? 0) !== 0) : false;

        let status = "-";
        if (isExempt) status = VISIT_INVOICING_STATUS.exempt;
        else if (isPatientNull || v.mStatoVisita.toUpperCase() === VISIT_STATUS.composingStatus) status = VISIT_INVOICING_STATUS.notInvoiceableStatus;
        else if (!isPatientNull && (invoiced === 0)) status = VISIT_INVOICING_STATUS.notInvoicedStatus;
        else if (!isPatientNull && (total > invoiced)) status = VISIT_INVOICING_STATUS.partialInvoicedStatus;
        else if (!isPatientNull && (total === invoiced)) status = VISIT_INVOICING_STATUS.invoicedStatus;
        else status = VISIT_INVOICING_STATUS.notInvoiceableStatus;
        return status;
    }

    const canSaveVisit = () => {
        let visitStatus = visit ? visit.mExecutionStatus : "";
        return (
            visit &&
            !isVisitSaving &&
            (visitStatus === VISIT_STATUS.composingStatus || visitStatus === VISIT_STATUS.bookedStatus)
        )
    }

    const canChangeVisitTempPatient = () => {
        let visitStatus = visit ? visit.mExecutionStatus : "";
        return (
            visit &&
            !isVisitSaving &&
            (
                visitStatus === VISIT_STATUS.composingStatus ||
                (visitStatus === VISIT_STATUS.bookedStatus && checkVisitRealPatientIsNull()) // && checkVisitRealPatientIsNull()
            )
        )
    }

    const canChangeVisitRealPatient = () => {
        let visitStatus = visit ? visit.mExecutionStatus : "";
        return (
            visit &&
            !isVisitSaving &&
            (
                visitStatus === VISIT_STATUS.composingStatus || visitStatus === VISIT_STATUS.bookedStatus
            )
        )
    }

    const canAcceptVisit = () => {
        let visitStatus = visit ? visit.mExecutionStatus : "";
        return (
            visit &&
            !isVisitSaving &&
            (
                visitStatus === VISIT_STATUS.bookedStatus ||
                visitStatus === VISIT_STATUS.partialAcceptedStatus ||
                visitStatus === VISIT_STATUS.partialExecutedStatus
            )
            && !checkVisitRealPatientIsNull()
            // && checkASLFields()
        )
    }

    const canInvoicingVisit = () => {
        let visitInvoicingStatus = visit ? visit.mInvoicingStatus : "";
        return (
            visit &&
            !isVisitSaving &&
            !checkVisitRealPatientIsNull() &&
            visitInvoicingStatus !== VISIT_INVOICING_STATUS.notInvoiceableStatus &&
            visitInvoicingStatus !== VISIT_INVOICING_STATUS.invoicedStatus &&
            visitInvoicingStatus !== VISIT_INVOICING_STATUS.exempt
        )
    }

    const canPrintModules = () => {
        let visitInvoicingStatus = visit ? visit.mInvoicingStatus : "";
        return (
            visit &&
            !isVisitSaving &&
            !checkVisitRealPatientIsNull()
        )
    }

    const getVisitLineStatusColor = (vl) => {
        return (vl.isExecuted) ?
            "success" :
            (vl.isAccepted) ?
                "secondary" :
                (vl.isBooked) ?
                    "primary" : "info"
    }

    const getVisitLineInvoicingColor = (vl) => {
        return (vl.mIsInvoiced) ?
            "success" :
            (vl.isExecuted) ?
                "warning" : "error"
    }

    const getVisitLinesCount = () => {
        return (visit && !JSONIsEmpty(visit) && visit.mListOfEsami) ? visit.mListOfEsami.length : 0;
    }

    const visitIsEmpty = () => {
        return getVisitLinesCount() === 0;
    }

    const deleteVisitLineFromVisit = async (vl) => {
        const res = await webServices.wsDeleteVisitLine(vl, alertContext, true);
        if (res.responseAnyError) return;
        let rec = res.responseData;
        await fetchAndSetVisit(vl.mVisita.mVisitaID);
        if (vl.mVisita.mVisitaID === getStorageCartID()) {
            await fetchAndSetCartVisit(vl.mVisita.mVisitaID);
        }
        setIsVisitSaving(false);
        return null;
    }

    const calculatePatientASL = (rec) => {
        rec.mASLName = ""
        if (rec.mLiveCity) {
            if (rec.mLiveCity.mWithSubTown) { //municipio
                if (rec.mSubTown) {
                    if (rec.mSubTown.mASL) {
                        rec.mASLName = rec.mSubTown.mASL.mCompanyName;
                    }
                }
            } else { //città
                if (rec.mLiveCity.mASL) {
                    rec.mASLName = rec.mLiveCity.mASL.mCompanyName;
                }
            }
        }
        return rec;
    };

    const normalizeVisit = (rec) => {
        const longDateTimeFormat = commonContext.getPlanningParameter("DATETIME_FORMAT_LONG");
        rec.mListOfEsami = getMappedVisitLines(rec.mListOfEsami);
        rec.mExecutionStatus = getVisitStatus(rec);
        rec.mInvoicingStatus = getVisitInvoicingStatus(rec);
        const bIsASL = (rec.mConvention) ? rec.mConvention.mIsASL : false;
        if (rec.mPatient) {
            if (!rec.mPatient.mBirthCountry) rec.mPatient.mBirthCountry = registriesContext.italy.country
            if (!rec.mPatient.mLiveCountry) rec.mPatient.mLiveCountry = registriesContext.italy.country
            if (!rec.mPatient.mCitizenOf) rec.mPatient.mCitizenOf = registriesContext.italy.country
            rec.mPatient = calculatePatientASL(rec.mPatient);
            if (bIsASL && !rec.mSponsor) rec.mSponsor = rec.mPatient.mFamilyDoctor;
        }
        if (bIsASL && !rec.mSponsor) rec.mSponsor = rec.mPatient.mFamilyDoctor;
        if (bIsASL && dateIsNull(rec.mDataRicetta)) rec.mDataRicetta = moment().format(longDateTimeFormat);
        return rec;
    }

    const fetchVisit = async (recordId) => {
        if (recordId > 0) {
            let res = await webServices.wsGetVisit({mVisitaID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            const rec = (recs.length !== 0) ? normalizeVisit(recs[0]) : JSONClone(empty_visit);
            return rec;
        } else {
            return JSONClone(empty_visit);
        }
    }

    const fetchAndSetVisit = async (pRecordId) => {
        const res = await fetchVisit(pRecordId);
        setVisit(JSONClone(res));
    }

    const saveVisitTmpPatient = async (data) => {
        let tempPatientBody = {};
        tempPatientBody = data.mTempPatient;
        tempPatientBody.mVisitaID = data.mVisitaID;
        setIsVisitSaving(true);
        const res = await webServices.wsSaveVisitTmpPatient(tempPatientBody, alertContext, false);
        setIsVisitSaving(false);
        if (res.responseAnyError) return;
        let rec = res.responseData;
        await fetchAndSetVisit(visit.mVisitaID);
        setCartID(0);
    }

    const saveVisitRealPatient = async (record) => {
        const longDateTimeFormat = commonContext.getPlanningParameter("DATETIME_FORMAT_LONG");
        record.mPatient.mBirthDate = record.mPatient.mBirthDate ? moment(record.mPatient.mBirthDate).format(longDateTimeFormat) : nullDate;
        let realPatientBody = {
            ...record.mPatient,
            mVisitaID: record.mVisitaID
        };
        setIsVisitSaving(true);
        const res = await webServices.wsSaveVisitRealPatient(realPatientBody, alertContext, false);
        setIsVisitSaving(false);
        if (res.responseAnyError) return;
        let rec = res.responseData;
        await fetchAndSetVisit(record.mVisitaID);
        setCartID(0);

    };

    const saveVisitASL = async (record) => {
        const longDateTimeFormat = commonContext.getPlanningParameter("DATETIME_FORMAT_LONG")
        let updateASLBody = {
            mVisitaID: record.mVisitaID,
            mNumeroRicetta: record.mNumeroRicetta,
            mDataRicetta: moment(record.mDataRicetta).format(longDateTimeFormat),
            mSponsor: {mSponsorID: record.mSponsor.mSponsorID},
            mEsenzione: record.mEsenzione,
            mPrimoAccesso: (record.mPrimoAccesso ?? false),
            mPriority: record.mPriority
        };
        setIsVisitSaving(true);
        const res = await webServices.wsSaveVisitAsl(updateASLBody, alertContext, false);
        setIsVisitSaving(false);
        if (res.responseAnyError) return;
        let rec = res.responseData;
        await fetchAndSetVisit(record.mVisitaID);
        setCartID(0);
    };

    const acceptVisit = async (record) => {
        let tempVisitBody = {};
        tempVisitBody.mVisitaID = record.mVisitaID;
        const res = await webServices.wsAcceptVisit(tempVisitBody, alertContext, true);
        if (res.responseAnyError) return;
        let rec = res.responseData;
        await fetchAndSetVisit(record.mVisitaID);
        setCartID(0);
        setIsVisitSaving(false);
    };

    const acceptVisitLine = async (record, pVisitLineID) => {
        let tempBody = {};
        tempBody.mEsameID = pVisitLineID;
        const res = await webServices.wsAcceptVisitLine(tempBody, alertContext, true);
        if (res.responseAnyError) return;
        let rec = res.responseData;
        await fetchAndSetVisit(record.mVisitaID);
        setCartID(0);
        setIsVisitSaving(false);
        return record.mVisitaID;
    };

    const fetchVisitLine = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetVisitLine({mEsameID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            let rec = (recs.length !== 0) ? recs[0] : {};
            setVisitLine(rec);
        } else {
            const newRecord = {mEsameID: 0}
            setVisitLine(newRecord);
        }
    }

    const fetchVisitLines = async (pFromDate, pToDate, pRoomID = null, pPageSize = null) => {
        let body = {};
        body.mFilterFrom = pFromDate.clone().format("YYYYMMDD");
        body.mFilterTo = pToDate.clone().format("YYYYMMDD");
        if (pRoomID) body.mRoom = {mRoomID: pRoomID};
        body.mPageSize = pPageSize ?? 10000;
        const res = await webServices.wsGetVisitLine(body, alertContext, true);
        if (res.responseAnyError) return;
        const recs = res.responseData;
        return recs;
    }

    const fetchVisitLinesShort = async (pFromDate, pToDate, pRoomID = null) => {
        let body = {};
        body.mFilterFrom = pFromDate.clone().format("YYYYMMDD");
        body.mFilterTo = pToDate.clone().format("YYYYMMDD");
        if (pRoomID) body.mRoomID = pRoomID;
        const res = await webServices.wsGetVisitLineShort(body, alertContext, true);
        if (res.responseAnyError) return;
        const recs = res.responseData;
        return recs;
    }


    const addVisitLineToCart = async (item) => {
        const longDateTimeFormat = commonContext.getPlanningParameter("DATETIME_FORMAT_LONG")
        let cartItem = {}
        cartItem.mDataAppuntamento = moment(item.mDataAppuntamento).format(longDateTimeFormat);
        cartItem.mPrestazione = {mPrestazioneID: item.mPrestazione.mPrestazioneID};
        cartItem.mDurata = (item.mDurataMinuti && item.mDurataMinuti !== 0) ? parseInt(item.mDurataMinuti / commonContext.getPlanningParameter("SLOT_SIZE")) : 0;
        cartItem.mPrezzoListino = parseMoney(item.mPrezzoListino);
        cartItem.mClientPrice = parseMoney(item.mClientPrice);
        cartItem.mRoom = {mRoomID: item.mRoom.mRoomID};
        cartItem.mVisita = {}
        cartItem.mVisita.mVisitaID = getStorageCartID();
        cartItem.mVisita.mConvention = {mConventionID: item.mConvention.mConventionID};
        cartItem.mAssignedDoctor = {
            mUserID: item.mAssignedDoctor.mUserID
        };
        setIsVisitSaving(true);
        const res = await webServices.wsAddMedicalServiceToCart(cartItem, alertContext, true);
        setIsVisitSaving(false);
        if (res.responseAnyError) return;
        let rec = res.responseData;
        await fetchAndSetCartVisit(rec.mVisitaID);
        await fetchAndSetVisit(rec.mVisitaID);
        return rec.mVisitaID;
    }

    const addVisitLineToVisit = async (item) => {
        const longDateTimeFormat = commonContext.getPlanningParameter("DATETIME_FORMAT_LONG")
        let cartItem = {}
        cartItem.mDataAppuntamento = moment(item.mDataAppuntamento).format(longDateTimeFormat);
        cartItem.mPrestazione = {mPrestazioneID: item.mPrestazione.mPrestazioneID};
        cartItem.mDurata = (item.mDurataMinuti && item.mDurataMinuti !== 0) ? parseInt(item.mDurataMinuti / commonContext.getPlanningParameter("SLOT_SIZE")) : 0;
        cartItem.mPrezzoListino = parseMoney(item.mPrezzoListino);
        cartItem.mClientPrice = parseMoney(item.mClientPrice);
        cartItem.mRoom = {mRoomID: item.mRoom.mRoomID};
        cartItem.mVisita = {}
        cartItem.mVisita.mVisitaID = visit.mVisitaID;
        cartItem.mVisita.mConvention = {mConventionID: item.mConvention.mConventionID}
        setIsVisitSaving(true);
        const res = await webServices.wsAddMedicalServiceToCart(cartItem, alertContext, true);
        setIsVisitSaving(false);
        if (res.responseAnyError) return;
        let rec = res.responseData;
        await fetchAndSetVisit(rec.mVisitaID);
        if (rec.mVisitaID === getStorageCartID()) {
            setCartID(0);
            await fetchAndSetCartVisit(rec.mVisitaID);
        }
        return rec.mVisitaID;
    }

    const setVisitLinesAssignee = async (vls, doctorID) => {

        vls = vls.map((item) => {
            return {mEsameID: item.mEsameID, mAssignedDoctor: {mUserID: doctorID}}
        })
        let tempBody = {};
        tempBody.mListOfEsami = vls;
        setIsVisitSaving(true);
        let res = await webServices.wsSetVisitLineAssignee(tempBody, alertContext, true);
        setIsVisitSaving(false);
        if (res.responseAnyError) return;
        let rec = res.responseData;
        await fetchAndSetVisit(visit.mVisitaID);
        return true;
    }

    const updateVisitLine = async (item) => {
        const longDateTimeFormat = commonContext.getPlanningParameter("DATETIME_FORMAT_LONG")
        let cartItem = {};
        cartItem.mDataAppuntamento = moment(item.mDataAppuntamento).format(longDateTimeFormat);
        cartItem.mPrestazione = {mPrestazioneID: item.mPrestazione.mPrestazioneID};
        cartItem.mDurata = (item.mDurataMinuti && item.mDurataMinuti !== 0) ? parseInt(item.mDurataMinuti / commonContext.getPlanningParameter("SLOT_SIZE")) : 0;
        cartItem.mPrezzoListino = parseMoney(item.mPrezzoListino);
        cartItem.mClientPrice = parseMoney(item.mClientPrice);
        cartItem.mRoom = {mRoomID: item.mRoom.mRoomID};
        cartItem.mVisita = {}
        cartItem.mVisita.mVisitaID = visit.mVisitaID;
        cartItem.mVisita.mConvention = {mConventionID: item.mConvention.mConventionID}
        cartItem.mEsameID = item.mEsameID;
        setIsVisitSaving(true);
        const res = await webServices.wsAddMedicalServiceToCart(cartItem, alertContext, true);
        setIsVisitSaving(false);
        if (res.responseAnyError) return;
        let rec = res.responseData;
        await fetchVisitLine(item.mEsameID);
        await fetchAndSetVisit(visit.mVisitaID);
        return visit.mVisitaID;
    };

    const saveVisitLine = async (bookingItemToSave) => {
        let resVisit = 0;
        switch (bookingMode) {
            case 0:
                break
            case 1:
                resVisit = await addVisitLineToCart(bookingItemToSave)
                break
            case 20:
                resVisit = await addVisitLineToCart(bookingItemToSave)
                break
            case 21:
                resVisit = await addVisitLineToVisit(bookingItemToSave)
                break
            case 3:
                resVisit = await updateVisitLine(bookingItemToSave)
                break
            case 4:
                break
            case 6:
                resVisit = await addVisitLineToCart(bookingItemToSave)
                break
            default:
                break
        }
        return resVisit;
    }

    const changeVisitSelectedTab = (tab) => {
        const newState = {
            ...bookingState,
            selectedTab: tab
        }
        setBookingState(newState);
    }

    const addDaysPlanningSelectedDate = (days) => {
        const newDate = bookingState.selectedDate.clone().add(days, 'day');
        setPlanningSelectedDate(newDate);
    }

    const setPlanningSelectedDate = (newDate) => {
        let newValue = {
            ...bookingState,
            selectedDate: newDate.clone(),
            selectedRoom: 0,
            selectedSlot: null,
        }
        setBookingState(newValue);
    }

    const resetPlanningSelectedDate = () => {
        let newValue = {
            ...bookingState,
            selectedDate: moment(),
            selectedRoom: 0,
            selectedSlot: null,
        }
        setBookingState(newValue);
    }

    const setPlanningSelectedRoom = (newRoom) => {
        let newValue = {
            ...bookingState,
            selectedRoom: newRoom,
        }
        setBookingState(newValue);
    }

    const resetPlanningSelectedRoom = () => {
        let newValue = {
            ...bookingState,
            selectedRoom: 0,
        }
        setBookingState(newValue);
    }

    const openBooking = (pBookingItem, mode) => {
        let dtAppointment = null;
        let room = {};
        let branch = {};
        let convention = {mConventionID: 0};
        let assignedDoctor = {mUserID: 0};
        let medicalServiceID = 0;
        let codicePadre = null;
        let codiceFiglio = null;
        let duration = 0;
        let clientPrice = 0;
        let price = 0;
        let visitLineID = 0;
        let visitID = 0;

        let prevState = {
            mode: bookingMode,
            bookingState: bookingState,
            bookingItem: bookingItem
        }
        setPrevState(prevState);
        let newState = {};
        switch (mode) {
            case 0:
                newState = {
                    ...bookingState,
                    selectedRoom: 0,
                    selectedSlot: null
                }
                break
            case 1:
                if (pBookingItem.room === 0 || !pBookingItem.slot) return
                if (!JSONIsEmpty(cart) && cart.mConvention) convention = cart.mConvention; else fetchAndSetVisit(0).then(res => {
                });
                dtAppointment = pBookingItem.slot.clone();
                room = {mRoomID: parseInt(pBookingItem.room)};
                newState = {
                    ...bookingState,
                    selectedRoom: parseInt(pBookingItem.room),
                    selectedSlot: dtAppointment
                }
                break
            case 20:
                visitID = cartID;
                if (!JSONIsEmpty(visit) && cartID !== 0) convention = {mConventionID: cart.mConvention.mConventionID}; else fetchAndSetVisit(0).then(res => {
                });
                dtAppointment = moment();
                room = {mRoomID: 0};
                newState = {
                    ...bookingState,
                    selectedRoom: 0,
                    selectedSlot: dtAppointment
                }
                medicalServiceID = 0;
                break
            case 21:
                visitID = visit.mVisitaID;
                convention = {mConventionID: visit.mConvention.mConventionID};
                dtAppointment = (pBookingItem.slot) ? pBookingItem.slot.clone() : null;
                room = {mRoomID: pBookingItem.room};
                newState = {
                    ...bookingState,
                    selectedRoom: parseInt(pBookingItem.room),
                    selectedSlot: dtAppointment
                }
                medicalServiceID = 0;
                break
            case 3:
                if (!pBookingItem.visitLine) return;
                if (!pBookingItem.visit) return;
                setVisitLine(pBookingItem.visitLine);
                if (pBookingItem.visit.mConvention) convention = pBookingItem.visit.mConvention;
                dtAppointment = moment(pBookingItem.visitLine.mDataAppuntamento);
                medicalServiceID = pBookingItem.visitLine.mPrestazione.mPrestazioneID;
                codicePadre = pBookingItem.visitLine.mPrestazione.mCodicePadre;
                codiceFiglio = pBookingItem.visitLine.mPrestazione.mCodiceFiglio;
                duration = pBookingItem.visitLine.mDurata;
                clientPrice = parseMoney(pBookingItem.visitLine.mClientPrice);
                price = parseMoney(pBookingItem.visitLine.mPrezzoListino);
                room = {mRoomID: pBookingItem.visitLine.mRoom.mRoomID};
                visitLineID = pBookingItem.visitLine.mEsameID;
                visitID = pBookingItem.visitLine.mVisita.mVisitaID;
                newState = {
                    ...bookingState,
                    selectedRoom: pBookingItem.visitLine.mRoom.mRoomID,
                    selectedSlot: dtAppointment
                }
                break
            case 4:
                if (!pBookingItem.visit) return;
                fetchAndSetVisit(pBookingItem.visit.mVisitaID).then(res => {
                });
                visitID = pBookingItem.visit.mVisitaID;
                let selTab = 0;
                if (checkVisitRealPatientIsNull()) {
                    selTab = 1;
                }
                newState = {
                    ...bookingState,
                    selectedRoom: 0,
                    selectedSlot: null,
                    selectedTab: selTab
                }
                break
            case 5:
                newState = {
                    ...bookingState
                }
                break
            case 6:
                if (pBookingItem.mRoom === 0 || !pBookingItem.mDataAppuntamento) return
                if (!JSONIsEmpty(cart) && cart.mConvention)
                    convention = cart.mConvention;
                else
                    fetchAndSetVisit(0).then(res => { });
                dtAppointment = pBookingItem.mDataAppuntamento.clone();
                room = {mRoomID: parseInt(pBookingItem.mRoom)};
                branch = {mBrancaID: parseInt(pBookingItem.mBranch)};
                assignedDoctor = pBookingItem.mAssignedDoctor;
                newState = {
                    ...bookingState,
                    selectedRoom: parseInt(pBookingItem.mRoom),
                    selectedBranch: parseInt(pBookingItem.mBranch),
                    selectedSlot: dtAppointment
                }
                console.log(newState);
                break
            default:
                break
        }
        setBookingState(newState);

        let newItem = {};
        newItem.mDataAppuntamento = dtAppointment;
        newItem.mPrestazione = {mPrestazioneID: medicalServiceID,};
        newItem.mCodicePadre = codicePadre;
        newItem.mCodiceFiglio = codiceFiglio;
        newItem.mDurata = duration;
        newItem.mDurataMinuti = duration * commonContext.getPlanningParameter("SLOT_SIZE");
        newItem.mPrezzoListino = parseMoney(price);
        newItem.mClientPrice = parseMoney(clientPrice);
        newItem.mRoom = room;
        newItem.mConvention = convention;
        newItem.mEsameID = visitLineID;
        newItem.mVisitaID = visitID;
        newItem.mAssignedDoctor = assignedDoctor;

        setBookingItem(newItem);
        setBookingMode(mode);
    }

    const updateBookingFromAvailability = (slot, room) => {
        let newBookingItem = {...bookingItem};
        newBookingItem.mDataAppuntamento = slot.timeBegin;
        newBookingItem.mRoom = room;
        setBookingItem({...newBookingItem});
        return newBookingItem;
    }

    const getModeURL = (mode) => {
        let url = "";
        switch (mode) {
            case -1:
                url = "/Booking/RoomsPlanner";
                break
            case 0:
                url = "/Booking/RoomsPlanner";
                break
            case 1:
                url = "/Booking/TakeBooking";
                break
            case 20:
                url = "/Booking/TakeBooking";
                break
            case 21:
                url = "/Booking/TakeBooking";
                break
            case 3:
                url = "/Booking/TakeBooking";
                break
            case 4:
                url = "/Booking/VisitDetail";
                break
            case 5:
                url = "/Booking/IntervalsScheduler";
                break
            case 6:
                url = "/Booking/TakeBooking";
                break
            default:
                break
        }
        return url;
    }

    const getPrevMode = () => {
        return prevState.mode;
    }

    const backToPrevState = () => {
        if (prevState.mode === 0) {
            goToRoomsPlanner();
            return;
        }
        let newBookingState = {...prevState.bookingState};
        setBookingState(newBookingState);
        setBookingItem(prevState.bookingItem);
        setBookingMode(prevState.mode);
    }

    const fetchAndSetCartVisit = async (pRecordId) => {
        const resVisit = await fetchVisit(pRecordId);
        const isCartEmpty = (!resVisit || JSONIsEmpty(resVisit) || (resVisit.mListOfEsami ?? []).length === 0);
        const cartID = isCartEmpty ? 0 : pRecordId;
        const cart = (pRecordId !== 0) ? isCartEmpty ? {} : resVisit : {};
        setStorageCartID(cartID);
        setCart(cart);
    }

    const getCartVisitLinesCount = () => {
        return (cart && !JSONIsEmpty(cart) && cart.mListOfEsami) ? cart.mListOfEsami.length : 0;
    }

    const cartIsEmpty = () => {
        return getCartVisitLinesCount() === 0;
    }

    const getVisitModule = async (visitID, fileTypeID) => {
        let body = {}
        body.mContextualRecordID = visitID;
        body.mTemplateFileNumber = fileTypeID.toString();
        let res = await webServices.wsPrintContextualReport(body, alertContext, true);
        if (res.responseAnyError) return;
        return res.responseData ?? {};
    }

    const goPrevSchedulerWeek = () => {
        const newFromDate = schedulerState.fromDate.clone().add(-1, 'week');
        const newToDate = newFromDate.clone().endOf('week');
        const newState = {
            ...schedulerState,
            fromDate: newFromDate,
            toDate: newToDate,
        }
        setSchedulerState(newState);
    }

    const setSchedulerLoaded = (bool) => {
        const newState = {
            ...schedulerState
        }
        setSchedulerState(newState);
    }

    const goNextSchedulerWeek = () => {
        const newFromDate = schedulerState.fromDate.clone().add(1, 'week');
        const newToDate = newFromDate.clone().endOf('week');
        const newState = {
            ...schedulerState,
            fromDate: newFromDate,
            toDate: newToDate
        }
        setSchedulerState(newState);
    }

    const calculateSchedulerDays = async () => {

        const shortDateFormat = commonContext.getPlanningParameter("DATE_FORMAT_SHORT");

        let schedulerDays = [];

        let currentDay = schedulerState.fromDate.clone();
        let endDay = schedulerState.toDate.clone();

        while (currentDay <= endDay) {

            const nextDay = currentDay.clone().add(1, 'day');
            const dayNumber = currentDay.isoWeekday();

            let result = {};
            result.slotDate = currentDay;
            result.slotDateWeekDay = dayNumber;
            result.slotDateWeekDayName = commonContext.getSlotNameOfDayLabel(currentDay);
            result.slotDateLabel = commonContext.getSlotDateLabel(currentDay);

            schedulerDays.push(result);
            currentDay = nextDay;
        }

        return schedulerDays;
    }

    const calculateSchedulerRoomSlots = (day, pi, vls, roomID) => {

        const slotDuration = commonContext.getPlanningParameter("SLOT_SIZE");
        const shortDateFormat = commonContext.getPlanningParameter("DATE_FORMAT_SHORT");
        const shortTimeFormat = commonContext.getPlanningParameter("TIME_FORMAT_SHORT");
        const longDateTimeFormat = commonContext.getPlanningParameter("DATETIME_FORMAT_LONG");

        const dayNumber = day.isoWeekday();

        let schedulerSlots = [];

        pi = pi.filter(pi=> pi.mPlanning.mRoom.mRoomID === roomID && pi.mPlanning.mGiorno === dayNumber && day.isBetween(moment(pi.mDateFrom), moment(pi.mDateTo), 'day', "[]"));

        vls = vls.filter(vl => vl.mRoomID === roomID);

        vls = vls.map((vl) => {
            const durataMins = vl.mDurata * slotDuration;
            vl.mDurataMinuti = durataMins;
            vl.mDataAppuntamento = moment(vl.mDataAppuntamento);
            vl.mDataFineAppuntamento = vl.mDataAppuntamento.clone().add(durataMins, 'minutes');
            vl.mOraAppuntamentoString = vl.mDataAppuntamento.clone().format(shortTimeFormat);
            return vl;
        });

        pi.map((slot) => {

            let firstWeekDay = moment(slot.mDateFrom).isoWeekday(dayNumber);
            firstWeekDay = (firstWeekDay < moment(slot.mDateFrom)) ? firstWeekDay.add(7, 'days') : firstWeekDay;

            const diffDays = day.diff(firstWeekDay, 'days');
            const schedulingDays = schedulerFreqOptions.filter(d => d.mSchedulingID === slot.mSchedule)[0].mSchedulingDays;

            slot.mIntervalSlots = [];
            slot.mTotalSlots = 0;
            slot.mOccupiedSlots = 0;
            slot.mFreeSlots = 0;

            if (((diffDays % schedulingDays) === 0)) {

                const hoursFrom = slot.mHourFrom.hours();
                const minutesFrom = slot.mHourFrom.minutes();
                const hoursTo = slot.mHourTo.hours();
                const minutesTo = slot.mHourTo.minutes();

                const intervalDateTimeFrom = moment(day).clone().add(hoursFrom, "hours").add(minutesFrom, "minutes");
                const intervalDateTimeTo = moment(day).clone().add(hoursTo, "hours").add(minutesTo, "minutes");

                slot.mIntervalDateTimeFrom = intervalDateTimeFrom.clone().format(shortDateFormat);
                slot.mIntervalDateTimeTo = intervalDateTimeTo.clone().format(shortDateFormat);

                const exceptions = slot.mListOfClosure ?? [];
                const dateFrom = moment(slot.mIntervalDateTimeFrom, shortDateFormat).format(longDateTimeFormat);
                const filtered = exceptions.filter(e => e.mClosureDate === dateFrom);

                let totalSlots = 0;
                let occupiedSlots = 0;
                let bOccupied = false;
                let bClosed = false;

                if (filtered.length === 0) {

                    /* inizio dalla data-ora dello slot e aggiunto di volta in volta la larghezza dello slot e vedo se è occupato */

                    let slotBegin = intervalDateTimeFrom.clone();
                    let slotEnd = slotBegin.clone().add(slotDuration, "minutes");

                    while (slotBegin < intervalDateTimeTo) {

                        slotEnd = slotBegin.clone().add(slotDuration, "minutes");

                        bOccupied = false;

                        let intervalSlot = {
                            mSlotBeginDateTime: slotBegin,
                            mSlotEndDateTime: slotEnd,
                            mSlotVisitlines: []
                        };

                        /* visite cadono all'interno solo per la fine */

                        const endingVisitLines = vls.filter(vl => vl.mDataFineAppuntamento.isBetween(slotBegin, slotEnd, null, "(]") && vl.mDataAppuntamento < slotBegin);
                        bOccupied = (endingVisitLines.length > 0) ? true : bOccupied;
                        intervalSlot.mSlotVisitlines = intervalSlot.mSlotVisitlines.concat(endingVisitLines);

                        /* visite che iniziano prima e finiscono dopo */

                        const overlappingVisitLines = vls.filter(vl => vl.mDataAppuntamento < slotBegin && vl.mDataFineAppuntamento > slotEnd);
                        bOccupied = (overlappingVisitLines.length > 0) ? true : bOccupied;
                        intervalSlot.mSlotVisitlines = intervalSlot.mSlotVisitlines.concat(overlappingVisitLines);

                        /* visite cadono all'interno solo per l'inizio */

                        const beginningVisitLines = vls.filter(vl => vl.mDataAppuntamento.isBetween(slotBegin, slotEnd, null, "[)"));
                        bOccupied = (beginningVisitLines.length > 0) ? true : bOccupied;
                        intervalSlot.mSlotVisitlines = intervalSlot.mSlotVisitlines.concat(beginningVisitLines);

                        totalSlots += 1;
                        occupiedSlots += bOccupied ? 1 : 0;

                        slot.mIntervalSlots.push(intervalSlot);

                        slotBegin = slotEnd.clone();

                    }

                } else {
                    bClosed = true;
                }

                slot.mTotalSlots = totalSlots;
                slot.mOccupiedSlots = occupiedSlots;
                slot.mFreeSlots = totalSlots - occupiedSlots;
                slot.mClosed = bClosed;

                schedulerSlots.push(slot);

            }
        })
        return schedulerSlots;
    }

    const normalizePlanningDay = (planningDay) => {
        const days = daysWithName.filter(d => d.mDayID === planningDay.mGiorno) ?? [];
        const day = days.length > 0 ? days[0].mDayName : "";
        return {
            ...planningDay,
            mGiornoNome: day
        };
    }

    const fetchPlanningDays = async () => {
        const res = await webServices.wsGetPlanningDays({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        recs = recs.map((interval) => {
            return normalizePlanningDay(interval);
        });
        return recs;
    }

    const fetchPlanningDaysByRoom = async (pRoomID) => {
        const res = await webServices.wsGetPlanningDays({mRoom: {mRoomID: pRoomID}}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        recs = recs.map((interval) => {
            return normalizePlanningDay(interval);
        });
        return recs;
    }

    const fetchPlanningDay = async (pPlanID) => {
        if (pPlanID !== 0) {

        } else {
            return JSONClone(newPlanningDay);
        }

    }

    const updatePlanningDay = async (pRecord) => {
        const res = await webServices.wsUpdatePlanningDay(pRecord, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        return (recs.length !== 0) ? recs[0] : null;
    }

    const deletePlanningDay = async (pRecord) => {
        const res = await webServices.wsDeletePlanningDay(pRecord, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        return (recs.length !== 0) ? recs[0] : null;
    }

    const normalizeInterval = (interval) => {
        const shortTimeFormat = commonContext.getPlanningParameter("TIME_FORMAT_SHORT");
        return {
            ...interval,
            mHourFrom: moment(interval.mHourFrom, shortTimeFormat),
            mHourTo: moment(interval.mHourTo, shortTimeFormat),
            mHourFromLabel: interval.mHourFrom,
            mHourToLabel: interval.mHourTo,
            rgbColor: "rgb(" + interval.mPlanning.mColor + ")",
            mDoctor: {
                ...interval.mDoctor,
                mCompleteName: (interval.mDoctor) ? getCompleteName(interval.mDoctor.mFirstName, interval.mDoctor.mLastName) : emptyCompleteName,
                mCompleteShortName: (interval.mDoctor) ? getCompleteShortName(interval.mDoctor.mFirstName, interval.mDoctor.mLastName) : emptyCompleteName
            }
        };
    }

    const fetchIntervalsByPlanID = async (mPlanID) => {
        const res = await webServices.wsGetPlanningIntervals({mPlanning: {mPlanID: mPlanID}}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        recs = recs.filter(i => i.mPlanning);
        recs = recs.map((interval) => {
            return normalizeInterval(interval);
        });
        return recs;
    }

    const fetchIntervals = async (fromDate, toDate, roomID = null) => {
        const longDateTimeFormat = commonContext.getPlanningParameter("DATETIME_FORMAT_LONG")
        let body = {};
        body.mDateFrom = fromDate.clone().format(longDateTimeFormat);
        body.mDateTo = toDate.clone().format(longDateTimeFormat);
        if (roomID) { body.mRoom = {mRoomID: roomID}; }
        const res = await webServices.wsGetPlanningIntervals(body, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        recs = recs.filter(i => i.mPlanning);
        recs = recs.map((interval) => {
            return normalizeInterval(interval);
        });
        return recs;
    }

    const fetchInterval = async (mPlanning, mDoctor) => {
        if (mPlanning !== 0 || mDoctor !== 0) {

        } else {
            return JSONClone(newPlanningInterval);
        }

    }

    const checkInterval = (interval) => {

        const openingTimeConfig = commonContext.getPlanningParameter("OPENING_TIME");
        const closingTimeConfig = commonContext.getPlanningParameter("CLOSING_TIME");
        const longDateTimeFormat = commonContext.getPlanningParameter("DATETIME_FORMAT_LONG");
        const shortTimeFormat = commonContext.getPlanningParameter("TIME_FORMAT_SHORT");
        const slotDurationConfig = commonContext.getPlanningParameter("SLOT_SIZE");

        let intervalBegin = moment(interval.mDateFrom).startOf('day').format(longDateTimeFormat);
        let intervalFromTime = moment(interval.mHourFrom, shortTimeFormat);
        let intervalToTime = moment(interval.mHourTo, shortTimeFormat);
        let openingTime = moment(openingTimeConfig, shortTimeFormat);
        let closingTime = moment(closingTimeConfig, shortTimeFormat);

        let slotBegin = openingTime.clone();
        let slotEnd = openingTime.clone();

        /* verifico che l'intervallo sia tra gli orari di apertura e chiusura */
        if (intervalFromTime < openingTime) {
            return false
        }
        ;
        if (intervalToTime > closingTime) {
            return false
        }
        ;

        let bBegin = false;
        let bEnd = false;

        /* verifico che l'inizio coincida con uno slot */
        while (slotBegin < closingTime) {

            slotEnd = slotBegin.clone().add(slotDurationConfig, "minutes");

            if (intervalFromTime.isSame(slotBegin)) bBegin = true;
            if (intervalToTime.isSame(slotEnd)) bEnd = true;

            slotBegin = slotEnd.clone();

        }

        if (!(bBegin && bEnd)) {
            return false
        }
        ;

        return true;
    }

    const updateInterval = async (pRecord) => {
        const shortTimeFormat = commonContext.getPlanningParameter("TIME_FORMAT_SHORT");
        const longDateTimeFormat = commonContext.getPlanningParameter("DATETIME_FORMAT_LONG");
        pRecord = {
            ...pRecord,
            mDateFrom: moment(pRecord.mDateFrom).startOf('day').format(longDateTimeFormat),
            mDateTo: moment(pRecord.mDateTo).startOf('day').format(longDateTimeFormat),
            mHourFrom: pRecord.mHourFrom.format(shortTimeFormat),
            mHourTo: pRecord.mHourTo.format(shortTimeFormat)
        }
        const checkPassed = checkInterval(pRecord);
        if (checkPassed === false) return false;
        const res = await webServices.wsUpdatePlanningInterval(pRecord, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        return (recs.length !== 0) ? recs[0] : null;
    }

    const deleteInterval = async (pRecord) => {
        const res = await webServices.wsDeletePlanningInterval(pRecord, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        return (recs.length !== 0) ? recs[0] : null;
    }

    const closeInterval = async (pRecord) => {
        const longDateTimeFormat = commonContext.getPlanningParameter("DATETIME_FORMAT_LONG");
        const shortDateFormat = commonContext.getPlanningParameter("DATE_FORMAT_SHORT");
        const body = {
            mIntervalID: pRecord.mIntervalID,
            mClosureDate: moment(pRecord.mIntervalDateTimeFrom, shortDateFormat).format(longDateTimeFormat)
        }
        const res = await webServices.wsClosePlanningInterval(body, alertContext, true);
        if (res.responseAnyError) return;
        return res.responseData;
    }

    const openInterval = async (pRecord) => {
        const longDateTimeFormat = commonContext.getPlanningParameter("DATETIME_FORMAT_LONG");
        const shortDateFormat = commonContext.getPlanningParameter("DATE_FORMAT_SHORT");
        const body = {
            mIntervalID: pRecord.mIntervalID,
            mClosureDate: moment(pRecord.mIntervalDateTimeFrom, shortDateFormat).format(longDateTimeFormat)
        }
        const res = await webServices.wsOpenPlanningInterval(body, alertContext, true);
        if (res.responseAnyError) return;
        return res.responseData;
    }

    const fetchVisitAttachments = async (recordId) => {
        const body = {};
        body.mContextualRecordID = recordId;
        body.mTableName = "YB_VISITA";
        if (recordId !== 0) {
            const res = await webServices.wsGetAttachmentsList(body, alertContext, true);
            if (res.responseAnyError) return;
            let resData = res.responseData;
            return resData;
        } else {
            return null;
        }
    }

    const updateVisitAttachment = async (recordId, pFileName, pListOfBytes) => {
        const body = {};
        body.mContextualRecordID = recordId;
        body.mFileName = pFileName;
        body.mListOfBytes = pListOfBytes;
        body.mTableName = "YB_VISITA";
        let res = await webServices.wsUploadGenericDocument(body, alertContext, true);
        if (res.responseAnyError) return null;
        return res.responseAnyError;
    }

    const downloadVisitAttachment = async (recordId) => {
        const body = {};
        body.mFileID = recordId;
        let res = await webServices.wsDownloadGenericDocument(body, alertContext, true);
        if (res.responseAnyError) return null;
        let resData = res.responseData;
        return resData;
    }

    return (
        <BookingContext.Provider
            value={{
                goToRoomsPlanner,
                calculatePlanningRoomSlots,
                planningVisitLines,
                getPlanningRoomVisitLines,
                bookingState,
                setPlanningSelectedDate,
                resetPlanningSelectedDate,
                setPlanningSelectedRoom,
                resetPlanningSelectedRoom,
                addDaysPlanningSelectedDate,
                visit,
                setVisit,
                checkVisitTempPatientIsNull,
                checkVisitRealPatientIsNull,
                resetVisitRealPatient,
                saveVisitRealPatient,
                saveVisitTmpPatient,
                saveVisitASL,
                changeVisitSelectedTab,
                acceptVisit,
                fetchVisitLines,
                fetchVisitLinesShort,
                visitLine,
                setVisitLine,
                saveVisitLine,
                isVisitSaving,
                acceptVisitLine,
                getVisitLinesCount,
                visitIsEmpty,
                canChangeVisitTempPatient,
                canChangeVisitRealPatient,
                canSaveVisit,
                canAcceptVisit,
                canInvoicingVisit,
                canPrintModules,
                getVisitLineStatusColor,
                getVisitLineInvoicingColor,
                setVisitLinesAssignee,
                openBooking,
                bookingMode,
                updateBookingFromAvailability,
                getStorageCartID,
                deleteVisitLineFromVisit,
                bookingItem,
                setBookingItem,
                getCartVisitLinesCount,
                cartIsEmpty,
                backToPrevState,
                getModeURL,
                getPrevMode,
                getVisitModule,
                VISIT_STATUS,
                VISIT_INVOICING_STATUS,
                VISIT_LINE_STATUS,
                VISIT_LINE_INVOICING_STATUS,
                fetchPlanningDays,
                fetchPlanningDaysByRoom,
                schedulerDayOptions,
                schedulerFreqOptions,
                fetchPlanningDay,
                updatePlanningDay,
                fetchIntervalsByPlanID,
                fetchIntervals,
                fetchInterval,
                updateInterval,
                deleteInterval,
                closeInterval,
                openInterval,
                schedulerState,
                goPrevSchedulerWeek,
                setSchedulerLoaded,
                goNextSchedulerWeek,
                calculateSchedulerDays,
                calculateSchedulerRoomSlots,
                deletePlanningDay,
                fetchVisitAttachments,
                updateVisitAttachment,
                downloadVisitAttachment
            }}
        >
            {props.children}
        </BookingContext.Provider>
    );
};

export {BookingProvider, BookingContext};