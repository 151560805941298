import {Button, Popover, Typography} from "@mui/material";
import React, {useEffect, useRef} from "react";
import {CustomButton} from "./ActionsToolbar";

export const TextGridPopover = ({
                                    ref,
                                    getTextFunc
                                }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [textValue, setTextValue] = React.useState(null);

    const divRef = useRef();

    const handleClose = () => {
        setAnchorEl(null);
        setTextValue(null);
    };

    const customAction = ()=>{
        setAnchorEl(divRef.current);
        setTextValue("...");
        getTextFunc().then((res)=>{
            setTextValue(res);
        })
    }

    const open = Boolean(anchorEl);
    const id = open ? 'grid-popover' : undefined;

    return (
        <div ref={divRef}>
            {getTextFunc && <CustomButton customAction={customAction} disabled={false} customIcon={"detail"} customTooltip={"Visualizza"}/>}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{p: 2}}>{textValue}</Typography>
            </Popover>
        </div>
    )
}