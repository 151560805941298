import {useTheme} from "@mui/material";
import moment from "moment/moment";
import React, {createContext, useContext, useState} from "react";
import {getCompleteName, JSONClone, setDataLoading} from "../common";
import * as webServices from "../services/WebServices";
import {CustomAlertContext} from "./AlertContext";
import {AuthContext} from "./AuthContext";

const ManagementContext = createContext();

const initial_management_state = {
    selectedDate: moment(),
    loaded: false
}

const initial_prev_state = {
    mode: -1,
    managementState: {},
    managementItem: {}
}

const initial_dashboard_filter = {
    mYearStart: moment(new Date(), "DD/MM/YYYY"),
    mYearEnd: moment(new Date(), "DD/MM/YYYY"),
}

const newSmsCampaign = {
    mServiceID: 0,
    mCompany: {mCompanyID: 0, mCompanyName: ""},
    mServiceCode: "",
    mServiceDescription: "",
    mScheduledBy: moment(),
    mMessageTemplate: "",
    mReady: true,
    mActive: true,
    mSystemRecord: false

}

const newSmsQueueItem = {
    mServiceQueueID: 0,
    mCreationDate: "0001-01-01T00:00:00",
    mScheduledDate: "0001-01-01T00:00:00",
    mAckDate: "0001-01-01T00:00:00",
    mService: {mServiceID: 0},
    mTargetPhone: "",
    mCognome: "",
    mNome: "",
    mMessageText: "",
    mOrderID: "",
    mStatus: "",
    mErrorDescription: ""
}

const newSmsItem = {
    mTargetPhone: "",
    mCognome: "",
    mNome: "",
    mMessageText: "",
    mOrderID: 0,
}

const newSmsOrder = {
    mSMSOrderID: 0,
    mSMSOrderDate: moment(),
    mSMSOrderNumber: "",
    mNumberOfSMS: 0,
    mStatus: ""
}

const ManagementProvider = (props) => {

    const alertContext = useContext(CustomAlertContext);
    const authContext = useContext(AuthContext);
    const theme = useTheme();

    const [managementState, setManagementState] = useState(JSONClone(initial_management_state));
    const [managementMode, setManagementMode] = useState(-1); //caricamento
    const [managementItem, setManagementItem] = useState({});

    const [prevState, setPrevState] = useState(JSONClone(initial_prev_state));
    const [dashboardFilter, setDashboardFilter] = useState(JSONClone(initial_dashboard_filter));
    const [smsCampaigns, setSmsCampaigns] = useState(null);
    const [smsOrders, setSmsOrders] = useState([]);
    const [connectedUsers, setConnectedUsers] = useState(null);
    const [logs, setLogs] = useState(null);
    const [anomalies, setAnomalies] = useState(null);

    const openManagement = (management, mode) => {
        let prevState = {
            mode: managementMode,
            managementState: managementState,
            managementItem: managementItem
        }
        setPrevState(prevState);
        let newState = {};
        switch (mode) {
            case 0:
                newState = {
                    ...managementState
                }
                break
            case 1:
                newState = {
                    ...managementState
                }
                break;
            case 2:
                newState = {
                    ...managementState
                }
                break;
            case 3:
                newState = {
                    ...managementState
                }
                break;
        }
        newState.loaded = false;
        setManagementState(newState);
        let newItem = {};
        setManagementItem(newItem);
        setManagementMode(mode);
    }

    const setManagementLoaded = (managementLoaded) => {
        let newValue = {
            ...managementState,
            loaded: managementLoaded
        }
        setManagementState(newValue);
    }

    const getMappedVisitLines = (vls) => {
        const shortDateTimeFormat = authContext.getSystemParameterValue("DATETIME_FORMAT_SHORT");
        if (!vls) return []
        if (vls.length === 0) return []
        return vls.map((vl) => {
            vl.mDataAppuntamentoString = moment(vl.mDataAppuntamento).format(shortDateTimeFormat);
            let assignedDoctor = vl.mAssignedDoctor ?? {}
            vl.mAssignedDoctorCompleteName = getCompleteName(assignedDoctor.mFirstName, assignedDoctor.mLastName);
            vl.mPatientCompleteName = (((vl.mVisita.mPatient ?? {}).mPatientID ?? 0) !== 0) ?
                getCompleteName(vl.mVisita.mPatient.mNomePaziente, vl.mVisita.mPatient.mCognomePaziente) :
                getCompleteName(vl.mVisita.mTempPatient.mNomePaziente, vl.mVisita.mTempPatient.mCognomePaziente);
            // vl.mTipoPagamento = "-";
            // vl.mNumeroRicetta = "-";
            return vl;
        })
    }

    const fetchVisitLines = async (pServerSideFilter, setVisitLines) => {
        setDataLoading(setVisitLines);
        let res = await webServices.wsGetVisitLine(pServerSideFilter, alertContext, true);
        if (res.responseAnyError) return;
        res.responseData = getMappedVisitLines(res.responseData);
        return res;
    }

    const fetchChartData = async (pBody) => {
        let res = await webServices.wsGetChartData(pBody, alertContext, true);
        if (res.responseAnyError) return null;
        let resData = res.responseData;
        return resData;
    };

    const fetchTableData = async (pBody) => {
        let res = await webServices.wsGetTableData(pBody, alertContext, true);
        if (res.responseAnyError) return null;
        let resData = res.responseData;
        return resData;
    };

    const fetchSmsCampaigns = async () => {
        setDataLoading(setSmsCampaigns);
        let res = await webServices.wsGetSmsCampaigns({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData.map((rec) => {
            return {
                ...rec,
                mScheduledBy: moment(rec.mScheduledBy)
            };
        })
        setSmsCampaigns(recs);
        return recs;
    }

    const fetchSmsCampaign = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetSmsCampaigns({mServiceID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData.map((rec) => {
                return {
                    ...rec,
                    mScheduledBy: moment(rec.mScheduledBy)
                }
            })
            return (recs.length !== 0) ? recs[0] : newSmsCampaign;
        } else {
            return newSmsCampaign;
        }
    }

    const updateSmsCampaign = async (record) => {
        const longDateTimeFormat = authContext.getSystemParameterValue("DATETIME_FORMAT_LONG");
        record.mScheduledBy = record.mScheduledBy ? moment(record.mScheduledBy).format(longDateTimeFormat) : null;
        webServices.wsUpdateSmsCampaign(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            setSmsCampaigns(null);
        });
    };

    const fetchSmsQueueItems = async (pCampaingID, bReset, serverSideStatus, setServerSideStatus) => {
        const filter = (bReset) ?
            serverSideStatus.defaultFilter :
            serverSideStatus.currentFilter;
        const requestedPage = (bReset ?? false) ? 1 : serverSideStatus.currentPage;
        const body = {
            ...filter,
            mRequestedPage: requestedPage,
            mCampagnaSMS: {mServiceID: pCampaingID},
        }
        const res = await webServices.wsGetSmsQueueItems(body, alertContext, true);
        if (res.responseAnyError) return;
        const newServerSideStatus = {
            ...serverSideStatus,
            currentFilter: filter,
            totalRows: (res.responseDataRowsCount ?? 0),
            totalPages: (res.responseDataPagesCount ?? 0),
            currentPage: (res.responseDataCurrentPage ?? 0),
            pageSize: (res.responseDataPageSize ?? 0)
        }
        setServerSideStatus(newServerSideStatus);
        const recs = res.responseData;
        return recs;
    }

    const fetchSmsQueueItem = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetSmsQueueItems({mServiceQueueID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newSmsQueueItem;
        } else {

            return newSmsQueueItem;
        }
    }

    const fetchSmsItem = async () => {
        return newSmsItem;
    }

    const sendSmsItem = async (record) => {
        const res = await webServices.wsSendSmsQueue(record, alertContext, true);
        if (res.responseAnyError) return;
    }

    const getSmsText = async (record) => {
        const res = await webServices.wsGetSmsText(record, alertContext, true);
        if (res.responseAnyError) return;
        return (res) ? res.responseData : "";
    }

    const fetchSmsCredit = async (recordId) => {
        const res = await webServices.wsGetSmsCredit({}, alertContext, true);
        if (res.responseAnyError) return;
        return (res) ? res.responseData : newSmsQueueItem;
    }

    const fetchConnectedUsers = async () => {
        setDataLoading(setConnectedUsers);
        let res = await webServices.wsGetConnectedUsers({}, alertContext, true);
        if (res.responseAnyError) return null;
        let resData = res.responseData;
        setConnectedUsers(resData);
        return resData;
    };

    const fetchSmsOrders = async () => {
        setDataLoading(setSmsOrders);
        let res = await webServices.wsGetSmsOrders({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        // let recs = res.responseData.map((rec) => {
        //     return {
        //         ...rec,
        //         mScheduledBy: moment(rec.mScheduledBy)
        //     };
        // })
        setSmsOrders(res.responseData);
        return recs;
    }

    const updateSmsOrder = async (record) => {
        const body = {
            ...record,
            mNumberOfSMS: parseInt(record.mNumberOfSMS)
        }
        webServices.wsUpdateSmsOrder(body, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchSmsOrders();
            return res;
        });
    };

    const fetchSmsOrder = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetSmsOrders({mSMSOrderID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            const recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newSmsOrder;
        } else {
            return newSmsOrder;
        }

    }

    const printSmsOrder = async (mSMSOrderID) => {
        let body = {}
        body.mContextualRecordID = mSMSOrderID;
        body.mTemplateFileNumber = "007";
        let res = await webServices.wsPrintContextualReport(body, alertContext, true);
        if (res.responseAnyError) return;
        return res.responseData ?? {};
    }

    const fetchLogs = async () => {
        setDataLoading(setLogs);
        let res = await webServices.wsGetLogs({}, alertContext, true);
        if (res.responseAnyError) return null;
        let resData = res.responseData;
        setLogs(resData);
        return resData;
    };

    const fetchAnomalies = async () => {
        setDataLoading(setAnomalies);
        let body = {mReportGroup: 'Incongruenze'}
        let res = await webServices.wsGetReports(body, alertContext, true);
        if (res.responseAnyError) return null;
        let resData = res.responseData;
        setAnomalies(resData);
        return resData;
    };

    const clearReport = async (recordId) => {
        let body = {mReportID: recordId}
        let res = await webServices.wsClearReport(body, alertContext, true);
        if (res.responseAnyError) return;
        return res.responseData ?? {};
    };

    return (
        <ManagementContext.Provider
            value={{
                managementMode,
                managementState,
                openManagement,
                setManagementLoaded,
                fetchVisitLines,
                dashboardFilter,
                setDashboardFilter,
                fetchChartData,
                fetchTableData,
                fetchSmsCampaigns,
                smsCampaigns,
                fetchSmsCampaign,
                updateSmsCampaign,
                fetchSmsQueueItems,
                fetchSmsQueueItem,
                fetchSmsItem,
                sendSmsItem,
                getSmsText,
                fetchSmsCredit,
                fetchConnectedUsers,
                connectedUsers,
                fetchSmsOrders,
                smsOrders,
                updateSmsOrder,
                fetchSmsOrder,
                printSmsOrder,
                fetchLogs,
                logs,
                fetchAnomalies,
                anomalies,
                clearReport
            }}
        >
            {props.children}
        </ManagementContext.Provider>
    );
};

export {ManagementProvider, ManagementContext};