export default function LinearProgress() {
    return {
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: 10,
                    borderRadius: 50
                },
                bar: {
                    borderRadius: 50
                }
            }
        }
    };
}
