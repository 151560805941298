import {Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import React, {useContext, useEffect, useState} from 'react';
import {JSONClone} from "../../../../common";
import DashboardChart from "../../../../components/Charts/DashboardChart";
import {DashboardDataFilter} from "../../../../components/Charts/DashboardDataFilter";
import {AccountingContext} from "../../../../context/AccountingContext";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {BookingContext} from "../../../../context/BookingContext";
import {ManagementContext} from "../../../../context/ManagementContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {loggedClassNames} from "../../../../theme/Styles";

const initCharts = [
    {
        mChartDescription: "Prestazioni / Anno",
        mChartXTitle: "Anno",
        mChartYTitle: "Prestazioni",
        mChartType: "PRESTAZIONI_PER_ANNO",
        mChartOutputType: "bar",
        mChartData: null,
        mWithFilter: true
    },
    {
        mChartDescription: "Prestazioni / Mese",
        mChartXTitle: "Mese",
        mChartYTitle: "Prestazioni",
        mChartType: "PRESTAZIONI_PER_MESE",
        mChartOutputType: "bar",
        mChartData: null,
        mWithFilter: true
    },
    {
        mChartDescription: "Prestazioni / Anno / Modality",
        mChartXTitle: "Anno N",
        mChartYTitle: "Prestazioni",
        mChartType: "MODALITY_PER_ANNO",
        mChartOutputType: "bar",
        mChartData: null,
        mWithFilter: true
    },
    {
        mChartDescription: "Fatturato / Anno",
        mChartXTitle: "Anno",
        mChartYTitle: "Fatturato",
        mChartType: "FATTURATO_PER_ANNO",
        mChartOutputType: "line",
        mXField: "mAnno",
        mSeries: ["mFatture", "mNoteCredito", "mTotaleFatturato"],
        mSeriesLabels: ["Fatture", "Note Credito", "Totale Fatturato"],
        mChartData: null,
        mWithFilter: false
    },
    {
        mChartDescription: "Fatturato / Anno",
        mChartXTitle: "Anno",
        mChartYTitle: "Fatturato",
        mChartType: "FATTURATO_PER_ANNO",
        mChartOutputType: "table",
        mChartData: null,
        mWithFilter: false,
        mChartColumns: [
            {headerName: "Anno", field: "mAnno", type: "noFilter", flex: 2},
            {headerName: "Fatture €", field: "mFatture", type: "noFilter", flex: 3},
            {headerName: "Note di credito €", field: "mNoteCredito", type: "noFilter", flex: 4},
            {headerName: "Totale €", field: "mTotaleFatturato", type: "noFilter", flex: 3},

        ]
    },
    {
        mChartDescription: "Fatturato % / Anno precedente",
        mChartXTitle: "Anno",
        mChartYTitle: "Fatturato %",
        mChartType: "FATTURATO_PER_ANNO",
        mChartOutputType: "gauge",
        mXField: "mAnno",
        mSeries: ["mTotaleFatturato"],
        mSeriesLabels: ["Fatturato"],
        mLegendLabel: "% rispetto all'anno precedente",
        mRowsToSkip: 1,
        mChartData: null,
        mWithFilter: false
    },
    {
        mChartDescription: "Fatturato / Trimestre",
        mChartXTitle: "Anno",
        mChartYTitle: "Fatturato",
        mChartType: "FATTURATO_PER_TRIMESTRE",
        mChartOutputType: "line",
        mXField: "mTrimestre",
        mSeries: ["mFatture", "mNoteCredito", "mTotaleFatturato"],
        mSeriesLabels: ["Fatture", "Note Credito", "Totale Fatturato"],
        mChartData: null,
        mWithFilter: false
    },
    {
        mChartDescription: "Fatturato / Trimestre",
        mChartXTitle: "Anno",
        mChartYTitle: "Fatturato",
        mChartType: "FATTURATO_PER_TRIMESTRE",
        mChartOutputType: "table",
        mChartData: null,
        mWithFilter: false,
        mChartColumns: [
            {headerName: "Trimestre", field: "mTrimestreSelf", type: "noFilter", flex: 2},
            {headerName: "Fatture €", field: "mFatture", type: "noFilter", flex: 3},
            {headerName: "Note di credito €", field: "mNoteCredito", type: "noFilter", flex: 4},
            {headerName: "Totale €", field: "mTotaleFatturato", type: "noFilter", flex: 3},

        ]
    },
    {
        mChartDescription: "Fatturato % / Trimestre precedente",
        mChartXTitle: "Trimestre",
        mChartYTitle: "Fatturato %",
        mChartType: "FATTURATO_PER_TRIMESTRE",
        mChartOutputType: "gauge",
        mXField: "mTrimestre",
        mSeries: ["mTotaleFatturato"],
        mSeriesLabels: ["Fatturato"],
        mRowsToSkip: 4,
        mChartData: null,
        mWithFilter: false
    },
]

export const ManagementDashboard = (props) => {

    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);
    const alertContext = useContext(CustomAlertContext);
    const accountingContext = useContext(AccountingContext);
    const managementContext = useContext(ManagementContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const columnsWidth = useMediaQuery(theme.breakpoints.up('xxl')) ? 4 : 12;

    const [disabled, setDisabled] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [charts, setCharts] = useState(JSONClone(initCharts));

    const filter = managementContext.dashboardFilter;

    useEffect(() => {
        if (loaded) return;
        getData().then((res) => {
            setCharts(res);
            setLoaded(true);
        });

    }, []);

    const resetData = async () => {
        setCharts(JSONClone(initCharts));
        setDisabled(false);
    }

    const getData = async () => {
        const newCharts = [];
        for (let iChartData = 0; iChartData < charts.length; iChartData += 1) {
            const newChart = JSONClone(charts[iChartData]);
            if (newChart.mChartOutputType === "bar") {
                const pBody = {
                    mChartType: newChart.mChartType,
                    mChartFilter: {mYearStart: filter.mYearStart.year(), mYearEnd: filter.mYearEnd.year()}
                }
                newChart.mChartData = await managementContext.fetchChartData(pBody);
            } else if (newChart.mChartOutputType === "table") {
                const pBody = {
                    mChartType: newChart.mChartType,
                }
                newChart.mChartData = await managementContext.fetchTableData(pBody);
            } else if (newChart.mChartOutputType === "line") {
                const pBody = {
                    mChartType: newChart.mChartType,
                }
                newChart.mChartData = await managementContext.fetchTableData(pBody);
            } else if (newChart.mChartOutputType === "gauge") {
                const pBody = {
                    mChartType: newChart.mChartType,
                }
                newChart.mChartData = await managementContext.fetchTableData(pBody);
            }
            newCharts.push(newChart);
        }
        return newCharts;
    }

    const getDataButton = () => {
        getData().then((res) => {
            setCharts(res);
        });
    }

    if (!loaded) return <></>

    return (
        <>
            <Grid
                container
                display="flex"
                direction={"column"}
                alignItems={"left"}
                justifyContent={"left"}
                spacing={theme.spacing(2)}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Dahsboard di analisi</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DashboardDataFilter
                        showFrom
                        showTo
                        submitFunction={getDataButton}
                        resetFunction={resetData}
                        isDisabled={disabled}
                    />
                </Grid>
                <Grid container item xs={12} spacing={theme.spacing(2)}>
                    {
                        charts.filter(chart => chart.mWithFilter === true).map((chart, index) => {
                            return (
                                <Grid item xs={12} sm={columnsWidth}>
                                    <DashboardChart key={`chart${index}`} chartObject={chart}/>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4">Dahsboard di analisi (storico)</Typography>
                </Grid>
                <Grid container item xs={12} spacing={theme.spacing(2)}>
                    {
                        charts.filter(chart => chart.mWithFilter === false).map((chart, index) => {
                            {
                                return (
                                    <Grid item xs={12} sm={columnsWidth}>
                                        <DashboardChart key={`history-chart${index}`} chartObject={chart}/>
                                    </Grid>
                                )
                            }
                        })
                    }
                </Grid>
            </Grid>
        </>
    );
}