import {FormControl} from "@mui/material";
import React from 'react';
import {SketchPicker} from 'react-color';
import {Controller} from "react-hook-form";

export const FormColorPicker = ({notFullWidth, noFormControl, ...props}) => {

    const getOptions = () => {
        let options = {}
        if (props.required !== null) {
            options.required = {};
            options.required.value = props.required;
            if (props.required === true) {
                options.required.message = "Campo richiesto";
            }
        }
        return options;
    }

    const isDisabled = () => {
        if (props.disabled) {
            return props.disabled;
        } else {
            return false;
        }
    }

    const getValue = (value) => {
        return (value) ?? "0,0,0";
    }

    const getRGBJson = (color) => {
        const cols = color.split(",");
        return {r: cols[0], g: cols[1], b: cols[2]}
    }

    const getStringFromRGB = (color) => {
        return color.rgb.r + "," + color.rgb.g + "," + color.rgb.b;
    }

    return (
        <FormControl fullWidth>
            <Controller
                name={props.field}
                control={props.control}
                render={({
                             field: {onChange, onBlur, value, name, ref},
                             fieldState: {invalid, isTouched, isDirty, error},
                             formState,
                         }) => (
                    <>
                        <SketchPicker
                            name={`color-picker-${name}`}
                            key={`color-picker-${name}`}
                            inputRef={ref}
                            color={getRGBJson(getValue(value))}
                            disabled={isDisabled()}
                            onChange={(color, event) => {
                                onChange(getStringFromRGB(color));
                            }}
                        />
                    </>

                )}
                {...props.register(props.field, getOptions())}
            />
        </FormControl>
    )

}