import {Grid, Typography, useTheme} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import React, {useContext, useEffect, useState} from 'react';
import {columnTypes, defaultColDef, isDataLoading, isDataNull} from "../../../../common";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridRowHeight, gridSizeStyle} from "../../../../theme/Styles";

const SupplierTypes = () => {

    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();

    const [record, setRecord] = useState({});
    const [dataLoaded, setDataLoaded] =useState(false);

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(registriesContext.supplierTypes)) registriesContext.fetchSupplierTypes().then((res) => {
            setDataLoaded(true);
        });
    }, []);

    const [columns, setColumns] = useState([
        {headerName: "Descrizione", field: "mDescrizione"},
        {headerName: "Codice", field: "mCodice"},
    ]);

    if (isDataNull(registriesContext.supplierTypes) || isDataLoading(registriesContext.supplierTypes)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Tipi soggetto erogatore</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{pb: theme.spacing(2)}}>
                        <Grid item xs={12}>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={registriesContext.supplierTypes}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
        </>
    )

}

export default SupplierTypes;