import {Alert, Grid, Link, Stack, Typography, useTheme} from "@mui/material";
import React, {useContext, useState} from 'react';
import {useForm} from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import {FormTextBox} from "../../../components/Form/FormTextBox";
import {AuthContext} from "../../../context/AuthContext";
import {notLoggedClassNames} from "../../../theme/Styles";
import {FormSubmitButton} from "../../../components/Form/FormSubmitButton";
import {BookingContext} from "../../../context/BookingContext";
import {RegistriesContext} from "../../../context/RegistriesContext";
import {CustomAlertContext} from "../../../context/AlertContext";

const init_login_record = {
    mUsername: "",
    mPassword: ""
}

const Login = (props) => {

    const alertContext = useContext(CustomAlertContext);
    const authContext = useContext(AuthContext);
    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);

    const theme = useTheme();
    const notLoggedClasses = notLoggedClassNames(theme);
    let navigate = useNavigate();

    const [message, setMessage] = useState("");
    const [record, setRecord] = useState(init_login_record);
    const [logging, setLogging] = useState(false);
    const [paymentsModalOpen, setPaymentsModalOpen] = useState(false);

    const {
        register,
        control,
        formState: {errors},
        handleSubmit,
    } = useForm({defaultValues: record});

    const isTried = authContext.getSession().bTried;
    const isLogged = authContext.getSession().isLogged;

    const userRedirect = () => {
        const url = authContext.getLoggedUserHomePage();
        navigate(url);
    }

    const onSubmit = data => {
        setLogging(true);
        authContext.login(data).then((res) => {
            let session = authContext.getSession();
            if ((session.bTried) && (session.isLogged)) {
                bookingContext.resetPlanningSelectedDate();
                registriesContext.resetBaseRegistries();
                registriesContext.checkCompanyPayments().then((res) => {
                    if (((res ?? {}).mLock ?? 0) > 0) {
                        alertContext.showCustomAlert("warning", (res.mWarningMessage ?? ""), userRedirect);
                    }
                    // userRedirect();
                })
            } else if ((session.bTried) && (!session.isLogged)) {
                setMessage("Accesso non riuscito: " + authContext.getSession().responseMessage);
            }
            setLogging(false);
        })
    }

    const renderButtonText = () => {
        return (authContext.isLogging) ? "..." : "Login"
    }

    const renderResultMessage = () => {
        if (!authContext.getSession()) return <></>
        if (!authContext.getSession().bTried) return <></>
        if (authContext.getSession().isLogged) return <Alert severity="success">Sessione ancora aperta!</Alert>
        return <Alert severity="error">{message}</Alert>
    }

    let session = authContext.getSession();
    if (session.isLogged) userRedirect();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction={"row"} spacing={theme.spacing(2)} sx={notLoggedClasses.loginContainer}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline"
                           sx={{mb: {xs: -0.5, sm: 0.5}}}>
                        <Typography variant="h5">Login</Typography>
                        <Typography component={Link} href="/Register" variant="body1" sx={{textDecoration: 'none'}}>
                            Non hai un account?
                        </Typography>
                    </Stack>

                </Grid>
                <Grid item xs={12}>
                    <FormTextBox
                        label="Username"
                        control={control}
                        record={record}
                        register={register}
                        field="mUsername"
                        required={true}
                        maxLength={50}
                        pattern={"email"}
                        disabled={authContext.isLogging}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextBox
                        label="Password"
                        control={control}
                        record={record}
                        register={register}
                        field="mPassword"
                        required={true}
                        maxLength={50}
                        pattern={"password"}
                        disabled={authContext.isLogging}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {renderResultMessage()}
                    </Typography>
                </Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"right"}>
                    <Typography component={Link} href="/RememberPassword" variant="body1" sx={{textDecoration: 'none'}}>
                        Hai dimenticato la password?
                    </Typography>
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                    <FormSubmitButton label={renderButtonText()} tooltip={"Login"} icon={"login"} disabled={false}
                                      loading={logging}/>
                </Grid>
            </Grid>
        </form>
    )
}
export default Login;