import {TreeItem, TreeView} from "@mui/x-tree-view";
import {
    CardContent,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    Stack,
    Switch,
    Tab,
    Tabs,
    Typography,
    useTheme
} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {booleanRendererWithBadge, defaultColDef, isDataLoading, isDataNull, JSONIsEmpty} from "../../../../common";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AuthContext} from "../../../../context/AuthContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";
import {IconLibraryItem} from "../../../../components/Icons/IconLibraryItem";
import {TabPanel} from "../../../../components/Tab/TabPanel";
import {FormDatePicker} from "../../../../components/Form/FormDatePicker";
import {CommonContext} from "../../../../context/CommonContext";
import {FormDiscreteSlider} from "../../../../components/Form/FormDiscreteSlider";
import {ActionButton} from "../../../../components/Buttons/ActionButton";

function ExpandMoreIcon() {
    return null;
}

function ChevronRightIcon() {
    return null;
}

const Companies = () => {

    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const authContext = useContext(AuthContext);
    const commonContext = useContext(CommonContext);

    const [record, setRecord] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [viewAsTree, setViewAsTree] = useState(false);
    const [custCodeRequired, setCustCodeRequired] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [planRows, setPlanRows] = useState(null);

    const {
        register,
        control,
        handleSubmit,
        getValues,
        reset,
        watch
    } = useForm({defaultValues: record});

    useEffect(() => {
        if (isDataNull(registriesContext.cities) && !isDataLoading(registriesContext.cities)) registriesContext.fetchCities();
    }, [registriesContext.cities]);

    useEffect(() => {
        if (isDataNull(registriesContext.zoneResponsables) && !isDataLoading(registriesContext.zoneResponsables)) registriesContext.fetchZoneResponsables();
    }, [registriesContext.zoneResponsables]);

    useEffect(() => {
        if (isDataNull(registriesContext.localResponsables) && !isDataLoading(registriesContext.localResponsables)) registriesContext.fetchLocalResponsables();
    }, [registriesContext.localResponsables]);

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(registriesContext.companies)) registriesContext.fetchCompaniesAsync().then((res) => {
            setDataLoaded(true);
        });
    }, [registriesContext.companies]);

    const tabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `company-tab-${index}`,
            'aria-controls': `company-tabpanel-${index}`,
        };
    }

    //dettaglio

    const onSubmit = (data) => {
        registriesContext.updateCompany(data);
        setModalOpen(false);
    }

    const onError = (errors, e) => {
        console.log(record);
        console.log(errors, e);
    }

    useEffect(() => {
        const subscription = watch(
            (value, {name, type}) => {
                if (type === 'change') {
                    //niente
                    const currentValues = getValues();
                    const isCodRequired = (!currentValues.mParent) ? true : false;
                    setCustCodeRequired(isCodRequired);
                }
            }
        );
        return () => subscription.unsubscribe();
    }, [watch]);

    const onClose = (event, reason) => {
        setModalOpen(false);
        setPlanRows(null);
    }

    //griglia

    const addAction = () => {
        registriesContext.fetchCompany(0).then((res) => {
            setRecord(res);
            reset(res);
            setModalOpen(true);
        });
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            registriesContext.fetchCompany(selectedRow.mCompanyID).then((resCompany) => {
                registriesContext.fetchCompanyPlan(selectedRow.mCompanyID).then((resPlan) => {
                    setRecord(resCompany);
                    reset(resCompany);
                    setModalOpen(true);
                    setPlanRows(resPlan);
                });
            });
        };

        const deleteAction = () => {
            alert(selectedRow.mCompanyID);
        }

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
            />
        )
    }

    const [columns, setColumns] = useState([
        {headerName: "Nome", field: "mCompanyName"},
        {headerName: "Indirizzo", field: "mAddress"},
        {headerName: "Città", field: "mCity.mCityName"},
        {headerName: "Codice Sede", field: "mCodiceSede"},
        {headerName: "Società padre", field: "mParent.mCompanyName"},
        {headerName: "Resp. terr.", field: "mLandManager.mCompleteName"},
        {headerName: "Resp. sede", field: "mSiteManager.mCompleteName"},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    const columnTypes = {
        toolButton: {sortable: false, editable: false, filter: '', width: '100', suppressSizeToFit: true}
    };

    //piano

    const createCompanyPlan = () => {
        const currentValues = getValues();
        registriesContext.updateCompany(currentValues).then((resSave) => {
            registriesContext.createCompanyPlan(record.mCompanyID).then((res) => {
                registriesContext.fetchCompanyPlan(record.mCompanyID).then((res) => {
                    setPlanRows(res);
                });
            });
        })

    }

    const PlansToolBar = (props) => {

        let selectedRow = props.node.data;

        const customDisabled = () => {
            return (selectedRow.mPayed ?? false) === true;
        }

        const payAction = () => {
            registriesContext.setCompanyPlanPayed(selectedRow.mPlanID).then((resCompany) => {
                registriesContext.fetchCompanyPlan(selectedRow.mCompanyID).then((res) => {
                    setPlanRows(res);
                });
            });
        };

        const creditCardAction = () => {

        };

        const customActions = [
            {
                action: payAction,
                disabled: customDisabled(),
                icon: "euro",
                tooltip: "Paga",
            },
            {
                action: creditCardAction,
                disabled: customDisabled(),
                icon: "credit-card",
                tooltip: "Effettua pagamento con carta",
            }
        ]

        return (
            <ActionsToolBar
                customActions={customActions}
            />
        )
    }

    const [planColumns, setPlanColumns] = useState([
        {headerName: "Rata", field: "mNumeroRata"},
        {headerName: "Mese", field: "mMese"},
        {headerName: "Anno", field: "mAnno"},
        {headerName: "Importo", field: "mTotalAdmount"},
        {headerName: "Pagato", field: "mPayed", cellRenderer: booleanRendererWithBadge},
        {headerName: "Azioni", cellRenderer: PlansToolBar, type: "toolBar"}
    ]);

    if (isDataNull(registriesContext.cities) || isDataLoading(registriesContext.cities)) return <CircularWaiting/>
    if (isDataNull(registriesContext.companies) || isDataLoading(registriesContext.companies)) return <CircularWaiting/>
    if (isDataNull(registriesContext.users) && !isDataLoading(registriesContext.users)) registriesContext.fetchUsers();

    const getTreeViewChildren = (companyID) => {
        return registriesContext.companies.filter(c => ((c.mParent ?? {}).mCompanyID ?? -1) === companyID).map((item, index) => {
            return (
                <TreeItem
                    nodeId={item.mCompanyID}
                    label={
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                        >
                            {item.mCompanyName}
                            <ToolBar node={{data: item}}/>
                        </Stack>
                    }
                    collapseIcon={<IconLibraryItem name={"expand-less"} iconSize={"small"}/>}
                    expandIcon={<IconLibraryItem name={"expand-more"} iconSize={"small"}/>}
                    sx={{marginLeft: theme.spacing(4)}}
                >
                    {getTreeViewChildren(item.mCompanyID)}
                </TreeItem>
            )
        });
    }

    const profile = (authContext.getLoggedUserProfile().mSQLName ?? "");

    //RESP_ITALIA
    //RESP_TERR
    //RESP_SEDE

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Società / Sedi</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{pb: theme.spacing(2)}}>
                        <Grid item xs={12}>
                            <Stack
                                direction={"row"}
                                justifyContent={"flex-end"}
                                spacing={theme.spacing(2)}
                                sx={{pt: theme.spacing(1)}}
                            >
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={viewAsTree}
                                                onChange={() => {
                                                    setViewAsTree(!viewAsTree)
                                                }}
                                            />
                                        }
                                        label="Visualizzione ad albero"
                                    />
                                </FormGroup>
                                <AddButton addAction={addAction}/>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    {
                        (viewAsTree) ?
                            <Stack
                                direction={"row"}
                                justifyContent="center"
                            >
                                <Paper elevation={3} id="treeView" sx={loggedClasses.companiesTreeViewContainer}>
                                    <TreeView
                                        aria-label="company navigator"
                                        defaultCollapseIcon={<ExpandMoreIcon/>}
                                        defaultExpandIcon={<ChevronRightIcon/>}
                                        sx={loggedClasses.companiesTreeView}
                                    >
                                        {registriesContext.companies.filter(c => !c.mParent).map((item, index) => {
                                            return (
                                                <>
                                                    <TreeItem
                                                        key={item.mCompanyID}
                                                        nodeId={item.mCompanyID}
                                                        label={
                                                            <Stack
                                                                direction={"row"}
                                                                alignItems={"center"}
                                                            >
                                                                {item.mCompanyName}
                                                                <ToolBar node={{data: item}}/>
                                                            </Stack>
                                                        }
                                                        sx={loggedClasses.companiesTreeViewItem}
                                                        collapseIcon={<IconLibraryItem name={"expand-less"}
                                                                                       iconSize={"small"}/>}
                                                        expandIcon={<IconLibraryItem name={"expand-more"}
                                                                                     iconSize={"small"}/>}
                                                    >

                                                        {getTreeViewChildren(item.mCompanyID)}
                                                    </TreeItem>
                                                </>
                                            )
                                        })}
                                    </TreeView>
                                </Paper>
                            </Stack>

                            :
                            <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                                <AgGridReact
                                    rowData={registriesContext.companies}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columns}
                                    columnTypes={columnTypes}
                                    suppressMovableColumns={true}
                                    suppressCellFocus={true}
                                    pagination={true}
                                    rowSelection={'single'}
                                    getRowHeight={(params) => gridRowHeight}
                                >
                                </AgGridReact>
                            </div>
                    }

                </Grid>
            </Grid>
            <CardModal
                modalOpen={modalOpen}
                onClose={onClose}
                title={"Dettaglio società"}
                formFunctions={{submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError}}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    {
                        (!JSONIsEmpty(record)) ?
                            <>
                                <Tabs
                                    value={selectedTab}
                                    variant={"fullWidth"}
                                    aria-label="company tabs"
                                    onChange={tabChange}
                                    sx={loggedClasses.companyTabButtons}
                                >
                                    <Tab
                                        index={0}
                                        icon={<IconLibraryItem name={"edit"} iconSize={"small"}/>}
                                        label="Anagrafica"
                                        {...a11yProps(0)}
                                        sx={loggedClasses.companyTabButton}
                                    />
                                    <Tab
                                        index={1}
                                        icon={<IconLibraryItem name={"euro"} iconSize={"small"}/>}
                                        label="Piano"
                                        {...a11yProps(1)}
                                        sx={loggedClasses.companyTabButton}/>
                                </Tabs>
                                <TabPanel
                                    value={selectedTab}
                                    index={0}
                                    sx={loggedClasses.companyTabPanel}
                                >
                                    <Grid
                                        container
                                        direction={"row"}
                                        spacing={theme.spacing(2)}
                                        justifyContent={"flex-start"}
                                        alignItems={"flex-start"}
                                    >

                                        <Grid item xs={12} lg={4}>
                                            <FormTextBox
                                                label="Nome"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mCompanyName"
                                                required={true}
                                                maxLength={100}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormTextBox
                                                label="PIVA"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mPartitaIVA"
                                                required={false}
                                                maxLength={12}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormTextBox
                                                label="CF"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mCodiceFisale"
                                                required={false}
                                                maxLength={16}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormTextBox
                                                label="Indirizzo"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mAddress"
                                                required={true}
                                                maxLength={50}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormTextBox
                                                label="CAP"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mCAP"
                                                required={true}
                                                maxLength={5}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormAutoComplete
                                                label="Città"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mCity"
                                                joinField="mCityID"
                                                values={registriesContext.cities}
                                                idField="mCityID"
                                                labelField="mCompleteName"
                                                selectedValue="id"
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormTextBox
                                                label="E-mail"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mMail"
                                                required={true}
                                                maxLength={100}
                                                pattern={"email"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormTextBox
                                                label="PEC"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mLegalMail"
                                                required={!record.mParent}
                                                maxLength={100}
                                                pattern={"email"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormTextBox
                                                label="Telefono"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mTelefono"
                                                required={false}
                                                maxLength={50}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormTextBox
                                                label="Codice Sede"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mCodiceSede"
                                                required={true}
                                                maxLength={45}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormAutoComplete
                                                label="Centro/Sede del gruppo"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mParent"
                                                joinField="mCompanyID"
                                                values={registriesContext.companies}
                                                idField="mCompanyID"
                                                labelField="mCompanyName"
                                                selectedValue="id"
                                                required={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <FormTextBox
                                                label="Codice Cliente"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mCodiceCliente"
                                                required={custCodeRequired}
                                                maxLength={5}
                                            />
                                        </Grid>
                                        {
                                            (profile === "RESP_ITALIA") ?
                                                <Grid item xs={12} lg={4}>
                                                    <FormAutoComplete
                                                        label="Responsabile territoriale"
                                                        control={control}
                                                        record={record}
                                                        register={register}
                                                        field="mLandManager"
                                                        joinField="mUserID"
                                                        values={registriesContext.zoneResponsables}
                                                        idField="mUserID"
                                                        labelField="mCompleteName"
                                                        selectedValue="id"
                                                        required={false}
                                                    />
                                                </Grid>
                                                :
                                                <></>
                                        }
                                        {
                                            (profile === "RESP_TERR") ?
                                                <Grid item xs={12} lg={4}>
                                                    <FormAutoComplete
                                                        label="Responsabile sede"
                                                        control={control}
                                                        record={record}
                                                        register={register}
                                                        field="mSiteManager"
                                                        joinField="mUserID"
                                                        values={registriesContext.localResponsables}
                                                        idField="mUserID"
                                                        labelField="mCompleteName"
                                                        selectedValue="id"
                                                        required={false}
                                                    />
                                                </Grid>
                                                :
                                                <></>
                                        }
                                    </Grid>
                                </TabPanel>
                                <TabPanel
                                    value={selectedTab}
                                    index={1}
                                    sx={loggedClasses.companyTabPanel}
                                >
                                    <Grid
                                        container
                                        direction={"row"}
                                        spacing={theme.spacing(2)}
                                        justifyContent={"flex-start"}
                                        alignItems={"flex-start"}
                                    >
                                        <Grid item xs={12} lg={3}>
                                            <FormDatePicker
                                                label="Data inizio fatturazione"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mStartDate"
                                                required={false}
                                                format={commonContext.getPlanningParameter("DATE_FORMAT_SHORT")}
                                                disabled={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <FormDiscreteSlider
                                                label="Durata contratto"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mDurata"
                                                required={false}
                                                min={12}
                                                max={60}
                                                step={12}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <FormTextBox
                                                label="Importo"
                                                control={control}
                                                record={record}
                                                register={register}
                                                field="mImporto"
                                                required={false}
                                                maxLength={50}
                                                pattern={"money"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={3}>
                                            <ActionButton
                                                color="primary"
                                                variant="contained"
                                                action={createCompanyPlan}
                                                size="medium"
                                                icon="calendar"
                                                iconSize="medium"
                                                tooltip="Crea o rigenera il piano"
                                                label="Sviluppa piano"
                                                fullWidth={true}
                                            />
                                        </Grid>
                                        {
                                            (planRows) &&
                                            <Grid item xs={12} lg={12}>
                                                <div id="agReactGridPlan" className="ag-theme-alpine"
                                                     style={gridSizeStyle}>
                                                    <AgGridReact
                                                        rowData={planRows}
                                                        defaultColDef={defaultColDef}
                                                        columnDefs={planColumns}
                                                        columnTypes={columnTypes}
                                                        suppressMovableColumns={true}
                                                        suppressCellFocus={true}
                                                        pagination={true}
                                                        rowSelection={'single'}
                                                        getRowHeight={(params) => gridRowHeight}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                            </Grid>
                                        }
                                    </Grid>
                                </TabPanel>
                            </>

                            :
                            <CircularWaiting/>
                    }
                </CardContent>
            </CardModal>
        </>
    );
}

export default Companies;