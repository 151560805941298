import React from 'react';
import {AccountingProvider} from "./AccountingContext";
import {CustomAlertProvider} from "./AlertContext";
import {AuthProvider} from "./AuthContext";
import {BookingProvider} from "./BookingContext";
import {ManagementProvider} from "./ManagementContext";
import {MedicalReportingProvider} from "./MedicalReportingContext";
import {RegistriesProvider} from "./RegistriesContext";
import {CommonProvider} from "./CommonContext";
import {RecupProvider} from "./RecupContext";

export const ContextTree = ({...props}) => {
    return (
        <>
            <CustomAlertProvider>
                <AuthProvider>
                    <RegistriesProvider>
                        <CommonProvider>
                            <BookingProvider>
                                <MedicalReportingProvider>
                                    <AccountingProvider>
                                        <ManagementProvider>
                                            <RecupProvider>
                                                {props.children}
                                            </RecupProvider>
                                        </ManagementProvider>
                                    </AccountingProvider>
                                </MedicalReportingProvider>
                            </BookingProvider>
                        </CommonProvider>
                    </RegistriesProvider>
                </AuthProvider>
            </CustomAlertProvider>
        </>
    )
}