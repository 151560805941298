export default function Tabs(theme) {
    return {
        MuiTabs: {
            styleOverrides: {
                vertical: {
                    // overflow: 'visible'
                }
            }
        }
    };
}
