import {IconLibraryItem} from "../Icons/IconLibraryItem";
import {alpha, Fab, Menu, MenuItem, styled} from "@mui/material";
import React, {useState} from "react";

export const ContextualMenu = ({
                                   actions
                               }) => {


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const callActionAndClose = (action) => {
        action();
        handleClose();
    };

    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({theme}) => ({
        '& .MuiPaper-root': {
            borderRadius: theme.spacing(1),
            marginTop: theme.spacing(1),
            minWidth: 250,
            color: theme.palette.primary.dark,
            boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    // fontSize: 18,
                    // color: theme.palette.text.secondary,
                    // marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    }));


    return (
        <div>
            <Fab color="primary" aria-label="add" onClick={handleClick} variant={"extended"}>
                <IconLibraryItem name={"list"} iconSize={"large"}/>
            </Fab>
            {
                (open) &&
                <StyledMenu
                    id="contextual-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {
                        actions.map((item) => {
                            return (
                                <MenuItem onClick={() => { callActionAndClose(item.action)}} disableRipple>
                                    <IconLibraryItem name={item.icon} iconSize={"large"}/>
                                {item.label}
                                    </MenuItem>
                                    )
                                })
                        }
                        </StyledMenu>
                    }
                </div>
                )

            }