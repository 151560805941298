import {CircularProgress, Grid, useTheme} from "@mui/material";
import React from "react";

export const CircularWaiting = ({size, minHeight}) => {

    const theme = useTheme();
    const _size = (size ?? 50);
    const h = (minHeight ?? '50vh');

    return (
        <Grid
            container
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{minHeight: h}}
        >
            <Grid item xs={12}>
                <CircularProgress color="primary" size={_size}/>
            </Grid>
        </Grid>
    )
}