import {FavoriteTwoTone, InventoryTwoTone, PhoneTwoTone, ReceiptTwoTone} from "@mui/icons-material";
import {
    Box,
    Grid,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tabs,
    Typography,
    useTheme
} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import {ContentState, convertToRaw, EditorState, Modifier} from "draft-js";
import {getCurrentContent} from "draft-js/lib/EditorState";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, {useContext, useEffect, useState} from 'react';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {columnTypes, defaultColDef, defaultEditorOptions, getCompleteName, isDataLoading} from "../../../../common";
import {VisitAttachmentsModal} from "../../../../components/Attachments/VisitAttachmentsModal";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormDatePicker} from "../../../../components/Form/FormDatePicker";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar} from "../../../../components/Grid/ActionsToolbar";
import {SonicViewerButton} from "../../../../components/Sonic/SonicViewerButton";
import {TabPanel} from "../../../../components/Tab/TabPanel";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AuthContext} from "../../../../context/AuthContext";
import {BookingContext} from "../../../../context/BookingContext";
import {MedicalReportingContext} from "../../../../context/MedicalReportingContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridRowHeight, gridSmallSizeStyle, loggedClassNames, reportingStyleNames} from "../../../../theme/Styles";
import {MedicalReportPreview} from "./MedicalReportPreview";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {CommonContext} from "../../../../context/CommonContext";
import {SquareButton} from "../../../../components/Buttons/SquareButton";

const ToolbarOption = ({
                           editorState,
                           onChange,
                           templates,
                           patient,
                           saveMedicalReport,
                           openAttachmentsClicked,
                           sonicUser,
                           sonicPassword,
                           isSaving
                       }) => {

    const medicalReportingContext = useContext(MedicalReportingContext);

    const theme = useTheme();

    const reportingStyles = reportingStyleNames(theme);

    const [open, setOpen] = useState(false);

    const openTemplateDropdown = () => {
        setOpen(!open);
    }

    const addTemplate = (template) => {
        let {contentBlocks, entityMap} = htmlToDraft(template);
        let contentState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
        )
        onChange(EditorState.push(editorState, contentState, 'insert-fragment'));
    }

    const ListItem = ({item}) => {

        const [isHovering, setIsHovering] = useState(false);

        const handleMouseEnter = () => {
            setIsHovering(true);
        };

        const handleMouseLeave = () => {
            setIsHovering(false);
        };

        const title = (item.mTitle.length > 20) ? item.mTitle.substring(0, 19).concat("...") : item.mTitle;

        return (
            <li
                onClick={() => {
                    addTemplate(item.mText)
                }}
                key={item.mTemplateID}
                className="rdw-dropdownoption-default"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{backgroundColor: isHovering ? "#F1F1F1" : "#FFFFFF"}}
            >
                {title}
            </li>
        )
    }

    let listItems = [];

    if (templates && !isDataLoading(templates)) {
        listItems = templates.map((item) => {
            return <ListItem item={item}/>
        });
    }

    const ulStyle = (!open) ? {visibility: "hidden"} : {width: '400px'};
    const dropDownStyle = {height: '2vw', width: '400px'};
    return (
        <Stack
            direction={"row"}
            display={"flex"}
            alignItems={"center"}
            spacing={theme.spacing(1)}
            sx={reportingStyles.reportingCustomToolbar}
        >
            <SquareButton
                color="primary"
                variant="contained"
                action={() => {
                    saveMedicalReport(true);
                }}
                size="extra-small"
                icon={"draft"}
                iconSize="small"
                tooltip={"Salva bozza"}
                label={""}
                disabled={isSaving}
                fullWidth={true}
            />
            <SquareButton
                color="primary"
                variant="contained"
                action={() => {
                    saveMedicalReport(false);
                }}
                size="extra-small"
                icon={"save"}
                iconSize="small"
                tooltip={"Salva finale"}
                label={""}
                disabled={isSaving}
                fullWidth={true}
            />
            <SquareButton
                color="primary"
                variant="contained"
                action={openAttachmentsClicked}
                size="extra-small"
                icon={"attach"}
                iconSize="small"
                tooltip="Allegati"
                label=""
                disabled={isSaving}
                fullWidth={true}
            />
            <SonicViewerButton
                openingMode={2}
                patientID={patient.mCF}
                sonicUser={sonicUser}
                sonicPassword={sonicPassword}
                disabled={isSaving}
                fullWidth={true}
            />
            <div onClick={openTemplateDropdown} className="rdw-block-wrapper" aria-label="rdw-block-control">
                <div className="rdw-dropdown-wrapper rdw-block-dropdown" aria-label="rdw-dropdown" style={dropDownStyle}>
                    <div className="rdw-dropdown-selectedtext" title="Placeholders">
                        <span>Template</span>
                        <div className={`rdw-dropdown-caretto${open ? "close" : "open"}`}></div>
                    </div>
                    <ul className={"rdw-dropdown-optionwrapper"} style={ulStyle}>
                        {listItems}
                    </ul>
                </div>
            </div>
        </Stack>
    )
}

export const MedicalReportEditor = (props) => {

    const medicalReportingContext = useContext(MedicalReportingContext);
    const commonContext = useContext(CommonContext);
    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);
    const authContext = useContext(AuthContext);
    const alertContext = useContext(CustomAlertContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    const reportingStyles = reportingStyleNames(theme);
    let navigate = useNavigate();

    const [selectedTab, setSelectedTab] = useState(2);
    const [agGridApi, setAgGridApi] = useState(null);

    let tmpEditorState = EditorState.createEmpty();
    const [editorState, setEditorState] = useState(tmpEditorState);
    const [isSaving, setIsSaving] = useState(false);
    const [medicalReport, setMedicalReport] = useState(null);
    const [patientVisitLines, setPatientVisitLines] = useState(null);
    const [medicalReportToOpenID, setMedicalReportToOpenID] = useState(-1);
    const [attachments, setAttachments] = useState(null);
    const [attachmentsModalOpen, setAttachmentsModalOpen] = useState(false);
    const [templates, setTemplates] = useState(null);

    let sonicUser = authContext.getLoggedUser().mSonicUserName;
    let sonicPassword = authContext.getLoggedUser().mSonicPassword;

    let patient = null;
    let patientCompleteName = "";
    let patID = 0;
    let visitID = 0;
    let medicalReportID = 0;
    let visitLines = null;

    const mode = medicalReportingContext.medicalReportState.mode;

    if (mode === 2) {
        visitLines = medicalReportingContext.medicalReportState.reportingVisitLines;
        visitID = medicalReportingContext.medicalReportState.reportingVisitLines[0].mVisita.mVisitaID;
        patient = medicalReportingContext.medicalReportState.reportingPatient;
        patientCompleteName = getCompleteName(patient.mNomePaziente, patient.mCognomePaziente);
        medicalReportID = 0;
    } else if (mode === 4 && medicalReportingContext.medicalReportState.medicalReport) {
        medicalReportID = medicalReportingContext.medicalReportState.medicalReport.mRefertoID;
        patient = medicalReportingContext.medicalReportState.reportingPatient;
    }
    if (mode === 4 && medicalReport) {
        visitLines = medicalReport.mListOfEsami;
        visitID = (medicalReport.mVisita ?? {}).mVisitaID ?? 0;
        patientCompleteName = getCompleteName(patient.mNomePaziente, patient.mCognomePaziente);
    }

    useEffect(() => {
        if (!templates && !isDataLoading(templates)) {
            setTemplates("loading");
            medicalReportingContext.fetchMedicalReportTemplates().then((res) => {
                setTemplates(res);
            });
        }
    }, [templates]);

    const {
        watch,
        register,
        control,
        formState: {errors},
        handleSubmit,
        reset,
        getValues,
        setValue
    } = useForm({defaultValues: patient});

    useEffect(() => {
        if (medicalReportToOpenID !== -1) return;
        if (!patientVisitLines && patient) {
            setPatientVisitLines("loading");
            medicalReportingContext.fetchPatientVisitLines(patient.mPatientID).then((res) => {
                setPatientVisitLines(res);
            });
        }
        if (!medicalReport) {
            setMedicalReport("loading");
            medicalReportingContext.fetchMedicalReport(medicalReportID).then((res) => {
                setMedicalReport(res);
            });
        }
        if (medicalReportID === 0) return;
        if (!medicalReport) return;
        const contentBlock = htmlToDraft(medicalReport.mTXT_REFERTO ?? "");
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const contentEditorState = EditorState.createWithContent(contentState);
        setEditorState(contentEditorState);
    }, [medicalReport]);

    useEffect(() => {
        if (medicalReportToOpenID === -1) return;
        if (isSaving) setIsSaving(false);
    }, [medicalReportToOpenID]);

    const tabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const ToolBar = (props) => {

        let record = props.node.data;

        const pdfDisabled = () => {
            return (record.mStatoEsame !== "REFERTATO" || !record.mReferto)
        }

        const pdfAction = () => {
            setMedicalReportToOpenID(record.mReferto.mRefertoID);
        };

        return (
            <ActionsToolBar
                hasPDF pdfAction={pdfAction} pdfDisabled={pdfDisabled()}
            />
        )
    }

    const columns = [
        {
            headerName: "Prestazione",
            field: "mPrestazione.mDescrizione",
            minWidth: '350'
        },
        {
            headerName: "Appuntamento",
            field: "mDataAppuntamentoString",
            minWidth: '160'
        },
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ];

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const onGridReady = (params) => {
        setAgGridApi(params.api);
    }

    const onEditorStateChange = (newState) => {
        setEditorState(newState);
    }

    const checkIsEmptyBlock = (content) => {
        if (!content) return true;
        if (!content.blocks) return true;
        const arrBlocks = content.blocks ?? [];
        if (arrBlocks.length === 0) return true;
        if (arrBlocks.length === 1 && arrBlocks[0].text === "") return true;
        return false;
    }

    const saveMedicalReport = (pDraft) => {
        if (mode === 2) {
            const rawContent = convertToRaw(editorState.getCurrentContent());
            const isEmptyBlock = checkIsEmptyBlock(rawContent);
            if (isEmptyBlock) {
                alertContext.showCustomAlert("error", "Attenzione! Il testo del referto non può essere vuoto.");
                return;
            }
            const htmlContent = draftToHtml(rawContent);
            setIsSaving(true);
            medicalReportingContext.createReportFromVisitLines(htmlContent, pDraft).then((res) => {
                //aprire pdf
                setMedicalReportToOpenID(res.mRefertoID);
            })
        } else {
            const rawContent = convertToRaw(editorState.getCurrentContent());
            const isEmptyBlock = checkIsEmptyBlock(rawContent);
            if (isEmptyBlock) {
                alertContext.showCustomAlert("error", "Attenzione! Il testo del referto non può essere vuoto.");
                return;
            }
            const htmlContent = draftToHtml(rawContent);
            setIsSaving(true);
            medicalReportingContext.updateMedicalReportContent(medicalReport, htmlContent, pDraft).then((res) => {
                //aprire pdf
                setMedicalReportToOpenID(medicalReport.mRefertoID);
            })
        }
    }

    const onPDFClose = () => {
        setMedicalReportToOpenID(-1);
        medicalReportingContext.goToReports();
        navigate('/MedicalReporting/Reports');
    }

    const openAttachmentsClicked = () => {
        if (visitID === 0) return;
        setAttachments("loading");
        bookingContext.fetchVisitAttachments(visitID).then(res => {
            setAttachments(res);
            setAttachmentsModalOpen(true);
        });
    };

    if (!registriesContext.sponsors) return <CircularWaiting/>

    if (!registriesContext.sexItemizedListValues) return <CircularWaiting/>

    if (mode === 2) {

        if ((visitLines ?? []).length === 0) return <CircularWaiting/>

    } else if (mode === 4) {

        if (!medicalReport) return <CircularWaiting/>

    }

    if (visitID === 0) return <CircularWaiting/>

    if (!patient) return <CircularWaiting/>

    if (!patientVisitLines) return <CircularWaiting/>

    if (isSaving) return <CircularWaiting/>

    if (medicalReportToOpenID !== -1) {
        return <MedicalReportPreview documentID={medicalReportToOpenID} onCloseAction={onPDFClose}/>
    }

    return (
        <>
            <Grid
                container
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={reportingStyles.reportingDetailHeader}
                key="VisitModalGrid"
            >
                <Grid item xs={12}>
                    <TableContainer component={Box}>
                        <Table aria-label="simple table">
                            {/*<TableHead>*/}
                            {/*    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>*/}

                            {/*    </TableRow>*/}
                            {/*</TableHead>*/}
                            <TableBody>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell align="left"><Typography
                                        variant="h5">Paziente {patientCompleteName}</Typography></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Box sx={{flexGrow: 1, display: 'flex'}}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            onChange={tabChange}
                            aria-label="reporting tabs"
                            value={selectedTab}
                            sx={reportingStyles.reportingTabButtons}
                        >
                            <Tab icon={<FavoriteTwoTone/>} label="Esami" {...a11yProps(0)}/>
                            <Tab wrapped icon={<PhoneTwoTone/>} label="Dati anagrafici paziente" {...a11yProps(1)}/>
                            <Tab icon={<ReceiptTwoTone/>} label="Referto" {...a11yProps(2)}/>
                            <Tab icon={<InventoryTwoTone/>} label="Precedenti" {...a11yProps(3)}/>
                        </Tabs>
                        <TabPanel value={selectedTab} index={0} sx={reportingStyles.reportingTabPanel}>
                            <div id="agReactGridMedicalServices" className="ag-theme-alpine" style={gridSmallSizeStyle}>
                                <AgGridReact
                                    rowData={visitLines}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columns}
                                    columnTypes={columnTypes}
                                    suppressMovableColumns={true}
                                    suppressCellFocus={true}
                                    pagination={true}
                                    rowSelection={'single'}
                                    onGridReady={onGridReady}
                                    getRowHeight={(params) => gridRowHeight}
                                >
                                </AgGridReact>
                            </div>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={1} sx={reportingStyles.reportingTabPanel}>
                            <Box>
                                <form>
                                    <Grid
                                        container
                                        direction={"row"}
                                        spacing={theme.spacing(2)}
                                        justifyContent={"flex-start"}
                                        alignItems={"flex-start"}
                                    >
                                        <Grid item xs={12} md={3}>
                                            <FormTextBox
                                                label="Nome"
                                                control={control}
                                                record={patient}
                                                register={register}
                                                field="mNomePaziente"
                                                required={true}
                                                maxLength={50}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormTextBox
                                                label="Cognome"
                                                control={control}
                                                record={patient}
                                                register={register}
                                                field="mCognomePaziente"
                                                required={true}
                                                maxLength={50}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormDatePicker
                                                label="Data nascita"
                                                control={control}
                                                record={patient}
                                                register={register}
                                                field="mBirthDate"
                                                required={false}
                                                format={commonContext.getPlanningParameter("DATE_FORMAT_SHORT")}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormAutoComplete
                                                label="Sesso"
                                                control={control}
                                                record={patient}
                                                register={register}
                                                field="mSex"
                                                joinField=""
                                                values={registriesContext.sexItemizedListValues}
                                                idField="mItemizedListValueID"
                                                labelField="mValue"
                                                selectedValue="label"
                                                required={true}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormTextBox
                                                label="CF"
                                                control={control}
                                                record={patient}
                                                register={register}
                                                field="mCF"
                                                required={true}
                                                minLength={16}
                                                maxLength={16}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormTextBox
                                                label="Cellulare"
                                                control={control}
                                                record={patient}
                                                register={register}
                                                field="mMobilePhone"
                                                required={false}
                                                maxLength={50}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormTextBox
                                                label="Fisso"
                                                control={control}
                                                record={patient}
                                                register={register}
                                                field="mHomePhone"
                                                required={false}
                                                maxLength={50}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={11}>
                                            <FormTextBox
                                                label="Note"
                                                control={control}
                                                record={patient}
                                                register={register}
                                                multiline
                                                lines={3}
                                                field="mAdditionalInformation"
                                                required={false}
                                                maxLength={100}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={2} sx={reportingStyles.reportingTabPanel}>
                            <Box>
                                <Editor
                                    toolbar={defaultEditorOptions}
                                    editorState={editorState}
                                    wrapperStyle={reportingStyles.reportingWrapper}
                                    editorStyle={reportingStyles.reportingArea}
                                    toolbarStyle={reportingStyles.reportingToolbar}
                                    onEditorStateChange={onEditorStateChange}
                                    onBlur={(event, editorState) => {
                                        onEditorStateChange(editorState);
                                    }}
                                    toolbarCustomButtons={
                                        [
                                            <ToolbarOption
                                                templates={templates}
                                                editorState={editorState}
                                                onChange={onEditorStateChange}
                                                patient={patient}
                                                openAttachmentsClicked={openAttachmentsClicked}
                                                saveMedicalReport={saveMedicalReport}
                                                sonicUser={sonicUser}
                                                sonicPassword={sonicPassword}
                                                isSaving={isSaving}
                                            />
                                        ]
                                    }
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={3} sx={reportingStyles.reportingTabPanel}>
                            <div id="agReactGridArchive" className="ag-theme-alpine" style={gridSmallSizeStyle}>
                                <AgGridReact
                                    rowData={patientVisitLines}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columns}
                                    columnTypes={columnTypes}
                                    suppressMovableColumns={true}
                                    suppressCellFocus={true}
                                    pagination={true}
                                    rowSelection={'single'}
                                    // onGridReady={onGridReady}
                                    getRowHeight={(params) => gridRowHeight}
                                >
                                </AgGridReact>
                            </div>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>
            {
                visitID !== 0 && attachmentsModalOpen === true &&
                <VisitAttachmentsModal
                    visitID={visitID}
                    modalOpen={attachmentsModalOpen}
                    setModalOpen={setAttachmentsModalOpen}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    canInsert={false}
                />
            }
        </>
    )
}