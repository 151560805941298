import React, {useContext, useEffect, useState} from 'react';
import {Autocomplete, Box, Card, CardContent, Grid, styled, TextField, useTheme} from "@mui/material";
import {gridRowHeight, gridSizeStyle} from "../../../../theme/Styles";
import moment from 'moment';
import {DateFilter} from "../../../../components/Filters/DateFilter";
import {AccountingContext} from "../../../../context/AccountingContext";
import {
    columnTypes,
    defaultColDef,
    downloadGenericListOfByte,
    downloadPDFListOfByte,
    downloadTextListOfByte,
    isDataLoading,
    isDataNull
} from "../../../../common";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {AgGridReact} from "ag-grid-react";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {ActionsToolBar} from "../../../../components/Grid/ActionsToolbar";
import {CardModal} from "../../../../components/Layout/CardModal";
import {useForm} from "react-hook-form";
import {FormSubmitButton} from "../../../../components/Form/FormSubmitButton";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {DateTimeFilter} from "../../../../components/Filters/DateTimeFilter";
import {AuthContext} from "../../../../context/AuthContext";

const init_filter_value = {
    mFromDate: moment(),
    mToDate: moment(),
    mMese: moment().month(),
    mAnno: moment().year(),
    mDoctor: 0,
    mRoom: 0
};

const AccountingReports = (props) => {

    const accountingContext = useContext(AccountingContext);
    const authContext = useContext(AuthContext);
    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();

    const [isLoading, setIsLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [currentFilter, setCurrentFilter] = useState(init_filter_value);
    const [modalOpen, setModalOpen] = useState(false);
    const [record, setRecord] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);

    const {
        register,
        control,
        handleSubmit,
        setValue,
        getValues,
        reset
    } = useForm({mode: 'onChange', defaultValues: currentFilter});

    const StyledCardHeader = styled(Box)({
        backgroundColor: theme.palette.primary.main,
        ...theme.typography.body1,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.primary.contrastText
    });

    useEffect(() => {
        if (reports.length === 0) {
            if (isLoading) return;
            setIsLoading(true);
            accountingContext.fetchAccountingReports().then((res) => {
                setIsLoading(false);
                setReports(res);
            });
        }
    }, []);

    useEffect(() => {
        if (isDataNull(registriesContext.doctors) && !isDataLoading(registriesContext.doctors)) registriesContext.fetchDoctors();
    }, [registriesContext.doctors]);

    useEffect(() => {
        if (isDataNull(registriesContext.rooms) && !isDataLoading(registriesContext.rooms)) registriesContext.fetchRooms();
    }, [registriesContext.rooms]);

    const onClose = (event, reason) => {
        setRecord(null);
        setModalOpen(false);
    }

    const onSubmit = (data) => {
        if (!record) return;
        if (record.mReportCode === "999") {
            const pBody = {
                mMese: data.mMese,
                mAnno: data.mAnno
            }
            setIsGenerating(true);
            accountingContext.exportASLReport(pBody).then((res) => {
                if (res) downloadTextListOfByte(res.mListOfBytes_FILE_A, res.mListOfBytes_FILE_A_FileName ?? "1.txt");
                if (res) downloadTextListOfByte(res.mListOfBytes_FILE_S, res.mListOfBytes_FILE_S_FileName ?? "1.txt");
                if (res) downloadTextListOfByte(res.mListOfBytes_SOGEI, res.mListOfBytes_SOGEI_FileName ?? "1.txt");
                setIsGenerating(false);
            })
        } else {
            setIsGenerating(true);
            let contextualID = 0;
            const longDateTimeFormat = authContext.getSystemParameterValue("DATETIME_FORMAT_LONG");
            let mFilterFrom = moment(data.mFromDate).startOf('day').format(longDateTimeFormat);
            let mFilterTo = moment(data.mToDate).add(1,'days').startOf('day').add(-1,'minutes').format(longDateTimeFormat);
            switch (reportType) {
                case 6:
                    contextualID = data.mDoctor;
                    break;
                case 8:
                    contextualID = data.mRoom;
                    break;
                case 9:
                    contextualID = data.mRoom;
                    mFilterFrom = moment(data.mFromDate).startOf('minute').format(longDateTimeFormat);
                    mFilterTo = moment(data.mToDate).startOf('minute').format(longDateTimeFormat);
                    break;
                default:
                    contextualID = 0;
                    break;
            }
            accountingContext.printAccountingReport(mFilterFrom, mFilterTo, contextualID, record.mReportCode).then((res) => {
                setIsGenerating(false);
                if (!res) return;
                switch (reportOutType) {
                    case "csv":
                        downloadTextListOfByte(res.mListOfBytes_CSV, res.mListOfBytes_CSV_FileName);
                        break;
                    case "pdf":
                        downloadPDFListOfByte(res.mListOfBytes, record.mLabel);
                        break;
                    default:
                        downloadGenericListOfByte(res.mListOfBytes, record.mLabel);
                        break;
                }

            })
        }
    }

    const onError = (errors, e) => {
        console.log(errors, e);
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const reportOutType = (((selectedRow ?? {}).mOutputType ?? {}).mOutputDescription ?? "").toLowerCase();

        let icon = reportOutType.toLowerCase();

        const openPrintModal = () => {
            setRecord(selectedRow);
            setModalOpen(true);
        }

        const customActions = [{
            action: openPrintModal,
            disabled: false,
            icon: icon,
            tooltip: "Genera",
        }];

        return (
            <ActionsToolBar
                hasEdit={false}
                hasDelete={false}
                customActions={customActions}
            />
        )
    }

    const [columns, setColumns] = useState([
        {headerName: "Codice", field: "mReportCode"},
        {headerName: "Nome", field: "mLabel"},
        {headerName: "Gruppo", field: "mReportGroup"},
        {headerName: "Input Type", field: "mInputType.mInputDescription"},
        {headerName: "Output Type", field: "mOutputType.mOutputDescription"},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    const onGridReady = (params) => {
        // setAgGridApi(params.api);
        // params.api.sizeColumnsToFit();
    }

    const reportName = ((record ?? {}).mLabel ?? "");
    const reportType = (((record ?? {}).mInputType ?? {}).mInputID ?? 0);
    const reportOutType = (((record ?? {}).mOutputType ?? {}).mOutputDescription ?? "").toLowerCase();

    //9 = data/ora da + data/ora a + stanza

    let doctors = [];
    if (registriesContext.doctors && !isDataLoading(registriesContext.doctors)) {
        registriesContext.doctors.map((item) => {
            const idValue = item.mUserID;
            const labelValue = item.mCompleteName;
            doctors.push({id: idValue, label: labelValue});
            return true;
        });
    }

    let rooms = [];
    if (registriesContext.rooms && !isDataLoading(registriesContext.rooms)) {
        registriesContext.getActiveRooms().map((item) => {
            const idValue = item.mRoomID;
            const labelValue = item.mRoomName;
            rooms.push({id: idValue, label: labelValue});
            return true;
        });
    }

    const currentValues = getValues();
    const selectedDoctor = doctors.find(d => d.id === currentValues.mDoctor);
    const selectedRoom = rooms.find(d => d.id === currentValues.mRoom);

    if (isDataNull(registriesContext.rooms) || isDataLoading(registriesContext.rooms)) return <CircularWaiting/>
    if (isDataNull(registriesContext.doctors) || isDataLoading(registriesContext.doctors)) return <CircularWaiting/>

    const isSubmittable = (reportType !== 6 && reportType !== 8 && reportType !== 9) || (reportType === 6 && currentValues.mDoctor && currentValues.mDoctor !== 0) || (reportType === 8 && currentValues.mRoom && currentValues.mRoom !== 0) || (reportType === 9 && currentValues.mRoom && currentValues.mRoom !== 0);

    return (
        <>
            <Card>
                <CardContent>
                    <Grid container spacing={theme.spacing(2)}>
                        <Grid item xs={12} lg={12}>
                            <StyledCardHeader>
                                {"Stampe contabili"}
                            </StyledCardHeader>
                        </Grid>
                        <Grid item xs={12}>
                            <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                                <AgGridReact
                                    rowData={reports}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columns}
                                    columnTypes={columnTypes}
                                    suppressMovableColumns={true}
                                    suppressCellFocus={true}
                                    pagination={true}
                                    rowSelection={'single'}
                                    onGridReady={onGridReady}
                                    getRowHeight={(params) => gridRowHeight}
                                >
                                </AgGridReact>
                            </div>
                        </Grid>
                    </Grid>
                    <CardModal
                        modalOpen={modalOpen}
                        onClose={onClose}
                        title={"Generazione report " + reportName}
                        size={"medium"}
                    >
                        <form onSubmit={handleSubmit(onSubmit, onError)}>
                            <CardContent>
                                <Grid container spacing={theme.spacing(2)}>

                                    {
                                        (reportType === 2) ?
                                            <Grid item xs={12} xl={10}>
                                                <DateFilter
                                                    showFrom
                                                    showTo
                                                    currentValue={getValues()}
                                                    setValue={(value) => {
                                                        setValue('mFromDate', value.mFromDate, {});
                                                        setValue('mToDate', value.mToDate, {});
                                                        const currentValues = getValues();
                                                        reset(currentValues)
                                                    }}
                                                    isDisabled={false}
                                                />
                                            </Grid>
                                            :
                                            (reportType === 3) ? <>
                                                    <Grid item xs={12} xl={4}>
                                                        <FormTextBox
                                                            label="Mese"
                                                            control={control}
                                                            record={currentFilter}
                                                            register={register}
                                                            field="mMese"
                                                            required={true}
                                                            pattern={"month"}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} xl={4}>
                                                        <FormTextBox
                                                            label="Anno"
                                                            control={control}
                                                            record={currentFilter}
                                                            register={register}
                                                            field="mAnno"
                                                            required={true}
                                                            pattern={"year"}
                                                        />
                                                    </Grid>
                                                </>
                                                :
                                                (reportType === 4) ?
                                                    <Grid item xs={12} xl={10}>
                                                        <DateFilter
                                                            showFrom
                                                            showTo
                                                            currentValue={getValues()}
                                                            setValue={(value) => {
                                                                setValue('mFromDate', value.mFromDate, {});
                                                                setValue('mToDate', value.mToDate, {});
                                                                const currentValues = getValues();
                                                                reset(currentValues)
                                                            }}
                                                            isDisabled={false}
                                                        />
                                                    </Grid>
                                                    :
                                                    (reportType === 5) ?
                                                        <Grid item xs={12} xl={10}>
                                                            <DateFilter
                                                                showFrom
                                                                currentValue={getValues()}
                                                                setValue={(value) => {
                                                                    setValue('mFromDate', value.mFromDate, {});
                                                                    setValue('mToDate', value.mToDate, {});
                                                                    const currentValues = getValues();
                                                                    reset(currentValues)
                                                                }}
                                                                isDisabled={false}
                                                            />
                                                        </Grid>
                                                        :
                                                        (reportType === 6) ?
                                                            <>
                                                                <Grid item xs={12} xl={6}>
                                                                    <DateFilter
                                                                        showFrom
                                                                        showTo
                                                                        currentValue={getValues()}
                                                                        setValue={(value) => {
                                                                            setValue('mFromDate', value.mFromDate, {});
                                                                            setValue('mToDate', value.mToDate, {});
                                                                            const currentValues = getValues();
                                                                            reset(currentValues)
                                                                        }}
                                                                        isDisabled={false}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} xl={4}>
                                                                    <Autocomplete
                                                                        name={`auto-complete-doctor`}
                                                                        key={`auto-complete-doctor`}
                                                                        disablePortal
                                                                        value={selectedDoctor}
                                                                        options={doctors}
                                                                        onChange={(event, item) => {
                                                                            item = item ?? {};
                                                                            setValue('mDoctor', item.id, {});
                                                                            const currentValues = getValues();
                                                                            reset(currentValues);
                                                                        }}
                                                                        renderInput={(params) =>
                                                                            <TextField variant="filled"
                                                                                       label={"Medico assegnatario"} {...params}/>
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </>
                                                            :
                                                            (reportType === 8) ?
                                                                <>
                                                                    <Grid item xs={12} xl={6}>
                                                                        <DateFilter
                                                                            showFrom
                                                                            showTo
                                                                            currentValue={getValues()}
                                                                            setValue={(value) => {
                                                                                setValue('mFromDate', value.mFromDate, {});
                                                                                setValue('mToDate', value.mToDate, {});
                                                                                const currentValues = getValues();
                                                                                reset(currentValues)
                                                                            }}
                                                                            isDisabled={false}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} xl={4}>
                                                                        <Autocomplete
                                                                            name={`auto-complete-room`}
                                                                            key={`auto-complete-room`}
                                                                            disablePortal
                                                                            value={selectedRoom}
                                                                            options={rooms}
                                                                            onChange={(event, item) => {
                                                                                item = item ?? {};
                                                                                setValue('mRoom', item.id, {});
                                                                                const currentValues = getValues();
                                                                                reset(currentValues);
                                                                            }}
                                                                            required={true}
                                                                            renderInput={(params) =>
                                                                                <TextField variant="filled"
                                                                                           label={"Stanza"} {...params}/>
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </>
                                                                :
                                                                (reportType === 9) ?
                                                                    <>
                                                                        <Grid item xs={12} xl={6}>
                                                                            <DateTimeFilter
                                                                                showFrom
                                                                                showTo
                                                                                currentValue={getValues()}
                                                                                setValue={(value) => {
                                                                                    setValue('mFromDate', value.mFromDate, {});
                                                                                    setValue('mToDate', value.mToDate, {});
                                                                                    const currentValues = getValues();
                                                                                    reset(currentValues)
                                                                                }}
                                                                                isDisabled={false}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} xl={4}>
                                                                            <Autocomplete
                                                                                name={`auto-complete-room`}
                                                                                key={`auto-complete-room`}
                                                                                disablePortal
                                                                                value={selectedRoom}
                                                                                options={rooms}
                                                                                onChange={(event, item) => {
                                                                                    item = item ?? {};
                                                                                    setValue('mRoom', item.id, {});
                                                                                    const currentValues = getValues();
                                                                                    reset(currentValues);
                                                                                }}
                                                                                required={true}
                                                                                renderInput={(params) =>
                                                                                    <TextField variant="filled" label={"Stanza"} {...params}/>
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                    :
                                                                    <></>
                                    }
                                    <Grid item xs={12} lg={2}>
                                        <FormSubmitButton label={"Genera"} tooltip={"Genera"} loading={isGenerating}
                                                          disabled={!isSubmittable}/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </form>
                    </CardModal>
                </CardContent>
            </Card>
        </>
    );
}

export default AccountingReports;
