import {Avatar} from '@mui/material';
import React, {useContext, useEffect} from 'react';
import {AuthContext} from "../../context/AuthContext";
import {stringAvatar} from "../../common";

const UserImage = (props) => {

    const authContext = useContext(AuthContext);

    let imageByteArray = authContext.getLoggedUserImage();

    useEffect(() => {
    }, []);

    if ((imageByteArray.length!==0) && (imageByteArray !== "")) {
        return (
            <Avatar alt={authContext.getLoggedUserCompleteName()} src={imageByteArray} sx={{ width: props.width, height: props.height }} />
        );
    }
    else {
        return (
            <Avatar {...stringAvatar(authContext.getLoggedUserCompleteName())}/>
        );
    }
}

export default UserImage;