import {Grid, Typography, useTheme} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {FormSubmitButton} from "../../../../components/Form/FormSubmitButton";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {AuthContext} from "../../../../context/AuthContext";
import {loggedClassNames} from "../../../../theme/Styles";
import {useNavigate} from "react-router-dom";

const ChangePassword = (props) => {

    const authContext = useContext(AuthContext);
    const alertContext = useContext(CustomAlertContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    let navigate = useNavigate();

    const [record, setRecord] = useState(null);

    const {
        watch,
        register,
        control,
        formState: {errors},
        handleSubmit,
        reset,
        getValues
    } = useForm({defaultValues: record});

    useEffect(() => {
        if (!record) {
            let changeStatus = authContext.changePasswordStatus;
            setRecord(changeStatus)
            reset(changeStatus);
        }
    }, []);

    const onSubmit = data => {
        if (data.mRepeatNewPassword !== data.mNewPassword) {
            alertContext.showCustomAlert("error", "Attenzione! Le due password devono coincidere.");
            authContext.setChangePasswordStatus(data);
            return;
        }
        alertContext.showConfirmDialog("Cambio password", "Confermando il cambio password la sessione verrà conclusa.",
            () => {
            },
            () => {
                authContext.changePassword(data.mNewPassword).then(() => {
                    authContext.logout().then(() => {
                        navigate("/Login");
                    })
                })
            }
        )

    }

    const onError = (errors, e) => {
        console.log(errors, e);
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid
                    container
                    direction={"row"}
                    spacing={theme.spacing(2)}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                >
                    <Grid item xs={12}>
                        <Typography variant="h4">Modifica password</Typography>
                    </Grid>
                    <Grid item xl={4} md={12}>
                        <FormTextBox
                            label="Password attuale"
                            control={control}
                            record={record}
                            register={register}
                            field="mCurrentPassword"
                            required={true}
                            maxLength={50}
                            pattern={"password"}
                        />
                    </Grid>
                    <Grid item xl={4} md={12}>
                        <FormTextBox
                            label="Nuova password"
                            control={control}
                            record={record}
                            register={register}
                            field="mNewPassword"
                            required={true}
                            maxLength={50}
                            pattern={"password"}
                        />
                    </Grid>
                    <Grid item xl={4} md={12}>
                        <FormTextBox
                            label="Ripeti password"
                            control={control}
                            record={record}
                            register={register}
                            field="mRepeatNewPassword"
                            required={true}
                            maxLength={50}
                            pattern={"password"}
                        />
                    </Grid>
                    <Grid item xl={3} md={12}>
                        <FormSubmitButton label={"cambia"}/>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default ChangePassword;