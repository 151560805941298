import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Grid,
    Popover,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import React, {useContext, useState} from "react";
import {getCompleteName} from "../../common";
import {BookingContext} from "../../context/BookingContext";
import {ManagementContext} from "../../context/ManagementContext";
import SmsComposer from "../Sms/SmsComposer";
import {loggedClassNames} from "../../theme/Styles";
import {StatusBadge} from "../Badges/StatusBadge";
import {ActionButton} from "../Buttons/ActionButton";
import VisitLineSlotDetail from "./VisitLineSlotDetail";
import {CardModal} from "../Layout/CardModal";
import {useNavigate} from "react-router-dom";
import {CommonContext} from "../../context/CommonContext";
import {ExpandMoreTwoTone} from "@mui/icons-material";
import {VisitLineSlotPaper} from "./VisitLineSlotPaper";
import {IconLibraryItem} from "../Icons/IconLibraryItem";
import {RegistriesContext} from "../../context/RegistriesContext";
import {VisitLineSlotHeader} from "./VisitLineSlotHeader";

export const VisitLineSlot = ({room, slot}) => {

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    let visitLinesCount = slot.visitLines.length;
    let isEmpty = visitLinesCount === 0;
    let slotPaddingFactor = 5;
    let slotFactor = 50;
    let slotPadding = (slot.size === 1) ? slotPaddingFactor : slotPaddingFactor * (slot.size + 2);
    let slotHeight = slotPadding + (slotFactor * slot.size);

    return (
        <>
            <Accordion
                sx={{
                    ...loggedClasses.roomSlot,
                    minHeight: slotHeight
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreTwoTone/>}
                    aria-controls={slot.key + "-content"}
                    id={slot.key + "-accordion"}
                    sx={{
                        ...loggedClasses.roomSlotTitle,
                        minHeight: slotHeight
                    }}
                >
                    <VisitLineSlotHeader slot={slot} room={room} key={slot.key}/>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        (!isEmpty) && (slot.visitLines).map((vl, index) => {
                            return (
                                <VisitLineSlotPaper
                                    room={room}
                                    slot={slot}
                                    vl={vl}
                                    key={slot.key + "-" + vl.mEsameID}
                                />
                            )
                        })
                    }
                </AccordionDetails>
            </Accordion>
        </>
    )
}