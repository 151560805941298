import {Paper, Typography, useTheme} from "@mui/material";
import React, {useContext, useState} from "react";
import {RoomIntervalConfig} from "./RoomIntervalConfig";
import {BookingContext} from "../../context/BookingContext";
import {CommonContext} from "../../context/CommonContext";
import {loggedClassNames} from "../../theme/Styles";

const RoomBox = ({room, openDetails})=>  {

    const bookingContext = useContext(BookingContext);
    const commonContext = useContext(CommonContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [configOpen, setConfigOpen] = useState(false);

    return (
        <Paper
            sx={loggedClasses.schedulerFirstLastColumn} elevation={3}
            key={'room-paper-' + room.mRoomID}
            onClick={() => {openDetails(room)}}
        >
            <Typography variant={"body2"} align={"center"}>
                {room.mRoomName}
            </Typography>
        </Paper>
    )
}

export default RoomBox