import {CardActions, CardContent, Grid, Stack, TextField, Typography, useTheme} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {withRouter} from 'react-router-dom';
import {
    booleanRenderer,
    columnTypes,
    defaultColDef,
    moneyColumnRenderer,
    isDataLoading,
    isDataNull, floatColumnRenderer, booleanMatcher
} from "../../../../common";
import {FormActionBar} from "../../../../components/Form/FormActionBar";
import {FormCheckBox} from "../../../../components/Form/FormCheckBox";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridExtraSizeStyle, gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {ActionButton} from "../../../../components/Buttons/ActionButton";

const Conventions = () => {

    const registriesContext = useContext(RegistriesContext);
    const alertContext = useContext(CustomAlertContext);

    const inputRef = useRef();
    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [modalOpen, setModalOpen] = useState(false);
    const [record, setRecord] = useState({});
    const [webDiscountVisible, setDiscountVisible] = useState(false);

    const [extraRecordId, setExtraRecordId] = useState(-1);
    const [extraRecords, setExtraRecords] = useState([]);
    const [extraModalOpen, setExtraModalOpen] = useState(false);
    const [extraRecord, setExtraRecord] = useState(null);

    const [isGridExpanded, setGridIsExpanded] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);


    const {
        register,
        control,
        handleSubmit,
        reset,
        getValues,
        watch
    } = useForm({defaultValues: record});

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(registriesContext.conventions)) registriesContext.fetchConventionsAsync().then((res) => {
            setDataLoaded(true);
        })
    }, [registriesContext.conventions]);

    //dettaglio

    useEffect(() => {
        const subscription = watch(
            (value, {name, type}) => {
                if (type === 'change') {
                    if (name === 'mIsWebEnabled') {
                        const currentValues = getValues();
                        setDiscountVisible(currentValues.mIsWebEnabled ?? false);
                    }
                }
            }
        );
        return () => subscription.unsubscribe();
    }, [watch]);

    const onSubmit = (data) => {
        console.log('record ', record);
        data = {
            ...data,
            mDiscount: parseInt(data.mDiscount ?? "0")
        };
        console.log('data ', data);
        registriesContext.updateConvention(data);
        setModalOpen(false);
        setGridIsExpanded(false);
    }

    const onError = (errors, e) => {
        console.log(record);
        console.log(errors, e);
    }

    const onClose = (event, reason) => {
        setModalOpen(false);
    }

    //griglia

    const addAction = () => {
        registriesContext.fetchConvention(0).then((res) => {
            setRecord(res);
            setDiscountVisible(res.mIsWebEnabled ?? false);
            reset(res);
            setModalOpen(true);
        });
    }

    const deleteRecord = (record) => {
        registriesContext.deleteConvention(record);
    }

    const deleteRecordCancel = () => {
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            registriesContext.fetchConvention(selectedRow.mConventionID).then((res) => {
                setRecord(res);
                setDiscountVisible(res.mIsWebEnabled ?? false);
                reset(res);
                setModalOpen(true);
            });
        };

        const deleteAction = () => {
            alertContext.showConfirmDialog(
                "Conferma eliminazione",
                "Stai elimimando la convenzione. Sei sicuro? ",
                deleteRecordCancel,
                () => {
                    deleteRecord(selectedRow);
                }
            );

        }

        const duplicateAction = () => {
            registriesContext.duplicateConvention(selectedRow.mConventionID).then((res) => {
                registriesContext.fetchConventions();
                if (!res) return;
                registriesContext.fetchConvention(res.mConventionID).then((res) => {
                    setRecord(res);
                    setDiscountVisible(res.mIsWebEnabled ?? false);
                    reset(res);
                    setModalOpen(true);
                });
            });
        }

        const pricesAction = () => {
            setExtraRecordId(selectedRow.mConventionID);
            // setExtraModalOpen(true);
            registriesContext.fetchConvention(selectedRow.mConventionID).then((res) => {
                setExtraRecord(res);
                setExtraRecords([]);
                setExtraModalOpen(true);
            });
        }

        const customActions = [
            {
                action: duplicateAction,
                disabled: false,
                icon: "copy",
                tooltip: "Duplica",
            },
            {
                action: pricesAction,
                disabled: false,
                icon: "euro",
                tooltip: "Prezzi",
            }
        ];

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
                customActions={customActions}
            />
        )
    }

    //prices

    const [columnsExtra, setColumnsExtra] = useState([
        {headerName: "Descrizione", field: ""},
        {headerName: "Prezzo", field: ""},
        // {headerName: "Azioni", cellRenderer: ExtraToolBar, type: "toolBar"}
    ]);

    const onExtraSubmit = (data) => {
        data = {
            ...data,
            mDiscount: parseInt(data.mDiscount ?? "0")
        };
        registriesContext.updateConvention(data);
        setExtraModalOpen(false);
    }

    const onExtraClose = () => {
        setExtraModalOpen(false);
        setExtraRecordId(-1);
    }

    const onGridReady = (params) => {
        // setAgGridApi(params.api);
        if (isGridExpanded) return
        else setGridIsExpanded(true);
        params.api.sizeColumnsToFit();
    }

    const onGridReadyCallback = useCallback((params) => {
        if (isGridExpanded) return
        else setGridIsExpanded(true);
        params.api.sizeColumnsToFit();
    }, []);

    const [columns, setColumns] = useState([
        {headerName: "Codice", field: "mConventionCode"},
        {headerName: "Descrizione", field: "mConventionDescription"},
        {headerName: "Abilitata", field: "mEnabled", cellRenderer: booleanRenderer, filterParams: { textMatcher: booleanMatcher  }},
        {headerName: "ASL", field: "mIsASL", cellRenderer: booleanRenderer, filterParams: { textMatcher: booleanMatcher  }},
        {headerName: "Disp. web", field: "mIsWebEnabled", cellRenderer: booleanRenderer, filterParams: { textMatcher: booleanMatcher  }},
        {headerName: "Sconto %", field: "mDiscount"},
        {headerName: "Prestazioni", field: "mPerformanceNumber"},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    if (isDataNull(registriesContext.conventions) || isDataLoading(registriesContext.conventions)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Convenzioni</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{pb: theme.spacing(2)}}>
                        <Grid item xs={12}>
                            <Stack
                                direction={"row"}
                                justifyContent={"flex-end"}
                                spacing={theme.spacing(2)}
                                sx={{pt: theme.spacing(1)}}>
                                <AddButton addAction={addAction}/>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={registriesContext.conventions}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            onGridReady={onGridReadyCallback}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
            <CardModal
                modalOpen={modalOpen}
                onClose={onClose}
                title={"Dettaglio convenzione"}
                size={"small"}
                formFunctions={{submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError}}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12} md={12}>
                            <FormTextBox
                                label="Codice"
                                control={control}
                                record={record}
                                register={register}
                                field="mConventionCode"
                                required={true}
                                maxLength={10}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormTextBox
                                label="Descrizione"
                                control={control}
                                record={record}
                                register={register}
                                field="mConventionDescription"
                                required={true}
                                maxLength={100}
                            />
                        </Grid>
                        {/*<FormAutoComplete*/}
                        {/*    label="Azienda proponente"*/}
                        {/*    control={control}*/}
                        {/*    record={record}*/}
                        {/*    register={register}*/}
                        {/*    field="mPublisherCompany"*/}
                        {/*    joinField="mCompanyID"*/}
                        {/*    values={registriesContext.companies}*/}
                        {/*    idField="mCompanyID"*/}
                        {/*    labelField="mCompanyName"*/}
                        {/*    selectedValue="id"*/}
                        {/*    required={true}*/}
                        {/*/>*/}
                        {/*<FormAutoComplete*/}
                        {/*    label="Azienda sottoscrittrice"*/}
                        {/*    control={control}*/}
                        {/*    record={record}*/}
                        {/*    register={register}*/}
                        {/*    field="mSubscriberCompany"*/}
                        {/*    joinField="mCompanyID"*/}
                        {/*    values={registriesContext.companies}*/}
                        {/*    idField="mCompanyID"*/}
                        {/*    labelField="mCompanyName"*/}
                        {/*    selectedValue="id"*/}
                        {/*    required={true}*/}
                        {/*/>*/}
                        <Grid item xs={12} md={3}>
                            <FormCheckBox
                                label="Abilitata"
                                control={control}
                                record={record}
                                register={register}
                                field="mEnabled"
                                required={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormCheckBox
                                label="ASL"
                                control={control}
                                record={record}
                                register={register}
                                field="mIsASL"
                                required={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormCheckBox
                                label="Disponibile alla prenotazione web"
                                control={control}
                                record={record}
                                register={register}
                                field="mIsWebEnabled"
                                required={false}
                            />
                        </Grid>
                        {
                            (webDiscountVisible) &&
                            <Grid item xs={12} md={3}>
                                <FormTextBox
                                    label="Sconto web %"
                                    control={control}
                                    record={record}
                                    register={register}
                                    field="mDiscount"
                                    required={true}
                                    maxLength={100}
                                    pattern={"number"}
                                />
                            </Grid>
                        }
                    </Grid>
                </CardContent>
            </CardModal>
            <CardModal
                modalOpen={extraModalOpen}
                onClose={onExtraClose}
                title={"Prezzi della convenzione"}
                formFunctions={{submitHandler: handleSubmit, onFormSubmit: onExtraSubmit, onFormError: onError}}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        spacing={theme.spacing(2)}
                        direction={"row"}
                    >
                        <Grid item container spacing={theme.spacing(2)}>
                            <Grid item xs={3}>
                                <ActionButton
                                    color="secondary"
                                    variant="contained"
                                    action={() => {
                                        alert('download')
                                    }}
                                    disabled={false}
                                    icon={"download"}
                                    iconSize={"small"}
                                    label={"Scarica listino da compilare"}
                                    size={"small"}
                                    tooltip={"Scarica listino"}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <ActionButton
                                    color="secondary"
                                    variant="contained"
                                    action={() => {
                                        alert('download')
                                    }}
                                    disabled={false}
                                    icon={"upload"}
                                    iconSize={"small"}
                                    label={"Carica listino compilato"}
                                    size={"small"}
                                    tooltip={"Carica listino compilato"}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <div id="agReactGrid" className="ag-theme-alpine" style={gridExtraSizeStyle}>
                                <AgGridReact
                                    rowData={extraRecords}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnsExtra}
                                    columnTypes={columnTypes}
                                    suppressMovableColumns={true}
                                    suppressCellFocus={true}
                                    pagination={true}
                                    rowSelection={'single'}
                                    getRowHeight={(params) => gridRowHeight}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardModal>
        </>
    )

}

export default Conventions;