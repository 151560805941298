import {FormControl} from "@mui/material";
import TextField from '@mui/material/TextField';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import moment from "moment";
import React, {useContext} from 'react';
import {Controller} from "react-hook-form";
import {CommonContext} from "../../context/CommonContext";

export const FormTimePicker = ({
                                   notFullWidth,
                                   noFormControl,
                                   minTime,
                                   maxTime,
                                   minutesStep,
                                   ...props
                               }) => {

    const commonContext = useContext(CommonContext);

    let it = moment().locale('it');

    let shortTimeFormat = commonContext.getPlanningParameter("TIME_FORMAT_SHORT");

    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDay();

    const minHours = moment(minTime ?? "07:00", shortTimeFormat).hours();
    const minMinutes = moment(minTime ?? "07:00", shortTimeFormat).minutes();
    const dtMinTime = moment(new Date(year, month, day, minHours, minMinutes));

    const maxHours = moment(maxTime ?? "22:00", shortTimeFormat).hours();
    const maxMinutes = moment(maxTime ?? "22:00", shortTimeFormat).minutes();
    const dtMaxTime = moment(new Date(year, month, day, maxHours, maxMinutes));

    const stepMins = minutesStep ?? 30;

    const getOptions = () => {
        let options = {}
        if (props.required !== null) {
            options.required = {};
            options.required.value = props.required;
            if (props.required === true) {
                options.required.message = "Campo richiesto";
            }
        }
        return options;
    }

    const isDisabled = () => {
        if (props.disabled) {
            return props.disabled;
        } else {
            return false;
        }
    }

    return (
        <FormControl fullWidth>
            <Controller
                name={props.field}
                control={props.control}
                render={({
                             field: {onChange, onBlur, value, name, ref},
                             fieldState: {invalid, isTouched, isDirty, error},
                             formState,
                         }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={it}>
                        <TimePicker
                            name={`timePicker-${name}`}
                            key={`timePicker-${name}`}
                            label={props.label}
                            inputFormat={props.format}
                            value={value}
                            minTime={dtMinTime}
                            maxTime={dtMaxTime}
                            minutesStep={stepMins}
                            disabled={isDisabled()}
                            onChange={(date) => {
                                onChange(date);
                            }}
                            onBlur={onBlur}
                            renderInput={(params) =>
                                <TextField
                                    variant="filled"
                                    inputRef={ref}
                                    {...params}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />}
                        />
                    </LocalizationProvider>
                )}
                {...props.register(props.field, getOptions())}
            />
        </FormControl>
    )

}