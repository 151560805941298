import {Alert, CardContent, Grid, Stack, Typography, useTheme} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {columnTypes, defaultColDef, emptyJSON, isDataLoading, isDataNull, JSONIsEmpty} from "../../../../common";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";

export const SponsorDetail = ({
                                  recordID,
                                  setRecordID,
                                  saveCallback,
                                  cancelCallback,
                                  ...props
                              }) => {

    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [sponsorRecord, setSponsorRecord] = useState(emptyJSON);

    const {
        register,
        control,
        handleSubmit,
        reset,
        setValue
    } = useForm({defaultValues: sponsorRecord});

    useEffect(() => {
        if (isDataNull(registriesContext.sponsorTypes) && !isDataLoading(registriesContext.sponsorTypes)) registriesContext.fetchSponsorTypes().then((res) => {});
    }, [registriesContext.sponsorTypes]);

    useEffect(() => {
        if (recordID === -1) return;
        if (!JSONIsEmpty(sponsorRecord)) return;
        registriesContext.fetchSponsor(recordID).then((res) => {
            setSponsorRecord(res);
            reset(res);
        });
    }, [recordID]);

    const onSubmit = (data) => {
        registriesContext.updateSponsor(data).then((res) => {
            setRecordID(-1);
            setSponsorRecord(emptyJSON);
            if (saveCallback) saveCallback(res.mSponsorID);
        });
    }

    const onError = (errors, e) => {
        console.log(sponsorRecord);
        console.log(errors, e);
    }

    const onClose = (event, reason) => {
        setRecordID(-1);
        setSponsorRecord(emptyJSON);
        if (cancelCallback) cancelCallback();
    }

    const modalOpen = () => {
        return (recordID !== -1) && (!JSONIsEmpty(sponsorRecord));
    }

    if (!modalOpen()) return <></>;

    if ((recordID !== -1) && (JSONIsEmpty(sponsorRecord))) return <CircularWaiting/>;

    const setNoAsl = () => {
        setValue('mASLCode', '8888888888888888');
    }

    return (
        <CardModal
            modalOpen={modalOpen}
            onClose={onClose}
            title={"Dettaglio sponsor"}
            size={"small"}
            formFunctions={{submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError}}
        >
            <CardContent sx={loggedClasses.detailsContent}>
                <Grid
                    container
                    spacing={theme.spacing(1)}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                >
                    <Grid item xs={12} md={6}>
                        <FormTextBox
                            label="Cognome"
                            control={control}
                            record={sponsorRecord}
                            register={register}
                            field="mSponsorLastName"
                            required={true}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormTextBox
                            label="Nome"
                            control={control}
                            record={sponsorRecord}
                            register={register}
                            field="mSponsorFirstName"
                            required={true}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormTextBox
                            label="Telefono"
                            control={control}
                            record={sponsorRecord}
                            register={register}
                            field="mSponsorPhone"
                            required={true}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <FormTextBox
                            label="Codice ASL"
                            control={control}
                            record={sponsorRecord}
                            register={register}
                            field="mASLCode"
                            required={true}
                            maxLength={50}
                            disabled={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <ActionButton
                            color="warning"
                            variant="contained"
                            action={setNoAsl}
                            size="small"
                            icon="check"
                            iconSize="medium"
                            tooltip="Illegibile"
                            label="Illegibile"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <FormAutoComplete
                            label="Tipo"
                            control={control}
                            record={sponsorRecord}
                            register={register}
                            field="mTipoSoggettoPrescrittore"
                            joinField="mTipoSoggettoPrescrittoreID"
                            values={registriesContext.sponsorTypes}
                            idField="mTipoSoggettoPrescrittoreID"
                            labelField="mDescrizione"
                            selectedValue="id"
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity="error">
                            Inserire il codice fiscale del medico se presente,
                            altrimenti il suo codice ASL, se presente o se leggibile.
                            Altrimenti fare click su "Illegibile"
                        </Alert>
                    </Grid>
                </Grid>
            </CardContent>
        </CardModal>
    );
}

const Sponsors = () => {

    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();

    const [dataLoaded, setDataLoaded] =useState(false);
    const [detailID, setDetailID] = useState(-1);

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(registriesContext.sponsors)) registriesContext.fetchSponsorsAsync().then((res) => {
            setDataLoaded(true);
        });
    }, [registriesContext.sponsors]);

    //griglia

    const addAction = () => {
        setDetailID(0);
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            setDetailID(selectedRow.mSponsorID);
        };

        const deleteAction = () => {
            alert(selectedRow.mSponsorID);
        }

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
            />
        )
    }

    const [columns, setColumns] = useState([
        {headerName: "Nome", field: "mCompleteName"},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    if (isDataNull(registriesContext.sponsors) || isDataLoading(registriesContext.sponsors)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Soggetto prescrittore</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{pb: theme.spacing(2)}}>
                        <Grid item xs={12}>
                            <Stack
                                direction={"row"}
                                justifyContent={"flex-end"}
                                spacing={theme.spacing(2)}
                                sx={{pt: theme.spacing(1)}}>
                                <AddButton addAction={addAction}/>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={registriesContext.sponsors}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
            <SponsorDetail recordID={detailID} setRecordID={setDetailID}/>

        </>
    )

}

export default Sponsors;