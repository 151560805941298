import {CardContent, Grid, Stack, useTheme} from "@mui/material";
import {gridExtraSizeStyle, gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {AgGridReact} from "ag-grid-react";
import {
    booleanRenderer,
    columnTypes,
    dateTimeShortColumnRenderer, daysWithName,
    defaultColDef,
    isDataNull, JSONClone,
    timeColumnRenderer, weekdayRenderer
} from "../../../../common";
import {CardModal} from "../../../../components/Layout/CardModal";
import React, {useContext, useEffect, useState} from "react";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import ReactJson from "react-json-view";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {useForm} from "react-hook-form";
import {RecupContext} from "../../../../context/RecupContext";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormTimePicker} from "../../../../components/Form/FormTimePicker";
import {CommonContext} from "../../../../context/CommonContext";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {FormDatePicker} from "../../../../components/Form/FormDatePicker";
import moment from "moment";

export const RecupEuDiaryTimeBands = ({
                                          open,
                                          onClose,
                                          diaryId,
                                          schemaId,
                                          executionUnitId,
                                      }) => {

    const commonContext = useContext(CommonContext);
    const recupContext = useContext(RecupContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const newSchemaRecord = {
        mSchemaBeginDate: moment(),
        mSchemaEndDate: moment(),
        mSchemaTimebands: []
    }

    const newTimeBandRecord = {
        mID: -1,
        mDay: 1,
        mOraInizio: null,
        mOraFine: null,
    }

    const [schemaRecord, setSchemaRecord] = useState(null);
    const [timebandRecord, setTimebandRecord] = useState({});
    const [timebandToRemove, setTimebandToRemove] = useState(-1);
    const [adding, setAdding] = useState(false);
    const [updating, setUpdating] = useState(false);

    const openingTime = commonContext.getPlanningParameter("OPENING_TIME");
    const closingTime = commonContext.getPlanningParameter("CLOSING_TIME");
    const slotDuration = commonContext.getPlanningParameter("SLOT_SIZE");

    useEffect(() => {
        if (timebandToRemove === -1) return;
        const foundIndex = schemaRecord.mSchemaTimebands.findIndex(item => item.mID === timebandToRemove);
        const newTimeBands = [...schemaRecord.mSchemaTimebands];
        newTimeBands.splice(foundIndex, 1);
        setSchemaRecord({...schemaRecord, mSchemaTimebands: newTimeBands});
        setAdding(false);
        setTimebandToRemove(-1);
    }, [timebandToRemove]);

    useEffect(() => {
        if (schemaRecord) return;
        if (schemaId===0) {
            const rec = JSONClone(newSchemaRecord);
            setSchemaRecord(rec);
            resetSchema(rec)
        }
        else {
            const body = {
                mAgendaID: diaryId,
                mSchemaID: schemaId,
            }
            recupContext.fetchSchemaTimeBands(body).then(res => {
                console.log('fetchSchemaTimeBands ', res);
                const schema = res.find(s=> s.id===schemaId);
                console.log('schema ', schema);
                if (schema) {
                    const timebands = schema.timebands.map((item,index) => {
                        return {
                            mID: index,
                            mDay: item.weekday,
                            mOraInizio: moment(item.startTime),
                            mOraFine:  moment(item.endTime),
                        }
                    })
                    const rec = {
                        mSchemaBeginDate: moment(schema.startDate),
                        mSchemaEndDate: moment(schema.endDate),
                        mSchemaTimebands: timebands
                    }
                    setSchemaRecord(rec);
                    resetSchema(rec);
                }
            });
        }
    }, [schemaRecord]);

    const {
        register: registerSchema,
        control: controlSchema,
        handleSubmit: handleSubmitSchema,
        reset: resetSchema,
        getValues: getValuesSchema
    } = useForm({defaultValues: schemaRecord});

    const {
        register: registerTimeBand,
        control: controlTimeBand,
        reset: resetTimeBand,
        getValues: getValuesTimeBand
    } = useForm({defaultValues: timebandRecord});

    const onSubmit = (data) => {
        const body = {
            mSchemaID: schemaId,
            mExecutionUnitID: executionUnitId,
            mAgendaID: diaryId,
            mSchemaBeginDate: data.mSchemaBeginDate,
            mSchemaEndDate: data.mSchemaEndDate,
            mSchemaTimebands: schemaRecord.mSchemaTimebands
        }
        recupContext.createTimeBands(body).then(res => {
            onClose();
        });
    }

    const onError = (errors, e) => {
        console.log(errors, e);
    }

    const newTimebandAction = () => {
        const rec = {
            ...JSONClone(newTimeBandRecord),
            mID: schemaRecord.mSchemaTimebands.length
        };
        setTimebandRecord(rec);
        resetTimeBand(rec);
        setAdding(true);
    }

    const saveTimebandAction = () => {
        const values = getValuesTimeBand();
        if (!values.mOraInizio) return;
        if (!values.mOraFine) return;
        const newTimeBands = [...schemaRecord.mSchemaTimebands, values];
        setSchemaRecord({...schemaRecord, mSchemaTimebands: newTimeBands});
        const rec = {...JSONClone(newTimeBandRecord)};
        setTimebandRecord(rec);
        resetTimeBand(rec);
        setAdding(false);
    }

    const ToolBarTimebands = (props) => {

        let selectedRow = props.node.data;

        const deleteAction = () => {
            setTimebandToRemove(selectedRow.mID);
        }

        return (
            <ActionsToolBar
                hasEdit={false}
                hasDelete deleteAction={deleteAction}
            />
        )

    }

    const [columnsTimebands, setColumnsTimebands] = useState([
        {headerName: "#", field: "mID"},
        {headerName: "Giorno", field: "mDay", cellRenderer: weekdayRenderer},
        {headerName: "Dalle", field: "mOraInizio", cellRenderer: timeColumnRenderer},
        {headerName: "Alle", field: "mOraFine", cellRenderer: timeColumnRenderer},
        {headerName: "Azioni", cellRenderer: ToolBarTimebands, type: "toolBar"}
    ]);

    if (!schemaRecord) return <CircularWaiting/>

    return (
        <>
            <CardModal
                modalOpen={open}
                onClose={onClose}
                title={"Creazione time bands agenda " + diaryId}
                size={"small"}
                formFunctions={{
                    submitHandler: handleSubmitSchema,
                    onFormSubmit: onSubmit,
                    onFormError: onError
                }}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        {
                            !updating ?
                                <>
                                    <Grid
                                        item
                                        container
                                        direction={"row"}
                                        spacing={theme.spacing(2)}
                                        justifyContent={"flex-start"}
                                        alignItems={"flex-start"}
                                    >
                                        <Grid item xs={6}>
                                            <FormDatePicker
                                                label="Dal"
                                                control={controlSchema}
                                                record={schemaRecord}
                                                register={registerSchema}
                                                field="mSchemaBeginDate"
                                                required={true}
                                                format={commonContext.getPlanningParameter("DATE_FORMAT_SHORT")}
                                                disabled={false}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormDatePicker
                                                label="Al"
                                                control={controlSchema}
                                                record={schemaRecord}
                                                register={registerSchema}
                                                field="mSchemaEndDate"
                                                required={true}
                                                format={commonContext.getPlanningParameter("DATE_FORMAT_SHORT")}
                                                disabled={false}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        direction={"row"}
                                        spacing={theme.spacing(2)}
                                        justifyContent={"flex-start"}
                                        alignItems={"flex-start"}
                                    >
                                        <Grid item xs={12} md={4}>
                                            <FormAutoComplete
                                                label="Day"
                                                control={controlTimeBand}
                                                record={timebandRecord}
                                                register={registerTimeBand}
                                                field="mDay"
                                                joinField=""
                                                values={daysWithName}
                                                idField="mDayID"
                                                labelField="mDayName"
                                                selectedValue="id"
                                                required={true}
                                                disabled={!adding}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormTimePicker
                                                label="Dalle"
                                                control={controlTimeBand}
                                                record={timebandRecord}
                                                register={registerTimeBand}
                                                field="mOraInizio"
                                                minTime={openingTime}
                                                maxTime={closingTime}
                                                minutesStep={5}
                                                required={true}
                                                format={commonContext.getPlanningParameter("TIME_FORMAT_SHORT")}
                                                disabled={!adding}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormTimePicker
                                                label="Alle"
                                                control={controlTimeBand}
                                                record={timebandRecord}
                                                register={registerTimeBand}
                                                field="mOraFine"
                                                minTime={openingTime}
                                                maxTime={closingTime}
                                                minutesStep={5}
                                                required={true}
                                                format={commonContext.getPlanningParameter("TIME_FORMAT_SHORT")}
                                                disabled={!adding}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <ActionButton
                                                color="primary"
                                                variant="contained"
                                                action={newTimebandAction}
                                                size="small"
                                                icon="new"
                                                iconSize="small"
                                                tooltip="Aggiungi"
                                                label=""
                                                sx={loggedClasses.registriesToolbarSx}
                                                disabled={adding}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <ActionButton
                                                color="primary"
                                                variant="contained"
                                                action={saveTimebandAction}
                                                size="small"
                                                icon="save"
                                                iconSize="small"
                                                tooltip="Salva"
                                                label=""
                                                sx={loggedClasses.registriesToolbarSx}
                                                disabled={!adding}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div id="agReactGridTimeBands" className="ag-theme-alpine"
                                             style={gridExtraSizeStyle}>
                                            <AgGridReact
                                                rowData={schemaRecord.mSchemaTimebands}
                                                defaultColDef={defaultColDef}
                                                columnDefs={columnsTimebands}
                                                columnTypes={columnTypes}
                                                suppressMovableColumns={true}
                                                suppressCellFocus={true}
                                                pagination={true}
                                                rowSelection={'single'}
                                                getRowHeight={(params) => gridRowHeight}
                                            >
                                            </AgGridReact>
                                        </div>
                                    </Grid>
                                </>
                                :
                                <CircularWaiting/>
                        }
                    </Grid>
                </CardContent>
            </CardModal>
        </>
    )
}
