import {Grid, Stack, Typography, useTheme} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {columnTypes, dateTimeShortColumnRenderer, defaultColDef} from "../../../../common";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {ActionsToolBar} from "../../../../components/Grid/ActionsToolbar";
import {StackedItem} from "../../../../components/Layout/StackedItem";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {MedicalReportingContext} from "../../../../context/MedicalReportingContext";
import {gridRowHeight, gridSizeStyle} from "../../../../theme/Styles";

export const MedicalReportsWorklist = (props) => {

    const medicalReportingContext = useContext(MedicalReportingContext);

    const theme = useTheme();
    let navigate = useNavigate();

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedVisits, setSelectedVisits] = useState([]);
    const [dataLoaded, setDataLoaded] =useState(false);

    const gridRef = useRef();

    useEffect(() => {
        if ((!dataLoaded) || medicalReportingContext.visitLinesToReport === null) {
            medicalReportingContext.fetchVisitLinesToReport().then((res) => {
                medicalReportingContext.setMedicalReportingLoaded(true);
                setDataLoaded(true);
            })
        }
    }, [medicalReportingContext.visitLinesToReport]);

    const ToolBar = (props) => {

        let record = props.node.data;

        const customDisabled = () => {
            return false;
        }

        const customAction = () => {
            openReporting([record]);
        };

        const customActions = [{
            action: customAction,
            disabled: customDisabled(),
            icon: "edit",
            tooltip: "Referta",
        }]

        return (
            <ActionsToolBar customActions={customActions}/>
        )
    }

    const columns = [
        {
            headerName: "Paziente", field: "mPatientCompleteName",
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
        },
        {headerName: "Visita", field: "mVisita.mAccessionNumber"},
        {headerName: "Prestazione", field: "mPrestazione.mDescrizione"},
        {headerName: "Data esame", field: "mDataAppuntamento", cellRenderer: dateTimeShortColumnRenderer},
        {headerName: "Assegnatario", field: "mAssignedDoctorCompleteName"},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ];

    const onSelectionChanged = () => {
        let rows = gridRef.current.api.getSelectedNodes().map(node => node.data);
        let visits = rows.map(q => q.mVisita.mVisitaID);
        let uniqueVisits = visits.filter((v, idx) => visits.indexOf(v) === idx);
        setSelectedRows(rows);
        setSelectedVisits(uniqueVisits);
    }

    const reportAllDisabled = () => {
        return (
            selectedRows.length === 0 || selectedVisits.length > 1
        )
    }

    const reportSelected = () => {
        let vls = selectedRows;
        openReporting(vls);
    }

    const openReporting = (vls) => {
        let reportingItem = {visitLinesToReport: vls}
        medicalReportingContext.openMedicalReporting(reportingItem, 2);
        navigate('/MedicalReporting/MedicalReportEditor');
    }

    if (!medicalReportingContext.medicalReportState.loaded) return <CircularWaiting/>

    return (
        <Grid
            container
            spacing={0}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
        >
            <Grid item xs={12}>
                <Typography variant="h4">Elenco esami da refertare </Typography>
            </Grid>
            <Grid item xs={12}>
                <Stack direction={"row"} spacing={theme.spacing(4)}>
                    <StackedItem>
                        <ActionButton
                            color="info"
                            variant="contained"
                            action={reportSelected}
                            size="medium"
                            icon="edit"
                            iconSize="medium"
                            tooltip="Referta selezionati"
                            label="Referta selezionati"
                            disabled={reportAllDisabled()}
                        />
                    </StackedItem>
                </Stack>
                <div id="invoicesGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={medicalReportingContext.visitLinesToReport}
                        defaultColDef={defaultColDef}
                        columnDefs={columns}
                        columnTypes={columnTypes}
                        suppressMovableColumns={true}
                        suppressCellFocus={true}
                        pagination={true}
                        rowSelection={'multiple'}
                        getRowHeight={(params) => gridRowHeight}
                        onSelectionChanged={onSelectionChanged}
                    >
                    </AgGridReact>
                </div>
            </Grid>
        </Grid>
    );

}