import {CardActions, CardContent, useTheme} from "@mui/material";
import React from "react";
import {loggedClassNames} from "../../theme/Styles";
import {FormActionBar} from "../Form/FormActionBar";
import {RoomScheduler} from "./RoomScheduler";
import {CardModal} from "../Layout/CardModal";

export const RoomIntervalConfig = ({
                                       open,
                                       onClose,
                                       room,
                                       ...props
                                   }) => {

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const mRoomID = ((room ?? {}).mRoomID ?? -1);
    const roomName = ((room ?? {}).mRoomName ?? "");

    if (mRoomID === -1) return <></>

    return (
        <CardModal
            modalOpen={open}
            onClose={onClose}
            title={"Configurazione sala " + roomName}
        >
            <CardContent sx={loggedClasses.detailsContent}>
                <RoomScheduler roomID={mRoomID}/>
            </CardContent>
            <CardActions>
                <FormActionBar hasSubmit={false} cancelAction={onClose} cancelLabel={"chiudi"}/>
            </CardActions>
        </CardModal>
    );
}