export default function Tab(theme) {
    return {
        MuiTab: {
            styleOverrides: {
                root: {
                    // minHeight: 46,
                }
            }
        }
    };
}
