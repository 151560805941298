import {Grid, Stack, Typography, useTheme} from "@mui/material";
import {ContextualMenu} from "../Menu/ContextualMenu";
import React from "react";


export const PageContainer = ({
                                  title,
                                  contextualMenuActions,
                                  ...props
                              }) => {
    const theme = useTheme();

    const titleCols = (contextualMenuActions) ? 8 : 12;

    return (
        <Grid
            container
            spacing={theme.spacing(2)}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
        >
            <Grid item container sx={{mt: theme.spacing(2)}}>
                <Grid item xs={12} md={titleCols}>
                    <Typography variant="h4">
                        {title}
                    </Typography>
                </Grid>
                {
                    (contextualMenuActions) &&
                    <Grid item xs={12} md={4}>
                        <Stack
                            direction={"row"}
                            justifyContent={"flex-end"}
                            alignItems={"flex-end"}
                        >
                            <ContextualMenu actions={contextualMenuActions}/>
                        </Stack>
                    </Grid>
                }
            </Grid>
            <Grid item xs={12}>
                { props.children }
            </Grid>
        </Grid>
    )
}