import {alpha} from "@mui/material";

export default function InputBase(theme) {
    return {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    // color: theme.palette.grey[600]
                    backgroundColor: theme.palette.grey[50],
                    border: "1px solid " + theme.palette.grey[300],
                    "&:before": {
                        borderBottom: "none !important",
                    },
                    "&.Mui-disabled:before": {
                        borderBottom: "none !important"
                    },
                    '&.Mui-focused': {
                        border: "1px solid " + theme.palette.primary.light,
                        boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: `1px solid ${theme.palette.primary.light}`
                        }
                    }
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.grey[50],
                    "&.Mui-disabled:before": {
                        borderBottom: "none !important"
                    },
                    '&.Mui-focused': {
                        backgroundColor: theme.palette.grey[50],
                    },
                    "&:hover": {
                        backgroundColor: theme.palette.grey[50],
                    }
                }
            }
        },
        MuiFocused: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.grey[50],
                    "&.Mui-disabled:before": {
                        borderBottom: "none !important"
                    }
                }
            }
        }
    };
}