import React from 'react';
import {Box, Grid, Typography, useTheme} from "@mui/material";
import imgSource from "../assets/images/doctor.png";
import AppLogo from "../components/Logos/AppLogo";
import {commonClassNames} from "../theme/Styles";

function NotAuthorize() {

    const theme = useTheme();

    return (
        <Grid container sx={{pt:theme.spacing(4)}}>
            <Grid item xs={12} textAlign={"center"}>
                <Typography>
                    <a href={"/Home"}>
                        <AppLogo size={200}/>
                    </a>
                </Typography>
                <Typography variant={"h3"}>Spiacenti, non sei autorizzato ad accedere a questa risorsa.</Typography>
                <img src={imgSource} alt={"notFound"} style={{height: '60vh', width: 'auto'}}/>
            </Grid>
        </Grid>
    );
}

export default NotAuthorize;