import {WarningTwoTone} from "@mui/icons-material";
import {CardContent, Grid, InputAdornment, Stack, Tooltip, Typography, useTheme} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {
    isDataLoading,
    isDataNull,
    JSONClone,
    JSONIsEmpty,
    moneyStringToFloat, parseMoney
} from "../../../../common";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {FormActionBar} from "../../../../components/Form/FormActionBar";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormDatePicker} from "../../../../components/Form/FormDatePicker";
import {FormDiscreteSlider} from "../../../../components/Form/FormDiscreteSlider";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {BookingContext} from "../../../../context/BookingContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {loggedClassNames} from "../../../../theme/Styles";
import {Availabilities} from "./Availabilities";
import {CardModal} from "../../../../components/Layout/CardModal";
import {CommonContext} from "../../../../context/CommonContext";
import moment from "moment";

const initial_avails_state = {
    fromDate: null,
    medicalServiceID: 0,
    offset: null,
    open: false
}

const TakeBooking = () => {

    const registriesContext = useContext(RegistriesContext);
    const commonContext = useContext(CommonContext);
    const bookingContext = useContext(BookingContext);
    const alertContext = useContext(CustomAlertContext);
    const [loaded, setLoaded] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [availsState, setAvailsState] = useState(JSONClone(initial_avails_state));

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    let navigate = useNavigate();

    const {
        watch,
        register,
        control,
        trigger,
        formState: {errors},
        handleSubmit,
        reset,
        getValues,
        setValue
    } = useForm({defaultValues: bookingContext.bookingItem}); //mode: 'onSubmit'

    useEffect(() => {

    }, []);

    const uniqueEnabledConvention = registriesContext.getUniqueActiveConvention();

    const DEFAULT_AGENDA_URL = commonContext.getPlanningParameter("DEFAULT_AGENDA_URL");

    console.log(DEFAULT_AGENDA_URL);

    useEffect(() => {
        if (loaded) return;
        if (isDataNull(registriesContext.enabledConventions) && !isDataLoading(registriesContext.enabledConventions)) registriesContext.fetchEnabledConventions();
        switch (bookingContext.bookingMode) {
            case 1:
                // isDataNull(registriesContext.roomMedicalServices) &&
                if (!isDataLoading(registriesContext.roomMedicalServices)) {
                    registriesContext.fetchRoomMedicalServices(bookingContext.bookingState.selectedRoom).then((res) => {
                        setLoaded(true);
                    })
                }
                break
            case 20:
                // isDataNull(registriesContext.activeMedicalServices)
                if (!isDataLoading(registriesContext.activeMedicalServices)) {
                    registriesContext.fetchActiveMedicalServicesAsync().then((res) => {
                        setLoaded(true);
                    })
                }
                break
            case 21:
                if (!isDataLoading(registriesContext.activeMedicalServices)) {
                    registriesContext.fetchActiveMedicalServicesAsync().then((res) => {
                        setLoaded(true);
                    })
                }
                break
            case 3:
                if (!isDataLoading(registriesContext.activeMedicalServices)) {
                    registriesContext.fetchActiveMedicalServicesAsync().then((res) => {
                        const medicalService = bookingContext.bookingItem.mPrestazione.mPrestazioneID;
                        const conventionID = bookingContext.bookingItem.mConvention.mConventionID;
                        registriesContext.fetchMedicalServicePrice(medicalService, conventionID).then((resPrice) => {
                            setValue('mPrezzoListino', resPrice, {});
                            setLoaded(true);
                        })
                    })
                }
                break
            case 6:
                if (!isDataLoading(registriesContext.roomMedicalServices)) {
                    registriesContext.fetchRoomAndBranchMedicalServices(bookingContext.bookingState.selectedRoom, bookingContext.bookingState.selectedBranch).then((res) => {
                        setLoaded(true);
                    })
                }
                break
            default:
                break
        }
    }, [loaded, registriesContext.enabledConventions, bookingContext.bookingMode, bookingContext.bookingState]);

    useEffect(() => {

        const subscription = watch(
            (value, {name, type}) => {
                if (type === 'change') {

                    let changeValues = getValues();

                    if ((name === 'mConvention') || (name === 'mPrestazione')) {

                        const oldConvention = changeValues.mOldConvetion ?? 0;

                        let newConvention = 0;
                        if (changeValues.mConvention) {
                            newConvention = changeValues.mConvention.mConventionID ?? 0;
                            setValue('mOldConvetion', newConvention, {});
                        }

                        let oldMedicalService = changeValues.mOldMedicalService ?? 0;

                        let newMedicalService = 0;
                        if (changeValues.mPrestazione) {
                            newMedicalService = changeValues.mPrestazione.mPrestazioneID ?? 0;
                            setValue('mOldMedicalService', newMedicalService, {});
                        }

                        if (newMedicalService === 0 || newConvention === 0) {

                            setDataLoading(false);

                            setValue('mDurataMinuti', 0, {});
                            setValue('mPrezzoListino', 0, {});
                            setValue('mCodicePadre', null, {});
                            setValue('mCodiceFiglio', null, {});
                            setValue('mMedicalService', null, {});
                            setValue('mIsASL', false, {});
                            setValue('mClientPrice', 0, {shouldValidate: true}); //{shouldValidate: true}

                            return;

                        }

                        if ((newConvention !== oldConvention) || (newMedicalService !== oldMedicalService)) {

                            setDataLoading(true);

                            registriesContext.fetchMedicalServiceDetails(newMedicalService).then((record) => {

                                setValue('mCodicePadre', record.mCodicePadre, {});
                                setValue('mCodiceFiglio', record.mCodiceFiglio, {});
                                setValue('mDurataMinuti', record.mDurataMinuti, {});
                                setValue('mMedicalService', record, {});

                                registriesContext.fetchMedicalServicePrice(newMedicalService, newConvention).then((price) => {

                                    setValue('mPrezzoListino', price, {}); //{shouldValidate: true}

                                    if (bookingContext.bookingMode !== 3)
                                        setValue('mClientPrice', price, {});

                                    setDataLoading(false);

                                    registriesContext.fetchConvention(newConvention).then((res) => {
                                        setValue('mIsASL', res.mIsASL, {shouldValidate: true});
                                    })

                                });

                            });
                        }

                    } else if (name === 'mClientPrice') {
                        setValue('mClientPrice', value.mClientPrice, {shouldValidate: true}); //{shouldValidate: true}
                        // bookingContext.setBookingItem(changeValues);
                    } else if (name === 'mCodicePadre') {
                        setValue('mCodicePadre', value.mCodicePadre, {shouldValidate: true}); //{shouldValidate: true}
                        // bookingContext.setBookingItem(changeValues);
                    } else if (name === 'mCodiceFiglio') {
                        setValue('mCodiceFiglio', value.mCodiceFiglio, {shouldValidate: true}); //{shouldValidate: true}
                        // bookingContext.setBookingItem(changeValues);
                    }

                }
            }
        );
        return () => subscription.unsubscribe();
    }, [watch]);

    //dettaglio

    let currentValues = getValues();

    const priceIsValid = (field) => {
        return moneyStringToFloat(field ?? "0") !== 0;
    }

    const showZeroPrice = () => {
        if (editDisabled()) return false;
        return !priceIsValid(currentValues.mPrezzoListino);
    }

    const showDifferentPrices = () => {
        if (editDisabled()) return false;
        if (priceIsValid(currentValues.mPrezzoListino) && moneyStringToFloat(currentValues.mPrezzoListino) !== moneyStringToFloat(currentValues.mClientPrice)) return true;
        return false;
    }

    const getPriceAdornment = () => {
        if (!showZeroPrice() && !showDifferentPrices()) return {};
        if (dataLoading) return {}
        if (!editDisabled() && showZeroPrice()) return {
            endAdornment:
                <InputAdornment position="end">
                    <Tooltip
                        color={"warning"}
                        title={"Attenzione: il prezzo di listino è 0. Fare click su Aggiorna per impostare \n il nuovo prezzo listino dal prezzo cliente."}>
                        <WarningTwoTone/>
                    </Tooltip>
                </InputAdornment>
        }
        if (!editDisabled() && showDifferentPrices()) return {
            endAdornment:
                <InputAdornment position="end">
                    <Tooltip
                        color={"warning"}
                        title={"Attenzione: il prezzo cliente è diverso da quello di listino. Fare click \n su Aggiorna per impostare il nuovo prezzo listino dal prezzo cliente."}>
                        <WarningTwoTone/>
                    </Tooltip>
                </InputAdornment>
        }
        return {}
    }

    const editDisabled = () => {
        if (!currentValues) return false;
        return (
            (!currentValues.mConvention) ||
            (!currentValues.mPrestazione) ||
            ((currentValues.mConvention.mConventionID ?? 0) === 0) ||
            ((currentValues.mPrestazione.mPrestazioneID ?? 0) === 0)
        )
    }

    const showParentCodeAlert = () => {
        if (editDisabled()) return false;
        if (!currentValues.mCodicePadre) return true;
        return false;
    }

    const getParentCodeAdornment = () => {
        if (!showParentCodeAlert()) return {};
        if (dataLoading) return {}
        return {
            endAdornment:
                <InputAdornment position="end">
                    <Tooltip
                        color={"warning"}
                        title={"Attenzione: codice figlio non valorizzato. Fare click su Aggiorna per impostare \n il codice."}>
                        <WarningTwoTone/>
                    </Tooltip>
                </InputAdornment>
        }
    }

    const showChildCodeAlert = () => {
        if (editDisabled()) return false;
        if (!currentValues.mCodiceFiglio) return true;
        return false;
    }

    const getChildCodeAdornment = () => {
        if (!showChildCodeAlert()) return {};
        if (dataLoading) return {}
        return {
            endAdornment:
                <InputAdornment position="end">
                    <Tooltip
                        color={"warning"}
                        title={"Attenzione: codice figlio non valorizzato. Fare click su Aggiorna per impostare \n il codice."}>
                        <WarningTwoTone/>
                    </Tooltip>
                </InputAdornment>
        }
    }

    const getCodesRequired = () => {
        if (dataLoading) return false;
        if (!currentValues) return false;
        if (currentValues.mIsASL === null) return false;
        return (currentValues.mIsASL === true);
    }

    const submitDisabled = () => {
        if (!currentValues) return false;
        return (
            (!currentValues.mConvention) ||
            (!currentValues.mPrestazione) ||
            ((currentValues.mConvention.mConventionID ?? 0) === 0) ||
            ((currentValues.mPrestazione.mPrestazioneID ?? 0) === 0) ||
            currentValues.mRoom.mRoomID === 0 ||
            (!currentValues.mDataAppuntamento) ||
            !priceIsValid(currentValues.mPrezzoListino) ||
            (getCodesRequired() && showChildCodeAlert()) ||
            bookingContext.isVisitSaving
        )

    }

    const customerPriceZeroCancel = (data) => {
        //niente
    }

    const saveVisitLineAndGoToVisit = () => {
        currentValues = getValues();
        bookingContext.setBookingItem(currentValues);
        bookingContext.saveVisitLine(currentValues).then(res => {
            let booking = {visit: {mVisitaID: res}}
            bookingContext.openBooking(booking, 4);
            navigate('/Booking/VisitDetail');
        })
    }

    const onSubmit = (data) => {

        if (submitDisabled()) return;

        bookingContext.setBookingItem(currentValues);
        const convention = registriesContext.conventions.find(c => c.mConventionID === currentValues.mConvention.mConventionID);
        const conventionName = (convention) ? convention.mConventionDescription : "";

        let price = parseMoney(currentValues.mClientPrice);

        if (price === 0) {
            alertContext.showConfirmDialog(
                "Conferma prezzo",
                "Il prezzo cliente della prestazione è impostato a € 0,00 sei sicuro?",
                customerPriceZeroCancel,
                saveVisitLineAndGoToVisit
            );
        } else if ((bookingContext.getCartVisitLinesCount() === 0) && (bookingContext.bookingMode === 1)) {
            alertContext.showConfirmDialog(
                "Conferma convenzione " + conventionName,
                "Stai salvando la visita con la convenzione " + conventionName + ". Sei sicuro? ",
                customerPriceZeroCancel,
                saveVisitLineAndGoToVisit
            );
        } else if ((bookingContext.getCartVisitLinesCount() === 0) && (bookingContext.bookingMode === 20)) {
            alertContext.showConfirmDialog(
                "Conferma convenzione",
                "Stai salvando la visita con la convenzione " + conventionName + ". Sei sicuro? ",
                customerPriceZeroCancel,
                saveVisitLineAndGoToVisit
            );
        } else if ((bookingContext.getVisitLinesCount() === 0) && (bookingContext.bookingMode === 21)) {
            alertContext.showConfirmDialog(
                "Conferma convenzione",
                "Stai salvando la visita con la convenzione " + conventionName + ". Sei sicuro? ",
                customerPriceZeroCancel,
                saveVisitLineAndGoToVisit
            );
        } else if ((bookingContext.getCartVisitLinesCount() === 0) && (bookingContext.bookingMode === 6)) {
            alertContext.showConfirmDialog(
                "Conferma convenzione " + conventionName,
                "Stai salvando la visita con la convenzione " + conventionName + ". Sei sicuro? ",
                customerPriceZeroCancel,
                saveVisitLineAndGoToVisit
            );
        } else {
            saveVisitLineAndGoToVisit();
        }
    }

    const onError = (errors, e) => {
        console.log(errors, e);
    }

    const handleClose = () => {
        const prevMode = bookingContext.getPrevMode();
        const url = bookingContext.getModeURL(prevMode);
        bookingContext.backToPrevState()
        navigate(url);
    }

    const updatePrice = () => {
        currentValues = getValues();
        const newPriceRow = {
            mConvenzione: currentValues.mConvention,
            mPrestazione: currentValues.mPrestazione,
            mPrice: currentValues.mClientPrice
        }
        const newPrice = moneyStringToFloat(currentValues.mClientPrice);
        registriesContext.updateMedicalServicePrice(newPriceRow).then((res) => {
            if (!res) return;
            let bookingItem = {...currentValues};
            bookingItem.mPrezzoListino = newPrice;
            bookingItem.mClientPrice = newPrice;
            bookingContext.setBookingItem(bookingItem);
            reset(bookingItem);
        })

    }

    const updateCodes = () => {
        currentValues = getValues();
        registriesContext.updateMedicalServiceCodes(currentValues.mMedicalService, currentValues.mCodicePadre, currentValues.mCodiceFiglio).then((res) => {
            let bookingItem = {...currentValues}
            // bookingItem.mPrezzoListino = newPrice;
            // bookingItem.mClientPrice = newPrice;
            bookingContext.setBookingItem(bookingItem);
            reset(bookingItem);
        })
    }

    const openAvailabilities = () => {
        if (!currentValues.mPrestazione || !currentValues.mConvention) return;
        bookingContext.setBookingItem(currentValues);

        const dateTime = currentValues.mDataAppuntamento ? currentValues.mDataAppuntamento : moment();
        const medicalServiceID = currentValues.mPrestazione.mPrestazioneID;

        let newAvailsState = {
            ...JSONClone(initial_avails_state),
            fromDate: dateTime,
            medicalServiceID: medicalServiceID,
            offset: 7,
            open: true,
        };

        setAvailsState(newAvailsState)
    }

    const closeAvailabilities = () => {
        let newAvailsState = {
            ...JSONClone(initial_avails_state)
        };
        setAvailsState(newAvailsState)
    }

    const goPrevAvailabilities = () => {
        let newFromDate = availsState.fromDate.clone().add(-1 * availsState.offset, 'day');
        if (newFromDate.startOf('day') < moment().startOf('day')) newFromDate = moment().startOf('day');
        let newAvailsState = {
            ...JSONClone(availsState),
            fromDate: newFromDate
        };
        setAvailsState(newAvailsState)
    }

    const goNextAvailabilities = () => {
        let newFromDate = availsState.fromDate.clone().add(1 * availsState.offset, 'day');
        let newAvailsState = {
            ...JSONClone(availsState),
            fromDate: newFromDate
        };
        setAvailsState(newAvailsState)
    }

    const getMedicalServices = () => {
        if (bookingContext.bookingMode === 1 || bookingContext.bookingMode === 6) return registriesContext.roomMedicalServices;
        if (bookingContext.bookingMode === 20 || bookingContext.bookingMode === 21 || bookingContext.bookingMode === 3) return registriesContext.activeMedicalServices;
    }

    const bookingFromAvailability = (slot, room) => {
        let bookingItem = bookingContext.updateBookingFromAvailability(slot, room);
        reset(bookingItem);
        closeAvailabilities();
    }

    let bAvailabilityVisible = (bookingContext.bookingMode === 20 || bookingContext.bookingMode === 21 || bookingContext.bookingMode === 3);

    if (bookingContext.bookingMode === -1 || bookingContext.bookingMode === 0) {
        bookingContext.goToRoomsPlanner();
        navigate(DEFAULT_AGENDA_URL);
    }

    if (bookingContext.bookingMode === 1 || bookingContext.bookingMode === 6) {
        if (bookingContext.bookingState.selectedRoom === 0) {
            bookingContext.goToRoomsPlanner();
            navigate(DEFAULT_AGENDA_URL);
        }
    }

    if (!registriesContext.enabledConventions) return <></>

    if ((bookingContext.bookingMode === 1 || bookingContext.bookingMode === 6) && (JSONIsEmpty(currentValues))) return <CircularWaiting/>
    if ((bookingContext.bookingMode === 1 || bookingContext.bookingMode === 6) && (isDataNull(registriesContext.roomMedicalServices) || isDataLoading(registriesContext.roomMedicalServices))) return <CircularWaiting/>
    if (bAvailabilityVisible && (isDataNull(registriesContext.activeMedicalServices) || isDataLoading(registriesContext.activeMedicalServices))) return <CircularWaiting/>

    const codesRequired = getCodesRequired();

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid
                    container
                    direction={"row"}
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    sx={loggedClasses.bookingDetailModal}
                    spacing={theme.spacing(2)}
                >
                    <Grid item xs={12}>
                        <Typography variant="h3">Prenota</Typography>
                    </Grid>
                    <Grid item xl={6} md={12}>
                        <FormAutoComplete
                            label="Convenzione"
                            control={control}
                            record={bookingContext.bookingItem}
                            register={register}
                            values={registriesContext.enabledConventions}
                            field="mConvention"
                            joinField="mConventionID"
                            idField="mConventionID"
                            labelField="mConventionDescription"
                            selectedValue="id"
                            required={true}
                            focused={true}
                            disabled={!bookingContext.visitIsEmpty() || dataLoading}
                            autoSelectFirst
                        />
                    </Grid>
                    <Grid item xl={6} md={12}>
                        <FormAutoComplete
                            label="Prestazione"
                            control={control}
                            record={bookingContext.bookingItem}
                            reset={reset}
                            register={register}
                            values={getMedicalServices()}
                            matchFrom={"any"}
                            field="mPrestazione"
                            joinField="mPrestazioneID"
                            idField="mPrestazioneID"
                            labelField="mDescrizione"
                            selectedValue="id"
                            required={true}
                            disabled={dataLoading}
                        />
                    </Grid>
                    <Grid item xl={3} md={12}>
                        <FormDiscreteSlider
                            label="Durata"
                            control={control}
                            record={bookingContext.bookingItem}
                            register={register}
                            field="mDurataMinuti"
                            required={true}
                            min={0 * commonContext.getPlanningParameter("SLOT_SIZE")}
                            max={5 * commonContext.getPlanningParameter("SLOT_SIZE")}
                            step={commonContext.getPlanningParameter("SLOT_SIZE")}
                            disabled={editDisabled() || dataLoading}
                        />
                    </Grid>
                    <Grid item xl={3} md={12}>
                        <FormTextBox
                            label="Prezzo Listino"
                            control={control}
                            record={bookingContext.bookingItem}
                            getValues={getValues}
                            setRecord={bookingContext.setBookingItem}
                            reset={reset}
                            register={register}
                            field="mPrezzoListino"
                            required={true}
                            maxLength={50}
                            pattern={"money"}
                            disabled
                        />
                    </Grid>
                    <Grid item xl={3} md={12}>
                        <FormTextBox
                            label="Prezzo Cliente"
                            control={control}
                            record={bookingContext.bookingItem}
                            getValues={getValues}
                            setRecord={bookingContext.setBookingItem}
                            reset={reset}
                            register={register}
                            field="mClientPrice"
                            required={true}
                            maxLength={50}
                            pattern={"money"}
                            disabled={editDisabled() || dataLoading}
                            icon={"warning"}
                            iconColor={"warning"}
                            InputProps={getPriceAdornment()}
                            autoFocus
                        />
                    </Grid>
                    {
                        (!editDisabled() && (showZeroPrice() || showDifferentPrices()) && (!dataLoading)) ?
                            <>
                                <Grid item xl={2} md={12}>
                                    <ActionButton
                                        color="warning"
                                        variant="contained"
                                        action={updatePrice}
                                        size="small"
                                        icon="refresh"
                                        iconSize="medium"
                                        tooltip="Aggiorna"
                                        label="Aggiorna"
                                    />
                                </Grid>

                            </>
                            : <></>
                    }
                    {
                        codesRequired &&
                        <>
                            <Grid item xl={4} md={12}>
                                <FormTextBox
                                    label="Codice Padre"
                                    control={control}
                                    record={bookingContext.bookingItem}
                                    getValues={getValues}
                                    setRecord={bookingContext.setBookingItem}
                                    reset={reset}
                                    register={register}
                                    field="mCodicePadre"
                                    required={getCodesRequired()}
                                    maxLength={10}
                                    pattern={"text"}
                                    disabled={editDisabled() || dataLoading}
                                    InputProps={getParentCodeAdornment()}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xl={4} md={12}>
                                <FormTextBox
                                    label="Codice Figlio"
                                    control={control}
                                    record={bookingContext.bookingItem}
                                    getValues={getValues}
                                    setRecord={bookingContext.setBookingItem}
                                    reset={reset}
                                    register={register}
                                    field="mCodiceFiglio"
                                    required={getCodesRequired()}
                                    maxLength={10}
                                    pattern={"text"}
                                    disabled={editDisabled() || dataLoading}
                                    InputProps={getChildCodeAdornment()}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xl={2} md={12}>
                                <ActionButton
                                    color="warning"
                                    variant="contained"
                                    action={updateCodes}
                                    size="small"
                                    icon="refresh"
                                    iconSize="medium"
                                    tooltip="Aggiorna"
                                    label="Aggiorna"
                                    disabled={editDisabled() || dataLoading}
                                />
                            </Grid>
                        </>
                    }

                    <Grid item xs={12} md={(bAvailabilityVisible) ? 4 : 6}>
                        <FormDatePicker
                            label="Data appuntamento"
                            control={control}
                            record={bookingContext.bookingItem}
                            register={register}
                            field="mDataAppuntamento"
                            required={false}
                            disabled={true}
                            format={commonContext.getPlanningParameter("DATETIME_FORMAT_SHORT")}
                        />
                    </Grid>
                    {
                        (bAvailabilityVisible) ?
                            <Grid item xs={12} md={2}>
                                <Stack direction={"row"}>
                                    <ActionButton
                                        color="secondary"
                                        variant="contained"
                                        action={openAvailabilities}
                                        size="small"
                                        icon="calendar"
                                        iconSize="medium"
                                        tooltip="Disponibilità"
                                        label="Disponibilità"
                                        disabled={editDisabled()}
                                    />
                                </Stack>
                            </Grid>
                            : <> </>
                    }
                    <Grid item xs={12} md={6}>
                        <FormAutoComplete
                            label="Stanza"
                            control={control}
                            record={bookingContext.bookingItem}
                            register={register}
                            field="mRoom"
                            joinField="mRoomID"
                            values={registriesContext.rooms}
                            idField="mRoomID"
                            labelField="mRoomName"
                            selectedValue="id"
                            required={true}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormActionBar
                            submitLabel={(bookingContext.bookingMode === 1 || bookingContext.bookingMode === 6 || bookingContext.bookingMode === 20) ? "Agg. a carrello" : "Salva"}
                            submitIcon={(bookingContext.bookingMode === 1 || bookingContext.bookingMode === 6 || bookingContext.bookingMode === 20) ? "addToCart" : "save"}
                            submitDisabled={submitDisabled()}
                            cancelAction={handleClose}
                        />
                    </Grid>
                </Grid>
            </form>
            <CardModal
                modalOpen={availsState.open}
                onClose={closeAvailabilities}
                title={"Disponibilità"}
            >
                <CardContent>
                    <Availabilities
                        availsState={availsState}
                        goPrevAvailabilities={goPrevAvailabilities}
                        goNextAvailabilities={goNextAvailabilities}
                        bookingFromAvailability={bookingFromAvailability}
                        close={closeAvailabilities}
                    />
                </CardContent>
            </CardModal>
        </>
    )

}

export default TakeBooking;
