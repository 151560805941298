import {FormControl} from "@mui/material";
import React from 'react';
import {ActionButton} from "../Buttons/ActionButton";

export const FormCancelButton = ({label, icon, disabled, action, sx,  ...props}) => {

    let isDisabled = disabled ?? false;
    let sIcon = icon ?? "cancel"

    return (
        <FormControl>
            <ActionButton
                color="primary"
                variant="contained"
                action={action}
                size="medium"
                icon={sIcon}
                iconSize="small"
                tooltip={label}
                label={label}
                disabled={isDisabled}
                sx={sx}
                {...props}
            />
        </FormControl>
    )

}