import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CardContent,
    Grid,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import {gridExtraSizeStyle, gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {AgGridReact} from "ag-grid-react";
import {
    booleanRenderer,
    columnTypes,
    dateTimeColumnRenderer,
    dateTimeShortColumnRenderer,
    defaultColDef,
    isDataNull, timeColumnRenderer, timeUtcColumnRenderer, weedayRenderer, weekdayRenderer
} from "../../../../common";
import {CardModal} from "../../../../components/Layout/CardModal";
import React, {useContext, useEffect, useState} from "react";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import ReactJson from "react-json-view";
import {FormSwitch} from "../../../../components/Form/FormSwitch";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {FormSubmitButton} from "../../../../components/Form/FormSubmitButton";
import {RecupEuDiary} from "./RecupEuDiary";
import {RecupDiaryMedicalServices} from "./RecupDiaryMedicalServices";
import {RecupContext} from "../../../../context/RecupContext";
import {useForm} from "react-hook-form";
import {RecupEuDiaryTimeBands} from "./RecupEuDiaryTimeBands";

export const RecupEuDiaries = ({
                                   open,
                                   onClose,
                                   authorityId,
                                   hospitalId,
                                   siteId,
                                   executionUnitId,
                               }) => {

    const recupContext = useContext(RecupContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [diaries, setDiaries] = useState(null);
    const [updating, setUpdating] = useState(false);
    const [diarySchemas, setDiarySchemas] = useState([]);
    const [schemaTimeBands, setSchemaTimeBands] = useState([]);
    const [expanded, setExpanded] = useState("pnlDiaries");
    const [diaryID, setDiaryID] = useState("");
    const [diaryModalOpen, setDiaryModalOpen] = useState(false);
    const [diaryMedicalServices, setDiaryMedicalServices] = useState([]);
    const [diaryMedicalServicesModalOpen, setDiaryMedicalServicesModalOpen] = useState(false);
    const [schemaTimebandsOpen, setSchemaTimebandsOpen] = useState(false);
    const [schemaID, setSchemaID] = useState("");

    useEffect(() => {
        if (!isDataNull(diaries)) return;
        recupContext.fetchDiaries(executionUnitId).then((res) => {
            setDiaries(res);
        });
    }, [diaries])

    const onCloseDiaries = (event, reason) => {
        setDiarySchemas([]);
        onClose();
    }

    const ToolBarDiaries = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            setDiaryID(selectedRow.id);
            setDiaryModalOpen(true);
        };

        const deleteAction = () => {
            alert(selectedRow.id);
        }

        const createSlotsAction = () => {
            setDiaryID(selectedRow.id);
            setSchemaID(0);
            setSchemaTimebandsOpen(true);
        }

        const getSchemasAction = () => {
            setDiaryID(selectedRow.id);
            recupContext.fetchDiarySchemas(selectedRow.id).then(res => {
                setDiarySchemas(res);
                setExpanded("pnlSchemas");
            });
        }

        const toggleDiary = () => {
            recupContext.toggleDiary(selectedRow).then(res => {
                setDiaries(null);
            });
        }

        const medicalServicesAction = () => {
            setDiaryMedicalServices(selectedRow.orders);
            setDiaryMedicalServicesModalOpen(true);
        }

        const customActions = [
            {
                action: createSlotsAction,
                disabled: false,
                icon: "clock",
                tooltip: "Create time bands",
            },
            {
                action: getSchemasAction,
                disabled: false,
                icon: "clock",
                tooltip: "Get schemas",
            },
            {
                action: toggleDiary ,
                disabled: false,
                icon: "power",
                tooltip: "Attiva/Disattiva",
            },
            {
                action: medicalServicesAction,
                disabled: false,
                icon: "medical-service",
                tooltip: "Prestazioni",
            }
        ];

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
                customActions={customActions}
            />
        )
    }

    const [columnsDiaries, setColumnsDiaries] = useState([
        {headerName: "ID", field: "id"},
        {headerName: "Code", field: "code"},
        {headerName: "External Code", field: "externalCode"},
        {headerName: "Name", field: "name"},
        {headerName: "Status", field: "status.name"},
        {headerName: "Azioni", cellRenderer: ToolBarDiaries, type: "toolBar"}
    ]);

    const addActionDiaries = () => {
        setDiaryID("0");
        setDiaryModalOpen(true);
    }

    const ExpandMoreIcon = () => {
        return null;
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    /* DIARY DETAILS */

    const onCloseDiary = (event, reason) => {
        setDiaryModalOpen(false);
        setDiaries(null);
    }

    /* DIARY MEDICAL SERVICES */

    const onCloseDiaryMedicalServices = (event, reason) => {
        setDiaryMedicalServices([]);
        setDiaryMedicalServicesModalOpen(false);
    }

    /* DIARY SCHEMA */

    const ToolBarSchemas = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            console.log('editAction ', selectedRow);
            setDiaryID(selectedRow.diaryId);
            setSchemaID(selectedRow.id);
            setSchemaTimebandsOpen(true);
        };

        const deleteAction = () => {
            const body = {
                mAgendaID: selectedRow.diaryId,
                mSchemaID: selectedRow.id,
            }
            setUpdating(true);
            recupContext.deleteSchema(body).then((res) => {
                recupContext.fetchDiarySchemas(selectedRow.diaryId).then(res => {
                    setUpdating(false);
                    setDiarySchemas(res);
                    setExpanded("pnlSchemas");
                });
            });
        }

        const getTimeBandAction = () => {
            const body = {
                mAgendaID: selectedRow.diaryId,
                mSchemaID: selectedRow.id,
            }
            recupContext.fetchSchemaTimeBands(body).then(res => {
                setSchemaTimeBands(res);
                setExpanded("pnlTimeBands");
            });
        }

        const customActions = [
            {
                action: getTimeBandAction,
                disabled: false,
                icon: "clock",
                tooltip: "Get time bands",
            }
        ];

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
                customActions={customActions}
            />
        )
    }

    const [columnsSchemas, setColumnsSchemas] = useState([
        {headerName: "ID", field: "id"},
        {headerName: "Dal", field: "startDate", cellRenderer: dateTimeShortColumnRenderer},
        {headerName: "Al", field: "endDate", cellRenderer: dateTimeShortColumnRenderer},
        {headerName: "Dalle", field: "slotStartTime", cellRenderer: timeUtcColumnRenderer},
        {headerName: "Slot", field: "schemaSlotSize"},
        {headerName: "Generato", field: "calendarGenerated", cellRenderer: booleanRenderer},
        {headerName: "Azioni", cellRenderer: ToolBarSchemas, type: "toolBar"}
    ]);

    const [columnsTimeBands, setColumnsTimeBands] = useState([
        {headerName: "ID", field: "id"},
        {headerName: "Giorno", field: "weekday", cellRenderer: weekdayRenderer},
        {headerName: "Dalle", field: "startTime", cellRenderer: timeUtcColumnRenderer},
        {headerName: "Alle", field: "endTime", cellRenderer: timeUtcColumnRenderer}
    ]);

    const onCloseTimeBands = (event, reason) => {
        setSchemaTimebandsOpen(false);
        recupContext.fetchDiaries(executionUnitId).then((res) => {
            setDiaries(res);
            recupContext.fetchDiarySchemas(diaryID).then(res => {
                setDiaryID("");
                setDiarySchemas(res);
                setExpanded("pnlSchemas");
            });
        });
    }

    const schemaTimeBandsRows = (schemaTimeBands.length>0) ? schemaTimeBands[0].timebands : [];
    const diariesJSON = diaries ?? [];
    const schemasJSON = diarySchemas ?? [];
    const schemaTimeBandsJSON = schemaTimeBands ?? [];

    return (
        <>
            <CardModal
                modalOpen={open}
                onClose={onCloseDiaries}
                title={"Agende Execution Unit " + executionUnitId}
                size={"large"}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <div>
                        <Accordion expanded={expanded === 'pnlDiaries'} onChange={handleChange('pnlDiaries')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="pnlDiaries-content"
                                id="pnlDiaries-header"
                                sx={loggedClasses.accordionHeader}
                            >
                                <Typography variant={"h6"} align={"center"}>
                                    Agende
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid
                                    container
                                    direction={"row"}
                                    spacing={theme.spacing(2)}
                                    justifyContent={"flex-start"}
                                    alignItems={"flex-start"}
                                >
                                    {
                                        (diaries && !updating) ?
                                            <>
                                                <Grid item xs={12} container sx={{pb: theme.spacing(2)}}>
                                                    <Grid item xs={12}>
                                                        <Stack
                                                            direction={"row"}
                                                            justifyContent={"flex-end"}
                                                            spacing={theme.spacing(2)}
                                                            sx={{pt: theme.spacing(1)}}>
                                                            <AddButton addAction={addActionDiaries}/>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div id="agReactGridDiaries" className="ag-theme-alpine"
                                                         style={gridExtraSizeStyle}>
                                                        <AgGridReact
                                                            rowData={diaries}
                                                            defaultColDef={defaultColDef}
                                                            columnDefs={columnsDiaries}
                                                            columnTypes={columnTypes}
                                                            suppressMovableColumns={true}
                                                            suppressCellFocus={true}
                                                            pagination={true}
                                                            rowSelection={'single'}
                                                            getRowHeight={(params) => gridRowHeight}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} container sx={{pt: theme.spacing(2)}}>
                                                    <Grid item xs={12}>
                                                        <h3>JSON DI RITORNO DIARIES</h3>
                                                        <ReactJson src={diariesJSON} theme="monokai"
                                                                   collapsed={true}/>
                                                    </Grid>
                                                </Grid>
                                            </>
                                            :
                                            <CircularWaiting/>

                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'pnlSchemas'} onChange={handleChange('pnlSchemas')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="pnlSchemas-content"
                                id="pnlSchemas-header"
                                sx={loggedClasses.accordionHeader}
                            >
                                <Typography variant={"h6"} align={"center"}>
                                    Schemas dell'agenda
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid
                                    container
                                    direction={"row"}
                                    spacing={theme.spacing(2)}
                                    justifyContent={"flex-start"}
                                    alignItems={"flex-start"}
                                >
                                    {
                                        (diarySchemas && !updating) ?
                                            <>
                                                <Grid item xs={12}>
                                                    <div id="agReactGridSchemas" className="ag-theme-alpine" style={gridExtraSizeStyle}>
                                                        <AgGridReact
                                                            rowData={diarySchemas}
                                                            defaultColDef={defaultColDef}
                                                            columnDefs={columnsSchemas}
                                                            columnTypes={columnTypes}
                                                            suppressMovableColumns={true}
                                                            suppressCellFocus={true}
                                                            pagination={true}
                                                            rowSelection={'single'}
                                                            getRowHeight={(params) => gridRowHeight}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} container sx={{pt: theme.spacing(2)}}>
                                                    <Grid item xs={12}>
                                                        <h3>JSON DI RITORNO SCHEMAS</h3>
                                                        <ReactJson src={schemasJSON} theme="monokai"
                                                                   collapsed={true}/>
                                                    </Grid>
                                                </Grid>
                                            </>
                                            :
                                            <CircularWaiting/>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'pnlTimeBands'} onChange={handleChange('pnlTimeBands')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="pnlTimeBands-content"
                                sx={loggedClasses.accordionHeader}
                            >
                                <Typography variant={"h6"} align={"center"}>
                                    Timebands dello schema
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid
                                    container
                                    direction={"row"}
                                    spacing={theme.spacing(2)}
                                    justifyContent={"flex-start"}
                                    alignItems={"flex-start"}
                                >
                                    {
                                        (schemaTimeBands && !updating) ?
                                            <>
                                                <Grid item xs={12}>
                                                    <div id="agReactGridTimebands" className="ag-theme-alpine" style={gridExtraSizeStyle}>
                                                        <AgGridReact
                                                            rowData={schemaTimeBandsRows}
                                                            defaultColDef={defaultColDef}
                                                            columnDefs={columnsTimeBands}
                                                            columnTypes={columnTypes}
                                                            suppressMovableColumns={true}
                                                            suppressCellFocus={true}
                                                            pagination={true}
                                                            rowSelection={'single'}
                                                            getRowHeight={(params) => gridRowHeight}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} container sx={{pt: theme.spacing(2)}}>
                                                    <Grid item xs={12}>
                                                        <h3>JSON DI RITORNO TIMEBANDS</h3>
                                                        <ReactJson src={schemaTimeBandsJSON} theme="monokai" collapsed={true}/>
                                                    </Grid>
                                                </Grid>
                                            </>
                                            :
                                            <CircularWaiting/>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </CardContent>
            </CardModal>

            {
                diaryID !== "" && diaryModalOpen &&
                <RecupEuDiary
                    open={diaryModalOpen}
                    onClose={onCloseDiary}
                    authorityId={authorityId}
                    hospitalId={hospitalId}
                    siteId={siteId}
                    executionUnitId={executionUnitId}
                    diaryId={diaryID}
                />
            }
            {
                diaryMedicalServices &&
                <RecupDiaryMedicalServices
                    open={diaryMedicalServicesModalOpen}
                    close={onCloseDiaryMedicalServices}
                    rows={diaryMedicalServices}
                />

            }
            {
                diaryID !== "" && schemaTimebandsOpen &&
                <RecupEuDiaryTimeBands
                    open={schemaTimebandsOpen}
                    onClose={onCloseTimeBands}
                    executionUnitId={executionUnitId}
                    diaryId={diaryID}
                    schemaId={schemaID}
                />
            }
        </>
    )
}
