import {ExpandMoreTwoTone} from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Badge, Chip,
    Grid,
    ListItem, ListItemButton, ListItemIcon,
    ListSubheader, Stack,
    Typography,
    useTheme
} from "@mui/material";
import List from "@mui/material/List";
import React, {useContext} from "react";
import {RegistriesContext} from "../../context/RegistriesContext";
import {StatusBadge} from "../Badges/StatusBadge";
import {CircularWaiting} from "../Waitings/CircularWaiting";
import {BookingContext} from "../../context/BookingContext";
import {loggedClassNames} from "../../theme/Styles";
import {VisitLineSlotPaper} from "./VisitLineSlotPaper";
import {useNavigate} from "react-router-dom";
import {CommonContext} from "../../context/CommonContext";
import {IconLibraryItem} from "../Icons/IconLibraryItem";
import {VisitLineSlot} from "./VisitLineSlot";
import {SquareButton} from "../Buttons/SquareButton";
import ListItemText from "@mui/material/ListItemText";

export const RoomsList = ({
                              selectedRoom,
                              setSelectedRoom
                          }) => {

    const bookingContext = useContext(BookingContext);
    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const activeRooms = registriesContext.getActiveRooms() ?? [];

    return (
        <List
            key={"rooms-list"}
            sx={loggedClasses.roomList}
        >
            {
                (activeRooms) ?
                    activeRooms.map((room) => {
                        let textStyle = (selectedRoom && selectedRoom.mRoomID===room.mRoomID) ?
                            {
                                textDecoration: 'underline',
                                fontWeight: '800!important'
                            }
                            :
                            {};
                        return (
                            <ListItem
                                key={'rooms-list-item-' + room.mRoomID}
                                sx={{
                                    ...loggedClasses.roomTag,
                                    boxShadow: 3,
                                    display: 'flex',
                                    direction: 'column',
                                    justifyContent: 'left',
                                    alignItems: 'flex-start'
                                }}
                                onClick={()=>{
                                    setSelectedRoom(room);
                                }}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    sx={loggedClasses.roomTagItem}
                                >
                                    <Grid item xs={12} md={9} sx={loggedClasses.roomTagItem}>
                                        <Chip
                                            color={"info"}
                                            label={room.mRoomName}
                                            sx={{
                                                ...textStyle,
                                                py: theme.spacing(0.5),
                                                px: theme.spacing(0.5),
                                                minWidth: '100px'
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={3}
                                        sx={loggedClasses.roomTagItem}
                                        display={"flex"}
                                        flexDirection={"row"}
                                        justifyContent="left"
                                        alignItems="flex-end"
                                    >
                                        <ListItemIcon sx={{color: theme.palette.primary.light }}>
                                            <IconLibraryItem iconSize={"small"} name={"room"}/>
                                        </ListItemIcon>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        )
                    })
                    :
                    <CircularWaiting/>
            }
        </List>
    )
}