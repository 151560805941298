import {Card, CardContent, useTheme} from "@mui/material";
import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {ContainerTitle} from "../../../components/Layout/ContainerTitle";
import {MedicalReportingContext} from "../../../context/MedicalReportingContext";
import {loggedClassNames} from "../../../theme/Styles";
import {MedicalReportEditor} from "./Components/MedicalReportEditor";
import {MedicalReportPreview} from "./Components/MedicalReportPreview";
import {MedicalReports} from "./Components/MedicalReports";
import {MedicalReportSign} from "./Components/MedicalReportSign";
import {MedicalReportsWorklist} from "./Components/MedicalReportsWorklist";
import {MedicalReportTemplates} from "./Components/MedicalReportTemplates";

const medicalReportingRoutes = [
    {path: '/MedicalReporting/Reports', component: <MedicalReports/>, exact: true},
    {path: '/MedicalReporting/Worklist', component: <MedicalReportsWorklist/>, exact: true},
    {path: '/MedicalReporting/MedicalReportEditor', component: <MedicalReportEditor/>, exact: true},
    {path: '/MedicalReporting/Sign', component: <MedicalReportSign/>, exact: true},
    {path: '/MedicalReporting/Templates', component: <MedicalReportTemplates/>, exact: true}
]

const LocationRenderer = () => {

    const location = useLocation();

    const medicalReportingContext = useContext(MedicalReportingContext);

    const exactElement = medicalReportingRoutes.find(l => l.exact === true && location.pathname === l.path);

    if (exactElement) return exactElement.component;

    const partialElement = medicalReportingRoutes.find(l => l.exact === false && location.pathname.startsWith(l.path));

    if (partialElement) return partialElement.component;

    medicalReportingContext.resetMedicalReports();
    medicalReportingContext.resetVisitLinesToReport();

    switch (medicalReportingContext.medicalReportState.mode) {
        case -1:
            return <></>;
        case 0:
            return <MedicalReports/>
        case 1:
            return <MedicalReportsWorklist/>
        case 2:
            return <MedicalReportEditor/>
        case 3:
            return <MedicalReports/>
        case 4:
            return <MedicalReportEditor/>
        case 5:
            return <MedicalReportSign/>
        case 6:
            return <MedicalReportTemplates/>
        default:
            return <></>
    }

}

const MedicalReportingContainer = (props) => {

    const medicalReportingContext = useContext(MedicalReportingContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    let navigate = useNavigate();

    //0 - elenco referti
    //1 - worklist di refertazione
    //2 - referta linee visita
    //3 - PDF referto
    //4 - modifica referto
    //5 - firma referto

    const [medicalReportToOpenID, setMedicalReportToOpenID] = useState(-1);
    const mode = medicalReportingContext.medicalReportState.mode;

    useEffect(() => {
        if (mode === 3) {
            setMedicalReportToOpenID(medicalReportingContext.medicalReportState.medicalReport.mRefertoID);
        }
    }, [medicalReportingContext.medicalReportState]);

    const onPDFClose = () => {
        medicalReportingContext.goToReports();
        setMedicalReportToOpenID(-1);
        navigate('/MedicalReporting/Reports');
    }

    return (
        <Card sx={loggedClasses.loggedContent}>
            <CardContent sx={loggedClasses.loggedCardContent}>
                <ContainerTitle
                    title={"Refertazione"}
                    onHomeClick={() => { medicalReportingContext.goToReports(); navigate('/MedicalReporting/Reports'); }}
                    key={"container-title-medical-reporting"}
                />
                <LocationRenderer/>
                {
                    (mode === 3) ? <MedicalReportPreview documentID={medicalReportToOpenID} onCloseAction={onPDFClose}/> : <></>
                }
            </CardContent>
        </Card>
    )
}

export default MedicalReportingContainer;
