import {
    AccountBalanceTwoTone,
    AddShoppingCartTwoTone,
    AddTwoTone,
    AlbumTwoTone,
    ApartmentTwoTone,
    ArrowCircleDownTwoTone,
    ArrowCircleLeftTwoTone,
    ArrowCircleRightTwoTone,
    ArrowCircleUpTwoTone,
    ArrowDropDownTwoTone,
    ArrowDropUpTwoTone,
    AssignmentTwoTone,
    AttachFileTwoTone,
    BarChartTwoTone,
    CalendarMonthTwoTone, CalendarViewDayTwoTone, CalendarViewWeekTwoTone,
    CancelTwoTone,
    CheckTwoTone,
    CloseTwoTone,
    ContentCopyTwoTone,
    CreditCardTwoTone,
    DeleteTwoTone,
    DesktopWindowsTwoTone,
    DevicesTwoTone,
    DocumentScannerTwoTone,
    DownloadTwoTone,
    EditTwoTone,
    EuroSymbolTwoTone,
    Face3TwoTone,
    Face6TwoTone,
    FaxTwoTone,
    FileOpenTwoTone,
    FindInPageTwoTone,
    FingerprintTwoTone,
    HistoryEduTwoTone,
    HomeTwoTone,
    HourglassBottomTwoTone,
    InfoTwoTone,
    LanguageTwoTone,
    LensTwoTone,
    LinkTwoTone,
    ListTwoTone,
    LockClockTwoTone,
    LoginTwoTone, MeetingRoomTwoTone,
    NotListedLocationTwoTone,
    NumbersTwoTone, PendingTwoTone,
    PersonTwoTone,
    PrintTwoTone,
    PublicTwoTone,
    RefreshTwoTone,
    ResetTvTwoTone,
    Room,
    RssFeedTwoTone,
    SaveTwoTone,
    ScheduleTwoTone,
    ScreenshotMonitorTwoTone,
    SearchTwoTone,
    SettingsTwoTone,
    ShareTwoTone,
    SpeedTwoTone,
    SquareTwoTone,
    TableViewTwoTone,
    TextsmsTwoTone,
    TextSnippetTwoTone,
    UploadTwoTone,
    VisibilityOffTwoTone,
    VisibilityTwoTone,
    VpnKeyTwoTone,
    WarehouseTwoTone,
    WidgetsTwoTone
} from "@mui/icons-material";
import {createElement} from 'react';
import CustomIcon from "./CustomIcon";

export const iconsLibrary = [
    {name: "account", custom: true, component: CustomIcon, customName: "mdi:account-circle-outline"},
    {name: "add", custom: false, component: AddTwoTone},
    {name: "addToCart", custom: false, component: AddShoppingCartTwoTone},
    {name: "arrowDown", custom: false, component: ArrowDropDownTwoTone},
    {name: "arrowUp", custom: false, component: ArrowDropUpTwoTone},
    {name: "attach", custom: false, component: AttachFileTwoTone},
    {name: "bank", custom: false, component: AccountBalanceTwoTone},
    {name: "bill", custom: true, component: CustomIcon, customName: "uil:bill"},
    {name: "branch", custom: true, component: CustomIcon, customName: "mdi:source-branch"},
    {name: "calendar", custom: false, component: CalendarMonthTwoTone},
    {name: "calendar-week", custom: false, component: CalendarViewWeekTwoTone},
    {name: "calendar-day", custom: false, component: CalendarViewDayTwoTone},
    {name: "cancel", custom: false, component: CancelTwoTone},
    {name: "cart", custom: true, component: CustomIcon, customName: "material-symbols:shopping-cart-outline"},
    {name: "cd", custom: false, component: AlbumTwoTone},
    {name: "chart", custom: false, component: BarChartTwoTone},
    {name: "chart-up", custom: true, component: CustomIcon, customName: "ci:line-chart-up"},
    {name: "chart-down", custom: true, component: CustomIcon, customName: "ci:line-chart-down"},
    {name: "chat", custom: false, component: TextsmsTwoTone},
    {name: "check", custom: false, component: CheckTwoTone},
    {name: "check-list", custom: true, component: CustomIcon, customName: "material-symbols:checklist-rounded"},
    {name: "clipboard", custom: false, component: AssignmentTwoTone},
    {name: "clock", custom: true, component: CustomIcon, customName: "mdi:clipboard-clock-outline"},
    {name: "close", custom: false, component: CloseTwoTone},
    {name: "company", custom: false, component: ApartmentTwoTone},
    {name: "copy", custom: false, component: ContentCopyTwoTone},
    {name: "credit-card", custom: false, component: CreditCardTwoTone},
    {name: "csv", custom: true, component: CustomIcon, customName: "ph:file-csv"},
    {name: "dashboard", custom: false, component: SpeedTwoTone},
    {name: "database", custom: true, component: CustomIcon, customName: "mdi:database-search"},
    {name: "delete", custom: false, component: DeleteTwoTone},
    {name: "detail", custom: false, component: LensTwoTone},
    {name: "desktop", custom: false, component: DesktopWindowsTwoTone},
    {name: "deal", custom: true, component: CustomIcon, customName: "mdi:deal-outline"},
    {name: "device", custom: false, component: DevicesTwoTone},
    {name: "doctor", custom: true, component: CustomIcon, customName: "healthicons:doctor-male-outline"},
    {name: "document", custom: false, component: TextSnippetTwoTone},
    {name: "download", custom: false, component: DownloadTwoTone},
    {name: "draft", custom: true, component: CustomIcon, customName: "lucide:book-template"},
    {name: "edit", custom: false, component: EditTwoTone},
    {name: "expand-less", custom: true, component: CustomIcon, customName: "material-symbols:expand-less"},
    {name: "expand-more", custom: true, component: CustomIcon, customName: "material-symbols:expand-more"},
    {name: "euro", custom: false, component: EuroSymbolTwoTone},
    {name: "fax", custom: false, component: FaxTwoTone},
    {name: "find", custom: false, component: FindInPageTwoTone},
    {name: "fingerprint", custom: false, component: FingerprintTwoTone},
    {name: "hash-tag", custom: false, component: NumbersTwoTone},
    {name: "hide", custom: false, component: VisibilityOffTwoTone},
    {name: "home", custom: false, component: HomeTwoTone},
    {name: "hospital", custom: true, component: CustomIcon, customName: "healthicons:hospital"},
    {name: "info", custom: false, component: InfoTwoTone},
    {name: "key", custom: false, component: VpnKeyTwoTone},
    {name: "language", custom: false, component: LanguageTwoTone},
    {name: "left", custom: false, component: ArrowCircleLeftTwoTone},
    {name: "link", custom: false, component: LinkTwoTone},
    {name: "list", custom: false, component: ListTwoTone},
    {name: "loading", custom: false, component: HourglassBottomTwoTone},
    {name: "lock-clock", custom: false, component: LockClockTwoTone},
    {name: "login", custom: false, component: LoginTwoTone},
    {name: "medical-device", custom: true, component: CustomIcon, customName: "medical-icon:i-cath-lab"},
    {name: "medical-service", custom: true, component: CustomIcon, customName: "carbon:image-medical"},
    {name: "new", custom: false, component: AddTwoTone},
    {name: "next", custom: false, component: ArrowCircleUpTwoTone},
    {name: "open", custom: false, component: FileOpenTwoTone},
    {name: "pending", custom: false, component: PendingTwoTone},
    {name: "person", custom: false, component: PersonTwoTone},
    {name: "person-male", custom: false, component: Face6TwoTone},
    {name: "person-female", custom: false, component: Face3TwoTone},
    {name: "pdf", custom: true, component: CustomIcon, customName: "ph:file-pdf"},
    {name: "power", custom: true, component: CustomIcon, customName: "mdi:power"},
    {name: "prev", custom: false, component: ArrowCircleDownTwoTone},
    {name: "print", custom: false, component: PrintTwoTone},
    {name: "question-mark", custom: false, component: NotListedLocationTwoTone},
    {name: "refresh", custom: false, component: RefreshTwoTone},
    {name: "reset", custom: false, component: ResetTvTwoTone},
    {name: "right", custom: false, component: ArrowCircleRightTwoTone},
    {name: "room", custom: false, component: MeetingRoomTwoTone},
    {name: "rss", custom: false, component: RssFeedTwoTone},
    {name: "save", custom: false, component: SaveTwoTone},
    {name: "scanner", custom: false, component: DocumentScannerTwoTone},
    {name: "screen", custom: false, component: ScreenshotMonitorTwoTone},
    {name: "schedule", custom: false, component: ScheduleTwoTone},
    {name: "search", custom: false, component: SearchTwoTone},
    {name: "settings", custom: false, component: SettingsTwoTone},
    {name: "share", custom: false, component: ShareTwoTone},
    {name: "sms", custom: true, component: CustomIcon, customName: "mdi:cellphone-message"},
    {name: "square", custom: false, component: SquareTwoTone},
    {name: "support", custom: true, component: CustomIcon, customName: "mdi:face-agent"},
    {name: "table", custom: false, component: TableViewTwoTone},
    {name: "upload", custom: false, component: UploadTwoTone},
    {name: "view", custom: false, component: VisibilityTwoTone},
    {name: "warehouse", custom: false, component: WarehouseTwoTone},
    {name: "whatsapp", custom: true, component: CustomIcon, customName: "logos:whatsapp-icon"},
    {name: "world", custom: false, component: PublicTwoTone},
    {name: "write-document", custom: false, component: HistoryEduTwoTone},
    {name: "default", custom: false, component: WidgetsTwoTone}
];

export const IconLibraryItem = ({
                                    name,
                                    iconSize,
                                    customSize,
                                    ...otherProps
                                }) => {

    iconSize = iconSize ?? "";

    let size = (customSize) ? customSize : (iconSize === "extra-large") ? 56 : (iconSize === "large") ? 48 : (iconSize === "medium") ? 32 : (iconSize === "small") ? 24 : 24;

    let foundIcon = iconsLibrary.find(i => i.name === name);
    if (!foundIcon) foundIcon = iconsLibrary.find(i => i.name === "default");

    let props;

    if (foundIcon.custom === true) {
        props = {...otherProps, name: foundIcon.customName, height: size, width: size}

    } else {

        props = {...otherProps, sx: {...otherProps.sx, fontSize: size}}

    }

    return createElement(foundIcon.component, props);


}