import {Grid, TextField, Typography, useTheme} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    booleanMatcher,
    booleanRendererWithBadge,
    columnTypes,
    dateTimeShortColumnRenderer,
    defaultColDef,
    isDataLoading,
    isDataNull,
    JSONClone, JSONIsEmpty
} from "../../../../common";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {ActionsToolBar} from "../../../../components/Grid/ActionsToolbar";
import {PaginationToolBar} from "../../../../components/Grid/PaginationToolBar";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AccountingContext} from "../../../../context/AccountingContext";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {BookingContext} from "../../../../context/BookingContext";
import {ManagementContext} from "../../../../context/ManagementContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {useForm} from "react-hook-form";
import * as PropTypes from "prop-types";
import {PatientDetail} from "../../registries/components/Patients";

const default_server_side_filter = {
    mNomePaziente: "",
    mCognomePaziente: "",
    mCF: "",
    mNumeroRicetta: ""
}

const defaultVisitLinesResult = {
    currentPage: 0,
    totalRows: 0,
    totalPages: 0,
    pageSize: 0
}

export const VisitLinesAdvancedSearch = (props) => {

    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);
    const alertContext = useContext(CustomAlertContext);
    const accountingContext = useContext(AccountingContext);
    const managementContext = useContext(ManagementContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [agGridApi, setAgGridApi] = useState(null);
    const [visitLines, setVisitLines] = useState(null);
    const [serverSideFilter, setServerSideFilter] = useState(JSONClone(default_server_side_filter));
    const [pagingState, setPagingState] = useState(JSONClone(defaultVisitLinesResult));
    const [pageChanged, setPageChanged] = useState(false);
    const [toReload, setToReload] = useState(false);
    const [refreshInterval, setRefreshInterval] = useState(120000);

    /* dettaglio paziente */
    const [modalOpen, setModalOpen] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [record, setRecord] = useState({});
    const [sponsorID, setSponsorID] = useState(-1);

    let navigate = useNavigate();

    const fetchData = (bReset, bSetPagingState) => {
        const filter = (bReset) ? JSONClone(default_server_side_filter) : serverSideFilter;
        filter.mRequestedPage = (bReset ?? false) ? 1 : pagingState.currentPage;
        managementContext.fetchVisitLines(filter, setVisitLines).then(res => {
            setVisitLines(res.responseData);
            if (bSetPagingState) {
                const newPagingState = {
                    currentPage: res.responseDataCurrentPage ?? 0,
                    totalRows: res.responseDataRowsCount ?? 0,
                    totalPages: res.responseDataPagesCount ?? 0,
                    pageSize: res.responseDataPageSize ?? 0
                }
                setPagingState(newPagingState);
            }
            setServerSideFilter(filter);
            setToReload(!toReload);
        });
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchData(false, false);
        }, refreshInterval);
        return () => {
            clearInterval(timer);
        };
    }, [refreshInterval, toReload]);

    useEffect(() => {
        if (isDataNull(visitLines) && !isDataLoading(visitLines)) {
            fetchData(true, true);
        }
    }, [visitLines]);

    useEffect(() => {
        if (!pageChanged) return;
        fetchData(false, false);
        setPageChanged(false);
    }, [pageChanged]);

    const onGridReady = (params) => {
        setAgGridApi(params.api);
        // params.api.sizeColumnsToFit();
    }

    const onSubmit = (data) => {
        registriesContext.updatePatient(data);
        setModalOpen(false);
    }

    const onError = (errors, e) => {
        console.log(record);
        console.log(errors, e);
    }

    const onClose = (event, reason) => {
        setModalOpen(false);
        //
    }

    const ToolBar = (props) => {

        let record = props.node.data;

        const editDisabled = () => {
            return false;
        }

        const editAction = () => {
            let booking = {visit: {mVisitaID: record.mVisita.mVisitaID}}
            bookingContext.openBooking(booking, 4);
            navigate('/Booking/VisitDetail');
        };

        const customDisabled = () => {
            return false;
        }

        const customAction = () => {
            console.log(JSON.stringify(record.mVisita));
            const mPatientID = record.mVisita.mPatient.mPatientID;
            registriesContext.fetchPatient(mPatientID).then((res) => {
                setModalOpen(true);
                reset(res);
                setRecord(res);
            });
        };

        const customActions = [{
            action: customAction,
            disabled: customDisabled(),
            icon: "person",
            tooltip: "Vai alla scheda del paziente",
        }]

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                customActions={customActions}
            />
        )
    }

    const invoicedRenderer = (props) => {
        const isExempt = ((props.data.mVisita.mEsenzione ?? {}).mEsenzioneID ?? 0) !== 0;
        return (isExempt) ? <></> : booleanRendererWithBadge(props)
    }

    const [columns, setColumns] = useState([
        {headerName: "Accettazione", field: "mVisita.mAccessionNumber", type: "noFilter", flex: 2},
        {
            headerName: "Data esame",
            field: "mDataAppuntamento",
            cellRenderer: dateTimeShortColumnRenderer,
            type: "noFilter",
            flex: 2
        },
        {headerName: "Paziente", field: "mPatientCompleteName", type: "noFilter", flex: 2},
        {headerName: "Prestazione", field: "mPrestazione.mDescrizione", type: "noFilter", flex: 3},
        {
            headerName: "Fatturato",
            field: "mIsInvoiced",
            cellRenderer: invoicedRenderer,
            filterParams: {textMatcher: booleanMatcher},
            maxWidth: 150,
            type: "noFilter"
        },
        {headerName: "Stato", field: "mStatoEsame", type: "noFilter"},
        {
            headerName: "Refertato",
            field: "mIsReported",
            cellRenderer: booleanRendererWithBadge,
            filterParams: {textMatcher: booleanMatcher},
            maxWidth: 150,
            type: "noFilter"
        },
        {headerName: "Tipo", field: "mVisita.mTipoPagamento.mDescription", maxWidth: 150, type: "noFilter"},
        // {headerName: "Ricetta", field: "mVisita.mNumeroRicetta", maxWidth: 150, type: "noFilter"},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    const serverSideFilterResetClick = () => {
        fetchData(true, true);
    }

    const serverSideFilterChange = (field, newValue) => {
        let newObject = JSONClone(serverSideFilter);
        newObject[field] = newValue;
        setServerSideFilter(newObject);
    }

    const serverSideConfirmClick = () => {
        fetchData(false, true);
    }

    const setCurrentPage = (newPageNumber) => {
        const newPagingState = {
            ...pagingState,
            currentPage: newPageNumber
        }
        setPageChanged(true);
        setPagingState(newPagingState);
    }

    const setSponsorByID = (pSponsorID) => {
        setValue('mFamilyDoctor.mSponsorID', pSponsorID, {});
        const newRecord = getValues();
        setModalOpen(true);
        reset(newRecord);
        setRecord(newRecord);
    }

    const {
        watch,
        getValues,
        setValue,
        register,
        control,
        handleSubmit,
        reset
    } = useForm({defaultValues: record});

    const hasSubTown = () => {
        if (!record) return false;
        let currentPat = getValues();
        if (!currentPat) return false;
        if (!currentPat.mLiveCity) return false;
        if (currentPat.mLiveCity.hasOwnProperty('mWithSubTown')) return currentPat.mLiveCity.mWithSubTown;
        return !JSONIsEmpty(currentPat.mSubTown);
    }


    if (isDataNull(visitLines) || isDataLoading(visitLines)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Elenco esami</Typography>
                </Grid>
                <Grid item xs={12}>
                    <PaginationToolBar
                        currentPage={pagingState.currentPage}
                        pagesCount={pagingState.totalPages}
                        pageSize={pagingState.pageSize}
                        setCurrentPage={setCurrentPage}
                        rowsCount={pagingState.totalRows}
                    >
                        <TextField
                            variant="filled"
                            sx={{width: 250}}
                            size={"small"}
                            label="Cognome"
                            type="text"
                            value={serverSideFilter.mCognomePaziente}
                            onChange={(e) => {
                                serverSideFilterChange("mCognomePaziente", e.target.value);
                            }}
                        />
                        <TextField
                            variant="filled"
                            sx={{width: 250}}
                            size={"small"}
                            label="Nome"
                            type="text"
                            value={serverSideFilter.mNomePaziente}
                            onChange={(e) => {
                                serverSideFilterChange("mNomePaziente", e.target.value);
                            }}
                        />
                        <TextField
                            variant="filled"
                            sx={{width: 250}}
                            size={"small"}
                            label="Ricetta/Visita/Accettazione"
                            type="text"
                            value={serverSideFilter.mNumeroRicetta}
                            onChange={(e) => {
                                serverSideFilterChange("mNumeroRicetta", e.target.value);
                            }}
                        />
                        <ActionButton
                            color="secondary"
                            variant="contained"
                            action={serverSideConfirmClick}
                            size="small"
                            icon="refresh"
                            iconSize="small"
                            tooltip="Cerca"
                            label="Cerca"
                            sx={loggedClasses.paginationToolbarHeightSx}
                        />
                        <ActionButton
                            color="secondary"
                            variant="contained"
                            action={serverSideFilterResetClick}
                            size="small"
                            icon="search"
                            iconSize="small"
                            tooltip="Annulla"
                            label="Annulla"
                            sx={loggedClasses.paginationToolbarHeightSx}
                        />
                    </PaginationToolBar>
                </Grid>
                <Grid item xs={12}>
                    <div id="visitLinesGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={visitLines}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            rowSelection={'single'}
                            pagination={false}
                            onGridReady={onGridReady}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
            {
                !JSONIsEmpty(record) && modalOpen &&
                <PatientDetail
                    modalOpen={modalOpen}
                    onClose={onClose}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    onError={onError}
                    record={record}
                    control={control}
                    register={register}
                    sponsorID={sponsorID}
                    setSponsorID={setSponsorID}
                    setSponsorByID={setSponsorByID}
                    hasSubTown={hasSubTown}
                />
            }
        </>
    )
        ;

}