export default function Pagination(theme) {
    return {
        MuiPagination: {
            styleOverrides: {
                root: {
                    minHeight: '58px',
                }
            }
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    minHeight: '58px',
                    minWidth: '58px',
                }
            }
        }
    };
}