import {Box, Grid, Tab, Tabs, useTheme} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {TabPanel} from "../../../../components/Tab/TabPanel";
import {AuthContext} from "../../../../context/AuthContext";
import {loggedClassNames} from "../../../../theme/Styles";
import ChangePassword from "./ChangePassword";
import EditProfile from "./EditProfile";
import {IconLibraryItem} from "../../../../components/Icons/IconLibraryItem";

const Profile = () => {

    const authContext = useContext(AuthContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const pageID = parseInt(searchParams.get('page') ?? 0);

    const [selectedTab, setSelectedTab] = useState(pageID ?? 0);

    useEffect(() => {

    }, []);

    const tabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <Grid
                container
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={loggedClasses.userDetailHeader}
                key="VisitModalGrid"
            >
                <Grid item xs={12} mt={2}>
                    <Box sx={{flexGrow: 1, display: 'flex'}}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            onChange={tabChange}
                            aria-label="basic tabs example"
                            value={selectedTab}
                            sx={loggedClasses.userTabButtons}
                        >
                            <Tab icon={<IconLibraryItem name={"account"} iconSize={"medium"}/>} label="Modifica profilo" {...a11yProps(0)}/>
                            <Tab icon={<IconLibraryItem name={"key"} iconSize={"medium"}/>} label="Modifica password" {...a11yProps(1)}/>
                        </Tabs>
                        <TabPanel value={selectedTab} index={0} sx={loggedClasses.userTabPanel}>
                            <EditProfile/>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={1} sx={loggedClasses.userTabPanel}>
                            <ChangePassword/>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Profile;