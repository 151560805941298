import {UploadFileTwoTone} from "@mui/icons-material";
import {Button, CardActions, CardContent, Grid, Typography, useTheme} from "@mui/material";
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {AuthContext} from "../../context/AuthContext";
import {loggedClassNames, userImageDropZoneStyles} from "../../theme/Styles";
import {CardModal} from "../Layout/CardModal";

const UploadUserImage = ({
                             isOpen,
                             onClose,
                             setIsUploading
                         }) => {

    const authContext = useContext(AuthContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [selectedFileName, setSelectedFileName] = useState(undefined);
    const [selectedFileContent, setSelectedFileContent] = useState(undefined);
    const [uploadMessage, setUploadMessage] = useState("");

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onabort = () => setUploadMessage('La lettura del file è stata interrotta.')
            reader.onerror = () => setUploadMessage('La lettura del file è fallita.')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                setSelectedFileContent(binaryStr);
            };
            setSelectedFileName(file.path);
            reader.readAsDataURL(file);
        })
    }, []);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        // accept: ["image/jpeg", "image/png", "image/gif", "image/*"],
        maxSize: 100000000, // 100 mb
        multiple: false,
        noDrag: true,
        noDragEventsBubbling: true,
        onDrop
    })

    const dropZoneStyles = userImageDropZoneStyles(theme);

    const style = useMemo(() => ({
        ...dropZoneStyles.userImageDropzoneBase,
        ...(isDragActive ? dropZoneStyles.userImageDropzoneActive : {}),
        ...(isFocused ? dropZoneStyles.userImageDropzoneFocused : {}),
        ...(isDragAccept ? dropZoneStyles.userImageDropzoneAccepted : {}),
        ...(isDragReject ? dropZoneStyles.userImageDropzoneRejected : {})
    }), [isDragActive, isFocused, isDragAccept, isDragReject]);

    const getImagePreview = () => {
        if (selectedFileContent) {
            return <img src={selectedFileContent} alt="" style={{width: 'auto', height: 200}}/>;
        } else {
            return <></>;
        }
    }

    const resetUpload = () => {
        acceptedFiles.length = 0;
        acceptedFiles.splice(0, acceptedFiles.length);
        setSelectedFileContent(undefined);
        onClose();
    }

    const saveImage = () => {
        setIsUploading(true);
        authContext.saveLoggedUserImage(selectedFileContent).then((res) => {
            setIsUploading(false);
            acceptedFiles.length = 0
            acceptedFiles.splice(0, acceptedFiles.length);
            setSelectedFileContent(undefined);
            onClose();
        })
    }

    const uploadDisabled = selectedFileContent === undefined;


    return (
        <>
            <CardModal
                key={'image-upload-modal'}
                modalOpen={isOpen}
                onClose={onClose}
                title={"Aggiorna immagine profilo"}
                size={"xs"}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid direction={'row'} spacing={theme.spacing(2)} justifyContent={'flex-start'}
                          alignItems={'flex-start'}>
                        <Grid item xs={12}>
                            {selectedFileContent ? (
                                <Typography>Nome file: {selectedFileName}</Typography>
                            ) : !uploadMessage ? (
                                <div {...getRootProps({style})}>
                                    <input {...getInputProps()} />
                                    {isDragActive ? (
                                        <Typography component={'p'}>Trascina il file qui ...</Typography>
                                    ) : (
                                        <Typography component={'p'}>Trascina il file qui, o fai click per selezionare un
                                            file</Typography>
                                    )}
                                </div>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {getImagePreview()}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color={"error"}>{uploadMessage}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button color="secondary" variant="contained" onClick={resetUpload}>
                        Annulla
                    </Button>
                    <Button color="primary" variant="contained" onClick={saveImage} disabled={uploadDisabled}>Upload</Button>
                </CardActions>
            </CardModal>
        </>
    );

};


export default UploadUserImage;