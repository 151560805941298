import {ExpandMoreTwoTone} from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Badge, Chip,
    Grid,
    ListItem,
    ListSubheader,
    Typography,
    useTheme
} from "@mui/material";
import List from "@mui/material/List";
import React, {useContext, useEffect, useState} from "react";
import {RegistriesContext} from "../../context/RegistriesContext";
import {StatusBadge} from "../Badges/StatusBadge";
import {CircularWaiting} from "../Waitings/CircularWaiting";
import {BookingContext} from "../../context/BookingContext";
import {loggedClassNames} from "../../theme/Styles";
import {VisitLineSlotPaper} from "./VisitLineSlotPaper";
import {useNavigate} from "react-router-dom";
import {CommonContext} from "../../context/CommonContext";
import {IconLibraryItem} from "../Icons/IconLibraryItem";
import {VisitLineSlot} from "./VisitLineSlot";
import {isDataLoading, isDataNull, setDataLoading} from "../../common";

export const RoomTimeLine = ({room}) => {

    const bookingContext = useContext(BookingContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [roomSlots, setRoomSlots] = useState(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
    }, [bookingContext.bookingState]);

    useEffect(() => {
        if (loaded) return;
        const dt = bookingContext.bookingState.selectedDate;
        setDataLoading(setRoomSlots);
        bookingContext.fetchVisitLines(dt,dt,room.mRoomID).then(vls=>{
            bookingContext.calculatePlanningRoomSlots(vls,room.mRoomID).then(res=>{
                setRoomSlots(res);
                setLoaded(true);
            })
        });
    }, [loaded]);

    return (
        <List
            sx={loggedClasses.roomContainer}
            key={"RoomTimeLine-" + room.mRoomID}
            subheader={<li/>}
        >
            <li key={"RoomTimeLineList-" + room.mRoomID}>
                <ul>
                    <ListSubheader key={"RoomTimeLineTitle-" + room.mRoomID} sx={loggedClasses.roomTitle}>
                        <Typography padding={theme.spacing(1)} variant="h5" align="center">
                            {room.mRoomName + ' '}
                            <Badge badgeContent={loaded ? roomSlots.visitLinesCount : "-"} sx={{px: theme.spacing(1)}} >
                                <IconLibraryItem name={"medical-device"} iconSize={"medium"}/>
                            </Badge>

                        </Typography>

                    </ListSubheader>
                    {
                        (loaded) ?
                            roomSlots.slots.map((slot) => {
                                let liKey = slot.key;
                                if (slot.hidden === false) {
                                    return (
                                        <ListItem key={'list-item-' + liKey} sx={{p: theme.spacing(0.5)}}>
                                            <VisitLineSlot key={'visit-line-slot-' + liKey} slot={slot} room={room}/>
                                        </ListItem>
                                    )
                                } else {
                                    return <></>
                                }
                            })
                            :
                            <CircularWaiting/>
                    }
                </ul>
            </li>
        </List>
    )
}