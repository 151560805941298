import {Card, CardContent} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import React, {useContext} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ContainerTitle} from "../../../components/Layout/ContainerTitle";
import {CustomAlertContext} from "../../../context/AlertContext";
import {AuthContext} from "../../../context/AuthContext";
import {BookingContext} from "../../../context/BookingContext";
import {RegistriesContext} from "../../../context/RegistriesContext";
import {loggedClassNames} from "../../../theme/Styles";
import IntervalsScheduler from "./components/IntervalsScheduler";
import RoomsPlanner from './components/RoomsPlanner';
import TakeBooking from './components/TakeBooking';
import VisitDetail from './components/VisitDetail';
import RoomPlanner from "./components/RoomPlanner";

const bookingReportsRoutes = [
    {path: '/Booking/RoomsPlanner', component: <RoomsPlanner/>, exact: true},
    {path: '/Booking/RoomPlanner', component: <RoomPlanner/>, exact: true},
    {path: '/Booking/IntervalsScheduler', component: <IntervalsScheduler/>, exact: true},
    {path: '/Booking/TakeBooking', component: <TakeBooking/>, exact: true},
    {path: '/Booking/VisitDetail', component: <VisitDetail/>, exact: true},
]

const LocationRenderer = () => {

    const location = useLocation();

    const bookingContext = useContext(BookingContext);

    const exactElement = bookingReportsRoutes.find(l => l.exact === true && location.pathname === l.path);

    if (exactElement) return exactElement.component;

    const partialElement = bookingReportsRoutes.find(l => l.exact === false && location.pathname.startsWith(l.path));

    if (partialElement) return partialElement.component;

    switch (bookingContext.bookingMode) {
        case -1:
            return <></>;
        case 0:
            return <RoomsPlanner/>
        case 1:
            return <TakeBooking/>
        case 20:
            return <TakeBooking/>
        case 21:
            return <TakeBooking/>
        case 3:
            return <TakeBooking/>
        case 4:
            return <VisitDetail/>
        case 5:
            return <IntervalsScheduler/>
        case 6:
            return <TakeBooking/>
        case 7:
            return <RoomPlanner/>
        default:
            return <></>
    }

}

const BookingContainer = (props) => {

    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);
    const alertContext = useContext(CustomAlertContext);
    const authContext = useContext(AuthContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const navigate = useNavigate();

    const site = (authContext.getLoggedUser().mWorkSite.mCompanyName ?? "").toUpperCase();

    const goToRoomsPlannerClick = () => {
        bookingContext.goToRoomsPlanner();
        navigate('/Booking/RoomsPlanner');
    }

    const goToRoomPlannerClick = () => {
        bookingContext.goToRoomsPlanner();
        navigate('/Booking/RoomPlanner');
    }

    const goToIntervalsSchedulerClick = () => {
        navigate('/Booking/IntervalsScheduler')
    }

    const customActions = [
        {
            action: goToRoomsPlannerClick,
            disabled: false,
            icon: "calendar-week",
            tooltip: "Agenda multisala",
        },
        {
            action: goToRoomPlannerClick,
            disabled: false,
            icon: "calendar-day",
            tooltip: "Agenda singola sala",
        },
        {
            action: goToIntervalsSchedulerClick,
            disabled: false,
            icon: "schedule",
            tooltip: "Planning",
        }
    ]

    return (
        <Card sx={loggedClasses.loggedContent}>
            <CardContent sx={loggedClasses.loggedCardContent}>
                <ContainerTitle
                    title={site}
                    hasHome={false}
                    customActions={customActions}
                    key={"container-title-booking"}
                />
                <LocationRenderer/>
            </CardContent>
        </Card>
    )

}

export default BookingContainer;
