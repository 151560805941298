import {Grid, Typography} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import React, {useContext, useEffect, useState} from 'react';
import {columnTypes, defaultColDef, moneyColumnRenderer} from "../../../../common";
import {AccountingContext} from "../../../../context/AccountingContext";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {gridRowHeight, gridSizeStyle} from "../../../../theme/Styles";

export const CreditDocumentDetails = (props) => {

    const alertContext = useContext(CustomAlertContext);
    const accountingContext = useContext(AccountingContext);

    const [agGridApi, setAgGridApi] = useState(null);

    useEffect(() => {
        if (!accountingContext.creditDocument)  {
            accountingContext.fetchCreditDocument(accountingContext.accountingState.creditDocument.mInvoiceID).then((res) => {});
        }
    }, [accountingContext.creditDocument]);

    const [columns] = useState([
        {
            headerName: "Descrizione riga",
            field: "mEsame.mPrestazione.mDescrizione",
            minWidth: '300'
        },
        {
            headerName: "€ Importo",
            field: "mPrice",
            minWidth: '130',
            cellRenderer: moneyColumnRenderer,
            cellClass: 'number-cell'
        },
    ]);

    if (!accountingContext.accountingState.creditDocument) return <></>;
    if (!accountingContext.creditDocument) return <></>;

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4" component="h5">
                        Dettaglio nota di credito n° {accountingContext.creditDocument.mInvoiceNumber} del {accountingContext.creditDocument.mInvoiceDateLabel}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <div id="myGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={accountingContext.creditDocument.mListOfInvoiceLine}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default CreditDocumentDetails;