export default function Chip(theme) {
    return {
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                },
                sizeMedium: {
                    textTransform: "uppercase",
                    fontWeight: '400',
                    fontSize: '0.7rem !important',
                    height: 40
                },
                iconMedium:{
                    color: theme.palette.primary.contrastText,
                },
                outlined: {
                    color: theme.palette.primary.contrastText,
                    '& .MuiChip-avatar':{
                        color: theme.palette.primary.contrastText,
                    }

                }
            }
        }
    };
}
