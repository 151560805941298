import {Autocomplete, Grid, Pagination, Stack, TextField, useTheme} from "@mui/material";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {RoomTimeLine} from "../../../../components/RoomsPlanner/RoomTimeLine";
import {BookingContext} from "../../../../context/BookingContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {loggedClassNames} from "../../../../theme/Styles";
import {CommonContext} from "../../../../context/CommonContext";
import {SquareButton} from "../../../../components/Buttons/SquareButton";
import {InputDatePicker} from "../../../../components/Input/InputDatePicker";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {JSONClone} from "../../../../common";

const initial_rooms_planner_state = {
    pagesCount: 0,
    activePage: 1
}

const RoomsPlanner = () => {

    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);
    const commonContext = useContext(CommonContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    let navigate = useNavigate();

    const [loaded,setLoaded] = useState(false);
    const [roomsPlannerState, setRoomsPlannerState] = useState(JSONClone(initial_rooms_planner_state));

    let roomsPerPage = commonContext.getPlanningParameter("ROOMS_PER_PAGE");

    let fromPage = roomsPerPage * (roomsPlannerState.activePage - 1);
    let toPage = (roomsPerPage * roomsPlannerState.activePage) - 1;

    const activeRooms = registriesContext.getActiveRooms() ?? [];

    let roomCols = parseInt(12 / roomsPerPage, 10);

    useEffect(() => {
        if (loaded) return;
        const pagesCount =  Math.ceil(activeRooms.length / roomsPerPage);
        let newValue = {
            ...roomsPlannerState,
            pagesCount: pagesCount
        }
        setRoomsPlannerState(newValue);
        setLoaded(true);
    }, [loaded]);

    const newBookingClick = () => {
        let booking = {
            room: 0,
            slot: null,
        }
        bookingContext.openBooking(booking, 20);
        navigate('/Booking/TakeBooking');
    }

    const pageChanged = (event, value) => {
        let newValue = {
            ...roomsPlannerState,
            activePage: value
        }
        setRoomsPlannerState(newValue);
    };

    const goToPrevSevenDays = () => {
        bookingContext.addDaysPlanningSelectedDate(-7);
    }

    const goToPrevOneDay = () => {
        bookingContext.addDaysPlanningSelectedDate(-1);
    }

    const goToNextOneDay = () => {
        bookingContext.addDaysPlanningSelectedDate(1);
    }

    const goToNextSevenDays = () => {
        bookingContext.addDaysPlanningSelectedDate(7);
    }

    const goToToday = () => {
        bookingContext.resetPlanningSelectedDate();
    }

    return (
        <>
            <Grid
                container
                direction={"row"}
                sx={loggedClasses.bookingDetailHeader}
            >
                <Grid item xs={12} md={6}>
                    <Stack direction={"row"} spacing={theme.spacing(0.5)}>
                        <SquareButton
                            color="secondary"
                            variant="contained"
                            action={goToPrevSevenDays}
                            size="small"
                            icon="left"
                            iconSize="small"
                            tooltip="Vai indietro di 7 giorni"
                            label="-7"
                            direction={"column"}
                            withBoxShadow={true}
                        />
                        <SquareButton
                            color="secondary"
                            variant="contained"
                            action={goToPrevOneDay}
                            size="small"
                            icon="left"
                            iconSize="small"
                            tooltip="Vai indietro di 1 giorno"
                            label="-1"
                            direction={"column"}
                            withBoxShadow={true}
                        />
                        <InputDatePicker
                            label={"Data"}
                            value={bookingContext.bookingState.selectedDate}
                            disabled={false}
                            format={commonContext.getPlanningParameter("DATE_FORMAT_SHORT")}
                            setValue={bookingContext.setPlanningSelectedDate}
                            sx={{minWidth: 100}}
                        />
                        <SquareButton
                            color="secondary"
                            variant="contained"
                            action={goToToday}
                            size="small"
                            icon="calendar"
                            iconSize="small"
                            tooltip="Vai a oggi"
                            label="Oggi"
                            direction={"column"}
                            withBoxShadow={true}
                        />
                        <SquareButton
                            color="secondary"
                            variant="contained"
                            action={goToNextOneDay}
                            size="small"
                            icon="right"
                            iconSize="small"
                            tooltip="Vai avanti di 1 giorno"
                            label="+1"
                            direction={"column"}
                            withBoxShadow={true}
                        />
                        <SquareButton
                            color="secondary"
                            variant="contained"
                            action={goToNextSevenDays}
                            size="small"
                            icon="right"
                            iconSize="small"
                            tooltip="Vai avanti di 7 giorni"
                            label="+7"
                            direction={"column"}
                            withBoxShadow={true}
                        />
                        <ActionButton
                            color="secondary"
                            variant="contained"
                            action={newBookingClick}
                            size="small"
                            icon="calendar"
                            iconSize="medium"
                            tooltip="Prenota"
                            label="Prenota"
                            withBoxShadow={true}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} display={"flex"} justifyContent="flex-end">
                    <Pagination
                        count={roomsPlannerState.pagesCount}
                        page={roomsPlannerState.activePage}
                        variant="outlined"
                        shape="rounded"
                        size="large"
                        onChange={pageChanged}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                direction={"row"}
                alignItems={"center"}
                sx={loggedClasses.bookingDetailHeader}
                spacing={theme.spacing(2)}
            >
                {
                    activeRooms.map((room, index) => {
                        let visible = ((index >= fromPage) && (index <= toPage));
                        return (visible) ?
                            <Grid item xs={roomCols}>
                                <RoomTimeLine room={room} key={"RoomTimeLine-" + room.mRoomID}/>
                            </Grid>
                            :
                            <></>
                    })
                }
            </Grid>
        </>
    )

}

export default RoomsPlanner;