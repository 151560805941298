import {CardContent, Grid, Paper, Stack, Tab, Tabs, Typography, useTheme} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {columnTypes, defaultColDef, isDataEmpty, isDataLoading, isDataNull} from "../../../../common";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AuthContext} from "../../../../context/AuthContext";
import {gridMediumSizeStyle, gridRowHeight, loggedClassNames} from "../../../../theme/Styles";
import {TabPanel} from "../../../../components/Tab/TabPanel";
import {CardModal} from "../../../../components/Layout/CardModal";
import {useLocation} from "react-router-dom";

const SystemParameters = () => {

    const authContext = useContext(AuthContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const pageName = (searchParams.get('page') ?? "").toUpperCase().trim();

    const [record, setRecord] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [dataLoaded, setDataLoaded] =useState(false);
    const [groups, setGroups] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);

    const {
        register,
        control,
        handleSubmit,
        reset
    } = useForm({defaultValues: record});

    useEffect(() => {
        if (isDataNull(authContext.paramGroupItemizedListValues)) authContext.fetchParamGroupItemizedListValues();
    }, [authContext.paramGroupItemizedListValues]);

    // useEffect(() => {
    //     if (
    //         isDataLoading(authContext.systemParameters) ||
    //         !isDataNull(authContext.systemParameters)
    //     ) return;
    //     if ((!dataLoaded) && !isDataLoading(authContext.systemParameters)) authContext.refreshSystemParameters().then((res)=>{
    //         setDataLoaded(true);
    //     })
    // }, [authContext.systemParameters]);

    useEffect(() => {
        if (
            isDataLoading(authContext.systemParameters) ||
            isDataNull(authContext.systemParameters) ||
            isDataEmpty(authContext.systemParameters)
        ) return;
        if (isDataNull(groups) && !isDataLoading(groups)) {
            setGroups("loading");
            let arrGroups = authContext.systemParameters
                .map((item) => {
                    return item.mParamGroup
                });
            arrGroups = arrGroups.filter((item, index) => arrGroups.indexOf(item) === index);
            if (pageName!=="") {
                const groupIndex = arrGroups.indexOf(pageName);
                if (groupIndex!==-1) setSelectedTab(groupIndex);
            }
            setGroups(arrGroups);
        }
    }, [groups, authContext.systemParameters]);

    //dettaglio

    const onSubmit = (data) => {
        authContext.updateSystemParameter(data);
        setModalOpen(false);
    }

    const onError = (errors, e) => {
        console.log(record);
        console.log(errors, e);
    }

    const onClose = (event, reason) => {
        setModalOpen(false);
    }

    //griglia

    const addAction = () => {
        const res = authContext.fetchSystemParameter(0);
        if (res === null) return;
        setRecord(res);
        reset(res);
        setModalOpen(true);
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            const res = authContext.fetchSystemParameter(selectedRow.mParameterID);
            if (res === null) return;
            setRecord(res);
            reset(res);
            setModalOpen(true);
        };

        const deleteAction = () => {
            authContext.deleteSystemParameter(selectedRow);
        }

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
            />
        )
    }

    const [columns, setColumns] = useState([
        {headerName: "Nome parametro", field: "mParamName"},
        {headerName: "Gruppo", field: "mParamGroup"},
        {headerName: "Valore", field: "mParamValue"},
        {headerName: "Descrizione", field: "mParamDesc"},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    const tabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `parameters-tab-${index}`,
            'aria-controls': `parameters-tabpanel-${index}`,
        };
    }

    const getFilteredParameters = (selectedGroup) => {
        return authContext.systemParameters
            .filter((item) => {
                return item.mParamGroup === selectedGroup
            })
    }

    if (isDataNull(authContext.systemParameters) || isDataLoading(authContext.systemParameters)) return <CircularWaiting/>
    if (isDataNull(authContext.paramGroupItemizedListValues)) return <CircularWaiting/>
    if (isDataNull(groups) || isDataLoading(groups)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                display="flex"
                direction={"column"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Parametri di sistema</Typography>
                </Grid>
                <Grid item container>
                    <Grid item xs={12}>
                        <Stack
                            direction={"row"}
                            justifyContent={"flex-end"}
                            spacing={theme.spacing(2)}
                            sx={{pt: theme.spacing(1)}}
                        >
                            <AddButton addAction={addAction}/>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper variant={"oulined"} sx={loggedClasses.systemParametersContainer}>
                        <Tabs
                            value={selectedTab}
                            variant={"fullWidth"}
                            aria-label="basic tabs example"
                            onChange={tabChange}
                            sx={loggedClasses.systemParametersTabButtons}
                        >
                            {
                                (groups).map((item, index) => {
                                    return (
                                        <Tab index={index} label={item} {...a11yProps(index)}
                                             sx={loggedClasses.systemParametersTabButton}/>
                                    )
                                })
                            }
                        </Tabs>
                        {
                            (groups).map((item, index) => {
                                return (
                                    <TabPanel value={selectedTab} index={index}
                                              sx={loggedClasses.systemParametersTabPanel}>
                                        <div id={"agReactGrid" + index} className="ag-theme-alpine"
                                             style={gridMediumSizeStyle}>
                                            <AgGridReact
                                                rowData={getFilteredParameters(item)}
                                                defaultColDef={defaultColDef}
                                                columnDefs={columns}
                                                columnTypes={columnTypes}
                                                suppressMovableColumns={true}
                                                suppressCellFocus={true}
                                                pagination={true}
                                                rowSelection={'single'}
                                                getRowHeight={(params) => gridRowHeight}
                                            >
                                            </AgGridReact>
                                        </div>
                                    </TabPanel>
                                )
                            })
                        }
                    </Paper>
                </Grid>
            </Grid>
            <CardModal
                modalOpen={modalOpen}
                onClose={onClose}
                title={"Dettaglio parametro di sistema"}
                size={"small"}
                formFunctions={{submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError}}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Nome parametro"
                                control={control}
                                record={record}
                                register={register}
                                field="mParamName"
                                required={true}
                                minLength={3}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormAutoComplete
                                label="Gruppo"
                                control={control}
                                record={record}
                                register={register}
                                field="mParamGroup"
                                joinField=""
                                values={authContext.paramGroupItemizedListValues}
                                idField="mItemizedListValueID"
                                labelField="mValue"
                                selectedValue="label"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Valore"
                                control={control}
                                record={record}
                                register={register}
                                field="mParamValue"
                                required={true}
                                maxLength={1000}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Descrizione"
                                control={control}
                                record={record}
                                register={register}
                                field="mParamDesc"
                                required={true}
                                multiline={true}
                                lines={5}
                                maxLength={1000}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardModal>
        </>
    )

}

export default SystemParameters;