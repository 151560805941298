import React from "react";
import {Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme} from "@mui/material";
import {loggedClassNames} from "../../theme/Styles";
import {IconLibraryItem} from "../Icons/IconLibraryItem";
import {ActionButton} from "../Buttons/ActionButton";

export const CustomConfirmDialog = (props) => {

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const handleCancel = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.handleCancel();
        props.handleHide(props.requestId);
    };

    const handleConfirm = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.handleConfirm();
        props.handleHide(props.requestId);
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            key={props.requestId}
        >
            <DialogTitle id="alert-dialog-title" sx={loggedClasses.customConfirmDialogTitle}>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={loggedClasses.customConfirmDialogMessage}>
                    <IconLibraryItem customSize={150} name={"question-mark"} />
                    <Box>{props.message}</Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={loggedClasses.customConfirmDialogActions}>
                <ActionButton
                    color="warning"
                    variant="contained"
                    action={handleConfirm}
                    size="medium"
                    icon="check"
                    iconSize="medium"
                    tooltip="Conferma"
                    label="Conferma"
                    fullWidth
                />
                <ActionButton
                    color="secondary"
                    variant="contained"
                    action={handleCancel}
                    size="medium"
                    icon="cancel"
                    iconSize="medium"
                    tooltip="Annulla"
                    label="Annulla"
                    fullWidth
                />
            </DialogActions>
        </Dialog>)
}