import {Grid, Typography, useTheme} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {columnTypes, defaultColDef, isDataLoading, isDataNull} from "../../../../common";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AuthContext} from "../../../../context/AuthContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";

const PaymentTypes = () => {

    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();

    const [dataLoaded, setDataLoaded] =useState(false);

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(registriesContext.paymentTypes)) registriesContext.fetchPaymentTypes().then((res) => {
            setDataLoaded(true);
        });
    }, [registriesContext.paymentTypes]);

    const [columns, setColumns] = useState([
        {headerName: "Descrizione", field: "mDescription"},
        {headerName: "Codice", field: "mCodice"},

    ]);

    if (isDataNull(registriesContext.paymentTypes) || isDataLoading(registriesContext.paymentTypes)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Tipologie pagamento</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{pb: theme.spacing(2)}}>
                        <Grid item xs={12}>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={registriesContext.paymentTypes}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
        </>
    )

}

export default PaymentTypes;