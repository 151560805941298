import {Grid, Typography, useTheme} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import React, {useContext, useEffect, useState} from 'react';
import {columnTypes, dateTimeColumnRenderer, defaultColDef, isDataLoading, isDataNull} from "../../../../common";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {ManagementContext} from "../../../../context/ManagementContext";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {StatusBadge} from "../../../../components/Badges/StatusBadge";

export const DBBackups = (props) => {

    const managementContext = useContext(ManagementContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    const [loaded, setLoaded] =useState(false);

    useEffect(() => {
        if ((!loaded) && !isDataLoading(managementContext.logs)) managementContext.fetchLogs().then((res) => {
            setLoaded(true);
        });
    }, [managementContext.logs]);

    const [columns, setColumns] = useState([
        { headerName: 'Data', field: 'mLogDate', cellRenderer: dateTimeColumnRenderer },
        { headerName: 'Messaggio', field: 'mLogMessage' }
    ]);

    if (isDataNull(managementContext.logs) || isDataLoading(managementContext.logs)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={managementContext.logs}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
        </>
    )

}