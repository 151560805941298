import {CardContent, Grid, Stack, Typography, useTheme} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {columnTypes, defaultColDef, isDataLoading, isDataNull} from "../../../../common";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";

const Counters = () => {

    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [record, setRecord] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [dataLoaded, setDataLoaded] =useState(false);

    const {
        register,
        control,
        handleSubmit,
        reset
    } = useForm({defaultValues: record});

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(registriesContext.counters)) registriesContext.fetchCounters().then((res)=>{
            setDataLoaded(true);
        })
    }, [registriesContext.counters]);

    //dettaglio

    const onSubmit = (data) => {
        let newData = data;
        newData.mActualValue = parseInt(newData.mActualValue);
        registriesContext.updateCounter(newData);
        setModalOpen(false);
    }

    const onError = (errors, e) => {
        console.log(record);
        console.log(errors, e);
    }

    const onClose = (event, reason) => {
        setModalOpen(false);
    }

    //griglia

    const addAction = () => {
        registriesContext.fetchCounter(0).then((res) => {
            setRecord(res);
            reset(res);
            setModalOpen(true);
        });
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            registriesContext.fetchCounter(selectedRow.mCounterID).then((res) => {
                setRecord(res);
                reset(res);
                setModalOpen(true);
            });
        };

        const deleteAction = () => {
            alert(selectedRow.mCounterID);
        }

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
            />
        )
    }

    const onGridReady = (params) => {
        // setAgGridApi(params.api);
        // params.api.sizeColumnsToFit();
    }

    const [columns, setColumns] = useState([
        {headerName: "Codice", field: "mCounterCode"},
        {headerName: "Descrizione", field: "mCounterDescription"},
        {headerName: "Valore successivo", field: "mActualValue"},
        {headerName: "Prefisso", field: "mPrefix"},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    if (isDataNull(registriesContext.counters) || isDataLoading(registriesContext.counters)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Contatori</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{pb: theme.spacing(2)}}>
                        <Grid item xs={12}>
                            <Stack
                                direction={"row"}
                                justifyContent={"flex-end"}
                                spacing={theme.spacing(2)}
                                sx={{pt: theme.spacing(1)}}>
                                <AddButton addAction={addAction}/>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={registriesContext.counters}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            onGridReady={onGridReady}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
            <CardModal
                modalOpen={modalOpen}
                onClose={onClose}
                title={"Dettaglio contatore"}
                size={"small"}
                formFunctions={{submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError}}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Codice"
                                control={control}
                                record={record}
                                register={register}
                                field="mCounterCode"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Descrizione"
                                control={control}
                                record={record}
                                register={register}
                                field="mCounterDescription"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Valore"
                                control={control}
                                record={record}
                                register={register}
                                field="mActualValue"
                                required={true}
                                maxLength={50}
                                pattern={"number"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Prefisso"
                                control={control}
                                record={record}
                                register={register}
                                field="mPrefix"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardModal>
        </>
    )

}

export default Counters;