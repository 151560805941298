import {Stack, useTheme} from '@mui/material';
import React from 'react';
import logo from "../../assets/images/InCare.png";
import logoPartner from "../../assets/images/logo-integrapp-negative.svg";
import {commonClassNames, loggedClassNames} from "../../theme/Styles";

function AppLogo ({size, partnerSize, direction}) {

    const theme = useTheme();
    const commonClasses = commonClassNames(theme);
    const dir = (direction) ? direction :  "column";
    const pStyle = (dir==="column") ? { height: partnerSize, width: 'auto' }: { height: 'auto', width: partnerSize };
    const lStyle = (dir==="column") ? { height: size, width: 'auto' }: { height: 'auto', width: size };

    return (
        <Stack
            spacing={theme.spacing(1)}
            justifyContent="center"
            alignItems="center"
            direction={dir}
        >
            {
                (partnerSize) && <img src={logoPartner} alt={"logo"} style={pStyle}/>
            }
            {
                (size) && <img src={logo} alt={"logo"} style={lStyle}/>
            }
        </Stack>

    );
}

export default AppLogo;