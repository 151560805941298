import {Card, CardContent, useTheme} from "@mui/material";
import React, {useContext} from 'react';
import {useLocation} from 'react-router-dom';
import {ContainerTitle} from "../../../components/Layout/ContainerTitle";
import {RegistriesContext} from "../../../context/RegistriesContext";
import {loggedClassNames} from "../../../theme/Styles";
import Asl from "./components/Asl";
import Banks from "./components/Banks";
import Branches from "./components/Branches";
import Cities from "./components/Cities";
import Companies from "./components/Companies";
import Conventions from "./components/Conventions";
import Counters from "./components/Counters";
import Countries from "./components/Countries";
import Devices from "./components/Devices";
import Districts from "./components/Districts";
import Domains from "./components/Domains";
import Exemptions from "./components/Exemptions";
import ExemptionTypes from "./components/ExemptionTypes";
import ItemizedLists from "./components/ItemizedLists";
import MedicalServices from "./components/MedicalServices";
import MedicalServiceTypes from "./components/MedicalServiceTypes";
import Modalities from "./components/Modalities";
import {Patients} from "./components/Patients";
import PaymentTypes from "./components/PaymentTypes";
import Profiles from "./components/Profiles";
import Regions from "./components/Regions";
import Rooms from "./components/Rooms";
import Sponsors from "./components/Sponsors";
import SponsorTypes from "./components/SponsorTypes";
import SupplierTypes from "./components/SupplierTypes";
import SystemFunctions from "./components/SystemFunctions";
import SystemParameters from "./components/SystemParameters";
import Users from "./components/Users";
import Reports from "./components/Reports";

const registriesRoutes = [
    {path: '/Registries/Asl', component: <Asl/>, exact: true},
    {path: '/Registries/Banks', component: <Banks/>, exact: true},
    {path: '/Registries/Branches', component: <Branches/>, exact: true},
    {path: '/Registries/Cities', component: <Cities/>, exact: true},
    {path: '/Registries/Companies', component: <Companies/>, exact: true},
    {path: '/Registries/Counters', component: <Counters/>, exact: true},
    {path: '/Registries/Countries', component: <Countries/>, exact: true},
    {path: '/Registries/Conventions', component: <Conventions/>, exact: true},
    {path: '/Registries/Devices', component: <Devices/>, exact: true},
    {path: '/Registries/Districts', component: <Districts/>, exact: true},
    {path: '/Registries/Domains', component: <Domains/>, exact: true},
    {path: '/Registries/Exemptions', component: <Exemptions/>, exact: true},
    {path: '/Registries/ExemptionTypes', component: <ExemptionTypes/>, exact: true},
    {path: '/Registries/ItemizedLists', component: <ItemizedLists/>, exact: true},
    {path: '/Registries/MedicalServices', component: <MedicalServices/>, exact: true},
    {path: '/Registries/MedicalServiceTypes', component: <MedicalServiceTypes/>, exact: true},
    {path: '/Registries/Modalities', component: <Modalities/>, exact: true},
    {path: '/Registries/PaymentTypes', component: <PaymentTypes/>, exact: true},
    {path: '/Registries/Patients', component: <Patients/>, exact: true},
    {path: '/Registries/Profiles', component: <Profiles/>, exact: true},
    {path: '/Registries/ReportQueries', component: <Reports/>, exact: true},
    {path: '/Registries/Regions', component: <Regions/>, exact: true},
    {path: '/Registries/Rooms', component: <Rooms/>, exact: true},
    {path: '/Registries/Sponsors', component: <Sponsors/>, exact: true},
    {path: '/Registries/SponsorTypes', component: <SponsorTypes/>, exact: true},
    {path: '/Registries/SystemFunctions', component: <SystemFunctions/>, exact: true},
    {path: '/Registries/SystemParameters', component: <SystemParameters/>, exact: true},
    {path: '/Registries/SupplierTypes', component: <SupplierTypes/>, exact: true},
    {path: '/Registries/Users', component: <Users/>, exact: true},
]

const LocationRenderer = () => {

    const location = useLocation();

    const exactElement = registriesRoutes.find(l => l.exact === true && location.pathname === l.path);

    if (exactElement) return exactElement.component;

    const partialElement = registriesRoutes.find(l => l.exact === false && location.pathname.startsWith(l.path));

    return (partialElement) ? partialElement.component : <></>;

}

const RegistriesContainer = (props) => {

    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    return (
        <Card sx={loggedClasses.loggedContent}>
            <CardContent sx={loggedClasses.loggedCardContent}>
                <ContainerTitle
                    title={"Anagrafiche"}
                    key={"container-title-medical-registries"}
                />
                <LocationRenderer/>
            </CardContent>
        </Card>
    );
};

export default RegistriesContainer;
