import {Autocomplete, CardContent, Checkbox, FormControlLabel, Grid, Stack, TextField, useTheme} from "@mui/material";
import React, {useContext, useEffect, useState} from 'react';
import {emptyDoctor, isDataLoading, isDataNull} from "../../../../common";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {StackedItem} from "../../../../components/Layout/StackedItem";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AuthContext} from "../../../../context/AuthContext";
import {BookingContext} from "../../../../context/BookingContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";

const VisitLineAssignee = ({
                               assigneeID,
                               visitLines,
                               selectedVisitLines,
                               modalOpen,
                               closeClicked
                           }) => {

    const authContext = useContext(AuthContext);
    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [applyToAll, setApplyToAll] = useState(false);

    useEffect(() => {
        if (isDataNull(registriesContext.doctors) && !isDataLoading(registriesContext.doctors)) registriesContext.fetchDoctors();
    }, [registriesContext.doctors]);

    let doctors = [];
    if (registriesContext.doctors && !isDataLoading(registriesContext.doctors)) {
        registriesContext.doctors.map((item) => {
            const idValue = item.mUserID;
            const labelValue = item.mCompleteName;
            doctors.push({id: idValue, label: labelValue});
            return true;
        });
    }

    if ((selectedVisitLines.length > 0) && (!selectedDoctor) && assigneeID) {
        let assignee = doctors.find(d=> d.id===assigneeID)
        assignee = assignee ? assignee : doctors.find(d=> d.id===0);
        setSelectedDoctor(assignee);
    }

    const setAssegnee = () => {
        if (applyToAll === true) {
            bookingContext.setVisitLinesAssignee(visitLines, selectedDoctor.id).then(res => {
                closeClicked();
            });
        } else {
            bookingContext.setVisitLinesAssignee(selectedVisitLines, selectedDoctor.id).then(res => {
                closeClicked();
            });
        }
    }

    if (!modalOpen) return <></>

    if (isDataNull(registriesContext.doctors) || isDataLoading(registriesContext.doctors)) return <CircularWaiting/>

    if (selectedVisitLines.length <= 0) return <CircularWaiting/>

    const esame = (((selectedVisitLines[0].mPrestazione ?? {}).mDescrizione) ?? "");

    return (
        <CardModal
            modalOpen={modalOpen}
            onClose={closeClicked}
            title={"Assegnatario esame " + esame}
            size={"small"}
        >
            <CardContent className={loggedClasses.loggedCardContent}>
                <Grid
                    container
                    spacing={theme.spacing(4)}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                >
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            name={`auto-complete-doctor`}
                            key={`auto-complete-doctor`}
                            disablePortal
                            options={doctors}
                            value={selectedDoctor}
                            onChange={(event, item) => {
                                item = item ?? {id: 0};
                                setSelectedDoctor(doctors.find(d=> d.id===item.id));
                            }}
                            renderInput={(params) =>
                                <TextField variant="filled" label={"Medico assegnatario"} {...params}/>
                            }
                        />
                        <FormControlLabel
                            name={`form-control-label-assegnee`}
                            key={`form-control-label-assegnee`}
                            label={"Applica a tutti gli esami della visita"}
                            // helperText={error ? error.message : null}
                            control=
                                {
                                    <Checkbox
                                        name={`checkbox-assegnee`}
                                        key={`checkbox-assegnee`}
                                        checked={applyToAll ?? false}
                                        // indeterminate={checked[0] !== checked[1]}
                                        onChange={(e) => {
                                            let newValue = e.target.checked ?? false;
                                            setApplyToAll(newValue);
                                        }}
                                    />
                                }
                        />

                    </Grid>
                </Grid>
                <Stack direction={"row"} spacing={theme.spacing(4)}>
                    <StackedItem>
                        <ActionButton
                            color="primary"
                            variant="contained"
                            action={setAssegnee}
                            size="large"
                            icon="save"
                            iconSize="medium"
                            tooltip="Assegna"
                            label="Assegna"
                            disabled={selectedDoctor === 0}
                        />
                    </StackedItem>
                    <StackedItem>
                        <ActionButton
                            color="primary"
                            variant="contained"
                            action={closeClicked}
                            size="large"
                            icon="cancel"
                            iconSize="medium"
                            tooltip="Chiudi"
                            label="Chiudi"
                        />
                    </StackedItem>
                </Stack>
            </CardContent>
        </CardModal>
    )
};

export default VisitLineAssignee;