import {AddTwoTone} from "@mui/icons-material";
import CheckIconTwoTone from '@mui/icons-material/Check';
import {IconButton, Stack, Tooltip} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import React from 'react';
import {ButtonIcon} from "../Icons/ButtonIcon";

export const ToolButton = ({ color, variant, action, size, icon, iconSize, tooltip, disabled, ...otherProps}) => {

    const theme = useTheme();

    let isDisabled = disabled  ?? false;
    let sTooltip = tooltip ?? "";
    let hasTooltip = !isDisabled && sTooltip!=="";

    const getButton = () => {
        return (
            <IconButton
                color={color}
                variant={variant}
                onClick={(e)=> {e.preventDefault(); action();}}
                size={size}
                disabled={isDisabled}
                {...otherProps}
            >
                <ButtonIcon icon={icon} iconSize={iconSize}/>
            </IconButton>
        )
    }

    return (
        !hasTooltip ?
            <>{getButton()}</>
            :
            <Tooltip title={tooltip}>
                <div>{getButton()}</div>
            </Tooltip>
    )
}