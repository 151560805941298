import {CardContent, Grid, Stack, useTheme} from "@mui/material";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {AgGridReact} from "ag-grid-react";
import {columnTypes, defaultColDef, isDataNull} from "../../../../common";
import {CardModal} from "../../../../components/Layout/CardModal";
import React, {useContext, useEffect, useState} from "react";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AddButton} from "../../../../components/Grid/ActionsToolbar";
import ReactJson from "react-json-view";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {useForm} from "react-hook-form";
import {RecupContext} from "../../../../context/RecupContext";
import {FormDatePicker} from "../../../../components/Form/FormDatePicker";

export const RecupEuDiary = ({
                                 open,
                                 onClose,
                                 authorityId,
                                 hospitalId,
                                 siteId,
                                 executionUnitId,
                                 diaryId
                             }) => {

    const recupContext = useContext(RecupContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [diary, setDiary] = useState(null);
    const [diaryRecord, setDiaryRecord] = useState({});
    const [executionUnit, setExecutionUnit] = useState(null);
    const [executionUnitRecord, setExecutionUnitRecord] = useState(null);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        if (!isDataNull(diary)) return;
        recupContext.fetchExecutionUnit(executionUnitId).then((res) => {
            if (res.length <= 0) return;
            const eu = res[0].registry;
            setExecutionUnit(eu);
            const execUnitRec = {
                mOperationUnitID: eu.idOperationUnit,
                mExecutionUnitCode: eu.code,
                mExecutionUnitName: eu.name,
                mExecutionUnitDescription: eu.description,
                mExecutionUnitID: eu.id,
            }
            setExecutionUnitRecord(execUnitRec);
            if (diaryId === "0") {
                recupContext.fetchDiary(diaryId).then((res) => {
                    setDiary({});
                    const diaryRec = {
                        ...res,
                        mAuthorityID: authorityId,
                        mHospitalID: hospitalId,
                        mSiteID: siteId,
                        mOperationUnitID: execUnitRec.mOperationUnitID,
                        mExecutionUnitID: execUnitRec.mExecutionUnitID
                    }
                    setDiaryRecord(diaryRec);
                    resetDiary(diaryRec);
                });
            } else {
                recupContext.fetchDiary(diaryId).then((res) => {
                    setDiary(res);
                    const diaryRec = {
                        mAuthorityID: res.authority.id,
                        mHospitalID: res.hospital.id,
                        mOperationUnitID: res.operationUnit.id,
                        mSiteID: res.site.id,
                        mExecutionUnitID: res.executionUnit.id,
                        mAgendaName: res.name,
                        mAgendaCode: res.code,
                        mAgendaExternalCode: res.externalCode,
                        mAgendaID: res.id
                    }
                    setDiaryRecord(diaryRec);
                    resetDiary(diaryRec);
                });
            }

        });
    }, [])

    const {
        register: registerDiary,
        control: controlDiary,
        handleSubmit: handleSubmitDiary,
        reset: resetDiary,
        getValues: getValuesDiary
    } = useForm({defaultValues: diaryRecord});

    const onSubmitDiary = (data) => {
        const {mAgendaID, ...newData} = data;
        const body = (data.mAgendaID === "0") ? newData : {...data}
        setUpdating(true);
        recupContext.updateDiary(body).then(res => {
            setUpdating(false);
            onClose();
        });
    }

    const onErrorDiary = (errors, e) => {
        console.log(errors, e);
    }

    const diaryJSON = diary ?? {}

    const title = (diaryId==="0") ? "Nuova agenda" : ("Dettaglio agenda " + diaryId);

    return (
        <>
            <CardModal
                modalOpen={open}
                onClose={onClose}
                title={title}
                size={"small"}
                formFunctions={{
                    submitHandler: handleSubmitDiary,
                    onFormSubmit: onSubmitDiary,
                    onFormError: onErrorDiary
                }}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        {
                            !updating ?
                                <>

                                    <Grid item xs={12} md={6}>
                                        <FormTextBox
                                            label="ID"
                                            control={controlDiary}
                                            record={diaryRecord}
                                            register={registerDiary}
                                            field="mAgendaID"
                                            required={true}
                                            maxLength={100}
                                            disabled={((diaryRecord ?? {}).mAgenda ?? "0") === "0"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormTextBox
                                            label="Codice"
                                            control={controlDiary}
                                            record={diaryRecord}
                                            register={registerDiary}
                                            field="mAgendaCode"
                                            required={true}
                                            maxLength={20}
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormTextBox
                                            label="Codice esterno"
                                            control={controlDiary}
                                            record={diaryRecord}
                                            register={registerDiary}
                                            field="mAgendaExternalCode"
                                            required={true}
                                            maxLength={100}
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormTextBox
                                            label="Nome"
                                            control={controlDiary}
                                            record={diaryRecord}
                                            register={registerDiary}
                                            field="mAgendaName"
                                            required={true}
                                            maxLength={100}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h3>JSON DI RITORNO DIARY</h3>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ReactJson src={diaryJSON} theme="monokai" collapsed={true}/>
                                    </Grid>
                                </>
                                :
                                <CircularWaiting/>
                        }
                    </Grid>
                </CardContent>
            </CardModal>
        </>
    )
}
