import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../context/AuthContext";
import {BookingContext} from "../../../context/BookingContext";
import {Alert, Grid, Link, Stack, Typography, useTheme} from "@mui/material";
import {notLoggedClassNames} from "../../../theme/Styles";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {FormTextBox} from "../../../components/Form/FormTextBox";
import {FormSubmitButton} from "../../../components/Form/FormSubmitButton";
import {RegistriesContext} from "../../../context/RegistriesContext";
import {isDataLoading, isDataNull} from "../../../common";
import {CircularWaiting} from "../../../components/Waitings/CircularWaiting";
import {FormAutoComplete} from "../../../components/Form/FormAutoComplete";

const init_record = {
    mUserName: "",
    mFirstName: "",
    mLastName: "",
    mCompany: {
        mCodiceCliente: ""
    },
    mProfile: {
        mProfileID: 0
    },
    mMobilePhone: ""
}

const Register = (props) => {

    const authContext = useContext(AuthContext);
    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const notLoggedClasses = notLoggedClassNames(theme);
    let navigate = useNavigate();

    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState(null);
    const [record, setRecord] = useState(init_record);
    const [registering, setRegistering] = useState(false);

    useEffect(() => {
        if (isDataNull(registriesContext.profiles) && !isDataLoading(registriesContext.profiles)) registriesContext.fetchProfiles().then((res) => {});
    }, [registriesContext.profiles]);

    const {
        register,
        control,
        formState: {errors},
        handleSubmit,
    } = useForm({defaultValues: record});

    const onSubmit = data => {
        setRegistering(true);
        authContext.register(data).then((res) => {
            setSuccess(!res.responseAnyError);
            setMessage(res.responseMessage);
            setRegistering(false);
        })
    }

    const renderButtonText = () => {
        return (registering) ? "..." : "Registrati"
    }

    if (isDataNull(registriesContext.profiles) || isDataLoading(registriesContext.profiles)) return <CircularWaiting/>

    const registerProfiles = registriesContext.profiles.filter(p=> p.mAvailableToRegistration ?? false) ?? [];

    if (registerProfiles.length===0) return <CircularWaiting/>

    return (
        <>
            {
                (!message || (message && !success)) &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction={"row"} spacing={theme.spacing(2)} sx={notLoggedClasses.loginContainer}>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="baseline"
                                   sx={{mb: {xs: -0.5, sm: 0.5}}}>
                                <Typography variant="h5">Registrazione</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextBox
                                label="Nome"
                                control={control}
                                record={record}
                                register={register}
                                field="mFirstName"
                                required={true}
                                maxLength={50}
                                disabled={registering}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormTextBox
                                label="Cognome"
                                control={control}
                                record={record}
                                register={register}
                                field="mLastName"
                                required={true}
                                maxLength={50}
                                disabled={registering}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormTextBox
                                label="Codice Cliente"
                                control={control}
                                record={record}
                                register={register}
                                field="mCompany.mCodiceCliente"
                                required={true}
                                maxLength={50}
                                disabled={registering}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormAutoComplete
                                label="Profilo"
                                control={control}
                                record={record}
                                register={register}
                                field="mProfile"
                                joinField="mProfileID"
                                values={registerProfiles}
                                idField="mProfileID"
                                labelField="mProfileName"
                                placeholder={"Scegli il profilo con cui registrarti alla piattaforma"}
                                selectedValue="id"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormTextBox
                                label="E-mail/Username"
                                control={control}
                                record={record}
                                register={register}
                                field="mUserName"
                                required={true}
                                maxLength={50}
                                pattern={"email"}
                                disabled={registering}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormTextBox
                                label="Telefono"
                                control={control}
                                record={record}
                                register={register}
                                field="mMobilePhone"
                                required={true}
                                maxLength={50}
                                disabled={registering}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component={Link} href="/Login" variant="body1" sx={{textDecoration: 'none'}}
                                        color="primary" textAlign={"center"}>
                                Torna alla login
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign={"center"}>
                            <FormSubmitButton label={renderButtonText()} tooltip={"Login"} icon={"login"}
                                              disabled={false}
                                              loading={registering}/>
                        </Grid>
                    </Grid>
                </form>
            }
            {
                (message && success) &&
                <>
                    <Grid item xs={12}>
                        <Stack display={"flex"} direction={"column"} spacing={theme.spacing(2)}>
                            <Typography component={"p"}>
                                Grazie per esserti registrato alla piattaforma InCare.
                                Abbiamo inviato le credenziali all'indirizzo da te indicato in fase di registrazione.
                                Una volta effettuato l'accesso, ti consigliamo di effettuare il cambio password per
                                garantire la tua sicurezza.
                                Nel caso in cui tu onn abbia ricevuto l'email, puoi contattarci al seguente indirizzo di
                                posta elettronica:
                            </Typography>
                            <Typography component={"p"}>
                                <a href={"mailto:it-support@smbs.it"}>it-support@smbs.it</a>
                            </Typography>
                            <Typography component={"p"}>
                                Lo staff di InCare
                            </Typography>
                            <Typography component={Link} href="/Login" variant="body1" sx={{textDecoration: 'none'}} color="primary" textAlign={"center"}>
                                Torna alla login
                            </Typography>
                        </Stack>
                    </Grid>
                </>
            }
        </>


    )
}
export default Register;