import {CardContent, Grid, Stack, Typography, useTheme} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import React, {useContext, useEffect, useState} from 'react';
import {columnTypes, defaultColDef, isDataLoading, isDataNull} from "../../../../common";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {MedicalReportingContext} from "../../../../context/MedicalReportingContext";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {useForm} from "react-hook-form";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {CardModal} from "../../../../components/Layout/CardModal";
import {FormHtmlEditor} from "../../../../components/Form/FormHtmlEditor";

export const MedicalReportTemplates = (props) => {

    const medicalReportingContext = useContext(MedicalReportingContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    const [record, setRecord] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const {
        register,
        control,
        handleSubmit,
        reset
    } = useForm({defaultValues: record});

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(medicalReportingContext.templates)) medicalReportingContext.fetchMedicalReportTemplates().then((res) => {
            setDataLoaded(true);
        });
    }, [medicalReportingContext.templates]);

    //dettaglio

    const onSubmit = (data) => {
        medicalReportingContext.updateMedicalReportTemplate(data);
        setModalOpen(false);
    }

    const onError = (errors, e) => {
        console.log(errors, e);
    }

    const onClose = (event, reason) => {
        setModalOpen(false);

    }

    //griglia

    const addAction = () => {
        medicalReportingContext.fetchMedicalReportTemplate(0).then((res) => {
            setRecord(res);
            reset(res);
            setModalOpen(true);
        });
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            medicalReportingContext.fetchMedicalReportTemplate(selectedRow.mTemplateID).then((res) => {
                setRecord(res);
                reset(res);
                setModalOpen(true);
            });
        };

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
            />
        )
    }

    const [columns, setColumns] = useState([
        {headerName: "Titolo", field: "mTitle"},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    if (isDataNull(medicalReportingContext.templates) || isDataLoading(medicalReportingContext.templates)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Template referto</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{pb: theme.spacing(2)}}>
                        <Grid item xs={12}>
                            <Stack
                                direction={"row"}
                                justifyContent={"flex-end"}
                                spacing={theme.spacing(2)}
                                sx={{pt: theme.spacing(1)}}>
                                <AddButton addAction={addAction}/>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={medicalReportingContext.templates}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
            <CardModal
                modalOpen={modalOpen}
                onClose={onClose}
                title={"Dettaglio template"}
                size={"small"}
                formFunctions={{submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError}}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Titolo"
                                control={control}
                                record={record}
                                register={register}
                                field="mTitle"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormHtmlEditor
                                label="Testo del template"
                                control={control}
                                record={record}
                                register={register}
                                field="mText"
                                required={true}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardModal>
        </>
    )

}