import {FormControl} from "@mui/material";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment";
import React, {useContext} from 'react';
import {Controller} from "react-hook-form";
import {dateIsNull, normalizeDateTime} from "../../common";
import {CommonContext} from "../../context/CommonContext";

export const FormDatePicker = ({
                                   notFullWidth,
                                   noFormControl,
                                   required,
                                   openTo,
                                   disableFuture,
                                   fullWidth,
                                   ...props
                                }) => {


    let it = moment().locale('it');
    const commonContext = useContext(CommonContext);
    const openToOption = openTo ?? "day";
    const isDisableFuture = disableFuture ?? false;
    const isFullWidth = fullWidth ?? false;
    const isRequired = (required ?? false);

    const getOptions = () => {
        let options = {}
        if (isRequired !== null) {
            options.required = {};
            options.required.value = isRequired;
            if (isRequired === true) {
                options.required.message = "Campo richiesto";
            }
        }
        return options;
    }

    const isDisabled = () => {
        if (props.disabled) {
            return props.disabled;
        } else {
            return false;
        }
    }

    const getValue = (value) => {
        return dateIsNull(value) ? null : normalizeDateTime(value);
    }

    return (
        <FormControl fullWidth>
            <Controller
                name={props.field}
                control={props.control}
                render={({
                             field: {onChange, onBlur, value, name, ref},
                             fieldState: {invalid, isTouched, isDirty, error},
                             formState,
                         }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={it}>
                        <DatePicker
                            name={`datePicker-${name}`}
                            key={`datePicker-${name}`}
                            label={props.label}
                            inputFormat={props.format}
                            value={getValue(value)}
                            disabled={isDisabled()}
                            openTo={openToOption}
                            onChange={(newValue) => {
                                onChange(newValue);
                            }}
                            onBlur={onBlur}
                            slotProps={{
                                textField: {
                                    variant:"filled",
                                    name: 'datepicker-text-field-' + name,
                                    key: 'datepicker-text-field-' + name,
                                    inputRef: ref,
                                    required: isRequired,
                                    error: (!!error),
                                    helperText: error ? error.message : null,
                                    format: props.format
                                }
                            }}
                            fullWidth={isFullWidth}
                            sx={props.sx}
                            disableFuture={isDisableFuture}
                        />
                    </LocalizationProvider>
                )}
                {...props.register(props.field, getOptions())}
            />
        </FormControl>
    )

}