import React from "react";
import Iconify from "./Iconify";

const CustomIcon = ({
                        name,
                        width,
                        height,
                        ...otherProps
                    }) => {


    return (
        <Iconify icon={name} width={width ?? 25} height={height ?? 25} {...otherProps} />
    );
}

export default CustomIcon;