import {Grid, LinearProgress, useTheme} from "@mui/material";
import React from "react";

export const LinearWaiting = () => {

    const theme = useTheme();

    return (
        <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            direction={"column"}
        >
            <Grid item xs={12}>
                <LinearProgress color="primary"/>
            </Grid>
        </Grid>
    )
}