import {Divider, Menu, MenuItem, Typography, useTheme,} from '@mui/material';
import React, {useContext} from 'react';
import {AuthContext} from "../../context/AuthContext";
import {loggedClassNames} from "../../theme/Styles";
import * as PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import {IconLibraryItem} from "../Icons/IconLibraryItem";

function TypograProfilo(props) {
    return null;
}

TypograProfilo.propTypes = {children: PropTypes.node};
const ProfileMenu = (props) => {

    const authContext = useContext(AuthContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    let navigate = useNavigate();

    const profileClick = () => {
        navigate("/User/Profile?page=0");
        props.closeHandler();
    }

    const changePasswordClick = () => {
        navigate("/User/Profile?page=1");
        props.closeHandler();
    }

    const homePageClick = () => {
        navigate("/Home");
        props.closeHandler();
    }

    const supportPageClick = () => {
        window.open('http://support.smbs.it/', '_blank', 'noreferrer');
        props.closeHandler();
    }

    return (
        <Menu
            anchorEl={props.anchor}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}
            transformOrigin={{ vertical: 'top', horizontal: 'center'}}
            id="profile-menu"
            keepMounted
            open={Boolean(props.anchor)}
            onClose={props.closeHandler}
            sx={{p: theme.spacing(6)}}
        >
            <Typography variant={"h5"} gutterBottom sx={{p: theme.spacing(6)}}>{authContext.getLoggedUserCompleteName()}</Typography>
            <Divider></Divider>
            <MenuItem onClick={profileClick}>
                <IconLibraryItem name={"account"} iconSize={"medium"} sx={{ mr: theme.spacing(2), color: theme.palette.secondary.dark}}/>
                <Typography variant={"h6"}>Profilo</Typography>
            </MenuItem>
            <MenuItem onClick={changePasswordClick}>
                <IconLibraryItem name={"key"} iconSize={"medium"} sx={{ mr: theme.spacing(2), color: theme.palette.secondary.dark}}/>
                <Typography variant={"h6"}>Cambio password</Typography>
            </MenuItem>
            <MenuItem onClick={homePageClick}>
                <IconLibraryItem name={"desktop"} iconSize={"medium"} sx={{ mr: theme.spacing(2), color: theme.palette.secondary.dark}}/>
                <Typography variant={"h6"}>Home page</Typography>
            </MenuItem>
            <MenuItem onClick={supportPageClick}>
                <IconLibraryItem name={"question-mark"} iconSize={"medium"} sx={{ mr: theme.spacing(2), color: theme.palette.secondary.dark}}/>
                <Typography variant={"h6"}>Supporto</Typography>
            </MenuItem>
        </Menu>
    )
}

export default ProfileMenu;