import {AddTwoTone} from "@mui/icons-material";
import CheckIconTwoTone from '@mui/icons-material/Check';
import {Button, FormControl, Stack, Tooltip} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import React from 'react';
import {ButtonIcon} from "../Icons/ButtonIcon";

export const ActionButton = ({
                                 color,
                                 variant,
                                 action,
                                 size,
                                 icon,
                                 iconSize,
                                 label,
                                 tooltip,
                                 disabled,
                                 loading,
                                 fullWidth,
                                 vertical,
                                 sx,
                                 ...otherProps
                             }) => {

    const theme = useTheme();

    let isDisabled = disabled ?? false;
    let sLabel = label ?? "";
    let sTooltip = tooltip ?? "";
    let hasTooltip = !isDisabled && sTooltip !== "";
    let isLoading = loading ?? false;
    let sIcon = (isLoading) ? "loading" : icon;
    let isFullWidth = fullWidth ?? false;
    let isVertical = vertical ?? false;
    let dir = isVertical ? "column" : "row";

    sx = sx ?? {};

    const getButton = () => {
        return (
            <FormControl fullWidth={isFullWidth}>
                <Button
                    color={color}
                    variant={variant}
                    onClick={action}
                    size={size}
                    disabled={isDisabled || isLoading}
                    sx={{ ...sx }}
                    {...otherProps}
                >
                    <Stack direction={dir} spacing={theme.spacing(0.2)} alignItems={"center"}>
                        <ButtonIcon icon={sIcon} iconSize={iconSize}/>
                        {sLabel}
                    </Stack>
                </Button>
            </FormControl>
        )
    }

    return (
        !hasTooltip ?
            <>{getButton()}</>
            :
            <Tooltip title={tooltip}>
                <div>{getButton()}</div>
            </Tooltip>
    )
}