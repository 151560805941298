import {CardContent, Grid, TextField, Typography, useTheme} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {columnTypes, dateFormatter, defaultColDef, isDataLoading, JSONClone, JSONIsEmpty} from "../../../../common";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormDatePicker} from "../../../../components/Form/FormDatePicker";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {PaginationToolBar} from "../../../../components/Grid/PaginationToolBar";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";
import {SponsorDetail} from "./Sponsors";
import {CommonContext} from "../../../../context/CommonContext";

const PatientDetail = ({
                           modalOpen,
                           onClose,
                           handleSubmit,
                           onSubmit,
                           onError,
                           record,
                           control,
                           register,
                           sponsorID,
                           setSponsorID,
                           setSponsorByID,
                           hasSubTown,
                            getValues
                       })=> {

    const registriesContext = useContext(RegistriesContext);
    const commonContext = useContext(CommonContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const editSponsorDisabled = !(record.mFamilyDoctor && record.mFamilyDoctor.mSponsorID);

    return (
        <CardModal
            modalOpen={modalOpen}
            onClose={onClose}
            title={"Dettaglio paziente"}
            formFunctions={{submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError}}
        >
            <CardContent sx={loggedClasses.detailsContent}>
                <>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12} lg={4}>
                            <FormTextBox
                                label="CF"
                                control={control}
                                record={record}
                                register={register}
                                field="mCF"
                                required={true}
                                minLength={16}
                                maxLength={16}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormTextBox
                                label="Nome"
                                control={control}
                                record={record}
                                register={register}
                                field="mNomePaziente"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormTextBox
                                label="Cognome"
                                control={control}
                                record={record}
                                register={register}
                                field="mCognomePaziente"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <FormAutoComplete
                                label="Sesso"
                                control={control}
                                record={record}
                                register={register}
                                field="mSex"
                                joinField=""
                                values={registriesContext.sexItemizedListValues}
                                idField="mItemizedListValueID"
                                labelField="mValue"
                                selectedValue="label"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <FormDatePicker
                                label="Data nascita"
                                control={control}
                                record={record}
                                register={register}
                                field="mBirthDate"
                                required={false}
                                format={commonContext.getPlanningParameter("DATE_FORMAT_SHORT")}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormAutoComplete
                                label="Città di nascita"
                                control={control}
                                record={record}
                                register={register}
                                field="mBirthCity"
                                joinField="mCityID"
                                values={registriesContext.cities}
                                idField="mCityID"
                                labelField="mCompleteName"
                                selectedValue="id"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormAutoComplete
                                label="Stato di nascita"
                                control={control}
                                record={record}
                                register={register}
                                field="mBirthCountry"
                                joinField="mCountryID"
                                values={registriesContext.countries}
                                idField="mCountryID"
                                labelField="mCountryName"
                                selectedValue="id"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormTextBox
                                label="Indirizzo di residenza"
                                control={control}
                                record={record}
                                register={register}
                                field="mLiveAddress"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormAutoComplete
                                label="Città di residenza"
                                control={control}
                                record={record}
                                register={register}
                                field="mLiveCity"
                                joinField="mCityID"
                                values={registriesContext.cities}
                                idField="mCityID"
                                labelField="mCompleteName"
                                selectedValue="id"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormAutoComplete
                                label="Stato di residenza"
                                control={control}
                                record={record}
                                register={register}
                                field="mLiveCountry"
                                joinField="mCountryID"
                                values={registriesContext.countries}
                                idField="mCountryID"
                                labelField="mCountryName"
                                selectedValue="id"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormAutoComplete
                                label="Stato di cittadinanza"
                                control={control}
                                record={record}
                                register={register}
                                field="mCitizenOf"
                                joinField="mCountryID"
                                values={registriesContext.countries}
                                idField="mCountryID"
                                labelField="mCountryName"
                                selectedValue="id"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormAutoComplete
                                label="Municipio di appartenenza"
                                control={control}
                                record={record}
                                register={register}
                                field="mSubTown"
                                joinField="mMunicipioID"
                                values={registriesContext.townHalls}
                                idField="mMunicipioID"
                                labelField="mNome"
                                selectedValue="id"
                                required={hasSubTown()}
                                disabled={!hasSubTown()}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormTextBox
                                label="ASL di appartenenza"
                                control={control}
                                record={record}
                                register={register}
                                field="mASLName"
                                required={false}
                                maxLength={50}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <FormAutoComplete
                                label="Medico curante"
                                control={control}
                                record={record}
                                register={register}
                                field="mFamilyDoctor"
                                joinField="mSponsorID"
                                values={registriesContext.sponsors}
                                idField="mSponsorID"
                                labelField="mCompleteName"
                                selectedValue="id"
                                required={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <ActionButton
                                color="primary"
                                variant="contained"
                                action={() => {
                                    setSponsorID(0);
                                }}
                                size="small"
                                icon="new"
                                iconSize="small"
                                tooltip="Nuovo"
                                label=""
                                sx={loggedClasses.registriesToolbarSx}
                                disabled={false}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <ActionButton
                                color="primary"
                                variant="contained"
                                action={() => {
                                    const values = getValues();
                                    setSponsorID(values.mFamilyDoctor.mSponsorID)
                                }}
                                size="small"
                                icon="edit"
                                iconSize="small"
                                tooltip="Modifica"
                                label=""
                                sx={loggedClasses.registriesToolbarSx}
                                disabled={editSponsorDisabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <FormTextBox
                                label="Cellulare"
                                control={control}
                                record={record}
                                register={register}
                                field="mMobilePhone"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormTextBox
                                label="Fisso"
                                control={control}
                                record={record}
                                register={register}
                                field="mHomePhone"
                                required={false}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormTextBox
                                label="E-mail"
                                control={control}
                                record={record}
                                register={register}
                                field="mEmail"
                                required={false}
                                maxLength={100}
                                pattern={"email"}
                            />
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <FormTextBox
                                label="Note"
                                control={control}
                                record={record}
                                register={register}
                                field="mAdditionalInformation"
                                required={false}
                                maxLength={100}
                            />
                        </Grid>
                    </Grid>
                    <SponsorDetail recordID={sponsorID} setRecordID={setSponsorID} saveCallback={setSponsorByID}/>
                </>
            </CardContent>
        </CardModal>
    )
}

const Patients = () => {

    const registriesContext = useContext(RegistriesContext);
    const commonContext = useContext(CommonContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [record, setRecord] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [dataLoaded, setDataLoaded] =useState(false);
    const [currServerSideFilter, setCurrServerSideFilter] = useState(registriesContext.patientsServerSideFilter);
    const [sponsorID, setSponsorID] = useState(-1);

    const {
        watch,
        getValues,
        setValue,
        register,
        control,
        handleSubmit,
        reset
    } = useForm({defaultValues: record});

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(registriesContext.patients)) registriesContext.fetchPatients(true).then((res)=>{
            setDataLoaded(true);
        })
    }, [registriesContext.patients]);

    //dettaglio

    useEffect(() => {
        const subscription = watch(
            (value, {name, type}) => {
                if (type === 'change' && (name === 'mLiveCity' || name === 'mSubTown')) {
                    registriesContext.calculateASL(value).then((newRecord) => {
                        setValue('mLiveCity', newRecord.mLiveCity, {});
                        setValue('mSubTown', newRecord.mSubTown);
                        setValue('mASLName', newRecord.mASLName, {shouldValidate: true});
                        setRecord(getValues());
                    });
                }
            }
        );
        return () => subscription.unsubscribe();
    }, [watch]);

    const hasSubTown = () => {
        if (!record) return false;
        let currentPat = getValues();
        if (!currentPat) return false;
        if (!currentPat.mLiveCity) return false;
        if (currentPat.mLiveCity.hasOwnProperty('mWithSubTown')) return currentPat.mLiveCity.mWithSubTown;
        return !JSONIsEmpty(currentPat.mSubTown);
    }

    const onSubmit = (data) => {
        registriesContext.updatePatient(data);
        setModalOpen(false);
    }

    const onError = (errors, e) => {
        console.log(record);
        console.log(errors, e);
    }

    const onClose = (event, reason) => {
        setModalOpen(false);
        // 
    }

    //griglia

    const addAction = () => {
        registriesContext.fetchPatient(0).then((res) => {
            setRecord(res);
            reset(res);
            setModalOpen(true);
        });
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            registriesContext.fetchPatient(selectedRow.mPatientID).then((res) => {
                setModalOpen(true);
                reset(res);
                setRecord(res);
            });
        };

        const deleteAction = () => {
            alert(selectedRow.mPatientID);
        }

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
            />
        )
    }

    const onGridReady = (params) => {
        // setAgGridApi(params.api);
        // params.api.sizeColumnsToFit();
    }

    const [columns, setColumns] = useState([
        {headerName: "Cognome", field: "mCognomePaziente", type: "noFilter"},
        {headerName: "Nome", field: "mNomePaziente", type: "noFilter"},
        {headerName: "Data Nascita", field: "mBirthDate", valueFormatter: dateFormatter, type: "noFilter"},
        {headerName: "CF", field: "mCF", type: "noFilter"},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    const serverSideFilterChange = (field, newValue) => {
        let newObject = JSONClone(currServerSideFilter);
        newObject[field] = newValue;
        setCurrServerSideFilter(newObject)
    }

    const serverSideFilterResetClick = () => {
        registriesContext.fetchPatients(true);
    }

    const serverSideConfirmClick = () => {
        registriesContext.fetchPatients(true, currServerSideFilter);
    }

    const setSponsorByID = (pSponsorID) => {
        setValue('mFamilyDoctor.mSponsorID', pSponsorID, {});
        const newRecord = getValues();
        setModalOpen(true);
        reset(newRecord);
        setRecord(newRecord);
    }

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Pazienti</Typography>
                </Grid>
                <Grid item container>
                    <Grid item xs={11}>
                        <PaginationToolBar
                            currentPage={registriesContext.patientsCurrentPage}
                            pagesCount={registriesContext.patientsTotalPages}
                            pageSize={registriesContext.patientsPageSize}
                            setCurrentPage={registriesContext.setPatientsCurrentPage}
                            rowsCount={registriesContext.patientsTotalRows}
                        >
                            <TextField
                                variant="filled"
                                sx={{width: 250}}
                                label="Cognome"
                                type="text"
                                value={currServerSideFilter.mCognomePaziente}
                                onChange={(e) => {
                                    serverSideFilterChange("mCognomePaziente", e.target.value);
                                }}
                            />
                            <TextField
                                variant="filled"
                                sx={{width: 250}}
                                label="Nome"
                                type="text"
                                value={currServerSideFilter.mNomePaziente}
                                onChange={(e) => {
                                    serverSideFilterChange("mNomePaziente", e.target.value);
                                }}
                            />
                            <ActionButton
                                color="secondary"
                                variant="contained"
                                action={serverSideConfirmClick}
                                size="small"
                                icon="search"
                                iconSize="small"
                                tooltip="Cerca"
                                label="Cerca"
                                sx={loggedClasses.paginationToolbarHeightSx}
                            />
                            <ActionButton
                                color="secondary"
                                variant="contained"
                                action={serverSideFilterResetClick}
                                size="small"
                                icon="refresh"
                                iconSize="small"
                                tooltip="Annulla"
                                label="Annulla"
                                sx={loggedClasses.paginationToolbarHeightSx}
                            />
                        </PaginationToolBar>
                    </Grid>
                    <Grid item xs={12} md={1} container justifyContent={"flex-end"}>
                        <AddButton addAction={addAction}/>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={registriesContext.patients}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            rowSelection={'single'}
                            pagination={false}
                            onGridReady={onGridReady}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
            {
                !JSONIsEmpty(record) && modalOpen &&
                    <PatientDetail
                        modalOpen={modalOpen}
                        onClose={onClose}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        onError={onError}
                        record={record}
                        control={control}
                        register={register}
                        sponsorID={sponsorID}
                        setSponsorID={setSponsorID}
                        setSponsorByID={setSponsorByID}
                        hasSubTown={hasSubTown}
                    />
            }
        </>
    )
}

export {PatientDetail, Patients};