import moment from "moment/moment";
import React from 'react';
import {TextField} from "@mui/material";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export const InputDateTimePicker = ({
                                    label,
                                    format,
                                    disabled,
                                    fullWidth,
                                    value,
                                    setValue,
                                    openTo,
                                    views,
                                    disableFuture,
                                    ...props
                                }) => {

    let it = moment().locale('it');

    const isDisabled = disabled ?? false;
    const isFullWidth = fullWidth ?? false;
    const openToOption = openTo ?? "day";
    const viewsOptions = views ?? ['year', 'month','day'];
    const isDisableFuture = disableFuture ?? false;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={it}>
            <DateTimePicker
                label={label}
                inputFormat={format}
                value={value}
                disabled={isDisabled}
                openTo={openToOption}
                views={viewsOptions}
                onChange={(newValue) => {
                    setValue(newValue);
                }}
                renderInput={(params) => <TextField variant="filled" {...params}/>}
                fullWidth={isFullWidth}
                sx={props.sx}
                disableFuture={isDisableFuture}
            />
        </LocalizationProvider>
    )

}