import {Alert, Grid, Stack, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {downloadPDFListOfByte} from "../../../../common";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import UploadMedicalReportPDFModal from "../../../../components/MedicalReport/UploadMedicalReportPDFModal";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {MedicalReportingContext} from "../../../../context/MedicalReportingContext";
import {loggedClassNames} from "../../../../theme/Styles";

export const MedicalReportSign = (props) => {

    const medicalReportingContext = useContext(MedicalReportingContext);

    const theme = useTheme();
   const loggedClasses = loggedClassNames(theme);
    let navigate = useNavigate();

    const [medicalReport, setMedicalReport] = useState(null);
    const [medicalReportPDF, setMedicalReportPDF] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [uploadMessage, setUploadMessage] = useState(null);

    const documentID = medicalReportingContext.medicalReportState.medicalReport.mRefertoID;

    useEffect(() => {
        if (documentID === -1) {  return; }
        if (!medicalReport && medicalReportPDF === "") {
            setMedicalReport("loading");
            medicalReportingContext.fetchMedicalReport(documentID).then((res) => {
                setMedicalReport(res);
                medicalReportingContext.fetchMedicalReportPDF(documentID).then((res) => {
                    setMedicalReportPDF(res.mListOfBytes);
                });
            });
        }
    }, [documentID]);

    const downloadMedicalReport = () => {
        const name = "Referto_" + medicalReport.mRefertoID + ".pdf";
        downloadPDFListOfByte(medicalReportPDF, name);
    }

    if (!medicalReport || !medicalReportPDF) return <CircularWaiting/>

    const toggle = () => setModalOpen(!modalOpen);

    const cancelUpload = () => {
        medicalReportingContext.goToReports();
        navigate('/MedicalReporting/Reports');
    }

    return (
        <Grid
            container
            spacing={0}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
        >
            <Grid item xs={12}>
                <Typography variant="h4" component="h5">
                    Referto n° {medicalReport.mRefertoID} del {medicalReport.mDataRefertoString}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" component="h6">Firma digitale referto</Typography>
                {
                    (!isUploaded && uploadMessage) ? <Alert severity="error"> {uploadMessage} </Alert> : <></>
                }
                {
                    (isUploaded && uploadMessage) ? <Alert severity="success"> {uploadMessage} </Alert> : <></>
                }
            </Grid>
            <Grid item xs={12}>
                <Stack
                    direction={"row"}
                    spacing={theme.spacing(2)}
                    sx={{paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2)}}
                >
                    <ActionButton
                        color="secondary"
                        variant="contained"
                        action={downloadMedicalReport}
                        disabled={false}
                        icon={"download"}
                        iconSize={"small"}
                        label={"Scarica referto da firmare"}
                        size={"small"}
                        tooltip={"Scarica referto"}
                    />
                    <ActionButton
                        color="secondary"
                        variant="contained"
                        action={toggle}
                        disabled={false}
                        icon={"upload"}
                        iconSize={"small"}
                        label={"Carica referto firmato"}
                        size={"small"}
                        tooltip={"Carica referto firmato"}
                    />
                    <ActionButton
                        color="secondary"
                        variant="contained"
                        action={cancelUpload}
                        disabled={false}
                        icon={"cancel"}
                        iconSize={"small"}
                        label={"Esci e torna alla lista"}
                        size={"small"}
                        tooltip={"Esci e torna alla lista"}
                    />
                </Stack>
            </Grid>
            <UploadMedicalReportPDFModal
                toggle={toggle}
                modalOpen={modalOpen}
                mRefertoID={medicalReport.mRefertoID}
                setIsUploaded={setIsUploaded}
                setUploadMessage={setUploadMessage}
                uploadMessage={uploadMessage}
            />

        </Grid>
    )

}