import React, {useContext, useEffect, useState} from "react";
import {AccountingContext} from "../../../../context/AccountingContext";
import {AuthContext} from "../../../../context/AuthContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {CardContent, Grid, Stack, Tab, Tabs, Typography, useTheme} from "@mui/material";
import {
    columnTypes, dateColumnRenderer,
    defaultColDef,
    isDataLoading,
    isDataNull,
    JSONIsEmpty,
    moneyColumnRenderer, setDataLoading
} from "../../../../common";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {AgGridReact} from "ag-grid-react";
import {useForm} from "react-hook-form";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {IconLibraryItem} from "../../../../components/Icons/IconLibraryItem";
import {TabPanel} from "../../../../components/Tab/TabPanel";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormDatePicker} from "../../../../components/Form/FormDatePicker";
import {FormDiscreteSlider} from "../../../../components/Form/FormDiscreteSlider";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {CardModal} from "../../../../components/Layout/CardModal";

const Budget = (props) => {

    const accountingContext = useContext(AccountingContext);
    const authContext = useContext(AuthContext);
    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [budgets, setBudgets] = useState(null);
    const [record, setRecord] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    const {
        register,
        control,
        handleSubmit,
        getValues,
        reset,
        watch
    } = useForm({defaultValues: record});


    useEffect(() => {
        if (!isDataNull(budgets)) return;
        if (isDataLoading(budgets)) return;
        setDataLoading(setBudgets);
        accountingContext.fetchBudgets().then((res) => {
            setBudgets(res);
        });
    }, [budgets]);

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            accountingContext.fetchBudget(selectedRow.mBudgetID).then((res) => {
                setRecord(res);
                reset(res);
                setModalOpen(true);
            });
        };

        const deleteAction = () => {
            alert(selectedRow.mBudgetID);
        }

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
            />
        )
    }

    const [columns, setColumns] = useState([
        {headerName: "Anno", field: "mAnno"},
        {headerName: "Importo", field: "mImporto", cellRenderer: moneyColumnRenderer},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    const addAction = () => {
        accountingContext.fetchBudget(0).then((res) => {
            setRecord(res);
            reset(res);
            setModalOpen(true);
        });
    }

    const onGridReady = (params) => {
        // setAgGridApi(params.api);
        // params.api.sizeColumnsToFit();
    }

    //dettaglio

    const onSubmit = (data) => {
        accountingContext.updateBudget(data).then(res=>{
            setModalOpen(false);
            setBudgets(null);
        });

    }

    const onError = (errors, e) => {
        console.log(record);
        console.log(errors, e);
    }

    const onClose = (event, reason) => {
        setModalOpen(false);
        setRecord(null);
    }

    // budget lines grid

    const [budgetLinesColumns, setBudgetLinesColumns] = useState([
        {headerName: "Da", field: "mPeriodoDa", cellRenderer: dateColumnRenderer},
        {headerName: "A", field: "mPeriodoA", cellRenderer: dateColumnRenderer},
        {headerName: "Importo", field: "mImporto", cellRenderer: moneyColumnRenderer},
        {headerName: "Consumo", field: "mConsumo", cellRenderer: moneyColumnRenderer},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    if (isDataNull(budgets) || isDataLoading(budgets)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Budget</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{pb: theme.spacing(2)}}>
                        <Grid item xs={12}>
                            <Stack
                                direction={"row"}
                                justifyContent={"flex-end"}
                                spacing={theme.spacing(2)}
                                sx={{pt: theme.spacing(1)}}
                            >
                                <AddButton addAction={addAction}/>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="budgetGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={budgets}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={false}
                            rowSelection={'single'}
                            onGridReady={onGridReady}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
            {
                record && modalOpen &&
                <CardModal
                    modalOpen={modalOpen}
                    onClose={onClose}
                    title={"Dettaglio budget"}
                    formFunctions={{submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError}}
                >
                    <CardContent sx={loggedClasses.detailsContent}>
                        <Grid
                            container
                            direction={"row"}
                            spacing={theme.spacing(2)}
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                        >
                            <Grid item xs={12} md={4}>
                                <FormTextBox
                                    label="Anno"
                                    control={control}
                                    record={record}
                                    register={register}
                                    field="mAnno"
                                    required={true}
                                    maxLength={4}
                                    pattern={"year"}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormTextBox
                                    label="Importo"
                                    control={control}
                                    record={record}
                                    register={register}
                                    field="mImporto"
                                    required={true}
                                    maxLength={50}
                                    pattern={"money"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div id="budgetGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                                    <AgGridReact
                                        rowData={record.mListOfBudgetLine}
                                        defaultColDef={defaultColDef}
                                        columnDefs={budgetLinesColumns}
                                        columnTypes={columnTypes}
                                        suppressMovableColumns={true}
                                        suppressCellFocus={true}
                                        pagination={false}
                                        rowSelection={'single'}
                                        onGridReady={onGridReady}
                                        getRowHeight={(params) => gridRowHeight}
                                    >
                                    </AgGridReact>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardModal>

            }

        </>
    );
}

export default Budget;