import moment from "moment";
import React, {createContext, useContext, useEffect, useState} from 'react';
import {getCompleteName, JSONClone, JSONIsEmpty, setDataLoading} from "../common";
import * as webServices from "../services/WebServices";
import {CustomAlertContext} from "./AlertContext";
import {AuthContext} from "./AuthContext";

const MedicalReportingContext = createContext();

const initial_prev_state = {
    mode: 0,
    state: {},
    item: {}
}

const initial_medicalReporting_state = {
    mode: -1,
    loaded: false
}

const defaultMedicalReportsServerSideFilter = {
    mNomePaziente: "",
    mCognomePaziente: "",
    mCF: ""
}

const newTemplate = {
    mTemplateID: 0,
    mTitle: "Nuovo template",
    mText: "",
}

const MedicalReportingProvider = (props) => {

    const alertContext = useContext(CustomAlertContext);
    const authContext = useContext(AuthContext);

    const [medicalReportState, setMedicalReportState] = useState(JSONClone(initial_medicalReporting_state));
    const [medicalReports, setMedicalReports] = useState(null);
    const [medicalReportsCurrentPage, setMedicalReportsCurrentPage] = useState(1);
    const [medicalReportsTotalRows, setMedicalReportsTotalRows] = useState(0);
    const [medicalReportsTotalPages, setMedicalReportsTotalPages] = useState(0);
    const [medicalReportsPageSize, setMedicalReportsPageSize] = useState(0);
    const [medicalReportsServerSideFilter, setMedicalReportsServerSideFilter] = useState(JSONClone(defaultMedicalReportsServerSideFilter));
    const [visitLinesToReport, setVisitLinesToReport] = useState(null);
    const [templates, setTemplates] = useState(null);

    //0 - elenco referti
    //1 - worklist di refertazione
    //2 - referta linee visita
    //3 - PDF referto
    //4 - modifica referto
    //5 - firma referto
    //6 - elenco templates

    useEffect(() => {
        if (medicalReportsCurrentPage > 1) fetchMedicalReports(false).then(() => {});
    }, [medicalReportsCurrentPage]);

    const openMedicalReporting = (reportingItem, mode) => {
        let newState = {};
        let patient = {};
        switch (mode) {
            case 0:
                newState = {...medicalReportState}
                break
            case 1:
                newState = {...medicalReportState}
                break
            case 2:
                if (!reportingItem.visitLinesToReport) return;
                if (reportingItem.visitLinesToReport.length === 0) return;
                patient = reportingItem.visitLinesToReport[0].mVisita.mPatient;
                newState = {
                    ...medicalReportState,
                    medicalReport: null,
                    reportingVisitLines: reportingItem.visitLinesToReport,
                    reportingPatient: patient
                }
                break
            case 3:
                if (!reportingItem.medicalReport) return;
                if (JSONIsEmpty(reportingItem.medicalReport)) return;
                newState = {
                    ...medicalReportState,
                    medicalReport: reportingItem.medicalReport
                }
                break
            case 4:
                if (!reportingItem.medicalReport) return;
                if ((reportingItem.medicalReport.mRefertoID ?? 0) === 0) return;
                patient = reportingItem.medicalReport.mPatient;
                newState = {
                    ...medicalReportState,
                    medicalReport: reportingItem.medicalReport,
                    reportingPatient: patient
                }
                break
            case 5:
                if (!reportingItem.medicalReport) return;
                if (JSONIsEmpty(reportingItem.medicalReport)) return;
                newState = {
                    ...medicalReportState,
                    medicalReport: reportingItem.medicalReport
                }
                break
            case 6:
                newState = {...medicalReportState}
                break
            default:
                break
        }
        newState.mode = mode;
        newState.loaded = false;
        setMedicalReportState(newState);
    }

    const setMedicalReportingLoaded = (medicalReportingLoaded) => {
        let newValue = { ...medicalReportState, loaded: medicalReportingLoaded }
        setMedicalReportState(newValue);
    }

    const goToReports = () => {
        let newState = JSONClone(initial_medicalReporting_state);
        setMedicalReportState(newState);
        setMedicalReports(null);
    }

    const normalizeMedicalReport = (report) => {
        const shortDateTimeFormat = authContext.getSystemParameterValue("DATETIME_FORMAT_SHORT");
        report.mDataRefertoString = moment(report.mDataReferto).format(shortDateTimeFormat);
        report.mPatient = report.mVisita.mPatient;
        report.mPatientCompleteName = getCompleteName(report.mPatient.mNomePaziente, report.mPatient.mCognomePaziente);
        return report;
    }

    const fetchMedicalReports = async (bReset, newServerSideFilter) => {
        setDataLoading(setMedicalReports);
        const body = (newServerSideFilter) ?  newServerSideFilter : (bReset) ? defaultMedicalReportsServerSideFilter : medicalReportsServerSideFilter;
        body.mRequestedPage = (bReset ?? false) ? 1 : medicalReportsCurrentPage;
        setMedicalReportsServerSideFilter(body);
        let res = await webServices.wsGetMedicalReports(body, alertContext, true);
        if (res.responseAnyError) return;
        setMedicalReportsTotalRows(res.responseDataRowsCount ?? 0);
        setMedicalReportsTotalPages(res.responseDataPagesCount ?? 0);
        setMedicalReportsCurrentPage(res.responseDataCurrentPage ?? 0);
        setMedicalReportsPageSize(res.responseDataPageSize ?? 0);
        let resData = res.responseData;
        let recs = resData.map((item) => {
            return normalizeMedicalReport(item)
        });
        setMedicalReports(recs);
        return recs;
    };

    const resetMedicalReports = () => {
        setMedicalReports(null);
    };

    const fetchMedicalReport = async (recordId) => {
        if (recordId !== 0) {
            let res = await webServices.wsGetMedicalReports({mRefertoID: recordId}, alertContext, true);
            if (res.responseAnyError) return null;
            let resData = res.responseData;
            let rec = (resData.length !== 0) ? normalizeMedicalReport(resData[0]) : {};
            return rec;
        } else {
            const newRecord = {mRefertoID: 0}
            return newRecord;
        }
    };

    const fetchSecondaryMedicalReport = async (recordId) => {
        if (recordId !== 0) {
            let res = await webServices.wsGetMedicalReports({mRefertoID: recordId}, alertContext, true);
            if (res.responseAnyError) return null;
            let resData = res.responseData;
            let rec = (resData.length !== 0) ? normalizeMedicalReport(resData[0]) : {};
            return rec;
        } else {
            const newRecord = {mRefertoID: 0}
            return newRecord;
        }
    };

    const updateMedicalReport = (record) => {
        webServices.wsUpdateMedicalReport(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            resetMedicalReports();
        });
    };

    const fetchMedicalReportPDF = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetMedicalReportPDF({mContextualRecordID: recordId}, alertContext, true);
            if (res.responseAnyError) return null;
            let resData = res.responseData;
            return resData;
        } else {
            const newRecord = {mRefertoID: 0}
            return newRecord;
        }
    }

    const updateMedicalReportPDF = async (recordId, mListOfBytes) => {
        const body = {};
        body.mRefertoID = recordId;
        body.mListOfBytes = mListOfBytes;
        let res = await webServices.wsUploadMedicalReportPDF(body, alertContext, true);
        return res.responseAnyError;
    }

    const getMappedVisitLines = (vls) => {
        const shortDateTimeFormat = authContext.getSystemParameterValue("DATETIME_FORMAT_SHORT");
        if (!vls) return []
        if (vls.length === 0) return []
        return vls.map((vl) => {
            vl.mDataAppuntamentoString = moment(vl.mDataAppuntamento).format(shortDateTimeFormat);
            let assignedDoctor = vl.mAssignedDoctor ?? {}
            vl.mAssignedDoctorCompleteName = getCompleteName(assignedDoctor.mFirstName, assignedDoctor.mLastName);
            vl.mPatientCompleteName = getCompleteName(vl.mVisita.mPatient.mNomePaziente, vl.mVisita.mPatient.mCognomePaziente);
            return vl;
        })
    }

    const fetchVisitLinesToReport = async () => {
        let body = {};
        body.mStatoEsame = 'ESEGUITO';
        let res= await webServices.wsGetVisitLine(body, alertContext, true);
        if (res.responseAnyError) return;
        let resData = res.responseData;
        let recs = getMappedVisitLines(resData);
        setVisitLinesToReport(recs);
        return recs;
    }

    const resetVisitLinesToReport = () => {
        setVisitLinesToReport(null);
    };

    const createReportFromVisitLines = async (htmlContent, draft) => {
        let isDraft = draft ?? false;
        let body = {};
        body.mStato = (isDraft) ? "BOZZA" : "FINALE";
        body.mTXT_REFERTO = htmlContent;
        body.mVisita = {mVisitaID: medicalReportState.reportingVisitLines[0].mVisita.mVisitaID};
        body.mAuthor = {mUserID: authContext.getLoggedUserID()};
        let tmpListOfEsami = [];
        medicalReportState.reportingVisitLines.map((item) => {
            tmpListOfEsami.push({mEsameID: item.mEsameID});
            return item;
        });
        body.mListOfEsami = tmpListOfEsami;
        const res = await webServices.wsUpdateMedicalReport(body, alertContext, true);
        if (res.responseAnyError) return;
        return res.responseData;
    }

    const updateMedicalReportContent = async (body, htmlContent, draft) => {
        let isDraft = draft ?? false;
        body.mStato = (isDraft) ? "BOZZA" : "FINALE";
        body.mTXT_REFERTO = htmlContent;
        const res = await webServices.wsUpdateMedicalReport(body, alertContext, true);
        if (res.responseAnyError) return;
        return res.responseData;
    };

    const fetchPatientVisitLines = async (pPatientID) => {
        let body = {
            mFilterPatientID: pPatientID,
            mStatoEsame: "REFERTATO",
            mWithReport: true
        };
        let res = await webServices.wsGetVisitLine(body, alertContext, true);
        if (res.responseAnyError) return;
        let resData = res.responseData;
        return getMappedVisitLines(resData);;
    }

    const fetchMedicalReportTemplates = async () => {
        setDataLoading(setTemplates);
        const res = await webServices.wsGetMedicalReportTemplates({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setTemplates(recs);
        return recs;
    };

    const fetchMedicalReportTemplate = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetMedicalReportTemplates({mTemplateID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newTemplate;
        } else {
            return newTemplate;
        }
    };

    const updateMedicalReportTemplate = (record) => {
        webServices.wsUpdateMedicalReportTemplate(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchMedicalReportTemplates();
        });
    };

    return (
        <MedicalReportingContext.Provider
            value={{
                openMedicalReporting,
                setMedicalReportingLoaded,
                goToReports,
                medicalReportState,
                fetchMedicalReports,
                resetMedicalReports,
                medicalReportsServerSideFilter,
                medicalReportsCurrentPage,
                medicalReportsTotalPages,
                medicalReportsTotalRows,
                setMedicalReportsCurrentPage,
                medicalReportsPageSize,
                medicalReports,
                fetchMedicalReport,
                fetchSecondaryMedicalReport,
                fetchMedicalReportPDF,
                updateMedicalReportPDF,
                updateMedicalReport,
                updateMedicalReportContent,
                fetchVisitLinesToReport,
                resetVisitLinesToReport,
                visitLinesToReport,
                createReportFromVisitLines,
                fetchPatientVisitLines,
                fetchMedicalReportTemplates,
                templates,
                fetchMedicalReportTemplate,
                updateMedicalReportTemplate
            }}
        >
            {props.children}
        </MedicalReportingContext.Provider>
    );
}

export {MedicalReportingProvider, MedicalReportingContext};