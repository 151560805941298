import {Grid, Typography, useTheme} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import React, {useContext, useEffect, useState} from 'react';
import {columnTypes, dateTimeColumnRenderer, defaultColDef, isDataLoading, isDataNull} from "../../../../common";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {ManagementContext} from "../../../../context/ManagementContext";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {StatusBadge} from "../../../../components/Badges/StatusBadge";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {ActionsToolBar} from "../../../../components/Grid/ActionsToolbar";

export const Anomalies = (props) => {

    const managementContext = useContext(ManagementContext);
    const alertContext = useContext(CustomAlertContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    const [loaded, setLoaded] =useState(false);

    useEffect(() => {
        if ((!loaded) && !isDataLoading(managementContext.anomalies)) managementContext.fetchAnomalies().then((res) => {
            setLoaded(true);
        });
    }, [managementContext.anomalies]);

    const clearActionCancel = () => {
    }

    const clearReport = (id) => {
        managementContext.clearReport(id).then((res) => {
            setLoaded(false);
        })
    }

    const ToolBar = (props) => {
        let selectedRow = props.node.data;

        const clearAction = () => {
            alertContext.showConfirmDialog(
                "Bonifica",
                "Attenzione! Questo effettuerà una bonifica dei dati esistenti. Procedere?",
                clearActionCancel,
                () => {
                    clearReport(selectedRow.mReportID);
                    setLoaded(false);
                }
            );
        };

        const customActions = [
            {
                action: clearAction,
                disabled: false,
                icon: 'play',
                label: 'Bonifica',
                tooltip: 'Bonifica'
            }
        ];

        return <ActionsToolBar hasEdit={false} hasDelete={false} customActions={customActions} />;
    };

    const [columns, setColumns] = useState([
        { headerName: 'Codice', field: 'mReportCode' },
        { headerName: 'Nome', field: 'mLabel' },
        { headerName: 'Totale', field: 'mScalarValue' },
        { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolBar' }
    ]);

    if (isDataNull(managementContext.anomalies) || isDataLoading(managementContext.anomalies)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={managementContext.anomalies}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
        </>
    )

}