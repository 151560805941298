import React, {useContext} from 'react';
import {CustomAlertContext} from "../../context/AlertContext";
import {Alert, Snackbar, Tooltip, useTheme} from "@mui/material";
import {CustomErrorPopup} from "./CustomErrorPopup";
import ReactJson from "react-json-view";
import {loggedClassNames} from "../../theme/Styles";

export const CustomAlert = ({
                                handleHide,
                                requestId,
                                open,
                                sender,
                                message,
                                severity
                            }) => {

    const alertContext = useContext(CustomAlertContext);

    const handleClosing = (event, reason) => {
        if (reason === 'clickaway') return;
        alertContext.removeCustomRequest(requestId);
        alertContext.removeDataAlertRequest(requestId);
        if (handleHide) handleHide();
    };

    const handleAlertClose = (event) => {
        alertContext.removeCustomRequest(requestId);
        alertContext.removeDataAlertRequest(requestId);
        if (handleHide) handleHide();
    };

    let suffix = "";

    if (sender && severity !== "success") suffix += " durante la chiamata " + sender;

    const completeMessage = message + suffix;

    return (
        <Snackbar
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            open={open}
            autoHideDuration={3000}
            onClose={handleClosing}
            key={requestId}
        >
            <Tooltip title={completeMessage} arrow>
                <Alert
                    variant="filled"
                    severity={severity}
                    onClose={handleAlertClose}
                >
                    {message}
                </Alert>
            </Tooltip>
        </Snackbar>
    );
};

export const JSONAlert = ({
                                handleHide,
                                requestId,
                                open,
                                message,
                                json
                            }) => {

    const alertContext = useContext(CustomAlertContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const handleClosing = (event, reason) => {
        if (reason === 'clickaway') return;
        alertContext.removeJsonRequest(requestId);
        if (handleHide) handleHide();
    };

    const handleAlertClose = (event) => {
        alertContext.removeJsonRequest(requestId);
        if (handleHide) handleHide();
    };

    return (
        <Snackbar
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            open={open}
            autoHideDuration={4000}
            onClose={handleClosing}
            key={requestId}
            sx={{maxHeight: theme.spacing(250), overflowY: "scroll"}}
        >
            <Tooltip title={message} arrow>
                <Alert
                    variant="filled"
                    severity={"info"}
                    onClose={handleAlertClose}
                >
                    <ReactJson src={json ??  {}} theme="monokai" collapsed={true}/>
                </Alert>
            </Tooltip>
        </Snackbar>
    );
};

export const CustomAlerts = () => {

    const alertContext = useContext(CustomAlertContext);

    if (alertContext.customAlertQueue.length === 0) return <></>;

    return (
        <>
            {
                alertContext.customAlertQueue.map(item => {
                    return (
                        <CustomAlert
                            requestId={item.requestId}
                            open={item.open}
                            message={item.message}
                            severity={item.severity}
                            handleHide={item.handleHide}
                        />
                    )
                })
            }
        </>
    )
}

export const DataAlerts = () => {

    const alertContext = useContext(CustomAlertContext);

    if (alertContext.dataAlertQueue.length === 0) return <></>;

    return (
        <>
            {
                alertContext.dataAlertQueue.map(item => {
                    if (!item.isFinished) return <></>;
                    if (!item.responseData) return (
                        <CustomAlert
                            requestId={item.requestId}
                            sender={item.apiURL}
                            open={true}
                            message={"Risposta chiamata API non valida."}
                            severity={"error"}
                        />
                    )
                    if (item.responseAnyError === true) {
                        let message = "Errore server: " + item.responseCode + " " + item.responseMessage;
                        if ((item.responseShowInPopup ?? false) === false) {
                            return (
                                <CustomAlert
                                    requestId={item.requestId}
                                    sender={item.apiURL}
                                    open={true}
                                    message={message}
                                    severity={"error"}
                                />
                            )
                        } else {
                            return (
                                <CustomErrorPopup
                                    requestId={item.requestId}
                                    open={true}
                                    title={"Errore server"}
                                    message={item.responseMessage}
                                    handleConfirm={alertContext.customConfirmDialog.handleConfirm}
                                    handleHide={alertContext.removeDataAlertRequest}
                                />
                            )
                        }
                    }
                    if (item.responseCode !== 0) {
                        let message = "Errore server: " + item.responseCode + " " + item.responseMessage;
                        return (
                            <CustomAlert
                                requestId={item.requestId}
                                sender={item.apiURL}
                                open={true}
                                message={message}
                                severity={"error"}
                            />)
                    }
                    if (item.responseCode === 0 && !item.bShowOnlyError) {
                        let message = item.responseMessage;
                        return (
                            <CustomAlert
                                requestId={item.requestId}
                                sender={item.apiURL}
                                open={true}
                                message={message}
                                severity={"success"}
                            />
                        )
                    }
                })
            }
        </>
    )
}

export const JsonAlerts = () => {

    const alertContext = useContext(CustomAlertContext);

    if (alertContext.jsonAlertQueue.length === 0) return <></>;

    return (
        <>
            {
                alertContext.jsonAlertQueue.map(item => {
                    return (
                        <JSONAlert
                            requestId={item.requestId}
                            open={item.open}
                            message={item.message}
                            handleHide={item.handleHide}
                            sender={""}
                            json={item.data}
                        />
                    )
                })
            }
        </>
    )
}
