export default function Alert(theme) {
    return {
        MuiAlert: {
            styleOverrides: {
                root: {
                    minHeight: theme.spacing(2),
                    padding: theme.spacing(1.5)
                },
                standardError: {
                    backgroundColor: theme.palette.error.dark,
                    color: theme.palette.grey[100],
                    '& .MuiAlert-icon': {
                        color: theme.palette.grey[100] + " !important",
                    }
                }
            }
        }
    };
}
