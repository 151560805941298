import {CardContent, Grid, Stack, Typography, useTheme} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {columnTypes, defaultColDef, moneyColumnRenderer} from "../../../../common";
import {AccountingContext} from "../../../../context/AccountingContext";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {BookingContext} from "../../../../context/BookingContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridRowHeight, gridSizeStyle} from "../../../../theme/Styles";
import {FormActionBar} from "../../../../components/Form/FormActionBar";
import {CardModal} from "../../../../components/Layout/CardModal";
import {ActionsToolBar} from "../../../../components/Grid/ActionsToolbar";
import {FormTextBox} from "../../../../components/Form/FormTextBox";

const EditVisitLinePriceModal = ({
                            record,
                            priceOpen,
                            priceCancel,
                            priceSave
                        }) => {

    const theme = useTheme();

    const savePriceAndClose = (data) => {
        priceSave(data.mPrice);
    }

    const {
        register,
        control,
        handleSubmit,
        reset
    } = useForm({defaultValues: record}); //mode: 'onSubmit'

    return (
        <CardModal
            modalOpen={priceOpen}
            onClose={priceCancel}
            title={"Modifica prezzo linea fattura"}
            size={"xs"}
        >
            <CardContent>
                <form onSubmit={handleSubmit(savePriceAndClose)}>
                    <Grid
                        container
                        spacing={theme.spacing(2)}
                        direction={"row"}
                    >
                        <Grid item xs={4}>
                            <FormTextBox
                                label="Prezzo iniziale"
                                control={control}
                                record={record}
                                reset={reset}
                                register={register}
                                field="mEsame.mPrezzoListino"
                                required={true}
                                maxLength={50}
                                pattern={"money"}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormTextBox
                                label="Sconto applicato"
                                control={control}
                                record={record}
                                reset={reset}
                                register={register}
                                field="mEsame.mSconto"
                                required={true}
                                maxLength={50}
                                pattern={"money"}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormTextBox
                                label="Nuovo prezzo"
                                control={control}
                                record={record}
                                reset={reset}
                                register={register}
                                field="mPrice"
                                required={true}
                                maxLength={50}
                                pattern={"money"}
                            />
                        </Grid>
                        <Grid item xs={4} md={6}>
                            <FormActionBar cancelAction={priceCancel}/>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </CardModal>
    )

}

export const InvoiceDetails = (props) => {

    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);
    const alertContext = useContext(CustomAlertContext);
    const accountingContext = useContext(AccountingContext);

    const theme = useTheme();

    const [agGridApi, setAgGridApi] = useState(null);
    const [priceModalOpen, setPriceModalOpen] = useState(false);
    const [priceRow,setPriceRow] = useState(null);
    const gridRef = useRef();

    const {
        watch, register, control, formState: {errors}, handleSubmit, reset, getValues
    } = useForm({mode: 'onSubmit', defaultValues: accountingContext.fromInvoiceCreditDocument});

    useEffect(() => {
        if (!accountingContext.invoice) {
            accountingContext.fetchInvoice(accountingContext.accountingState.invoice.mInvoiceID).then(() => {});
        }
    }, [accountingContext.invoice]);

    useEffect(() => {
        reset(accountingContext.fromInvoiceCreditDocument);
        return () => {
        };
    }, [accountingContext.fromInvoiceCreditDocument]);

    const onError = (errors, e) => {
        console.log(errors, e);
    }

    useEffect(() => {
        const subscription = watch(
            (value, {name, type}) => {
                if (type === 'change') {
                    //niente
                    accountingContext.setFromInvoiceCreditDocument(value);
                }
            }
        );
        return () => subscription.unsubscribe();
    }, [watch]);

    const documentCreateCancel = (data) => {
        //niente
    }

    const getSelectedRows = () =>{
        return gridRef.current.api.getSelectedNodes().map(node => node.data) ?? [];
    }

    const onSubmit = (data) => {
        const selectedRows = getSelectedRows();
        alertContext.showConfirmDialog(
            "Conferma generazione nota di credito",
            "Stai generando la nota di credito. Sei sicuro? ",
            documentCreateCancel,
            () => {
                accountingContext.createCreditDocumentFromInvoice(data, selectedRows).then((res)=>{
                    let accountingItem = {creditDocument: res};
                    accountingContext.openAccounting(accountingItem, 5);
                });
            }
        );
    }

    const onPriceCancel = () => {
        setPriceRow(null);
        setPriceModalOpen(false);
    }

    const onPriceSave = (priceValue) => {
        const newPriceRow = {
            ...priceRow,
            mPrice: priceValue
        }
        accountingContext.updateInvoiceLine(newPriceRow).then(() => {
            accountingContext.fetchInvoice(accountingContext.accountingState.invoice.mInvoiceID).then(() => {
                setPriceRow(null);
                setPriceModalOpen(false);
            });

        })
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            setPriceRow(selectedRow);
            setPriceModalOpen(true);
        };

        return (
            <ActionsToolBar
                hasEdit editAction={editAction} editTooltip={"Modifica prezzo"}
                hasDelete={false}
            />
        )
    }

    const [columns] = useState([
        {
            headerName: "Descrizione riga",
            field: "mEsame.mPrestazione.mDescrizione",
            minWidth: '300',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
        },
        {
            headerName: "€ Importo",
            field: "mPrice",
            minWidth: '130',
            cellRenderer: moneyColumnRenderer,
            cellClass: 'number-cell'
        },
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    if (!accountingContext.accountingState.invoice) return <></>;
    if (!accountingContext.invoice) return <></>;
    if (!accountingContext.fromInvoiceCreditDocument) return <></>;

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4" component="h5">
                        Dettaglio fattura
                        n° {accountingContext.invoice.mInvoiceNumber} del {accountingContext.invoice.mInvoiceDateLabel}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <div id="myGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={accountingContext.invoice.mListOfInvoiceLine}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'multiple'}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Stack direction={"row"} spacing={theme.spacing(4)}>
                    <FormActionBar hasSubmit submitLabel={"Crea nota di credito"}
                                   submitTooltip={"Crea nota di credito da linee fattura selezionate"}
                                   hasCancel={false}/>
                </Stack>
            </form>
            {
                (priceRow) ? <EditVisitLinePriceModal record={priceRow} priceOpen={priceModalOpen} priceCancel={onPriceCancel} priceSave={onPriceSave}/>:<></>
            }
        </>
    );
};

export default InvoiceDetails;