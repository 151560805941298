import {Box, styled, useTheme} from "@mui/material";
import React from "react";

const StackedItem = (props) => {

    const theme = useTheme();

    const StyledBox = styled(Box)({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    });

    return (
        <StyledBox>
            {props.children}
        </StyledBox>
    )
}

export {StackedItem};
