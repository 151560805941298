// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

export const Typography = (theme,fontFamily) => ({
    htmlFontSize: 16,
    [theme.breakpoints.down("lg")]: {
        fontSize: 12
    },
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
        fontWeight: 500
    },
    h2:  {
        fontWeight: 500
    },
    h3:  {
        fontWeight: 500
    },
    h4:  {
        fontWeight: 500
    },
    h5:  {
        fontWeight: 500
    },
    h6:  {
        fontWeight: 500
    },
    caption: {
        fontWeight: 500
    },
    subtitle1: {
        fontWeight: 500
    },
    subtitle2: {
        fontWeight: 500
    }
});