import React, {createContext, useContext} from "react";
import {AuthContext} from "./AuthContext";
import moment from "moment";
import {daysWithName, empty_slot, intDateTimeFormat, JSONClone} from "../common";
import * as webServices from "../services/WebServices";
import {RegistriesContext} from "./RegistriesContext";
import {CustomAlertContext} from "./AlertContext";

const CommonContext = createContext();

const CommonProvider = (props) => {

    const alertContext = useContext(CustomAlertContext);
    const authContext = useContext(AuthContext);
    const registriesContext = useContext(RegistriesContext);

    const getPlanningParameter = (pParamName) => {
        let ret = null;
        switch (pParamName) {
            // case "SLOT_SIZE":
            //     ret = authContext.getSystemParameterValue("SLOT_SIZE")
            //     break;
            // case "OPENING_TIME":
            //     ret = authContext.getSystemParameterValue("OPENING_TIME")
            //     break;
            // case "CLOSING_TIME":
            //     ret = authContext.getSystemParameterValue("CLOSING_TIME")
            //     break;
            // case "DATE_FORMAT_SHORT":
            //     ret = authContext.getSystemParameterValue("DATE_FORMAT_SHORT")
            //     break;
            // case "DATE_FORMAT_LONG":
            //     ret = authContext.getSystemParameterValue("DATE_FORMAT_LONG")
            //     break;
            // case "TIME_FORMAT_SHORT":
            //     ret = authContext.getSystemParameterValue("TIME_FORMAT_SHORT")
            //     break;
            // case "TIME_FORMAT_LONG":
            //     ret = authContext.getSystemParameterValue("TIME_FORMAT_LONG")
            //     break;
            // case "DATETIME_FORMAT_SHORT":
            //     ret = authContext.getSystemParameterValue("DATETIME_FORMAT_SHORT")
            //     break;
            // case "DATETIME_FORMAT_LONG":
            //     ret = authContext.getSystemParameterValue("DATETIME_FORMAT_LONG")
            //     break;
            // case "ROOMS_PER_PAGE":
            //     ret = authContext.getSystemParameterValue("ROOMS_PER_PAGE")
            //     break;
            // case "AVAILABILITIES_DAYS_SLIDING_WINDOW":
            //     ret = authContext.getSystemParameterValue("AVAILABILITIES_DAYS_SLIDING_WINDOW")
            //     break;
            case "DATETIME_FORMAT_INT":
                ret = intDateTimeFormat
                break;
            default:
                ret = authContext.getSystemParameterValue(pParamName)
                break
        }
        return ret;
    }

    const getOpeningDateTime = (date) => {
        const openingTimeConfig = getPlanningParameter("OPENING_TIME");
        let dt = date.clone().startOf('day');
        return combineDateAndHours(dt, openingTimeConfig);
    }

    const getClosingDateTime = (date) => {
        const closingTimeConfig = getPlanningParameter("CLOSING_TIME");
        let dt = date.clone().startOf('day');
        return combineDateAndHours(dt, closingTimeConfig);
    }

    const combineDateAndHours = (date, hours) => {
        const shortDateFormat = getPlanningParameter("DATE_FORMAT_SHORT");
        const shortDateTimeFormat = getPlanningParameter("DATETIME_FORMAT_SHORT");
        date = date.clone().format(shortDateFormat);
        return moment(date + " " + hours, shortDateTimeFormat)
    }

    const getSlotDateLabel = (currentDate) => {
        currentDate = moment(currentDate, "YYYYMMDD");
        return currentDate.format(getPlanningParameter("DATE_FORMAT_SHORT")).toUpperCase();
    }

    const getSlotHourLabel = (currentDate) => {
        return currentDate.format(getPlanningParameter("TIME_FORMAT_SHORT")).toUpperCase();
    }

    const getSlotNameOfDayLabel = (currentDate) => {
        currentDate = moment(currentDate, "YYYYMMDD");
        return currentDate.format('dddd').toUpperCase();
    }

    const getPlanningRoomVisitLines = (room, visitLines) => {
        return (visitLines.length === 0) ? [] : visitLines.filter(vl => vl.mRoomID === room.mRoomID);
    }

    const calculateAvailabilitiesDays = async (fromDate, days) => {

        const shortDateFormat = getPlanningParameter("DATE_FORMAT_SHORT");

        let availsDays = [];

        let currentDay = fromDate.clone().startOf('day');
        days = (days>0) ? days - 1 : days;
        let endDay = fromDate.clone().add(days, 'day');

        while (currentDay <= endDay) {

            const nextDay = currentDay.clone().add(1, 'day');
            const dayNumber = currentDay.isoWeekday();

            let result = {};
            result.day = currentDay.clone().startOf('day');
            result.dayComparator = currentDay.clone().startOf('day').format("YYYYMMDD");
            // result.dateWeekDay = dayNumber;
            result.weekDayName = daysWithName.filter(d => d.mDayID === dayNumber)[0].mDayName;
            result.dayLabel = currentDay.format(shortDateFormat);
            availsDays.push(result);
            currentDay = nextDay;
        }

        return availsDays;
    }

    const calculateAvailabilities = async (fromDate, toDate, pMedicalServiceID, activeRooms, vls) => {

        const slotDurationConf = getPlanningParameter("SLOT_SIZE");
        const openingTimeConfig = getPlanningParameter("OPENING_TIME");
        const closingTimeConfig = getPlanningParameter("CLOSING_TIME");

        //slot occupati
        let initDate = fromDate.clone().startOf('day');
        let endDate = combineDateAndHours(toDate.clone().startOf('day'), closingTimeConfig);

        let avails = [];
        let availIndex = -1;
        let dt = null;
        let result = null;
        let avail = null;

        activeRooms.map((room, index) => {

            let currentSlot = initDate.clone();
            let roomVisitLines = getPlanningRoomVisitLines(room, vls);

            while (currentSlot < endDate) {

                let openingTime = combineDateAndHours(currentSlot.clone().startOf('day'), openingTimeConfig);
                let closingTime = combineDateAndHours(currentSlot.clone().startOf('day'), closingTimeConfig);

                let nextSlot = currentSlot.clone().add(slotDurationConf, 'minutes');

                if (currentSlot.isBetween(openingTime, closingTime, "minute", "[)") && currentSlot>=moment()) {

                    const timeBegin = currentSlot.clone();
                    const timeEnd = nextSlot.clone();
                    const vls = roomVisitLines.filter(vl => moment(vl.mDataAppuntamento) >= currentSlot && moment(vl.mDataAppuntamento) < nextSlot);

                    let newSlot = JSONClone(empty_slot);
                    newSlot.timeBegin = timeBegin;
                    newSlot.timeBeginLabel = timeBegin.format('HH');
                    newSlot.comparator = timeBegin.format("YYYYMMDDHHmmss");
                    newSlot.timeEnd = timeEnd;
                    newSlot.duration = slotDurationConf;
                    newSlot.isCurrent = moment().isBetween(currentSlot, nextSlot);
                    newSlot.isLast = (nextSlot >= closingTime);
                    newSlot.visitLines = vls;
                    newSlot.key = "slot-" + room.mRoomID + "-" + timeBegin.format(intDateTimeFormat);

                    if (vls.length === 0) {

                        avail = {};
                        avail.room = room;
                        avail.slot = newSlot;

                        dt = newSlot.timeBegin.clone().startOf('day');
                        availIndex = avails.findIndex(a => a.dayComparator === dt.format("YYYYMMDD"));

                        if (availIndex < 0) {
                            result = {};
                            result.day = dt;
                            result.dayComparator = dt.format("YYYYMMDD");
                            result.availabilities = [avail];
                            avails.push(result);
                        } else {
                            avails[availIndex].availabilities.push(avail);
                        }

                    }

                }

                currentSlot = nextSlot.clone();
            }

            return null;
        });

        return avails;
    }

    const calculateAvailabilitiesFromService = async (fromDate, pMedicalServiceID) => {

        const days =  getPlanningParameter("AVAILABILITIES_DAYS_SLIDING_WINDOW");
        const longDateTimeFormat = getPlanningParameter("DATETIME_FORMAT_LONG")

        //prestazione
        let medicalService = await registriesContext.fetchMedicalService(pMedicalServiceID);

        if (!medicalService) return [];
        let avails = [];

        let initDate = fromDate.clone().startOf('day');
        let endDate = initDate.clone().add(days, 'day');

        let body = {
            mBranca: {...medicalService.mBranca},
            mDateFrom: initDate.clone().format(longDateTimeFormat)
        };

        //slots
        let res = await webServices.wsGetPlanningSlots(body, alertContext, true);
        if (res.responseAnyError) return [];
        let planningSlots = res.responseData;

        let currentSlot = initDate.clone().startOf('day');

        while (currentSlot < endDate) {

            const slots = planningSlots
                .filter((slot)=> moment(slot.mDateFrom).startOf('day').isSame(currentSlot))
                .sort((a, b) => moment(a.mDateFrom) > moment(b.mDateFrom) ? 1 : -1);

            const result = {};
            result.day = currentSlot;
            result.availabilities = slots;

            avails.push(result);

            let nextSlot = currentSlot.clone().add(1, 'day');
            currentSlot = nextSlot.clone();
        }

        return avails;
    }

    const calculateFirstAvailability = async (pRoomID) => {
        let slotDurationConf = getPlanningParameter("SLOT_SIZE");
        const rooms = registriesContext.getActiveRooms();
        const room = rooms.find(r=> r.mRoomID === pRoomID );
        if (!room) return null;
        //slot occupati
        let body = {};
        let initDate = moment().startOf('day');
        let endDate = initDate.clone().add(1, 'day');
        body.mFilterFrom = initDate.clone().format("YYYYMMDD");
        body.mFilterTo = endDate.clone().format("YYYYMMDD");
        body.mPageSize = 10000;
        let res = await webServices.wsGetVisitLineShort(body, alertContext, true);
        let vls = res.responseData;
        if (res.responseAnyError) return [];
        let dt = null;

        const openingTimeConfig = getPlanningParameter("OPENING_TIME");
        const closingTimeConfig = getPlanningParameter("CLOSING_TIME");

        let currentSlot = initDate.clone();
        let roomVisitLines = getPlanningRoomVisitLines(room, vls);

        while (currentSlot < endDate) {

            let openingTime = combineDateAndHours(currentSlot.clone().startOf('day'), openingTimeConfig);
            let closingTime = combineDateAndHours(currentSlot.clone().startOf('day'), closingTimeConfig);
            let nextSlot = currentSlot.clone().add(slotDurationConf, 'minutes');

            if (currentSlot.isBetween(openingTime, closingTime, "minute", "[)") && currentSlot>=moment()) {

                const vls = roomVisitLines.filter(vl => moment(vl.mDataAppuntamento) >= currentSlot && moment(vl.mDataAppuntamento) < nextSlot);

                if (vls.length === 0) {

                    dt = currentSlot.clone();

                    return dt;
                }

            }

            currentSlot = nextSlot.clone();
        }

        return null;
    }

    return (
        <CommonContext.Provider
            value={{
                getPlanningParameter,
                combineDateAndHours,
                getOpeningDateTime,
                getClosingDateTime,
                getSlotDateLabel,
                getSlotHourLabel,
                getSlotNameOfDayLabel,
                calculateAvailabilitiesDays,
                calculateAvailabilities,
                calculateAvailabilitiesFromService,
                calculateFirstAvailability
            }}
        >
            {props.children}
        </CommonContext.Provider>
    );
}

export {CommonProvider, CommonContext};