import React, {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";
import {SquareButton} from "../Buttons/SquareButton";

export const SonicViewerButton = ({ openingMode, sonicUser, sonicPassword, patientID, ...other}) => {

    //sonicDatacenter + ".eu1.sonicpacs.com
    //http://193.70.87.196/viewer/#/list?id=admin&password=password

    const authContext = useContext(AuthContext);

    let baseSonicUrl = authContext.getSystemParameterValue("SONIC_URL") ?? "http://193.70.87.196";
    let user = sonicUser ?? "";
    let password = sonicPassword ?? "";
    openingMode = openingMode ?? 0;

    const buttonClicked = () => {
        let sonicUrl = baseSonicUrl;
        if (openingMode === 1) {   /* Apri PACS */
            sonicUrl += "/viewer/#/list?id=" + user + "&password=" + password;
        }
        else  if (openingMode === 2) { /* Apri paziente con pat_id */
            sonicUrl += "/viewer/#/viewer?id=" + user + "&password=" + password + "&patientid=" + patientID ?? "";
        }
        window.open(sonicUrl, "_blank");
    };

    return (
        <SquareButton
            color="primary"
            variant="contained"
            action={buttonClicked}
            size="extra-small"
            icon={"medical-service"}
            iconSize="small"
            tooltip="Apri viewer"
            label=""
            other
        />
    );
};
