import {Autocomplete, Grid, Stack, TextField, Typography, useTheme} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import React, {useContext, useEffect, useState} from 'react';
import {
    columnTypes,
    defaultColDef,
    isDataLoading,
    isDataNull,
    JSONClone,
    JSONIsEmpty,
    moneyColumnRenderer
} from "../../../../common";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {PaginationToolBar} from "../../../../components/Grid/PaginationToolBar";
import {AccountingContext} from "../../../../context/AccountingContext";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {BookingContext} from "../../../../context/BookingContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {useNavigate} from "react-router-dom";

export const Documents = () => {

    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);
    const alertContext = useContext(CustomAlertContext);
    const accountingContext = useContext(AccountingContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    let navigate = useNavigate();

    const [agGridApi, setAgGridApi] = useState(null);
    const [dataLoaded, setDataLoaded] =useState(false);
    const [currInvoicesServerSideFilter, setCurrInvoicesServerSideFilter] = useState(accountingContext.invoicesServerSideFilter);

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(accountingContext.invoices)) accountingContext.fetchInvoices(true).then((res) => {
            setDataLoaded(true);
        });
    }, [accountingContext.invoices]);

    useEffect(() => {
        if (!JSONIsEmpty(accountingContext.invoice)) accountingContext.fetchInvoice(0).then(() => {
        });
    }, []);

    useEffect(() => {
        if (!JSONIsEmpty(accountingContext.invoicePDF)) accountingContext.fetchInvoicePDF(0);
    }, [accountingContext.invoicePDF]);

    const onGridReady = (params) => {
        // setAgGridApi(params.api);
        // params.api.sizeColumnsToFit();
    }

    const ToolBar = (props) => {

        let record = props.node.data;

        const pdfDisabled = () => {
            return false
        }

        const pdfAction = () => {
            if (record.mInvoiceType === 1 || record.mInvoiceType === 2) {
                let accountingItem = {invoice: record};
                accountingContext.openAccounting(accountingItem, 2);
            } else if (record.mInvoiceType === 4) {
                let accountingItem = {creditDocument: record};
                accountingContext.openAccounting(accountingItem, 5);
            }
        };

        const detailsDisabled = () => {
            return !(record.mInvoiceType === 1  || record.mInvoiceType === 2 && record.mCanBeCancelled);
        }

        const detailsAction = () => {
            if (record.mInvoiceType === 1 || record.mInvoiceType === 2)  {
                let accountingItem = {invoice: record};
                accountingContext.openAccounting(accountingItem, 4);
            } else if (record.mInvoiceType === 4) {
                let accountingItem = {creditDocument: record};
                accountingContext.openAccounting(accountingItem, 6);
            }
        };

        const editDisabled = () => {
            return !(record.mInvoiceType === 1 || record.mInvoiceType === 2 && record.mCanBeCancelled);
        }

        const editAction = () => {
            let accountingItem = {invoice: record};
            accountingContext.openAccounting(accountingItem, 3);
        };

        return (
            <ActionsToolBar
                hasPDF pdfAction={pdfAction} pdfDisabled={pdfDisabled()}
                hasDetails detailsAction={detailsAction} detailsDisabled={detailsDisabled()} detailsTooltip={"Composizione"}
                hasEdit editAction={editAction} editDisabled={editDisabled()} editTooltip={"Modifica testata"}
            />
        )
    }

    const destinatarioRenderer = (props) => {
        console.log(props)
        if (props.data.mInvoiceType === 1) {
            return props.data.mPatient.mCompleteName
        }
        else if (props.data.mInvoiceType === 2) {
            return props.data.mCompany ? props.data.mCompany.mCompanyName : "NON IMPOSTATO"
        }
        else if (props.data.mInvoiceType === 4) {
            return props.data.mPatient.mCompleteName
        }
    }

    const cfRenderer = (props) => {
        console.log(props)
        if (props.data.mInvoiceType === 1) {
            return props.data.mPatient.mCF
        }
        else if (props.data.mInvoiceType === 2) {
            return props.data.mCompany ? props.data.mCompany.mCodiceFisale : "NON IMPOSTATO"
        }
        else if (props.data.mInvoiceType === 4) {
            return props.data.mPatient.mCF
        }
    }


    const [columns, setColumns] = useState([
        {headerName: "Numero", field: "mInvoiceNumber", maxWidth: 130},
        {headerName: "Data", field: "mInvoiceDateLabel"},
        {headerName: "Destinatario", field: "", cellRenderer: destinatarioRenderer},
        {headerName: "CF", field: "", cellRenderer: cfRenderer},
        {headerName: "Tipo", field: "mInvoiceTypeDescription"},
        {headerName: "Totale", field: "mTotalAdmount", cellRenderer: moneyColumnRenderer, maxWidth: 120},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    const serverSideFilterChange = (field, newValue) => {
        if ((newValue ?? "") === "") {
            accountingContext.fetchInvoices(true).then((res)=>{
                setCurrInvoicesServerSideFilter(accountingContext.invoicesServerSideFilter);
            });
        } else {
            let newObject = JSONClone(currInvoicesServerSideFilter);
            newObject[field] = newValue;
            setCurrInvoicesServerSideFilter(newObject);
            accountingContext.fetchInvoices(true, newObject).then((res)=>{});
        }
    }

    const addAction = () => {
        navigate('/Accounting/CreateInvoiceFromServices');
    }

    const currentYear = currInvoicesServerSideFilter.mYear;
    const invoicesYears = accountingContext.getInvoicesYears();


    if (isDataNull(accountingContext.invoices) || isDataLoading(accountingContext.invoices)) return <CircularWaiting/>
    if (!registriesContext.baseRegistriesLoaded) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Fatturazione </Typography>
                </Grid>
                <Grid item container>
                    <Grid item xs={8}>
                        <PaginationToolBar
                            currentPage={accountingContext.invoicesCurrentPage}
                            pagesCount={accountingContext.invoicesTotalPages}
                            pageSize={accountingContext.invoicesPageSize}
                            setCurrentPage={accountingContext.setInvoicesCurrentPage}
                            rowsCount={accountingContext.invoicesTotalRows}
                        >
                            <Autocomplete
                                sx={{width: 250}}
                                name={`auto-complete-year`}
                                key={`auto-complete-year`}
                                disablePortal
                                options={invoicesYears}
                                defaultValue={currentYear}
                                onChange={(event, item) => {
                                    serverSideFilterChange('mYear', item);
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        variant="filled"
                                        label={"Anno"}
                                        {...params}
                                    />
                                }
                            />
                        </PaginationToolBar>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack
                            direction={"row"}
                            justifyContent={"flex-end"}
                            spacing={theme.spacing(2)}
                            sx={{pt: theme.spacing(1)}}
                        >
                            <AddButton addAction={addAction} label={"Nuova fattura"} />
                        </Stack>

                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="invoicesGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={accountingContext.invoices}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={false}
                            rowSelection={'single'}
                            onGridReady={onGridReady}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>

        </>
    );
};

export default Documents;