import {CardContent, Grid, Stack, Typography, useTheme} from '@mui/material';
import {AgGridReact} from 'ag-grid-react';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    booleanMatcher,
    booleanRenderer,
    columnTypes,
    defaultColDef,
    isDataLoading,
    isDataNull
} from "../../../../common";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridExtraSizeStyle, gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";

const Devices = () => {

    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [record, setRecord] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [dataLoaded, setDataLoaded] =useState(false);

    const {
        register,
        control,
        handleSubmit,
        reset
    } = useForm({defaultValues: record});

    const [extraRecordId, setExtraRecordId] = useState(-1);
    const [extraRecord, setExtraRecord] = useState({});
    const [extraModalOpen, setExtraModalOpen] = useState(false);
    const [selectedMedicalServices, setSelectedMedicalServices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const gridRef = useRef();

    useEffect(() => {
        if (isDataNull(registriesContext.medicalServices) && !isDataLoading(registriesContext.medicalServices)) registriesContext.fetchMedicalServices();
    }, [registriesContext.medicalServices]);

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(registriesContext.devices)) registriesContext.fetchDevices().then((res)=>{
            setDataLoaded(true);
        })
    }, [registriesContext.devices]);

    //dettaglio

    const onSubmit = (data) => {
        registriesContext.updateDevice(data);
        setModalOpen(false);
    }

    const onError = (errors, e) => {
        console.log(record);
        console.log(errors, e);
    }

    const onClose = (event, reason) => {
        setModalOpen(false);

    }

    //griglia

    const addAction = () => {
        registriesContext.fetchDevice(0).then((res) => {
            setRecord(res);
            reset(res);
            setModalOpen(true);
        });
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            registriesContext.fetchDevice(selectedRow.mAETitleID).then((res) => {
                setRecord(res);
                reset(res);
                setModalOpen(true);
            });
        };

        const deleteAction = () => {
            registriesContext.deleteDevice(selectedRow);
        }

        const extraAction = () => {
            setExtraRecordId(selectedRow.mAETitleID);
            registriesContext.fetchDevice(selectedRow.mAETitleID).then((res) => {
                setExtraRecord(res);
                setExtraModalOpen(true);
            });
        }

        const duplicateDevice = () => {
            registriesContext.duplicateDevice(selectedRow.mAETitleID).then((res) => {
                registriesContext.fetchDevices();
                if (!res) return;
                registriesContext.fetchDevice(res.mAETitleID).then((res) => {
                    setRecord(res);
                    reset(res);
                    setModalOpen(true);
                });
            });
        }

        const customActions = [
            {
                action: extraAction,
                disabled: false,
                icon: "medical-service",
                tooltip: "Prestazioni collegate",
            },
            {
                action: duplicateDevice,
                disabled: false,
                icon: "copy",
                tooltip: "Duplica",
            }
        ];

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
                customActions={customActions}
            />
        )
    }

    const onGridReady = (params) => {
        // setAgGridApi(params.api);
        // params.api.sizeColumnsToFit();params.api.sizeColumnsToFit();
    }

    const [columns, setColumns] = useState([
        {headerName: "Nome", field: "mAETitleName"},
        // {headerName: "IP", field: "mAETitleIP"},
        {headerName: "Porta", field: "mAETitlePort"},
        {headerName: "Nome nodo", field: "mNodeName"},
        {headerName: "Stanza", field: "mRoom.mRoomName"},
        {headerName: "Prestazioni", field: "mPerformanceNumber"},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    // extra

    const onExtraClose = () => {
        setExtraModalOpen(false);
        setExtraRecordId(-1);
    }

    const columnsExtra = [
        {
            headerName: "Descrizione",
            field: "mDescrizione",
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true
        },
        {headerName: "Tipo", field: "mTipo.mTipoDescription"},
        {headerName: "Modality", field: "mModality.mModalityCode"},
        {headerName: "Branca", field: "mBranca.mBrancaName"},
        {headerName: "Attiva", field: "active", cellRenderer: booleanRenderer, filterParams: { textMatcher: booleanMatcher  }}
    ];

    const getActiveMedicalServices = () => {
        if (!extraRecord.mListOfPerformance) {
            return [];
        }
        if (!registriesContext.medicalServices) {
            return [];
        }
        //extraRecord.mListOfPerformance
        let activeMedicalServices = extraRecord.mListOfPerformance.map((activeMedicalService) => {
            return activeMedicalService.mPrestazioneID;
        });
        let all = registriesContext.medicalServices.map((medicalService) => {
            medicalService.active = (activeMedicalServices.indexOf(medicalService.mPrestazioneID) > -1);
            return medicalService;
        });
        return all;
    }

    const getSelectedMedicalServices = () =>{
        let selectedIds = gridRef.current.api.getSelectedNodes().map((node, index) => {
            return node.data.mPrestazioneID;
        });
        return selectedIds ?? [];
    }

    const AddSelectedButton = (props) => {

        const buttonClicked = () => {
            setIsLoading(true);
            let activeMedicalServices = extraRecord.mListOfPerformance;
            const selectedRows = getSelectedMedicalServices();
            selectedRows.map((item, index) => {
                let foundMedicalService = activeMedicalServices.find(ms => ms.mPrestazioneID === item);
                if (!foundMedicalService) {
                    let medicalService = {};
                    medicalService.mPrestazioneID = item;
                    activeMedicalServices.push(medicalService);
                }
            });
            extraRecord.mListOfPerformance = activeMedicalServices;
            registriesContext.updateDeviceMedicalServices(extraRecord).then((res) => {
                setExtraRecordId(-1);
                setExtraRecordId(extraRecordId);
                setIsLoading(false);
            })
        };

        return (
            <ActionButton
                color="info"
                variant="contained"
                action={buttonClicked}
                size="medium"
                icon="link"
                iconSize="medium"
                tooltip="Collega selezione"
                label="Collega selezione"
                {...props}
            />
        );

    }

    const RemoveSelectedButton = (props) => {

        const buttonClicked = () => {
            setIsLoading(true);
            let activeMedicalServices = extraRecord.mListOfPerformance;
            const selectedRows = getSelectedMedicalServices();
            selectedRows.map((item, index) => {
                let foundMedicalService = activeMedicalServices.findIndex(ms => ms.mPrestazioneID === item);
                if (foundMedicalService >= 0) {
                    //remove
                    activeMedicalServices.splice(foundMedicalService, 1);
                }
            });
            extraRecord.mListOfPerformance = activeMedicalServices;
            registriesContext.updateDeviceMedicalServices(extraRecord).then((res) => {
                setExtraRecordId(-1);
                setExtraRecordId(extraRecordId);
                setIsLoading(false);
            })
        };

        return (
            <ActionButton
                color="info"
                variant="contained"
                action={buttonClicked}
                size="medium"
                icon="link"
                iconSize="medium"
                tooltip="Scollega selezione"
                label="Scollega selezione"
                {...props}
            />
        );
    }

    if (isDataNull(registriesContext.medicalServices) || isDataLoading(registriesContext.medicalServices)) return <CircularWaiting/>
    if (isDataNull(registriesContext.devices) || isDataLoading(registriesContext.devices)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={11}>
                    <Typography variant="h4">Apparecchiature</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{pb: theme.spacing(2)}}>
                        <Grid item xs={12}>
                            <Stack
                                direction={"row"}
                                justifyContent={"flex-end"}
                                spacing={theme.spacing(2)}
                                sx={{pt: theme.spacing(1)}}>
                                <AddButton addAction={addAction}/>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={registriesContext.devices}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            onGridReady={onGridReady}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
            <CardModal
                modalOpen={modalOpen}
                onClose={onClose}
                title={"Dettaglio apparecchiatura"}
                size={"small"}
                formFunctions={{submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError}}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Nome"
                                control={control}
                                record={record}
                                register={register}
                                field="mAETitleName"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextBox
                                label="IP"
                                control={control}
                                record={record}
                                register={register}
                                field="mAETitleIP"
                                required={false}
                                maxLength={50}
                                pattern={"IP"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Porta"
                                control={control}
                                record={record}
                                register={register}
                                field="mAETitlePort"
                                required={true}
                                maxLength={50}
                                pattern={"number"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Descrizione tecnica"
                                control={control}
                                record={record}
                                register={register}
                                field="mTechDescription"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Nome nodo"
                                control={control}
                                record={record}
                                register={register}
                                field="mNodeName"
                                required={true}
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormAutoComplete
                                label="Stanza"
                                control={control}
                                record={record}
                                register={register}
                                field="mRoom"
                                joinField="mRoomID"
                                values={registriesContext.rooms}
                                idField="mRoomID"
                                labelField="mRoomName"
                                selectedValue="id"
                                required={true}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardModal>
            <CardModal
                modalOpen={extraModalOpen}
                onClose={onExtraClose}
                title={"Prestazioni dell'apparecchiatura"}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    {
                        (!isLoading) ?
                            <Grid
                                container
                                spacing={theme.spacing(2)}
                                direction={"row"}
                            >
                                <Grid item xs={12} md={2}>
                                    {AddSelectedButton()}
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {RemoveSelectedButton()}
                                </Grid>
                                <Grid item xs={12}>
                                    <div id="agReactGrid" className="ag-theme-alpine" style={gridExtraSizeStyle}>
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={getActiveMedicalServices()}
                                            defaultColDef={defaultColDef}
                                            columnDefs={columnsExtra}
                                            columnTypes={columnTypes}
                                            suppressMovableColumns={true}
                                            suppressCellFocus={true}
                                            pagination={true}
                                            rowSelection={'multiple'}
                                            getRowHeight={(params) => gridRowHeight}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            :
                            <CircularWaiting></CircularWaiting>
                    }
                </CardContent>
            </CardModal>
        </>
    )
}

export default Devices;