import React, {useContext, useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {loggedClassNames} from "../../theme/Styles";

export const PDFViewer = ({dataType, dataContent}) => {
    {/* <embed*/}
    {/*    type="application/pdf"*/}
    {/*    src={'data:application/pdf;base64,' + medicalReportPDF}*/}
    {/*    style={loggedClasses.documentPreview}*/}
    {/* >*/}
    {/* </embed>*/}

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    return (
        <object data={dataContent} type={dataType} style={loggedClasses.documentPreview}>
            <p>
                Il tuo browser non riesce a visualizzare il PDF.
            </p>
            <p>
                <a href={dataContent}>Clicca qui per scaricare il file.</a>
            </p>
        </object>
    );
}
