import {CardContent, Grid, Stack, useTheme} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    booleanMatcher,
    booleanRenderer,
    columnTypes,
    dateFormatter,
    defaultColDef,
    isDataLoading,
    isDataNull,
    JSONClone
} from "../../../../common";
import {StatusBadge} from "../../../../components/Badges/StatusBadge";
import {FormCheckBox} from "../../../../components/Form/FormCheckBox";
import {FormDatePicker} from "../../../../components/Form/FormDatePicker";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {ManagementContext} from "../../../../context/ManagementContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import '../../../../theme/agGridStyle.css';
import {gridExtraSizeStyle, gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";
import {CommonContext} from "../../../../context/CommonContext";
import {useNavigate} from "react-router-dom";
import {PaginationToolBar} from "../../../../components/Grid/PaginationToolBar";
import {PageContainer} from "../../../../components/Layout/PageContainer";
import {SmsModulePreview} from "./SmsOrderPreview";
import {AuthContext} from "../../../../context/AuthContext";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {TextGridPopover} from "../../../../components/Grid/TextPopover";

const defaultServerSideFilter = {}

const defaultServerSideStatus = {
    totalRows: 0,
    totalPages: 0,
    pageSize: 0,
    currentPage: 0,
    defaultFilter: JSONClone(defaultServerSideFilter),
    currentFilter: JSONClone(defaultServerSideFilter)
}

const completedStatus = "EVASO";

export const SmsCampaigns = (props) => {

    const registriesContext = useContext(RegistriesContext);
    const commonContext = useContext(CommonContext);
    const managementContext = useContext(ManagementContext);
    const authContext = useContext(AuthContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    let navigate = useNavigate();

    const [smsCredit, setSmsCredit] = useState(null);
    const [campaignsModalOpen, setCampaignsModalOpen] = useState(false);
    const [smsCampaign, setSmsCampaign] = useState({});
    const [campaignModalOpen, setCampaignModalOpen] = useState(false);
    const [agGridApi, setAgGridApi] = useState(null);
    const [queueRecordId, setQueueRecordId] = useState(-1);
    const [queueRecords, setQueueRecords] = useState([]);
    const [queueModalOpen, setQueueModalOpen] = useState(false);
    const [serverSideStatus, setServerSideStatus] = useState(JSONClone(defaultServerSideStatus));
    const [ordersModalOpen, setOrdersModalOpen] = useState(false);
    const [smsOrder, setSmsOrder] = useState({});
    const [orderModalOpen, setOrderModalOpen] = useState(false);
    const [campaignsDataLoaded, setCampaignsDataLoaded] =useState(false);
    const [ordersDataLoaded, setOrdersDataLoaded] =useState(false);
    const [orderToPrint, setOrderToPrint] =useState(-1);

    const anchorCellRef = useRef(null);

    const isAdmin = authContext.isLoggedUserAdmin();

    useEffect(() => {
        if (isDataNull(registriesContext.companies) && !isDataLoading(registriesContext.companies)) registriesContext.fetchCompanies();
    }, [registriesContext.companies]);

    useEffect(() => {
        if (!campaignsDataLoaded || (isDataNull(managementContext.smsCampaigns) && !isDataLoading(managementContext.smsCampaigns))) {
            managementContext.fetchSmsCampaigns().then((res) => {
                setCampaignsDataLoaded(true);
            });
        }
    }, [managementContext.smsCampaigns]);

    useEffect(() => {
        if (!ordersDataLoaded || (isDataNull(managementContext.smsOrders) && !isDataLoading(managementContext.smsOrders))) {
            managementContext.fetchSmsOrders().then(()=>{
                setOrdersDataLoaded(true);
            });
        }
    }, [managementContext.smsOrders]);

    useEffect(() => {
        if (isDataNull(smsCredit) && !isDataLoading()) {
            setSmsCredit("loading");
            managementContext.fetchSmsCredit().then((res) => {
                setSmsCredit(res);
            });
        }
    }, [smsCredit]);

    useEffect(() => {
        if (isDataNull(registriesContext.smsPackagesItemizedListValues) && !isDataLoading(registriesContext.smsPackagesItemizedListValues)) registriesContext.fetchSmsPackagesItemizedListValues();
    }, [registriesContext.smsPackagesItemizedListValues]);

    //crediti sms

    let smsCreditRows = [];
    if (!isDataNull(smsCredit) && !isDataLoading()) {
        smsCreditRows = [
            {id: 0, label: "SMS acquistati", value: smsCredit.total_bought},
            {id: 1, label: "SMS inviati", value: smsCredit.total_sent},
            {id: 2, label: "SMS residui", value: smsCredit.remaining_credits},
        ]
    }

    const [columnsCredits, setColumnsCredits] = useState([
        {headerName: "", field: "label", type: "noFilter"},
        {
            headerName: "#", field: "value", type: "statusColumn", maxWidth: 150,
            cellRenderer: (props) => {
                return (
                    <StatusBadge label={props.node.data.value} color={"success"}/>
                )
            },
        },
    ]);

    //ordini

    const {
        register: registerOrder,
        control: controlOrder,
        handleSubmit: handleSubmitOrder,
        reset: resetOrder
    } = useForm({defaultValues: smsOrder});

    const openOrders = () => {
        setOrdersModalOpen(true);
    }

    const onOrdersClose = (event, reason) => {
        setOrdersModalOpen(false);
    }

    const addSmsOrder = () => {
        managementContext.fetchSmsOrder(0).then((res) => {
            setSmsOrder(res);
            resetOrder(res);
            setOrderModalOpen(true);
        });
    }

    const smsOrdersContextMenuActions = [
        {action: addSmsOrder, icon:"add", label:"Nuovo ordine SMS" },
    ];

    const SmsOrdersToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            managementContext.fetchSmsOrder(selectedRow.mSMSOrderID).then((res) => {
                setSmsOrder(res);
                resetOrder(res);
                setOrderModalOpen(true);
            });
        }

        const customAction = () => {
            setOrderToPrint(selectedRow.mSMSOrderID);
        }

        const changeOrderState = (data, newStatus) => {
            data.mStatus = newStatus;
            managementContext.updateSmsOrder(data).then((res) => {

            })
        }

        const editDisabled = () => {
            return (selectedRow.mStatus===completedStatus);
        }

        const approveDisabled = () => {
            return (selectedRow.mStatus===completedStatus);
        }

        const approveAction = () =>{
            changeOrderState(selectedRow, completedStatus);
        }

        let adminActions = [
            {
                action: approveAction,
                disabled: approveDisabled(),
                icon: "check",
                tooltip: "Evadi",
            }
        ];

        let customActions = [
            {
                action: customAction,
                disabled: false,
                icon: "print",
                tooltip: "Stampa ordine",
            }
        ];

        if (isAdmin) {
            customActions = [
                ...customActions,
                ...adminActions
            ]
        }

        return (
            <ActionsToolBar
                hasEdit editAction={editAction} editDisabled={editDisabled()}
                customActions={customActions}
            />
        )
    }
    const smsOrderStatusRenderer = (props) => {
        const status = (props.node.data.mStatus ?? "");
        const color = (status.toUpperCase() === completedStatus) ? "success" : "error";
        return (
            <StatusBadge label={status} color={color} fullWidth/>
        )
    }

    const [ordersColumns, setOrdersColumns] = useState([
        {headerName: "Ordine Numero", field: "mSMSOrderNumber"},
        {headerName: "Data", field: "mSMSOrderDate", cellRenderer: dateFormatter},
        {headerName: "Numero SMS", field: "mNumberOfSMS"},
        {headerName: "Stato", field: "mStatus", cellRenderer: smsOrderStatusRenderer},
        {headerName: "Azioni", cellRenderer: SmsOrdersToolBar, type: "toolBar"}
    ]);

    const onOrderSubmit = (data) => {
        managementContext.updateSmsOrder(data).then((res) => {
            setOrderModalOpen(false);
        })
    }

    const onOrderError = (errors, e) => {
        console.log(errors, e);
    }

    const onOrderClose = (event, reason) => {
        setOrderModalOpen(false);
    }

    //campagne

    const {
        register: registerCampaign,
        control: controlCampaign,
        handleSubmit: handleSubmitCampaign,
        reset: resetCampaign
    } = useForm({defaultValues: smsCampaign});

    const openCampaigns = () => {
        setCampaignsModalOpen(true);
    }

    const onCampaignsClose = (event, reason) => {
        setCampaignsModalOpen(false);
    }

    //dettaglio campagna

    const onCampaignSubmit = (data) => {
        managementContext.updateSmsCampaign(data).then((res) => {
            setCampaignModalOpen(false);
        })
    }

    const onCampaignError = (errors, e) => {
        console.log(errors, e);
    }

    const onCampaignClose = (event, reason) => {
        setCampaignModalOpen(false);
    }

    //griglia mAccountID

    const configClicked = () => {
        navigate("/Registries/SystemParameters?page=SMS");
    }

    const addCampaignAction = () => {
        managementContext.fetchSmsCampaign(0).then((res) => {
            setSmsCampaign(res);
            resetCampaign(res);
            setCampaignModalOpen(true);
        });
    }

    const CampaignsToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            managementContext.fetchSmsCampaign(selectedRow.mServiceID).then((res) => {
                setSmsCampaign(res);
                resetCampaign(res);
                setCampaignModalOpen(true);
            });
        };

        const customAction = () => {
            setQueueRecordId(selectedRow.mServiceID);
            managementContext.fetchSmsQueueItems(selectedRow.mServiceID, true, serverSideStatus, setServerSideStatus).then((res) => {
                setQueueRecords(res);
                setQueueModalOpen(true);
            });
        }

        const customActions = [{
            action: customAction,
            disabled: false,
            icon: "list",
            tooltip: "Coda messaggi",
        }];

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                customActions={customActions}
            />
        )
    }

    const [campaignsColumns, setCampaignsColumns] = useState([
        {headerName: "Company", field: "mCompany.mCompanyName"},
        {headerName: "Codice", field: "mServiceCode"},
        {headerName: "Descrizione", field: "mServiceDescription"},
        {headerName: "Attivo", field: "mActive", cellRenderer: booleanRenderer, filterParams: { textMatcher: booleanMatcher  }},
        {headerName: "Preparata", field: "mReady", cellRenderer: booleanRenderer, filterParams: { textMatcher: booleanMatcher  }},
        {headerName: "Attiva dal", field: "mScheduledBy", cellRenderer: dateFormatter},
        {headerName: "Record di sistema", field: "mSystemRecord", cellRenderer: booleanRenderer, filterParams: { textMatcher: booleanMatcher  }},
        {
            headerName: "SMS inviati",
            cellRenderer: (props) => {
                return (
                    <StatusBadge label={props.node.data.mTotalSMSSent} color={"success"}/>
                )
            },
            type: "statusColumn",
            maxWidth: 150
        },
        {headerName: "Azioni", cellRenderer: CampaignsToolBar, type: "toolBar"}
    ]);

    // queue

    const onQueueClose = () => {
        setQueueModalOpen(false);
        setQueueRecordId(-1);
    }

    const smsSendingStatusRenderer = (props) => {
        const color = (props.node.data.mStatus.toUpperCase() === "INVIATO") ? "success" : "error";
        return (
            <StatusBadge label={props.node.data.mStatus} color={color} fullWidth/>
        )
    }

    const getSmsText = async (record) => {
        return await managementContext.getSmsText(record);
    }


    const smsPopoverRenderer = (params) => {
        const getTextFunc = async ()=> {return getSmsText(params.data);};
        return <TextGridPopover ref={anchorCellRef} getTextFunc={getTextFunc}/>
    };

    const [columnsQueue, setColumnsQueue] = useState([
        {headerName: "Data invio", field: "mSentDate", cellRenderer: dateFormatter},
        {headerName: "Cellulare", field: "mTargetPhone"},
        {headerName: "Nome", field: "mNome"},
        {headerName: "Cognome", field: "mCognome"},
        {headerName: "Messaggio", field: "mMessageText", cellRenderer: smsPopoverRenderer},
        {headerName: "Stato", field: "mStatus", cellRenderer: smsSendingStatusRenderer},
    ]);

    const serverSideSetCurrentPage = (page) => {
        const newServerSideStatus = {
            ...serverSideStatus,
            currentPage: (page),
        }
        managementContext.fetchSmsQueueItems(queueRecordId, false, newServerSideStatus, setServerSideStatus).then((res) => {
            setQueueRecords(res);
        });
    }

    const generalContextMenuActions = [
        {action: openOrders, icon:"euro", label:"Ordini SMS" },
        {action: openCampaigns, icon:"sms", label:"Campagne" },
        {action: configClicked, icon:"settings", label:"Config. account" },
    ];

    if (isDataNull(registriesContext.companies) || isDataLoading(registriesContext.companies)) return <CircularWaiting/>
    if (isDataNull(managementContext.smsOrders) || isDataLoading(managementContext.smsOrders)) return <CircularWaiting/>
    if (isDataNull(managementContext.smsCampaigns) || isDataLoading(managementContext.smsCampaigns)) return <CircularWaiting/>
    if (isDataNull(smsCredit)) return <CircularWaiting/>

    return (
        <>
            <PageContainer title={"SMS"} contextualMenuActions={generalContextMenuActions}>
                <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                    <AgGridReact
                        rowData={smsCreditRows}
                        defaultColDef={defaultColDef}
                        columnDefs={columnsCredits}
                        columnTypes={columnTypes}
                        suppressMovableColumns={true}
                        suppressCellFocus={true}
                        pagination={true}
                        rowSelection={'single'}
                        getRowHeight={(params) => gridRowHeight}
                    >
                    </AgGridReact>
                </div>
            </PageContainer>
            <CardModal
                modalOpen={ordersModalOpen}
                onClose={onOrdersClose}
                title={"Ordini SMS"}
                size={"large"}
            >
                <CardContent>
                    <PageContainer title={""} contextualMenuActions={smsOrdersContextMenuActions}>
                        <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                            <AgGridReact
                                rowData={managementContext.smsOrders}
                                defaultColDef={defaultColDef}
                                columnDefs={ordersColumns}
                                columnTypes={columnTypes}
                                suppressMovableColumns={true}
                                suppressCellFocus={true}
                                pagination={true}
                                rowSelection={'single'}
                                getRowHeight={(params) => gridRowHeight}
                            >
                            </AgGridReact>
                        </div>
                    </PageContainer>
                </CardContent>
            </CardModal>
            <CardModal
                modalOpen={orderModalOpen}
                onClose={onOrderClose}
                title={"Dettaglio ordine SMS"}
                size={"small"}
                formFunctions={{submitHandler: handleSubmitOrder, onFormSubmit: onOrderSubmit, onFormError: onOrderError}}
            >
                <CardContent>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12}>
                            <FormAutoComplete
                                label="Numero SMS"
                                control={controlOrder}
                                record={smsOrder}
                                register={registerOrder}
                                field="mNumberOfSMS"
                                joinField=""
                                values={registriesContext.smsPackagesItemizedListValues}
                                idField="mItemizedListValueID"
                                labelField="mValue"
                                selectedValue="label"
                                required={true}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardModal>
            <CardModal
                modalOpen={campaignsModalOpen}
                onClose={onCampaignsClose}
                title={"Campagne SMS"}
                size={"large"}
            >
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sx={{pb: theme.spacing(2)}}>
                            <Stack
                                direction={"row"}
                                justifyContent={"flex-end"}
                            >
                                <AddButton addAction={addCampaignAction}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                                <AgGridReact
                                    rowData={managementContext.smsCampaigns}
                                    defaultColDef={defaultColDef}
                                    columnDefs={campaignsColumns}
                                    columnTypes={columnTypes}
                                    suppressMovableColumns={true}
                                    suppressCellFocus={true}
                                    pagination={true}
                                    rowSelection={'single'}
                                    getRowHeight={(params) => gridRowHeight}
                                >
                                </AgGridReact>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardModal>
            <CardModal
                modalOpen={campaignModalOpen}
                onClose={onCampaignClose}
                title={"Dettaglio campagna SMS"}
                size={"small"}
                formFunctions={{submitHandler: handleSubmitCampaign, onFormSubmit: onCampaignSubmit, onFormError: onCampaignError}}
            >
                <CardContent>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Codice Campagna"
                                control={controlCampaign}
                                record={smsCampaign}
                                register={registerCampaign}
                                field="mServiceCode"
                                required={true}
                                maxLength={100}
                                disabled={smsCampaign.mSystemRecord}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Descrizione"
                                control={controlCampaign}
                                record={smsCampaign}
                                register={registerCampaign}
                                field="mServiceDescription"
                                required={true}
                                maxLength={100}
                                disabled={smsCampaign.mSystemRecord}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormDatePicker
                                label="Attiva dal"
                                control={controlCampaign}
                                record={smsCampaign}
                                register={registerCampaign}
                                field="mScheduledBy"
                                required={true}
                                format={commonContext.getPlanningParameter("DATE_FORMAT_SHORT")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextBox
                                label="Modello del messaggio"
                                control={controlCampaign}
                                record={smsCampaign}
                                register={registerCampaign}
                                field="mMessageTemplate"
                                multiline={true}
                                lines={4}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormCheckBox
                                label="Attiva"
                                control={controlCampaign}
                                record={smsCampaign}
                                register={registerCampaign}
                                field="mActive"
                                required={false}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormCheckBox
                                label="Preparata"
                                control={controlCampaign}
                                record={smsCampaign}
                                register={registerCampaign}
                                field="mReady"
                                required={false}
                                readonly
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormCheckBox
                                label="Record di sistema"
                                control={controlCampaign}
                                record={smsCampaign}
                                register={registerCampaign}
                                field="mSystemRecord"
                                required={false}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardModal>
            <CardModal
                modalOpen={queueModalOpen}
                onClose={onQueueClose}
                title={"Coda messaggi della campagna"}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    {
                        <Grid
                            container
                            spacing={theme.spacing(2)}
                            direction={"row"}
                        >
                            <Grid item xs={12}>
                                <PaginationToolBar
                                    currentPage={serverSideStatus.currentPage}
                                    pagesCount={serverSideStatus.totalPages}
                                    pageSize={serverSideStatus.pageSize}
                                    setCurrentPage={serverSideSetCurrentPage}
                                    rowsCount={serverSideStatus.totalRows}
                                >
                                </PaginationToolBar>
                            </Grid>
                            <Grid item xs={12}>
                                <div id="agReactGrid" className="ag-theme-alpine" style={gridExtraSizeStyle}>
                                    <AgGridReact
                                        rowData={queueRecords}
                                        defaultColDef={defaultColDef}
                                        columnDefs={columnsQueue}
                                        columnTypes={columnTypes}
                                        suppressMovableColumns={true}
                                        suppressCellFocus={true}
                                        pagination={true}
                                        rowSelection={'single'}
                                        getRowHeight={(params) => gridRowHeight}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    }
                </CardContent>
            </CardModal>
            <SmsModulePreview orderID={orderToPrint} setOrderToPrint={setOrderToPrint}/>
        </>
    )

}