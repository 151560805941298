import {FormControl, FormControlLabel, FormGroup, useTheme} from "@mui/material";
import React, {useState} from 'react';
import {Controller} from "react-hook-form";
import {getCurrentContent} from "draft-js/lib/EditorState";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import {loggedClassNames, reportingStyleNames} from "../../theme/Styles";
import {defaultEditorOptions} from "../../common";

export const FormHtmlEditor = ({notFullWidth, noFormControl, ...props}) => {

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    const reportingStyles = reportingStyleNames(theme);


    const value = props.record[props.field];
    let initState = EditorState.createEmpty();
    if ((value ?? "") != "") {
        const contentBlock = htmlToDraft(value ?? "");
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        initState = EditorState.createWithContent(contentState);
    }
    const [editorState, setEditorState] = useState(initState);

    const getOptions = () => {
        let options = {}
        if (props.required !== null) {
            options.required = {};
            options.required.value = props.required;
            if (props.required === true) {
                options.required.message = "Campo richiesto";
            }
        }
        return options;
    }

    const isDisabled = () => {
        if (props.disabled) {
            return props.disabled;
        } else {
            return false;
        }
    }

    const checkIsEmptyBlock = (content) => {
        if (!content) return true;
        if (!content.blocks) return true;
        const arrBlocks = content.blocks ?? [];
        if (arrBlocks.length === 0) return true;
        if (arrBlocks.length === 1 && arrBlocks[0].text === "") return true;
        return false;
    }

    const label = {inputProps: {'aria-label': 'Checkbox demo'}};

    return (
        <FormControl fullWidth>
            <Controller
                name={props.field}
                control={props.control}
                render={({
                             field: {onChange, onBlur, value, name, ref},
                             fieldState: {invalid, isTouched, isDirty, error},
                             formState,
                         }) => (
                    <>
                        <Editor
                            toolbar={defaultEditorOptions}
                            editorState={editorState}
                            wrapperStyle={reportingStyles.templateWrapper}
                            editorStyle={reportingStyles.templateArea}
                            toolbarStyle={reportingStyles.templateToolbar}
                            onEditorStateChange={(newState) => {
                                const rawContent = convertToRaw(newState.getCurrentContent());
                                const htmlContent = draftToHtml(rawContent);
                                onChange(htmlContent);
                                setEditorState(newState);
                            }}
                        />
                    </>

                )}
                {...props.register(props.field, getOptions())}
            />
        </FormControl>
    )

}