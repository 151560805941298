import React, {useEffect, useState} from "react";
import {Box, Grid, Tab, Tabs, useTheme} from "@mui/material";
import {IconLibraryItem} from "../../../../components/Icons/IconLibraryItem";
import {TabPanel} from "../../../../components/Tab/TabPanel";
import EditProfile from "../../user/components/EditProfile";
import ChangePassword from "../../user/components/ChangePassword";
import {DBBackups} from "./DBBackups";
import {Sessions} from "./Sessions";
import {Anomalies} from "./Anomalies";
import {loggedClassNames} from "../../../../theme/Styles";

export const ControlPanel = (props) => {
    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {

    }, []);

    const tabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <Grid
                container
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={loggedClasses.controlPanelHeader}
                key="ControlPanelGrid"
            >
                <Grid item xs={12} mt={2}>
                    <Box>
                        <Tabs
                            value={selectedTab}
                            variant="fullWidth"
                            aria-label="basic tabs example"
                            onChange={tabChange}
                            sx={loggedClasses.controlPanelTabButtons}
                        >
                            <Tab
                                label="DB Backup" {...a11yProps(0)}
                                sx={loggedClasses.controlPanelTabButton}
                            />
                            <Tab
                                label="Anomalie" {...a11yProps(0)}
                                sx={loggedClasses.controlPanelTabButton}
                            />
                            <Tab
                                label="Sessioni" {...a11yProps(1)}
                                sx={loggedClasses.controlPanelTabButton}
                            />
                        </Tabs>
                        <TabPanel value={selectedTab} index={0} sx={loggedClasses.controlPanelTabPanel}>
                            <DBBackups/>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={1} sx={loggedClasses.controlPanelTabPanel}>
                            <Anomalies/>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={2} sx={loggedClasses.controlPanelTabPanel}>
                            <Sessions/>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}