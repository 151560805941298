import {Box, CardContent, Grid, Stack, Typography, useTheme} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {isDataLoading, isDataNull} from "../../../../common";
import {SquareButton} from "../../../../components/Buttons/SquareButton";
import {RoomIntervalConfig} from "../../../../components/RoomsIntervalsScheduling/RoomIntervalConfig";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {BookingContext} from "../../../../context/BookingContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {loggedClassNames} from "../../../../theme/Styles";
import {IntervalAvailabilities} from "../../../../components/RoomsIntervalsScheduling/IntervalAvailabilities";
import {CardModal} from "../../../../components/Layout/CardModal";
import {CustomAlertContext} from "../../../../context/AlertContext";
import RoomBox from "../../../../components/RoomsIntervalsScheduling/RoomBox";
import RoomDayCell from "../../../../components/RoomsIntervalsScheduling/RoomDayCell";

const IntervalsScheduler = () => {

    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);
    const alertContext = useContext(CustomAlertContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [schedulerDays, setSchedulerDays] = useState(null);
    const [intervals, setIntervals] = useState(null);
    const [visitLines, setVisitLines] = useState(null);
    const [selectedInterval, setSelectedInterval] = useState(null);
    const [schedulingRoom, setSchedulingRoom] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const schedulingModalOpen = schedulingRoom !== null;

    const activeRooms = registriesContext.getActiveRooms();

    useEffect(() => {
        if (loaded) return;
        registriesContext.fetchCompanies();
        registriesContext.fetchDoctors();
        registriesContext.fetchBranches().then(() => {});
        bookingContext.calculateSchedulerDays().then((days) => {
            setSchedulerDays(days);
            const fromDate = bookingContext.schedulerState.fromDate;
            const toDate = bookingContext.schedulerState.toDate;
            bookingContext.fetchIntervals(fromDate, toDate).then(ints => {
                setIntervals(ints);
                bookingContext.fetchVisitLinesShort(fromDate, toDate).then(vls => {
                    setVisitLines(vls);
                    setLoaded(true);
                });

            });
        });

    }, [loaded]);

    useEffect(() => {
        setLoaded(false);
    }, [bookingContext.schedulerState]);

    useEffect(() => {
        if (bookingContext.bookingMode !== 5 && bookingContext.bookingMode !== 6) bookingContext.openBooking({}, 5);
    }, [bookingContext.bookingMode]);

    const goToPrev = () => {
        bookingContext.goPrevSchedulerWeek();
    }

    const goToNext = () => {
        bookingContext.goNextSchedulerWeek();
    }

    const openRoomSchedulingConfig = (pRoom) => {
        setSchedulingRoom(pRoom);
    }

    const onRoomSchedulingConfigClose = (event, reason) => {
        setSchedulingRoom(null);
        setLoaded(false);
    }


    const closeInterval = (interval) => {
        bookingContext.closeInterval(interval).then((res) => {
            setLoaded(false);
        });
    }

    const openInterval = (interval) => {
        bookingContext.openInterval(interval).then((res) => {
            setLoaded(false);
        });
    }

    const openIntervalDetail = (interval) => {
        setSelectedInterval(interval);
    }

    const closeIntervalDetail = (interval) => {
        setSelectedInterval(null);
        setLoaded(false);
    }

    if (isDataNull(schedulerDays) || isDataLoading(schedulerDays)) return <CircularWaiting/>
    if (isDataNull(registriesContext.companies) || isDataLoading(registriesContext.companies)) return <CircularWaiting/>
    if (isDataNull(registriesContext.doctors) || isDataLoading(registriesContext.doctors)) return <CircularWaiting/>
    if (isDataNull(registriesContext.branches) || isDataLoading(registriesContext.branches)) return <CircularWaiting/>

    const selectedIntervalName = (selectedInterval) ? selectedInterval.mBranca.mBrancaName : "";

    return (
        <>
            <Stack
                container
                direction={"row"}
                spacing={theme.spacing(1)}
                display={"flex"}
                justifyContent={"center"}
            >
                <Box>
                    <Stack direction={"column"} spacing={theme.spacing(1)}>
                        <Box sx={loggedClasses.schedulerDayHeader}>
                            <Grid
                                container
                                direction={"row"}
                                spacing={theme.spacing(1)}
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"}>
                                    <SquareButton
                                        color="primary"
                                        variant="contained"
                                        action={goToPrev}
                                        size="medium"
                                        icon="left"
                                        iconSize="medium"
                                        tooltip="Vai indietro di 7 giorni"
                                        label="-7 GG"
                                        direction={"column"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"}>
                                    <SquareButton
                                        color="primary"
                                        variant="contained"
                                        action={goToNext}
                                        size="medium"
                                        icon="right"
                                        iconSize="medium"
                                        tooltip="Vai avanti di 7 giorni"
                                        label="+7 GG"
                                        direction={"column"}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        {activeRooms.map((room, index) => {
                            return <RoomBox room={room} openDetails={openRoomSchedulingConfig}/>
                        })}
                    </Stack>
                </Box>
                {
                    (schedulerDays).map((day, schedulerDayIndex) => {
                        return (
                            <Box key={'scheduler-day-box-' + schedulerDayIndex}>
                                <Stack direction={"column"} spacing={theme.spacing(1)}>
                                    <Box sx={loggedClasses.schedulerDayHeader}>
                                        <Typography variant={"h6"} align={"center"}>{day.slotDateLabel} </Typography>
                                        <Typography variant={"h6"} align={"center"}>{day.slotDateWeekDayName}</Typography>
                                    </Box>
                                    {
                                        activeRooms.map((room, roomIndex) => {
                                            let slots = []
                                            if (intervals && visitLines) {
                                                slots = bookingContext.calculateSchedulerRoomSlots(day.slotDate, intervals, visitLines, room.mRoomID);
                                            }
                                            return (
                                                <RoomDayCell slots={slots} closeInterval={closeInterval} openInterval={openInterval} openIntervalDetail={openIntervalDetail} />
                                            )
                                        })
                                    }
                                </Stack>
                            </Box>
                        )
                    })
                }
            </Stack>
            {
                selectedInterval &&
                <CardModal
                    modalOpen={selectedInterval}
                    onClose={closeIntervalDetail}
                    title={"Disponibilità " + selectedIntervalName}
                >
                    <CardContent>
                        <IntervalAvailabilities interval={selectedInterval} close={closeIntervalDetail}/>
                    </CardContent>
                </CardModal>
            }
            {
                schedulingRoom &&
                <RoomIntervalConfig open={schedulingModalOpen} onClose={onRoomSchedulingConfigClose} room={schedulingRoom}/>
            }


        </>
    )

}

export default IntervalsScheduler;