import {Accordion, AccordionDetails, AccordionSummary, Grid, Stack, Typography, useTheme} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {columnTypes, dateFormatter, defaultColDef, isDataLoading, isDataNull, timeFormatter} from "../../common";
import {CustomAlertContext} from "../../context/AlertContext";
import {BookingContext} from "../../context/BookingContext";
import {RegistriesContext} from "../../context/RegistriesContext";
import {gridRowHeight, loggedClassNames} from "../../theme/Styles";
import {FormActionBar} from "../Form/FormActionBar";
import {FormAutoComplete} from "../Form/FormAutoComplete";
import {FormColorPicker} from "../Form/FormColorPicker";
import {FormDatePicker} from "../Form/FormDatePicker";
import {FormTimePicker} from "../Form/FormTimePicker";
import {ActionsToolBar, AddButton} from "../Grid/ActionsToolbar";
import {CircularWaiting} from "../Waitings/CircularWaiting";
import {CommonContext} from "../../context/CommonContext";

function ExpandMoreIcon() {
    return null;
}

export const RoomScheduler = ({roomID}) => {

    const registriesContext = useContext(RegistriesContext);
    const commonContext = useContext(CommonContext);
    const bookingContext = useContext(BookingContext);
    const alertContext = useContext(CustomAlertContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [expanded, setExpanded] = useState("panel1");
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedExceptionDoctor, setSelectedExceptionDoctor] = useState(null);
    const [room, setRoom] = useState(null);
    const [planningDays, setPlanningDays] = useState(null);
    const planningDaysGridRef = useRef();
    const [planningDay, setPlanningDay] = useState(null);
    const [planningDayEdit, setPlanningDayEdit] = useState(false);
    const [planningIntervals, setPlanningIntervals] = useState([]);
    const [planningInterval, setPlanningInterval] = useState(null);
    const [planningIntervalEdit, setPlanningIntervalEdit] = useState(false);
    const [planningIntervalUnlimited, setPlanningIntervalUnlimited] = useState(false);
    const planningIntervalsGridRef = useRef();

    const openingTime = commonContext.getPlanningParameter("OPENING_TIME");
    const closingTime = commonContext.getPlanningParameter("CLOSING_TIME");
    const slotDuration = commonContext.getPlanningParameter("SLOT_SIZE");

    useEffect(() => {
        if (roomID === -1) return;
        if (!room) registriesContext.fetchRoom(roomID).then((res) => {
            setRoom(res);
        });
        if (!planningDays) {
            bookingContext.fetchPlanningDaysByRoom(roomID).then((res) => {
                setPlanningDays(res);
            })
        }
    }, [])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    //planning day

    const {
        register,
        control,
        handleSubmit,
        reset
    } = useForm({defaultValues: planningDay});

    const addPlanningDayAction = () => {
        bookingContext.fetchPlanningDay(0).then((res) => {
            setPlanningDay(res);
            reset(res);
            setPlanningDayEdit(true);
        });
    }

    const cancelPlanningDayAction = () => {
        setPlanningDay(null);
        reset(null);
        setPlanningDayEdit(false);
    }

    const onSubmitPlanningDay = (data) => {
        data.mRoom = room;
        bookingContext.updatePlanningDay(data).then((res) => {
            addPlanningDayAction();
            bookingContext.fetchPlanningDaysByRoom(roomID).then((res) => {
                setPlanningDays(res);
                setPlanningDayEdit(false);
            })
        });
    }

    const onErrorPlanningDay = (errors, e) => {
        console.log(errors, e);
    }

    const PlanningDaysToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            setPlanningDay(selectedRow);
            reset(selectedRow);
            setPlanningDayEdit(true);
        };

        const deleteAction = () => {
            bookingContext.deletePlanningDay(selectedRow).then((res) => {
                addPlanningDayAction();
                bookingContext.fetchPlanningDaysByRoom(roomID).then((res) => {
                    setPlanningDays(res);
                    setPlanningDayEdit(false);
                })
            });
        }

        const customAction = () => {
            setPlanningDay(selectedRow);
            bookingContext.fetchIntervalsByPlanID(selectedRow.mPlanID).then((res) => {
                setPlanningIntervals(res);
                setExpanded("panel2");
            });
        }

        const customActions = [{
            action: customAction,
            disabled: false,
            icon: "clock",
            tooltip: "Scheduling",
        }];

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
                customActions={customActions}
            />
        )
    }

    const planningDaysColumns = [
        {headerName: "Giorno", field: "mGiornoNome"},
        {headerName: "Azioni", cellRenderer: PlanningDaysToolBar, type: "toolBar"}
    ];

    //intervals

    const {
        register: intervalRegister,
        control: intervalControl,
        handleSubmit: intervalHandleSubmit,
        reset: intervalReset,
        getValues: intervalGetValues,
        watch: intervalWatch

    } = useForm({defaultValues: planningInterval});

    const addIntervalAction = () => {
        bookingContext.fetchInterval(0, 0).then((res) => {
            setPlanningInterval(res);
            intervalReset(res);
            setPlanningIntervalEdit(true);
        });
    }

    const intervalCancelAction = () => {
        setPlanningInterval(null);
        intervalReset(null);
        setPlanningIntervalEdit(false);
    }

    const onSubmitInterval = (data) => {
        data.mPlanning = {mPlanID: planningDay.mPlanID};
        bookingContext.updateInterval(data).then((res) => {
            if (res === false) {
                alertContext.showCustomAlert("error", "Attenzione! Lo slot deve essere compreso tra gli orari di apertura e chiusura e deve coprire almeno uno slot.");
                return;
            }
            addIntervalAction();
            bookingContext.fetchIntervalsByPlanID(planningDay.mPlanID).then((res) => {
                setPlanningIntervals(res);
                setPlanningIntervalEdit(false);
            });
        });
    }

    const onErrorInterval = (errors, e) => {
        console.log(errors, e);
    }

    const onPlanningIntervalsSelectionChanged = useCallback(() => {
        // const rows = planningIntervalsGridRef.current.api.getSelectedRows();
        // if (rows.length === 0) return;
        // const row = rows[0];
        // registriesContext.fetchIntervalsByPlanID(row.mPlanID).then((res) => {
        //     console.log(res);
        //     setPlanningIntervals(res);
        // });
    }, []);

    const IntervalsToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            setPlanningInterval(selectedRow);
            intervalReset(selectedRow);
            setPlanningIntervalEdit(true);
        };

        const deleteAction = () => {
            bookingContext.deleteInterval(selectedRow).then((res) => {
                bookingContext.fetchIntervalsByPlanID(planningDay.mPlanID).then((res) => {
                    setPlanningIntervals(res);
                    setPlanningIntervalEdit(false);
                });
            });
        }

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
            />
        )
    }

    const intervalsColumns = [
        {headerName: "Medico", field: "mDoctor.mCompleteName"},
        {headerName: "Branca", field: "mBranca.mBrancaName"},
        {headerName: "Dal", field: "mDateFrom", cellRenderer: dateFormatter},
        {headerName: "Al", field: "mDateTo", cellRenderer: dateFormatter},
        {headerName: "Dalle", field: "mHourFrom", cellRenderer: timeFormatter},
        {headerName: "Alle", field: "mHourTo", cellRenderer: timeFormatter},
        {headerName: "Azioni", cellRenderer: IntervalsToolBar, type: "toolBar"}
    ];

    if (isDataNull(registriesContext.doctors) || isDataLoading(registriesContext.doctors)) return <CircularWaiting/>
    if (isDataNull(registriesContext.branches) || isDataLoading(registriesContext.branches)) return <CircularWaiting/>

    if (roomID === -1) return <></>

    const roomName = (room) ? room.mRoomName : "";
    const planningFormDisabled = (!planningDay || !planningDayEdit);
    const intervalFormDisabled = (!planningInterval || !planningIntervalEdit);

    const selectedDayName = (planningDay ?? {}).mGiornoNome ?? "";

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={loggedClasses.schedulerAccordionHeader}
                >
                    <Typography variant={"h6"} align={"center"}>
                        Gestione giorni di lavoro della sala
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction={"row"}>
                        <Grid item xs={9}>
                            <div id="agReactGrid" className="ag-theme-alpine"
                                 style={{height: "60vh", width: "45vw"}}>
                                <AgGridReact
                                    ref={planningDaysGridRef}
                                    rowData={planningDays}
                                    defaultColDef={defaultColDef}
                                    columnDefs={planningDaysColumns}
                                    columnTypes={columnTypes}
                                    suppressMovableColumns={true}
                                    suppressCellFocus={true}
                                    pagination={true}
                                    getRowHeight={(params) => gridRowHeight}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <form onSubmit={handleSubmit(onSubmitPlanningDay)}>
                                <Grid container direction={"row"} spacing={theme.spacing(1)}>
                                    <Grid item xs={12}>
                                        <Stack
                                            direction={"row"}
                                            justifyContent={"flex-end"}
                                            spacing={theme.spacing(2)}
                                        >
                                            <AddButton addAction={addPlanningDayAction}/>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormAutoComplete
                                            label="Giorno"
                                            control={control}
                                            record={planningDay}
                                            register={register}
                                            field="mGiorno"
                                            joinField=""
                                            values={bookingContext.schedulerDayOptions}
                                            idField="mDayID"
                                            labelField="mDayName"
                                            selectedValue="id"
                                            required={true}
                                            disabled={planningFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                                        <FormColorPicker
                                            label="Colore"
                                            control={control}
                                            record={planningDay}
                                            register={register}
                                            field="mColor"
                                            required={true}
                                            disabled={planningFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormActionBar cancelAction={cancelPlanningDayAction}
                                                       submitDisabled={planningFormDisabled}
                                                       cancelDisabled={planningFormDisabled}/>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2bh-content"
                    sx={loggedClasses.schedulerAccordionHeader}
                >
                    <Typography variant={"h6"} align={"center"}>
                        Gestione attività nel giorno selezionato {selectedDayName}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction={"row"}>
                        <Grid item xs={9}>
                            <div id="agReactGrid" className="ag-theme-alpine"
                                 style={{height: "60vh", width: "45vw"}}>
                                <AgGridReact
                                    ref={planningIntervalsGridRef}
                                    rowData={planningIntervals}
                                    defaultColDef={defaultColDef}
                                    columnDefs={intervalsColumns}
                                    columnTypes={columnTypes}
                                    suppressMovableColumns={true}
                                    suppressCellFocus={true}
                                    pagination={true}
                                    getRowHeight={(params) => gridRowHeight}
                                    rowSelection={'single'}
                                    onSelectionChanged={onPlanningIntervalsSelectionChanged}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <form onSubmit={intervalHandleSubmit(onSubmitInterval, onErrorInterval)}>
                                <Grid container direction={"row"} spacing={theme.spacing(1)}>
                                    <Grid item xs={12}>
                                        <Stack
                                            direction={"row"}
                                            justifyContent={"flex-end"}
                                            spacing={theme.spacing(2)}
                                        >
                                            <AddButton addAction={addIntervalAction}/>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormAutoComplete
                                            label="Branca"
                                            control={intervalControl}
                                            record={planningInterval}
                                            register={intervalRegister}
                                            field="mBranca"
                                            joinField="mBrancaID"
                                            values={registriesContext.branches}
                                            idField="mBrancaID"
                                            labelField="mBrancaName"
                                            selectedValue="id"
                                            required={true}
                                            disabled={intervalFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormAutoComplete
                                            label="Medico"
                                            control={intervalControl}
                                            record={planningInterval}
                                            register={intervalRegister}
                                            field="mDoctor"
                                            joinField="mUserID"
                                            values={registriesContext.doctors}
                                            idField="mUserID"
                                            labelField="mCompleteName"
                                            selectedValue="id"
                                            required={true}
                                            disabled={intervalFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormDatePicker
                                            label="Dal"
                                            control={intervalControl}
                                            record={planningInterval}
                                            register={intervalRegister}
                                            field="mDateFrom"
                                            required={true}
                                            format={commonContext.getPlanningParameter("DATE_FORMAT_SHORT")}
                                            disabled={planningIntervalUnlimited || intervalFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormDatePicker
                                            label="Al"
                                            control={intervalControl}
                                            record={planningInterval}
                                            register={intervalRegister}
                                            field="mDateTo"
                                            required={true}
                                            format={commonContext.getPlanningParameter("DATE_FORMAT_SHORT")}
                                            disabled={planningIntervalUnlimited || intervalFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormTimePicker
                                            label="Dalle"
                                            control={intervalControl}
                                            record={planningInterval}
                                            register={intervalRegister}
                                            field="mHourFrom"
                                            minTime={openingTime}
                                            maxTime={closingTime}
                                            minutesStep={slotDuration}
                                            required={true}
                                            format={commonContext.getPlanningParameter("TIME_FORMAT_SHORT")}
                                            disabled={planningIntervalUnlimited || intervalFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormTimePicker
                                            label="Alle"
                                            control={intervalControl}
                                            record={planningInterval}
                                            register={intervalRegister}
                                            field="mHourTo"
                                            minTime={openingTime}
                                            maxTime={closingTime}
                                            minutesStep={slotDuration}
                                            required={true}
                                            format={commonContext.getPlanningParameter("TIME_FORMAT_SHORT")}
                                            disabled={planningIntervalUnlimited || intervalFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormAutoComplete
                                            label="Schedulazione"
                                            control={intervalControl}
                                            record={planningInterval}
                                            register={intervalRegister}
                                            field="mSchedule"
                                            joinField=""
                                            values={bookingContext.schedulerFreqOptions}
                                            idField="mSchedulingID"
                                            labelField="mSchedulingName"
                                            selectedValue="id"
                                            required={true}
                                            disabled={planningIntervalUnlimited || intervalFormDisabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormActionBar cancelAction={intervalCancelAction}
                                                       submitDisabled={intervalFormDisabled}
                                                       cancelDisabled={intervalFormDisabled}/>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};