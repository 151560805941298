import {
    Autocomplete,
    Grid, IconButton,
    ListItem,
    ListSubheader,
    Pagination,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {RoomTimeLine} from "../../../../components/RoomsPlanner/RoomTimeLine";
import {BookingContext} from "../../../../context/BookingContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {loggedClassNames} from "../../../../theme/Styles";
import {CommonContext} from "../../../../context/CommonContext";
import {SquareButton} from "../../../../components/Buttons/SquareButton";
import {InputDatePicker} from "../../../../components/Input/InputDatePicker";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AuthContext} from "../../../../context/AuthContext";
import {VisitLineSlot} from "../../../../components/RoomsPlanner/VisitLineSlot";
import List from "@mui/material/List";
import {RoomsList} from "../../../../components/RoomsPlanner/RoomsList";


const RoomPlanner = () => {

    const authContext = useContext(AuthContext);
    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);
    const commonContext = useContext(CommonContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    let navigate = useNavigate();

    //se c'è una stanza assegnata all'utente la seleziono
    //--> altrimenti prendo quella di default della struttura
    //--> altrimenti prendo la prima tra le attive

    const activeRooms = registriesContext.getActiveRooms() ?? [];
    const loggedDefaultRoom = authContext.getLoggedUserDefaultRoom();
    const loggedDefaultRoomExists = (loggedDefaultRoom) && activeRooms.find(r => r.mRoomID === loggedDefaultRoom.mRoomID) !== null;
    const defaultRoom = registriesContext.fetchDefaultRoom();
    const defaultRoomRoomExists = (defaultRoom) && activeRooms.find(r => r.mRoomID === defaultRoom.mRoomID) !== null;

    const initRoomID =
        (loggedDefaultRoomExists) ? loggedDefaultRoom.mRoomID :
            (defaultRoomRoomExists) ? defaultRoom.mRoomID :
                (activeRooms.length !== 0) ? activeRooms[0].mRoomID : -1;

    let selectedRoomItem = (initRoomID !== -1) ? activeRooms.find(r => r.mRoomID === initRoomID) : null;

    const [selectedRoom, setSelectedRoom] = useState(selectedRoomItem);

    const newBookingClick = () => {
        let booking = {
            room: 0,
            slot: null,
        }
        bookingContext.openBooking(booking, 20);
        navigate('/Booking/TakeBooking');
    }

    const goToPrevSevenDays = () => {
        bookingContext.addDaysPlanningSelectedDate(-7);
    }

    const goToPrevOneDay = () => {
        bookingContext.addDaysPlanningSelectedDate(-1);
    }

    const goToNextOneDay = () => {
        bookingContext.addDaysPlanningSelectedDate(1);
    }

    const goToNextSevenDays = () => {
        bookingContext.addDaysPlanningSelectedDate(7);
    }

    const goToToday = () => {
        bookingContext.resetPlanningSelectedDate();
    }

    const selectedRoomChange = (room)=> {
        bookingContext.setPlanningSelectedRoom(room.mRoomID);
        setSelectedRoom(room);
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="flex-start"
                spacing={theme.spacing(2)}
                sx={loggedClasses.bookingDetailHeader}
            >
                <Grid item xs={12} md={9}>
                    <Stack
                        direction="column"
                        justifyContent="left"
                        alignItems="flex-start"
                        spacing={theme.spacing(2)}
                    >
                        <Stack
                            direction="row"
                            justifyContent="left"
                            alignItems="center"
                            spacing={theme.spacing(0.5)}
                        >
                            <SquareButton
                                color="secondary"
                                variant="contained"
                                action={goToPrevSevenDays}
                                size="medium"
                                icon="left"
                                iconSize="small"
                                tooltip="Vai indietro di 7 giorni"
                                label="-7"
                                direction={"column"}
                                withBoxShadow={true}
                            />
                            <SquareButton
                                color="secondary"
                                variant="contained"
                                action={goToPrevOneDay}
                                size="medium"
                                icon="left"
                                iconSize="small"
                                tooltip="Vai indietro di 1 giorno"
                                label="-1"
                                direction={"column"}
                                withBoxShadow={true}
                            />
                            <InputDatePicker
                                label={"Data"}
                                value={bookingContext.bookingState.selectedDate}
                                disabled={false}
                                format={commonContext.getPlanningParameter("DATE_FORMAT_SHORT")}
                                setValue={bookingContext.setPlanningSelectedDate}
                                sx={{minWidth: 100}}
                            />
                            <SquareButton
                                color="secondary"
                                variant="contained"
                                action={goToToday}
                                size="medium"
                                icon="calendar"
                                iconSize="small"
                                tooltip="Vai a oggi"
                                label="Oggi"
                                direction={"column"}
                                withBoxShadow={true}
                            />
                            <SquareButton
                                color="secondary"
                                variant="contained"
                                action={goToNextOneDay}
                                size="medium"
                                icon="right"
                                iconSize="small"
                                tooltip="Vai avanti di 1 giorno"
                                label="+1"
                                direction={"column"}
                                withBoxShadow={true}
                            />
                            <SquareButton
                                color="secondary"
                                variant="contained"
                                action={goToNextSevenDays}
                                size="medium"
                                icon="right"
                                iconSize="small"
                                tooltip="Vai avanti di 7 giorni"
                                label="+7"
                                direction={"column"}
                                withBoxShadow={true}
                            />
                            <SquareButton
                                color="secondary"
                                variant="contained"
                                action={newBookingClick}
                                size="medium"
                                icon="calendar"
                                iconSize="medium"
                                tooltip="Prenota"
                                label="Prenota"
                                direction={"column"}
                                withBoxShadow={true}
                            />
                        </Stack>

                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="left"
                            alignItems="flex-start"
                            spacing={theme.spacing(0.5)}
                        >
                            <Grid item xs={6} md={9} sx={{p: 0}}>
                                <RoomTimeLine room={selectedRoom} key={"RoomTimeLine-" + selectedRoom}/>
                            </Grid>
                            <Grid item xs={6} md={3} sx={{p: 0}}>
                                <RoomsList selectedRoom={selectedRoom} setSelectedRoom={selectedRoomChange}/>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={3}/>
            </Grid>
        </>
    )

}

export default RoomPlanner;