import {DoWebApiCall} from "./AxiosInstance";

export const wsRegister = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Users/Register', pBody, alertContext, showOnlyError);
}

export const wsLogin = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Users/DoLogin', pBody, alertContext, showOnlyError);
}

export const wsPing = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Users/Ping', {}, alertContext, showOnlyError);
}

export const wsLogout = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Users/DoLogout', pBody, alertContext, showOnlyError);
}

export const wsRememberPassword = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Users/RememberPassword', pBody, alertContext, showOnlyError);
}

export const wsChangePassword = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Users/ChangePassword', pBody, alertContext, showOnlyError);
}

export const wsGetUsers = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Users/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateUser = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Users/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsSetUserImage = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Users/SetUserImage', pBody, alertContext, showOnlyError);
}

export const wsGetDoctorFees = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Prestazione/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateDoctorFees = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Users/UpdateFees', pBody, alertContext, showOnlyError);
}

export const wsUpdateDefaultDoctorFee = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Users/UpdateDefaultFees', pBody, alertContext, showOnlyError);
}

export const wsGetSystemParameters = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Parameter/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateSystemParameter = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Parameter/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsDeleteSystemParameter = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Parameter/Delete', pBody, alertContext, showOnlyError);
}

export const wsGetDomains = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Domain/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateDomain = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Domain/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsDeleteDomain = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Domain/Delete', pBody, alertContext, showOnlyError);
}

export const wsGetSystemFunctions = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Function/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateSystemFunction = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Function/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsDeleteSystemFunction = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Function/Delete', pBody, alertContext, showOnlyError);
}

export const wsGetProfiles = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Profile/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateProfile = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Profile/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsAddProfileFunction = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Profile/AddFunction', pBody, alertContext, showOnlyError);
}

export const wsRemoveProfileFunction = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Profile/RemoveFunction', pBody, alertContext, showOnlyError);
}

export const wsGetModalities = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Modality/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateModality = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Modality/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetCities = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Cities/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateCity = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Cities/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetDistricts = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Provincia/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateDistrict = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Provincia/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetRegions = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Region/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateRegion = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Region/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetCompanies = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Company/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateCompany = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Company/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsCreateCompanyPlan = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Plan/Create', pBody, alertContext, showOnlyError);
}

export const wsGetCompanyPlan = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Plan/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsSetCompanyPlanPayed = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Plan/SetPayed', pBody, alertContext, showOnlyError);
}

export const wsCheckCompanyPayments = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Plan/CheckPayements', pBody, alertContext, showOnlyError);
}

export const wsGetBranches = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Branca/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateBranch = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Branca/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetCounters = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Counter/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateCounter = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Counter/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetConventions = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Convention/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateConvention = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Convention/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsDeleteConvention = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Convention/Delete', pBody, alertContext, showOnlyError);
}

export const wsDuplicateConvention = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Convention/Duplicate', pBody, alertContext, showOnlyError);
}

export const wsGetCountries = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Countries/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateCountry = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Countries/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetDevices = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Device/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateDevice = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Device/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsDuplicateDevice = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Device/Duplicate', pBody, alertContext, showOnlyError);
}

export const wsDeleteDevice = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Device/Delete', pBody, alertContext, showOnlyError);
}

export const wsUpdateDeviceMedicalServices = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Device/LinkPerformance', pBody, alertContext, showOnlyError);
}

export const wsGetDocuments = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Document/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsGetUnstoredDocumentByID = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Document/GetUnstoredDocumentByID', pBody, alertContext, showOnlyError);
}

export const wsUpdateDocument = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Document/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetVisitLine = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Esame/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsGetVisitLineShort = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Esame/GetByObjectShort', pBody, alertContext, showOnlyError);
}

export const wsUpdateVisitLine = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Esame/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetVisit = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Visita/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateVisit = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Visita/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsAcceptVisit = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Visita/Accept', pBody, alertContext, showOnlyError);
}

export const wsAddMedicalServiceToCart = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Esame/AddToChart', pBody, alertContext, showOnlyError);
}

export const wsDeleteVisitLine = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Esame/Delete', pBody, alertContext, showOnlyError);
}

export const wsSetVisitLineAssignee = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Esame/SetAssegnee', pBody, alertContext, showOnlyError);
}

export const wsAcceptVisitLine = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Esame/AcceptLine', pBody, alertContext, showOnlyError);
}

export const wsSaveVisitTmpPatient = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Visita/SetTMPPatient', pBody, alertContext, showOnlyError);
}

export const wsSaveVisitRealPatient = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Visita/SetRealPatient', pBody, alertContext, showOnlyError);
}

export const wsSaveVisitAsl = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Visita/UpdateASL', pBody, alertContext, showOnlyError);
}

export const wsGetSupplierTypes = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/TipoSoggettoErogatore/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateSupplierType = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/TipoSoggettoErogatore/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetExemptions = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Esenzione/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateExemption = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Esenzione/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetExemptionTypes = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/TipiEsenzione/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateExemptionType = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/TipiEsenzione/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsCreateInvoiceFromVisitLines = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Invoice/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsCreateInvoiceFromServices = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Invoice/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetInvoices = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Invoice/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsCancelInvoice = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Invoice/CancelInvoice', pBody, alertContext, showOnlyError);
}

export const wsGetInvoicePDF = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Document/GetPDFInvoice', pBody, alertContext, showOnlyError);
}

export const wsGetCreditDocumentPDF = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Document/GetPDFNotaDiCredito', pBody, alertContext, showOnlyError);
}

export const wsPrintContextualReport = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Document/PrintContextualReport', pBody, alertContext, showOnlyError);
}

export const wsGetAttachmentsList = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Document/GetAttachementList', pBody, alertContext, showOnlyError);
}

export const wsUploadGenericDocument = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Document/UploadGenericDocument', pBody, alertContext, showOnlyError);
}

export const wsDownloadGenericDocument = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Document/DownloadGenericDocument', pBody, alertContext, showOnlyError);
}

export const wsUpdateInvoice = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Invoice/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsDeleteInvoice = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Invoice/CancelInvoice', pBody, alertContext, showOnlyError);
}

export const wsGetInvoiceLines = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/InvoiceLine/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateInvoiceLine = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/InvoiceLine/Update', pBody, alertContext, showOnlyError);
}

export const wsGetItemizedLists = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/ItemizedList/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateItemizedList = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/ItemizedList/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetItemizedListValues = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/ItemizedListValue/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateItemizedListValue = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/ItemizedListValue/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsDeleteItemizedListValue = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/ItemizedListValue/Delete', pBody, alertContext, showOnlyError);
}

export const wsGetMedicalReports = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Referto/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateMedicalReport = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Referto/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetMedicalReportPDF = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Document/GetPDFReferto', pBody, alertContext, showOnlyError);
}

export const wsUploadMedicalReportPDF = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Referto/Sign', pBody, alertContext, showOnlyError);
}

export const wsDeliveryMedicalReport = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Referto/DeliveryReport', pBody, alertContext, showOnlyError);
}

export const wsGetMedicalReportTemplates = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/TemplateReferto/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateMedicalReportTemplate = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/TemplateReferto/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetMedicalServices = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Prestazione/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateMedicalService = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Prestazione/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetMedicalServiceTypes = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/TipoPrestazione/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsGetMedicalServicePrice = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Prestazione/GetPrice', pBody, alertContext, showOnlyError);
}

export const wsGetMedicalServicePrices = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Listino/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsSetMedicalServicePrice = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Prestazione/SetPrice', pBody, alertContext, showOnlyError);
}

export const wsGetRoomMedicalServices = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Prestazione/GetByRoom', pBody, alertContext, showOnlyError);
}
export const wsGetDeviceMedicalServices = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Prestazione/GetByDevice', pBody, alertContext, showOnlyError);
}

export const wsGetActiveMedicalServices = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Prestazione/GetActive', pBody, alertContext, showOnlyError);
}

export const wsGetRooms = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Room/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateRoom = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Room/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetRoomsByMedicalService = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Room/GetByPerformance', pBody, alertContext, showOnlyError);
}

export const wsUpdateMedicalServiceType = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/TipoPrestazione/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetPatients = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Patient/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsGetPatientDataFromCF = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Patient/GetDataFromCF', pBody, alertContext, showOnlyError);
}

export const wsUpdatePatient = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Patient/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsCalculatePatientCF = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Patient/CalculateCF', pBody, alertContext, showOnlyError);
}

export const wsGetPaymentTypes = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/TipologiaPagamento/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdatePaymentType = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/TipologiaPagamento/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetSponsors = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Sponsor/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateSponsor = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Sponsor/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetSponsorTypes = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/TipoSoggettoPrescrittore/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateSponsorType = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/TipoSoggettoPrescrittore/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetTownHalls = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Municipio/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateTownHall = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Municipio/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetBanks = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Bank/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateBank = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Bank/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetChartData = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Chart/GetChartData', pBody, alertContext, showOnlyError);
}

export const wsGetTableData = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Chart/GetTableData', pBody, alertContext, showOnlyError);
}

export const wsGetPlanningDays = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Planning/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdatePlanningDay = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Planning/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsDeletePlanningDay = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Planning/Delete', pBody, alertContext, showOnlyError);
}

export const wsGetPlanningIntervals = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Interval/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsGetPlanningSlots = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Interval/GetSlot', pBody, alertContext, showOnlyError);
}

export const wsUpdatePlanningInterval = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Interval/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsDeletePlanningInterval = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Interval/Delete', pBody, alertContext, showOnlyError);
}

export const wsClosePlanningInterval = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Interval/CloseForThisDay', pBody, alertContext, showOnlyError);
}

export const wsOpenPlanningInterval = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Interval/OpenForThisDay', pBody, alertContext, showOnlyError);
}

export const wsGetSmsCampaigns = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/CampagnaSMS/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateSmsCampaign = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/CampagnaSMS/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetSmsQueueItems = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/SmsQueue/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsSendSmsQueue = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/SmsQueue/SendCustomMessage', pBody, alertContext, showOnlyError);
}

export const wsGetSmsText = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/SmsQueue/GetSMSText', pBody, alertContext, showOnlyError);
}

export const wsGetSmsCredit = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/SMSQueue/GetCredits', pBody, alertContext, showOnlyError);
}

export const wsGetSmsOrders = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/SMSOrder/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateSmsOrder = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/SMSOrder/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsPrintAccountingReport = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Document/PrintGenericReport', pBody, alertContext, showOnlyError);
}

export const wsExportASLReport = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/SIAS/Export', pBody, alertContext, showOnlyError);
}

export const wsGetReports = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Report/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateReport = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Report/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsDeleteReport = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Report/Delete', pBody, alertContext, showOnlyError);
}

export const wsClearReport = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Report/Clear', pBody, alertContext, showOnlyError);
}

export const wsGetReportsInputModes = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/InputMode/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsGetReportsOutputModes = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/OutputMode/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsGetConnectedUsers = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Session/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsGetBudgets = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Budget/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateBudget = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Budget/CreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetLogs = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Log/GetByObject', pBody, alertContext, showOnlyError);
}

/* RECUP */

export const wsGetHierarchicalStructure = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Recup/GetHierarchicalStructure', pBody, alertContext, showOnlyError);
}

export const wsGetExecutionUnits = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Recup/GetExecutionUnits', pBody, alertContext, showOnlyError);
}

export const wsUpdateExecutionUnit = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Recup/ExecutionUnitsCreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsToggleExecutionUnit = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Recup/ExecutionUnitToggle', pBody, alertContext, showOnlyError);
}

export const wsGetDiariesByExecutionUnit = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Recup/GetDiariesByExecutionUnit', pBody, alertContext, showOnlyError);
}

export const wsUpdateDiary = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Recup/DiariesCreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetDiariesByID = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Recup/GetDiariesDetailByID', pBody, alertContext, showOnlyError);
}

export const wsGetDiarySchemas = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Recup/GetDiarySchemas', pBody, alertContext, showOnlyError);
}

export const wsToogleDiary = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Recup/DiaryToggle', pBody, alertContext, showOnlyError);
}

export const wsUpdateOperationUnit = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Recup/OperationUnitCreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsCreateTimeBands = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Recup/SchemaAndTimebandsCreateOrUpdate', pBody, alertContext, showOnlyError);
}

export const wsGetSchemaTimeBands = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Recup/GetSchemaTimebands', pBody, alertContext, showOnlyError);
}

export const wsDeleteSchema = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Recup/DeleteDiarySchema', pBody, alertContext, showOnlyError);
}

export const wsGetCompanyServices = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Service/GetByObject', pBody, alertContext, showOnlyError);
}

export const wsUpdateCompanyService = async (pBody, alertContext, showOnlyError) => {
    return await DoWebApiCall('/Service/CreateOrUpdate', pBody, alertContext, showOnlyError);
}