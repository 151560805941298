import React, {useContext, useEffect, useState} from 'react';
import {CardContent, Grid, useTheme} from "@mui/material";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import ReactJson from "react-json-view";
import {CardModal} from "../../../../components/Layout/CardModal";
import {RecupContext} from "../../../../context/RecupContext";
import {loggedClassNames} from "../../../../theme/Styles";
import {useForm} from "react-hook-form";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {isDataNull} from "../../../../common";

export const RecupExecutionUnit = ({
                                       open,
                                       onClose,
                                       executionUnitId,
                                       operationUnits
                                   }) => {

    const recupContext = useContext(RecupContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [executionUnit, setExecutionUnit] = useState(null);
    const [executionUnitRecord, setExecutionUnitRecord] = useState(null);
    const [updating, setUpdating] = useState(false);

    useEffect(()=>{
        if (!isDataNull(executionUnit)) return;
        if (executionUnitId==="0") {
            recupContext.fetchExecutionUnit(executionUnitId).then((res) => {
                const eu = res;
                setExecutionUnit({});
                const execUnitRec = {
                    mOperationUnitID: eu.mOperationUnitID,
                    mExecutionUnitCode: eu.mExecutionUnitCode,
                    mExecutionUnitName: eu.mExecutionUnitName,
                    mExecutionUnitDescription: eu.mExecutionUnitDescription,
                    mExecutionUnitID: eu.mExecutionUnitID,
                }
                setExecutionUnitRecord(execUnitRec);
                resetEu(execUnitRec);
            });
        }
        else {
            recupContext.fetchExecutionUnit(executionUnitId).then((res) => {
                if (res.length <= 0) return;
                const eu = res[0].registry;
                console.log('res ', res);
                console.log('eu ', eu);
                setExecutionUnit(eu);
                const execUnitRec = {
                    mOperationUnitID: eu.idOperationUnit,
                    mExecutionUnitCode: eu.code,
                    mExecutionUnitName: eu.name,
                    mExecutionUnitDescription: eu.description,
                    mExecutionUnitID: eu.id,
                }
                setExecutionUnitRecord(execUnitRec);
                resetEu(execUnitRec);
            });
        }
    },[executionUnit])

    const {
        register: registerEu,
        control: controlEu,
        handleSubmit: handleSubmitEu,
        reset: resetEu,
        getValues: getValuesEu
    } = useForm({defaultValues: executionUnitRecord});

    const onSubmitEu = (data) => {
        setUpdating(true);
        recupContext.updateExecutionUnit(data).then(res => {
            setUpdating(false);
            onClose();
        });
    }

    const onErrorEu = (errors, e) => {
        console.log(executionUnit);
        console.log(errors, e);
    }

    const executionUnitJSON = executionUnit ?? {};

    const title = (executionUnitId==="0") ? "Nuova execution unit" : ("Dettaglio execution unit " + executionUnitId);

    return (
        <>
            {
                open &&
                <CardModal
                    modalOpen={open}
                    onClose={onClose}
                    title={title}
                    size={"small"}
                    formFunctions={{submitHandler: handleSubmitEu, onFormSubmit: onSubmitEu, onFormError: onErrorEu}}
                >
                    <CardContent sx={loggedClasses.detailsContent}>
                        <Grid
                            container
                            direction={"row"}
                            spacing={theme.spacing(2)}
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                        >
                            {
                                executionUnit && !updating ?
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <FormTextBox
                                                label="ID"
                                                control={controlEu}
                                                record={executionUnitRecord}
                                                register={registerEu}
                                                field="mExecutionUnitID"
                                                required={true}
                                                maxLength={100}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormTextBox
                                                label="Codice"
                                                control={controlEu}
                                                record={executionUnitRecord}
                                                register={registerEu}
                                                field="mExecutionUnitCode"
                                                required={true}
                                                maxLength={20}
                                                disabled={((executionUnitRecord ?? {}).mExecutionUnitID ?? "0") !== "0"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormTextBox
                                                label="Nome"
                                                control={controlEu}
                                                record={executionUnitRecord}
                                                register={registerEu}
                                                field="mExecutionUnitName"
                                                required={true}
                                                maxLength={50}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormTextBox
                                                label="Descrizione"
                                                control={controlEu}
                                                record={executionUnitRecord}
                                                register={registerEu}
                                                field="mExecutionUnitDescription"
                                                required={false}
                                                maxLength={50}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormAutoComplete
                                                label="Operation Unit"
                                                control={controlEu}
                                                record={executionUnitRecord}
                                                register={registerEu}
                                                field="mOperationUnitID"
                                                joinField=""
                                                values={operationUnits}
                                                idField="id"
                                                labelField="label"
                                                selectedValue="id"
                                                required={true}
                                                disabled={false}
                                            />
                                        </Grid>
                                    </>
                                    :
                                    <CircularWaiting/>
                            }

                        </Grid>
                        <Grid item xs={12} container sx={{pt: theme.spacing(2)}}>
                            <Grid item xs={12}>
                                <h3>JSON DI RITORNO EXEC UNIT</h3>
                                <ReactJson src={executionUnitJSON} theme="monokai" collapsed={true}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardModal>
            }

        </>
    )
}