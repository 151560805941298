export const greyPrimary = [
    '#ffffff',
    '#fafafa',
    '#f5f5f5',
    '#f0f0f0',
    '#d9d9d9',
    '#bfbfbf',
    '#8c8c8c',
    '#595959',
    '#262626',
    '#141414',
    '#000000'
];
export const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
export  const greyConstant = ['#fafafb', '#e6ebf1'];
