import {Card, CardContent} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import React, {useContext} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ContainerTitle} from "../../../components/Layout/ContainerTitle";
import {CustomAlertContext} from "../../../context/AlertContext";
import {BookingContext} from "../../../context/BookingContext";
import {ManagementContext} from "../../../context/ManagementContext";
import {RegistriesContext} from "../../../context/RegistriesContext";
import {loggedClassNames} from "../../../theme/Styles";
import {ManagementDashboard} from "./components/ManagementDashboard";
import {VisitLinesAdvancedSearch} from "./components/VisitLinesAdvancedSearch";
import {SmsCampaigns} from "./components/SmsCampaigns";
import {ControlPanel} from "./components/ControlPanel";

const managementRoutes = [
    {path: '/Management/SMS', component: <SmsCampaigns/>, exact: true},
    {path: '/Management/ManagementDashboard', component: <ManagementDashboard/>, exact: true},
    {path: '/Management/AdvancedSearch', component: <VisitLinesAdvancedSearch/>, exact: true},
    {path: '/Management/ControlPanel', component: <ControlPanel/>, exact: true}
]

const LocationRenderer = () => {

    const location = useLocation();

    const managementContext = useContext(ManagementContext);

    const exactElement = managementRoutes.find(l => l.exact === true && location.pathname === l.path);

    if (exactElement) return exactElement.component;

    const partialElement = managementRoutes.find(l => l.exact === false && location.pathname.startsWith(l.path));

    if (partialElement) return partialElement.component;

    switch (managementContext.managementMode) {
        case -1:
            return <></>;
        case 0:
            return <VisitLinesAdvancedSearch/>
        case 1:
            return <ManagementDashboard/>
        case 2:
            return <SmsCampaigns/>
        case 3:
            return <ControlPanel/>
        default:
            return <></>
    }

}

const ManagementContainer = (props) => {

    const registriesContext = useContext(RegistriesContext);
    const managementContext = useContext(ManagementContext);
    const bookingContext = useContext(BookingContext);
    const alertContext = useContext(CustomAlertContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    let navigate = useNavigate();

    const mode = managementContext.managementMode;

    return (
        <Card sx={loggedClasses.loggedContent}>
            <CardContent sx={loggedClasses.loggedCardContent}>
                <ContainerTitle
                    title={"Gestione"}
                    onHomeClick={() => { managementContext.openManagement({}, 1); navigate('/Management/ManagementDashboard')}}
                    key={"container-title-management"}
                />
                <LocationRenderer/>
            </CardContent>
        </Card>
    )

}

export default ManagementContainer;
