import {
    Avatar,
    CardContent,
    Grid,
    ListItem,
    ListItemAvatar,
    Stack,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {AgGridReact} from 'ag-grid-react';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import {columnTypes, defaultColDef, isDataLoading, isDataNull, JSONIsEmpty} from "../../../../common";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar, AddButton} from "../../../../components/Grid/ActionsToolbar";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AuthContext} from "../../../../context/AuthContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";

const empty_list_item = {
    id: 0,
    value: ""
}

const ItemizedListEditor = ({
                                itemizedListID
                            }) => {

    const authContext = useContext(AuthContext);
    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();

    const [values, setValues] = useState(null);
    const [currentItem, setCurrentItem] = useState(empty_list_item);
    const [textFocused, setTextFocused] =useState(false);

    const textRef = useRef(null);

    useEffect(() => {
        if (isDataNull(values) && !isDataLoading(values)) {
            setValues("loading");
            registriesContext.fetchItemizedListValues(itemizedListID).then((res) => {
                setValues(res);
                setCurrentItem(empty_list_item);
            })
        }
    }, [values]);

    const checkSystemParameters = (mItemizedListID) => {
        if (mItemizedListID === 1) registriesContext.resetSexItemizedListValues();
        if (mItemizedListID === 3) authContext.resetParamGroupItemizedListValues();
    }

    const onValueToAddChange = (e) => {
        const newCurrItem = {...currentItem, value: e.target.value};
        setCurrentItem(newCurrItem);
    }

    const cancelClicked = () => {
        setCurrentItem(empty_list_item);
        if (!textRef.current) return;
        textRef.current.focus();
    };

    const saveClicked = () => {
        registriesContext.updateItemizedListValue(itemizedListID, currentItem.id, currentItem.value).then(() => {
            registriesContext.fetchItemizedListValues(itemizedListID).then((res) => {
                setValues(res);
                setCurrentItem(empty_list_item);
                checkSystemParameters(itemizedListID);
            })
        })
    };

    const ExtraToolBar = (item) => {

        const editAction = () => {
            const selectedItem = {id: item.mItemizedListValueID, value: item.mValue};
            setCurrentItem(selectedItem);
        };

        const deleteAction = () => {
            registriesContext.deleteItemizedListValue(item.mItemizedListValueID).then((res) => {
                registriesContext.fetchItemizedListValues(itemizedListID).then((res) => {
                    setValues(res);
                    setCurrentItem(empty_list_item);
                    checkSystemParameters(itemizedListID)
                })
            })
        };

        return (
            <ActionsToolBar
                hasDelete deleteAction={deleteAction}
                hasEdit editAction={editAction}
            />
        )
    }

    if (isDataNull(values) || isDataLoading(values))  return <CircularWaiting/>

    return (
        <Grid
            container
            spacing={theme.spacing(2)}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
        >
            <Grid item xs={12} md={6}>
                <TextField
                    variant="filled"
                    label={"Valore"}
                    inputRef={textRef}
                    value={currentItem.value}
                    onChange={onValueToAddChange}
                    autoFocus
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Stack direction={"row"} spacing={theme.spacing(4)}>
                    <ActionButton
                        color="info"
                        variant="contained"
                        action={saveClicked}
                        size="small"
                        icon="save"
                        iconSize="medium"
                        tooltip={(currentItem.id===0) ? "Aggiungi" : "Salva"}
                        label={(currentItem.id===0) ? "Aggiungi" : "Salva"}
                        disabled={currentItem.value ===""}
                    />
                    <ActionButton
                        color="primary"
                        variant="contained"
                        action={cancelClicked}
                        size="small"
                        icon="reset"
                        iconSize="medium"
                        tooltip="Annulla"
                        label="Annulla"
                        disabled={currentItem.value === ""}
                    />

                </Stack>
            </Grid>
            <Grid item xs={12}>
                <List dense={true}>
                    {
                        values.map((item, index) => {
                            return (
                                <ListItem secondaryAction={ExtraToolBar(item)}>
                                    <ListItemAvatar>
                                        <Avatar
                                            sx={{bgcolor: theme.palette.secondary.main}}
                                        >
                                            {index + 1}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={item.mValue}/>
                                </ListItem>)
                        })
                    }
                </List>
            </Grid>
        </Grid>
    )
}
const ItemizedLists = () => {

    const authContext = useContext(AuthContext);
    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [record, setRecord] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const {
        register,
        control,
        handleSubmit,
        reset
    } = useForm({defaultValues: record});

    const [extraRecordId, setExtraRecordId] = useState(-1);

    useEffect(() => {
        if ((!dataLoaded) && !isDataLoading(registriesContext.itemizedLists)) registriesContext.fetchItemizedLists().then((res) => {
            setDataLoaded(true);
        })
    }, [registriesContext.itemizedLists]);

    //dettaglio

    const onSubmit = (data) => {
        registriesContext.updateItemizedList(data);
        setModalOpen(false);
    }

    const onError = (errors, e) => {
        console.log(errors, e);
    }

    const onClose = (event, reason) => {
        setModalOpen(false);
    }

    //griglia

    const addAction = () => {
        registriesContext.fetchItemizedList(0).then((res) => {
            setRecord(res);
            reset(res);
            setModalOpen(true);
        });
    }

    const ToolBar = (props) => {

        let selectedRow = props.node.data;

        const editAction = () => {
            registriesContext.fetchItemizedList(selectedRow.mItemizedListID).then((res) => {
                setRecord(res);
                reset(res);
                setModalOpen(true);
            });
        };

        const deleteAction = () => {
            alert(selectedRow.mItemizedListID);
        }

        const extraAction = () => {
            setExtraRecordId(selectedRow.mItemizedListID);
        };

        return (
            <ActionsToolBar
                hasEdit editAction={editAction}
                hasDelete deleteAction={deleteAction}
                hasExtra extraAction={extraAction} extraLabel={"Valori collegati"}
            />
        )
    }

    const [columns, setColumns] = useState([
        {headerName: "ID", field: "mItemizedListID"},
        {headerName: "Descrizione", field: "mSQLName"},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    const onExtraClose = () => {
        setExtraRecordId(-1);
    }

    const extraModalOpen = (extraRecordId !== -1);

    if (isDataNull(registriesContext.itemizedLists) || isDataLoading(registriesContext.itemizedLists)) return <CircularWaiting/>

    return (
        <>
            <Grid
                container
                spacing={0}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Liste</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{pb: theme.spacing(2)}}>
                        <Grid item xs={12}>
                            <Stack
                                direction={"row"}
                                justifyContent={"flex-end"}
                                spacing={theme.spacing(2)}
                                sx={{pt: theme.spacing(1)}}>
                                <AddButton addAction={addAction}/>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                        <AgGridReact
                            rowData={registriesContext.itemizedLists}
                            defaultColDef={defaultColDef}
                            columnDefs={columns}
                            columnTypes={columnTypes}
                            suppressMovableColumns={true}
                            suppressCellFocus={true}
                            pagination={true}
                            rowSelection={'single'}
                            getRowHeight={(params) => gridRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
            <CardModal
                modalOpen={modalOpen}
                onClose={onClose}
                title={"Dettaglio lista"}
                size={"small"}
                formFunctions={{submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError}}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <FormTextBox
                        classes={loggedClasses}
                        label="Descrizione"
                        control={control}
                        record={record}
                        register={register}
                        field="mSQLName"
                        required={true}
                        maxLength={50}
                    />
                </CardContent>
            </CardModal>
            <CardModal
                modalOpen={extraModalOpen}
                onClose={onExtraClose}
                title={"Valori lista"}
                size={"small"}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <ItemizedListEditor itemizedListID={extraRecordId}/>
                </CardContent>
            </CardModal>
        </>
    )

}

export default ItemizedLists;