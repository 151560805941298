import moment from "moment/moment";
import React, {createContext, useContext, useEffect, useState} from 'react';
import {
    emptyDoctor,
    getCompleteName,
    isDataLoading,
    isDataNull,
    JSONClone, moneyStringToFloat,
    nullDate,
    nullDateTime,
    setDataLoading
} from "../common";
import * as webServices from "../services/WebServices";
import {CustomAlertContext} from "./AlertContext";
import {AuthContext} from "./AuthContext";
import {
    wsCheckCompanyPayments,
    wsGetPatientDataFromCF,
    wsGetReportsOutputModes,
    wsSetCompanyPlanPayed
} from "../services/WebServices";

const RegistriesContext = createContext();

const newCity = {
    mCityID: 0,
    mCityName: "Nuova città"
}

const newDistrict = {
    mProvID: 0,
    mProvName: "Nuova provincia",
    mProvTarga: "",
    mISTATCode: ""
}

const newRegion = {
    mRegionID: 0,
    mRegionName: ""
}

const newUser = {
    mUserID: 0,
    mUserName: "Username (email)",
    mMail: "",
    mMobilePhone: "",
    mFirstName: "Nome",
    mLastName: "",
    mHeaderReferto: "",
    mFooterReferto: "",
    mProfile: null,
    mCompany: null
}

const newModality = {
    mModalityID: 0,
    mModalityCode: "NEW",
    mModalityDescription: "Nuova modalità",
    mDefaultDoctor: null
}

const newSystemFunction = {
    mFunctionID: 0,
    mFunctionName: "Nuova funzione",
    mFunctionDescription: "Nuova funzione",
    mComponentName: "",
    mProfileID: 0,
    mShowOnDesktop: false,
    mDomain: null
}

const newDomain = {
    mDomainID: 0,
    mDomainName: "Nuovo dominio"
}

const newProfile = {
    mProfileID: 0,
    mProfileName: "Nuovo profilo",
    mAvailableToRegistration: false
}

const newCompany = {
    mCompanyID: 0,
    mCompanyName: "Nuova Azienda",
    mAddress: "",
    mTelefono: "",
    mCodiceFisale: "",
    mPartitaIVA: "",
    mCity: null,
    mCAP: "",
    mLegalMail: "",
    mMail: "",
    mParent: null,
    mType: "COMPANY",
    mStartDate: moment(nullDateTime),
    mDurata: 0,
    mImporto: 0
}

const newAslCompany = {
    mCompanyID: 0,
    mCompanyName: "Nuova Asl",
    mAddress: "",
    mTelefono: "",
    mCodiceFisale: "",
    mPartitaIVA: "",
    mCity: null,
    mCAP: "",
    mLegalMail: "",
    mMail: "",
    mParent: null,
    mType: "ASL"
}

const newBank = {
    mBankID: 0,
    "mBankName": "Nuova banca",
    "mBankIBAN": "IT"
}

const newBranch = {
    mBrancaID: 0,
    mBrancaCode: "NEW",
    mBrancaName: "Nuova branca"
}

const newCounter = {
    mCounterID: 0,
    mCounterCode: "NEW",
    mCounterDescription: "Nuova contatore",
    mActualValue: 0,
    mPrefix: "-"
}

const newConvention = {
    "mConventionID": 0,
    "mConventionCode": "COD",
    "mConventionDescription": "Nuova convenzione",
    // "mPublisherCompany": null,
    // "mSubscriberCompany": null,
    "mEnabled": true,
    "mIsASL": false
}

const newExemption = {
    mEsenzioneID: 0,
    mEsenzioneCode: "Codice",
    mEsenzioneDescription: "Descrizione",
    mTipoEsenzione: null
}

const newExemptionType = {
    mTipoEsenzioneID: 0,
    mCODICE_TIPO: "CODICE",
    mDESC_TIPO: "Descrizione",
    mTipoPagamento: null
}

const newItemizedList = {
    mItemizedListID: 0,
    mSQLName: "Nuovo Itemized List"
}

const newItemizedListValue = {
    mItemizedListValueID: 0,
    mValue: "Nuovo Valore Itemized List"
}

const newMedicalService = {
    mPrestazioneID: 0,
    mDescrizione: "Nuova prestazione",
    mDurata: 0,
    mClasse: "",
    mNumerosita: 1,
    mTipo: null,
    mModality: null,
    mBranca: null
}

const newReport = {
    mReportID: 0,
    mReportCode: "000",
    mLabel: "Nuovo report",
    mReportGroup: "Nuovo gruppo",
    mQuery: "SELECT * FROM"
}

const newRoom = {
    mRoomID: 0,
    mRoomName: "Nuova stanza"
}

const newMedicalServiceType = {
    mTipoPrestazioneID: 0,
    mTipoDescription: "Nuovo tipo di prestazione"
}

const newDevice = {
    mAETitleID: 0,
    mAETitleName: "Nuovo nodo DICOM",
    mType: "Apparecchiatura"
}

const newTownHall = {
    mMunicipioID: 0
}

const newCountry = {
    mCountryID: 0,
    mCountryName: "Nuovo stato",
    mCountryCode: "",
    mCountryAnagraphicCode: ""
}

let newPatient = {
    mPatientID: 0,
    mPatientName: "Nuovo paziente",
    mASLName: "",
    mBirthCountry: null,
    mLiveCountry: null,
    mCitizenOf: null
}

const newSponsor = {
    mSponsorID: 0,
    mSponsorFirstName: "",
    mSponsorLastName: "",
    mSponsorPhone: "-",
    // mASLCode": "009876",
    mTipoSoggettoPrescittore: null
}

const newSponsorType = {
    mTipoSoggettoPrescrittoreID: 0,
    mCodice: "",
    mDescrizione: ""
}

const defaultPatientServerSideFilter = {
    mNomePaziente: "",
    mCognomePaziente: "",
    mCF: ""
}

const initialConvention = {mConventionID: 0, mConventionDescription: ""}

const initialMedicalService = {mPrestazioneID: 0, mDescrizione: ""}

const RegistriesProvider = (props) => {

    const alertContext = useContext(CustomAlertContext);
    const authContext = useContext(AuthContext);

    const [banks, setBanks] = useState(null);
    const [payingMethods, setPayingMethods] = useState(null);
    const [cities, setCities] = useState(null);
    const [districts, setDistricts] = useState(null);
    const [regions, setRegions] = useState(null);
    const [countries, setCountries] = useState(null);
    const [italy, setItaly] = useState(JSONClone(newCountry));
    const [modalities, setModalities] = useState(null);
    const [doctors, setDoctors] = useState(null);
    const [users, setUsers] = useState(null);
    const [functionalDomains, setFunctionalDomains] = useState(null);
    const [systemFunctions, setSystemFunctions] = useState(null);
    const [profiles, setProfiles] = useState(null);
    const [domains, setDomains] = useState(null);
    const [aslCompanies, setAslCompanies] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [allCompanies, setAllCompanies] = useState(null);
    const [branches, setBranches] = useState(null);
    const [counters, setCounters] = useState(null);
    const [conventions, setConventions] = useState(null);
    const [enabledConventions, setEnabledConventions] = useState(null);
    const [exemptions, setExemptions] = useState(null);
    const [itemizedLists, setItemizedLists] = useState(null);
    const [itemizedListValues, setItemizedListValues] = useState(null);
    const [sexItemizedListValues, setSexItemizedListValues] = useState(null);
    const [attachTypeItemizedListValues, setAttachTypeItemizedListValues] = useState(null);
    const [moduleTypeItemizedListValues, setModuleTypeItemizedListValues] = useState(null);
    const [medicalServices, setMedicalServices] = useState(null);
    const [classesListValues, setClassesListValues] = useState(null);
    const [roomMedicalServices, setRoomMedicalServices] = useState(null);
    const [activeMedicalServices, setActiveMedicalServices] = useState(null);
    const [reports, setReports] = useState(null);
    const [reportsInputModes, setReportsInputModes] = useState(null);
    const [reportsOutputModes, setReportsOutputModes] = useState(null);
    const [reportsGroupItemizedListValues, setReportsGroupItemizedListValues] = useState(null);
    const [rooms, setRooms] = useState(null);
    const [roomsFilter, setRoomsFilter] = useState("");
    const [roomDevices, setRoomDevices] = useState(null);
    const [devices, setDevices] = useState(null);
    const [patients, setPatients] = useState(null);
    const [patientsCurrentPage, setPatientsCurrentPage] = useState(1);
    const [patientsTotalRows, setPatientsTotalRows] = useState(0);
    const [patientsTotalPages, setPatientsTotalPages] = useState(0);
    const [patientsPageSize, setPatientsPageSize] = useState(0);
    const [patientsServerSideFilter, setPatientsServerSideFilter] = useState(JSONClone(defaultPatientServerSideFilter));
    const [sponsors, setSponsors] = useState(null);
    const [townHalls, setTownHalls] = useState(null);
    const [paymentTypes, setPaymentTypes] = useState(null);
    const [exemptionTypes, setExemptionTypes] = useState(null);
    const [supplierTypes, setSupplierTypes] = useState(null);
    const [sponsorTypes, setSponsorTypes] = useState(null);
    const [medicalServiceTypes, setMedicalServiceTypes] = useState(null);
    const [prioritiesItemizedListValues, setPrioritiesItemizedListValues] = useState(null);
    const [zoneResponsables, setZoneResponsables] = useState(null);
    const [localResponsables, setLocalResponsables] = useState(null);
    const [smsPackagesItemizedListValues, setSmsPackagesItemizedListValues] = useState(null);

    const [baseRegistriesLoaded, setBaseRegistriesLoaded] = useState(false);
    const [registriesLoaded, setRegistriesLoaded] = useState(false);

    useEffect(() => {
        if (!authContext.checkIsLogged()) return;
        if (isDataNull(cities)) fetchCities();
        if (isDataNull(countries)) fetchCountries();
        if (isDataNull(rooms)) fetchRooms();
        if (isDataNull(conventions)) fetchConventions();
        if (isDataNull(enabledConventions)) fetchEnabledConventions();
        if (!italy.loaded) fetchItaly();
        if (isDataNull(aslCompanies)) fetchAslCompanies();
        if (isDataNull(sponsors)) fetchSponsors();
        if (isDataNull(townHalls)) fetchTownHalls();
        if (isDataNull(sexItemizedListValues)) fetchSexItemizedListValues();
        if (isDataNull(activeMedicalServices)) fetchActiveMedicalServices();
        if (!baseRegistriesLoaded) setBaseRegistriesLoaded(true);
    }, [authContext.sessionRefreshSwitch]);

    useEffect(() => {
        if (patientsCurrentPage > 1) fetchPatients(false).then(() => {
        });
    }, [patientsCurrentPage]);

    const resetBaseRegistries = () => {
        setCities(null);
        setCountries(null);
        setRooms(null);
        setConventions(null);
        setItaly(JSONClone(newCountry));
        setAslCompanies(null);
        setSponsors(null);
        setTownHalls(null);
        setSexItemizedListValues(null);
        setActiveMedicalServices(null);
    }

    const normalizeCity = (city) => {
        let name = "";
        if (city.mCityName) name += city.mCityName.toUpperCase();
        if (city.mTargaProvincia) name += " (" + city.mTargaProvincia.toUpperCase() + ")";
        return {...city, mCompleteName: name};
    }

    const fetchCities = () => {
        setDataLoading(setCities);
        webServices.wsGetCities({}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            recs = recs.map((city) => {
                return normalizeCity(city)
            });
            setCities(recs);
            return recs;
        })
    }

    const fetchCitiesAsync = async () => {
        setDataLoading(setCities);
        const res = await webServices.wsGetCities({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        recs = recs.map((city) => {
            return normalizeCity(city)
        });
        setCities(recs);
        return recs;
    }

    const fetchCity = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetCities({mCityID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newCity;
        } else {
            return newCity;
        }
    }

    const fetchCityWithAslAsync = async (recordId) => {
        const res = await webServices.wsGetCities({mCityID: recordId, mQueryLevel: 1}, alertContext, true);
        if (res.responseAnyError) return {};
        let recs = res.responseData;
        return (recs.length !== 0) ? recs[0] : null;
    }

    const updateCity = (record) => {
        webServices.wsUpdateCity(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchCities();
        });
    };

    const fetchDistricts = async () => {
        setDataLoading(setDistricts);
        const res = await webServices.wsGetDistricts({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setDistricts(recs);
        return recs;
    }

    const fetchDistrict = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetDistricts({mProvID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newDistrict;
        } else {
            return newDistrict;
        }
    }

    const updateDistrict = (record) => {
        webServices.wsUpdateDistrict(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchDistricts();
        });
    };

    const fetchRegions = async () => {
        setDataLoading(setRegions);
        const res = await webServices.wsGetRegions({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setRegions(recs);
        return recs;
    }

    const fetchRegion = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetRegions({mRegionID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newRegion;
        } else {
            return newRegion;
        }
    }

    const updateRegion = (record) => {
        webServices.wsUpdateRegion(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchRegions();
        });
    };

    const fetchCountries = () => {
        setDataLoading(setCountries);
        webServices.wsGetCountries({}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setCountries(recs);
        });
    }

    const fetchCountriesAsync = async () => {
        setDataLoading(setCountries);
        const res = await webServices.wsGetCountries({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setCountries(recs);
        return recs;
    }

    const fetchItaly = () => {
        webServices.wsGetCountries({mCountryName: 'Italia'}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            let tmpCountry = {};
            if (recs.length !== 0) {
                tmpCountry.loaded = true;
                tmpCountry.country = recs[0];
                setItaly(JSONClone(tmpCountry));
            }
        });
    }

    const fetchCountry = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetCountries({mCountryID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newCountry;
        } else {
            return newCountry;
        }
    }

    const updateCountry = (record) => {
        webServices.wsUpdateCountry(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchCountries();
        });
    };

    const normalizeUser = (user) => {
        return {
            ...user,
            mCompleteName: getCompleteName(user.mFirstName, user.mLastName),
            mActive: (user.mLoginStatus ?? "") === "Attivo"
        };
    }

    const fetchUsers = async () => {
        setDataLoading(setUsers);
        const res = await webServices.wsGetUsers({}, alertContext, true)
        if (res.responseAnyError) return;
        let recs = res.responseData;
        recs = recs.map((user) => {
            return normalizeUser(user);
        });
        setUsers(recs);
        return recs;
    };

    const fetchUser = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetUsers({mUserID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? normalizeUser(recs[0]) : newUser;
        } else {
            return newUser;
        }
    };

    const updateUser = async (record) => {
        let res = await webServices.wsUpdateUser(record, alertContext, true);
        if (res.responseAnyError) return;
        fetchUsers();
        fetchProfiles();
        setZoneResponsables(null);
        setLocalResponsables(null);
    };

    const fetchZoneResponsables = () => {
        setDataLoading(setZoneResponsables);
        const profile = (authContext.getLoggedUserProfile().mSQLName ?? "");
        let body = {}
        body.mProfile = {mSQLName: "RESP_TERR"};
        webServices.wsGetUsers(body, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            recs = recs.map((user) => {
                return normalizeUser(user);
            });
            setZoneResponsables(recs);
        });
    };

    const fetchLocalResponsables = () => {
        setDataLoading(setLocalResponsables);
        const profile = (authContext.getLoggedUserProfile().mSQLName ?? "");
        let body = {}
        body.mProfile = {mSQLName: "RESP_SEDE"};
        webServices.wsGetUsers(body, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            recs = recs.map((user) => {
                return normalizeUser(user);
            });
            setLocalResponsables(recs);
        });
    };

    const fetchDoctors = () => {
        setDataLoading(setDoctors);
        const body = {"mProfile": {"mSQLName": "DOCTOR"}};
        webServices.wsGetUsers(body, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            recs = recs.map((doctor) => {
                return normalizeUser(doctor)
            });
            recs.push(emptyDoctor);
            setDoctors(recs);
        });
    };

    const fetchDoctorFees = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetDoctorFees({mDoctorID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            recs = recs.sort((a, b) => ((a.mFee ?? {}).mValue  ?? 0) > ((b.mFee ?? {}).mValue ?? 0) ? -1 : 1);
            return recs;
        } else {
            return [];
        }
    };

    const updateDoctorFees = async (record) => {
        const res = await webServices.wsUpdateDoctorFees(record, alertContext, true);
        if (res.responseAnyError) return;
    };

    const updateDefaultDoctorFee = async (record) => {
        const res = await webServices.wsUpdateDefaultDoctorFee(record, alertContext, true);
        if (res.responseAnyError) return;
    };
    const fetchModalities = async () => {
        setDataLoading(setModalities);
        const res = await webServices.wsGetModalities({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        recs = recs.map((modality) => {
            return normalizeModality(modality)
        });
        setModalities(recs);
        return recs;
    };
    const normalizeModality = (modality) => {
        if (modality.mDefaultDoctor) {
            return {
                ...modality,
                mCompleteName: getCompleteName(modality.mDefaultDoctor.mFirstName, modality.mDefaultDoctor.mLastName)
            };
        } else {
            return {...modality, mCompleteName: "NON IMPOSTATO"};
        }
    }

    const fetchModality = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetModalities({mModalityID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? normalizeModality(recs[0]) : newModality;
        } else {
            return newModality;
        }

    };

    const updateModality = (record) => {
        webServices.wsUpdateModality(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchModalities();
        });
    };

    const fetchFunctionalDomains = () => {
        setDataLoading(setFunctionalDomains);
        webServices.wsGetDomains({}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setFunctionalDomains(recs);
        });
    }

    const fetchSystemFunctions = async () => {
        setDataLoading(setSystemFunctions);
        const res = await webServices.wsGetSystemFunctions({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setSystemFunctions(recs);
        return recs;
    }

    const fetchSystemFunction = async (recordId) => {
        if (recordId !== 0) {
            let res = await webServices.wsGetSystemFunctions({mFunctionID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newSystemFunction;
        } else {
            return newSystemFunction;
        }
    }

    const updateSystemFunction = (record) => {
        webServices.wsUpdateSystemFunction(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchSystemFunctions();
        });
    };

    const deleteSystemFunction = (record) => {
        webServices.wsDeleteSystemFunction(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchSystemFunctions();
        });
    };

    const fetchDomains = () => {
        setDataLoading(setDomains);
        webServices.wsGetDomains({}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setDomains(recs);
        });
    }

    const fetchDomainsAsync = async () => {
        setDataLoading(setDomains);
        const res = await webServices.wsGetDomains({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setDomains(recs);
        return recs;
    }

    const fetchDomain = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetDomains({mDomainID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newDomain;
        } else {
            return newDomain;
        }
    }

    const updateDomain = (record) => {
        webServices.wsUpdateDomain(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchDomains();
        });
    };

    const deleteDomain = (record) => {
        webServices.wsDeleteDomain(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchDomains();
        });
    };

    const fetchProfiles = async () => {
        setDataLoading(setProfiles);
        const res = await webServices.wsGetProfiles({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setProfiles(recs);
        return recs;
    }

    const fetchProfile = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetProfiles({mProfileID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newProfile;
        } else {
            return newProfile;
        }
    }

    const updateProfile = (record) => {
        record.mSonicPrice = parseInt(record.mSonicPrice);
        webServices.wsUpdateProfile(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchProfiles();
        });
    };

    const addProfileFunction = async (pFunctionID, pProfileID) => {
        const res = await webServices.wsAddProfileFunction({
            mFunctionID: pFunctionID,
            "mProfileID": pProfileID
        }, alertContext, true);
        return true;
    };

    const removeProfileFunction = async (pFunctionID, pProfileID) => {
        const res = await webServices.wsRemoveProfileFunction({
            mFunctionID: pFunctionID,
            "mProfileID": pProfileID
        }, alertContext, true);
        return true;
    };

    const normalizeCompany = (company) => {
        company = (company.city) ?
            {
                ...company,
                mCompleteCity: (company.mCity.mCityName ?? "").toUpperCase() + " - " + (company.mCity.mTargaProvincia ?? "").toUpperCase()
            } :
            {...company, mCompleteCity: "NON IMPOSTATA"};
        company = (company.mLandManager) ?
            {
                ...company,
                mLandManager: {
                    ...company.mLandManager,
                    mCompleteName: getCompleteName(company.mLandManager.mFirstName, company.mLandManager.mLastName)
                }
            } :
            {...company, mLandManager: {mCompleteName: "NON IMPOSTATO"}};
        company = (company.mSiteManager) ?
            {
                ...company,
                mSiteManager: {
                    ...company.mSiteManager,
                    mCompleteName: getCompleteName(company.mSiteManager.mFirstName, company.mSiteManager.mLastName)
                }
            } :
            {...company, mSiteManager: {mCompleteName: "NON IMPOSTATO"}};

        return company;
    }

    const fetchAslCompanies = () => {
        setDataLoading(setAslCompanies);
        webServices.wsGetCompanies({mType: "ASL"}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            recs = recs.map((company) => {
                return normalizeCompany(company);
            });
            setAslCompanies(recs);
            return recs;
        })
    }

    const fetchAslCompaniesAsync = async () => {
        setDataLoading(setAslCompanies);
        let res = await webServices.wsGetCompanies({mType: "ASL"}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        recs = recs.map((company) => {
            return normalizeCompany(company);
        });
        setAslCompanies(recs);
        return recs;
    }

    const fetchAslCompany = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetCompanies({
                mCompanyID: recordId,
                "mType": "ASL"
            }, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newAslCompany;
        } else {
            return newAslCompany;
        }
    }

    const updateAslCompany = (record) => {
        record.mType = "ASL";
        webServices.wsUpdateCompany(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchAslCompanies();
        });
    };

    const fetchCompanies = () => {
        setDataLoading(setCompanies);
        webServices.wsGetCompanies({mType: "COMPANY"}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            recs = recs.map((company) => {
                return normalizeCompany(company)
            });
            setCompanies(recs);
        });
    }

    const fetchCompaniesAsync = async () => {
        setDataLoading(setCompanies);
        const res = await webServices.wsGetCompanies({mType: "COMPANY"}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        recs = recs.map((company) => {
            return normalizeCompany(company)
        });
        setCompanies(recs);
        return recs;
    }

    const fetchCompany = async (recordId) => {
        if (recordId !== 0) {
            const longDateTimeFormat = authContext.getSystemParameterValue("DATETIME_FORMAT_LONG");
            const res = await webServices.wsGetCompanies({mCompanyID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            let rec = (recs.length !== 0) ? recs[0] : newCompany;
            if (!rec.mStartDate) {
                rec.mStartDate = moment(nullDate, longDateTimeFormat)
            }
            return rec;
        } else {
            return newCompany;
        }
    }

    const updateCompany = async (record) => {
        const longDateTimeFormat = authContext.getSystemParameterValue("DATETIME_FORMAT_LONG");
        record.mStartDate = moment(record.mStartDate).format(longDateTimeFormat);
        record.mDurata = (record.mDurata && record.mDurata !== 0) ? parseInt(record.mDurata) : 0;
        record.mImporto = parseFloat(record.mImporto);
        record.mType = "COMPANY";
        const res = await webServices.wsUpdateCompany(record, alertContext, true);
        if (res.responseAnyError) return;
        fetchCompanies();
        return res;
    };

    const createCompanyPlan = async (recordId) => {
        const res = await webServices.wsCreateCompanyPlan({mCliente: {mCompanyID: recordId}}, alertContext, false);
        if (res.responseAnyError) return;
        return res;
    };

    const fetchCompanyPlan = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetCompanyPlan({mCliente: {mCompanyID: recordId}}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return recs;
        } else {
            return null;
        }
    }

    const setCompanyPlanPayed = async (recordId) => {
        const res = await webServices.wsSetCompanyPlanPayed({mPlanID: recordId}, alertContext, false);
        if (res.responseAnyError) return;
        return res;
    };

    const checkCompanyPayments = async () => {
        const res = await webServices.wsCheckCompanyPayments({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        return recs;
    }

    const fetchBanks = async () => {
        setDataLoading(setBanks);
        const res = await webServices.wsGetBanks({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setBanks(recs);
        return recs;
    }

    const fetchBank = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetBanks({mBankID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newBank;
        } else {

            return newBank;
        }
    }

    const updateBank = (record) => {
        webServices.wsUpdateBank(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchBanks();
        });
    };

    const fetchBranches = async () => {
        setDataLoading(setBranches);
        const res = await webServices.wsGetBranches({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setBranches(recs);
        return recs;
    };

    const fetchBranch = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetBranches({mBrancaID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newBranch;
        } else {
            return newBranch;
        }
    };

    const updateBranch = (record) => {
        webServices.wsUpdateBranch(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchBranches();
        });
    };

    const fetchCounters = async () => {
        const res = await webServices.wsGetCounters({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setCounters(recs);
        return recs;
    };

    const fetchCounter = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetCounters({mCounterID: recordId}, alertContext, true)
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newCounter;
        } else {
            return newCounter;
        }

    };

    const updateCounter = (record) => {
        webServices.wsUpdateCounter(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchCounters();
        });
    };

    const fetchConventions = () => {
        setDataLoading(setConventions);
        webServices.wsGetConventions({}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setConventions(recs);
            return recs;
        })
    };

    const fetchConventionsAsync = async () => {
        setDataLoading(setConventions);
        const res = await webServices.wsGetConventions({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setConventions(recs);
        return recs;
    };

    const fetchEnabledConventions = () => {
        setDataLoading(setEnabledConventions);
        const pBody = {mEnabled: true};
        webServices.wsGetConventions(pBody, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setEnabledConventions(recs);
            return recs;
        })
    };

    const getUniqueActiveConvention = () => {
        if (enabledConventions && enabledConventions.length === 1) {
            return enabledConventions[0];
        } else return null;
    }

    const fetchConvention = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetConventions({mConventionID: recordId}, alertContext, true)
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newConvention;
        } else {
            return newConvention;
        }
    };

    const updateConvention = (record) => {
        webServices.wsUpdateConvention(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchConventions();
            fetchEnabledConventions();
        });
    };

    const deleteConvention = (record) => {
        webServices.wsDeleteConvention(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchConventions();
        });
    }


    const duplicateConvention = async (recordId) => {
        const oldRecord = await fetchConvention(recordId);
        const body = {
            mConventionID: recordId,
            mConventionDescription: "Copia di " + oldRecord.mConventionDescription,
            mConventionCode: oldRecord.mConventionCode + "-COPY"
        }
        const res = await webServices.wsDuplicateConvention(body, alertContext, true);
        if (res.responseAnyError) return;
        let rec = res.responseData;
        return rec;
    }

    const fetchExemptions = () => {
        setDataLoading(setExemptions);
        webServices.wsGetExemptions({}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setExemptions(recs);
        });
    }

    const fetchExemptionsAsync = async () => {
        setDataLoading(setExemptions);
        const res = await webServices.wsGetExemptions({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setExemptions(recs);
        return recs;
    }

    const fetchExemption = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetExemptions({mExemptionID: recordId}, alertContext, true)
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newExemption;
        } else {
            return newExemption;
        }
    }

    const updateExemption = (record) => {
        webServices.wsUpdateExemption(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchExemptions();
        });
    };

    const fetchItemizedLists = async () => {
        setDataLoading(setItemizedLists);
        const res = await webServices.wsGetItemizedLists({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setItemizedLists(recs);
        return recs;
    }

    const fetchItemizedList = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetItemizedLists({mItemizedListID: recordId}, alertContext, true)
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newItemizedList;
        } else {
            return newItemizedList;
        }
    }

    const updateItemizedList = (record) => {
        webServices.wsUpdateItemizedList(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchItemizedLists();
        });
    };

    const fetchItemizedListValues = async (mItemizedListID) => {
        let body = {};
        body.mItemizedList = {mItemizedListID: mItemizedListID};
        const res = await webServices.wsGetItemizedListValues(body, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        // setItemizedListValues(recs);
        return recs;
    }

    const fetchItemizedListValue = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetItemizedListValues({mItemizedListValueID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newItemizedListValue;
        } else {
            return newItemizedListValue;
        }
    }

    const updateItemizedListValue = async (mItemizedListID, mItemizedListValueID, mValue) => {
        let body = {};
        body.mItemizedList = {mItemizedListID: mItemizedListID};
        body.mItemizedListValueID = mItemizedListValueID;
        body.mValue = mValue;
        let res = await webServices.wsUpdateItemizedListValue(body, alertContext, true);
        if (res.responseAnyError) return;
        return true;
    };

    const deleteItemizedListValue = async (mItemizedListValueID) => {
        const res = await webServices.wsDeleteItemizedListValue({mItemizedListValueID: mItemizedListValueID}, alertContext, true);
        if (res.responseAnyError) return;
        return true;
    };

    const fetchPayingMethodsItemizedListValues = () => {
        setDataLoading(setPayingMethods);
        let body = {};
        body.mItemizedList = {mItemizedListID: 10};
        webServices.wsGetItemizedListValues(body, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setPayingMethods(recs);
        });
    }

    const fetchMedicalServices = async () => {
        setDataLoading(setMedicalServices);
        const res = await webServices.wsGetMedicalServices({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setMedicalServices(recs);
        return recs;
    }

    const normalizeMedicalService = (ms) => {
        const slotDurationConfig = authContext.getSystemParameterValue("SLOT_SIZE");
        return {...ms, mDurataMinuti: ms.mDurata * slotDurationConfig};
    }

    const fetchMedicalService = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetMedicalServices({mPrestazioneID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;

            return (recs.length !== 0) ? normalizeMedicalService(recs[0]) : newMedicalService;
        } else {
            return newMedicalService;
        }
    }

    const fetchMedicalServiceDetails = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetMedicalServices({mPrestazioneID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return normalizeMedicalService(recs[0]);
        } else {
            return 0;
        }
    }

    const fetchMedicalServicePrice = async (medicalServiceID, conventionID) => {

        if (medicalServiceID !== 0 && conventionID !== 0) {
            const body = {
                mPrestazione: {mPrestazioneID: medicalServiceID},
                mConvenzione: {mConventionID: conventionID}
            }
            const res = await webServices.wsGetMedicalServicePrice(body, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return recs;
        } else {
            return 0;
        }
    }

    const fetchAllMedicalServicePrices = async (medicalServiceID) => {
        if (medicalServiceID !== 0) {
            const body = {mPrestazione: {mPrestazioneID: medicalServiceID}}
            const res = await webServices.wsGetMedicalServicePrices(body, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return recs;
        } else {
            return [];
        }
    }

    const updateMedicalServicePrice = async (pPriceRow) => {
        const body = {
            ...pPriceRow,
            mPrice: moneyStringToFloat(pPriceRow.mPrice),
        }
        const res = await webServices.wsSetMedicalServicePrice(body, alertContext, true);
        if (res.responseAnyError) return false;
        return true;
    }

    const updateMedicalService = async (pBody) => {
        pBody.mDurata = parseInt(pBody.mDurata);
        const res = await webServices.wsUpdateMedicalService(pBody, alertContext, true);
        if (res.responseAnyError) return;
        return true;
    };

    const updateMedicalServiceCodes = async (pBody, mCodicePadre, mCodiceFiglio) => {
        pBody.mCodicePadre = mCodicePadre;
        pBody.mCodiceFiglio = mCodiceFiglio;
        const res = await webServices.wsUpdateMedicalService(pBody, alertContext, true);
        if (res.responseAnyError) return;
        return true;
    };

    const fetchRoomMedicalServices = async (roomId) => {
        setDataLoading(setRoomMedicalServices);
        const res = await webServices.wsGetRoomMedicalServices({mRoomID: roomId}, alertContext, true);
        if (res.responseAnyError) return;
        const resData = res.responseData;
        const recs = resData;
        setRoomMedicalServices(recs);
        return recs;
    }

    const fetchRoomAndBranchMedicalServices = async (roomId, branchId) => {
        setDataLoading(setRoomMedicalServices);
        const res = await webServices.wsGetRoomMedicalServices({mRoomID: roomId, mBrancaID: branchId }, alertContext, true);
        if (res.responseAnyError) return;
        const resData = res.responseData;
        const recs = resData;
        setRoomMedicalServices(recs);
        return recs;
    }

    const resetMedicalServices = async () => {
        setRoomMedicalServices(null);
    }

    const fetchActiveMedicalServices = () => {
        setDataLoading(setActiveMedicalServices);
        webServices.wsGetActiveMedicalServices({}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setActiveMedicalServices(recs);
            return recs;
        })
    }

    const fetchActiveMedicalServicesAsync = async (roomId) => {
        setDataLoading(setActiveMedicalServices);
        const res = await webServices.wsGetActiveMedicalServices({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setActiveMedicalServices(recs);
        return recs;
    }

    const fetchRooms = () => {
        setDataLoading(setRooms);
        webServices.wsGetRooms({}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setRooms(recs);
            return recs;
        });
    }

    const fetchRoomsAsync = async () => {
        setDataLoading(setRooms);
        const res = await webServices.wsGetRooms({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setRooms(recs);
        return recs;
    }

    const fetchRoom = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetRooms({mRoomID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newRoom;
        } else {
            return newRoom;
        }
    }

    const updateRoom = (record) => {
        webServices.wsUpdateRoom(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchRooms();
        });
    };

    const fetchRoomsByMedicalService = async (pMedicalServiceID) => {
        //prestazione
        let body = {}
        body.mPrestazioneID = pMedicalServiceID;
        const resMedicalServices = await webServices.wsGetMedicalServices(body, alertContext, true);
        if (resMedicalServices.responseAnyError) return;
        let medicalService = (resMedicalServices.responseData.length !== 0) ? resMedicalServices.responseData[0] : newMedicalService;
        //stanze
        const resRoomMedicalService = await webServices.wsGetRoomsByMedicalService(body, alertContext, true);
        if (resRoomMedicalService.responseAnyError) return;
        medicalService.mActiveRooms = resRoomMedicalService.responseData;
        return medicalService;
    }

    const fetchDefaultRoom = (recordId) => {
        //recupero parametro
        //se esiste filtro le active rooms per mRoomID
        //altrimenti torno -1
        const activeRooms = getActiveRooms();
        if ((activeRooms ?? []).length===0) return null;
        let defaultRoomID = authContext.getSystemParameterValue("DEFAULT_RESERVATION_ROOM");
        defaultRoomID = defaultRoomID ? parseInt(defaultRoomID) : null;
        return (defaultRoomID) ? activeRooms.find(r => r.mRoomID === defaultRoomID) : null;
    }

    const getActiveRooms = () => {
        if ((rooms ?? []).length === 0) {
            return [];
        }
        if (isDataLoading(rooms)) {
            return [];
        }
        let filteredRooms = rooms.filter(r => (r.mActive ?? false) && r.mRoomName.toLowerCase().includes(roomsFilter.toLowerCase()));
        return filteredRooms;
    }

    const fetchDevices = async () => {
        setDataLoading(setDevices);
        const res = await webServices.wsGetDevices({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setDevices(recs);
        return recs;
    }

    const fetchRoomDevices = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetDevices({mRoom: {mRoomID: recordId}}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return recs;
        } else {
            return [];
        }
    }

    const fetchDevice = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetDevices({mAETitleID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newDevice;
        } else {
            return newDevice;
        }
    }

    const duplicateDevice = async (recordId) => {
        const oldDevice = await fetchDevice(recordId);
        const body = {
            mAETitleID: recordId,
            mAETitleName: "Copia di " + oldDevice.mAETitleName
        }
        const res = await webServices.wsDuplicateDevice(body, alertContext, true);
        if (res.responseAnyError) return;
        let rec = res.responseData;
        return rec;
    }

    const updateDevice = (record) => {
        webServices.wsUpdateDevice(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchDevices();
        });
    };

    const deleteDevice = (record) => {
        webServices.wsDeleteDevice(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchDevices();
        });
    };

    const updateDeviceMedicalServices = async (record) => {
        let res = await webServices.wsUpdateDeviceMedicalServices(record, alertContext, true);
        if (res.responseAnyError) return;
        fetchDevices();
    };

    const fetchPatients = async (bReset, newServerSideFilter) => {
        setDataLoading(setPatients);
        const body = (newServerSideFilter) ? newServerSideFilter : (bReset) ? defaultPatientServerSideFilter : patientsServerSideFilter;
        body.mRequestedPage = (bReset ?? false) ? 1 : patientsCurrentPage;
        setPatientsServerSideFilter(body);
        const res = await webServices.wsGetPatients(body, alertContext, true);
        if (res.responseAnyError) return;
        setPatientsTotalRows(res.responseDataRowsCount ?? 0);
        setPatientsTotalPages(res.responseDataPagesCount ?? 0);
        setPatientsCurrentPage(res.responseDataCurrentPage ?? 0);
        setPatientsPageSize(res.responseDataPageSize ?? 0);
        let recs = res.responseData;
        recs = recs.map((item) => {
            return calculatePatientASL(item);
        });
        setPatients(recs);
        return recs;
    }

    const normalizePatient = (patient) => {
        const shortDateFormat = authContext.getSystemParameterValue("DATE_FORMAT_SHORT")
        return (patient.mBirthDate) ?
            {...patient, mBirthDateString: moment(patient.mBirthDate).format(shortDateFormat)}
            :
            {...patient, mBirthDateString: "-"}
    }

    const fetchPatient = async (recordId) => {
        if (recordId !== 0) {
            let body = {};
            body.mPatientID = recordId;
            const res = await webServices.wsGetPatients(body, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            let rec = (recs.length !== 0) ? calculatePatientASL(recs[0]) : newPatient;
            return rec;
        } else {
            newPatient = {
                ...newPatient,
                mBirthCountry: italy.country,
                mLiveCountry: italy.country,
                mCitizenOf: italy.country
            }
            return newPatient;
        }
    }

    const calculatePatientCF = async (pCF)=> {
        let body = {};
        body.mCF = pCF;
        const res = await webServices.wsGetPatientDataFromCF(body, alertContext, true);
        if (res.responseAnyError) return;
        let rec = res.responseData;
        let patRes = JSONClone(newPatient);
        patRes = {
            ...patRes,
            mBirthCity: rec.mBirthCity,
            mSex: rec.mSex,
            mBirthDate: rec.mBirthDate
        }
        return patRes;
    }

    const fetchPatientByCF = async (pCF) => {
        let body = {};
        body.mCF = pCF;
        const res = await webServices.wsGetPatients(body, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        let rec = (recs.length !== 0) ? calculatePatientASL(recs[0]) : await calculatePatientCF(pCF);
        return normalizePatient(rec);
    }

    const updatePatient = (record) => {
        const longDateTimeFormat = authContext.getSystemParameterValue("DATETIME_FORMAT_LONG");
        record.mBirthDate = record.mBirthDate ? moment(record.mBirthDate).format(longDateTimeFormat) : nullDate;
        webServices.wsUpdatePatient(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchPatients(true);
        });
    };

    const calculatePatientASL = (rec) => {
        rec.mASLName = ""
        if (rec.mLiveCity) {
            if (rec.mLiveCity.mWithSubTown) { //municipio
                if (rec.mSubTown) {
                    if (rec.mSubTown.mASL) {
                        rec.mASLName = rec.mSubTown.mASL.mCompanyName;
                    }
                }
            } else { //città
                if (rec.mLiveCity.mASL) {
                    rec.mASLName = rec.mLiveCity.mASL.mCompanyName;
                }
            }
        }
        return rec;
    };

    const checkPatientLiveCity = (pat) => {
        let patientLiveCity = pat ?? {};
        patientLiveCity = patientLiveCity.mLiveCity ?? {};
        patientLiveCity = patientLiveCity.mCityID ?? 0;
        return patientLiveCity;
    }

    const checkPatientSubTown = (pat) => {
        let patientSubTown = pat ?? {};
        patientSubTown = patientSubTown.mSubTown ?? {};
        patientSubTown = patientSubTown.mMunicipioID ?? 0;
        return patientSubTown;
    }

    const calculateASL = async (pt) => {
        let newRecord = JSONClone(pt);
        newRecord.mASLName = "";
        let cityID = checkPatientLiveCity(newRecord);
        if (cityID === 0) {
            newRecord.mSubTown = null;
        } else {
            let city = await fetchCityWithAslAsync(cityID);
            if (city) {
                newRecord.mLiveCity = city;
                if (city.mWithSubTown) { //municipio
                    if (newRecord.mSubTown) {
                        let subTownID = checkPatientSubTown(newRecord);
                        if (subTownID === 0) {
                            newRecord.mSubTown = null;
                        } else {
                            let subTown = await fetchTownHall(subTownID);
                            if (subTown.mASL) {
                                newRecord.mSubTown = subTown;
                                newRecord.mASLName = subTown.mASL.mCompanyName;
                            }
                        }
                    }
                } else if (city.mASL) {
                    newRecord.mLiveCity = city;
                    newRecord.mSubTown = null;
                    newRecord.mASLName = city.mASL.mCompanyName;
                }
            } else {
                newRecord.mSubTown = null;
            }
        }
        return newRecord;
    }

    const fetchSexItemizedListValues = () => {
        let body = {};
        body.mItemizedList = {mItemizedListID: 1};
        webServices.wsGetItemizedListValues(body, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setSexItemizedListValues(recs);
            return recs;
        })
    }

    const resetSexItemizedListValues = () => {
        setSexItemizedListValues(null);
    }

    const fetchAttachTypeItemizedListValues = async () => {
        let body = {};
        body.mItemizedList = {mItemizedListID: 42};
        let res = await webServices.wsGetItemizedListValues(body, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setAttachTypeItemizedListValues(recs);
        return recs;
    }

    const resetAttachTypeItemizedListValues = () => {
        setAttachTypeItemizedListValues(null);
    }

    const fetchModuleTypeItemizedListValues = async () => {
        let body = {};
        body.mItemizedList = {mItemizedListID: 43};
        let res = await webServices.wsGetItemizedListValues(body, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setModuleTypeItemizedListValues(recs);
        return recs;
    }

    const resetModuleTypeItemizedListValues = () => {
        setModuleTypeItemizedListValues(null);
    }

    const normalizeSponsor = (sponsor) => {
        return {
            ...sponsor,
            mCompleteName: getCompleteName(sponsor.mSponsorFirstName, sponsor.mSponsorLastName)
        }
    }

    const fetchSponsors = () => {
        setDataLoading(setSponsors);
        webServices.wsGetSponsors({}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            recs = recs.map((sponsor) => {
                return normalizeSponsor(sponsor);
            });
            setSponsors(recs);
        });
    };

    const fetchSponsorsAsync = async () => {
        setDataLoading(setSponsors);
        const res = await webServices.wsGetSponsors({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        recs = recs.map((sponsor) => {
            return normalizeSponsor(sponsor);
        });
        setSponsors(recs);
        return recs;
    };

    const fetchSponsor = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetSponsors({mSponsorID: recordId}, alertContext, true)
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newSponsor;
        } else {
            return newSponsor;
        }
    }

    const updateSponsor = async (record) => {
        const res = await webServices.wsUpdateSponsor(record, alertContext, true);
        if (res.responseAnyError) return;
        await fetchSponsorsAsync();
        let rec = res.responseData;
        return (rec) ? rec : null;
    };

    const fetchTownHalls = () => {
        setDataLoading(setTownHalls);
        webServices.wsGetTownHalls({}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setTownHalls(recs);
        });
    };

    const fetchTownHall = async (mMunicipioID) => {
        const res = await webServices.wsGetTownHalls({mMunicipioID: mMunicipioID}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        return (recs.length !== 0) ? recs[0] : newTownHall;
    };

    const fetchPaymentTypes = async () => {
        setDataLoading(setPaymentTypes);
        const res = await webServices.wsGetPaymentTypes({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setPaymentTypes(recs);
        return recs;
    }

    const fetchExemptionTypes = async () => {
        setDataLoading(setExemptionTypes);
        const res = await webServices.wsGetExemptionTypes({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setExemptionTypes(recs);
        return recs;
    }

    const fetchSupplierTypes = async () => {
        setDataLoading(setSupplierTypes);
        const res = await webServices.wsGetSupplierTypes({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setSupplierTypes(recs);
        return recs;
    }

    const fetchSponsorTypes = async () => {
        setDataLoading(setSponsorTypes);
        const res = await webServices.wsGetSponsorTypes({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setSponsorTypes(recs);
        return recs;
    }

    const fetchMedicalServiceClassListValues = () => {
        let body = {};
        body.mItemizedList = {mItemizedListID: 23};
        webServices.wsGetItemizedListValues(body, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setClassesListValues(recs);
            return recs;
        })
    }

    const fetchMedicalServiceTypes = async () => {
        setDataLoading(setMedicalServiceTypes);
        const res = await webServices.wsGetMedicalServiceTypes({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setMedicalServiceTypes(recs);
        return recs;
    }

    const fetchReports = async () => {
        setDataLoading(setReports);
        const res = await webServices.wsGetReports({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setReports(recs);
        return recs;
    };

    const fetchReport = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetReports({mReportID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newReport;
        } else {

            return newReport;
        }
    }

    const updateReport = (record) => {
        webServices.wsUpdateReport(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchReports();
        });
    };

    const deleteReport = (recordId) => {
        webServices.wsDeleteReport({mReportID: recordId}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchReports();
        });
    };

    const fetchReportsInputModes = async () => {
        setDataLoading(setReportsInputModes);
        const res = await webServices.wsGetReportsInputModes({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setReportsInputModes(recs);
        return recs;
    };

    const fetchReportsOutputModes = async () => {
        setDataLoading(setReportsOutputModes);
        const res = await webServices.wsGetReportsOutputModes({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setReportsOutputModes(recs);
        return recs;
    };

    const fetchReportsGroupItemizedListValues = async () => {
        let body = {};
        body.mItemizedList = {mItemizedListID: 27};
        let res = await webServices.wsGetItemizedListValues(body, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setReportsGroupItemizedListValues(recs);
        return recs;
    }

    const fetchSmsPackagesItemizedListValues = async () => {
        let body = {};
        body.mItemizedList = {mItemizedListID: 46};
        let res = await webServices.wsGetItemizedListValues(body, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setSmsPackagesItemizedListValues(recs);
        return recs;
    }

    const resetSmsPackagesItemizedListValues = () => {
        setSmsPackagesItemizedListValues(null);
    }

    const fetchPrioritiesItemizedListValues = () => {
        let body = {};
        body.mItemizedList = {mItemizedListID: 48};
        webServices.wsGetItemizedListValues(body, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setPrioritiesItemizedListValues(recs);
            return recs;
        })
    }

    const resetPrioritiesItemizedListValues = () => {
        setPrioritiesItemizedListValues(null);
    }

    const fetchAllCompanies = () => {
        setDataLoading(setAllCompanies);
        webServices.wsGetCompanies({}, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            recs = recs.map((company) => {
                return normalizeCompany(company);
            });
            setAllCompanies(recs);
            return recs;
        })
    }

    return (
        <RegistriesContext.Provider
            value={{
                registriesLoaded,
                setRegistriesLoaded,
                baseRegistriesLoaded,
                setBaseRegistriesLoaded,
                resetBaseRegistries,
                fetchCities,
                fetchCitiesAsync,
                cities,
                fetchCity,
                fetchCityWithAslAsync,
                updateCity,
                fetchDistricts,
                districts,
                fetchDistrict,
                updateDistrict,
                fetchRegions,
                regions,
                fetchRegion,
                updateRegion,
                fetchCountries,
                fetchCountriesAsync,
                countries,
                fetchCountry,
                fetchItaly,
                italy,
                updateCountry,
                fetchUsers,
                users,
                fetchUser,
                updateUser,
                fetchZoneResponsables,
                zoneResponsables,
                fetchLocalResponsables,
                localResponsables,
                fetchDoctors,
                doctors,
                fetchDoctorFees,
                updateDoctorFees,
                updateDefaultDoctorFee,
                fetchModalities,
                modalities,
                fetchModality,
                updateModality,
                fetchFunctionalDomains,
                functionalDomains,
                fetchSystemFunctions,
                systemFunctions,
                fetchSystemFunction,
                updateSystemFunction,
                deleteSystemFunction,
                fetchDomains,
                fetchDomainsAsync,
                domains,
                fetchDomain,
                updateDomain,
                deleteDomain,
                fetchProfiles,
                profiles,
                fetchProfile,
                updateProfile,
                addProfileFunction,
                removeProfileFunction,
                fetchAslCompanies,
                fetchAslCompaniesAsync,
                aslCompanies,
                fetchAslCompany,
                updateAslCompany,
                fetchCompanies,
                fetchCompaniesAsync,
                companies,
                fetchCompany,
                updateCompany,
                fetchCompanyPlan,
                createCompanyPlan,
                setCompanyPlanPayed,
                checkCompanyPayments,
                fetchBanks,
                banks,
                fetchBank,
                fetchPayingMethodsItemizedListValues,
                payingMethods,
                updateBank,
                fetchBranches,
                branches,
                fetchBranch,
                updateBranch,
                fetchCounters,
                counters,
                fetchCounter,
                updateCounter,
                fetchConventions,
                fetchConventionsAsync,
                conventions,
                fetchEnabledConventions,
                getUniqueActiveConvention,
                enabledConventions,
                fetchConvention,
                updateConvention,
                deleteConvention,
                duplicateConvention,
                fetchExemptions,
                fetchExemptionsAsync,
                exemptions,
                fetchExemption,
                updateExemption,
                fetchItemizedLists,
                itemizedLists,
                fetchItemizedList,
                updateItemizedList,
                fetchItemizedListValues,
                itemizedListValues,
                fetchItemizedListValue,
                updateItemizedListValue,
                deleteItemizedListValue,
                fetchMedicalServices,
                medicalServices,
                fetchMedicalService,
                updateMedicalService,
                fetchMedicalServiceDetails,
                fetchMedicalServicePrice,
                fetchAllMedicalServicePrices,
                updateMedicalServicePrice,
                updateMedicalServiceCodes,
                fetchRooms,
                fetchRoomsAsync,
                fetchRoomsByMedicalService,
                rooms,
                roomsFilter,
                getActiveRooms,
                fetchRoom,
                fetchRoomMedicalServices,
                fetchRoomAndBranchMedicalServices,
                resetMedicalServices,
                roomMedicalServices,
                fetchDefaultRoom,
                fetchActiveMedicalServices,
                fetchActiveMedicalServicesAsync,
                activeMedicalServices,
                updateRoom,
                fetchRoomDevices,
                roomDevices,
                fetchDevices,
                devices,
                fetchDevice,
                updateDevice,
                deleteDevice,
                duplicateDevice,
                updateDeviceMedicalServices,
                fetchPatients,
                patients,
                patientsTotalPages,
                patientsTotalRows,
                patientsCurrentPage,
                setPatientsCurrentPage,
                patientsPageSize,
                patientsServerSideFilter,
                setPatientsServerSideFilter,
                fetchPatient,
                fetchPatientByCF,
                updatePatient,
                checkPatientLiveCity,
                calculateASL,
                fetchSexItemizedListValues,
                resetSexItemizedListValues,
                sexItemizedListValues,
                fetchAttachTypeItemizedListValues,
                resetAttachTypeItemizedListValues,
                attachTypeItemizedListValues,
                fetchModuleTypeItemizedListValues,
                resetModuleTypeItemizedListValues,
                moduleTypeItemizedListValues,
                fetchSponsors,
                fetchSponsorsAsync,
                sponsors,
                fetchSponsor,
                updateSponsor,
                fetchTownHalls,
                fetchTownHall,
                townHalls,
                fetchPaymentTypes,
                paymentTypes,
                fetchExemptionTypes,
                exemptionTypes,
                fetchSupplierTypes,
                supplierTypes,
                fetchSponsorTypes,
                sponsorTypes,
                fetchMedicalServiceTypes,
                medicalServiceTypes,
                fetchMedicalServiceClassListValues,
                classesListValues,
                reports,
                fetchReports,
                fetchReport,
                updateReport,
                deleteReport,
                fetchReportsInputModes,
                reportsInputModes,
                fetchReportsOutputModes,
                reportsOutputModes,
                fetchReportsGroupItemizedListValues,
                reportsGroupItemizedListValues,
                smsPackagesItemizedListValues,
                fetchSmsPackagesItemizedListValues,
                resetSmsPackagesItemizedListValues,
                prioritiesItemizedListValues,
                fetchPrioritiesItemizedListValues,
                resetPrioritiesItemizedListValues,
                allCompanies,
                fetchAllCompanies
            }}
        >
            {props.children}
        </RegistriesContext.Provider>
    );
}

export {RegistriesProvider, RegistriesContext};
