import PropTypes from 'prop-types';
import {Box, Chip, Grid, Stack, Typography} from '@mui/material';
import {MainCard} from '../Cards/MainCard';
import React from 'react';
import {useTheme} from "@mui/material/styles";
import {IconLibraryItem} from "../Icons/IconLibraryItem";

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const AnalyticCard = ({color, title, count, percentage, isLoss, extra, symbol}) => {

    const theme = useTheme();

    const sSymbol = symbol ?? "";

    const absExtra = Math.abs(extra).toFixed(2);

    const perc = percentage * 100;

    return (
        <MainCard contentSX={{p: 0.5}} border={false}>
            <Stack spacing={0.5}>
                <Typography variant="h6" color="textSecondary">
                    {title}
                </Typography>
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography variant="h4" color="inherit">
                            {sSymbol} {count.toFixed(2)}
                        </Typography>
                    </Grid>
                    {perc && (
                        <Grid item>
                            <Chip
                                variant="combined"
                                color={color}
                                icon={
                                    <>
                                        {!isLoss && <IconLibraryItem name={"chart-up"} iconSize={"small"}/>}
                                        {isLoss && <IconLibraryItem name={"chart-down"} iconSize={"small"}/>}
                                    </>
                                }
                                label={`${perc.toFixed(2)}%`}
                                sx={{ml: 1.25, pl: 1}}
                                size="small"
                            />
                        </Grid>
                    )}
                </Grid>
            </Stack>
            <Box sx={{pt: 0.5}}>
                <Typography variant="caption" color="textSecondary">
                    {(extra<0) ? "Differenza al raggiungimento target " : "Superamento target di "}
                    <Typography component="span" variant="caption" sx={{color: `${color || 'primary'}.main`}}>
                        {sSymbol} {absExtra}
                    </Typography>{' '}
                </Typography>
            </Box>
        </MainCard>
    )
}

AnalyticCard.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    count: PropTypes.string,
    percentage: PropTypes.number,
    isLoss: PropTypes.bool,
    extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

AnalyticCard.defaultProps = {
    color: 'primary'
};

export default AnalyticCard;
