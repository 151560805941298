import {FavoriteTwoTone, PersonPinTwoTone, PhoneTwoTone, ReceiptTwoTone} from '@mui/icons-material';
import {
    Alert,
    Box,
    Divider,
    Fab,
    FormControl,
    Grid,
    Paper,
    Stack,
    styled,
    Tab,
    Tabs,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {
    columnTypes,
    dateColumnRenderer,
    defaultColDef,
    floatToString,
    isDataLoading,
    isDataNull,
    moneyColumnRenderer,
    openInNewTab
} from "../../../../common";
import {VisitAttachmentsModal} from "../../../../components/Attachments/VisitAttachmentsModal";
import {VisitModulesButton} from "../../../../components/Attachments/VisitModulesButton";
import {StatusBadge} from "../../../../components/Badges/StatusBadge";
import {ActionButton} from "../../../../components/Buttons/ActionButton";
import {FormActionBar} from "../../../../components/Form/FormActionBar";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormDatePicker} from "../../../../components/Form/FormDatePicker";
import {FormSubmitButton} from "../../../../components/Form/FormSubmitButton";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {ActionsToolBar} from "../../../../components/Grid/ActionsToolbar";
import {StackedItem} from "../../../../components/Layout/StackedItem";
import {TabPanel} from "../../../../components/Tab/TabPanel";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AccountingContext} from "../../../../context/AccountingContext";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {BookingContext} from "../../../../context/BookingContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import '../../../../theme/agGridStyle.css';
import {gridRowHeight, gridSizeStyle, gridSmallSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {CreditDocumentPreview} from "../../accounting/components/CreditDocumentPreview";
import {InvoicePreview} from "../../accounting/components/InvoicePreview";
import {SponsorDetail} from "../../registries/components/Sponsors";
import VisitLineAssignee from "./VisitLineAssignee";
import {IconLibraryItem} from "../../../../components/Icons/IconLibraryItem";
import {SquareButton} from "../../../../components/Buttons/SquareButton";
import {StyledCardHeader} from "../../../../components/Layout/SyledCardHeader";
import {CommonContext} from "../../../../context/CommonContext";
import {FormCheckBox} from "../../../../components/Form/FormCheckBox";
import {AceButton} from "../../../../components/Dicom/AceButton";

const VisitDetail = () => {

    const registriesContext = useContext(RegistriesContext);
    const commonContext = useContext(CommonContext);
    const bookingContext = useContext(BookingContext);
    const alertContext = useContext(CustomAlertContext);
    const accountingContext = useContext(AccountingContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    let navigate = useNavigate();


    const [searchedPatientCF, setSearchedPatientCF] = useState(null);
    const [searchedPatient, setSearchedPatient] = useState(null);
    const [sponsorID, setSponsorID] = useState(-1);
    const [invoiceToOpenID, setInvoiceToOpenID] = useState(-1);
    const [creditDocumentToOpenID, setCreditDocumentToOpenID] = useState(-1);
    const [assigneeOpen, setAssigneOpen] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);
    const [assignedDoctor, setAssignedDoctor] = useState(null);
    const [selectedVisitLineRows, setSelectedVisitLineRows] = useState([]);
    const [attachments, setAttachments] = useState(null);
    const [attachmentsModalOpen, setAttachmentsModalOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const gridRef = useRef();

    const activeRooms = registriesContext.getActiveRooms() ?? [];

    let selectedTab = bookingContext.bookingState.selectedTab ?? 0;

    const DEFAULT_AGENDA_URL = commonContext.getPlanningParameter("DEFAULT_AGENDA_URL");

    useEffect(() => {
        if (isDataNull(registriesContext.exemptions) && !isDataLoading(registriesContext.exemptions)) registriesContext.fetchExemptions();
    }, [registriesContext.exemptions]);

    useEffect(() => {
        if (isDataNull(registriesContext.sponsors) && !isDataLoading(registriesContext.sponsors)) registriesContext.fetchSponsors();
    }, [registriesContext.sponsors]);

    useEffect(() => {
        if (isDataNull(registriesContext.sponsors) && !isDataLoading(registriesContext.sponsors)) registriesContext.fetchSponsors();
    }, [registriesContext.loaded]);

    useEffect(() => {
        resetASL(bookingContext.visit);
        resetTempPatient(bookingContext.visit);
        resetRealPatient(bookingContext.visit);
        if (!bookingContext.visit) return;
        if (bookingContext.bookingItem.mVisitaID === bookingContext.visit.mVisitaID) {
            setLoaded(true);
        }
    }, [bookingContext.visit]);

    const hasSubTown = () => {
        let currentPat = getValuesRealPatient('mPatient');
        if (!currentPat) return false;
        if (!currentPat.mLiveCity) return false;
        return currentPat.mLiveCity.mWithSubTown ?? false;
    }

    const visitLineStatusRenderer = (props) => {
        return (
            <StatusBadge label={props.node.data.mStatoEsame}
                         color={bookingContext.getVisitLineStatusColor(props.node.data)} fullWidth/>
        )
    }

    const visitLineInvoicingStatusRenderer = (props) => {
        return (
            <StatusBadge label={props.node.data.mInvoicingStatus}
                         color={bookingContext.getVisitLineInvoicingColor(props.node.data)} fullWidth/>
        )
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const assigneCloseClick = () => {
        setAssignedDoctor(null);
        setAssigneOpen(false);
        gridRef.current.api.deselectAll();
    }

    const ToolBar = (props) => {

        let record = props.node.data;

        const editDisabled = () => {
            // return (props.node.data.isAccepted || props.node.data.isExecuted || props.node.data.isReported || props.node.data.isInvoiced);
            return (!props.node.data.mCanBeUpdated);
        }

        const editAction = () => {
            let booking = {
                visit: bookingContext.visit,
                visitLine: props.node.data
            }
            bookingContext.openBooking(booking, 3);
            navigate('/Booking/TakeBooking');
        };

        const deleteDisabled = () => {
            return (!props.node.data.mCanBeDeleted);
        }

        const deleteAction = () => {
            bookingContext.deleteVisitLineFromVisit(props.node.data).then();
        };

        const extraAction = () => {
            const assigneeID = ((record.mAssignedDoctor ?? {}).mUserID ?? 0);
            setAssignedDoctor(assigneeID);
            setAssigneOpen(true);

        }

        const extraDisabled = () => {
            return (props.node.data.isInvoiced || props.node.data.isReported);
        }

        const customActions = [
            {
                action: extraAction,
                disabled: extraDisabled(),
                icon: "doctor",
                tooltip: "Assegna",
            }
        ]

        return (
            <ActionsToolBar
                hasDelete deleteAction={deleteAction} deleteDisabled={deleteDisabled()}
                hasEdit editAction={editAction} editDisabled={editDisabled()}
                customActions={customActions}
            />
        )
    }

    //colonne griglia

    const columns = [
        {
            headerName: "Prestazione",
            field: "mPrestazione.mDescrizione",
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            type: "flex2"
        },
        {
            headerName: "Appuntamento",
            field: "mDataAppuntamentoString",
            maxWidth: 160

        },
        {
            headerName: "Stanza",
            field: "mRoom.mRoomName"
        },
        {
            headerName: "Listino",
            field: "mPrezzoListino",
            cellRenderer: moneyColumnRenderer,
            cellClass: 'number-cell',
            maxWidth: 120
        },
        {
            headerName: "Sconto",
            field: "mSconto",
            cellRenderer: moneyColumnRenderer,
            cellClass: 'number-cell',
            maxWidth: 120
        },
        {
            headerName: "Scontato",
            field: "mClientPrice",
            cellRenderer: moneyColumnRenderer,
            cellClass: 'number-cell',
            maxWidth: 120
        },
        {
            headerName: "Assegnato a",
            field: "mAssignedDoctor.mShortName",
            type: "statusColumn",
            maxWidth: 160

        },
        {
            headerName: "",
            cellRenderer: visitLineStatusRenderer,
            type: "statusColumn",
            maxWidth: 160
        },
        {
            headerName: "",
            cellRenderer: visitLineInvoicingStatusRenderer,
            type: "statusColumn",
            maxWidth: 160
        },
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ];

    //selezione

    //cancel

    const visitSaveCancel = (data) => {
        //niente
    }

    //temp patient

    const {
        register: registerTempPatient,
        control: controlTempPatient,
        handleSubmit: handleSubmitTempPatient,
        reset: resetTempPatient
    } = useForm({mode: 'onSubmit', defaultValues: bookingContext.visit});

    const saveVisitTempPatient = (data) => {
        if (bookingContext.canChangeVisitTempPatient()) bookingContext.saveVisitTmpPatient(data).then();
    }

    const onSubmitTempPatient = (data) => {
        alertContext.showConfirmDialog(
            "Conferma salvataggio",
            "Stai salvando il paziente temporaneo per la visita. Sei sicuro? ",
            visitSaveCancel,
            () => {
                saveVisitTempPatient(data);
            }
        );
    }

    const onErrorTempPatient = (errors, e) => {
        console.log(errors, e);
    }

    const getVisitTempPatientForm = () => {
        return (
            <Box>
                <form onSubmit={handleSubmitTempPatient(onSubmitTempPatient, onErrorTempPatient)}
                      id={"formTempPatient"}>
                    <Grid
                        container
                        spacing={theme.spacing(1)}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12} md={3}>
                            <FormTextBox
                                label="Cognome"
                                control={controlTempPatient}
                                record={bookingContext.visit}
                                register={registerTempPatient}
                                field="mTempPatient.mCognomePaziente"
                                required={true}
                                maxLength={100}
                                disabled={!bookingContext.canChangeVisitTempPatient()}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormTextBox
                                label="Nome"
                                control={controlTempPatient}
                                record={bookingContext.visit}
                                register={registerTempPatient}
                                field="mTempPatient.mNomePaziente"
                                required={true}
                                maxLength={100}
                                disabled={!bookingContext.canChangeVisitTempPatient()}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormTextBox
                                label="Cellulare/Telefono"
                                control={controlTempPatient}
                                record={bookingContext.visit}
                                register={registerTempPatient}
                                field="mTempPatient.mMobilePhone"
                                required={true}
                                maxLength={100}
                                disabled={!bookingContext.canChangeVisitTempPatient()}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormSubmitButton label="prenota" disabled={!bookingContext.canChangeVisitTempPatient()}/>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        )
    }

    //real patient

    const isSearchRealPatientDisabled = (searchedPatient);
    const isResetRealPatientDisabled = (!searchedPatient);
    const isRealPatientDisabled = (!searchedPatient);

    const {
        watch: watchRealPatient,
        register: registerRealPatient,
        control: controlRealPatient,
        handleSubmit: handleSubmitRealPatient,
        getValues: getValuesRealPatient,
        setValue: setValueRealPatient,
        reset: resetRealPatient
    } = useForm({mode: 'onSubmit', defaultValues: bookingContext.visit});

    const [editRealPatientSponsorDisabled, setRealPatientEditSponsorDisabled] = useState(false);

    useEffect(() => {
        const subscription = watchRealPatient(
            (value, {name, type}) => {
                if (type === 'change') {
                    if (name === 'mPatient.mLiveCity' || name === 'mPatient.mSubTown') {
                        registriesContext.calculateASL(value.mPatient).then((newRecord) => {
                            setValueRealPatient('mPatient.mLiveCity', newRecord.mLiveCity, {});
                            setValueRealPatient('mPatient.mSubTown', newRecord.mSubTown, {});
                            setValueRealPatient('mPatient.mASLName', newRecord.mASLName, {shouldValidate: true});
                            bookingContext.setVisit(getValuesRealPatient());
                        });
                    }
                    if (name === 'mPatient.mFamilyDoctor') {
                        const values = getValuesRealPatient();
                        const isDisabled = !(values.mPatient && values.mPatient.mFamilyDoctor && values.mPatient.mFamilyDoctor.mSponsorID);
                        setRealPatientEditSponsorDisabled(isDisabled)
                    }
                }
            }
        );
        return () => subscription.unsubscribe();
    }, [watchRealPatient]);

    const saveVisitRealPatient = (data) => {
        if (bookingContext.canChangeVisitRealPatient()) bookingContext.saveVisitRealPatient(data).then();
    }

    const onSubmitRealPatient = (data) => {
        alertContext.showConfirmDialog(
            "Conferma salvataggio",
            "Stai salvando il paziente e i suoi dati anagrafici. Sei sicuro? ",
            visitSaveCancel,
            () => {
                saveVisitRealPatient(data);
            }
        );
    }

    const onErrorRealPatient = (errors, e) => {
        console.log(errors, e);
    }

    const setPatientSponsorByID = (pSponsorID) => {
        setValueRealPatient('mPatient.mFamilyDoctor.mSponsorID', pSponsorID, {});
        bookingContext.setVisit(getValuesRealPatient());
    }

    const getVisitRealPatientForm = () => {
        return (
            <Box sx={{mt: theme.spacing(1)}}>
                <form onSubmit={handleSubmitRealPatient(onSubmitRealPatient, onErrorRealPatient)}>
                    <Grid
                        container
                        spacing={theme.spacing(1)}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        {/*<Grid item xs={12} lg={3}>*/}
                        {/*    <FormTextBox*/}
                        {/*        label="CF"*/}
                        {/*        control={controlRealPatient}*/}
                        {/*        record={bookingContext.visit}*/}
                        {/*        register={registerRealPatient}*/}
                        {/*        field="mPatient.mCF"*/}
                        {/*        required={true}*/}
                        {/*        minLength={16}*/}
                        {/*        maxLength={16}*/}
                        {/*        disabled={isRealPatientDisabled}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} lg={3}>
                            <FormTextBox
                                label="Cognome"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mCognomePaziente"
                                required={true}
                                maxLength={50}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <FormTextBox
                                label="Nome"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mNomePaziente"
                                required={true}
                                maxLength={50}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <FormAutoComplete
                                label="Sesso"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mSex"
                                joinField=""
                                values={registriesContext.sexItemizedListValues}
                                idField="mItemizedListValueID"
                                labelField="mValue"
                                selectedValue="label"
                                required={true}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <FormDatePicker
                                label="Data nascita"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mBirthDate"
                                required={true}
                                format={commonContext.getPlanningParameter("DATE_FORMAT_SHORT")}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <FormAutoComplete
                                label="Città di nascita"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mBirthCity"
                                joinField="mCityID"
                                values={registriesContext.cities}
                                idField="mCityID"
                                labelField="mCompleteName"
                                selectedValue="id"
                                required={true}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <FormAutoComplete
                                label="Stato di nascita"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mBirthCountry"
                                joinField="mCountryID"
                                values={registriesContext.countries}
                                idField="mCountryID"
                                labelField="mCountryName"
                                selectedValue="id"
                                required={true}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormTextBox
                                label="Indirizzo di residenza"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mLiveAddress"
                                required={true}
                                maxLength={50}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <FormAutoComplete

                                label="Città di residenza"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mLiveCity"
                                joinField="mCityID"
                                values={registriesContext.cities}
                                idField="mCityID"
                                labelField="mCompleteName"
                                selectedValue="id"
                                required={true}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <FormAutoComplete
                                label="Stato di residenza"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mLiveCountry"
                                joinField="mCountryID"
                                values={registriesContext.countries}
                                idField="mCountryID"
                                labelField="mCountryName"
                                selectedValue="id"
                                required={true}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <FormAutoComplete
                                label="Stato di cittadinanza"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mCitizenOf"
                                joinField="mCountryID"
                                values={registriesContext.countries}
                                idField="mCountryID"
                                labelField="mCountryName"
                                selectedValue="id"
                                required={true}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormAutoComplete
                                label="Municipio di appartenenza"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mSubTown"
                                joinField="mMunicipioID"
                                values={registriesContext.townHalls}
                                idField="mMunicipioID"
                                labelField="mNome"
                                selectedValue="id"
                                required={hasSubTown()}
                                disabled={isRealPatientDisabled || !hasSubTown()}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormTextBox
                                label="ASL di appartenenza"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mASLName"
                                required={false}
                                maxLength={50}
                                disabled={true}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <FormAutoComplete
                                label="Medico curante"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mFamilyDoctor"
                                joinField="mSponsorID"
                                values={registriesContext.sponsors}
                                idField="mSponsorID"
                                labelField="mCompleteName"
                                selectedValue="id"
                                required={false}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <ActionButton
                                color="primary"
                                variant="contained"
                                action={() => {
                                    setSponsorID(0);
                                }}
                                size="small"
                                icon="new"
                                iconSize="small"
                                tooltip="Nuovo"
                                label=""
                                sx={loggedClasses.registriesToolbarSx}
                                disabled={isRealPatientDisabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <ActionButton
                                color="primary"
                                variant="contained"
                                action={() => {
                                    const values = getValuesRealPatient();
                                    setSponsorID(values.mPatient.mFamilyDoctor.mSponsorID)
                                }}
                                size="small"
                                icon="edit"
                                iconSize="small"
                                tooltip="Modifica"
                                label=""
                                sx={loggedClasses.registriesToolbarSx}
                                disabled={isRealPatientDisabled || editRealPatientSponsorDisabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <FormTextBox
                                label="Cellulare"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mMobilePhone"
                                required={true}
                                maxLength={50}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <FormTextBox
                                label="Fisso"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mHomePhone"
                                required={false}
                                maxLength={50}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <FormTextBox
                                label="E-mail"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mEmail"
                                required={false}
                                maxLength={100}
                                pattern={"email"}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <FormTextBox
                                label="Note"
                                control={controlRealPatient}
                                record={bookingContext.visit}
                                register={registerRealPatient}
                                field="mPatient.mAdditionalInformation"
                                required={false}
                                maxLength={100}
                                disabled={isRealPatientDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <FormActionBar hasCancel={false} submitDisabled={isRealPatientDisabled}/>
                        </Grid>
                    </Grid>

                </form>
                <SponsorDetail recordID={sponsorID} setRecordID={setSponsorID} saveCallback={setPatientSponsorByID}/>
            </Box>
        )
    }

    //search patient

    const onSearchPatientChange = (pCFToSearch) => {
        setSearchedPatientCF(pCFToSearch);
        if (pCFToSearch.length !== 16) {
            if (pCFToSearch.length === 0) {
                setSearchedPatient(null);
            }
            return;
        }
        setSearchedPatient(null);
        registriesContext.fetchPatientByCF(pCFToSearch).then((res) => {
            let patID = res.mPatientID ?? 0;
            setSearchedPatient(res);
            let currentVisit = getValuesRealPatient();
            if (patID === 0) {
                let patient = {};
                setValueRealPatient('mPatient.mCognomePaziente', currentVisit.mTempPatient.mCognomePaziente, {});
                setValueRealPatient('mPatient.mNomePaziente', currentVisit.mTempPatient.mNomePaziente, {});
                setValueRealPatient('mPatient.mMobilePhone', currentVisit.mTempPatient.mMobilePhone, {});
                setValueRealPatient('mPatient.mBirthCity', res.mBirthCity, {});
                setValueRealPatient('mPatient.mSex', res.mSex, {});
                setValueRealPatient('mPatient.mBirthDate', res.mBirthDate, {});
                setValueRealPatient('mPatient.mCF', pCFToSearch, {});

                const tempRealValues = getValuesRealPatient();
                const familyDoctorDisabled = !(tempRealValues.mPatient && tempRealValues.mPatient.mFamilyDoctor && tempRealValues.mPatient.mFamilyDoctor.mSponsorID);
                setRealPatientEditSponsorDisabled(familyDoctorDisabled);

                bookingContext.setVisit(tempRealValues);
                bookingContext.changeVisitSelectedTab(2);
            } else {
                setValueRealPatient('mPatient', res, {});
                currentVisit.mPatient = res;
                bookingContext.setVisit(getValuesRealPatient());
                bookingContext.changeVisitSelectedTab(2);
            }
        });
    }

    const getSearchPatientBox = () => {
        return (
            <Box sx={{mt: theme.spacing(1)}}>
                <Grid
                    container
                    spacing={theme.spacing(1)}
                    direction={"row"}
                >
                    <Grid item xs={12} md={3}>
                        <TextField
                            variant="filled"
                            fullWidth
                            label="CF da cercare"
                            type="text"
                            value={searchedPatientCF}
                            onChange={(event) => {
                                onSearchPatientChange(event.target.value);
                            }}
                            disabled={isSearchRealPatientDisabled}
                            autoFocus={!isSearchRealPatientDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <ActionButton
                            color="primary"
                            variant="contained"
                            action={() => {
                                bookingContext.resetVisitRealPatient();
                                setSearchedPatientCF("");
                                setSearchedPatient(null);
                            }}
                            size="small"
                            icon="reset"
                            iconSize="small"
                            tooltip="Reset"
                            label="Reset"
                            disabled={isResetRealPatientDisabled}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <ActionButton
                            color="primary"
                            variant="contained"
                            action={() => {
                                openInNewTab("https://www.codicefiscale.com/")
                            }}
                            size="small"
                            icon="world"
                            iconSize="small"
                            tooltip="Calcola CF"
                            label="Calcola CF"
                            disabled={false}
                            fullWidth
                        />
                    </Grid>
                    {
                        (!searchedPatient) ?
                            <Grid item xs={12} md={3}>
                                <FormControl>
                                    <Alert severity="info">
                                        {/*{searchedPatient.mNomePaziente} {searchedPatient.mCognomePaziente} nato*/}
                                        {/*il: {searchedPatient.mBirthDateString}*/}
                                        Inserisci il CF per effettuare la ricerca
                                    </Alert>
                                </FormControl>
                            </Grid>
                            :
                            (searchedPatient && searchedPatient.mPatientID !== 0) ?
                                <Grid item xs={12} md={3}>
                                    <FormControl>
                                        <Alert severity="success">
                                            {/*{searchedPatient.mNomePaziente} {searchedPatient.mCognomePaziente} nato*/}
                                            {/*il: {searchedPatient.mBirthDateString}*/}
                                            Paziente trovato in anagrafica!
                                        </Alert>
                                    </FormControl>
                                </Grid>
                                :
                                (searchedPatient && searchedPatient.mPatientID === 0) ?
                                    <Grid item xs={12} md={3}>
                                        <FormControl>
                                            <Alert severity="error">
                                                {/*{searchedPatient.mNomePaziente} {searchedPatient.mCognomePaziente} nato*/}
                                                {/*il: {searchedPatient.mBirthDateString}*/}
                                                Paziente non trovato in anagrafica!
                                            </Alert>
                                        </FormControl>
                                    </Grid>
                                    : <></>

                    }
                </Grid>
            </Box>
        )
    }

    //save

    const saveDisabled = () => {
        return (
            !bookingContext.canSaveVisit()
        )
    }

    const saveVisit = (data) => {
        if (bookingContext.visit.mStatoVisita === bookingContext.VISIT_STATUS.composingStatus) {
            bookingContext.saveCartVisitTmpPatient(data);
        } else {
            bookingContext.updateVisitTmpPatient(data);
        }
    }

    //save and accept all

    const acceptDisabled = () => {
        return (
            !bookingContext.canAcceptVisit()
        )
    }

    const accept = () => {
        bookingContext.acceptVisit(bookingContext.visit).then();
    }

    const acceptClick = () => {
        if (acceptDisabled()) return;
        alertContext.showConfirmDialog(
            "Conferma accettazione",
            "Stai accettando tutti gli esami. Sei sicuro? ",
            visitSaveCancel,
            accept
        );
    }

    //save and accept selected

    const acceptSelectedDisabled = () => {
        return (
            !bookingContext.canAcceptVisit() || selectedVisitLineRows.filter(r => r.isBooked).length === 0
        )
    }

    const acceptSelected = () => {
        selectedVisitLineRows.map(row => {
            bookingContext.acceptVisitLine(bookingContext.visit, row.mEsameID).then();
        });
        gridRef.current.api.deselectAll();
    }

    const acceptSelectedClick = () => {
        if (acceptSelectedDisabled()) return;
        if (selectedVisitLineRows.length === 0) return;
        alertContext.showConfirmDialog(
            "Conferma accettazione",
            "Stai accettando gli esami selezionati. Sei sicuro? ",
            visitSaveCancel,
            acceptSelected
        );
    }

    //invoice selected

    const invoiceSelectedDisabled = () => {
        return (
            !bookingContext.canInvoicingVisit() || selectedVisitLineRows.filter(r => !r.isInvoiced).length === 0
        )
    }

    const invoiceSelected = () => {
        if (selectedVisitLineRows.length === 0) return;
        const toInvoiceLines = selectedVisitLineRows.filter(r => !r.isInvoiced);
        let accountingItem = {visit: bookingContext.visit, visitLines: toInvoiceLines};
        accountingContext.openAccounting(accountingItem, 1);
        navigate('/Accounting/Home');
    }

    const invoiceSelectedClick = () => {
        if (invoiceSelectedDisabled()) return;
        if (selectedVisitLineRows.length === 0) return;
        alertContext.showConfirmDialog(
            "Conferma fatturazione",
            "Stai fatturando gli esami selezionati. Sei sicuro? ",
            visitSaveCancel,
            invoiceSelected
        );
    }

    //ASL

    const {
        watch: watchASL,
        register: registerASL,
        control: controlASL,
        handleSubmit: handleSubmitASL,
        getValues: getValuesASL,
        setValue: setValueASL,
        reset: resetASL,
    } = useForm({mode: 'onSubmit', defaultValues: bookingContext.visit});

    const aslValues = getValuesASL();
    const [editSponsorEnabled, setEditSponsorEnabled] = useState(false);

    const calcEditSponsorEnabled = aslValues.mSponsor && aslValues.mSponsor.mSponsorID;

    useEffect(() => {
        if (!registriesContext.prioritiesItemizedListValues) registriesContext.fetchPrioritiesItemizedListValues();
    }, [registriesContext.prioritiesItemizedListValues]);

    useEffect(() => {
        const subscription = watchASL(
            (value, {name, type}) => {
                if (type === 'change') {
                    if (name === 'mSponsor') {
                        const values = getValuesASL();
                        (values.mSponsor && values.mSponsor.mSponsorID) ? setEditSponsorEnabled(true) : setEditSponsorEnabled(false);
                    }
                }
            }
        );
        return () => subscription.unsubscribe();
    }, [watchASL]);

    const saveVisitASL = (data) => {
        bookingContext.saveVisitASL(data).then();
    }

    const onSubmitASL = (data) => {
        alertContext.showConfirmDialog(
            "Conferma salvataggio",
            "Stai salvando la visita. Sei sicuro? ",
            visitSaveCancel,
            () => {
                saveVisitASL(data);
            }
        );
    }

    const onErrorASL = (errors, e) => {
        console.log(errors, e);
    }

    const setSponsorByID = (pSponsorID) => {
        setValueASL('mSponsor.mSponsorID', pSponsorID, {});
        bookingContext.setVisit(getValuesASL());
        bookingContext.changeVisitSelectedTab(3);
    }

    const getASLForm = () => {
        return (
            <Box sx={{mt: theme.spacing(1)}}>
                <form onSubmit={handleSubmitASL(onSubmitASL, onErrorASL)}>
                    <Grid
                        container
                        spacing={theme.spacing(1)}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"flex-start"}
                    >
                        <Grid xs={6}
                              container
                              spacing={theme.spacing(1)}
                              direction={"row"}
                              justifyContent={"flex-start"}
                              alignItems={"flex-start"}
                        >
                            <Grid item xs={12} md={6}>
                                <FormTextBox
                                    label="Numero ricetta"
                                    control={controlASL}
                                    record={bookingContext.visit}
                                    register={registerASL}
                                    field="mNumeroRicetta"
                                    required={true}
                                    minLength={15}
                                    maxLength={15}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormDatePicker
                                    label="Data ricetta"
                                    control={controlASL}
                                    record={bookingContext.visit}
                                    register={registerASL}
                                    field="mDataRicetta"
                                    required={true}
                                    format={commonContext.getPlanningParameter("DATE_FORMAT_SHORT")}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormAutoComplete
                                    label="Esenzione"
                                    control={controlASL}
                                    record={bookingContext.visit}
                                    register={registerASL}
                                    values={registriesContext.exemptions}
                                    field="mEsenzione"
                                    joinField="mEsenzioneID"
                                    idField="mEsenzioneID"
                                    labelField="mEsenzioneDescription"
                                    selectedValue="id"
                                    required={false}
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormAutoComplete
                                    label="Priorità"
                                    control={controlASL}
                                    record={bookingContext.visit}
                                    register={registerASL}
                                    field="mPriority"
                                    joinField=""
                                    values={registriesContext.prioritiesItemizedListValues}
                                    idField="mItemizedListValueID"
                                    labelField="mValue"
                                    selectedValue="label"
                                    required={false}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <FormCheckBox
                                    label="Primo accesso"
                                    control={controlASL}
                                    record={bookingContext.visit}
                                    register={registerASL}
                                    getValues={getValuesASL}
                                    field="mPrimoAccesso"
                                    reset={resetASL}
                                    required={false}
                                />
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <FormAutoComplete
                                    label="Soggetto prescrittore"
                                    control={controlASL}
                                    record={bookingContext.visit}
                                    register={registerASL}
                                    values={registriesContext.sponsors}
                                    field="mSponsor"
                                    joinField="mSponsorID"
                                    idField="mSponsorID"
                                    labelField="mCompleteName"
                                    selectedValue="id"
                                    required={true}
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <ActionButton
                                    color="primary"
                                    variant="contained"
                                    action={() => {
                                        setSponsorID(0);
                                    }}
                                    size="small"
                                    icon="new"
                                    iconSize="small"
                                    tooltip="Nuovo"
                                    label=""
                                    sx={loggedClasses.registriesToolbarSx}
                                    disabled={false}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <ActionButton
                                    color="primary"
                                    variant="contained"
                                    action={() => {
                                        const values = getValuesASL();
                                        setSponsorID(values.mSponsor.mSponsorID)
                                    }}
                                    size="small"
                                    icon="edit"
                                    iconSize="small"
                                    tooltip="Modifica"
                                    label=""
                                    sx={loggedClasses.registriesToolbarSx}
                                    disabled={!editSponsorEnabled && !calcEditSponsorEnabled}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormActionBar hasCancel={false}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <SponsorDetail recordID={sponsorID} setRecordID={setSponsorID} saveCallback={setSponsorByID}/>
            </Box>
        )
    }

    const getOtherForm = () => {
        return (
            <Box sx={{mt: theme.spacing(1)}}>
                <form onSubmit={handleSubmitASL(onSubmitASL, onErrorASL)}>
                    <Grid
                        container
                        spacing={theme.spacing(1)}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12} md={3}>
                            <FormAutoComplete
                                label="Soggetto prescrittore"
                                control={controlASL}
                                record={bookingContext.visit}
                                register={registerASL}
                                values={registriesContext.sponsors}
                                field="mSponsor"
                                joinField="mSponsorID"
                                idField="mSponsorID"
                                labelField="mCompleteName"
                                selectedValue="id"
                                required={true}
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <ActionButton
                                color="primary"
                                variant="contained"
                                action={() => {
                                    setSponsorID(0);
                                }}
                                size="small"
                                icon="new"
                                iconSize="small"
                                tooltip="Nuovo"
                                label=""
                                sx={loggedClasses.registriesToolbarSx}
                                disabled={false}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <ActionButton
                                color="primary"
                                variant="contained"
                                action={() => {
                                    const values = getValuesASL();
                                    setSponsorID(values.mSponsor.mSponsorID)
                                }}
                                size="small"
                                icon="edit"
                                iconSize="small"
                                tooltip="Modifica"
                                label=""
                                sx={loggedClasses.registriesToolbarSx}
                                disabled={editSponsorEnabled}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <FormActionBar hasCancel={false}/>
                </form>
                <SponsorDetail recordID={sponsorID} setRecordID={setSponsorID} saveCallback={setSponsorByID}/>
            </Box>
        )
    }

    //fatture

    const InvoicesToolBar = (props) => {

        let record = props.node.data;

        const pdfDisabled = () => {
            return false
        }

        const pdfAction = () => {
            if (record.mInvoiceType === 1) {
                setInvoiceToOpenID(record.mInvoiceID);
            } else if (record.mInvoiceType === 4) {
                setCreditDocumentToOpenID(record.mInvoiceID);
            }
        };

        return (
            <ActionsToolBar
                hasPDF pdfAction={pdfAction} pdfDisabled={pdfDisabled()}
            />
        )
    }

    const invoicesColumns = [
        {headerName: "Numero", field: "mInvoiceNumber", maxWidth: 130},
        {headerName: "Data", field: "mInvoiceDate", cellRenderer: dateColumnRenderer},
        {headerName: "Tipo", field: "mInvoiceTypeDescription"},
        {headerName: "Totale", field: "mTotalAdmount", cellRenderer: moneyColumnRenderer, maxWidth: 120},
        {headerName: "Azioni", cellRenderer: InvoicesToolBar, type: "toolBar"}
    ];

    // fine

    const tabChange = (event, newValue) => {
        bookingContext.changeVisitSelectedTab(newValue);
    };

    const AddButton = () => {

        const buttonClicked = () => {

            const defaultRoom = registriesContext.fetchDefaultRoom();

            const initRoomID =
                (defaultRoom) ? defaultRoom :
                    (activeRooms.length !==0) ? activeRooms[0].mRoomID : -1;

            commonContext.calculateFirstAvailability(initRoomID).then((dt) => {

                let booking = {
                    room: initRoomID,
                    slot: dt
                }
                bookingContext.openBooking(booking, 21);
                navigate('/Booking/TakeBooking');
            });
        };

        return (
            <ActionButton
                color="secondary"
                variant="contained"
                action={buttonClicked}
                size="small"
                icon="calendar"
                iconSize="medium"
                tooltip="Aggiungi esame"
                label="Aggiungi"
            />
        );
    };

    if (isDataNull(registriesContext.exemptions) || isDataLoading(registriesContext.exemptions)) return <CircularWaiting/>
    if (isDataNull(registriesContext.sponsors) || isDataLoading(registriesContext.sponsors)) return <CircularWaiting/>

    if (!bookingContext.visit && bookingContext.visit.mVisitaID !== 0) return <CircularWaiting/>

    if (!bookingContext.visit || bookingContext.visit.mVisitaID === 0) return <CircularWaiting/>

    if (bookingContext.visit && bookingContext.visitIsEmpty()) {
        bookingContext.goToRoomsPlanner();
        navigate(DEFAULT_AGENDA_URL);
    }

    if (bookingContext.isVisitSaving) return <CircularWaiting/>

    const canPrintModules = () => {
        return bookingContext.canPrintModules();
    }

    const VisitStyledPaper = styled(Paper)(
        loggedClasses.visitInfoSticky
    );

    const switchInfoPanel = (event) => {
        setInfoOpen(!infoOpen);
    }

    const stickyStyle = {
        position: 'fixed',
        top: '22vh',
        left: 'calc(100vw - 15vw)',
        zIndex: 5,
    }

    function onVisitLinesSelectionChanged() {
        setSelectedVisitLineRows(gridRef.current.api.getSelectedNodes().map(node => node.data) ?? []);
    }

    const openAttachmentsClicked = () => {
        if (bookingContext.visit.mVisitaID === 0) return;
        setAttachments("loading");
        bookingContext.fetchVisitAttachments(bookingContext.visit.mVisitaID).then(res => {
            setAttachments(res);
            setAttachmentsModalOpen(true);
        });
    };

    if (bookingContext.getVisitLinesCount() === 0) {
        bookingContext.goToRoomsPlanner();
        navigate(DEFAULT_AGENDA_URL);
    }

    const mPatientCF = (bookingContext.visit && bookingContext.visit.mPatient) ? bookingContext.visit.mPatient.mCF ?? '' : '';

    return (
        <>
            <Box sx={stickyStyle}>
                <Fab color="primary" aria-label="add" onClick={switchInfoPanel} variant={"extended"}>
                    Info visita
                    <IconLibraryItem name={"info"} iconSize={"large"}/>
                </Fab>
                {

                    (infoOpen) ?
                        <VisitStyledPaper>
                            <Grid container spacing={theme.spacing(1)}>
                                <Grid item xs={12}>
                                    <StyledCardHeader>
                                        <Grid container spacing={0}>
                                            <Grid item xs={10}>
                                                <Typography variant={"h5"} component={"span"}
                                                            justifyContent={"center"}>{"Dettagli visita"}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <SquareButton
                                                    color="primary"
                                                    variant={"outlined"}
                                                    action={switchInfoPanel}
                                                    size="small"
                                                    icon="close"
                                                    iconSize="small"
                                                    tooltip="Chiudi"
                                                    label=""
                                                />
                                            </Grid>
                                        </Grid>
                                    </StyledCardHeader>
                                </Grid>
                                <Grid item container display={"flex"} alignItems={"center"}>
                                    <Grid item xs={12} md={8}>
                                        # Visita
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant={"body1"}>
                                            {bookingContext.visit.mAccessionNumber}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container display={"flex"} alignItems={"center"}>
                                    <Grid item xs={12} md={8}>
                                        Stato visita
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <StatusBadge
                                            label={(bookingContext.visit.mStatoVisita ?? "").toUpperCase()}
                                            color={"info"} fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container display={"flex"} alignItems={"center"}>
                                    <Grid item xs={12} md={8}>
                                        Convenzione
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant={"body1"}>
                                            {bookingContext.visit.mConvention.mConventionDescription}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container display={"flex"} alignItems={"center"}>
                                    <Grid item xs={12} md={8}>
                                        Imponibile
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant={"body1"}>
                                            {"€ " + floatToString(bookingContext.visit.mImponibileNonScontato)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container display={"flex"} alignItems={"center"}>
                                    <Grid item xs={12} md={8}>
                                        Tipologia pagamento
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <StatusBadge
                                            label={((bookingContext.visit.mTipoPagamento ?? {}).mDescription ?? "").toUpperCase()}
                                            color={"info"} fullWidth/>
                                    </Grid>
                                </Grid>
                                {
                                    (bookingContext.visit.mConvention.mIsASL ?? false) &&
                                    <>
                                        <Grid item container>
                                            <Grid item xs={12} md={8}>
                                                Sconti
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant={"body1"}>
                                                    {"€ " + floatToString(bookingContext.visit.mTotaleSconti)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container>
                                            <Grid item xs={12} md={8}>
                                                Imponibile scontato
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant={"body1"}>
                                                    {"€ " + floatToString(bookingContext.visit.mImponibileScontato)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                                <Grid item container display={"flex"} alignItems={"center"}>
                                    <Grid item xs={12} md={8}>
                                        Tasse e bolli
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant={"body1"}>
                                            {"€ " + floatToString(bookingContext.visit.mTotaleTasse)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container display={"flex"} alignItems={"center"}>
                                    <Grid item xs={12} md={8}>
                                        Importo finale
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <StatusBadge
                                            label={"€ " + floatToString(bookingContext.visit.mTotaleFinale)}
                                            color={"success"} fullWidth/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </VisitStyledPaper>
                        :
                        <></>
                }
            </Box>
            <Grid
                container
                display="flex"
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={loggedClasses.bookingDetailHeader}
                key="VisitModalGrid"
            >
                <Grid item xs={12}>
                    <Paper sx={loggedClasses.visitTabContainer} variant={"outlined"}>
                        <Tabs
                            onChange={tabChange}
                            aria-label="basic tabs example"
                            value={selectedTab}
                            sx={loggedClasses.visitTabButtons}
                        >
                            <Tab
                                icon={<FavoriteTwoTone/>}
                                label="Esami" {...a11yProps(0)}
                                sx={loggedClasses.visitTabButton}
                            />
                            <Tab
                                icon={<PhoneTwoTone/>}
                                label="Prenotazione" {...a11yProps(1)}
                                sx={loggedClasses.visitTabButton}
                            />
                            <Tab
                                icon={<PhoneTwoTone/>}
                                label="Dati paziente" {...a11yProps(2)}
                                sx={loggedClasses.visitTabButton}
                            />
                            {
                                (bookingContext.visit.mConvention.mIsASL ?? false) ?
                                    <Tab
                                        icon={<PersonPinTwoTone/>}
                                        label="ASL" {...a11yProps(3)}
                                        sx={loggedClasses.visitTabButton}
                                    />
                                    :
                                    <Tab
                                        icon={<PersonPinTwoTone/>}
                                        label="Altri dati" {...a11yProps(3)}
                                        sx={loggedClasses.visitTabButton}
                                    />
                            }
                            {
                                ((bookingContext.visit.mListOfInvoice ?? []).length > 0) ?
                                    <Tab
                                        icon={<ReceiptTwoTone/>}
                                        label="Fatture" {...a11yProps(4)}
                                        sx={loggedClasses.visitTabButton}
                                    /> :
                                    <></>
                            }
                        </Tabs>
                        <TabPanel
                            value={selectedTab}
                            index={0}
                            sx={loggedClasses.visitTabPanel}
                        >
                            <Stack direction={"row"} spacing={theme.spacing(1)}>
                                <StackedItem>
                                    <AddButton/>
                                </StackedItem>
                                <StackedItem>
                                    <ActionButton
                                        color="secondary"
                                        variant="contained"
                                        action={acceptClick}
                                        size="small"
                                        icon="check-list"
                                        iconSize="medium"
                                        tooltip="Accetta tutti"
                                        label="Accetta tutti"
                                        disabled={acceptDisabled()}
                                    />
                                </StackedItem>
                                <StackedItem>
                                    <ActionButton
                                        color="secondary"
                                        variant="contained"
                                        action={acceptSelectedClick}
                                        size="small"
                                        icon="check"
                                        iconSize="medium"
                                        tooltip="Accetta selezionati"
                                        label="Accetta selezionati"
                                        disabled={acceptSelectedDisabled()}
                                    />
                                </StackedItem>
                                <StackedItem>
                                    <ActionButton
                                        color="secondary"
                                        variant="contained"
                                        action={invoiceSelectedClick}
                                        size="small"
                                        icon="bill"
                                        iconSize="medium"
                                        tooltip="Fattura selezionati"
                                        label="Fattura selezionati"
                                        disabled={invoiceSelectedDisabled()}
                                    />
                                </StackedItem>
                                <StackedItem>
                                    <ActionButton
                                        color="secondary"
                                        variant="contained"
                                        action={openAttachmentsClicked}
                                        size="small"
                                        icon="attach"
                                        iconSize="medium"
                                        tooltip="Allegati"
                                        label="Allegati"
                                    />

                                </StackedItem>
                                {
                                    (canPrintModules()) ?
                                        <StackedItem>
                                            <VisitModulesButton visitID={bookingContext.visit.mVisitaID}/>
                                        </StackedItem>
                                        : <></>
                                }
                                <StackedItem>
                                    <AceButton patientID={mPatientCF}/>
                                </StackedItem>

                            </Stack>
                            <div id="dgVisitLines" className="ag-theme-alpine" style={gridSizeStyle}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={bookingContext.visit.mListOfEsami}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columns}
                                    columnTypes={columnTypes}
                                    suppressMovableColumns={true}
                                    suppressCellFocus={true}
                                    pagination={true}
                                    rowSelection={'multiple'}
                                    getRowHeight={(params) => gridRowHeight}
                                    onSelectionChanged={onVisitLinesSelectionChanged}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel
                            value={selectedTab}
                            index={1}
                            sx={loggedClasses.visitTabPanel}
                        >
                            {getVisitTempPatientForm()}
                            {/*<Divider light/>*/}
                            {/*{getSearchPatientBox()}*/}
                        </TabPanel>
                        <TabPanel
                            value={selectedTab}
                            index={2}
                            sx={loggedClasses.visitTabPanel}
                        >
                            {getSearchPatientBox()}
                            <Divider light/>
                            {getVisitRealPatientForm()}
                        </TabPanel>
                        {
                            (bookingContext.visit.mConvention.mIsASL ?? false) ?
                                <TabPanel
                                    value={selectedTab}
                                    index={3}
                                    sx={loggedClasses.visitTabPanel}
                                >
                                    {getASLForm()}
                                </TabPanel>
                                :
                                <TabPanel value={selectedTab} index={3} sx={loggedClasses.visitTabPanel}>
                                    {getOtherForm()}
                                </TabPanel>
                        }
                        {
                            ((bookingContext.visit.mListOfInvoice ?? []).length > 0) ?
                                <TabPanel
                                    value={selectedTab}
                                    index={4}
                                    sx={loggedClasses.visitTabPanel}
                                >
                                    <div id="dgInvoices" className="ag-theme-alpine" style={gridSmallSizeStyle}>
                                        <AgGridReact
                                            rowData={bookingContext.visit.mListOfInvoice}
                                            defaultColDef={defaultColDef}
                                            columnDefs={invoicesColumns}
                                            columnTypes={columnTypes}
                                            suppressMovableColumns={true}
                                            suppressCellFocus={true}
                                            pagination={true}
                                            getRowHeight={(params) => gridRowHeight}
                                        />
                                    </div>
                                </TabPanel>
                                : <></>
                        }

                    </Paper>
                </Grid>
            </Grid>
            <VisitLineAssignee modalOpen={assigneeOpen} closeClicked={assigneCloseClick}
                               visitLines={bookingContext.visit.mListOfEsami} selectedVisitLines={selectedVisitLineRows}
                               assigneeID={assignedDoctor}/>
            <CreditDocumentPreview documentID={creditDocumentToOpenID} setDocumentID={setCreditDocumentToOpenID}/>
            <InvoicePreview documentID={invoiceToOpenID} setDocumentID={setInvoiceToOpenID}/>
            {
                bookingContext.visit && bookingContext.visit.mVisitaID !== 0 && attachmentsModalOpen === true &&
                <VisitAttachmentsModal
                    visitID={bookingContext.visit.mVisitaID}
                    modalOpen={attachmentsModalOpen}
                    setModalOpen={setAttachmentsModalOpen}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    canInsert={true}
                />
            }
        </>
    )
}

export default VisitDetail;