import {Stack} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import moment from "moment";
import React, {useContext, useState} from 'react';
import {InputDatePicker} from "../Input/InputDatePicker";
import {CommonContext} from "../../context/CommonContext";
import {InputDateTimePicker} from "../Input/InputDateTimePicker";

export const DateTimeFilter = ({
                               showFrom,
                               showTo,
                               currentValue,
                               setValue,
                               isDisabled
                           }) => {

    const commonContext = useContext(CommonContext);

    const theme = useTheme();

    const it = moment().locale('it');

    const [resetted, setResetted] = useState(false);

    return (
        <Stack direction={"row"} spacing={theme.spacing(2)}>
            {
                (showFrom ?? false) &&
                    <InputDateTimePicker
                        label="Da"
                        openTo="day"
                        views={['year', 'month', 'day', 'hours', 'minutes']}
                        value={currentValue.mFromDate}
                        format={commonContext.getPlanningParameter("DATETIME_FORMAT_SHORT")}
                        setValue={(newValue) => {
                            const newFilter = {mFromDate: newValue, mToDate: currentValue.mToDate};
                            setValue(newFilter);
                        }}
                        sx={{width: 300}}
                        disabled={isDisabled}
                    />
            }
            {
                (showTo ?? false) &&
                    <InputDateTimePicker
                        label="A"
                        openTo="day"
                        views={['year', 'month', 'day', 'hours', 'minutes']}
                        value={currentValue.mToDate}
                        format={commonContext.getPlanningParameter("DATETIME_FORMAT_SHORT")}
                        setValue={(newValue) => {
                            const newFilter = {mFromDate: currentValue.mFromDate, mToDate: newValue};
                            setValue(newFilter);
                        }}
                        sx={{width: 300}}
                        disabled={isDisabled}
                    />
            }
        </Stack>
    )

}