import {
    Autocomplete,
    Avatar,
    Box,
    CardActions,
    CardContent,
    Grid,
    ListItem,
    ListItemAvatar,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useDropzone} from "react-dropzone";
import {downloadGenericListOfByte, isDataLoading, isDataNull} from "../../common";
import {AuthContext} from "../../context/AuthContext";
import {RegistriesContext} from "../../context/RegistriesContext";
import {loggedClassNames, userImageDropZoneStyles} from "../../theme/Styles";
import {ActionButton} from "../Buttons/ActionButton";
import {ActionsToolBar} from "../Grid/ActionsToolbar";
import {BookingContext} from "../../context/BookingContext";
import {CardModal} from "../Layout/CardModal";

export const VisitAttachmentsModal = ({
                                          visitID,
                                          modalOpen,
                                          setModalOpen,
                                          attachments,
                                          setAttachments,
                                          canInsert
                                      }) => {

    const authContext = useContext(AuthContext);
    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [selectedFileType, setSelectedFileType] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileContent, setSelectedFileContent] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadMessage, setUploadMessage] = useState("");

    useEffect(() => {
        if (isDataNull(registriesContext.attachTypeItemizedListValues) && !isDataLoading(registriesContext.attachTypeItemizedListValues)) registriesContext.fetchAttachTypeItemizedListValues();
    }, [registriesContext.attachTypeItemizedListValues]);

    const onDrop = useCallback(acceptedFiles => {

        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            setUploadMessage('');
            setSelectedFile(file);
            reader.onabort = () => setUploadMessage('La lettura del file è stata interrotta.')
            reader.onerror = () => setUploadMessage('La lettura del file è fallita.')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                setSelectedFileContent(binaryStr);
            }
            reader.readAsDataURL(file);
        })
    }, []);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isFocused,
        isDragAccept,
        isDragReject,
        inputRef
    } = useDropzone({
        maxSize: 100000000, // 100 mb
        multiple: false,
        onDrop
    })

    const dropZoneStyles = userImageDropZoneStyles(theme);

    const style = useMemo(() => ({
        ...dropZoneStyles.smallDropzoneBase,
        ...(isDragActive ? dropZoneStyles.userImageDropzoneActive : {}),
        ...(isFocused ? dropZoneStyles.userImageDropzoneFocused : {}),
        ...(isDragAccept ? dropZoneStyles.userImageDropzoneAccepted : {}),
        ...(isDragReject ? dropZoneStyles.userImageDropzoneRejected : {})
    }), [isDragActive, isFocused, isDragAccept, isDragReject]);

    const resetUpload = () => {
        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length);
        setUploadMessage("");
        setSelectedFileType(null);
        setSelectedFile(null);
        if (inputRef.current) inputRef.current.value = '';
    }

    let inputTypeItems = [];
    if (registriesContext.attachTypeItemizedListValues) {
        registriesContext.attachTypeItemizedListValues.map((item) => {
            const idValue = item.mItemizedListValueID;
            const labelValue = item.mValue;
            inputTypeItems.push({id: idValue, label: labelValue});
            return true;
        });
    }

    const closeClicked = () => {
        setModalOpen(false);
    };

    const onClose = (event, reason) => {
        setModalOpen(false);
    }

    const uploadFile = () => {
        const mimeType = selectedFile.type;
        const name = selectedFile.name;
        const fileContent = selectedFileContent.replace("data:" + mimeType + ";base64,", "");
        setIsUploading(true);
        bookingContext.updateVisitAttachment(visitID, name, fileContent).then((res) => {
            setIsUploading(false);
            setUploadMessage("Upload avvenuto con successo");
            acceptedFiles.length = 0
            acceptedFiles.splice(0, acceptedFiles.length)
            if (inputRef.current) inputRef.current.value = '';
            setAttachments("loading");
            bookingContext.fetchVisitAttachments(visitID).then(res => {
                setAttachments(res);
                resetUpload();
            });

        })
    }

    const AttachmentToolBar = (item) => {

        const deleteAction = () => {
            alert('delete ' + item.mFileID);
        };

        const viewAction = () => {
            bookingContext.downloadVisitAttachment(item.mFileID).then((res) => {
                if (res !== null) downloadGenericListOfByte(res.mListOfBytes, item.mFileName);
            })
        };

        return (
            <ActionsToolBar
                hasView viewAction={viewAction}
                hasDelete deleteAction={deleteAction}
            />
        )
    }

    return (
        <>

            {(!isDataNull(attachments) && !isDataLoading(attachments)) &&
                <CardModal
                    modalOpen={modalOpen}
                    onClose={onClose}
                    title={"Allegati visita " + visitID}
                >
                    <CardContent sx={loggedClasses.smallDetailsContent}>
                        <Grid
                            container
                            spacing={theme.spacing(6)}
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            sx
                        >
                            <Grid item xs={12} md={6}>
                                <Grid
                                    container
                                    spacing={theme.spacing(1)}
                                    direction={"row"}
                                    justifyContent={"flex-start"}
                                    alignItems={"flex-start"}
                                >
                                    {
                                        (canInsert) ?
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography variant={'h6'}>Aggiungi allegato</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography>Selezionare il tipo di allegato, il file da caricare e
                                                        fare click su Upload.</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        size={"small"}
                                                        name={`auto-complete-docu-type`}
                                                        key={`auto-complete-docu-type`}
                                                        disablePortal
                                                        options={inputTypeItems}
                                                        defaultValue={selectedFileType}
                                                        onChange={(event, item) => {
                                                            item = item ?? {};
                                                            setSelectedFileType(item.label);
                                                        }}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                label={"Tipo di allegato"}
                                                                variant="filled"
                                                                {...params}
                                                            />
                                                        }
                                                        disabled={isUploading}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} disabled={isUploading}>
                                                    <div  {...getRootProps({style})}>
                                                        <input {...getInputProps()} />
                                                        {
                                                            isDragActive ?
                                                                <Typography variant={"caption"}>Trascina il file qui
                                                                    ...</Typography> :
                                                                <Typography variant={"caption"}>Trascina il file
                                                                    qui, o fai click per selezionare un
                                                                    file</Typography>
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {
                                                        selectedFile &&
                                                        <Typography variant={"caption"}>{selectedFile.name}</Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <ActionButton
                                                        color="primary"
                                                        variant="contained"
                                                        action={uploadFile}
                                                        size="small"
                                                        icon={isUploading ? "pending" : "upload"}
                                                        iconSize="small"
                                                        tooltip="Upload"
                                                        label="Upload"
                                                        disabled={!selectedFileType || !selectedFile || isUploading}
                                                    />
                                                </Grid>
                                                {
                                                    uploadMessage !== '' && (
                                                        <Grid item xs={8}>
                                                            <Typography color={"error"}>{uploadMessage}</Typography>
                                                        </Grid>
                                                    )
                                                }
                                            </>
                                            :
                                            <></>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {
                                    ((attachments ?? []).length === 0) ?
                                        <Box>
                                            <Typography variant={"h4"}>Nessun allegato presente!</Typography>
                                        </Box>
                                        :
                                        <>
                                            <Grid item xs={12}>
                                                <Typography variant={'h6'}>Lista allegati caricati</Typography>
                                            </Grid>
                                            <List dense={true}>
                                                {
                                                    attachments.map((item, index) => {
                                                        return (
                                                            <ListItem
                                                                secondaryAction={(canInsert) ? AttachmentToolBar(item) : <></>}>
                                                                <ListItemAvatar>
                                                                    <Avatar
                                                                        sx={{bgcolor: theme.palette.secondary.main}}> {index + 1} </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText primary={item.mFileName}/>
                                                            </ListItem>)
                                                    })
                                                }
                                            </List>
                                        </>
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <ActionButton
                            color="primary"
                            variant="contained"
                            action={closeClicked}
                            size="large"
                            icon="cancel"
                            iconSize="medium"
                            tooltip="Chiudi"
                            label="Chiudi"
                        />
                    </CardActions>
                </CardModal>
            }
        </>
    )
        ;
};
