import {
    Avatar,
    Box,
    CardActions,
    CardContent,
    Grid,
    ListItem,
    ListItemAvatar,
    Typography,
    useTheme
} from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import React, {useContext, useEffect, useState} from "react";
import {isDataLoading, isDataNull} from "../../common";
import {AuthContext} from "../../context/AuthContext";
import {RegistriesContext} from "../../context/RegistriesContext";
import {loggedClassNames} from "../../theme/Styles";
import {ActionButton} from "../Buttons/ActionButton";
import {ActionsToolBar} from "../Grid/ActionsToolbar";
import {CardModal} from "../Layout/CardModal";
import {VisitModulePreview} from "./VisitModulePreview";

export const VisitModulesButton = ({visitID}) => {

    const authContext = useContext(AuthContext);
    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedModuleType, setSelectedModuleType] = useState({id: -1, label: ""});

    useEffect(() => {
        if (isDataNull(registriesContext.moduleTypeItemizedListValues) && !isDataLoading(registriesContext.moduleTypeItemizedListValues)) registriesContext.fetchModuleTypeItemizedListValues();
    }, [registriesContext.moduleTypeItemizedListValues]);

    let outputTypeItems = [];
    if (registriesContext.moduleTypeItemizedListValues) {
        registriesContext.moduleTypeItemizedListValues.map((item) => {
            const idValue = item.mItemizedListValueID;
            const labelValue = item.mValue;
            outputTypeItems.push({id: idValue, label: labelValue});
            return true;
        });
    }

    const openClicked = () => {
        setModalOpen(true);
    };

    const closeClicked = () => {
        setModalOpen(false);
    };

    const onClose = (event, reason) => {
        setModalOpen(false);
    }

    const ModuleToolBar = (item) => {

        const viewPDF = () => {
            setSelectedModuleType(item);
        }

        return (
            <ActionsToolBar
                hasPDF customAction={viewPDF}
            />
        )
    }

    return (
        <>
            <ActionButton
                color="secondary"
                variant="contained"
                action={openClicked}
                size="small"
                icon="attach"
                iconSize="medium"
                tooltip="Moduli"
                label="Moduli"
            />
            <CardModal
                modalOpen={modalOpen}
                onClose={onClose}
                title={"Moduli visita " + visitID}
            >
                <CardContent>
                    <Grid
                        container
                        spacing={theme.spacing(4)}
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12}>
                            {
                                (outputTypeItems.length === 0) ?
                                    <Box>
                                        <Typography variant={"h4"}>Nessun modulo presente!</Typography>
                                    </Box>
                                    :
                                    <List dense={true}>
                                        {
                                            outputTypeItems.map((item, index) => {
                                                return (
                                                    <ListItem secondaryAction={ModuleToolBar(item)}>
                                                        <ListItemAvatar>
                                                            <Avatar sx={{bgcolor: theme.palette.secondary.main}}> {index + 1} </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={item.id + ' - ' + item.label}/>
                                                    </ListItem>)
                                            })
                                        }
                                    < /List>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <ActionButton
                        color="primary"
                        variant="contained"
                        action={closeClicked}
                        size="large"
                        icon="cancel"
                        iconSize="medium"
                        tooltip="Chiudi"
                        label="Chiudi"
                    />
                </CardActions>
            </CardModal>
            <VisitModulePreview visitID={visitID} setModulType={setSelectedModuleType} moduleType={selectedModuleType}/>
        </>
    )
        ;
};
