import {alpha} from "@mui/material";

export default function Button(theme) {

    let boxShadow = `${alpha(theme.palette.grey[300], 0.8)} 0px 3px 1px -2px, `;
    boxShadow += `${alpha(theme.palette.grey[300], 0.8)} 0px 2px 2px 0px,`;
    boxShadow += `${alpha(theme.palette.grey[300], 0.8)} 0px 1px 5px 0px`;

    const disabledStyle = {
        '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[200],
            // boxShadow: `0px 0px 0px 2px ${alpha(theme.palette.grey[300], 0.8)}`,
            boxShadow: boxShadow
        }
    };

    return {
        MuiButton: {
            defaultProps: {
                //disableElevation: true
            },
            styleOverrides: {
                root: {
                    minHeight: '58px',
                    fontSize: '0.75rem !important',
                    // boxShadow: boxShadow,
                    boxShadow: 3,
                    backgroundColor: theme.palette.secondary.main,
                    // "&:hover": {
                    //     backgroundColor: theme.palette.primary.light,
                    // }
                },
                contained: {
                    ...disabledStyle,
                },
                outlined: {
                    color: theme.palette.secondary.contrastText,
                    ...disabledStyle
                },
                textPrimary: {
                    "&:hover": {
                        backgroundColor: theme.palette.secondary.light,
                    }
                },
                outlinedPrimary: {
                    "&:hover": {
                        backgroundColor: theme.palette.secondary.light,
                    }
                },
                outlinedSecondary: {
                    "&:hover": {
                        backgroundColor: theme.palette.secondary.light,
                    }
                },
                containedPrimary: {
                    "&:hover": {
                        backgroundColor: theme.palette.secondary.light,
                    }
                },
                containedSecondary: {
                    "&:hover": {
                        backgroundColor: theme.palette.secondary.light,
                    }
                }

            }
        }
    };
}
