export default function Table(theme) {
    return {
        MuiTableContainer: {
            styleOverrides: {
                root: {}
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {}
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: { }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: { }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                }
            }
        },
        MuiTableBody: {
            styleOverrides: {
                root: {}
            }
        }
    };
}
