import {FormControl, Slider, TextField} from "@mui/material";
import React from 'react';
import {Controller} from "react-hook-form";

export const FormDiscreteSlider = ({
                                       ...props
                                   }) => {

    const getOptions = () => {
        let options = {}
        if (props.required !== null) {
            options.required = {};
            options.required.value = props.required;
            if (props.required === true) {
                options.required.message = "Campo richiesto";
            }
        }
        return options;
    }

    const isDisabled = () => {
        if (props.disabled) {
            return props.disabled;
        } else {
            return false;
        }
    }

    return (
        <FormControl fullWidth>
            <Controller
                name={props.field}
                control={props.control}
                render={({
                             field: {onChange, onBlur, value, name, ref},
                             fieldState: {invalid, isTouched, isDirty, error},
                             formState,
                         }) => (
                    <>
                        <TextField
                            name={`textfield-${name}`}
                            key={`textfield-${name}`}
                            variant="filled"
                            label={props.label}
                            value={value ?? 0}
                            disabled
                            onBlur={onBlur}
                        />
                        <Slider
                            name={`discrete-slider-${name}`}
                            key={`discrete-slider-${name}`}
                            inputRef={ref}
                            // defaultValue={field.value ?? 0}defaultValue={field.value ?? 0}
                            value={value ?? 0}
                            step={props.step}
                            marks
                            min={props.min}
                            max={props.max}
                            disabled={isDisabled()}
                            onChange={(event, newValue) => {
                                onChange(newValue);
                            }}
                            onBlur={onBlur}
                        />
                    </>

                )}
                {...props.register(props.field, getOptions())}
            />
        </FormControl>
    )

}