import {Alert, Grid, Link, Typography, useTheme} from "@mui/material";
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {useNavigate, withRouter} from 'react-router-dom';
import {FormTextBox} from "../../../components/Form/FormTextBox";
import {AuthContext} from "../../../context/AuthContext";
import {notLoggedClassNames} from "../../../theme/Styles";
import {FormSubmitButton} from "../../../components/Form/FormSubmitButton";

const init_reset_record = {
    username: ""
}

const RememberPassword = (props) => {

    const authContext = useContext(AuthContext);
    const [message, setMessage] = useState("");
    const [record, setRecord] = useState(init_reset_record);

    const theme = useTheme();
    const notLoggedClasses = notLoggedClassNames(theme);
    let navigate = useNavigate();

    const {
        watch,
        register,
        control,
        formState: {errors},
        handleSubmit,
        reset,
        getValues
    } = useForm({defaultValues: record});

    const isTried = authContext.resetPasswordStatus.bTried;
    const isSucceded = authContext.resetPasswordStatus.isSucceded;

    useEffect(() => {
        if ((authContext.resetPasswordStatus.bTried) && (authContext.resetPasswordStatus.isSucceded)) setMessage("Reset riuscito.");
        else if ((authContext.resetPasswordStatus.bTried) && (!authContext.resetPasswordStatus.isSucceded)) setMessage("Reset non riuscito.");
    }, [authContext.resetPasswordStatus]);

    const onSubmit = data => {
        authContext.rememberPassword(data.username);
    }

    const renderButtonText = () => {
        return (authContext.resetPasswordStatus.isResetting) ? "..." : "Reset"
    }

    const renderResultMessage = () => {
        if (!authContext.resetPasswordStatus) return <></>
        if (!authContext.resetPasswordStatus.bTried) return <></>
        let severity = (isSucceded) ? "success" : "error";
        return <Alert severity={severity}>{message}</Alert>
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction={"column"} spacing={theme.spacing(4)} sx={notLoggedClasses.loginContainer}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Reset password</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextBox
                            label="Username"
                            control={control}
                            record={record}
                            register={register}
                            field="username"
                            required={true}
                            maxLength={50}
                            pattern={"email"}
                            disabled={authContext.isResetting}
                        />
                    </Grid>
                    {
                        (isTried) &&
                            <Grid item xs={12}>
                                <Typography>
                                    {renderResultMessage()}
                                </Typography>
                            </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography component={Link} href="/Login" variant="body1" sx={{textDecoration: 'none'}} color="primary" textAlign={"center"}>
                            Torna alla login
                        </Typography>
                    </Grid>
                    {
                        (!isSucceded) &&
                        <Grid item xs={12} textAlign={"center"}>
                            <FormSubmitButton label={renderButtonText()} tooltip={"Reset password"} icon={"reset"} disabled={false}/>
                        </Grid>
                    }
                </Grid>
            </form>
        </>
    )
}
export default RememberPassword;