import {Card, CardContent} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import React, {useContext} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ContainerTitle} from "../../../components/Layout/ContainerTitle";
import {RecupContext} from "../../../context/RecupContext";
import {loggedClassNames} from "../../../theme/Styles";
import {RecupHierarchy} from "./components/RecupHierarchy";


const recupRoutes = [
    {path: '/Recup/Home', component: <RecupHierarchy/>, exact: true},
]

const LocationRenderer = () => {

    const location = useLocation();

    const recupContext = useContext(RecupContext);

    const exactElement = recupRoutes.find(l => l.exact === true && location.pathname === l.path);

    if (exactElement) return exactElement.component;

    const partialElement = recupRoutes.find(l => l.exact === false && location.pathname.startsWith(l.path));

    if (partialElement) return partialElement.component;

}

const RecupContainer = (props) => {

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    let navigate = useNavigate();

    // const mode = recupContext.recupMode;

    return (
        <Card sx={loggedClasses.loggedContent}>
            <CardContent sx={loggedClasses.loggedCardContent}>
                <ContainerTitle
                    title={"ReCUP"}
                    onHomeClick={() => { navigate('/Recup/Home') }}
                    key={"container-title-recup"}
                />
                <LocationRenderer/>
            </CardContent>
        </Card>
    )

}

export default RecupContainer;
