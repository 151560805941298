import {Box, Card, CardContent, Grid, Paper, Stack, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import React, {useContext} from 'react';
import {useNavigate, Link} from "react-router-dom";
import {AuthContext} from "../../../context/AuthContext";
import {RegistriesContext} from "../../../context/RegistriesContext";
import {commonClassNames, loggedClassNames} from "../../../theme/Styles";
import {IconLibraryItem} from "../../../components/Icons/IconLibraryItem";
import {ContainerTitle} from "../../../components/Layout/ContainerTitle";
import {CreditDocumentPreview} from "../accounting/components/CreditDocumentPreview";
import {InvoicePreview} from "../accounting/components/InvoicePreview";

const Home = () => {

    const authContext = useContext(AuthContext);
    const registriesContext = useContext(RegistriesContext);

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);
    const commonClasses = commonClassNames(theme);
    let navigate = useNavigate();

    let desktopItems = authContext.getDesktopMenuItems();

    return (
        <Grid
            item
            container
            direction={"row"}
            display={"flex"}
            alignContent={"flex-start"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            justifyItems={"flex-start"}
            sx={{p: theme.spacing(4)}}
            spacing={theme.spacing(2)}
        >
            <Grid item xs={12}>
                <Typography variant={"h3"}>Benvenuto, {authContext.getLoggedUser().mFirstName}</Typography>
            </Grid>

            {
                desktopItems.map((item, index) => (
                    <Grid item xs={6} md={2} key={'col-' + item.mComponentName}>
                        <Link key={'link-' + item.mComponentName} to={"/" + item.mComponentName} style={loggedClasses.desktopNavButtonLinkStyle}>
                            <Paper elevation={6} sx={loggedClasses.desktopNavButton} key={'paper-' + item.mComponentName}>
                                <Grid
                                    item
                                    container
                                    display={"flex"}
                                    justifyContent={"flex-start"}
                                    alignItems={"center"}
                                    alignContent={"flex-start"}
                                    spacing={theme.spacing(1)}
                                >
                                    <Grid item xs={5}>
                                            <span style={commonClasses.desktopIcon}>
                                                <IconLibraryItem name={item.mImageKey} iconSize={"extra-large"} sx={{mr: theme.spacing(2)}}/>
                                            </span>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant={"body2"}> {item.mFunctionName} </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Link>
                    </Grid>
                ))
            }
        </Grid>
    );
}

export default Home;
