import {CardContent, Grid} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import React, {useContext, useEffect, useState} from "react";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {MedicalReportingContext} from "../../../../context/MedicalReportingContext";
import {loggedClassNames} from "../../../../theme/Styles";
import {CardModal} from "../../../../components/Layout/CardModal";
import {PDFViewer} from "../../../../components/PDF/PDFViewer";

export const MedicalReportPreview = ({
                                         documentID,
                                         onCloseAction
                                     }) => {

    const medicalReportingContext = useContext(MedicalReportingContext);
    const [medicalReportPDF, setMedicalReportPDF] = useState("");

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    useEffect(() => {
        if (documentID === -1) {
            return;
        }
        if (medicalReportPDF === "") {
            medicalReportingContext.fetchMedicalReportPDF(documentID).then((res) => {
                if (res===null) { onClose(); return; }
                setMedicalReportPDF(res.mListOfBytes);
            });
        }
    }, [documentID]);

    const modalOpen = () => {
        return documentID !== -1 && (medicalReportPDF !== "");
    }

    const onClose = (event, reason) => {
        setMedicalReportPDF("");
        onCloseAction();
    }

    if (!modalOpen()) return <></>

    return (
        <CardModal
            modalOpen={modalOpen}
            onClose={onClose}
            title={"Anteprima referto"}
        >
            <CardContent>
                {
                    (medicalReportPDF === "") ?
                        <CircularWaiting/> :
                        <Grid container>
                            <Grid item xs={12}>
                                <PDFViewer dataType={"application/pdf"} dataContent={'data:application/pdf;base64,' + medicalReportPDF}/>
                            </Grid>
                        </Grid>
                }
            </CardContent>
        </CardModal>
    )

}