import {Alert, Autocomplete, Grid, TextField, Typography, useTheme} from "@mui/material";
import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    columnTypes,
    defaultColDef,
    isDataLoading,
    isDataNull,
    JSONClone,
    moneyColumnRenderer, moneyStringToFloat
} from "../../../../common";
import {FormActionBar} from "../../../../components/Form/FormActionBar";
import {FormAutoComplete} from "../../../../components/Form/FormAutoComplete";
import {FormCheckBox} from "../../../../components/Form/FormCheckBox";
import {FormTextBox} from "../../../../components/Form/FormTextBox";
import {CircularWaiting} from "../../../../components/Waitings/CircularWaiting";
import {AccountingContext} from "../../../../context/AccountingContext";
import {CustomAlertContext} from "../../../../context/AlertContext";
import {BookingContext} from "../../../../context/BookingContext";
import {RegistriesContext} from "../../../../context/RegistriesContext";
import {useNavigate} from "react-router-dom";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {AgGridReact} from "ag-grid-react";
import {ActionsToolBar} from "../../../../components/Grid/ActionsToolbar";
import {ActionButton} from "../../../../components/Buttons/ActionButton";

const initialRecord = {
    mIva: 22
}

const initialSelectedService = {
    mServiceID: 0,
    mServiceName: "",
    mServicePrice: 0
}

export const CreateInvoiceFromServices = ({...props}) => {

    const registriesContext = useContext(RegistriesContext);
    const bookingContext = useContext(BookingContext);
    const alertContext = useContext(CustomAlertContext);
    const accountingContext = useContext(AccountingContext);

    const theme = useTheme();
    let navigate = useNavigate();
    const loggedClasses = loggedClassNames(theme);

    const [firstLoaded, setFirstLoaded] = useState(false);
    const [record, setRecord] = useState(JSONClone(initialRecord));
    const [selectedService, setSelectedService] = useState(JSONClone(initialSelectedService));
    const [invoiceLines, setInvoiceLines] = useState([]);
    const [addServiceDisabled, setAddServiceDisabled] = useState(true);

    const {
        watch,
        register,
        control,
        formState: {errors},
        handleSubmit,
        reset,
        getValues,
        setValue
    } = useForm({defaultValues: record});

    const {
        watch: watchService,
        register: registerService,
        control: controlService,
        getValues: getValuesService,
        setValue: setValueService
    } = useForm({defaultValues: selectedService});

    useEffect(() => {
        if (firstLoaded) return;
        if (!isDataLoading(registriesContext.allCompanies)) registriesContext.fetchAllCompanies();
        if (!isDataLoading(registriesContext.payingMethods)) registriesContext.fetchPayingMethodsItemizedListValues();
        if (!isDataLoading(accountingContext.vatItems)) accountingContext.fetchVatItemizedListValues().then(r => {});
        if (!isDataLoading(accountingContext.companyServices)) accountingContext.fetchCompanyServices().then(r => {});
    }, [firstLoaded]);

    useEffect(() => {
        const subscription = watchService(
            (value, {name, type}) => {
                if (type === 'change') {
                    const serviceValues = getValuesService();

                    const serviceNull = (serviceValues.mServiceID ?? 0) ===0;
                    const priceNull = (serviceValues.mServicePrice ?? 0) ===0;

                    setAddServiceDisabled(serviceNull || priceNull);

                }
            }
        );
        return () => subscription.unsubscribe();
    }, [watchService]);

    const onError = (errors, e) => {
        console.log(errors, e);
    }

    const createDisabled = () => {
        return invoiceLines.length===0
    }

    const invoiceCreateCancel = (data) => {
        //niente
    }

    const getSumbitLabel = () => {
        return "Crea";
    }

    const onSubmit = (data) => {
        const title = "Conferma generazione fattura";
        const message = "Stai generando la fattura. Sei sicuro?"
        alertContext.showConfirmDialog(
            title,
            message,
            invoiceCreateCancel,
            () => {
                const body = {
                    ...data,
                    mIva: parseInt(data.mIva),
                    mListOfServices: [...invoiceLines]
                };
                accountingContext.createInvoiceFromServices(body).then((res) => {
                    let accountingItem = {invoice: res};
                    accountingContext.openAccounting(accountingItem, 2);
                    navigate('/Accounting/Home');
                })
            }
        )

    }

    const onClose = () => {
        accountingContext.openAccounting({}, 0);
        navigate('/Accounting/Home');
    }

    const onGridReady = (params) => {
        // setAgGridApi(params.api);
        // params.api.sizeColumnsToFit();
    }

    const ToolBar = (props) => {

        let record = props.node.data;

        const deleteAction = ()=> {
            const foundIndex = invoiceLines.findIndex(item => item.mServiceID === record.mServiceID);
            const lines = [...invoiceLines];
            lines.splice(foundIndex, 1);
            setInvoiceLines(lines);
        }

        return (
            <ActionsToolBar
                hasDelete={true} deleteAction={deleteAction}
            />
        )
    }

    const [columns, setColumns] = useState([
        {headerName: "Nome", field: "mServiceName"},
        {headerName: "Prezzo", field: "mServicePrice", cellRenderer: moneyColumnRenderer},
        {headerName: "Azioni", cellRenderer: ToolBar, type: "toolBar"}
    ]);

    if (isDataNull(registriesContext.payingMethods) || isDataLoading(registriesContext.payingMethods)) return <CircularWaiting/>
    if (isDataNull(accountingContext.vatItems) || isDataLoading(accountingContext.vatItems)) return <CircularWaiting/>
    if (isDataNull(registriesContext.allCompanies) || isDataLoading(registriesContext.allCompanies)) return <CircularWaiting/>

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid
                    container
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                >
                    <Grid
                        item
                        container
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                        spacing={theme.spacing(2)}
                        sx={loggedClasses.bookingDetailModal}
                    >
                        <Grid item xs={12}>
                            <Typography variant="h3">Crea fattura fuori linea</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormAutoComplete
                                label="Azienda"
                                control={control}
                                record={record}
                                register={register}
                                field="mCompany"
                                joinField="mCompanyID"
                                values={registriesContext.allCompanies}
                                idField="mCompanyID"
                                labelField="mCompanyName"
                                selectedValue="id"
                                required={true}
                                selectFirst={true}
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormAutoComplete
                                label="Metodo di pagamento"
                                control={control}
                                record={record}
                                register={register}
                                field="mPayementMode"
                                joinField=""
                                values={registriesContext.payingMethods}
                                idField="mItemizedListValueID"
                                labelField="mValue"
                                selectedValue="label"
                                required={true}
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormAutoComplete
                                label="IVA"
                                control={control}
                                record={record}
                                register={register}
                                field="mIva"
                                joinField=""
                                values={accountingContext.vatItems}
                                idField="mItemizedListValueID"
                                labelField="mValue"
                                selectedValue="label"
                                required={true}
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormAutoComplete
                                label="Servizio"
                                control={controlService}
                                record={selectedService}
                                register={registerService}
                                field="mServiceID"
                                joinField=""
                                values={accountingContext.companyServices}
                                idField="mServiceID"
                                labelField="mServiceName"
                                selectedValue="id"
                                required={false}
                                selectFirst={true}
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormTextBox
                                label="Prezzo"
                                control={controlService}
                                record={selectedService}
                                register={registerService}
                                field="mServicePrice"
                                required={true}
                                maxLength={50}
                                pattern={"money"}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <ActionButton
                                color="primary"
                                variant="contained"
                                action={() => {
                                    const values = getValuesService();
                                    let found = accountingContext.companyServices.find(item => item.mServiceID === values.mServiceID);
                                    found = {
                                        ...found,
                                        mServicePrice: moneyStringToFloat(values.mServicePrice)
                                    }
                                    const lines = [...invoiceLines];
                                    lines.push(found);
                                    setInvoiceLines(lines);
                                    setValueService('mServiceID',0);
                                    setValueService('mServicePrice',0);
                                }}
                                size="small"
                                icon="add"
                                iconSize="small"
                                tooltip="Aggiungi"
                                label="Aggiungi"
                                sx={loggedClasses.registriesToolbarSx}
                                disabled={addServiceDisabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div id="invoiceLinesGrid" className="ag-theme-alpine" style={gridSizeStyle}>
                                <AgGridReact
                                    rowData={invoiceLines}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columns}
                                    columnTypes={columnTypes}
                                    suppressMovableColumns={true}
                                    suppressCellFocus={true}
                                    pagination={false}
                                    rowSelection={'single'}
                                    onGridReady={onGridReady}
                                    getRowHeight={(params) => gridRowHeight}
                                >
                                </AgGridReact>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormActionBar
                                submitLabel={getSumbitLabel()}
                                submitDisabled={createDisabled()}
                                cancelAction={onClose}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    )
        ;
};

export default CreateInvoiceFromServices;