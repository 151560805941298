import {CardContent, Grid, useTheme} from "@mui/material";
import {gridRowHeight, gridSizeStyle, loggedClassNames} from "../../../../theme/Styles";
import {AgGridReact} from "ag-grid-react";
import {columnTypes, defaultColDef} from "../../../../common";
import {CardModal} from "../../../../components/Layout/CardModal";
import React, {useState} from "react";

export const RecupEuMedicalServices = ({
                                           open,
                                           close,
                                           rows
                                       }) => {

    const theme = useTheme();
    const loggedClasses = loggedClassNames(theme);

    const [columnsEuMedicalServices, setColumnsEuMedicalServices] = useState([
        {headerName: "ID", field: "id", flex: 1},
        {headerName: "Codice Cur", field: "cur", flex: 1},
        {headerName: "Nome", field: "name", flex: 3}
    ]);

    return (
        <>
            <CardModal
                modalOpen={open}
                onClose={close}
                title={"Prestazioni collegate"}
                size={"small"}
            >
                <CardContent sx={loggedClasses.detailsContent}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={theme.spacing(2)}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                    >
                        <Grid item xs={12}>
                            <div id="agReactGridEuMedicalServices" className="ag-theme-alpine"
                                 style={gridSizeStyle}>
                                <AgGridReact
                                    rowData={rows}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnsEuMedicalServices}
                                    columnTypes={columnTypes}
                                    suppressMovableColumns={true}
                                    suppressCellFocus={true}
                                    pagination={true}
                                    rowSelection={'single'}
                                    getRowHeight={(params) => gridRowHeight}
                                >
                                </AgGridReact>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardModal>
        </>
    )
}
