import React, {createContext, useContext, useEffect, useState} from 'react';
import {getCompleteName, getConfigKey, isDataLoading, isDataNull, JSONClone, setDataLoading} from "../common";
import * as webServices from "../services/WebServices";
import {CustomAlertContext} from "./AlertContext";
import {API_KEY_SESSION_KEY, getSessionValue, LOGGED_USER_SESSION_KEY, setSessionValue} from "../storage";

const DefaultAuthStatus = {
    isLogging: false,
    bTried: false,
    isLogged: false,
    isExpired: false,
    loggedUser: null,
    responseMessage: "",
    sessionProgress: 0
};

const DefaultResetPasswordStatus = {
    isResetting: false,
    bTried: false,
    isSucceded: false,
};

const DefaultChangePassword = {
    mCurrentPassword: "",
    mNewPassword: "",
    mRepeatNewPassword: ""
};

const newSystemParameter = {
    mParameterID: 0,
    mParamName: "Nuovo parametro"
}

const AuthContext = createContext();

const AuthProvider = (props) => {

    const [userImage, setUserImage] = useState([]);
    const [sessionRefreshSwitch, setSessionRefreshSwitch] = useState({refreshed: false});
    const [changePasswordStatus, setChangePasswordStatus] = useState(JSONClone(DefaultChangePassword));
    const [resetPasswordStatus, setResetPasswordStatus] = useState(JSONClone(DefaultResetPasswordStatus));
    const [systemParameters, setSystemParameters] = useState(null);
    const [paramGroupItemizedListValues, setParamGroupItemizedListValues] = useState(null);
    const [loginStatusItemizedListValues, setLoginStatusItemizedListValues] = useState(null);
    const alertContext = useContext(CustomAlertContext);

    // const maxSessionTime = getConfigKey("sessionDurationMins") * 60 * 1000;
    // const maxSessionTime = 2 * 60 * 1000;
    const maxSessionTime = getConfigKey("sessionDurationMins") * 60 * 1000;
    const sessionCheckInterval = getConfigKey("sessionCheckSecs")  * 1000;

    useEffect(() => {
        if (!checkIsLogged()) return;
        if (isDataNull(systemParameters) && !isDataLoading(systemParameters))fetchSystemParameters().then(()=>{});
    }, [sessionRefreshSwitch]);

    const timedCheck = () => {
        const session = getSessionValue(LOGGED_USER_SESSION_KEY,'');
        const apiKey = getSessionValue(API_KEY_SESSION_KEY,'');
        if (checkIsLogging()) return;
        if (!checkIsLogged()) return;
        ping();
    }

    const fetchSystemParameters = async () => {
        setDataLoading(setSystemParameters);
        let res = await webServices.wsGetSystemParameters({}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setSystemParameters(recs);
        return recs;
    }

    const filterSystemParameter = (pNameToSearch, params) => {
        let ret = null;
        const foundItems = params.filter(param => param.mParamName === pNameToSearch);
        ret = (foundItems.length > 0) ? foundItems[0].mParamValue : "";
        return ret;
    }

    const getSystemParameterValue = (pNameToSearch) => {
        if (!checkIsLogged()) return null;
        if (isDataLoading(systemParameters)) return null;
        let tmpParameters = [];
        if ((systemParameters ?? []).length === 0) return null;
        const ret = filterSystemParameter(pNameToSearch, systemParameters);
        return ret;
    }

    const fetchSystemParameter = (pIdRecord) => {
        if ((systemParameters ?? []).length === 0) return null;
        if (pIdRecord !== 0) {
            let tmpFilter = systemParameters.filter(param => param.mParameterID === pIdRecord);
            return (tmpFilter) ? (tmpFilter.length !== 0) ? tmpFilter[0] : null : null;
        } else {
            return newSystemParameter;
        }
    }

    const fetchParamGroupItemizedListValues = () => {
        let body = {};
        body.mItemizedList = {mItemizedListID: 3};
        webServices.wsGetItemizedListValues(body, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setParamGroupItemizedListValues(recs);
        });
    }

    const resetParamGroupItemizedListValues = () => {
        setParamGroupItemizedListValues(null);
    }

    const fetchLoginStatusItemizedListValues = () => {
        let body = {};
        body.mItemizedList = {mItemizedListID: 25};
        webServices.wsGetItemizedListValues(body, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let recs = res.responseData;
            setLoginStatusItemizedListValues(recs);
        });
    }

    const resetLoginStatusItemizedListValues = () => {
        setLoginStatusItemizedListValues(null);
    }

    const updateSystemParameter = (record) => {
        webServices.wsUpdateSystemParameter(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchSystemParameters().then(()=>{});
            fetchParamGroupItemizedListValues();
        });
    };

    const deleteSystemParameter = (record) => {
        webServices.wsDeleteSystemParameter(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            fetchSystemParameters().then(()=>{});
            fetchParamGroupItemizedListValues();
        });
    };

    const refreshSystemParameters = async () => {
        setSystemParameters(null);
        refreshSession();
    }

    /* SESSION */

    const refreshSession = () => {
        const newValue = !sessionRefreshSwitch.refreshed;
        setSessionRefreshSwitch({refreshed: newValue});
    }

    const setSessionExpired = async () => {
        let mUserID = getLoggedUserID();
        if (mUserID === 0) return;
        let body = {};
        body.mUserID = mUserID;
        body.mDisconnectReason = 0;
        let res = await webServices.wsLogout(body, alertContext, true);
        setSessionValue(LOGGED_USER_SESSION_KEY, DefaultAuthStatus);
        setSessionValue(API_KEY_SESSION_KEY, '');
    };

    const ping = () => {
        webServices.wsPing({}, alertContext, true).then(res => {});
    };

    const getSessionProgressValue = () => {
        let storage = getSessionValue(LOGGED_USER_SESSION_KEY,'');
        return storage.sessionProgress ?? 0;
    }

    const getSessionProgressNormalizedValue = () => {
        let sessionProgress = getSessionProgressValue();
        let res = sessionProgress * 100 / maxSessionTime;
        return res
    }

    const getSessionRemainTime = () => {
        let sessionProgress = getSessionProgressValue();
        return Math.max(0, maxSessionTime - sessionProgress);
    }

    const resetSessionProgressValue = () => {
        setSessionProgressValue(0);
    }

    const setSessionProgressValue = (value) => {
        let currentSession = getSessionValue(LOGGED_USER_SESSION_KEY,'');
        currentSession = {
            ...currentSession,
            sessionProgress: value
        };
        setSessionValue(LOGGED_USER_SESSION_KEY, currentSession);
    }

    const setSessionRemainingTime = (value) => {
        let currentSession = getSessionValue(LOGGED_USER_SESSION_KEY,'');
        currentSession = {
            ...currentSession,
            sessionProgress: maxSessionTime - value
        };
        setSessionValue(LOGGED_USER_SESSION_KEY, currentSession);
    }

    const getSession = () => {
        return getSessionValue(LOGGED_USER_SESSION_KEY,'');
    };

    const checkIsLogging = () => {
        let storage = getSessionValue(LOGGED_USER_SESSION_KEY,'');
        return (storage && storage.isLogging) ? storage.isLogging : false
    }

    const checkIsLogged = () => {
        let storage = getSessionValue(LOGGED_USER_SESSION_KEY,'');
        return (storage && storage.isLogged) ? storage.isLogged : false
    };

    const getLoggedUser = () => {
        let storage = getSessionValue(LOGGED_USER_SESSION_KEY,'');
        return (storage && storage.isLogged) ? storage.loggedUser ?? {} : {};
    };

    const getLoggedUserID = () => {
        return getLoggedUser().mUserID ?? 0;
    };

    const getLoggedUserCompleteName = () => {
        return getCompleteName(getLoggedUser().mFirstName, getLoggedUser().mLastName);
    }

    const getLoggedUserImage = () => {
        return getLoggedUser().mImageListOfBytes ?? [];
    };

    const getLoggedUserProfile = (user) => {
        return (getLoggedUser().mProfile ?? {})
    }

    const getLoggedUserHomePage = () => {
        if (isLoggedUserDesk()) {
            return("/Booking/RoomsPlanner");
        }
        else if (isLoggedUserDoctor()) {
            return("/MedicalReporting/Worklist");
        }
        else {
            return("/Management/ManagementDashboard");
        }
    }

    const getLoggedUserDefaultRoom = () => {
        return getLoggedUser().mDefaultRoom ?? null;
    }

    const isLoggedUserAdmin = () => {
        return (getLoggedUserProfile().mSQLName ?? "") === "ADMIN";
    }

    const isLoggedUserDesk = () => {
        return (getLoggedUserProfile().mSQLName ?? "") === "ACCEPTANCE";
    }

    const isLoggedUserManager = () => {
        return (getLoggedUserProfile().mSQLName ?? "") === "AMM_CONT";
    }

    const isLoggedUserDoctor = () => {
        return (getLoggedUserProfile().mSQLName ?? "") === "DOCTOR";
    }

    const rememberPassword = (pEmail) => {
        let tmpSessionResetting = {...resetPasswordStatus, isResetting: true }
        setResetPasswordStatus(tmpSessionResetting);
        let body = {};
        body.mUsername = pEmail;
        webServices.wsRememberPassword(body, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            let tmpSessionResetting = {...resetPasswordStatus, bTried: true, isSucceded: true }
            tmpSessionResetting.bTried = true;
            tmpSessionResetting.isSucceded = true;
            setResetPasswordStatus(tmpSessionResetting);
            // if (res.responseData.length > 0) {
            //     tmpSessionResetting.isLogged = true;
            //     tmpSessionResetting.loggedUser = res.responseData[0];
            //     tmpSessionResetting.responseMessage = res.responseMessage;
            //     tmpSessionResetting.sessionProgress = 0;
            // } else {
            //     tmpSessionResetting.isLogged = false;
            //     tmpSessionResetting.loggedUser = [];
            //     tmpSessionResetting.responseMessage = res.responseMessage;
            // }
        });
    };

    const register = async (body) => {
        let res = await webServices.wsRegister(body, alertContext, true);
        return res;
    };

    const login = async (body) => {
        setSessionValue(API_KEY_SESSION_KEY, '');
        let tmpSessionLogging = JSONClone(DefaultAuthStatus);
        tmpSessionLogging.isLogging = true;
        setSessionValue(LOGGED_USER_SESSION_KEY, tmpSessionLogging);
        refreshSession();
        let res = await webServices.wsLogin(body, alertContext, true);
        if (res.responseAnyError) return;
        tmpSessionLogging.bTried = true;
        tmpSessionLogging.isLogging = false;
        let apiKey = (res.responseApiKey) ? res.responseApiKey : "";
        setSessionValue(API_KEY_SESSION_KEY, apiKey);
        if (res.responseData.length > 0) {
            tmpSessionLogging.isLogged = true;
            tmpSessionLogging.loggedUser = res.responseData[0];
            tmpSessionLogging.responseMessage = res.responseMessage;
            tmpSessionLogging.sessionProgress = 0;
        } else {
            tmpSessionLogging.isLogged = false;
            tmpSessionLogging.loggedUser = [];
            tmpSessionLogging.responseMessage = res.responseMessage;
        }
        setSessionValue(LOGGED_USER_SESSION_KEY, tmpSessionLogging);
        refreshSession();
        return res;
    };

    const logout = async () => {
        let mUserID = getLoggedUserID();
        if (mUserID === 0) return;
        let body = {};
        body.mUserID = mUserID;
        body.mDisconnectReason = 1;
        let res = await webServices.wsLogout(body, alertContext, true);
        if (res.responseAnyError) return;
        setSessionValue(LOGGED_USER_SESSION_KEY, DefaultAuthStatus);
        setSessionValue(API_KEY_SESSION_KEY,'');
        return res;
    };

    const changePassword = async (mNewPassword) => {
        let mUserID = getLoggedUserID();
        if (mUserID === 0) return;
        let body = {};
        body.mUserID = mUserID;
        body.mPassword = mNewPassword;
        const res = await webServices.wsChangePassword(body, alertContext, true);
        if (res.responseAnyError) return;
        return true;
    };

    const saveLoggedUserImage = async (mImageListOfBytes) => {
        let mUserID = getLoggedUserID();
        if (mUserID === 0) return;
        let pBody = {"mUserID": mUserID, "mImageListOfBytes": mImageListOfBytes}
        let res = await webServices.wsSetUserImage(pBody, alertContext, true);
        if (res.responseAnyError) return;
        let resUser = await getUser(mUserID);
        let newSession = getSessionValue(LOGGED_USER_SESSION_KEY,'');
        newSession.loggedUser = resUser;
        setSessionValue(LOGGED_USER_SESSION_KEY, newSession);
        return true;
    }

    const getUser = async (pUserID) => {
        let res = await webServices.wsGetUsers({mUserID: pUserID, mWithAvatar: true}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        return (recs.length !== 0) ? recs[0] : {};
    }

    const updateUser = async (pBody) => {
        let res = await webServices.wsUpdateUser(pBody, alertContext, true);
        return !res.responseAnyError;
    }

    const updateLoggedUserProfile = async (user) => {
        let currentUser = getLoggedUser();
        let newUser = {...currentUser, ...user};
        let res = await updateUser(newUser, alertContext, true);
        if (res.responseAnyError) return;
        let resUser = await getUser(newUser.mUserID);
        let newSession = getSessionValue(LOGGED_USER_SESSION_KEY,'');
        newSession.loggedUser = resUser;
        setSessionValue(LOGGED_USER_SESSION_KEY, newSession);
        return true;
    }

    const getAllMenuItems = () => {
        return (getLoggedUser().mProfile) ? getLoggedUser().mProfile.mListOfFunction : [];
    };

    const getDesktopMenuItems = () => {
        return getAllMenuItems().filter((funct) => (funct.mIsEnabled ?? false) && (funct.mShowOnDesktop ?? false));
    };


    return (
        <AuthContext.Provider
            value={{
                register,
                login,
                timedCheck,
                getSessionProgressValue,
                getSessionProgressNormalizedValue,
                resetSessionProgressValue,
                setSessionProgressValue,
                setSessionRemainingTime,
                getSessionRemainTime,
                sessionRefreshSwitch,
                maxSessionTime,
                sessionCheckInterval,
                setSessionExpired,
                logout,
                rememberPassword,
                changePassword,
                changePasswordStatus,
                setChangePasswordStatus,
                resetPasswordStatus,
                setResetPasswordStatus,
                checkIsLogging,
                checkIsLogged,
                getLoggedUser,
                getLoggedUserProfile,
                getLoggedUserHomePage,
                getLoggedUserDefaultRoom,
                updateLoggedUserProfile,
                isLoggedUserAdmin,
                isLoggedUserDesk,
                isLoggedUserManager,
                getLoggedUserID,
                getSession,
                getAllMenuItems,
                getDesktopMenuItems,
                getLoggedUserImage,
                saveLoggedUserImage,
                getLoggedUserCompleteName,
                fetchSystemParameters,
                getSystemParameterValue,
                fetchSystemParameter,
                systemParameters,
                updateSystemParameter,
                deleteSystemParameter,
                refreshSystemParameters,
                fetchParamGroupItemizedListValues,
                resetParamGroupItemizedListValues,
                paramGroupItemizedListValues,
                fetchLoginStatusItemizedListValues,
                resetLoginStatusItemizedListValues,
                loginStatusItemizedListValues
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}

export {AuthProvider, AuthContext};
