import {Button, FormControl, Stack, Tooltip} from "@mui/material";
import {useTheme} from '@mui/material/styles';
import React from 'react';
import {commonClassNames} from "../../theme/Styles";
import {ButtonIcon} from "../Icons/ButtonIcon";

export const SquareButton = ({
                                 color,
                                 variant,
                                 action,
                                 size,
                                 icon,
                                 iconSize,
                                 label,
                                 tooltip,
                                 disabled,
                                 direction,
                                 fullWidth,
                                 withBoxShadow,
                                 sx,
                                 ...otherProps
                             }) => {

    const theme = useTheme();
    const commonClasses = commonClassNames(theme);

    let isFullWidth = fullWidth ?? false;
    let isDisabled = disabled ?? false;
    let sLabel = label ?? "";
    let sTooltip = tooltip ?? "";
    let hasTooltip = !isDisabled && sTooltip !== "";
    let sDirection = (direction ?? "row");
    let sSize = size ?? "small";
    let iSize = (sSize === "extra-small") ? 2 : (sSize === "small") ? 3 : (sSize === "medium") ? 4 : (sSize === "large") ? 5 : 3;
    let cssStyle = {
        ...sx,
        minWidth: iSize + "vw !important",
        maxWidth: iSize + "vw !important",
        minHeight: iSize + "vw !important",
        maxHeight: iSize + "vw !important"
    };
    let bWithBoxShadow = withBoxShadow ?? false;

    if (!bWithBoxShadow) {
        cssStyle = {
            ...cssStyle,
            boxShadow: "none !important"
        }
    }


    const getButton = () => {
        return (
            <FormControl fullWidth={isFullWidth}>
                <Button
                    color={color}
                    variant={variant}
                    onClick={action}
                    size={size}
                    disabled={isDisabled}
                    sx={cssStyle}
                    {...otherProps}
                >
                    <Stack
                        direction={sDirection}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        spacing={theme.spacing(4)}
                    >
                        <ButtonIcon icon={icon} iconSize={iconSize}/>
                        {sLabel}
                    </Stack>
                </Button>
            </FormControl>
        )
    }

    return (
        !hasTooltip ?
            <>{getButton()}</>
            :
            <Tooltip title={tooltip}>
                <div>{getButton()}</div>
            </Tooltip>
    )
}