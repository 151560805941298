import moment from 'moment';
import React, {createContext, useContext, useEffect, useState} from 'react';
import {ReactSession} from 'react-client-session';
import {
    emptyCompleteName,
    getCompleteName,
    JSONClone,
    JSONIsEmpty,
    moneyStringToFloat,
    setDataLoading
} from "../common";
import * as webServices from "../services/WebServices";
import {CustomAlertContext} from "./AlertContext";
import {AuthContext} from "./AuthContext";
import {
    wsCreateInvoiceFromServices,
    wsGetCompanyServices,
    wsUpdateBudget,
    wsUpdateCompanyService
} from "../services/WebServices";

const AccountingContext = createContext();

const initial_prev_state = {
    mode: 0,
    managementState: {},
    managementItem: {}
}

const initial_accounting_state = {
    visitLines: [],
    invoiceLines: []
}

const invoice_header_data = {
    cf: '',
    completeName: '',
    address: '',
    payingMethod: null,
    bank: null,
    notes: '',
    payed: true
}

const credit_document_header_data = {}

const defaultInvoicesServerSideFilter = {
    mYear: moment(new Date()).year()
}

const newService = {
    mServiceID: 0,
    mServiceName: "",
    mDescrizione: "",
    mBarcode: "",
    mServicePrice: 0
}

const AccountingProvider = (props) => {

    const alertContext = useContext(CustomAlertContext);
    const authContext = useContext(AuthContext);

    const [accountingState, setAccountingState] = useState(JSONClone(initial_accounting_state));
    const [accountingMode, setAccountingMode] = useState(0);
    const [accountingLoaded, setAccountingLoaded] = useState(false);
    const [prevState, setPrevState] = useState(JSONClone(initial_prev_state));
    const [fromVisitLinesInvoice, setFromVisitLinesInvoice] = useState({});
    const [invoices, setInvoices] = useState(null);
    const [invoice, setInvoice] = useState(null);
    const [invoicesCurrentPage, setInvoicesCurrentPage] = useState(1);
    const [invoicesTotalRows, setInvoicesTotalRows] = useState(0);
    const [invoicesTotalPages, setInvoicesTotalPages] = useState(0);
    const [invoicesPageSize, setInvoicesPageSize] = useState(0);
    const [invoicesServerSideFilter, setInvoicesServerSideFilter] = useState(JSONClone(defaultInvoicesServerSideFilter));
    const [fromInvoiceCreditDocument, setFromInvoiceCreditDocument] = useState({});
    const [creditDocument, setCreditDocument] = useState(null);
    const [invoiceQuoteTypes, setInvoiceQuoteTypes] = useState(null);
    const [companyServices, setCompanyServices] = useState(null);
    const [vatItems, setVatItems] = useState(null);

    //0 - elenco fatture
    //1 - creazione fattura da linee visita
    //2 - stampa fattura
    //3 - edit fattura
    //4 - dettaglio fattura / crea nota credito da righe fattura
    //5 - stampa nota di credito
    //6 - dettaglio nota di credito

    useEffect(() => {
        if (invoicesCurrentPage > 1) fetchInvoices(false).then(() => {
        });
    }, [invoicesCurrentPage]);

    const openAccounting = (accountItem, mode) => {
        let prevState = {
            mode: accountingMode,
            accountingState: accountingState
        }
        setPrevState(prevState);
        let newState = {};
        switch (mode) {
            case 0:
                newState = {
                    ...accountingState,
                    invoice: null,
                    creditDocument: null
                }
                break
            case 1:
                if (!accountItem.visit) return;
                if (!accountItem.visitLines) return;
                if (accountItem.visitLines.length === 0) return;
                let invoiceHeader = JSONClone(invoice_header_data);
                invoiceHeader.cf = accountItem.visit.mPatient.mCF;
                invoiceHeader.completeName = getCompleteName(accountItem.visit.mPatient.mNomePaziente, accountItem.visit.mPatient.mCognomePaziente);
                invoiceHeader.address = accountItem.visit.mPatient.mLiveAddress + " ";
                invoiceHeader.address += accountItem.visit.mPatient.mLiveCity.mCityName + " ";
                invoiceHeader.address += "(" + accountItem.visit.mPatient.mLiveCity.mTargaProvincia + ")";
                invoiceHeader.address += "(" + accountItem.visit.mPatient.mLiveCity.mTargaProvincia + ")";
                setFromVisitLinesInvoice(invoiceHeader);
                newState = {
                    ...accountingState,
                    visit: accountItem.visit,
                    visitLines: accountItem.visitLines
                }
                break
            case 2:
                setInvoice(null);
                if (!accountItem.invoice) return;
                if (JSONIsEmpty(accountItem.invoice)) return;
                newState = {
                    ...accountingState,
                    invoice: accountItem.invoice
                }
                break
            case 3:
                setInvoice(null);
                if (!accountItem.invoice) return;
                newState = {
                    ...accountingState,
                    invoice: accountItem.invoice
                }
                break
            case 4:
                if (!accountItem.invoice) return;
                setInvoice(null);
                let creditDocumentHeader = JSONClone(credit_document_header_data);
                setFromInvoiceCreditDocument(creditDocumentHeader);
                newState = {
                    ...accountingState,
                    invoice: accountItem.invoice
                }
                break
            case 5:
                setCreditDocument(null);
                if (!accountItem.creditDocument) return;
                if (JSONIsEmpty(accountItem.creditDocument)) return;
                newState = {
                    ...accountingState,
                    creditDocument: accountItem.creditDocument
                }
                break
            case 6:
                if (!accountItem.creditDocument) return;
                setCreditDocument(null);
                newState = {
                    ...accountingState,
                    creditDocument: accountItem.creditDocument
                }
                break
            default:
                break
        }
        setAccountingState(newState);
        setAccountingLoaded(false);
        setAccountingMode(mode);
    }

    const createInvoiceFromVisitLines = async (header) => {
        let vls = [];
        accountingState.visitLines.map((vl) => {
            vls.push({mEsameID: vl.mEsameID});
        })
        let body = {};
        /* aggiunto tipo fattura per la gestione delle fatture libere */
        body.mInvoiceType = 1;
        body.mPayementMode = header.payingMethod;
        body.mListOfEsami = vls;
        body.mVisita = {mVisitaID: accountingState.visit.mVisitaID}
        body.mPayed = (header.payed ?? false);
        body.mNotes = (header.notes);
        body.mBank = (header.bank) ? {mBankID: header.bank.mBankID ?? 0} : null;
        body.mQuote = (header.mQuote && header.mQuote !== "") ? header.mQuote.substring(0, 2) : "";
        let res = await webServices.wsCreateInvoiceFromVisitLines(body, alertContext, true);
        if (res.responseAnyError) return;
        resetInvoices();
        let resData = res.responseData;
        return resData;
    }

    const getInvoicesYears = () => {
        let availYears = [defaultInvoicesServerSideFilter.mYear - 4];
        availYears = [...availYears, defaultInvoicesServerSideFilter.mYear - 3];
        availYears = [...availYears, defaultInvoicesServerSideFilter.mYear - 2];
        availYears = [...availYears, defaultInvoicesServerSideFilter.mYear - 1];
        availYears = [...availYears, defaultInvoicesServerSideFilter.mYear];
        return availYears;
    }

    const resetInvoicesServerSideFilter = () => {
        let resetValues = JSONClone(defaultInvoicesServerSideFilter);
        setInvoicesServerSideFilter(resetValues);
        return resetValues;
    }

    const normalizeInvoice = (item) => {
        let shortDateFormat = authContext.getSystemParameterValue("DATE_FORMAT_SHORT");
        if (item.mPatient) {
            item.mPatient.mCompleteName = getCompleteName(item.mPatient.mNomePaziente, item.mPatient.mCognomePaziente)
            item.mPatient.mLiveCity = item.mPatient.mLiveCity ?? {};
            item.mPatient.mCompleteAddress = (item.mPatient.mLiveAddress ?? "")
            item.mPatient.mCompleteAddress += " ";
            item.mPatient.mCompleteAddress += (item.mPatient.mLiveCity.mCityName ?? "")
            item.mPatient.mCompleteAddress += " ";
            item.mPatient.mCompleteAddress += "(" + (item.mPatient.mLiveCity.mTargaProvincia ?? "") + ")";
            item = {...item};
        } else {
            item.mPatient = {};
            item.mPatient.mCompleteName = emptyCompleteName;
        }
        if (item.mInvoiceDate) item.mInvoiceDateLabel = moment(item.mInvoiceDate ?? new Date()).clone().format(shortDateFormat);
        return {...item};
    }

    const fetchInvoices = async (bReset, newServerSideFilter) => {
        setDataLoading(setInvoices);
        const body = (newServerSideFilter) ? newServerSideFilter : (bReset) ? defaultInvoicesServerSideFilter : invoicesServerSideFilter;
        body.mRequestedPage = (bReset ?? false) ? 1 : invoicesCurrentPage;
        body.mYear = body.mYear.toString();
        setInvoicesServerSideFilter(body);
        const res = await webServices.wsGetInvoices(body, alertContext, true);
        setInvoicesTotalRows(res.responseDataRowsCount ?? 0);
        setInvoicesTotalPages(res.responseDataPagesCount ?? 0);
        setInvoicesCurrentPage(res.responseDataCurrentPage ?? 0);
        setInvoicesPageSize(res.responseDataPageSize ?? 0);
        if (res.responseAnyError) return;
        let resData = res.responseData;
        let recs = resData.map((item) => {
            return normalizeInvoice(item);
        });
        setInvoices(recs);
        return recs;
    };

    const resetInvoices = () => {
        setInvoices(null);
    };

    const fetchInvoice = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetInvoices({mInvoiceID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let resData = res.responseData;
            let recs = resData.map((item) => {
                return {...normalizeInvoice(item)}
            });
            const record = (recs.length !== 0) ? recs[0] : {};
            setInvoice(record);
            return record;
        } else {
            const newRecord = {mInvoiceID: 0}
            setInvoice(newRecord);
            return newRecord;
        }
    };

    const updateInvoice = (record) => {
        webServices.wsUpdateInvoice(record, alertContext, true).then((res) => {
            if (res.responseAnyError) return;
            resetInvoices();
        });
    };

    const updateInvoiceLine = async (pPriceRow) => {
        const body = {
            ...pPriceRow,
            mPrice: moneyStringToFloat(pPriceRow.mPrice)
        }
        const res = await webServices.wsUpdateInvoiceLine(body, alertContext, true);
        if (res.responseAnyError) return;
        return true;
    };

    const fetchInvoicePDF = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetInvoicePDF({mContextualRecordID: recordId}, alertContext, true);
            if (res.responseAnyError) return null;
            let resData = res.responseData;
            return resData;
        } else {
            const newRecord = {mInvoiceID: 0}
            return newRecord;
        }
    }

    const createCreditDocumentFromInvoice = async (header, visitLines) => {
        let body = {};
        body.mInvoiceID = accountingState.invoice.mInvoiceID;
        body.mListOfInvoiceLine = visitLines;
        const res = await webServices.wsCancelInvoice(body, alertContext, true);
        if (res.responseAnyError) return;
        let resData = res.responseData;
        return resData;
    }

    const fetchInvoiceQuoteTypeItemizedListValues = async () => {
        let body = {};
        body.mItemizedList = {mItemizedListID: 45};
        let res = await webServices.wsGetItemizedListValues(body, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setInvoiceQuoteTypes(recs);
        return recs;
    }

    const fetchCreditDocument = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetInvoices({mInvoiceID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let resData = res.responseData;
            let recs = resData.map((item) => {
                return {...normalizeInvoice(item)}
            });
            const rec = (recs.length !== 0) ? recs[0] : {};
            setCreditDocument(rec);
            return rec;
        } else {
            const newRecord = {mInvoiceID: 0}
            setCreditDocument(newRecord);
        }
    };

    const fetchCreditDocumentPDF = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetCreditDocumentPDF({mContextualRecordID: recordId}, alertContext, true);
            if (res.responseAnyError) return null;
            let resData = res.responseData;
            return resData;
        } else {
            const newRecord = {mInvoiceID: 0}
            return newRecord;
        }
    }

    const fetchAccountingReports = async () => {
        const res = await webServices.wsGetReports({mReportGroup: "Contabile"}, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        return recs;
    };

    const printAccountingReport = async (pFromDate, pToDate, pContextualRecordID, pTemplateFileNumber) => {
        const longDateTimeFormat = authContext.getSystemParameterValue("DATETIME_FORMAT_LONG");
        const body = {
            mFilterFrom: pFromDate,
            mFilterTo: pToDate,
            mContextualRecordID: pContextualRecordID,
            mTemplateFileNumber: pTemplateFileNumber
        }
        const res = await webServices.wsPrintAccountingReport(body, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        return recs;
    };

    const exportASLReport = async (pBody) => {
        pBody.mMese = pBody.mMese.toString();
        while (pBody.mMese.length < 2) pBody.mMese = "0" + pBody.mMese;
        pBody.mAnno = pBody.mAnno.toString();
        pBody.mType = 1;
        const res = await webServices.wsExportASLReport(pBody, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        return recs;
    };

    const fetchBudgets = async () => {
        const body = {};
        const res = await webServices.wsGetBudgets(body, alertContext, true);
        if (res.responseAnyError) return;
        let resData = res.responseData;
        let recs = resData;
        return recs;
    };

    const fetchBudget = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetBudgets({mBudgetID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let resData = res.responseData;
            let recs = resData;
            const record = (recs.length !== 0) ? recs[0] : {};
            return record;
        } else {
            const newRecord = {mBudgetID: 0}
            return newRecord;
        }
    };

    const updateBudget = async (record) => {
        const body = {
            ...record,
            mImporto: moneyStringToFloat(record.mImporto),
        }
        const res = await webServices.wsUpdateBudget(body, alertContext, true);
        console.log('wsUpdateBudget ', res);
        if (res.responseAnyError) return;
    };

    const fetchCompanyServices = async () => {
        setDataLoading(setCompanyServices);
        const res = await webServices.wsGetCompanyServices({}, alertContext, true)
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setCompanyServices(recs);
        return recs;
    };

    const fetchCompanyService = async (recordId) => {
        if (recordId !== 0) {
            const res = await webServices.wsGetCompanyServices({mServiceID: recordId}, alertContext, true);
            if (res.responseAnyError) return;
            let recs = res.responseData;
            return (recs.length !== 0) ? recs[0] : newService;
        } else {
            return newService;
        }
    };

    const updateCompanyService = async (record) => {
        const body = {
            ...record,
            mServicePrice: moneyStringToFloat(record.mServicePrice),
        }
        let res = await webServices.wsUpdateCompanyService(body, alertContext, true);
        if (res.responseAnyError) return;
    };

    const fetchVatItemizedListValues = async () => {
        let body = {};
        body.mItemizedList = {mItemizedListID: 33};
        let res = await webServices.wsGetItemizedListValues(body, alertContext, true);
        if (res.responseAnyError) return;
        let recs = res.responseData;
        setVatItems(recs);
        return recs;
    }

    const createInvoiceFromServices = async (body) => {
        body.mInvoiceType = 2;
        let res = await webServices.wsCreateInvoiceFromServices(body, alertContext, true);
        if (res.responseAnyError) return;
        resetInvoices();
        let resData = res.responseData;
        return resData;
    }

    return (
        <AccountingContext.Provider
            value={{
                accountingState,
                accountingMode,
                accountingLoaded,
                setAccountingLoaded,
                fromVisitLinesInvoice,
                setFromVisitLinesInvoice,
                createInvoiceFromVisitLines,
                openAccounting,
                getInvoicesYears,
                invoicesServerSideFilter,
                resetInvoicesServerSideFilter,
                fetchInvoices,
                resetInvoices,
                invoices,
                invoicesTotalPages,
                invoicesTotalRows,
                invoicesCurrentPage,
                setInvoicesCurrentPage,
                invoicesPageSize,
                fetchInvoice,
                invoice,
                setInvoice,
                updateInvoice,
                updateInvoiceLine,
                fetchInvoicePDF,
                fromInvoiceCreditDocument,
                setFromInvoiceCreditDocument,
                fetchInvoiceQuoteTypeItemizedListValues,
                invoiceQuoteTypes,
                createCreditDocumentFromInvoice,
                fetchCreditDocument,
                fetchCreditDocumentPDF,
                creditDocument,
                setCreditDocument,
                fetchAccountingReports,
                printAccountingReport,
                exportASLReport,
                fetchBudgets,
                fetchBudget,
                updateBudget,
                fetchCompanyServices,
                companyServices,
                fetchCompanyService,
                updateCompanyService,
                fetchVatItemizedListValues,
                vatItems,
                createInvoiceFromServices
            }}
        >
            {props.children}
        </AccountingContext.Provider>
    );
}

export {AccountingProvider, AccountingContext};